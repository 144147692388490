import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {BaseModalDirective} from '../base-modal';
import {ModalConfig} from '../modal-config';
import {DrivingLicenceDescriptionModel} from './models/driving-licence-description-modal.model';
import {ObverseDescriptionModel} from './models/obverse-description.model';
import {ReverseDescriptionModel} from './models/reverse-description.model';

export enum KEY_CODE {
  ESCAPE = 'Escape'
}

@Component({
  selector: 'app-driving-licence-description-modal',
  templateUrl: './driving-licence-description-modal.component.html',
  styleUrls: ['./driving-licence-description-modal.component.scss']
})
export class DrivingLicenceDescriptionModalComponent
  extends BaseModalDirective
  implements OnInit, OnDestroy {
  drivingLicenceDescriptionModal: DrivingLicenceDescriptionModel;
  obverseDescription: ObverseDescriptionModel;
  reverseDescription: ReverseDescriptionModel;

  constructor(
    public config: ModalConfig,
    private translate: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    this.translate.get('DRIVERS_LICENSE.DESC_MODAL').subscribe({
      next: (content) => {
        this.drivingLicenceDescriptionModal = content;
      }
    });
  }

  ngOnDestroy(): void {}

  onCloseClick() {
    this.close();
  }
}

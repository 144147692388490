import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { finalize, tap } from 'rxjs/operators';
import { AccountApiService } from 'src/app/my-account/settings/account-api.service';
import { FormConfig } from 'src/app/my-account/settings/form.model';
import { GhostButtonType } from 'src/app/shared/components/ghost-button/ghost-button-type.enum';
import { BaseModalDirective } from 'src/app/shared/components/modal/base-modal';

import { AuthService } from './../../../../core/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { DeleteAccountModal } from './_model/delete-account.model';
import { DeleteAccountFormBuilderService } from './delete-account-form-builder.service';

@Component({
  selector: 'app-delete-account-modal',
  templateUrl: './delete-account-modal.component.html',
  styleUrls: ['./delete-account-modal.component.scss'],
  providers: [DeleteAccountFormBuilderService]
})
export class DeleteAccountModalComponent extends BaseModalDirective {
  loading = false;
  content: DeleteAccountModal;

  config: FormConfig;

  buttonOk = {
    width: 136,
    height: 50
  };

  buttonCancel = {
    width: 136,
    height: 50
  };

  GhostButtonType = GhostButtonType;

  constructor(
    private formBuilder: DeleteAccountFormBuilderService,
    private auth: AuthService,
    private accountApi: AccountApiService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {
    super();
    this.translate.get('DASHBOARD.SETTINGS.DELETE_ACCOUNT_MODAL').subscribe({
      next: (result: DeleteAccountModal) => (this.content = result)
    });
    this.config = this.formBuilder.build();
  }

  onOkClick() {
    const passwordControl = this.config.form.get('password');
    passwordControl.markAsTouched();
    passwordControl.updateValueAndValidity();
    if (!this.config.form.valid) {
      return;
    }

    this.accountApi
      .deleteClientAccount(passwordControl.value)
      .pipe(
        tap(() => (this.loading = this.getData().freezeOverlay = true)),
        finalize(() => this.cleanup())
      )
      .subscribe({
        complete: () => this.deleted(),
        error: () => this.errored()
      });
  }
  onCancelClick() {
    this.close();
  }

  private cleanup() {
    this.loading = this.getData().freezeOverlay = false;
    const passwordControl = this.config.form.get('password');
    passwordControl.markAsUntouched();
    passwordControl.patchValue(null);
  }

  private deleted() {
    this.toastr.success(this.translate.instant('TOASTR.DASHBOARD.SETTINGS.ACCOUNT_DELETED'));
    this.auth.logout();
  }

  private errored() {
    this.toastr.warning(this.translate.instant('TOASTR.DASHBOARD.SETTINGS.ACCOUNT_DELETED_FAILED'));
    this.close();
  }
}

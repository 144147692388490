import { Country } from './../../../models/countries/country.model';
import { ValidationPatterns } from './../../../validation/validation-patterns.const';
import { DistrictOffice } from './../../../models/district-offices/district-office.model';
import { ValidationFunctions } from 'src/app/shared/validation/validation-functions.const';

import { Injectable } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
  AbstractControl
} from '@angular/forms';
import { EmployeeDto } from 'src/app/shared/models/employee/employee-dto.model';
import {
  BaseFormBinderService,
  nullReplacer
} from 'src/app/shared/services/form/base-form-binder.service';
import { ValidationExtension } from 'src/app/shared/validation/validation-extensions.const';
import { DrivingLicenceDataFormValidation } from './driving-licence-data.model';
import { ValidationService } from 'src/app/shared/validation/validation.service';

@Injectable()
export class DrivingLicenceDataFormBinder extends BaseFormBinderService {
  private countries: Array<Country>;
  private districtOffices: Array<DistrictOffice>;
  private defaultCountry: Country;

  private savedCountryCode: string;
  private savedDistrictOffice: string;
  private selectedCountry: Country;

  private fieldValidation: DrivingLicenceDataFormValidation;

  constructor(private formBuilder: UntypedFormBuilder, private validationService: ValidationService) {
    super();
  }
  protected createForm(fieldValidations: DrivingLicenceDataFormValidation) {
    const fg = new UntypedFormGroup({});
    const formFields = Object.entries(fieldValidations.formFields);
    formFields.forEach(([name, validator]: [string, ValidatorFn]) => {
      fg.addControl(name, new UntypedFormControl(null, validator, name !== 'serialNumber' ? ValidationFunctions.allowedCharsValidator(this.validationService) : null));
    });
    fg.controls['validIndefinitely'].valueChanges.subscribe((validIndefinitely) =>
      this.hasValidIndefinitelyChange(validIndefinitely)
    );
    this.fieldValidation = fieldValidations;
    return fg;
  }

  isControlRequired(controlName: string): boolean {
    return (
      controlName &&
      this.form.get(controlName) &&
      ValidationExtension.hasRequiredField(this.form.get(controlName))
    );
  }

  public setCountries(countries: Array<Country>, defaultCountry: Country): void {
    this.countries = countries;

    this.getForm()
      .get('authorityCountry')
      .setValidators([
        ValidationFunctions.valueInArrayValidator(
          this.countries.map((country: Country) => country.countryCode)
        ),
        this.getForm().get('authorityCountry').validator
      ]);
    this.defaultCountry = defaultCountry;

    if (this.savedCountryCode) {
      this.selectedCountry = this.countries.find(
        (country: Country) =>
          country.countryCode === this.savedCountryCode ||
          country.countryName === this.savedCountryCode
      );
      this.set('authorityCountry', this.selectedCountry.countryName);
      this.savedCountryCode = null;

      if (this.savedDistrictOffice && this.districtOffices) {
        if (this.selectedCountry.countryCode === this.defaultCountry.countryCode) {
          this.set(
            'issuingAuthority',
            this.districtOffices.find(
              (office: DistrictOffice) => office.id === this.savedDistrictOffice
            ).name
          );
        } else {
          this.set('issuingAuthority', this.savedDistrictOffice);
        }
        this.savedDistrictOffice = null;
      }
    }
    if (this.districtOffices) {
      this.setIssuingAuthorityValidator();
    }
  }

  public setDistrictOffices(offices: Array<DistrictOffice>): void {
    this.districtOffices = offices;
    if (this.savedDistrictOffice && this.selectedCountry) {
      this.set('issuingAuthority', this.savedDistrictOffice);
    }
    this.savedDistrictOffice = null;
  }

  public addCountryUpdatedValidator(): void {
    this.getForm()
      .get('authorityCountry')
      .setValidators([this.countryUpdated(), this.getForm().get('authorityCountry').validator]);

    if (this.defaultCountry) {
      this.setIssuingAuthorityValidator();
      this.setSerialNumberValidator();
    }
  }

  private setIssuingAuthorityValidator() {
    if (this.get('authorityCountry') === this.defaultCountry.countryCode) {
      this.getForm()
        .get('issuingAuthority')
        .setValidators([
          this.fieldValidation.formFields.issuingAuthority,
          ValidationFunctions.valueEmptyOrInArrayValidator(
            this.districtOffices.map((office: DistrictOffice) => office.name)
          )
        ]);
    }
  }

  private setSerialNumberValidator() {
    if (this.get('authorityCountry') === this.defaultCountry.countryCode) {
      this.getForm()
            .get('serialNumber').clearValidators();
            
      this.getForm()
        .get('serialNumber')
        .setValidators([
          Validators.pattern(ValidationPatterns.driverLicenseNo),
          Validators.required
        ]);
    } else {
      this.getForm()
      .get('serialNumber').clearValidators();
      
      this.getForm()
        .get('serialNumber')
        .setValidators([
          Validators.maxLength(16),
          Validators.required
      ]);
    }
  }

  private countryUpdated(): ValidatorFn {
    return (control: AbstractControl): null => {
      if (control && control.value && this.getForm().get('issuingAuthority')) {
        if (control.value === this.defaultCountry.countryCode) {
          this.setSerialNumberValidator();
          this.setIssuingAuthorityValidator();
        } else {
          this.getForm()
            .get('issuingAuthority')
            .setValidators([
              this.fieldValidation.formFields.issuingAuthority,
              Validators.maxLength(150)
            ]);
            this.getForm()
            .get('serialNumber').clearValidators();
          this.getForm()
            .get('serialNumber')
            .setValidators([Validators.maxLength(16), Validators.required]);
        }
        this.getForm().get('serialNumber').updateValueAndValidity();
        this.getForm().get('issuingAuthority').updateValueAndValidity();
      }
      return null;
    };
  }

  hasValidIndefinitelyChange(validIndefinitely: any): void {
    if (validIndefinitely) {
      this.disable('expirationDate');
      this.getForm()
        .get('expirationDate')
        .setValidators([this.fieldValidation.formFields.expirationDate, Validators.nullValidator]);
      if (this.getForm().get('expirationDate')) {
        this.setControl('expirationDate', ' ');
      }
    } else {
      this.enable('expirationDate');
      if (this.getForm().get('expirationDate').invalid) {
        this.setControl('expirationDate', '');
      }
    }
    this.getForm().get('expirationDate').updateValueAndValidity();
  }
}

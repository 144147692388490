import * as layout from './layout.action';
import { Header } from '../header/_models/header.model';
import { createSelector, createFeatureSelector } from '@ngrx/store';
import { Footer } from '../footer/_models/footer.model';
import { InfoBarMessage } from '../info-bar/_models/info-bar-message.model';

export interface LayoutState {
  infoBarsData: InfoBarMessage[];
  headerData: Header;
  footerData: Footer;
}

export const layoutInitialState: LayoutState = {
  infoBarsData: new Array<InfoBarMessage>(),
  headerData: null,
  footerData: null
};

export function layoutReducer(
  state: LayoutState = layoutInitialState,
  action: layout.Actions
): LayoutState {
  switch (action.type) {
    case layout.ActionTypes.SET_HEADER:
      return Object.assign({}, state, {
        headerData: action.payload
      });
    case layout.ActionTypes.SET_FOOTER:
      return Object.assign({}, state, {
        footerData: action.payload
      });
    case layout.ActionTypes.SET_INFO_BARS:
      return Object.assign({}, state, {
        infoBarsData: action.payload
      });
    default:
      return state;
  }
}

export const featureName = 'layout';

export const selectLayoutState = createFeatureSelector<LayoutState>(featureName);

export const selectHeaderData = createSelector(selectLayoutState, (state) => state.headerData);

export const selectFooterData = createSelector(selectLayoutState, (state) => state.footerData);

export const selectInfoBarsData = createSelector(selectLayoutState, (state) => state.infoBarsData);

import { ServiceLinkType } from './service-link-type.enum';
import { Component, OnInit, Input, ElementRef } from '@angular/core';

@Component({
  selector: 'ic-service-link',
  templateUrl: './service-link.component.html',
  styleUrls: ['./service-link.component.scss']
})
export class ServiceLinkComponent implements OnInit {
  @Input() type: ServiceLinkType;
  @Input() noArrowOnMobile = false;
  @Input() fontSizePixels: number;
  @Input() active = false;

  @Input() internalUrl: string;
  @Input() externalUrl: string;
  constructor(private selfReference: ElementRef) {}

  ngOnInit() {
    const selfDOM = this.selfReference.nativeElement;
    switch (this.type) {
      case ServiceLinkType.HOMEPAGE:
        selfDOM.classList.add('homepage');
        break;
      case ServiceLinkType.CATEGORY_PAGE:
        selfDOM.classList.add('category');
        break;
      default:
        selfDOM.classList.add('homepage');
    }

    if (this.noArrowOnMobile) {
      selfDOM.classList.add('no-arrow-mobile');
    }

    if (this.active) {
      selfDOM.classList.add('active');
    }
  }
}

import { FormInputError } from './../model/form-input.model';
import { Injectable, OnDestroy } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Subscription, BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class ErrorListIndicatorService implements OnDestroy {
  private formControl: AbstractControl;
  private lifetimeSubscriptions: Subscription = new Subscription();
  private errorListSubject: BehaviorSubject<FormInputError[]> = new BehaviorSubject([]);
  private isInvalidSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

  get isInvalid(): boolean {
    return this.isInvalidSubject.getValue();
  }

  get isInvalidChanges(): Observable<boolean> {
    return this.isInvalidSubject.asObservable();
  }

  get errorList(): FormInputError[] {
    return this.errorListSubject.getValue();
  }

  get errorListChanges(): Observable<FormInputError[]> {
    return this.errorListSubject.asObservable();
  }

  init(formControl: AbstractControl, errorList: FormInputError[]) {
    this.formControl = formControl;

    const hiddenErrorList = errorList.map((error) => {
      error.show = false;
      return error;
    });

    this.errorListSubject.next(hiddenErrorList);

    this.lifetimeSubscriptions.unsubscribe();
    this.lifetimeSubscriptions = new Subscription();
    const sub1 = this.formControl.statusChanges.subscribe(() => this.updateValidationIndicators());
    this.lifetimeSubscriptions.add(sub1);
  }

  updateValidationIndicators() {
    const isInvalid = this.formControl.invalid && this.formControl.touched;
    this.isInvalidSubject.next(isInvalid);

    const errorList = this.errorListSubject.getValue().map((item) => {
      item.show = this.hasOneOfErrors(item.type);
      return Object.assign({}, item);
    });
    this.errorListSubject.next(errorList);
  }

  private hasOneOfErrors(errors: string[]) {
    let invalid = false;
    errors.forEach((value) => {
      if (this.formControl.hasError(value)) {
        invalid = true;
      }
    });
    return this.formControl.touched && invalid;
  }

  ngOnDestroy(): void {
    this.lifetimeSubscriptions.unsubscribe();
  }
}

import { AuthService } from 'src/app/core/auth';
import { CheckStatusResponseDto } from '../company-card-application/_models/check-status-response-dto';
import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { StatusDto } from 'src/app/shared/models/applications/status-dto.model';
import { environment as env } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BreadcrumbsService } from 'src/app/shared/components/breadcrumbs/breadcrumbs.service';
import { AttachmentDtoList } from 'src/app/shared/models/attachments/attachment-dto-list.model';
import { ApplicationDto } from 'src/app/shared/models/applications/application-dto.model';
import { ApplicationBasicDto } from 'src/app/shared/models/applications/application-basic-dto.model';
import { TranslateService } from '@ngx-translate/core';
import { CardTypes } from '../_consts/card-types.enum';
import { AttachmentMetadataList } from 'src/app/shared/components/stc/attachments/model/attachment-metadata-list';
import { STC_V1 } from 'src/app/shared/consts/api-version.config';
import { StcWizardGuardService } from './stc-wizard-guard.service';
import { filter, take, finalize, tap } from 'rxjs/operators';

const FILLED = '/filled';

@Injectable()
export class StcApplicationsService implements StcWizardGuardService {
  loggedUserUid: string;
  public loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isBusiness: boolean;
  constructor(
    private http: HttpClient,
    private breadcrumbsService: BreadcrumbsService,
    private translate: TranslateService,
    private auth: AuthService
  ) {
    this.auth.user$
    .pipe(
      filter((result) => result != null),
      take(1)
    )
    .subscribe((result) => {
      this.loggedUserUid = result.uuid;
      this.isBusiness = AuthService.isBusinessUser(result);
    });
  }
  private readonly header = new HttpHeaders().set('Content-Type', STC_V1);

  private static mapCardTypeToFileName(cardType: ApplicationBasicDto.TypeEnum): string {
    switch (cardType) {
      case ApplicationBasicDto.TypeEnum.DRIVER_CARD:
        return 'kierowcy.pdf';
      case ApplicationBasicDto.TypeEnum.TEMPORARY_DRIVER_CARD:
        return 'czasowa_i_nieodnawialna.pdf';
      case ApplicationBasicDto.TypeEnum.WORKSHOP_CARD:
        return 'warsztatowa.pdf';
      case ApplicationBasicDto.TypeEnum.COMPANY_CARD:
        return 'przedsiebiorstwa.pdf';
      case ApplicationBasicDto.TypeEnum.CONTROL_CARD:
        return 'kontrolna.pdf';
      default:
        break;
    }
  }

  getStatus(id: string, isPaper: boolean): Observable<StatusDto> {
    return this.http.get<StatusDto>(
      `${env.BACKEND_API.BASE}${isPaper ? env.BACKEND_API.PAPER_FORM : env.BACKEND_API.APPLICATIONS}/${id}${env.BACKEND_API.STATUS}`
    );
  }

  getApplicationsBasic(id: string): Observable<Partial<ApplicationBasicDto>> {
    return this.http.get<any>(`${env.BACKEND_API.BASE}${env.BACKEND_API.OBJECTS}/${id}${env.BACKEND_API.KIND}`,
    { headers: this.header })
  } 

  getApplicationStatuses(applicationId: string, isPaper: boolean): Observable<CheckStatusResponseDto> {
    return this.http.get<CheckStatusResponseDto>(
      `${env.BACKEND_API.BASE}${isPaper ? env.BACKEND_API.PAPER_FORM : env.BACKEND_API.APPLICATIONS}/${applicationId}${env.BACKEND_API.STATUSES}`
    );
  }

  getRequiredAttachmentsListBy(
    applicationId: string,
    cardType: string,
    isPaper: boolean
  ): Observable<AttachmentMetadataList> {
    if(isPaper) {
      return this.http.get<AttachmentMetadataList>(
        `${env.BACKEND_API.BASE}${env.BACKEND_API.PAPER_FORM}/${cardType}/${applicationId}${env.BACKEND_API.REQUIRED_ATTACHMENTS}`
      );
    } else {
      return this.http.get<AttachmentMetadataList>(
        `${env.BACKEND_API.BASE}${env.BACKEND_API.APPLICATIONS}/${cardType}/${applicationId}${env.BACKEND_API.ATTACHMENTS}`
      );
    }
  }

  getRequiredAttachmentsListForPaperBy(
    applicationId: string,
    cardType: string
  ): Observable<AttachmentMetadataList> {
    return this.http.get<AttachmentMetadataList>(
      `${env.BACKEND_API.BASE}${env.BACKEND_API.PAPER_FORM}/${cardType}/${applicationId}${env.BACKEND_API.REQUIRED_ATTACHMENTS}`
    );
  }

  public getRequiredAttachmentsListForCardType(
    id: string,
    cardType: CardTypes
  ): Observable<AttachmentMetadataList> {
    const cardTypeUrlSegment = StcWizardGuardService.mapCardTypeToURLSegment(cardType);
    return this.http.get<AttachmentMetadataList>(
      `${env.BACKEND_API.BASE}${env.BACKEND_API.APPLICATIONS}/${cardTypeUrlSegment}/${id}${env.BACKEND_API.ATTACHMENTS}`
    );
  }

  public getAttachments(id: string): Observable<AttachmentDtoList> {
    return this.http.get<AttachmentDtoList>(
      `${env.BACKEND_API.BASE}${env.BACKEND_API.APPLICATIONS}/${id}${env.BACKEND_API.ATTACHMENTS}?filter=withoutContent`
    );
  }

  putProceedAttachmentsStatusBy(
    applicationId: string,
    cardType: string,
    isPaper: boolean
  ): Observable<ApplicationDto> {
    const type = cardType.toLowerCase().replace(/_/g, '-');
    return this.http.put<ApplicationDto>(
      `${env.BACKEND_API.BASE}${isPaper ? env.BACKEND_API.PAPER_FORM : env.BACKEND_API.APPLICATIONS}/${type}/${applicationId}${env.BACKEND_API.PROCEED_ATTACHMENTS}`,
      {},
      { headers: this.header }
    );
  }

  moveBackward(id: string, status?: string, isPaper: boolean = false, cardType?: CardTypes): Observable<any> {
    const statusQuery = status == null ? '' : `?status=${status}`;
    return this.http.put<any>(
      `${env.BACKEND_API.BASE}${isPaper ? env.BACKEND_API.PAPER_FORM : env.BACKEND_API.APPLICATIONS}/${id}${env.BACKEND_API.BACK}${statusQuery}`,
      null
    );
  }

  deleteApplication(cardType: string, applicationId: string, isPaper: boolean): Observable<any> {
    const type = cardType.toLowerCase().replace(/_/g, '-');

    return this.http.delete<any>(
      `${env.BACKEND_API.BASE}${isPaper ? env.BACKEND_API.PAPER_FORM : env.BACKEND_API.APPLICATIONS}/${type}/${applicationId}`
    );
  }

  getFilledApplicationLink(applicationType: string, applicationId: string, isPaper: boolean): Observable<any> {
    return this.http.get<string>(
      `${env.BACKEND_API.BASE}${isPaper ? env.BACKEND_API.PAPER_FORM : env.BACKEND_API.APPLICATIONS}/${applicationType}/${applicationId}${FILLED}`,
      { responseType: 'blob' as 'json' }
    );
  }

  downloadFilledApplication(type: ApplicationBasicDto.TypeEnum, id: string, isPaper: boolean) {
    this.loading.next(true);

    this.getFilledApplicationLink(type, id, isPaper).pipe(finalize(() => this.loading.next(false))).subscribe((result) => {
      const blob = new Blob([result], { type: 'application/pdf' });
      const data = window.URL.createObjectURL(blob);
      const fileName = this.translate.instant('PAYMENT.PAYMENT_CONFIRMATION.PAYMENT_CONFIRMATION');
      const link = document.createElement('a');

      link.href = data;
      link.download = fileName + StcApplicationsService.mapCardTypeToFileName(type);
      link.click();
    });
  }

  verifyAccessToSite(bDate: string, nip: string, id: string, cardType: CardTypes, paperOwnerId: string): Observable<Partial<ApplicationBasicDto>> {
    sessionStorage.setItem('bdate', bDate);
    sessionStorage.setItem('nip', nip);
    sessionStorage.setItem('id', id);
    sessionStorage.setItem('cardType', cardType);
    sessionStorage.setItem('paperOwnerId', paperOwnerId);
    const body = {
      applicationType: cardType,
      paperFormId: id,
      validationNumber: bDate || nip,
      validationNumber_2: paperOwnerId
    };
    return this.http.post(`${env.BACKEND_API.BASE}${env.BACKEND_API.PAPER_FORM}/download/get-info`, body).pipe(tap(value => sessionStorage.setItem('paperFormDetails', JSON.stringify(value))));
  }

  downloadPaperAnonymousApplication():  Observable<any> {
    const body = {
      applicationType: sessionStorage.getItem('cardType'),
      paperFormId: sessionStorage.getItem('id'),
      validationNumber: sessionStorage.getItem('nip') || sessionStorage.getItem('bdate'),
      validationNumber_2: sessionStorage.getItem('paperOwnerId')
    };
    return this.http.post(`${env.BACKEND_API.BASE}${env.BACKEND_API.PAPER_FORM}/download/download-pdf`, body, { responseType: 'blob' as 'json' })
  }
}

import { TranslateService } from '@ngx-translate/core';
import { MODAL_OVERLAY_CLICKED } from 'src/app/shared/components/modal/modal-ref';
import { CardLossInformationModalComponent } from './../../modal/card-loss-information-modal/card-loss-information-modal.component';
import { CardTypes } from './../../../../applications/stc/_consts/card-types.enum';
import { CorrectionsErrors } from 'src/app/shared/models/corrections/corrections.model';
import { CroppedImageInfoModalComponent } from 'src/app/shared/components/modal/cropped-image-info-modal/cropped-image-info-modal.component';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { MissingRequiredAttachmentNotificationService } from '../../../services/missing-required-attachment-notification/missing-required-attachment-notification.service';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { EMPTY, iif, Observable, Subscription, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, shareReplay, take, tap } from 'rxjs/operators';
import { Extensions } from 'src/app/shared/extensions/extensions';

import { GhostButtonType } from '../../ghost-button/ghost-button-type.enum';
import {
  ApplicationAttachmentType,
  AttachmentEntity,
  AttachmentMetadata,
  UploadStatus
} from './model';
import {
  AttachmentsApiService,
  AttachmentsFacadeService,
  AttachmentsFormBinder,
  AttachmentsStore,
  AttachmentValidatorFactory
} from './services';
import { AttachmentType } from 'src/app/shared/models/attachments/attachment-dto.model';
import { ReasonDto } from 'src/app/shared/models/applications/reason-dto.model';
import { ApplicationDto } from 'src/app/shared/models/applications/application-dto.model';
import { ModalRef } from '../../modal/modal-ref';

@Component({
  selector: 'app-stc-card-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss'],
  providers: [
    AttachmentValidatorFactory,
    AttachmentsFacadeService,
    AttachmentsStore,
    AttachmentsFormBinder,
    AttachmentsApiService
  ],
})
export class AttachmentsComponent implements OnInit, OnDestroy {
  @Input() applicationId: string;
  @Input() complaintId: string;
  @Input() applicationType: ApplicationAttachmentType = 'APPLICATION';
  @Input() cardType: ApplicationDto.TypeEnum;
  @Input() set errors(data: CorrectionsErrors) {
    data?.attachmentErrors?.forEach((element) => {
      this.correctionErrors.set(element.entry, element.checksums);
    });
  }
  @Input() reason: ReasonDto.ReasonTypeEnum;

  @Output() submitted: EventEmitter<void> = new EventEmitter();
  @Output() back: EventEmitter<void> = new EventEmitter();

  @Output() state: EventEmitter<Observable<AttachmentEntity[]>> = new EventEmitter();

  form: UntypedFormGroup;
  CardTypes = ApplicationDto.TypeEnum;

  attachmentsConfig$: Observable<AttachmentMetadata[]>;

  GhostButtonType: typeof GhostButtonType = GhostButtonType;

  groupPlaceholders: any[] = new Array(3);

  submitDisabled = true;
  skipFormValidation = false;
  modalRef: ModalRef;
  sub: Subscription = new Subscription();
  displayMissingRequiredAttachmentNotification: boolean;
  config: any;
  correctionErrors = new Map<string, Array<string>>();
  ReasonEnum = ReasonDto.ReasonTypeEnum;

  constructor(
    private attachmentsService: AttachmentsFacadeService,
    private formBinder: AttachmentsFormBinder,
    private missingRequiredAttachmentNotificationService: MissingRequiredAttachmentNotificationService,
    private modalService: ModalService,
    private translate: TranslateService
    
  ) {
    this.form = this.formBinder.bindForm();
  }
  ngOnInit() {
    this.state.emit(this.attachmentsService.entities$);
    let config;
    this.attachmentsConfig$ = this.attachmentsService
      .fetchInitialDataFor(this.applicationId, this.applicationType)
      .pipe(
        tap((configs) => this.formBinder.initFormControls(configs, this.applicationType)),
        tap((configs) =>
          this.attachmentsService.entities$
            .pipe(take(1))
            .subscribe((entities) => this.showModalIfPhotoNotUploaded(entities, configs))
        ),
        tap(() => (this.groupPlaceholders = [])),
        tap(() => (this.submitDisabled = false)),
        tap((configs) => (config = configs)),
        tap((configs) => (this.skipFormValidation = configs.length === 0)),
        shareReplay()
      );

    this.nextSub = this.attachmentsService.entities$
      .pipe(
        map((entities) =>
          entities.some((element) => element.status.equals(UploadStatus.IN_PROGRESS))
        )
      )
      .subscribe((isDisabled) => (this.submitDisabled = isDisabled));

    this.displayMissingRequiredAttachmentNotification =
      this.missingRequiredAttachmentNotificationService.shouldNotify();
  }

  submitForm(): void {
    if (!this.skipFormValidation) {
      this.formBinder.submit();
      this.form.statusChanges
        .pipe(
          filter((status) => status === 'VALID' || status === 'INVALID'),
          take(1),
          mergeMap((status) =>
            iif(
              () => status === 'VALID',
              this.attachmentsService.putProceedAttachments(
                this.applicationId,
                this.applicationType
              ),
              throwError(null)
            )
          ),
          catchError((err) => {
            Extensions.scrollToFirstInvalidInput();
            return EMPTY;
          })
        )
        .subscribe({ next: () => this.submitted.emit() });
    } else {
      this.attachmentsService
        .putProceedAttachments(this.applicationId, this.applicationType)
        .subscribe({ next: () => this.submitted.emit() });
    }
  }

  onSubmit() {
    if(this.reason === ApplicationDto.ReasonTypeEnum.FIRSTISSUELOSTFOREIGNCARD) {
      this.modalRef = this.modalService.open(CardLossInformationModalComponent);
      const sub = this.modalRef.afterClosed.subscribe((value) => {
        if(value === MODAL_OVERLAY_CLICKED || value === null) {
          return;
        }

        if(value === 'NEXT') {
          this.submitForm();
        }

        sub.unsubscribe();
        this.modalRef = null;
      });
    } else {
      this.submitForm();
    }
  }

  showModalIfPhotoNotUploaded(entities: AttachmentEntity[], config: AttachmentMetadata[]) {
    const configEntities = config.filter((entity) => entity.type === AttachmentType.PHOTO);
    const filteredEntities = entities.filter((entity) => entity.dto.type === AttachmentType.PHOTO);
    configEntities.length !== 0 && filteredEntities.length === 0
      ? this.modalService.open(CroppedImageInfoModalComponent, {
        data: { maxHeight: true }
      })
      : null;
  }

  onBackClicked = () => {
    this.back.emit();
  }

  set nextSub(val: Subscription) {
    this.sub.add(val);
  }

  generateCardName(): string {
    switch (this.cardType) {
      case ApplicationDto.TypeEnum.WORKSHOPCARD: 
        return this.translate.instant(
          'STC.ATTACHMENTS.CARD_NAMES.label_workshop'
        );
      case ApplicationDto.TypeEnum.DRIVERCARD: 
      return this.translate.instant('STC.ATTACHMENTS.CARD_NAMES.label_driver');
      case ApplicationDto.TypeEnum.TEMPORARYDRIVERCARD: 
        return this.translate.instant('STC.ATTACHMENTS.CARD_NAMES.label_temporaryDriver');
      case ApplicationDto.TypeEnum.COMPANYCARD: 
        return this.translate.instant('STC.ATTACHMENTS.CARD_NAMES.label_company');
      case ApplicationDto.TypeEnum.CONTROLCARD: 
        return this.translate.instant('STC.ATTACHMENTS.CARD_NAMES.label_control');
      default: 
        return this.translate.instant('STC.ATTACHMENTS.CARD_NAMES.label_default');
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}

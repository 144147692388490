<main class="payment ic-page" *ngIf="content">
  <header class="payment__header" *ngIf="payment">
    <h1 class="payment__header--title" [ngSwitch]="payment.typeSubscription">
      <ng-container *ngSwitchCase="PoskTypeSubscription.ZMIANA_KARTY_PLATNOSC_ZA_LICENCJE">{{
        content.withCardChange
      }}</ng-container>
      <ng-container *ngSwitchCase="PoskTypeSubscription.ZMIANA_KARTY_ZWROT_SRODKOW">{{
        content.onlyCardChange
      }}</ng-container>
      <ng-container *ngSwitchDefault>{{ content.title }}</ng-container>
    </h1>
  </header>

  <section class="payment__summary" *ngIf="payment">
    <!-- Informacja o płatności -->
    <ng-container [ngSwitch]="paymentType">
      <div class="payment__summary--row-wrapper" *ngSwitchCase="OskPaymentType.LICENCE">
        <ng-container [ngSwitch]="payment.typeSubscription">
          <!-- Licencja: koszyk zmiana karty -->
          <div
            class="payment__summary--row payment__summary--row-1"
            *ngSwitchCase="PoskTypeSubscription.ZMIANA_KARTY_ZWROT_SRODKOW">
            <div class="payment__summary-item">
              <p>{{ content.CART_LIST.type }}</p>
            </div>

            <div class="payment__summary-item">
              <p class="mb-0">
                <strong>{{ content.CART_LIST.cardAuth }}</strong>
              </p>
            </div>
          </div>
          <!-- Licencja: koszyk pozostałe przypadki -->
          <div class="payment__summary--row" *ngSwitchDefault>
            <div class="payment__summary-item">
              <p>{{ content.CART_LIST.type }}</p>
            </div>
            <div class="payment__summary-item">
              <p>{{ content.CART_LIST.period }}</p>
            </div>
            <div class="payment__summary-item payment__summary-item--last">
              <p>{{ content.CART_LIST.price }}</p>
            </div>

            <div class="payment__summary-item">
              <p class="mb-0">
                <strong
                  >{{ isPeriodic ? content.CART_LIST.periodicPayment : '' }}
                  {{ payment.licenceType | formatPoskLicenceType | async }}</strong
                >
              </p>
            </div>
            <div class="payment__summary-item">
              <p class="mb-0">
                <strong>{{ payment.period }} {{ content.CART_LIST.days }}</strong>
              </p>
            </div>
            <div class="payment__summary-item payment__summary-item--last">
              <p class="mb-0">
                <strong>{{ payment.amount / 100 }} {{ content.CART_LIST.units }}</strong>
              </p>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="payment__summary--row-wrapper" *ngSwitchCase="OskPaymentType.WORD">
        <!-- Egzamin WORD: koszyk -->
        <div class="payment__summary--row payment__summary--row-4">
          <div class="payment__summary-item">
            <p>{{ content.CART_LIST.type }}</p>
          </div>
          <div class="payment__summary-item">
            <p>{{ content.CART_LIST.category }}</p>
          </div>
          <div class="payment__summary-item">
            <p>{{ content.CART_LIST.examinationCenter }}</p>
          </div>
          <div class="payment__summary-item payment__summary-item--last">
            <p>{{ content.CART_LIST.price }}</p>
          </div>

          <div class="payment__summary-item">
            <p class="mb-0">
              <strong>{{ content.CART_LIST.exam }}</strong>
            </p>
          </div>
          <div class="payment__summary-item">
            <p class="mb-0">
              <strong>{{ payment.category }}</strong>
            </p>
          </div>
          <div class="payment__summary-item">
            <p class="mb-0">
              <strong>{{ payment.wordName }}</strong>
            </p>
          </div>
          <div class="payment__summary-item payment__summary-item--last">
            <p class="mb-0">
              <strong>{{ payment.amount / 100 }} {{ content.CART_LIST.units }}</strong>
            </p>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- Informacje o prowizji -->
    <!-- NOTE -->
    <!--  -->
    <div *ngIf="payment.provision" class="payment__summary--row payment__summary--row-2">
      <div class="payment__summary-item">
        <p class="mb-0">{{ content.CART_LIST.provision }}</p>
      </div>
      <div class="payment__summary-item payment__summary-item--last">
        <p class="mb-0">
          <strong>{{ payment.provision / 100 }} {{ content.CART_LIST.units }}</strong>
        </p>
      </div>
    </div>

    <!-- Podsumowanie platnosci -->
    <div
      *ngIf="payment.typeSubscription !== PoskTypeSubscription.ZMIANA_KARTY_ZWROT_SRODKOW"
      class="payment__summary--row payment__summary--row-2 payment__summary--sum">
      <div class="payment__summary-item">
        <p class="mb-0">
          <strong>{{ content.CART_LIST.priceSum }}</strong>
        </p>
      </div>
      <div class="payment__summary-item payment__summary-item--last">
        <p class="mb-0">
          <strong
            >{{ payment.amount / 100 | number: '1.2-2' }}
            <!-- NOTE -->
            <!-- + payment.provision / 100 -->
            {{ content.CART_LIST.units }}</strong
          >
        </p>
      </div>
    </div>
    <app-info-box
      *ngIf="isPeriodic"
      infoBoxText="{{
        isPeriodic && payment.typeSubscription === PoskTypeSubscription.ZMIANA_KARTY_ZWROT_SRODKOW
          ? content.CART_LIST.onlyCardAuthDesc
          : content.CART_LIST.periodic
      }}"
      [maxWidthLimited]="false"
      [iconStyle]="'monochrome'"></app-info-box>
  </section>

  <app-choose-payment-method
    *ngIf="payment"
    payButtonText="{{ setHTMLpayButtonText() }}"
    titleText="{{ setHTMLPaymentTitle() }}"
    (submitPaymentMethod)="onSubmit($event)"
    [isPeriodic]="isPeriodic">
  </app-choose-payment-method>

  <app-loaders type="dots" [show]="initialLoaderEnabled"></app-loaders>
  <app-loaders fullScreen="true" [type]="'splash'" [show]="splashEnabled"></app-loaders>
  <app-loaders
    fullScreen="true"
    animation="fadeOut"
    [type]="'car'"
    [loadingText]="'LOADERS.PLEASE_WAIT' | translate"
    disableScroll="true"
    [show]="loaderEnabled"></app-loaders>

  <div *ngIf="errors.invalidStatus">
    <p class="no-exams-label">
      {{ 'EXTERNAL_PAYMENTS.ERRORS.IVALID_STATUS' | translate }}
    </p>
  </div>

  <div *ngIf="errors.invalidType">
    <p class="no-exams-label">
      {{ 'EXTERNAL_PAYMENTS.ERRORS.INVALID_TYPE' | translate }}
    </p>
  </div>

  <div *ngIf="errors.noResponse">
    <p class="no-exams-label">
      {{ 'EXTERNAL_PAYMENTS.ERRORS.NO_RESPONSE' | translate }}
    </p>
  </div>

  <section
    *ngIf="errors.noResponse || errors.invalidType || errors.invalidKey || errors.invalidStatus"
    class="redirect-section">
    <a href="{{ redirectUrl }}">
      <ic-ghost-button
        [width]="250"
        [type]="GhostButtonType.BIG_SECOND_RANK_FLEX"
        [typeHTML]="'button'"
        [arrowLeft]="true"
        [showArrow]="true"
        ><span innerHTML="{{ 'EXTERNAL_PAYMENTS.CONFIRMATION.btnBack' | translate }}"></span
      ></ic-ghost-button>
    </a>
    <p class="confirmation">
      {{ 'EXTERNAL_PAYMENTS.CONFIRMATION.redirectText' | translate }}
      <strong>{{ timeToRedirect }}</strong
      >s.
    </p>
  </section>
</main>

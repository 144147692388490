import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { GhostButtonType } from 'src/app/shared/components/ghost-button/ghost-button-type.enum';
import { EspPaymentService } from '../_services/esp-payment.service';
import { BillDto } from '../_models/esp-payment.model';
import { customErrorHandler } from 'src/app/shared/extensions/extensions';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-payment-confirmation',
  templateUrl: './payment-confirmation.component.html',
  styleUrls: ['./payment-confirmation.component.scss']
})
export class PaymentConfirmationComponent implements OnInit, OnDestroy {
  GhostButtonType: typeof GhostButtonType = GhostButtonType;
  status = 'accepted';
  content: any;
  redirectUrl: string;
  automaticRedirect: ReturnType<typeof setInterval>;
  sub: Subscription;
  timeToRedirect = 15;

  constructor(
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private espService: EspPaymentService,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.getPaymentStatusAndTranslationContent();
    this.goToLinkAutomatically();
  }

  getPaymentStatusAndTranslationContent() {
    this.espService.getPaymentStatus(this.route.snapshot.paramMap.get('id')).subscribe({
      next: (bill: BillDto) => {
        this.status = bill.paymentStatus;
        this.redirectUrl = bill.redirectUrl;
        this.translate
          .get(`EXTERNAL_PAYMENTS.CONFIRMATION.${this.status.toUpperCase()}`)
          .subscribe({
            next: (content) => (this.content = content)
          });
      },
      error: (error) => {
        customErrorHandler(error, this.toastrService, this.translate);
      }
    });
  }

  goToLinkAutomatically() {
    this.automaticRedirect = setInterval(() => {
      if (this.timeToRedirect > 0) {
        this.timeToRedirect--;
      } else {
        clearInterval(this.automaticRedirect);
        this.goToLink();
      }
    }, 1000);
  }

  goToLink() {
    const link = this.redirectUrl.slice(0, 1);

    if (link === '/') {
      this.router.navigateByUrl(this.redirectUrl);
    } else {
      window.location.href = this.redirectUrl;
    }
  }

  ngOnDestroy() {
    clearTimeout(this.automaticRedirect);
  }
}

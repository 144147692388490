import { TranslateService } from '@ngx-translate/core';
import { BaseModalDirective } from 'src/app/shared/components/modal/base-modal';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CropperImageInfoModel } from './model/cropped-image-info-model.model';

@Component({
  selector: 'app-cropped-image-info-modal',
  templateUrl: './cropped-image-info-modal.component.html',
  styleUrls: ['./cropped-image-info-modal.component.scss']
})
export class CroppedImageInfoModalComponent extends BaseModalDirective implements OnInit {
  pageTexts: CropperImageInfoModel;
  sub: Subscription;

  constructor(private translateService: TranslateService) {
    super();
  }

  ngOnInit() {
    this.sub = this.translateService
      .get('STC.ATTACHMENTS.INSTRUCTION_MODAL')
      .subscribe((res: any) => {
        this.pageTexts = res;
      });
  }

  onClose() {
    this.close(null);
  }
}

import {Extensions, setCookieWithHours} from 'src/app/shared/extensions/extensions';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { User } from '../model/user.model';
import { AuthErrorLoggerService } from './auth-error-logger.service';
import { authConfig } from './auth.config';
import { localStorageLogoutSync, localStorageLoginSync } from './auth.const';
import { v4 as uuidv4 } from 'uuid';
import { jwtDecode } from "jwt-decode";

@Injectable()
export class AuthHelperService {

  constructor(
    private oauth: OAuthService,
    private router: Router,
    private storage: OAuthStorage,
    private errorLogger: AuthErrorLoggerService,
  ) {}

  public configure() {
    this.oauth.configure(authConfig);
    this.oauth.setupAutomaticSilentRefresh();
  }

  public autoLogin(): Promise<User> {
    return this.oauth
      .loadDiscoveryDocumentAndTryLogin()
      .then((loggedIn) => this.resolveLoginAttempt(loggedIn))
      .then((loggedIn) => this.resolveUserProfile(loggedIn))
      .then(
        () => this.finishLogin(),
        () => this.loginError()
      );
  }

  public refreshUser(): Promise<User> {
    return this.resolveSilentRefresh()
      .then((loggedIn) => this.resolveUserProfile(loggedIn))
      .then(
        () => this.finishRefresh(),
        () => this.loginError()
      );
  }

  public tryLogin(url?: string) {
    this.oauth.resetImplicitFlow();
    const redirectUrl = url || '/';
    this.pushRedirectUrl(redirectUrl);
    this.oauth.initImplicitFlow();
  }

  public logout() {
    localStorage.setItem(localStorageLogoutSync.key, localStorageLogoutSync.value);
    localStorage.removeItem(localStorageLogoutSync.key);
    this._logout();
  }

  public syncLogout() {
    this._logout();
  }

  public setAnonymousToken(): void {
    if (!Extensions.getCookie('anonymousToken') && !sessionStorage.getItem('anonymousToken')) {
      setCookieWithHours(`anonymousToken=${uuidv4()};`, 4);
      setCookieWithHours('editableApplication=true;', 4);
    }
  }

  syncLogin() {
    window.location.reload();
  }

  private resolveLoginAttempt(loggedIn: boolean): Promise<boolean> {
    if (loggedIn) {
      return Promise.resolve(true);
    }
    return this.resolveSilentRefresh();
  }

  private resolveSilentRefresh(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.oauth.silentRefresh().then(
        () => resolve(true),
        (event) => {
          if (
            event.type === 'silent_refresh_error' &&
            event.reason &&
            event.reason.params.error === 'login_required'
          ) {
            resolve(false);
            return;
          }
          reject(false);
        }
      );
    });
  }

  private resolveUserProfile(loggedIn: boolean): Promise<boolean> {
    if (!loggedIn) {
      return Promise.resolve(false);
    }

    return new Promise((resolve, reject) => {
      try {
        this.oauth.loadUserProfile().then(
          () => {
            resolve(true);
          },
          () => {
            reject(false);
          }
        );
      } catch (e) {
        reject(false);
      }
    });
  }

  private loginError(): Promise<any> {
    this.errorLogger.logError();
    this.router.navigateByUrl('/');
    return Promise.reject();
  }

  private redirectAfterLogin() {
    const url = this.popRedirectUrl();
    if (url !== null)
      setTimeout(() => this.router.navigateByUrl(String(url), { replaceUrl: true }), 0);
  }

  private finishLogin(): Promise<User> {
    let user: User = null;
    if (this.oauth.hasValidIdToken()) {
      user = this.mapUser();
      this.initLoginSync(user);
      this.redirectAfterLogin();
    }
    return Promise.resolve(user);
  }

  private initLoginSync(user: User) {
    localStorage.setItem(localStorageLoginSync.key, user.uuid);
    setTimeout(() => localStorage.removeItem(localStorageLoginSync.key), 500);
  }

  private finishRefresh(): Promise<User> {
    let user: User = null;
    if (this.oauth.hasValidIdToken()) {
      user = this.mapUser();
    }
    return Promise.resolve(user);
  }

  private mapUser(): User {
    const { authorities, hasAcc } = (jwtDecode(this.oauth.getAccessToken()) as any);
    const { email, name, sub } = JSON.parse(JSON.stringify(this.oauth.getIdentityClaims()));
    return {
      hasAcc: hasAcc,
      email: email,
      firstname: name,
      lastname: '',
      uuid: sub,
      roles: [...authorities]
    };
  }

  private _logout() {
    this.oauth.logOut();
  }

  private pushRedirectUrl(redirectUrl: string) {
    this.storage.setItem('url', redirectUrl);
  }

  private popRedirectUrl(): string {
    const url = this.storage.getItem('url');
    this.storage.removeItem('url');
    return url;
  }
}

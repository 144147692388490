import { Component, OnInit, Input } from '@angular/core';
import { BackButtonService } from './back-button.service';
import { GhostButtonType } from '../../ghost-button/ghost-button-type.enum';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss']
})
export class BackButtonComponent implements OnInit {
  @Input()
  showWarning = false;
  @Input()
  label = 'Wstecz';
  @Input()
  formPrevPage = '';
  @Input()
  formOriginPage = '';
  @Input()
  disabled = false;
  GhostButtonType: typeof GhostButtonType = GhostButtonType;

  constructor(private backButtonService: BackButtonService) {}

  ngOnInit() {}

  navigateBack() {
    this.backButtonService.tryGoToPrevAppPage(this.formPrevPage, this.formOriginPage);
  }
}

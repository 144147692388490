import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AttachmentsApiConfig } from 'src/app/applications/stc/abstract-application-steps/attachments/attachments-api-config';
import { STC_V1 } from 'src/app/shared/consts/api-version.config';
import { environment as env } from 'src/environments/environment';
import { AttachmentDtoList } from '../../../../models/attachments/attachment-dto-list.model';
import { AttachmentDto } from '../../../../models/attachments/attachment-dto.model';
import { DriverCardApplicationDto } from '../../../../models/driver-card-application/driver-card-application.model';
import { ApplicationAttachmentType } from '../model';
import { AttachmentMetadataList } from '../model/attachment-metadata-list';

const PROCEED_ATTACHMENTS = 'proceed-attachments';

@Injectable()
export class AttachmentsApiService {
  private readonly header = new HttpHeaders().set('Content-Type', STC_V1);

  constructor(private http: HttpClient, private apiConfig: AttachmentsApiConfig) {}

  getAttachmentsBy(
    applicationId: string,
    applicationType: ApplicationAttachmentType
  ): Observable<AttachmentDtoList> {
    // return this.http.get<AttachmentDtoList>(`${env.BACKEND_API.BASE}${env.BACKEND_API.APPLICATIONS}/${applicationId}${env.BACKEND_API.ATTACHMENTS}?filter=withoutContent`);
    if (applicationType === 'APPLICATION') {
      return this.http.get<AttachmentDtoList>(
        `${env.BACKEND_API.BASE}${env.BACKEND_API.APPLICATIONS}` +
          `/${applicationId}${env.BACKEND_API.ATTACHMENTS}`
      );
    } else {
      return this.http.get<AttachmentDtoList>(
        `${env.BACKEND_API.BASE}${env.BACKEND_API.CORRECTIONS}` +
          `/${this.apiConfig.cardType}/${applicationId}${env.BACKEND_API.ATTACHMENTS}`
      );
    }
  }

  getRequiredAttachmentsListBy(
    applicationId: string,
    applicationType: ApplicationAttachmentType,
    cardType?: string
  ): Observable<AttachmentMetadataList> {
    if (applicationType === 'APPLICATION') {
      return this.http.get<AttachmentMetadataList>(
        `${env.BACKEND_API.BASE}${env.BACKEND_API.APPLICATIONS}/${cardType || this.apiConfig.cardType }` +
          `/${applicationId}${env.BACKEND_API.ATTACHMENTS}` 
      );
    } else {
      return this.http.get<AttachmentMetadataList>(
        `${env.BACKEND_API.BASE}${env.BACKEND_API.CORRECTIONS}/${cardType || this.apiConfig.cardType}` +
          `/${applicationId}${env.BACKEND_API.REQUIRED_ATTACHMENTS}`
      );
    }
  }
  postAttachment(
    attachment: AttachmentDto,
    applicationType: ApplicationAttachmentType
  ): Observable<HttpEvent<any>> {
    if (applicationType === 'APPLICATION') {
      return this.http.post<HttpEvent<any>>(
        `${env.BACKEND_API.BASE}${env.BACKEND_API.APPLICATIONS}/${this.apiConfig.cardType}` +
          `/${attachment.applicationId}${env.BACKEND_API.ATTACHMENTS}`,
        attachment,
        { headers: this.header, reportProgress: true, observe: 'events' }
      );
    } else {
      return this.http.post<HttpEvent<any>>(
        `${env.BACKEND_API.BASE}${env.BACKEND_API.CORRECTIONS}/${this.apiConfig.cardType}` +
          `/${attachment.correctionId}${env.BACKEND_API.ATTACHMENTS}`,
        attachment,
        { headers: this.header, reportProgress: true, observe: 'events' }
      );
    }
  }
  deleteAttachmentBy(
    applicationId: string,
    attachmentId: string,
    applicationType: ApplicationAttachmentType
  ) {
    if (applicationType === 'APPLICATION') {
      return this.http.delete(
        `${env.BACKEND_API.BASE}${env.BACKEND_API.APPLICATIONS}` +
          `/${applicationId}${env.BACKEND_API.ATTACHMENTS}/${attachmentId}`
      );
    } else {
      return this.http.delete(
        `${env.BACKEND_API.BASE}${env.BACKEND_API.CORRECTIONS}/${this.apiConfig.cardType}` +
          `/${applicationId}${env.BACKEND_API.ATTACHMENTS}/${attachmentId}`
      );
    }
  }
  putProceedAttachmentsStatusBy(
    applicationId: string,
    applicationType: ApplicationAttachmentType
  ): Observable<DriverCardApplicationDto> {
    if (applicationType === 'APPLICATION') {
      return this.http.put<DriverCardApplicationDto>(
        `${env.BACKEND_API.BASE}${env.BACKEND_API.APPLICATIONS}/${this.apiConfig.cardTypeUrl}` +
          `/${applicationId}/${PROCEED_ATTACHMENTS}`,
        {},
        { headers: this.header }
      );
    } else {
      return this.http.put<DriverCardApplicationDto>(
        `${env.BACKEND_API.BASE}${env.BACKEND_API.CORRECTIONS}/${this.apiConfig.cardTypeUrl}` +
          `/${applicationId}/${PROCEED_ATTACHMENTS}`,
        {},
        { headers: this.header }
      );
    }
  }
}

import { Action } from '@ngrx/store';

export interface EmployerTypeAction extends Action {
  type: EmployerTypeActionTypes;
}

export enum EmployerTypeActionTypes {
  SET_TRANSPORT_COMPANY = '[Employer Type] Set As Transport Company',
  SET_WORKSHOP = '[Employer Type] Set As Workshop',
  SET_CONTROL_AUTHORITY = '[Employer Type] Set As Control Authority',
  SET_INITIAL_STATE = '[Employer Type] Set Initial State'
}

export class SetAsTransportCompanyAction implements EmployerTypeAction {
  readonly type = EmployerTypeActionTypes.SET_TRANSPORT_COMPANY;
}

export class SetAsWorkshopAction implements EmployerTypeAction {
  readonly type = EmployerTypeActionTypes.SET_WORKSHOP;
}

export class SetAsControlAuthorityAction implements EmployerTypeAction {
  readonly type = EmployerTypeActionTypes.SET_CONTROL_AUTHORITY;
}

export class SetInitialStateAction implements EmployerTypeAction {
  readonly type = EmployerTypeActionTypes.SET_INITIAL_STATE;
}

import { AppVersionChangedModalComponent } from './components/modal/app-version-changed-modal/app-version-changed-modal.component';
import { CheckingStcStatusResultComponent } from './components/stc/checking-stc-status-result/checking-stc-status-result.component';
import {SelectableInputComponent} from './selectable-input/selectable-input.component';
import {ExternalCartListComponent} from './components/external-cart-list/external-cart-list.component';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {NgbAccordionModule, NgbDatepickerModule} from '@ng-bootstrap/ng-bootstrap';
import {StoreModule} from '@ngrx/store';
import {TranslateModule} from '@ngx-translate/core';
import {AngularCropperjsModule} from 'angular-cropperjs';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {ClickOutsideModule} from 'ng-click-outside';
import {DragScrollComponent} from 'ngx-drag-scroll';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {ToastrModule} from 'ngx-toastr';
import {LoadersModule} from '../layout/loaders/loaders.module';
import {AcceptancesChecboxesComponent} from './components/acceptances-checboxes/acceptances-checboxes.component';
import {BreadcrumbsComponent} from './components/breadcrumbs/breadcrumbs.component';
import {
  CustomerServiceOfficeInfoBoxComponent
} from './components/customer-service-office-info-box/customer-service-office-info-box.component';
import {
  FirstTimeAutosaveToastrComponent
} from './components/first-time-autosave-toastr/first-time-autosave-toastr.component';
import {BackButtonComponent} from './components/forms/back-button/back-button.component';
import {FormCheckboxComponent} from './components/forms/form-checkbox/form-checkbox.component';
import {FormInputComponent} from './components/forms/form-input/form-input.component';
import {RadioButtonPlaceholderComponent} from './components/forms/radio-button/radio-button-placeholder.component';
import {RadioButtonComponent} from './components/forms/radio-button/radio-button.component';
import {TextInputPlaceholderComponent} from './components/forms/text-input/text-input-placeholder.component';
import {SimpleTextInputComponent} from './components/forms/text-input/text-input.component';
import {GhostButtonComponent} from './components/ghost-button/ghost-button.component';
import {ImageEditButtonComponent} from './components/image-edit-button/image-edit-button.component';
import {InfoBoxComponent} from './components/info-box/info-box.component';
import {LegalInfoBoxComponent} from './components/legal-info-box/legal-info-box.component';
import {AcceptDateModalComponent} from './components/modal/accept-date-modal/accept-date-modal.component';
import {
  ActionConfirmationModalComponent
} from './components/modal/action-confirmation-modal/action-confirmation-modal.component';
import {ChooseEmployeeModalComponent} from './components/modal/choose-employee-modal/choose-employee-modal.component';
import {
  CroppedImageInfoModalComponent
} from './components/modal/cropped-image-info-modal/cropped-image-info-modal.component';
import {CroppedImageModalComponent} from './components/modal/cropped-image-modal/cropped-image-modal.component';
import {
  DefaultConfirmationModalComponent
} from './components/modal/default-confirmation-modal/default-confirmation-modal.component';
import {DeleteAccountModalComponent} from './components/modal/delete-account-modal/delete-account-modal.component';
import {DeleteEmployeeModalComponent} from './components/modal/delete-employee-modal/delete-employee-modal.component';
import {
  DraftAlreadyExistsModalComponent
} from './components/modal/draft-already-exists-modal/draft-already-exists-modal.component';
import {
  DrivingLicenceDescriptionModalComponent
} from './components/modal/driving-licence-description-modal/driving-licence-description-modal.component';
import {
  EmployeeDataChangeModalComponent
} from './components/modal/employee-data-change-modal/employee-data-change-modal/employee-data-change-modal.component';
import {InsertionDirective} from './components/modal/insertion.directive';
import {ModalComponent} from './components/modal/modal.component';
import {SearchModalComponent} from './components/modal/search-modal/search-modal.component';
import {SignMethodModalComponent} from './components/modal/sign-method-modal/sign-method-modal.component';
import {
  TextInformationModalComponent
} from './components/modal/text-information-modal/text-information-modal.component';
import {
  UnavailableServiceForBusinessModalComponent
} from './components/modal/unavailable-service-for-business-modal/unavailable-service-for-business-modal.component';
import {WizardStepBackModalComponent} from './components/modal/wizard-step-back-modal/wizard-step-back-modal.component';
import {
  WordReservationTimedOutModalComponent
} from './components/modal/word-reservation-timed-out-modal/word-reservation-timed-out-modal.component';
import {
  WordSummaryLoaderModalComponent
} from './components/modal/word-summary-loader-modal/word-summary-loader-modal.component';
import {MostPopularServicesComponent} from './components/most-popular-services/most-popular-services.component';
import {
  PaginationLimitChooserComponent
} from './components/pagination-limit-chooser/pagination-limit-chooser.component';
import {PaginationComponent} from './components/pagination/pagination.component';
import {
  PasswordRequirementHintComponent
} from './components/password-requirement-hint/password-requirement-hint.component';
import {RelatedServicesComponent} from './components/related-services/related-services.component';
import {ResponsiveCarouselComponent} from './components/responsive-carousel/responsive-carousel.component';
import {SearchBarComponent} from './components/search-bar/search-bar.component';
import {ServiceLinkComponent} from './components/service-link/service-link.component';
import {SocialButtonComponent} from './components/social-button/social-button.component';
import {StatementComponent} from './components/statement/statement.component';
import {AddressDataComponent} from './components/stc/address-data/address-data.component';
import {AttachmentsPaperComponent} from './components/stc/attachments-paper/attachments-paper.component';
import {AttachmentComponent} from './components/stc/attachments/attachment/attachment.component';
import {
  AttachmentsGroupPlaceholderComponent
} from './components/stc/attachments/attachments-group-placeholder/attachments-group-placeholder.component';
import {AttachmentsInputComponent} from './components/stc/attachments/attachments-input/attachments-input.component';
import {
  AttachmentsTypeGroupComponent
} from './components/stc/attachments/attachments-type-group/attachments-type-group.component';
import {AttachmentsComponent} from './components/stc/attachments/attachments.component';
import {CompanyCardNumberComponent} from './components/stc/company-card-number/company-card-number.component';
import {CompanyDataComponent} from './components/stc/company-data/company-data.component';
import {ControlCardNumberComponent} from './components/stc/control-card-number/control-card-number.component';
import {DriverCardNumberComponent} from './components/stc/driver-card-number/driver-card-number.component';
import {DrivingLicenceDataComponent} from './components/stc/driving-licence-data/driving-licence-data.component';
import {PersonDataComponent} from './components/stc/person-data/person-data.component';
import {RefundFormDataComponent} from './components/stc/refund-form-data/refund-form-data.component';
import {WorkshopCardNumberComponent} from './components/stc/workshop-card-number/workshop-card-number.component';
import {StepperComponent} from './components/stepper/stepper.component';
import {TeaserBannerComponent} from './components/teaser-banner/teaser-banner.component';
import {TwoWayActionBarComponent} from './components/two-way-action-bar/two-way-action-bar.component';
import {ExamDaysAccordionComponent} from './components/word-exam-calendar/accordion/exam-days-accordion';
import {DatePickerComponent} from './components/word-exam-calendar/date-picker/date-picker.component';
import {DateComponent} from './components/word-exam-calendar/date/date.component';
import {WordExamCalendarComponent} from './components/word-exam-calendar/word-exam-calendar.component';
import {DropZoneDirective} from './directives/drag-drop/drag-drop.directive';
import {ExternalLinkDirective} from './directives/external-link/external-link.directive';
import {IndicateDirective} from './directives/indicate/indicate.directive';
import {InternalLinkDirective} from './directives/internal-link/internal-link.directive';
import {PreventDoubleClickDirective} from './directives/prevent-double-click/prevent-double-click.directive';
import {TextEllipsisDirective} from './directives/text-ellipsis/text-ellipsis.directive';
import {TriggerClickDirective} from './directives/trigger-click/trigger-click.directive';
import {DestinationPipe} from './pipes/destination.pipe';
import {DisplayEmptyValueAsDashPipe} from './pipes/display-empty-value-as-dash.pipe';
import {EllipsisPipe} from './pipes/ellipsis.pipe';
import {ReplacePipe} from './pipes/replace.pipe';
import {SafeHtmlPipe} from './pipes/safe-html.pipe';
import * as fromShared from './store/shared.reducer';
import {PromotionalBoxComponent} from './components/promotional-box/promotional-box.component';
import {OsMapsComponent} from './components/os-maps/os-maps.component';
import {ChoosePaymentMethodComponent} from './components/choose-payment-method/choose-payment-method.component';
import {SelectableInputModule} from './selectable-input/selectable-input.module';
import {CategoryMenuComponent} from './components/category-menu/category-menu.component';
import {
  SignMethodWarningModalComponent
} from './components/modal/sign-method-warning-modal/sign-method-warning-modal.component';
import {AddressPipe} from './pipes/address-pipe/address.pipe';
import {TrainingCardInfoComponent} from './components/modal/training-card-info/training-card-info.component';
import {
  PaymentConfirmationListComponent
} from './components/pik/payment-confirmation-list/payment-confirmation-list.component';
import {ComplaintDataComponent} from './components/stc/complaint-data/complaint-data.component';
import {
  ReportAlreadySubmittedModalComponent
} from './components/modal/simple-nonblocking-information-modal/attestation-already-submitted-modal/report-already-submitted-modal.component';
import {
  ApplicationAlreadySubmittedModalComponent
} from './components/modal/simple-nonblocking-information-modal/application-already-submitted-modal/application-already-submitted-modal.component';
import {PlncurrencyPipe} from './pipes/currency/plncurrency.pipe';
import { AgreementsModalComponent } from './components/modal/agreements/agreements-modal.component';
import { ExistingPaperModalComponent } from './components/modal/existing-paper-modal/existing-paper-modal.component';
import { CreatedPaperApplicationToastComponent } from './components/created-paper-application-toast/created-paper-application-toast.component';
import { ShouldKeepAnonymousApplicationModalComponent } from './components/modal/should-keep-anonymous-application-modal/should-keep-anonymous-application-modal.component';
import { ApplicationVerificationModalComponent } from './components/modal/application-verification-modal/application-verification-modal.component';
import { CardLossInformationModalComponent } from './components/modal/card-loss-information-modal/card-loss-information-modal.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { TextMaskModule } from '@matheo/text-mask';
import { AutopayTermsComponent } from './components/autopay-terms/autopay-terms.component';

@NgModule({
  declarations: [
    DrivingLicenceDescriptionModalComponent,
    DeleteEmployeeModalComponent,
    ChooseEmployeeModalComponent,
    SearchModalComponent,
    SearchBarComponent,
    ModalComponent,
    InsertionDirective,
    GhostButtonComponent,
    ServiceLinkComponent,
    SocialButtonComponent,
    AcceptDateModalComponent,
    StepperComponent,
    ChooseEmployeeModalComponent,
    SignMethodModalComponent,
    DefaultConfirmationModalComponent,
    IndicateDirective,
    DestinationPipe,
    EllipsisPipe,
    FormInputComponent,
    BackButtonComponent,
    BreadcrumbsComponent,
    ReplacePipe,
    FormCheckboxComponent,
    WizardStepBackModalComponent,
    DisplayEmptyValueAsDashPipe,
    PersonDataComponent,
    AddressDataComponent,
    DrivingLicenceDataComponent,
    CompanyDataComponent,
    DriverCardNumberComponent,
    RefundFormDataComponent,
    WorkshopCardNumberComponent,
    ControlCardNumberComponent,
    AttachmentsComponent,
    AttachmentsInputComponent,
    AttachmentComponent,
    StatementComponent,
    TwoWayActionBarComponent,
    AttachmentsTypeGroupComponent,
    AttachmentsGroupPlaceholderComponent,
    AttachmentsTypeGroupComponent,
    CompanyCardNumberComponent,
    AttachmentsPaperComponent,
    InfoBoxComponent,
    UnavailableServiceForBusinessModalComponent,
    MostPopularServicesComponent,
    DraftAlreadyExistsModalComponent,
    ApplicationAlreadySubmittedModalComponent,
    ReportAlreadySubmittedModalComponent,
    RelatedServicesComponent,
    ExternalLinkDirective,
    LegalInfoBoxComponent,
    MostPopularServicesComponent,
    TeaserBannerComponent,
    CustomerServiceOfficeInfoBoxComponent,
    AcceptancesChecboxesComponent,
    TextInformationModalComponent,
    DeleteAccountModalComponent,
    RadioButtonComponent,
    SimpleTextInputComponent,
    TextInputPlaceholderComponent,
    RadioButtonPlaceholderComponent,
    PasswordRequirementHintComponent,
    FirstTimeAutosaveToastrComponent,
    PreventDoubleClickDirective,
    WordSummaryLoaderModalComponent,
    CroppedImageModalComponent,
    ImageEditButtonComponent,
    ResponsiveCarouselComponent,
    CroppedImageInfoModalComponent,
    WordReservationTimedOutModalComponent,
    EmployeeDataChangeModalComponent,
    SafeHtmlPipe,
    InternalLinkDirective,
    TextEllipsisDirective,
    TriggerClickDirective,
    PaginationComponent,
    PaginationLimitChooserComponent,
    DropZoneDirective,
    ActionConfirmationModalComponent,
    WordExamCalendarComponent,
    ExamDaysAccordionComponent,
    DatePickerComponent,
    DateComponent,
    PromotionalBoxComponent,
    OsMapsComponent,
    ChoosePaymentMethodComponent,
    ExternalCartListComponent,
    CategoryMenuComponent,
    SignMethodWarningModalComponent,
    AddressPipe,
    TrainingCardInfoComponent,
    PaymentConfirmationListComponent,
    ComplaintDataComponent,
    PlncurrencyPipe,
    AgreementsModalComponent,
    ExistingPaperModalComponent,
    CreatedPaperApplicationToastComponent,
    ShouldKeepAnonymousApplicationModalComponent,
    ApplicationVerificationModalComponent,
    CardLossInformationModalComponent,
    CheckingStcStatusResultComponent,
    AppVersionChangedModalComponent,
    AutopayTermsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TextMaskModule,
    RouterModule,
    LoadersModule,
    TranslateModule,
    ClickOutsideModule,
    ReactiveFormsModule,
    AngularCropperjsModule,
    StoreModule.forFeature(fromShared.featureName, fromShared.sharedReducer),
    ToastrModule,
    AngularSvgIconModule,
    DragScrollComponent,
    NgbAccordionModule,
    NgbDatepickerModule,
    InfiniteScrollModule,
    SelectableInputModule,
    PdfViewerModule,
  ],
    exports: [
        PdfViewerModule,
        AutopayTermsComponent,
        DrivingLicenceDescriptionModalComponent,
        DefaultConfirmationModalComponent,
        DeleteEmployeeModalComponent,
        AcceptDateModalComponent,
        SearchModalComponent,
        SearchBarComponent,
        GhostButtonComponent,
        ServiceLinkComponent,
        SocialButtonComponent,
        StepperComponent,
        IndicateDirective,
        DestinationPipe,
        EllipsisPipe,
        ReplacePipe,
        FormInputComponent,
        BackButtonComponent,
        BreadcrumbsComponent,
        FormCheckboxComponent,
        WizardStepBackModalComponent,
        DisplayEmptyValueAsDashPipe,
        PersonDataComponent,
        AddressDataComponent,
        DrivingLicenceDataComponent,
        CompanyDataComponent,
        DriverCardNumberComponent,
        RefundFormDataComponent,
        WorkshopCardNumberComponent,
        ControlCardNumberComponent,
        LoadersModule,
        StatementComponent,
        TranslateModule,
        AttachmentsComponent,
        AttachmentsPaperComponent,
        TwoWayActionBarComponent,
        CompanyCardNumberComponent,
        InfoBoxComponent,
        UnavailableServiceForBusinessModalComponent,
        RelatedServicesComponent,
        LegalInfoBoxComponent,
        MostPopularServicesComponent,
        CustomerServiceOfficeInfoBoxComponent,
        DraftAlreadyExistsModalComponent,
        ApplicationAlreadySubmittedModalComponent,
        ReportAlreadySubmittedModalComponent,
        AcceptancesChecboxesComponent,
        TextInformationModalComponent,
        ExternalLinkDirective,
        RadioButtonComponent,
        SimpleTextInputComponent,
        TextInputPlaceholderComponent,
        RadioButtonPlaceholderComponent,
        PasswordRequirementHintComponent,
        FirstTimeAutosaveToastrComponent,
        PreventDoubleClickDirective,
        WordSummaryLoaderModalComponent,
        CroppedImageModalComponent,
        ResponsiveCarouselComponent,
        CroppedImageInfoModalComponent,
        WordReservationTimedOutModalComponent,
        EmployeeDataChangeModalComponent,
        SafeHtmlPipe,
        InternalLinkDirective,
        TextEllipsisDirective,
        TriggerClickDirective,
        PaginationComponent,
        PaginationLimitChooserComponent,
        WordExamCalendarComponent,
        PromotionalBoxComponent,
        OsMapsComponent,
        ChoosePaymentMethodComponent,
        ExternalCartListComponent,
        SelectableInputComponent,
        CategoryMenuComponent,
        AddressPipe,
        PaymentConfirmationListComponent,
        ComplaintDataComponent,
        PlncurrencyPipe,
        AgreementsModalComponent,
        CheckingStcStatusResultComponent,
        NgbAccordionModule,
        AppVersionChangedModalComponent
    ]
})
export class SharedModule {}

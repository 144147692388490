import { environment as env } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import {
  PayWithBlikResponse,
  PayWithRedirectResponse,
  EspPaymentDto,
  BillDto
} from '../_models/esp-payment.model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EspPaymentService {
  constructor(private _http: HttpClient) {}

  getEspPayment(id: string): Observable<EspPaymentDto> {
    return this._http.get<EspPaymentDto>(`${env.ESP.BASE}${env.ESP.PAYMENTS}/${id}`);
  }

  createPayment$(id: string): Observable<PayWithRedirectResponse> {
    return this._http.post<PayWithRedirectResponse>(
      `${env.ESP.BASE}${env.ESP.PAYMENTS}/${id}${env.ESP.REDIRECT}`,
      {}
    );
  }

  getPaymentStatus(billId): Observable<BillDto> {
    return this._http.get<BillDto>(`${env.ESP.BASE}${env.ESP.BILLS}/${billId}`);
  }

  createBlikPayment$(id: any, payload): Observable<PayWithBlikResponse> {
    return this._http.post<PayWithBlikResponse>(
      `${env.ESP.BASE}${env.ESP.PAYMENTS}/${id}${env.ESP.BLIK}`,
      payload
    );
  }
}

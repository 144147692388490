import { environment } from 'src/environments/environment';

const BASE_URL = environment.WP_API_HOST;

const TRANSLATION_SUFFIX = '/infocar/search-section';

export const SEARCH_CONFIG = {
  API: {
    TRANSLATION_ENDPOINT: `${BASE_URL}${TRANSLATION_SUFFIX}`
  },

  RESULTS_TO_SHOW: 5,
  ALL_RESULTS: undefined
};

import { TranslateService } from '@ngx-translate/core';
import { ApplicationBasicDto } from 'src/app/shared/models/applications/application-basic-dto.model';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StcApplicationsService } from 'src/app/applications/stc/_services/stc-applications.service';
import { ReasonDto } from 'src/app/shared/models/applications/reason-dto.model';
import { finalize } from 'rxjs/operators';
import { ApplicationDto } from 'src/app/shared/models/applications/application-dto.model';
import { AttachmentMetadata } from '../attachments/model';
import { ModalRef, MODAL_OVERLAY_CLICKED } from '../../modal/modal-ref';
import { CardLossInformationModalComponent } from '../../modal/card-loss-information-modal/card-loss-information-modal.component';
import { ModalService } from '../../modal/modal.service';

@Component({
  selector: 'app-attachments-paper',
  templateUrl: './attachments-paper.component.html',
  styleUrls: ['./attachments-paper.component.scss']
})
export class AttachmentsPaperComponent implements OnInit {
  @Input() displayTitle = true;
  @Input() isActionBar = false;
  @Input() isStatement = true;
  @Input() applicationId: string;
  @Input() complaintId: string;
  @Input() isPaper: boolean = false;
  @Input() cardType: string;
  @Input() infoBoxWidth: number;
  @Input() showInfoBox: boolean = true;
  @Input() showPaymentConfirmationAttachment: boolean = true;
  @Input() reason: ReasonDto.ReasonTypeEnum;
  @Output() back: EventEmitter<void> = new EventEmitter();
  @Output() submitted: EventEmitter<void> = new EventEmitter();
  @Output() paperAttachments = new EventEmitter();

  attachments: string[] = [];
  isAttachmentsRequired: boolean;
  cardName;
  ReasonEnum = ReasonDto.ReasonTypeEnum;
  loading: boolean;
  CardTypes = ApplicationBasicDto.TypeEnum;
  attachmentsList: AttachmentMetadata[];
  modalRef: ModalRef;

    constructor(private stcService: StcApplicationsService, private translate: TranslateService, private modalService: ModalService,) {}

  ngOnInit() {
    this.loading = true;
    this.stcService
      .getRequiredAttachmentsListForPaperBy(this.applicationId, this.cardType)
      .pipe(finalize(() => this.loading = false))
      .subscribe((x) => {
        if(x) {
          this.paperAttachments.emit(x);
        }
        this.attachments = x.attachments.map(({ type }) => type);
        this.attachmentsList = x.attachments;
        this.addRequirementForOldCards();
        this.isAttachmentsRequired = this.attachments.length !== 0;
      });
  }

  private addRequirementForOldCards() {
    switch (this.cardType) {
      case ApplicationBasicDto.TypeEnum.WORKSHOP_CARD: {
        this.cardName = this.translate.instant(
          'STC.PAPER_ATTACHMENTS.PREVIOUS_CARD.LABEL_WORKSHOP'
        );
        break;
      }
      case ApplicationBasicDto.TypeEnum.DRIVER_CARD: {
        this.cardName = this.translate.instant('STC.PAPER_ATTACHMENTS.PREVIOUS_CARD.LABEL_DRIVER');
        break;
      }
      case ApplicationBasicDto.TypeEnum.TEMPORARY_DRIVER_CARD: {
        this.cardName = this.translate.instant('STC.PAPER_ATTACHMENTS.PREVIOUS_CARD.LABEL_TEMPORARY_DRIVER');
        break;
      }
      case ApplicationBasicDto.TypeEnum.COMPANY_CARD: {
        this.cardName = this.translate.instant('STC.PAPER_ATTACHMENTS.PREVIOUS_CARD.LABEL_COMPANY');
        break;
      }
      case ApplicationBasicDto.TypeEnum.CONTROL_CARD: {
        this.cardName = this.translate.instant('STC.PAPER_ATTACHMENTS.PREVIOUS_CARD.LABEL_CONTROL');
        break;
      }
      default: {
        this.cardName = 'karta';
        break;
      }
    }
  }
  checkIfAttachmentIsRequired(attachment: string): boolean {
    return this.attachments.includes(attachment);
  }

  attachementHasRequiredAttr(attachment: string): boolean {
    return this.attachmentsList.find(el => el.type === attachment)?.required
  }

  onBackClicked = () => {
    this.back.emit();
  }

  shouldShowInfoBox(): boolean {
    return this.reason === ApplicationDto.ReasonTypeEnum.CARDLOSS || this.reason === ApplicationDto.ReasonTypeEnum.FIRSTISSUELOSTFOREIGNCARD
  }

  submitForm(): void {
    this.stcService
      .putProceedAttachmentsStatusBy(this.applicationId, this.cardType, this.isPaper)
      .subscribe(() => this.submitted.emit());
  }

  onSubmit() {
     if(this.reason === ApplicationDto.ReasonTypeEnum.FIRSTISSUELOSTFOREIGNCARD) {
      this.modalRef = this.modalService.open(CardLossInformationModalComponent);
      const sub = this.modalRef.afterClosed.subscribe((value) => {
        if(value === MODAL_OVERLAY_CLICKED || value === null) {
          return;
        }

        if(value === 'NEXT') {
          this.submitForm();
        }

        sub.unsubscribe();
        this.modalRef = null;
      });
    } else {
      this.submitForm();
    }
  }
}

export enum OskLicenseProductPeriod {
  DAYS_30 = '30',
  DAYS_90 = '90',
  DAYS_180 = '180'
}

export enum OskPaymentType {
  WORD = 'word',
  LICENCE = 'licencja'
}

export enum OskLicenceType {
  EXAM = 'Egzamin', // to moze wylecieć - dogadac temat
  SMALL_OSK = 'MaleOSK',
  MEDIUM_OSK = 'SrednieOSK',
  BIG_OSK = 'DuzeOSK',
  OSK = 'OSK'
}

export interface PoskPaymentQueryParams {
  id: string;
  type: OskPaymentType;
  error: string;
}

export enum PoskPaymentStatus {
  REGISTERED = 'REGISTERED',
  INITIALIZING_PAYMENT_PROCESS = 'INITIALIZING_PAYMENT_PROCESS',
  SEND = 'SEND',
  REJECTED = 'REJECTED',
  ACCEPTED = 'ACCEPTED',
  ERROR = 'ERROR'
}

export enum PoskTypeSubscription {
  REJESTRACJA_PLATNOSCI = 'REJESTRACJA_PLATNOSCI',
  ZMIANA_KARTY_ZWROT_SRODKOW = 'ZMIANA_KARTY_ZWROT_SRODKOW',
  ZMIANA_KARTY_PLATNOSC_ZA_LICENCJE = 'ZMIANA_KARTY_PLATNOSC_ZA_LICENCJE'
}

import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { SocialButtonType } from './social-button-type.enum';

@Component({
  selector: 'ic-social-button',
  templateUrl: './social-button.component.html',
  styleUrls: ['./social-button.component.scss']
})
export class SocialButtonComponent implements OnInit {
  @Input() type: SocialButtonType;
  @Input() iconSrc = '';
  @Input() iconAlt = '';
  @Input() label = '';

  constructor(private selfReference: ElementRef) {}

  ngOnInit() {
    const selfDOM = this.selfReference.nativeElement.querySelector('button');

    switch (this.type) {
      case SocialButtonType.FACEBOOK:
        selfDOM.classList.add('facebook');
        break;
      case SocialButtonType.GOOGLE:
        selfDOM.classList.add('google');
        break;
      default: {
      }
    }
  }
}

import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { DragScrollComponent } from 'ngx-drag-scroll';
import { ResponsiveCarouselItem } from './model/responsive-carousel-item.model';

@Component({
  selector: 'app-responsive-carousel',
  templateUrl: './responsive-carousel.component.html',
  styleUrls: ['./responsive-carousel.component.scss']
})
export class ResponsiveCarouselComponent implements OnInit, AfterViewInit {
  @ViewChild('ds', { read: ElementRef, static: false }) dsView: ElementRef;
  @ViewChild('ds', { read: DragScrollComponent, static: false })
  ds: DragScrollComponent;
  @ViewChild('dots', { read: ElementRef, static: false }) dots: ElementRef;
  @ViewChildren('viewItem', { read: ElementRef })
  viewItems: QueryList<ElementRef>;
  @Input() addMarginRight = true;
  @Input() parentMargin: any;
  @Input() items: ResponsiveCarouselItem[];
  isMobile: boolean;
  numberOfDots: number;
  activeDotIndex = 0;
  imagesLoaded = 0;
  Array = Array;
  breakpoints = [0];

  constructor(private selfRef: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.onResize();
  }

  initDots() {
    const containerWidth = this.dsView?.nativeElement?.getBoundingClientRect()?.width;
    let counter = 1;
    let sum = 0;
    let itemWidth;
    this.viewItems.forEach((item: ElementRef, index) => {
      itemWidth = item.nativeElement.clientWidth;
      if (sum + itemWidth > containerWidth) {
        counter++;
        this.breakpoints.push(index);
        sum = itemWidth;
      } else {
        sum += itemWidth;
      }
    });
    this.numberOfDots = counter;
    this.breakpoints.push(this.viewItems.length);
  }

  imageLoaded() {
    this.imagesLoaded++;
    if (this.imagesLoaded >= this.items.length) {
      this.initDots();
    }
  }

  indexChanged(index) {
    this.activeDotIndex = this.breakpoints.findIndex((breakpoint) => index < breakpoint) - 1;
  }

  ngAfterViewInit() {
    if (this.addMarginRight && this.isMobile) {
      this.addPaddingToElem();
    }
  }

  private removePaddingFromElem() {
    this.selfRef.nativeElement.parentElement.style.marginLeft = 0;
  }

  private addPaddingToElem() {
    if(this.viewItems.last && this.dsView && this.dots && this.selfRef) {
      this.viewItems.last.nativeElement.style.marginRight = 0;
      this.dsView.nativeElement.children[0].style.paddingRight = this.parentMargin;
      this.dots.nativeElement.style.paddingRight = this.parentMargin;
      this.selfRef.nativeElement.parentElement.style.marginLeft = this.parentMargin;
    }
  }

  onDotClick(index) {
    this.ds.moveTo(this.breakpoints[index]);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    if (window.outerWidth <= 576) {
      this.isMobile = true;
      this.activeDotIndex = 0;
      if (this.addMarginRight) {
        setTimeout(() => {
          this.addPaddingToElem();
        }, 0);
      }
    } else {
      this.isMobile = false;
      if (this.addMarginRight) {
        setTimeout(() => {
          this.removePaddingFromElem();
        }, 0);
      }
    }
  }
}

import { Action } from '@ngrx/store';

import { CountedApiResponseModel } from './../../shared/models/search/counted-api-response.model';
import { Service } from './../../shared/models/search/service.model';

export interface SearchAction extends Action {
  type: SearchActionTypes;
  query?: string;
  result?: CountedApiResponseModel<Service>;
}

export enum SearchActionTypes {
  SET_RESULTS = '[SearchServicesCache] Set',
  LOADING = '[SearchServicesCache] Loading'
}

export namespace SearchActions {
  export class SetResultsSearchAction implements SearchAction {
    readonly type = SearchActionTypes.SET_RESULTS;
    constructor(public result: CountedApiResponseModel<Service>) {}
  }

  export class LoadingResultsSearchAction implements SearchAction {
    readonly type = SearchActionTypes.LOADING;
    constructor(public query: string) {}
  }
}

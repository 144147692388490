export interface FormOfRefundDto {
  bankAccountNumber?: string;
  refundType: FormOfRefundDto.RefundTypeEnum;
}
export namespace FormOfRefundDto {
  export type RefundTypeEnum =
    | 'BANK_TRANSFER'
    | 'MONEY_ORDER_RESIDENCE_ADDRESS'
    | 'MONEY_ORDER_MAILING_ADDRESS'
    | 'MONEY_ORDER_WORKSHOP_ADDRESS';
  export const RefundTypeEnum = {
    BANKTRANSFER: 'BANK_TRANSFER' as RefundTypeEnum,
    MONEYORDERRESIDENCEADDRESS: 'MONEY_ORDER_RESIDENCE_ADDRESS' as RefundTypeEnum,
    MONEYORDERMAILINGADDRESS: 'MONEY_ORDER_MAILING_ADDRESS' as RefundTypeEnum,
    MONEYORDERWORKSHOPADDRESS: 'MONEY_ORDER_WORKSHOP_ADDRESS' as RefundTypeEnum
  };
}

import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnInit,
  Output,
  Renderer2
} from '@angular/core';
import { CheckboxControlValueAccessor, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormInputError } from '../_model/form-input.model';

@Component({
  selector: 'app-form-checkbox',
  templateUrl: './form-checkbox.component.html',
  styleUrls: ['./form-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormCheckboxComponent),
      multi: true
    }
  ]
})
export class FormCheckboxComponent extends CheckboxControlValueAccessor implements OnInit {
  @HostBinding('class.host-bordered-group') get hostBorderedGroup() {
    return this.styleType === 'bordered';
  }

  @Input() inputId: string;
  @Input() label: string;
  @Input() sublabel: string;
  @Input() button: string;
  @Input() isRequired: Boolean;
  @Input() bolded: Boolean;
  @Input() formControlName: string;
  @Input() formGroup: UntypedFormGroup;
  @Input() errorList: FormInputError[];
  @Input() isErrorContainer = true;
  @Input() isRequiredAfterButton: boolean;
  @Input() forceInvalid = false;
  @Input() styleType: 'default' | 'bordered' | 'toggle' = 'default';
  @Input() checked: boolean;
  @Input() disabled = false;
  @Input() isReportedForCorrection: boolean;
  @Input() warnValue: any;

  @Output() openModalClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() valueChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  onChange: (value) => void;
  onTouched: () => void;

  writeValue(value: boolean): void {
    this.checked = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  forwardChanged(value: boolean) {
    // this.onChange(value);
    this.valueChanged.emit(value);
    this.onTouched();
  }

  openModal(event: Event) {
    event.preventDefault();
    this.openModalClicked.emit();
  }

  hasOneOfErrors(errors: string[]) {
    let invalid = false;
    errors.forEach((value) => {
      if (this.formGroup && this.formGroup.controls[this.formControlName].hasError(value)) {
        invalid = true;
      }
    });
    return this.formGroup && this.formGroup.controls[this.formControlName].touched && invalid;
  }

  constructor(_renderer: Renderer2, _elementRef: ElementRef) {
    super(_renderer, _elementRef);
  }

  ngOnInit() {
    if (this.formGroup) {
      this.formGroup.controls[this.formControlName].valueChanges.subscribe({
        next: (value) => {
          this.forwardChanged(value);
        }
      });
    }
  }

  showWarning(): boolean {
    return (
      !!this.warnValue &&
      this.isTouched() &&
      this.warnValue === this.formGroup.controls[this.formControlName].value
    );
  }

  isTouched() {
    return this.formGroup?.controls[this.formControlName].touched;
  }

  isValid() {
    return this.formGroup?.controls[this.formControlName].valid;
  }
}

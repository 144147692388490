import { Component, OnInit, Input, ElementRef, OnDestroy } from '@angular/core';
import {
  Router,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError
} from '@angular/router';
import { LoaderStripeService } from '../_service/loader-stripe.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-loader-stripe',
  templateUrl: './loader-stripe.component.html',
  styleUrls: ['./loader-stripe.component.scss']
})
export class LoaderStripeComponent implements OnInit, OnDestroy {
  public isOpened: boolean;
  private isOpenedFromRouter: boolean;
  private isOpenedNotFromRouter: boolean;
  private subscription: Subscription;

  constructor(
    private el: ElementRef,
    private router: Router,
    private loaderService: LoaderStripeService,
  ) {
    this.router.events.subscribe((routerEvent) => {
      this.onRouterEvent(routerEvent);
    });
  }

  ngOnInit() {
    this.subscription = this.loaderService.notifyIsOpened().subscribe((result) => {
      this.isOpenedNotFromRouter = result;
      this.updateIsOpened();
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onRouterEvent(event): void {
    if (event instanceof NavigationStart) {
      this.open();
    } else if (
      event instanceof NavigationEnd ||
      event instanceof NavigationCancel ||
      event instanceof NavigationError
    ) {
      this.close();
    }
  }

  open(): void {
    this.isOpenedFromRouter = true;
    this.updateIsOpened();
  }
  close(): void {
    this.isOpenedFromRouter = false;
    this.updateIsOpened();
  }

  private updateIsOpened() {
    this.isOpened = this.isOpenedFromRouter || this.isOpenedNotFromRouter;
  }
}

<div class="container">
  <ng-container *ngFor="let step of steps; let i = index">
    <div
      class="step"
      appTriggerClick
      [ngClass]="{ active: i == currentStepIndex, done: i < currentStepIndex }">
      <div *ngIf="i == currentStepIndex" class="active-step-marker-mobile"></div>
      <div class="step-number">{{ i + 1 }}</div>
      <div class="step-title">{{ step.title }}</div>
    </div>
  </ng-container>
</div>

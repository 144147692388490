import { isNullOrUndefined } from 'src/app/shared/extensions/extensions';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModalDirective } from '../base-modal';
import { ActionConfirmationModalContent } from './_model/action-confirmation-modal-content.model';

export enum ActionConfirmationModalType {
  DELETE_STC_DRAFT_APPLICATION = 'DELETE_STC_DRAFT_APPLICATION',
  CREATE_NEW_STC_DRAFT_APPLICATION = 'CREATE_NEW_STC_DRAFT_APPLICATION',
  CANCEL_WORD_RESERVATION = 'CANCEL_WORD_RESERVATION',
  GENERIC = 'GENERIC',
  PROCEED_TO_EXAM_PAYMENT = 'PROCEED_TO_EXAM_PAYMENT',
  PROCEED_TO_TACHO_PAYMENT = 'PROCEED_TO_TACHO_PAYMENT',
  PROCEED_TO_TACHO_PAYMENT_ANONYMOUS = 'PROCEED_TO_TACHO_PAYMENT_ANONYMOUS',
}

const TRANSLATE_KEYS = {
  [ActionConfirmationModalType.DELETE_STC_DRAFT_APPLICATION]:
    'ACTION_CONFIRMATION_MODAL.DELETE_STC_DRAFT_APPLICATION',
  [ActionConfirmationModalType.CREATE_NEW_STC_DRAFT_APPLICATION]:
    'ACTION_CONFIRMATION_MODAL.CREATE_NEW_STC_DRAFT_APPLICATION',
  [ActionConfirmationModalType.CANCEL_WORD_RESERVATION]:
    'ACTION_CONFIRMATION_MODAL.CANCEL_WORD_RESERVATION',
  [ActionConfirmationModalType.GENERIC]: 'ACTION_CONFIRMATION_MODAL.GENERIC',
  [ActionConfirmationModalType.PROCEED_TO_EXAM_PAYMENT]:
    'ACTION_CONFIRMATION_MODAL.PROCEED_TO_EXAM_PAYMENT',
  [ActionConfirmationModalType.PROCEED_TO_TACHO_PAYMENT]: 'ACTION_CONFIRMATION_MODAL.PROCEED_TO_TACHO_PAYMENT',
  [ActionConfirmationModalType.PROCEED_TO_TACHO_PAYMENT_ANONYMOUS]: 'ACTION_CONFIRMATION_MODAL.PROCEED_TO_TACHO_PAYMENT_ANONYMOUS'
};

@Component({
  selector: 'app-action-confirmation-modal',
  templateUrl: './action-confirmation-modal.component.html',
  styleUrls: ['./action-confirmation-modal.component.scss']
})
export class ActionConfirmationModalComponent extends BaseModalDirective implements OnInit {
  content: ActionConfirmationModalContent;

  constructor(private translate: TranslateService) {
    super();
  }

  ngOnInit() {
    let actionType: ActionConfirmationModalType = this.getData();
    if (isNullOrUndefined(actionType)) {
      actionType = ActionConfirmationModalType.GENERIC;
    }

    this.translate
      .get(TRANSLATE_KEYS[actionType])
      .subscribe((result: ActionConfirmationModalContent) => {
        this.content = result;
      });
  }

  onConfirmClick() {
    this.close(true);
  }

  onCancelClick() {
    this.close();
  }
}

import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnChanges {
  @Input() pageCount: number;
  @Input() currentPage: number;
  @Input() mobileInfiniteScroll = false;
  @Input() mobileLoading: boolean;
  @Output() pageChanged: EventEmitter<number> = new EventEmitter();
  pages: Array<number>;
  pagesOffset = 2;
  window = window;
  constructor() {}

  ngOnInit() {
    this.generatePages();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.pageCount && changes.pageCount.currentValue !== changes.pageCount.previousValue) {
      this.generatePages();
    }
  }

  generatePages() {
    this.pages = Array(this.pageCount || 0)
      .fill(0)
      .map((_, i) => i + 1);
  }

  setPageNext() {
    if (this.currentPage < this.pages.length) {
      this.currentPage++;
      this.pageChanged.emit(this.currentPage);
    }
  }

  setPagePrevious() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.pageChanged.emit(this.currentPage);
    }
  }

  setPage(pageNumber: number) {
    if (pageNumber !== this.currentPage) this.pageChanged.emit(pageNumber);
    this.currentPage = pageNumber;
  }

  display(pageNumber: number): boolean {
    return (
      pageNumber === 1 ||
      pageNumber === this.pages.length ||
      (this.currentPage - this.pagesOffset < pageNumber &&
        this.currentPage + this.pagesOffset > pageNumber)
    );
  }

  displayDots(pageNumber: number): boolean {
    return (
      pageNumber !== 1 &&
      pageNumber !== this.pages.length &&
      (pageNumber === this.currentPage - this.pagesOffset ||
        pageNumber === this.currentPage + this.pagesOffset)
    );
  }
}

import { Injectable, OnDestroy } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { KeyIdentifier, KeysListenerCallback } from './../model/key-listener';

@Injectable()
export class KeyEventListenerService implements OnDestroy {
  private _callbacks: KeysListenerCallback[];
  private keyEventsSubsciprions = new Subscription();

  set callbacks(callbackArray: KeysListenerCallback[]) {
    this._callbacks = callbackArray;
  }

  get callbacks(): KeysListenerCallback[] {
    return this._callbacks;
  }

  subscribe() {
    this.unsubscribe();
    this.keyEventsSubsciprions = new Subscription();
    const keyEvents = fromEvent(document, 'keydown');
    for (const callbackObj of this.callbacks) {
      const sub = keyEvents
        .pipe(filter((keyEvent: KeyboardEvent) => this.isKeyPressed(keyEvent, callbackObj.keys)))
        .subscribe((event) => {
          callbackObj.callback();
        });
      this.keyEventsSubsciprions.add(sub);
    }
  }

  unsubscribe() {
    if (!this.keyEventsSubsciprions.closed) {
      this.keyEventsSubsciprions.unsubscribe();
    }
  }

  private isKeyPressed(keyEvent: KeyboardEvent, keyIdentifiers: KeyIdentifier[]): boolean {
    const pressedKey = keyEvent.key || keyEvent.keyCode;
    let isPressed = false;
    for (const keyIdentifier of keyIdentifiers) {
      if (pressedKey === keyIdentifier.key || pressedKey === keyIdentifier.keyCode) {
        isPressed = true;
        break;
      }
    }

    return isPressed;
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Breadcrumb, mapPagesToBreadcrumbs, Page } from './breadcrumb.model';
import { BreadcrumbsService } from './breadcrumbs.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
  pageToBreadcrumb: Record<Page, Breadcrumb>;
  breadcrumbs: Array<Breadcrumb>;
  translatedBreadcrumbs: any;
  breadcrumbsSet = false;
  hideBreadcrumbsPlaceholder = false;
  breadcrumbsHidden = false;
  previousUrl: string;
  currentUrl: string;
  displayEllipsis: boolean;
  private destroy$ = new Subject();

  constructor(
    private breadcrumbsService: BreadcrumbsService,
    private router: Router,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.currentUrl = this.router.url;
    this.breadcrumbsService.breadcrumbsHide.subscribe((value: boolean) => {
      setTimeout(() => {
        this.breadcrumbsHidden = value;
      });
    });
    this.breadcrumbsService.breadcrumbsChange.subscribe((breadcrumbs: Array<Page | Breadcrumb>) => {
      if (!this.pageToBreadcrumb) {
        this.fillPageToBreadcrumbMap();
      }
      this.onBreadcrumbsChange(breadcrumbs);
    });

    this.translateBreadcrumbs();

    this.breadcrumbsService.breadcrumbsClear.subscribe((_) => this.clear());

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.breadcrumbsSet = false;
      }

      if (event instanceof NavigationEnd) {
        if (this.currentUrl !== event.urlAfterRedirects) {
          if (!this.breadcrumbsSet) {
            this.clear();
          }
          this.currentUrl = this.router.url;
        }
      }
    });
  }
  // note when using info-car won't need to refreash a page for translation, add
  // translateService.onLangChange subscriber and fire this method in its subscription
  private translateBreadcrumbs() {
    this.translateService.get('BREADCRUMBS').subscribe({
      next: (translated) => {
        this.translatedBreadcrumbs = translated;
      }
    });
  }

  onBreadcrumbsChange(breadcrumbs: Array<Page | Breadcrumb>) {
    if (!(breadcrumbs && breadcrumbs.length > 0)) {
      return;
    }

    const newBreadcrumbs: Array<Breadcrumb> = new Array<Breadcrumb>();
    for (const breadcrumb of breadcrumbs) {
      if ((breadcrumb as Breadcrumb).url && (breadcrumb as Breadcrumb).label) {
        newBreadcrumbs.push(breadcrumb as Breadcrumb);
      } else {
        const breadcrumbFromPage = this.pageToBreadcrumb[breadcrumb as Page];
        if (breadcrumbFromPage.label.startsWith(Breadcrumb.LABEL_PREFIX)) {
          const key = breadcrumbFromPage.label.substring(Breadcrumb.LABEL_PREFIX.length);
          breadcrumbFromPage.label = this.translatedBreadcrumbs[key];
        }
        newBreadcrumbs.push(breadcrumbFromPage);
      }
    }
    setTimeout(() => {
      this.hideBreadcrumbsPlaceholder = true;
      this.breadcrumbs = newBreadcrumbs;
      this.calculateEllipsis();
    });
  }

  calculateEllipsis() {
    setTimeout(() => {
      let width = 0;
      const list = document.getElementById('breadcrumbs-list');
      if (list) {
        const elements = list.children;
        for (const element of elements as any) {
          width += element.clientWidth + 52; // width + margin
        }
        width -= 52;
        this.displayEllipsis = width > 700;
      }
    });
  }

  clear() {
    this.breadcrumbs = null;
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }

  private fillPageToBreadcrumbMap() {
    this.pageToBreadcrumb = mapPagesToBreadcrumbs();
  }
}

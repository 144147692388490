import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { SimpleNonblockingInformationModalContent } from '../model/simple-nonblocking-information-modal-content.model';
import { GhostButtonType } from '../../../ghost-button/ghost-button-type.enum';
import { SimpleNonblockingInformationModalComponent } from '../simple-nonblocking-information-modal.component';

@Component({
  selector: 'app-application-already-submitted-modal',
  templateUrl: '../simple-nonblocking-information-modal.component.html',
  styleUrls: ['../simple-nonblocking-information-modal.component.scss']
})
export class ApplicationAlreadySubmittedModalComponent
  extends SimpleNonblockingInformationModalComponent
  implements OnInit {
  content: SimpleNonblockingInformationModalContent;
  GhostButtonType: typeof GhostButtonType = GhostButtonType;

  constructor(private translate: TranslateService) {
    super();
  }

  ngOnInit() {
    this.translate
      .get('APPLICATION_ALREADY_SUBMITTED_MODAL')
      .subscribe((result: SimpleNonblockingInformationModalContent) => {
        this.content = result;
      });
  }
}

<div [ngClass]="{ 'footer-content': true, UA: currentLng === appLanguage.UKRAINIAN }">
    <div class="container">
      <div class="row">
        <div class="row col-md-6 service-row">
          <div class="service-item-info col-lg-4 col-6">
            <ul>
              <li
                *ngFor="let item of firstColumnAsArray()"
                [ngSwitch]="item.fragment !== undefined">
                <a
                  *ngSwitchCase="true"
                  routerLink="{{ item.routerLink }}"
                  externalLink="{{ item.href }}"
                  fragment="{{ item.fragment }}">
                  {{ item.text }}
                </a>
                <a
                  *ngSwitchDefault
                  routerLink="{{ item.routerLink }}"
                  externalLink="{{ item.href }}">
                  {{ item.text }}
                </a>
              </li>
            </ul>
          </div>
          <div class="service-item-proper col-lg-4 col-6">
            <ul>
              <li *ngFor="let item of secondColumnAsArray()">
                <a routerLink="{{ item.routerLink }}" externalLink="{{ item.href }}">
                  {{ item.text }}
                </a>
              </li>
            </ul>
          </div>
          <div class="service-item-partners col-lg-4 col-6">
            <ul>
              <li *ngFor="let item of thirdColumnAsArray()">
                <a routerLink="{{ item.routerLink }}" externalLink="{{ item.href }}">
                  {{ item.text }}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="row col-md-6 service-item-customer-service">
          <div
            class="col-lg-4 col-6"
            *ngFor="let itemColumn of [tachographColumnAsArray(), otherColumnAsArray()]">
            <ul>
              <li *ngFor="let item of itemColumn">
                <span *ngIf="!item.href">{{ item.text }}</span>
                <a href="{{ item.href }}" *ngIf="item.href">
                  <span>{{ item.text }}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
</div>

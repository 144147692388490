import { GhostButtonComponent } from './../../ghost-button/ghost-button.component';
import { Page } from './../../breadcrumbs/breadcrumb.model';
import { GhostButtonType } from './../../ghost-button/ghost-button-type.enum';
import { PurchaseOrderResponse } from 'src/app/applications/drivers-license/document-payment/payment-process/payment/_services/document-payment-purchase.types';
import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  ViewChildren,
  QueryList
} from '@angular/core';
import {
  GetConfirmationParams,
  PikConfirmationType,
  PikPaymentConfirmationDTO
} from './models/payment-confirmation.model';
import { PaymentConfirmationListService } from './services/payment-confirmation-list.service';
import { Subscriptions } from 'src/app/shared/rx-utils/subscriptions';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { BreadcrumbsService } from '../../breadcrumbs/breadcrumbs.service';
import { Breadcrumb } from '../../breadcrumbs/breadcrumb.model';
import { MY_ACCOUNT_ROUTE } from 'src/app/my-account/_consts/my-account-route.const';

const ID_QUERY_PARAM = 'id';
const PAGE_QUERY_PARAM = 'strona';
const LIMIT_QUERY_PARAM = 'limit';

@Component({
  selector: 'app-payment-confirmation-list',
  templateUrl: './payment-confirmation-list.component.html',
  styleUrls: ['./payment-confirmation-list.component.scss']
})
export class PaymentConfirmationListComponent implements OnInit, OnDestroy {
  @ViewChildren('button') buttons: QueryList<GhostButtonComponent>;
  @Input() title: string;
  @Input() type: PikConfirmationType;
  @Input() boxTitle: string;
  @Input() buttonLink = `/${MY_ACCOUNT_ROUTE}`;
  @Input() breadcrumbsList: (Page | Breadcrumb)[] = [];
  confirmations: PurchaseOrderResponse[];
  GhostButtonType = GhostButtonType;
  downloadIndex = -1;
  currentPage = 1;
  itemsPerPage = 10;
  showSingleExam: boolean;
  totalItemsCount: number;
  filteredItemsCount: number;
  pageCount: number;
  itemsPerPageAvailableOptions = [10, 20, 50];
  getConfirmationParams$: BehaviorSubject<GetConfirmationParams>;

  subs: Subscriptions = new Subscriptions();

  constructor(
    private confirmationService: PaymentConfirmationListService,
    private route: ActivatedRoute,
    private router: Router,
    private breadcrumbs: BreadcrumbsService
  ) {}

  ngOnInit(): void {
    this.subs.next = this.route.queryParams.subscribe((params) => {
      if (params[ID_QUERY_PARAM]) {
        this.getSingleExam(params[ID_QUERY_PARAM]);
      }
      if (params[PAGE_QUERY_PARAM]) {
        this.currentPage = parseInt(params[PAGE_QUERY_PARAM], 10);
      }
      if (params[LIMIT_QUERY_PARAM]) {
        this.itemsPerPage = parseInt(params[LIMIT_QUERY_PARAM], 10);
      }

      this.setBreadcrumbs();
    });

    this.getConfirmationParams$ = new BehaviorSubject({
      page: this.currentPage - 1,
      limit: this.itemsPerPage
    });

    this.subs.next = this.getConfirmationParams$.subscribe((newParams) => {
      if (this.showSingleExam) {
        return;
      }
      this.confirmationService.getPaymentConfirmationList(this.type, newParams).subscribe({
        next: (resp: PikPaymentConfirmationDTO) => {
          this.confirmations = resp.purchaseOrders;
          this.totalItemsCount = resp.totalCount;
          this.pageCount = resp.totalPages;
        }
      });
    });
  }

  private getSingleExam(id: string): void {
    this.showSingleExam = true;
    this.confirmationService.getSingleConfirmation(id).subscribe({
      next: (resp: any) => {
        this.confirmations = [resp];
        this.totalItemsCount = 1;
        this.pageCount = 1;
      }
    });
  }

  onPageChange(newPageNumber: number): void {
    this.currentPage = newPageNumber;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { [PAGE_QUERY_PARAM]: this.currentPage },
      queryParamsHandling: 'merge',
      replaceUrl: true
    });

    this.getConfirmationParams$.next({
      ...this.getConfirmationParams$.value,
      page: this.currentPage - 1
    });
  }

  onLimitChange(newLimit: number): void {
    this.itemsPerPage = newLimit;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        [LIMIT_QUERY_PARAM]: this.itemsPerPage,
        [PAGE_QUERY_PARAM]: 1
      },
      queryParamsHandling: 'merge',
      replaceUrl: true
    });

    this.getConfirmationParams$.next({
      ...this.getConfirmationParams$.value,
      limit: this.itemsPerPage,
      page: 0
    });
  }

  clearIdFilter(): void {
    this.showSingleExam = false;
    this.router.navigate([], {
      relativeTo: this.route
    });
    this.getConfirmationParams$.next(this.getConfirmationParams$.value);
  }

  formatPrice(price: number): string {
    return (price / 100).toPrecision(price.toString().length).replace('.', ',');
  }

  convertPDFConfirmation(index: number, billId: string, date: string, name: string) {
    this.setLoadingOnActiveButton(index);

    this.confirmationService.getPDFConfirmation(billId).subscribe({
      next: (result) => {
        const blob = new Blob([result], { type: 'application/pdf' });
        const data = window.URL.createObjectURL(blob);
        const fileName = `${name} - ${date}.pdf`;
        const link = document.createElement('a');

        link.href = data;
        link.download = fileName;
        link.click();
      },
      complete: () => this.removeLoadingOnActiveButton(index)
    });
  }

  setLoadingOnActiveButton(buttonIndex) {
    const button = this.buttons.toArray()[buttonIndex];
    button.disabled = true;
    button.loading = true;
  }

  removeLoadingOnActiveButton(buttonIndex) {
    const button = this.buttons.toArray()[buttonIndex];
    button.disabled = false;
    button.loading = false;
  }

  setBreadcrumbs() {
    this.breadcrumbs.setBreadcrumbs(this.breadcrumbsList);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}

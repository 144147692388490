import { ApplicationDto } from './../../../models/applications/application-dto.model';
import { BaseModalDirective } from 'src/app/shared/components/modal/base-modal';
import { Component, OnInit } from '@angular/core';
import { GhostButtonType } from '../../ghost-button/ghost-button-type.enum';

@Component({
  selector: 'app-existing-paper-modal',
  templateUrl: './existing-paper-modal.component.html',
  styleUrls: ['./existing-paper-modal.component.scss']
})
export class ExistingPaperModalComponent extends BaseModalDirective implements OnInit {

  GhostButtonType = GhostButtonType;
  cardType: ApplicationDto.TypeEnum;

  constructor() { 
    super();
  }

  ngOnInit(): void {
    this.cardType = this.getData();
  }

  onCancelClick(): void {
    this.close(null);
  }

  onDeleteClick(): void {
    this.close('DELETE');
  }

  onEditClick(): void {
    this.close('EDIT')
  }
}

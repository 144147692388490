<section class="draft-already-exists-modal">
  <div class="loader" *ngIf="!content">
    <app-loaders type="dots"></app-loaders>
  </div>
  <div *ngIf="content">
    <header class="header">
      <div class="header__main">
        <h1 class="header__main__title" [innerHtml]="content.title"></h1>
        <h6 class="header__main__subtitle" [innerHtml]="content.description"></h6>
      </div>
      <img
        role="button"
        alt="{{ content.btnCloseAria }}"
        (click)="onCancelClick()"
        tabindex="0"
        (keydown.enter)="onCancelClick()"
        class="header__close-btn"
        src="assets/images/close_mobile_.svg" />
    </header>
    <main class="data">
      <div class="data__chunk">
        <div class="data__chunk__label" [innerHtml]="content.cardType"></div>
        <div class="data__chunk__content">
          {{ content.driverCard }}
        </div>
      </div>
      <div class="data__chunk">
        <div class="data__chunk__label" [innerHtml]="content.cardReason"></div>
        <div class="data__chunk__content">
          {{ reason?.message }}
        </div>
      </div>
      <div class="data__chunk">
        <div class="data__chunk__label" [innerHtml]="content.lastModified"></div>
        <div class="data__chunk__content">
          {{ date }}
        </div>
      </div>
    </main>
    <div class="nav-btns">
      <button (click)="onEditClick()" class="cool-btn primary-btn">
        <span *ngIf="isViewportMobile" [innerHtml]="content.editShort"></span>
        <span *ngIf="!isViewportMobile" [innerHtml]="content.edit"></span>
      </button>
      <button (click)="onDeleteClick()" class="cool-btn outline-btn">
        <span *ngIf="isViewportMobile" [innerHtml]="content.deleteShort"></span>
        <span *ngIf="!isViewportMobile" [innerHtml]="content.delete"></span>
      </button>
      <button
        (click)="onCancelClick()"
        class="cool-btn mobile-full-width outline-btn"
        [innerHtml]="content.cancel"></button>
    </div>
  </div>
</section>

import { EmployerTypes } from './../_consts/employer-types';
import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { take, filter } from 'rxjs/operators';
import { AuthService } from 'src/app/core/auth/auth.service';
import { environment as env } from 'src/environments/environment';
import * as fromType from '../_store/type.reducer';
import { User } from '../model/user.model';
import {
  SetAsControlAuthorityAction,
  SetAsTransportCompanyAction,
  SetAsWorkshopAction,
  SetInitialStateAction
} from './../_store/type.action';

const EMPLOYER_TYPE = '/type';
@Injectable()
export class EmployerTypeService implements OnDestroy {
  loggedUserUid: string;
  private sub: Subscription;

  constructor(
    private _http: HttpClient,
    private auth: AuthService,
    private typeStore$: Store<fromType.EmployerTypeState>
  ) {
    this.sub = this.auth.user$
      .pipe(filter((user) => !!user))
      .subscribe((user) => this.fetchEmployeerTypeIfLoggedInWithBusiness(user));
  }

  private fetchEmployeerTypeIfLoggedInWithBusiness(user: User) {
    if (AuthService.isBusinessUser(user)) {
      this.loggedUserUid = user.uuid;
      this.fetchEmployerType(user.uuid).subscribe((result) => {
        if (result === EmployerTypes.TRANSPORT_COMPANY) {
          this.typeStore$.dispatch(new SetAsTransportCompanyAction());
        } else if (result === EmployerTypes.WORKSHOP) {
          this.typeStore$.dispatch(new SetAsWorkshopAction());
        } else if (result === EmployerTypes.CONTROL_AUTHORITY) {
          this.typeStore$.dispatch(new SetAsControlAuthorityAction());
        }
      });
    } else {
      this.typeStore$.dispatch(new SetInitialStateAction());
    }
  }

  private fetchEmployerType(employerId: string): Observable<EmployerTypes> {
    return this._http.get<EmployerTypes>(
      `${env.BACKEND_API.BASE}${env.BACKEND_API.EMPLOYERS}/${employerId}${EMPLOYER_TYPE}`
    );
  }

  getEmployerType(): Observable<EmployerTypes> {
    return this.typeStore$.pipe(select(fromType.selectEmployerType));
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}

import { SUMMARY_STEP_ROUTE } from './../application-process/summary-step/_consts/summary-step-route.const';
import { PERSONAL_DATA_STEP_ROUTE } from './../application-process/personal-data-step/_consts/personal-data-step-route.const';
import { PAYMENT_STEP_ROUTE } from '../application-process/payment-step/_consts/payment-step-route.const';
export const TRAINING_CARD_ROUTE = 'tachograf/karty-testowe';

export const TRAINING_CARD_STEPS_ROUTES: string[] = [
  `/${TRAINING_CARD_ROUTE}/${PERSONAL_DATA_STEP_ROUTE}`,
  `/${TRAINING_CARD_ROUTE}/${SUMMARY_STEP_ROUTE}`,
  `/${TRAINING_CARD_ROUTE}/${PAYMENT_STEP_ROUTE}`
];

import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse, HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable, of, pipe, throwError } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { AttachmentType } from 'src/app/shared/models/attachments/attachment-dto.model';

import { AttachmentDto } from '../../../../models/attachments/attachment-dto.model';
import { getBase64 } from '../functions/file-functions';
import {
  ApplicationAttachmentType,
  AttachmentEntity,
  AttachmentMetadata,
  UploadStatus
} from '../model';
import { AttachmentsApiService } from './attachments-api.service';
import { AttachmentsStore } from './attachments-store.service';

@Injectable()
export class AttachmentsFacadeService {
  dtos$: Observable<AttachmentDto[]> = this.attachmentsStore.entities$.pipe(
    map((list) => list.map((item) => item.dto))
  );

  entities$: Observable<AttachmentEntity[]> = this.attachmentsStore.entities$;

  private applicationId: string;
  private config: AttachmentMetadata[] = [];

  constructor(
    private attachmentApi: AttachmentsApiService,
    private attachmentsStore: AttachmentsStore,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}

  fetchInitialDataFor(
    applicationId: string,
    applicationType: ApplicationAttachmentType
  ): Observable<AttachmentMetadata[]> {
    return forkJoin({
      attachmentsConfig: this.attachmentApi.getRequiredAttachmentsListBy(
        applicationId,
        applicationType
      ),
      uploadedAttachments: this.attachmentApi.getAttachmentsBy(applicationId, applicationType)
    }).pipe(
      tap(({ uploadedAttachments }) => {
        this.applicationId = applicationId;
        this.attachmentsStore.init(uploadedAttachments.attachments);
      }),
      map(({ attachmentsConfig }) =>
        attachmentsConfig.attachments.map((item) => ({
          ...item,
          required: item.required || false
        }))
      ),
      tap((config) => (this.config = config))
    );
  }

  selectEntitiesByType(type: AttachmentType): Observable<AttachmentEntity[]> {
    return this.attachmentsStore.selectEntitiesByType(type).pipe(map((list) => list.toArray()));
  }

  selectDtosByType(type: AttachmentType): Observable<AttachmentDto[]> {
    return this.selectEntitiesByType(type).pipe(map((list) => list.map((item) => item.dto)));
  }

  deleteAttachment(attachment: AttachmentEntity, applicationType: ApplicationAttachmentType) {
    this.attachmentsStore.deleteEntity(attachment);
    if (attachment.status.status === 'ERROR') {
      return;
    }
    return this.attachmentApi
      .deleteAttachmentBy(
        attachment.dto.applicationId || attachment.dto.correctionId,
        attachment.dto.id,
        applicationType
      )
      .pipe(
        catchError((error) => {
          if (attachment.status.equals(UploadStatus.ERROR)) {
            return of(null);
          }
          this.attachmentsStore.createUploaded(attachment.dto);
          return throwError(error);
        })
      )
      .subscribe(
        () =>
          this.toastr.info(
            `${this.translate.instant('TOASTR.STC.ATTACHMENTS.ATTACHMENT_DELETED')} ${
              attachment.dto.fileName
            }`
          ),
        () =>
          this.toastr.warning(
            `${this.translate.instant('TOASTR.STC.ATTACHMENTS.ATTACHMENT_CANNOT_DELETE_PART_1')} ${
              attachment.dto.fileName
            }${this.translate.instant('TOASTR.STC.ATTACHMENTS.ATTACHMENT_CANNOT_DELETE_PART_2')}`
          )
      );
  }

  createReadyToUploadAttachmentsFromFiles(
    attachmentType: AttachmentType,
    files: File[],
    applicationType: ApplicationAttachmentType
  ) {
    files.forEach((file) => {
      getBase64(file)
        .then((content) =>
          this.createAttachmentDto(file.name, content, attachmentType, applicationType)
        )
        .then((dto) => this.createEntityFromDtoAndStartUpload(dto, applicationType))
        .catch(() =>
          this.toastr.warning(
            `${this.translate.instant('TOASTR.STC.ATTACHMENTS.ATTACHMENT_LOAD_ERROR')} ${file.name}`
          )
        );
    });
  }

  private createAttachmentDto(
    filename: string,
    content: string,
    attachmentType: AttachmentType,
    applicationType: ApplicationAttachmentType
  ): AttachmentDto {
    return {
      applicationId: applicationType == 'APPLICATION' ? this.applicationId : null,
      correctionId: applicationType == 'CORRECTION' ? this.applicationId : null,
      fileName: filename,
      format: null,
      content: content,
      maxNumber: null,
      maxSize: null,
      type: attachmentType,
      checksum: null
    };
  }

  private createEntityFromDtoAndStartUpload(
    dto: AttachmentDto,
    applicationType: ApplicationAttachmentType
  ) {
    const entity: AttachmentEntity = this.attachmentsStore.createReadyToUpload(dto);
    this.uploadAttachment(entity, applicationType);
  }

  public uploadAttachment(
    attachment: AttachmentEntity,
    applicationType: ApplicationAttachmentType
  ) {
    if (
      attachment.status.equals(UploadStatus.IN_PROGRESS) ||
      attachment.status.equals(UploadStatus.UPLOADED)
    ) {
      return;
    }
    this.attachmentApi
      .postAttachment(attachment.dto, applicationType)
      .pipe(
        updateProgress((progress) => this.attachmentsStore.updateProgress(attachment, progress)),
        toResponseBody()
      )
      .subscribe(
        (dto: AttachmentDto) => this.attachmentsStore.updateUploaded(attachment, dto),
        (response: HttpErrorResponse) =>
          this.attachmentsStore.updateStatus(attachment, UploadStatus.ERROR, response.error.errors)
      );
  }

  public putProceedAttachments(applicationId: string, applicationType: ApplicationAttachmentType) {
    return this.attachmentApi.putProceedAttachmentsStatusBy(applicationId, applicationType);
  }
}

export function updateProgress<T>(cb: (progress: number) => void) {
  return tap((event: HttpEvent<T>) => {
    if (event.type === HttpEventType.UploadProgress) {
      cb(Math.round((100 * event.loaded) / event.total));
    }
  });
}

export function toResponseBody<T>() {
  return pipe(
    filter((event: HttpEvent<T>) => event.type === HttpEventType.Response),
    map((res: HttpResponse<T>) => res.body)
  );
}

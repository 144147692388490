export enum ExamType {
  Theoretical = 'THEORY',
  Practical = 'PRACTICE',
  Linked = 'LINKED'
}

export interface AvailableExam {
  type?: ExamType;
  price?: number;
  availablePlaces: number;
  examFullName?: string;
  examDate?: string;
  examHour?: string;
}

<ng-container *ngIf="window.innerWidth >= 768 || !mobileInfiniteScroll; else mobileTemplate">
  <div *ngIf="pages.length > 1" class="container">
    <div class="row pagination-row">
      <div
        class="pagination-box"
        id="arrow-left"
        role="button"
        [attr.aria-label]="'MAIN_SLIDER.prevBtnAria' | translate"
        tabindex="0"
        (keydown.enter)="setPagePrevious()"
        (click)="setPagePrevious()">
        <div>
          <svg-icon class="close-btn" src="assets/images/prev_icon_normal.svg"></svg-icon>
        </div>
      </div>
      <ng-container *ngFor="let pageNumber of pages; let i = index">
        <div
          *ngIf="!displayDots(pageNumber) && display(pageNumber)"
          tabindex="0"
          (keydown.enter)="setPage(pageNumber)"
          class="pagination-box"
          [ngClass]="{ active: i == currentPage - 1 }"
          role="button"
          (click)="setPage(pageNumber)">
          <div>{{ pageNumber }}</div>
        </div>
        <div class="dots" *ngIf="displayDots(pageNumber) && !display(pageNumber)">...</div>
      </ng-container>
      <div
        class="pagination-box"
        tabindex="0"
        (keydown.enter)="setPageNext()"
        id="arrow-right"
        role="button"
        [attr.aria-label]="'MAIN_SLIDER.nextBtnAria' | translate"
        (click)="setPageNext()">
        <div>
          <svg-icon class="close-btn" src="assets/images/next_icon_normal.svg"></svg-icon>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #mobileTemplate>
  <div *ngIf="pages.length > 1 && currentPage < pages.length" class="container">
    <div class="mobile-pagination row">
      <div class="see-more-box">
        <div (click)="setPageNext()" role="button" class="see-more-button">
          {{ 'NEWS.NEWS_LIST.seeMoreButtonLabel' | translate }}
        </div>
      </div>
    </div>
  </div>
</ng-template>
<app-loaders [show]="mobileLoading" fullScreen="true" type="spinner"></app-loaders>

import { ReasonDto } from './reason-dto.model';
import { StatusDto } from './status-dto.model';

/**
 * INFOCAR STC API
 * API for processing STC applications
 *
 * OpenAPI spec version: v1
 * Contact: java@pulab.pl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * DTO describing application basic fields
 */
export interface ApplicationBasicDto {
  /**
   * Creation date of application
   * 
   */
  applicationKind: string;
  createdAt: string;
  /**
   * First name of person
   */
  firstName?: string;
  deprecated: boolean;
  /**
   * Last name of person
   */
  lastName?: string;
  paper?: boolean;
  /**
   * Reason type
   */
  reasonType: ReasonDto.ReasonTypeEnum;
  /**
   * Application serial number
   */
  serialNumber: string;
  /**
   * Status of application
   */
  status: StatusDto;
  /**
   * Type of application
   */
  type: ApplicationBasicDto.TypeEnum;
  /**
   * Update date of application
   */
  updatedAt: string;
  /**
   *  Successful payment date of application
   */
  paymentAcceptedAt: string;
  /**
   * Id of application
   */
  applicationId: string;
  /**
   * Owners Id
   */
  applicationOwnerId: string;
  /**
   * Owner type
   */
  applicationOwnerType: string;
  /**
   * Complaint id
   */
  complaintId?: string;
  /**
   * Complaint Reason
   * add enum values when necessary YAGNI
   */
  complaintReason?: string;
  /**
   * Correction id
   */
  correctionId?: string;
}
export namespace ApplicationBasicDto {
  export type TypeEnum = 'DRIVER_CARD' | 'TEMPORARY_DRIVER_CARD' | 'CONTROL_CARD' | 'COMPANY_CARD' | 'WORKSHOP_CARD';
  export const TypeEnum = {
    DRIVER_CARD: 'DRIVER_CARD' as TypeEnum,
    TEMPORARY_DRIVER_CARD: 'TEMPORARY_DRIVER_CARD' as TypeEnum,
    CONTROL_CARD: 'CONTROL_CARD' as TypeEnum,
    COMPANY_CARD: 'COMPANY_CARD' as TypeEnum,
    WORKSHOP_CARD: 'WORKSHOP_CARD' as TypeEnum
  };
}

import { ControlCardNumberFormValidation } from './control-card-number.model';
import { Validators } from '@angular/forms';
import { ValidationPatterns } from 'src/app/shared/validation/validation-patterns.const';

export const defaultControlCardNumberValidation: ControlCardNumberFormValidation = {
  formGroupName: 'controlCardNumber',

  formFields: {
    controlCardNumber: Validators.compose([
      Validators.minLength(16),
      Validators.maxLength(16),
      Validators.pattern(ValidationPatterns.cardNumber)
    ])
  }
};

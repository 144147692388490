<section class="statement">
  <div class="container-fluid">
    <div class="statement__info">
      <p class="title">{{ statementTitle }}</p>
      <span class="subtitle">{{ statementSubtitle }}</span>
    </div>
    <div class="button">
      <ic-ghost-button
        [type]="GhostButtonType.SMALL_SECOND_RANK"
        [justifyContent]="'center'"
        [width]="130"
        [typeHTML]="'button'"
        [isDownload]="true"
        [linkHref]="linkHref"
        [fileName]="fileName">
        {{ downloadLabel }}
      </ic-ghost-button>
    </div>
  </div>
</section>

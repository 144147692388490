<section class="ic-page">
  <div class="reason-container ic-input">
    <div class="reason-container__control-card" *ngIf="isControlCard && !isBusiness">
      <h2 class="choice-reason-title">
        {{ 'COMPLAINT.COMPLAINT_REASON.chooseCardType' | translate }}
      </h2>
      <label
        tabindex="0"
        appTriggerClick
        [appIndicateFor]="'card-type-not-selected'"
        tabindex="0"
        [animClass]="'indicate'"
        [firstFrameClass]="'indicate-ff'"
        class="ic-radio-btn label-reason label-card-type"
        [class.label-reason--selected]="isPersonal === false">
        {{ 'COMPLAINT.COMPLAINT_REASON.impersonalCard' | translate }}
        <input
          tabindex="-1"
          type="radio"
          name="isPersonal"
          [(ngModel)]="isPersonal"
          [value]="false" />
        <span class="checkmark"></span>
      </label>

      <label
        tabindex="0"
        appTriggerClick
        [appIndicateFor]="'card-type-not-selected'"
        tabindex="0"
        [animClass]="'indicate'"
        [firstFrameClass]="'indicate-ff'"
        class="ic-radio-btn label-reason label-card-type"
        [class.label-reason--selected]="isPersonal === true">
        {{ 'COMPLAINT.COMPLAINT_REASON.personalCard' | translate }}
        <input
          tabindex="-1"
          type="radio"
          name="isPersonal"
          [(ngModel)]="isPersonal"
          [value]="true" />
        <span class="checkmark"></span>
      </label>
    </div>
    <form [formGroup]="reasonForCardForm">
      <h2 class="choice-reason-title">
        {{ 'COMPLAINT.COMPLAINT_REASON.title' | translate }}
      </h2>
      <app-form-input
        inputId="cardNumber"
        [isRequired]="isControlRequired('cardNumber')"
        formControlName="cardNumber"
        type="text"
        [placeholder]="'COMPLAINT.COMPLAINT_REASON.cardNumberPlaceholder' | translate"
        [label]="'COMPLAINT.COMPLAINT_REASON.cardNumberLabel' | translate"
        [formGroup]="reasonForCardForm"
        [errorList]="[
          { label: 'COMPLAINT.COMPLAINT_REASON.required' | translate, type: ['required'] }
        ]">
      </app-form-input>
      <p class="reason-container__info-box">
        {{ 'COMPLAINT.COMPLAINT_REASON.infobox' | translate }}
      </p>

      <app-selectable-input
        id="reason"
        formControlName="reason"
        [formGroup]="reasonForCardForm"
        [items]="reasonList"
        [htmlId]="'reason'"
        [isRequired]="isControlRequired('reason')"
        key="message"
        outputKey="reasonType"
        [label]="'COMPLAINT.COMPLAINT_REASON.reasonLabel' | translate"
        [placeholder]="'COMPLAINT.COMPLAINT_REASON.reasonPlaceholder' | translate"
        [errorList]="[
          { label: 'COMPLAINT.COMPLAINT_REASON.required' | translate, type: ['required'] }
        ]">
      </app-selectable-input>

      <div class="textarea">
        <label for="message">
          {{ 'COMPLAINT.COMPLAINT_REASON.textareaLabel' | translate
          }}{{ isControlRequired('messageText') ? '*' : '' }}
        </label>
        <textarea
          name="message"
          formControlName="messageText"
          [formGroup]="reasonForCardForm"
          id="message"
          [ngClass]="{
            required:
              reasonForCardForm.controls.messageText.invalid &&
              reasonForCardForm.controls.messageText.touched
          }"
          [maxLength]="428"
          [placeholder]="'COMPLAINT.COMPLAINT_REASON.textareaPlaceholder' | translate"></textarea>
        <span
          *ngIf="
            reasonForCardForm.controls.messageText.invalid &&
            reasonForCardForm.controls.messageText.touched
          "
          class="error"
          >{{ 'COMPLAINT.COMPLAINT_REASON.required' | translate }}</span
        >
      </div>

      <app-two-way-action-bar
        (submit)="submitReason()"
        (navigateBack)="onBackClicked()"
        [forwardButtonDisabled]="!reasonForCardForm.valid"
        [icPageContainer]="false">
      </app-two-way-action-bar>
    </form>
  </div>
</section>

import { Extensions } from 'src/app/shared/extensions/extensions';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { BaseModalDirective } from '../base-modal';
import { GhostButtonType } from '../../ghost-button/ghost-button-type.enum';

@Component({
  selector: 'app-agreements-modal',
  templateUrl: './agreements-modal.component.html',
  styleUrls: ['./agreements-modal.component.scss']
})
export class AgreementsModalComponent extends BaseModalDirective implements OnInit {

  formGroup: UntypedFormGroup;
  GhostButtonType = GhostButtonType;

  constructor(
    private fb: UntypedFormBuilder
  ) { 
    super();

    this.formGroup = this.fb.group({
      regulations: [null, Validators.requiredTrue],
      infoRead: [null, Validators.requiredTrue]
    })
  }

  ngOnInit(): void {
  }

  onCancelClick(): void {
    this.close(null);
  }

  onConfirmClick(): void {
    if(this.formGroup.valid) {
      this.close(this.formGroup.value);
    } else {
      Extensions.scrollToFirstInvalidInput();
    }
  }
}

<section class="card-loss-info-modal">
    <ng-container>
      <header class="header">
        <div class="header-wrapper">
            <h1 class="header-title">{{'SHARED.CARD_LOSS_INFO_MODAL.title' | translate}}</h1>
        </div>
        <button [attr.aria-label]="'SHARED.CARD_LOSS_INFO_MODAL.closeAria' | translate" (click)="onCancelClick()" class="close-btn">
          <svg-icon class="close-btn-icon" src="assets/images/close_mobile_.svg"></svg-icon>
        </button>
      </header>
      <main class="main">
        <p>{{'SHARED.CARD_LOSS_INFO_MODAL.description' | translate}}</p>

        <div class="nav-btns">
          <ic-ghost-button
          [typeHTML]="'button'"
          appPreventDoubleClick
          (throttledClick)="onNextClick()"
          [showArrow]="false"
          class="next"
          [justifyContent]="'center'"
          [width]="150">
          <span
            class="forwardSpan start-btn__label"
            [innerHtml]="'SHARED.CARD_LOSS_INFO_MODAL.nextButton' | translate"></span>
          </ic-ghost-button>
        </div>
      </main>
    </ng-container>
  </section>
  
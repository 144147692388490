<main class="confirmation ic-page" *ngIf="content">
  <header class="confirmation__header">
    <h1 class="confirmation__header--title">{{ content.title }}</h1>
  </header>
  <article class="confirmation__article">
    <p class="confirmation__article--description">
      {{ content.paymentInfo }}
    </p>
  </article>
  <section class="confirmation__button">
    <ic-ghost-button
      [width]="250"
      [type]="GhostButtonType.BIG_SECOND_RANK_FLEX"
      [typeHTML]="'button'"
      [arrowLeft]="true"
      [showArrow]="true"
      ><span
        innerHTML="{{ 'EXTERNAL_PAYMENTS.CONFIRMATION.btnBack' | translate }}"
        (click)="goToLink()"></span
    ></ic-ghost-button>
    <p class="confirmation__redirect">
      {{ 'EXTERNAL_PAYMENTS.CONFIRMATION.redirectText' | translate }}
      <strong>{{ timeToRedirect }}</strong
      >s.
    </p>
  </section>
</main>

export interface ClientAcceptancesDto {
  actualAcceptances: AcceptanceType[];
  availableAcceptances: AcceptanceDto[];
  userName: string;
}

export interface ClientAcceptancesResponseDto {
  message: string;
}

export interface AcceptanceDecisionDto {
  name: AcceptanceType;
  granted: boolean;
}

export interface AcceptanceDto {
  name: AcceptanceType;
  required: boolean;
}

export enum AcceptanceType {
  RULES = 'RULES',
  INFOS = 'INFOS',
  MARKETING_PHONE = 'MARKETING_PHONE',
  MARKETING_EMAIL = 'MARKETING_EMAIL'
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EmployerTypes } from '../_consts/employer-types';
import { EmployerTypeAction, EmployerTypeActionTypes } from './type.action';

export interface EmployerTypeState {
  type: EmployerTypes;
}

export const initialState: EmployerTypeState = {
  type: null
};

export function employerTypeReducer(state = initialState, action: EmployerTypeAction) {
  switch (action.type) {
    case EmployerTypeActionTypes.SET_TRANSPORT_COMPANY:
      return Object.assign({}, state, {
        type: EmployerTypes.TRANSPORT_COMPANY
      });

    case EmployerTypeActionTypes.SET_WORKSHOP:
      return Object.assign({}, state, {
        type: EmployerTypes.WORKSHOP
      });

    case EmployerTypeActionTypes.SET_CONTROL_AUTHORITY: {
      return Object.assign({}, state, {
        type: EmployerTypes.CONTROL_AUTHORITY
      });
    }
    case EmployerTypeActionTypes.SET_INITIAL_STATE: {
      return Object.assign({}, state, {
        type: initialState
      });
    }
    default:
      return state;
  }
}

export const featureName = 'employerType';

export const selectEmployerTypeState = createFeatureSelector<EmployerTypeState>(featureName);

export const selectEmployerType = createSelector(
  selectEmployerTypeState,
  (state: EmployerTypeState) => state.type
);

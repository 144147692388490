import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

const WIZARD_STEP_BACK_MODAL = '/application-wizard-back-modal';

@Injectable({
  providedIn: 'root'
})
export class WizardStepBackModalService {
  constructor(private _http: HttpClient) {}
}

import { trigger, transition, style, animate } from '@angular/animations';
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDate, NgbDatepickerI18n, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { interval } from 'rxjs';
import { isNullOrUndefined } from 'src/app/shared/extensions/extensions';
import { WordExamCategory } from 'src/app/shared/models/word-exam-reservation/word-exam.model';
import { Subscriptions } from '../../rx-utils/subscriptions';
import { GhostButtonType } from '../ghost-button/ghost-button-type.enum';
import { ModalRef } from '../modal/modal-ref';
import { MINUTE } from './../../extensions/extensions';
import { IcDatepickerI18n } from './ic-datepicker-i18n';
import { ExamType } from './_models/available-exam';
import { SelectedExam } from './_models/selected-exam.model';
import {
  CalendarStepCategoryPrice,
  WordExamCalendarContent
} from './_models/word-exam-calendar-content.model';
import { ScheduledDays } from './_models/word-exam-schedule-list.model';
import { WordExamCalendarService } from './_service/word-exam-calendar.service';

const INITIAL_NUMBER_OF_DAYS_TO_SHOW = 7;
const INCREMENT_NUMBER_OF_DAYS_TO_SHOW = 7;
const TOASTR_TRANSLATE_PATH = 'TOASTR.WORD_EXAM_REGISTRATION.CLENDAR.CALENDAR_REFRESHED';
const CALENDAR_UPDATE_MINUTE_INTERVAL = 5;
const PRACTICE_ONLY_CATEGORIES = [
  WordExamCategory.BE,
  WordExamCategory.CE,
  WordExamCategory.C_1_E,
  WordExamCategory.DE,
  WordExamCategory.D_1_E
];
const THEORY_ONLY_CATEGORIES = [
  WordExamCategory.BLOK_C,
  WordExamCategory.BLOK_D
];

@Component({
  selector: 'app-word-exam-calendar',
  templateUrl: './word-exam-calendar.component.html',
  styleUrls: ['./word-exam-calendar.component.scss'],
  providers: [
    WordExamCalendarService,
    {
      provide: NgbDatepickerI18n,
      useClass: IcDatepickerI18n
    }
  ]
})
export class WordExamCalendarComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('datePicker') datePicker: NgbInputDatepicker;
  @Input() licenseCategory: WordExamCategory;
  @Input() scheduledDays: ScheduledDays[];
  @Input() examType: string;
  @Input() isCandidateMature: boolean;
  @Input() candidateMaturityDate: Date;
  @Output() examSelected: EventEmitter<SelectedExam> = new EventEmitter();
  @Output() updateSchedule: EventEmitter<void> = new EventEmitter();

  stickyFilterButton = false;
  content: WordExamCalendarContent;
  GhostButtonType = GhostButtonType;
  ExamType = ExamType;
  examTypeFormGroup: UntypedFormGroup = new UntypedFormGroup({
    examType: new UntypedFormControl(null)
  });
  hoveredDate: NgbDate;
  isDatePickerOpen: boolean;
  fromDate: NgbDate;
  toDate: NgbDate;
  startDate: NgbDate;
  dateRange: {
    fromDate: Date;
    toDate: Date;
  };
  containerElement: HTMLElement;
  filteredDays: ScheduledDays[] = [];
  activeFilter: ExamType = null;
  numberOfDaysToShow: number = INITIAL_NUMBER_OF_DAYS_TO_SHOW;
  isDayScheduled: Map<string, boolean> = new Map();
  modalRef: ModalRef;
  subscription: Subscriptions = new Subscriptions();
  practiceExamsOnly: boolean;
  theoreticalPrice: number;
  practicePrice: number;
  linkedPrice: number;
  WordExamCategory = WordExamCategory;
  theoreticalOnly: boolean;

  constructor(
    private calendarService: WordExamCalendarService,
    protected route: ActivatedRoute,
    protected router: Router,
    private toastrService: ToastrService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.calendarService.getWordExamCalendarContent().subscribe({
      next: (content) => {
        this.content = content;

        if (this.scheduledDays[0]) {
          const prices = {
            practicePrice: null,
            theoryPrice: null,
            linkedPrice: null,
        }

        this.scheduledDays.forEach(day => {
            if (prices.practicePrice && prices.theoryPrice && prices.linkedPrice) return;
            day.scheduledHours.forEach(hour => {
                if (prices.practicePrice && prices.theoryPrice && prices.linkedPrice) return;
                if(hour.theoryExams.length) {
                    prices.theoryPrice = hour.theoryExams[0].amount;
                }
                if(hour.practiceExams.length) {
                    prices.practicePrice = hour.practiceExams[0].amount;
                }
                if(hour.linkedExamsDto.length) {
                    prices.linkedPrice = hour.linkedExamsDto[0].theoryExam.amount + hour.linkedExamsDto[0].practiceExams[0].amount;
                }
            })
        });

        this.theoreticalPrice = prices.theoryPrice;
        this.practicePrice = prices.practicePrice;
        this.linkedPrice = prices.linkedPrice;
        
        const startDate = new Date(this.scheduledDays[0].day);
        this.startDate = new NgbDate(
            startDate.getUTCFullYear(),
            startDate.getUTCMonth() + 1,
            startDate.getUTCDate()
          );
        }

        this.setupDaysFiltering();
        this.checkAvailableFiltersForCategory();
      }
    });

    this.containerElement = document.getElementById('calendar-step-container');
    this.updateCalendarPeriodically(CALENDAR_UPDATE_MINUTE_INTERVAL);

    if(this.examType) {
      this.examTypeFormGroup.get('examType').setValue(this.examType);
      this.examTypeFormGroup.get('examType').disable();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.scheduledDays &&
      !this.compareSchedules(this.scheduledDays, changes.scheduledDays.currentValue)
    ) {
      this.scheduledDays = changes.scheduledDays.currentValue;
      this.filteredDays = this.filterDays(this.scheduledDays, this.dateRange, this.activeFilter);
      this.toastrService.info(this.translateService.instant(TOASTR_TRANSLATE_PATH), '', {
        toastClass: 'calendar-refreshed-toastr'
      });
    }
  }

  compareSchedules(schedule1: ScheduledDays[], schedule2: ScheduledDays[]): boolean {
    if (JSON.stringify(schedule1).toLowerCase() === JSON.stringify(schedule2).toLowerCase()) {
      return true;
    }
    return false;
  }

  updateCalendarPeriodically(period: number) {
    // minutes
    const source = interval(period * MINUTE);
    this.subscription.next = source.subscribe(() => {
      this.updateWordExamSchedule();
    });
  }

  updateWordExamSchedule() {
    this.updateSchedule.emit();
  }

  @HostListener('document:click', ['$event'])
  public onDocumentClick(event: any) {
    if (event.target.className === 'clear-date-label') {
      this.clearDateRange();
      this.datePicker.close();
    }
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      date.equals(this.toDate) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isScheduled(date: NgbDate): boolean {
    const dateKey = this.getDateKey(date);
    if (!this.isDayScheduled.has(dateKey)) {
      const isScheduled = !isNullOrUndefined(
        this.scheduledDays.find((day: ScheduledDays) => {
          return date.equals(this.convertDateToNgbDate(new Date(day.day)));
        })
      );
      this.isDayScheduled.set(dateKey, isScheduled);
    }
    return this.isDayScheduled.get(dateKey);
  }

  private getDateKey(date: NgbDate): string {
    return `${date.year}${date.month}${date.day}`;
  }

  onDateSelection(date: NgbDate) {
    if (this.startDate && date.before(this.startDate)) {
      return;
    }

    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
      this.toDate = null;
    } else if (
      this.fromDate &&
      !this.toDate &&
      (date.after(this.fromDate) || date.equals(this.fromDate))
    ) {
      this.toDate = date;
      this.dateRange = {
        fromDate: this.convertNgbDateToDate(this.fromDate),
        toDate: this.convertNgbDateToDate(this.toDate)
      };

      this.filteredDays = this.filterDays(this.scheduledDays, this.dateRange, this.activeFilter);
    } else {
      this.fromDate = date;
      this.toDate = null;
    }
  }

  onDateAccept() {
    setTimeout((_) => this.datePicker.close(), 200);
  }

  private convertNgbDateToDate(ngbDate: NgbDate): Date {
    return new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
  }

  private convertDateToNgbDate(date: Date): NgbDate {
    return new NgbDate(date.getUTCFullYear(), date.getUTCMonth() + 1, date.getUTCDate());
  }

  toggleIsDatePickerOpen() {
    this.isDatePickerOpen = !this.isDatePickerOpen;
  }

  setIsDatePickerOpen(isOpen: boolean) {
    this.isDatePickerOpen = isOpen;
    if (isNullOrUndefined(this.toDate) && !isNullOrUndefined(this.dateRange)) {
      this.fromDate = this.convertDateToNgbDate(this.dateRange.fromDate);
      this.toDate = this.convertDateToNgbDate(this.dateRange.toDate);
    }
  }

  clearDateRange() {
    this.fromDate = null;
    this.toDate = null;
    this.dateRange = null;
    this.datePicker.close();
    this.filteredDays = this.filterDays(this.scheduledDays, this.dateRange, this.activeFilter);
  }

  changeDateEvent() {
    this.datePicker.open();
    this.isDatePickerOpen = true;
  }

  scrollToElement(tag: HTMLElement) {
    tag.scrollIntoView({ behavior: 'smooth' });
  }

  @HostListener('window:scroll', ['$event'])
  handleScroll() {
    const windowScroll = window.pageYOffset;
    if (windowScroll >= 800) {
      this.stickyFilterButton = true;
    } else {
      this.stickyFilterButton = false;
    }
  }

  setupDaysFiltering() {
    if (this.candidateMaturityDate) {
      this.scheduledDays = this.removePracticeAndCombinedExamsBeforeMaturityDate(
        this.scheduledDays,
        this.candidateMaturityDate
      );
    }
    this.filteredDays = this.scheduledDays;
    this.subscription.next = this.examTypeFormGroup
      .get('examType')
      .valueChanges.subscribe((examType) => {
        this.activeFilter = examType;
        this.filteredDays = this.filterDays(this.scheduledDays, this.dateRange, examType);
      });
  }

  removePracticeAndCombinedExamsBeforeMaturityDate(days: ScheduledDays[], maturityDate: Date) {
    const filtered = days.reduce((result, day) => {
      // if day comes after maturity date display it normally
      if (new Date(day.day) >= maturityDate) {
        result.push(day);
      } else {
        // if day comes before maturity date display it, but only if it has theory exams
        // also don't display practice and linked exams for that day
        let hasTheory = false;
        day.scheduledHours.forEach((hour) => {
          hour.practiceExams = [];
          hour.linkedExamsDto = [];
          hasTheory = hasTheory || (hour.theoryExams && hour.theoryExams.length > 0);
        });
        if (hasTheory) {
          result.push(day);
        }
      }
      return result;
    }, []);
    return filtered;
  }

  checkAvailableFiltersForCategory() {
    this.practiceExamsOnly = PRACTICE_ONLY_CATEGORIES.includes(this.licenseCategory);
    this.theoreticalOnly = THEORY_ONLY_CATEGORIES.includes(this.licenseCategory);
    if (this.practiceExamsOnly) {
      this.lockExamTypeFilters(ExamType.Practical);
    } else if (this.theoreticalOnly) {
      this.lockExamTypeFilters(ExamType.Theoretical);
    }
  }

  lockExamTypeFilters(examType: ExamType) {
    this.activeFilter = examType;
    this.examTypeFormGroup.get('examType').setValue(examType);
    this.examTypeFormGroup.get('examType').disable();
    this.filteredDays = this.filterDays(this.scheduledDays, this.dateRange, this.activeFilter);
  }

  private filterDays(
    scheduledDays: ScheduledDays[],
    dateRange: { fromDate: Date; toDate: Date },
    filter: ExamType
  ): ScheduledDays[] {
    this.isDayScheduled.clear();
    let filtered = scheduledDays;

    if (!isNullOrUndefined(filter)) {
      filtered = filtered.filter((day) => {
        const hour = day.scheduledHours.find((hour) => {
          if (filter === ExamType.Theoretical) {
            return hour.theoryExams && hour.theoryExams.length > 0;
          } else if (filter === ExamType.Practical) {
            return hour.practiceExams && hour.practiceExams.length > 0;
          } else if (filter === ExamType.Linked) {
            return hour.linkedExamsDto && hour.linkedExamsDto.length > 0;
          }
        });
        const hasExam = !isNullOrUndefined(hour);

        const dateKey = this.getDateKey(this.convertDateToNgbDate(new Date(day.day)));
        this.isDayScheduled.set(dateKey, hasExam);

        return hasExam;
      });
    }

    if (!isNullOrUndefined(dateRange)) {
      filtered = filtered.filter((day) => {
        const date = new Date(day.day);
        date.setHours(0, 0, 0, 0);
        return date >= dateRange.fromDate && date <= dateRange.toDate;
      });
    }

    return filtered;
  }

  showMoreDays(): void {
    this.numberOfDaysToShow += INCREMENT_NUMBER_OF_DAYS_TO_SHOW;
  }

  onExamSelected(exam: SelectedExam): void {
    this.examSelected.emit(exam);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}

import {CONTROL_CARD_APPLICATION_REASON_ROUTE} from '../application-reason/_consts/application-reason.route.const';
import {
  CONTROL_CARD_APPLICATION_PERSONAL_DATA_PARAMETRIZED_ROUTE
} from '../personal-data/_consts/personal-data.route.const';
import {CONTROL_CARD_APPLICATION_ATTACHMENTS_PARAMETRIZED_ROUTE} from '../attachments/_consts/attachments.route.const';
import {CONTROL_CARD_APPLICATION_SUMMARY_PARAMETRIZED_ROUTE} from '../summary/_consts/summary.route.const';
import {CONTROL_CARD_APPLICATION_PAYMENT_PARAMETRIZED_ROUTE} from '../payment/_consts/payment-route.const';
import {CONTROL_CARD_COMPLAINT_REASON_ROUTE} from '../complaint-reason/_consts/complaint-reason.route.const';
import {
  STC_TACHOGRAPH_APPLICATIONS_ROUTE,
  STC_TACHOGRAPH_COMPLAINTS_ROUTE,
  STC_TACHOGRAPH_CORRECTIONS_ROUTE
} from '../../_consts/stc-route.const';

export const CONTROL_CARD_APPLICATION_ROUTE = 'karta-kontrolna';

export const INDIVIDUAL_CONTROL_CARD_APPLICATION_STEPS_ROUTES: string[] = [
  `/${STC_TACHOGRAPH_APPLICATIONS_ROUTE}/${CONTROL_CARD_APPLICATION_ROUTE}/${CONTROL_CARD_APPLICATION_REASON_ROUTE}`,
  `/${STC_TACHOGRAPH_APPLICATIONS_ROUTE}/${CONTROL_CARD_APPLICATION_ROUTE}/${CONTROL_CARD_APPLICATION_PERSONAL_DATA_PARAMETRIZED_ROUTE}`,
  `/${STC_TACHOGRAPH_APPLICATIONS_ROUTE}/${CONTROL_CARD_APPLICATION_ROUTE}/${CONTROL_CARD_APPLICATION_ATTACHMENTS_PARAMETRIZED_ROUTE}`,
  `/${STC_TACHOGRAPH_APPLICATIONS_ROUTE}/${CONTROL_CARD_APPLICATION_ROUTE}/${CONTROL_CARD_APPLICATION_SUMMARY_PARAMETRIZED_ROUTE}`,
  `/${STC_TACHOGRAPH_APPLICATIONS_ROUTE}/${CONTROL_CARD_APPLICATION_ROUTE}/${CONTROL_CARD_APPLICATION_PAYMENT_PARAMETRIZED_ROUTE}`
];

export const INDIVIDUAL_CONTROL_CARD_COMPLAINT_STEPS_ROUTES: string[] = [
  `/${STC_TACHOGRAPH_COMPLAINTS_ROUTE}/${CONTROL_CARD_APPLICATION_ROUTE}/${CONTROL_CARD_COMPLAINT_REASON_ROUTE}`,
  `/${STC_TACHOGRAPH_COMPLAINTS_ROUTE}/${CONTROL_CARD_APPLICATION_ROUTE}/${CONTROL_CARD_APPLICATION_PERSONAL_DATA_PARAMETRIZED_ROUTE}`,
  `/${STC_TACHOGRAPH_COMPLAINTS_ROUTE}/${CONTROL_CARD_APPLICATION_ROUTE}/${CONTROL_CARD_APPLICATION_ATTACHMENTS_PARAMETRIZED_ROUTE}`,
  `/${STC_TACHOGRAPH_COMPLAINTS_ROUTE}/${CONTROL_CARD_APPLICATION_ROUTE}/${CONTROL_CARD_APPLICATION_SUMMARY_PARAMETRIZED_ROUTE}`,
  `/${STC_TACHOGRAPH_COMPLAINTS_ROUTE}/${CONTROL_CARD_APPLICATION_ROUTE}/${CONTROL_CARD_APPLICATION_PAYMENT_PARAMETRIZED_ROUTE}`
];

export const INDIVIDUAL_CONTROL_CARD_CORRECTION_STEPS_ROUTES: string[] = [
  `/${STC_TACHOGRAPH_CORRECTIONS_ROUTE}/${CONTROL_CARD_APPLICATION_ROUTE}/${CONTROL_CARD_APPLICATION_PERSONAL_DATA_PARAMETRIZED_ROUTE}`,
  `/${STC_TACHOGRAPH_CORRECTIONS_ROUTE}/${CONTROL_CARD_APPLICATION_ROUTE}/${CONTROL_CARD_APPLICATION_ATTACHMENTS_PARAMETRIZED_ROUTE}`,
  `/${STC_TACHOGRAPH_CORRECTIONS_ROUTE}/${CONTROL_CARD_APPLICATION_ROUTE}/${CONTROL_CARD_APPLICATION_SUMMARY_PARAMETRIZED_ROUTE}`
];

export const BUSINESS_CONTROL_CARD_APPLICATION_STEPS_ROUTES: string[] = [
  `/${STC_TACHOGRAPH_APPLICATIONS_ROUTE}/${CONTROL_CARD_APPLICATION_ROUTE}/${CONTROL_CARD_APPLICATION_REASON_ROUTE}`,
  `/${STC_TACHOGRAPH_APPLICATIONS_ROUTE}/${CONTROL_CARD_APPLICATION_ROUTE}/${CONTROL_CARD_APPLICATION_PERSONAL_DATA_PARAMETRIZED_ROUTE}`,
  `/${STC_TACHOGRAPH_APPLICATIONS_ROUTE}/${CONTROL_CARD_APPLICATION_ROUTE}/${CONTROL_CARD_APPLICATION_ATTACHMENTS_PARAMETRIZED_ROUTE}`,
  `/${STC_TACHOGRAPH_APPLICATIONS_ROUTE}/${CONTROL_CARD_APPLICATION_ROUTE}/${CONTROL_CARD_APPLICATION_SUMMARY_PARAMETRIZED_ROUTE}`,
  `/${STC_TACHOGRAPH_APPLICATIONS_ROUTE}/${CONTROL_CARD_APPLICATION_ROUTE}/${CONTROL_CARD_APPLICATION_PAYMENT_PARAMETRIZED_ROUTE}`
];

export const BUSINESS_CONTROL_CARD_COMPLAINT_STEPS_ROUTES: string[] = [
  `/${STC_TACHOGRAPH_COMPLAINTS_ROUTE}/${CONTROL_CARD_APPLICATION_ROUTE}/${CONTROL_CARD_COMPLAINT_REASON_ROUTE}`,
  `/${STC_TACHOGRAPH_COMPLAINTS_ROUTE}/${CONTROL_CARD_APPLICATION_ROUTE}/${CONTROL_CARD_APPLICATION_PERSONAL_DATA_PARAMETRIZED_ROUTE}`,
  `/${STC_TACHOGRAPH_COMPLAINTS_ROUTE}/${CONTROL_CARD_APPLICATION_ROUTE}/${CONTROL_CARD_APPLICATION_ATTACHMENTS_PARAMETRIZED_ROUTE}`,
  `/${STC_TACHOGRAPH_COMPLAINTS_ROUTE}/${CONTROL_CARD_APPLICATION_ROUTE}/${CONTROL_CARD_APPLICATION_SUMMARY_PARAMETRIZED_ROUTE}`,
  `/${STC_TACHOGRAPH_COMPLAINTS_ROUTE}/${CONTROL_CARD_APPLICATION_ROUTE}/${CONTROL_CARD_APPLICATION_PAYMENT_PARAMETRIZED_ROUTE}`
];

export const BUSINESS_CONTROL_CARD_CORRECTION_STEPS_ROUTES: string[] = [
  `/${STC_TACHOGRAPH_CORRECTIONS_ROUTE}/${CONTROL_CARD_APPLICATION_ROUTE}/${CONTROL_CARD_APPLICATION_PERSONAL_DATA_PARAMETRIZED_ROUTE}`,
  `/${STC_TACHOGRAPH_CORRECTIONS_ROUTE}/${CONTROL_CARD_APPLICATION_ROUTE}/${CONTROL_CARD_APPLICATION_ATTACHMENTS_PARAMETRIZED_ROUTE}`,
  `/${STC_TACHOGRAPH_CORRECTIONS_ROUTE}/${CONTROL_CARD_APPLICATION_ROUTE}/${CONTROL_CARD_APPLICATION_SUMMARY_PARAMETRIZED_ROUTE}`
];

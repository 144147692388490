import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Slide } from '../slider/models/slider.model';

const MAIN_PAGE_ENDPOINT = '/homepage';

@Injectable()
export class MainPageService {
  constructor() {}

  // todo delete magic ids when real cms will be up and running
  getSlides(): Slide[] {
    return [
      {
        buttonLabel: 'Czytaj dalej',
        description: '',
        destination: {
          id: null,
          type: 'basket_disable_article'
        },
        header: '<span class="header-red">UWAGA</span><br/>ZMIANA REGULAMINU SERWISU INFOCAR<br/>WYGASZAMY USŁUGI PŁATNOŚCI ZA DOKUMENTY',
        href: null,
        id: 204,
        img: 'assets/images/sliders/AS.jpg',
        ordinalNumber: 1,
        subheader: '',
        alt: 'Zdjęcie prezentujące kobiete patrzącą w laptopa'
      },
      {
        buttonLabel: 'Czytaj dalej',
        description: '',
        destination: {
          id: null,
          type: 'new_stc_applications_article'
        },
        header:
          '<span class="header-red">UWAGA</span>',
        href: null,
        id: 205,
        img: 'assets/images/sliders/new_stc_application.png',
        ordinalNumber: 2,
        subheader: 'NOWE WZORY WNIOSKÓW',
        alt: 'Zdjęcie prezentujące kluczyk do samochodu i zamek'
      },
      {
        buttonLabel: 'Czytaj dalej',
        description: '',
        destination: {
          id: null,
          type: 'g2v2_info_article'
        },
        header:
          'NOWE KARTY TACHOGRAFOWE G2V2',
        href: null,
        id: 206,
        img: 'assets/images/sliders/g2v2_banner.png',
        ordinalNumber: 3,
        subheader:
        'SPRAWDŹ, CO SIĘ ZMIENIŁO!',
        alt: 'Grafika przedstawiająca drogi przecinające się w mieście, z lotu ptaka'
      },
      {
        buttonLabel: 'Czytaj dalej',
        description: '',
        destination: {
          id: null,
          type: 'word_price_article'
        },
        header:
          'Ceny egzaminów idą w górę',
        href: null,
        id: 208,
        img: 'assets/images/sliders/slider_egzaminy_ceny.png',
        ordinalNumber: 4,
        subheader:
        'kolejne sejmiki wojewódzkie zmieniają stawki',
        alt: 'Zdjęcie prezentujące samochód szkoły jazdy.'
      },
      {
        buttonLabel: 'Sprawdź',
        description: '',
        destination: {
          id: null,
          type: 'driver_license_word_exam_check_availability'
        },
        header: '<br>Sprawdź dostępność terminów <br class="hidden-mobile">egzaminu na prawo jazdy',
        href: null,
        id: 210,
        img: 'assets/images/sliders/promo-slider.jpg',
        ordinalNumber: 5,
        subheader:
          'Chcesz jak najszybciej zapisać się na egzamin?<br>Sprawdź, gdzie i kiedy zdasz go najwcześniej!',
        alt: 'Zdjęcie prezentujące telefon komórkowy z otwarym oknem info-car.pl'
      },
      {
        buttonLabel: 'Dowiedz się więcej',
        description: '',
        destination: { id: null, type: 'duedate_article' },
        header: '<span class=\'header-red\'>COVID-19</span> PRZEDŁUŻENIE WAŻNOŚCI DOKUMENTÓW',
        href: null,
        id: 211,
        img: 'assets/images/sliders/waznosc.jpg',
        ordinalNumber: 6,
        subheader:
          'Wszystkie te dokumenty i uprawnienia, których termin upłynął w okresie stanu zagrożenia epidemicznego lub stanu epidemii pozostaną ważne i będą ważne także w okresie 60 dni od daty zakończenia stanu zagrożenia epidemicznego lub stanu epidemii.',
        alt: ''
      },
      {
        buttonLabel: 'Dowiedz się więcej',
        description: '',
        destination: { id: null, type: 'applications_article' },
        header: '<span class="header-red">UWAGA</span>',
        href: null,
        id: 212,
        img: 'assets/images/sliders/tacho.jpg',
        ordinalNumber: 7,
        subheader:
          'OD 4 MAJA 2020 R. PWPW BĘDZIE <br>PRZYJMOWAŁA WNIOSKI JEDYNIE NA <br>AKTUALNIE OBOWIĄZUJĄCYCH <br>FORMULARZACH.',
        alt: ''
      },
      {
        buttonLabel: 'Dowiedz się więcej',
        description: '',
        destination: null,
        header: 'Potrzebujesz podpisu kwalifikowanego?',
        href: 'https://sigillum.pl/',
        id: 213,
        img: 'assets/images/sliders/sigillum.jpg',
        ordinalNumber: 8,
        subheader:
          'Sprawdź ofertę centrum usług zaufania sigillum <br>teraz dostępny również "Podpis kwalifikowany na e-dowodzie"',
        alt: '' 
      },
      {
        id: 221,
        header: "CZEKASZ NA DOWÓD REJESTRACYJNY?",
        subheader: "SPRAWDŹ CZY TWÓJ DOWÓD REJESTRACYJNY JEST GOTOWY DO ODBIORU",
        description: "",
        buttonLabel: "Więcej",
        img: "assets/images/sliders/driver-license.jpg",
        ordinalNumber: 9,
        href: null,
        destination: {
          id: null,
          type: 'check_registration_document_status'
        },
        alt: ''
      },
      {
        id: 222,
        header: "SKOŃCZYŁEŚ JUŻ KURS?",
        subheader: "ZAPISZ SIĘ NA EGZAMIN NA PRAWO JAZDY",
        description: "",
        buttonLabel: "Zapisz się",
        img: "assets/images/sliders/driver-course-finished.jpg",
        ordinalNumber: 10,
        href: null,
        destination: {
          id: null,
          type: 'driver_license_word_exam'
        },
        alt: 'Zdjęcie prezentujące wnętrze samochodu. Kobieta prowadzi samochód.'
      },
      {
        id: 223,
        header: "ADR ZASADY ZDOBYWANIA ZAŚWIADCZENIA",
        subheader: "SPRAWDŻ, JAK ZDOBYĆ ZAŚWIADCZENIA ADR",
        description: "",
        buttonLabel: "Więcej",
        img: "assets/images/sliders/adr.jpg",
        ordinalNumber: 11,
        href: null,
        destination: {
          id: null,
          type: 'adr_certificate_rules'
        },
        alt: ''
      },
      {
        id: 224,
        header: "JESTEŚ KIEROWCĄ ZAWODOWYM?",
        subheader: "UZYSKAJ KARTĘ DO TACHOGRAFU",
        description: "",
        buttonLabel: "Uzyskaj kartę",
        img: "assets/images/sliders/driver-tacho.jpg",
        ordinalNumber: 12,
        href: null,
        destination: {
          id: null,
          type: 'tachograph_card'
        },
        alt: 'Zdjęcie prezentujące cysternę widzianą z lotu ptaka'
      }
    ];
  }
}

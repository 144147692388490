import { Component } from '@angular/core';

@Component({
  selector: 'app-radio-button-placeholder',
  template: `
    <article class="placeholder">
      <div class="placeholder__element placeholder__element--mark"></div>
      <div class="placeholder__element placeholder__element--label"></div>
    </article>
  `,
  styleUrls: ['./radio-button-placeholder.component.scss']
})
export class RadioButtonPlaceholderComponent {}

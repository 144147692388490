<section class="application-already-submitted-modal">
  <ng-template [ngIf]="content">
    <header class="header">
      <h1 class="header__title" [innerHtml]="content.title"></h1>
      <img
        role="button"
        alt="{{ content.btnCloseAria }}"
        (click)="onCancelClick()"
        tabindex="0"
        (keydown.enter)="onCancelClick()"
        class="header__close-btn"
        src="assets/images/close_mobile_.svg" />
    </header>
    <main class="content" appInternalLink>
      <p [innerHtml]="content.description"></p>
      <p [innerHtml]="content.myAccountDesc | safeHtml"></p>
    </main>
    <ic-ghost-button
      [type]="GhostButtonType.BIG_FIRST_RANK"
      [typeHTML]="'button'"
      [width]="136"
      (click)="onOkClick()"
      [justifyContent]="'center'">
      {{ content.buttonLabel }}
    </ic-ghost-button>
  </ng-template>
</section>

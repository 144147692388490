import { ControlCardNumberFormBinder } from './control-card-number.form-binder';
import {
  ControlCardNumberDto,
  ControlCardNumberFormValidation,
  ControlCardNumberStaticContent
} from './control-card-number.model';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { defaultControlCardNumberValidation } from './control-card-number.validation';
import { ControlCardApplicationDto } from 'src/app/shared/models/control-card-application/control-card-application.model';
import { CorrectionsErrors, FieldErrorEntry } from '../../../models/corrections/corrections.model';

@Component({
  selector: 'app-control-card-number',
  templateUrl: './control-card-number.component.html',
  styleUrls: ['./control-card-number.component.scss'],
  providers: [ControlCardNumberFormBinder]
})
export class ControlCardNumberComponent implements OnInit, OnDestroy {
  @Input() staticContent: ControlCardNumberStaticContent;
  @Input() formValidation: ControlCardNumberFormValidation = defaultControlCardNumberValidation;
  @Input() aggregateFormGroup: UntypedFormGroup;
  @Input() fetchedData: ControlCardNumberDto;
  @Input() reason: ControlCardApplicationDto.ReasonTypeEnum;
  @Input() htmlElementId: string;

  @Output() saveDraft: EventEmitter<any> = new EventEmitter();

  @Input() set correctionsErrors(data: CorrectionsErrors) {
    this._correctionsErrors = data;
    this.getFieldsErrors(data);
  }
  private _correctionsErrors: CorrectionsErrors;
  formGroup: UntypedFormGroup;
  formPrefix = 'controlAuthority';
  fieldsErrors: FieldErrorEntry[] = [];

  public constructor(private binder: ControlCardNumberFormBinder) {
    this.formGroup = this.binder.bindForm(this.formValidation);
  }

  public ngOnInit() {
    if (this.reason && this.reason !== ControlCardApplicationDto.ReasonTypeEnum.FIRSTISSUE) {
      this.formValidation = this.binder.addRequiredValidatorToDriverCardNumber(this.formValidation);
    }
    this.formGroup = this.binder.bindForm(this.formValidation);
    this.aggregateFormGroup.addControl(this.formValidation.formGroupName, this.formGroup);
    this.binder.fillFormWith(this.fetchedData);
  }

  public onBlur() {
    this.saveDraft.emit();
  }

  sectionErrorsExist(): boolean {
    return this._correctionsErrors?.sectionErrors.some((item) => item.entry === this.formPrefix);
  }

  getFieldsErrors(correctionsErrors: CorrectionsErrors) {
    this.fieldsErrors =
      correctionsErrors?.fieldErrors
        .filter(
          (item) =>
            item.entry.startsWith(this.formPrefix) || item.entry.startsWith('currentControlCard')
        )
        .map((entry) => {
          return {
            entry: entry.entry,
            previousValue: entry.previousValue
          };
        }) || [];
  }

  isFieldIncludedInCorrection(fieldName: string): boolean {
    return (
      this.fieldsErrors.some((err) => err.entry.endsWith(fieldName)) || this.sectionErrorsExist()
    );
  }

  findPreviousValue(fieldName: string): any {
    return this.fieldsErrors.find((err) => err.entry.endsWith(fieldName))?.previousValue;
  }

  public isControlRequired(controlName: string): boolean {
    return this.binder.isControlRequired(controlName);
  }

  ngOnDestroy(): void {
    if (this.aggregateFormGroup.contains(this.formValidation.formGroupName)) {
      this.aggregateFormGroup.removeControl(this.formValidation.formGroupName);
    }
  }
}

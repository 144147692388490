import { ToastrService } from 'ngx-toastr';
import { StcApplicationsService } from 'src/app/applications/stc/_services/stc-applications.service';
import { CardTypes } from './../../../../applications/stc/_consts/card-types.enum';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { BaseModalDirective } from '../base-modal';
import { Extensions } from 'src/app/shared/extensions/extensions';
import { GhostButtonType } from '../../ghost-button/ghost-button-type.enum';
import { ValidationPatterns } from 'src/app/shared/validation/validation-patterns.const';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-application-verification-modal',
  templateUrl: './application-verification-modal.component.html',
  styleUrls: ['./application-verification-modal.component.scss'],
  providers: [StcApplicationsService]
})
export class ApplicationVerificationModalComponent extends BaseModalDirective implements OnInit, OnDestroy {

  formGroup: UntypedFormGroup;
  cardType: CardTypes;
  GhostButtonType = GhostButtonType;
  accessAttempt: number = 0;
  id: string;
  paperOwnerId: string;

  constructor(
    private fb: UntypedFormBuilder,
    private stcService: StcApplicationsService,
  ) {
    super();

    this.formGroup = this.fb.group({
      birthDate: ['',  Validators.compose([
        Validators.minLength(8),
        Validators.maxLength(11),
        Validators.pattern(ValidationPatterns.date),
      ])],
      nip: '',
    })
   }

  ngOnInit(): void {
    this.cardType = this.getData()?.cardType;
    this.id = this.getData()?.id;
    this.paperOwnerId = this.getData()?.paperOwnerId;
    if(this.shoudlAskForNip()) {
      this.formGroup.get('nip').setValidators(Validators.required);
    } else {
      this.formGroup.get('birthDate').setValidators(Validators.required);
    }
  }

  onCancelClick(): void {
    this.close(null);
  }

  shoudlAskForNip(): boolean {
    return this.cardType !== CardTypes.DRIVER_CARD && this.cardType !== CardTypes.TEMPORARY_DRIVER_CARD
  }

  ngOnDestroy(): void {
    this.accessAttempt = 0;
  }

  handlePoolingForAccess(): void {
    if(this.accessAttempt === 2) {
      this.close(false);
    } else {
      this.formGroup.get('birthDate')?.reset();
      this.formGroup.get('nip')?.reset();
      this.formGroup.get('birthDate')?.markAsTouched();
      this.formGroup.get('nip')?.markAsTouched();
      Extensions.scrollToFirstInvalidInput();
      this.accessAttempt++;
    }
  }

  onConfirmClick(): void {
    if(this.formGroup.valid) {
      this.stcService.verifyAccessToSite(this.formGroup.get('birthDate').value, this.formGroup.get('nip').value, this.id, this.cardType, this.paperOwnerId).subscribe({
        next: value => {
          this.close(true);
        },
        error: (err: HttpErrorResponse) => {
          if(err.status === 401) {
            this.handlePoolingForAccess();
          }
        }
      })
    } else {
      Extensions.scrollToFirstInvalidInput();
    }
  }
}

import { nameof, Extensions } from 'src/app/shared/extensions/extensions';
import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { BaseFormBinderService } from 'src/app/shared/services/form/base-form-binder.service';
import { AcceptancesFormValidation } from '../acceptances-validation';
import { AcceptancesFormConfig } from '../_models/acceptances-form.model';
import { AcceptanceDto, AcceptanceType } from 'src/app/core/model/acceptances-model';
import { AcceptancesStaticContent } from '../_models/acceptances-static-content.model';

@Injectable()
export class AcceptancesFormBuilderService extends BaseFormBinderService {
  acceptances: AcceptanceDto[];

  constructor() {
    super();
  }

  public build(
    staticContent: AcceptancesStaticContent,
    formValidation?: AcceptancesFormValidation,
    missingAcceptances?: AcceptanceDto[],
    blockRequired?: boolean
  ): AcceptancesFormConfig {
    if (missingAcceptances) {
      this.acceptances = missingAcceptances;
    } else {
      this.acceptances = [
        { name: AcceptanceType.RULES, required: true },
        { name: AcceptanceType.INFOS, required: true },
        { name: AcceptanceType.MARKETING_PHONE, required: false },
        { name: AcceptanceType.MARKETING_EMAIL, required: false }
      ];
    }

    return this.createConfig(staticContent, formValidation, this.acceptances, blockRequired);
  }

  private createConfig(
    staticContent: AcceptancesStaticContent,
    formValidation: AcceptancesFormValidation,
    missingAcceptances: AcceptanceDto[],
    blockRequired?: boolean
  ): AcceptancesFormConfig {
    const controls = missingAcceptances.map((acceptance) => ({
      inputId: acceptance.name,
      formControlName: acceptance.name,
      label: staticContent[acceptance.name].label,
      button: staticContent[acceptance.name].button,
      required: acceptance.required
    }));

    Object.keys(formValidation).forEach((key) => {
      if (!missingAcceptances.find((acc) => acc.name === key)) {
        delete formValidation.formFields[key];
      }
    });

    const form = {
      controls: controls,
      form: this.bindForm(formValidation)
    };

    if (blockRequired) {
      form.controls.forEach((control) => {
        const formControl = form.form.get(control.formControlName);
        if (formControl && control.required) {
          formControl.disable();
        }
      });
    }

    return form;
  }

  protected createForm(fieldValidations: AcceptancesFormValidation): UntypedFormGroup {
    const fg = new UntypedFormGroup({});
    const formFields = Object.entries(fieldValidations.formFields);
    formFields.forEach(([name, validator]: [string, ValidatorFn]) => {
      fg.addControl(name, new UntypedFormControl(false, validator));
    });

    return fg;
  }

  public setControlsValueTo(value: any) {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.controls[key].disabled ? null : this.form.controls[key].patchValue(value);
    });

    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
  }
}

import { GhostButtonType } from './ghost-button-type.enum';
import { Component, OnInit, Input, ElementRef, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ic-ghost-button',
  templateUrl: './ghost-button.component.html',
  styleUrls: ['./ghost-button.component.scss']
})
export class GhostButtonComponent implements OnInit {
  @Input() showArrow = false;
  @Input() arrowLeft = false;
  @Input() type: GhostButtonType;
  @Input() typeHTML = 'submit';
  @Input() disabled = false;
  @Input() inline = false;
  @Input() width: number;
  @Input() height: number;
  @Input() justifyContent: string;
  @Input() isDownload: boolean;
  @Input() fileName: string;
  @Input() linkHref: string;
  @Input() loading = false;
  @Input() ariaLabel: string;
  @Input() htmlId: string;

  constructor(private selfReference: ElementRef) {}

  ngOnInit() {
    const selfDOM: HTMLElement = this.selfReference.nativeElement.querySelector('.ghost-btn');

    switch (this.type) {
      case GhostButtonType.BIG_FIRST_RANK:
        break;
      case GhostButtonType.BIG_FIRST_RANK_FLEX:
        selfDOM.classList.add('flexible');
        break;
      case GhostButtonType.BIG_SECOND_RANK:
        selfDOM.classList.add('narrow', 'transparent-blue-text-to-dark-blue-text');
        break;
      case GhostButtonType.BIG_SECOND_RANK_FLEX:
        selfDOM.classList.add('flexible', 'transparent-blue-text-to-dark-blue-text');
        break;
      case GhostButtonType.SMALL_FIRST_RANK:
        selfDOM.classList.add('narrow', 'slim');
        break;
      case GhostButtonType.SMALL_FIRST_RANK_FLEX:
        selfDOM.classList.add('narrow', 'slim', 'flexible');
        break;
      case GhostButtonType.SMALL_SECOND_RANK:
        selfDOM.classList.add('narrow', 'slim', 'transparent-blue-text-to-dark-blue-text');
        break;
      case GhostButtonType.SMALL_SECOND_RANK_FLEX:
        selfDOM.classList.add(
          'narrow',
          'slim',
          'transparent-blue-text-to-dark-blue-text',
          'flexible'
        );
        break;
      case GhostButtonType.SLIDER:
        selfDOM.classList.add('transparent-to-white', 'inline');
        break;
      case GhostButtonType.SLIDER_CONTROL:
        selfDOM.classList.add('slider-control', 'transparent-to-light-gray');
        break;
      case GhostButtonType.FOOTER_OTHER_PRODUCTS:
        selfDOM.classList.add('footer-other-products');
        break;
      case GhostButtonType.WORD_EXAM_SMALL_FLEX:
        selfDOM.classList.add(
          'narrow',
          'slim',
          'transparent-blue-text-to-dark-blue-text',
          'flexible',
          'blue-hover'
        );
        break;
      case GhostButtonType.SIMPLE_LINK:
        selfDOM.classList.add('simple-link-button');
        break;
      default: {
      }
    }

    if (this.showArrow && this.arrowLeft) {
      selfDOM.classList.add('back');
    }

    if (this.justifyContent) {
      selfDOM.style.justifyContent = this.justifyContent;
    }
  }

  downloadFile() {
    const link = document.createElement('a');
    link.download = this.fileName;
    link.href = this.linkHref;
    link.click();
  }
}

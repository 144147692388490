import {CheckStatusService} from '../../../shared/services/check-status/check-status.service';
import {Observable} from 'rxjs';
import {AttachmentMetadataList} from '../../../shared/components/stc/attachments/model/attachment-metadata-list';
import {CardTypes} from '../_consts/card-types.enum';
import {AttachmentDtoList} from '../../../shared/models/attachments/attachment-dto-list.model';

export namespace StcWizardGuardService {
  export const DRIVER_CARD_TYPE_URL_SEGMENT = 'DRIVER_CARD';
  export const TEMPORARY_DRIVER_CARD_TYPE_URL_SEGMENT = 'TEMPORARY_DRIVER_CARD';
  export const WORKSHOP_CARD_TYPE_URL_SEGMENT = 'WORKSHOP_CARD';
  export const CONTROL_CARD_TYPE_URL_SEGMENT = 'CONTROL_CARD';
  export const COMPANY_CARD_TYPE_URL_SEGMENT = 'COMPANY_CARD';
  export function mapCardTypeToURLSegment(cardType: CardTypes): string {
    switch (cardType) {
      case CardTypes.DRIVER_CARD:
        return DRIVER_CARD_TYPE_URL_SEGMENT;
        case CardTypes.TEMPORARY_DRIVER_CARD:
          return TEMPORARY_DRIVER_CARD_TYPE_URL_SEGMENT;
      case CardTypes.WORKSHOP_CARD:
        return WORKSHOP_CARD_TYPE_URL_SEGMENT;
      case CardTypes.COMPANY_CARD:
        return COMPANY_CARD_TYPE_URL_SEGMENT;
      case CardTypes.CONTROL_CARD:
        return CONTROL_CARD_TYPE_URL_SEGMENT;
      default:
        throw Error('Cannot match card type');
    }
  }
}
export interface StcWizardGuardService extends CheckStatusService {
  getAttachments(id: string, cardType: CardTypes): Observable<AttachmentDtoList>;

  getRequiredAttachmentsListForCardType(
    id: string,
    cardType: CardTypes
  ): Observable<AttachmentMetadataList>;

  moveBackward(id: string, status?: string, isPaper?: boolean): Observable<any>;
}

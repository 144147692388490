import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { BaseFormBinderService } from 'src/app/shared/services/form/base-form-binder.service';

import { ApplicationAttachmentType, AttachmentMetadata } from '../model';
import { AttachmentsFacadeService } from './attachments-facade.service';
import { AttachmentValidatorFactory } from './attachment-validator-factory';

@Injectable()
export class AttachmentsFormBinder extends BaseFormBinderService implements OnDestroy {
  private subs = new Subscription();
  private entitiesSizeChange$ = this.attachmentsService.entities$.pipe(
    map((entities) => entities.length),
    distinctUntilChanged()
  );

  constructor(
    private fb: UntypedFormBuilder,
    private attachmentsService: AttachmentsFacadeService,
    private attachmentValidatorFactory: AttachmentValidatorFactory
  ) {
    super();
  }

  protected createForm(): UntypedFormGroup {
    return this.fb.group({});
  }

  public initFormControls(
    configs: AttachmentMetadata[],
    applicationType: ApplicationAttachmentType
  ) {
    configs.forEach((config) => {
      const control = this.createControl(config);
      this.createEntityOnControlStatusChanges(control, config, applicationType);
    });

    this.validateControlsOnEntitiesSizeChange();
  }

  private createControl(config: AttachmentMetadata) {
    const control = this.fb.control(
      null,
      this.attachmentValidatorFactory.composeSyncValidators(config),
      this.attachmentValidatorFactory.composeAsyncValidators(config)
    );
    this.form.addControl(config.type, control);
    return control;
  }

  private createEntityOnControlStatusChanges(
    control: UntypedFormControl,
    config: AttachmentMetadata,
    applicationType: ApplicationAttachmentType
  ) {
    this.nextSub = control.statusChanges
      .pipe(filter((status) => status === 'VALID' && control.value))
      .subscribe(() => {
        this.attachmentsService.createReadyToUploadAttachmentsFromFiles(
          config.type,
          [...control.value],
          applicationType
        );
      });
  }

  private validateControlsOnEntitiesSizeChange() {
    this.nextSub = this.entitiesSizeChange$.subscribe(() => this.updateControlsValidity());
  }

  submit() {
    super.submit({ updateValueAndValidity: false });
    this.updateControlsValidity();
  }

  private updateControlsValidity() {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).patchValue(null);
    });
  }

  set nextSub(val: Subscription) {
    this.subs.add(val);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}

import { IndicatorService } from './../../services/indicator/indicator.service';
import { Directive, ElementRef, Input, OnInit, OnDestroy, NgZone, Renderer2 } from '@angular/core';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

/**
 * @description
 * Dyrektywa wyświetlająca animację na elemencie, odpalaną z komponentu.
 * Z każdym wywołaniem animacja startuje od pierwszej klatki, płynnie przechodząc z poprzedniego stanu, bez efektu migotania.
 *
 * @usageNotes
 * ### Użycie
 * np.
 * <div [appIndicateFor]="'identyfikator - dowolny ciąg znaków'"> nasz element nr. 1 </div>
 * <div [appIndicateFor]="'identyfikator - dowolny ciąg znaków'"> nasz element nr. 2 </div>
 *
 * W komponencie wstrzykujemy serwis IndicatorService.
 * Nie jest on wstrzykiwany globalnie, więc musimy się upewnić, że dostarczamy tę samą instancję jeśli chemy uruchamiać z jednego miejsca
 * animację na wielu komponentach.
 *
 * #### Uruchomienie animacji
 *
 *  indicatorService: IndicatorService;
 *  indicatorService.indicate('identyfikator - dowolny ciąg znaków');
 */
@Directive({
  selector: '[appIndicateFor]'
})
export class IndicateDirective implements OnInit, OnDestroy {
  @Input('appIndicateFor') eventName: string;
  @Input('animClass') animationClassName = 'indicate--default';
  @Input('firstFrameClass') animationFirstFrameClassName = 'indicate--default-first-frame';
  @Input('animDuration') animationDurationInMillis = 3000;

  private animationId;
  private subscription: Subscription;

  constructor(
    private elementRef: ElementRef,
    private indicatorService: IndicatorService,
    private zone: NgZone,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.subscription = this.indicatorService
      .getIndicateEvent$()
      .pipe(filter((result) => result === this.eventName))
      .subscribe(() => this.requestFirstFrame());
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private requestFirstFrame() {
    const firstFrameFun = this.setupFirstFrame.bind(this);
    this.zone.runOutsideAngular(() => {
      requestAnimationFrame(firstFrameFun);
    });
  }

  private setupFirstFrame() {
    const nextFramesFun = this.setupNextFrames.bind(this);
    this.renderer.removeClass(this.elementRef.nativeElement, this.animationClassName);
    this.renderer.addClass(this.elementRef.nativeElement, this.animationFirstFrameClassName);
    requestAnimationFrame(nextFramesFun);
  }

  private setupNextFrames() {
    const clearAnimationFun = this.clearAnimation.bind(this);
    this.renderer.addClass(this.elementRef.nativeElement, this.animationClassName);
    this.renderer.removeClass(this.elementRef.nativeElement, this.animationFirstFrameClassName);
    clearTimeout(this.animationId);
    this.animationId = setTimeout(clearAnimationFun, this.animationDurationInMillis);
  }

  private clearAnimation() {
    this.renderer.removeClass(this.elementRef.nativeElement, this.animationClassName);
    this.renderer.removeClass(this.elementRef.nativeElement, this.animationFirstFrameClassName);
  }
}

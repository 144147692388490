import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { LayoutState } from 'src/app/layout/_store/layout.reducer';
import { SharedState } from 'src/app/shared/store/shared.reducer';

@Injectable()
export class ApplicationStartupService {
  constructor(private layoutStore: Store<LayoutState>, private sharedStore: Store<SharedState>) {}

  init(): Promise<any> {
    return new Promise((resolve, _) => {
      this.sharedStore.pipe(take(1)).subscribe((store) => {
        if (!store.searchServicesData) {
          // todo add translation to store
        }
      });
    });
  }
}

export function startupFunction(startup: ApplicationStartupService) {
  return () => {
    startup.init();
  };
}

import { AnonymousGuard } from 'src/app/core/guard/anonymous-guard';
import { ErrorModule } from './error/error.module';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';

import * as fromSearch from './_store/search.reducer';
import * as fromType from './_store/type.reducer';
import { AuthModule } from './auth';
import { AuthGuard } from './guard/auth-guard';
import { CompanyGuard } from './guard/company-guard';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { LanguageHeaderInterceptor } from './interceptors/language-header.interceptor';
import { EmployerTypeService } from './services/employer-type.service';
import { ApplicationStartupService, startupFunction } from './startup/application-startup.service';
import { AcceptancesService } from './services/acceptances-service';
import { BlobErrorHttpInterceptor } from './interceptors/blob-error-http.interceptor';
import { StcPaperInterceptor } from './interceptors/stc-paper.interceptor';

// Przeznaczony do importu tylko w AppModule!
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule,
    StoreModule.forFeature(fromSearch.featureName, fromSearch.searchCacheReducer),
    StoreModule.forFeature(fromType.featureName, fromType.employerTypeReducer),
    AuthModule,
    ErrorModule
  ],
  providers: [
    ApplicationStartupService,
    EmployerTypeService,
    AcceptancesService,
    AuthGuard,
    CompanyGuard,
    AnonymousGuard,
    {
      provide: APP_INITIALIZER,
      useFactory: startupFunction,
      deps: [ApplicationStartupService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageHeaderInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BlobErrorHttpInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: StcPaperInterceptor,
      multi: true
    },
  ]
})
export class CoreModule {
  constructor(private employerTypeService: EmployerTypeService) {}
}

<div class="cropped__instruction">
  <div class="cropped__instruction-wrapper">
    <div class="cropped__instruction-wrapper--padding">
      <header class="cropped__instruction-wrapper--header">
        <div class="cropped__instruction-wrapper--header-title">
          <h2>{{ pageTexts.title }}</h2>
          <button [attr.aria-label]="pageTexts.btnCloseAria" (click)="onClose()" class="close-btn">
            <svg-icon class="close-btn-icon" src="assets/images/close_mobile_.svg"></svg-icon>
          </button>
        </div>
        <p class="cropped__instruction-wrapper--headersubTitle">
          {{ pageTexts.subtitle }}
        </p>
      </header>
    </div>
    <main class="cropped__instruction-wrapper--main">
      <div class="cropped__instruction-wrapper--padding">
        <section class="cropped__instruction-wrapper--list">
          <h2>{{ pageTexts.listTitle }}</h2>
          <ul>
            <li *ngFor="let item of pageTexts.list">{{ item }}</li>
          </ul>
        </section>
        <section class="cropped__instruction-wrapper--image">
          <h2 [innerHTML]="pageTexts.rightPictureTitle"></h2>
          <div class="cropped__instruction-wrapper--image-box">
            <figure
              *ngFor="let image of pageTexts.rightImages"
              class="cropped__instruction-wrapper--image-box-figure">
              <img loading="lazy" [src]="image.imageUrl" [alt]="image.alt" />
            </figure>
            <!--            <figure class="cropped__instruction-wrapper&#45;&#45;image-box-figure">-->
            <!--              <img-->
            <!--                loading="lazy"-->
            <!--                src="assets/images/tachograf/prawidlowe1.png"-->
            <!--                alt="Portret kobiety z rozpuszczonymi włosami" />-->
            <!--            </figure>-->
            <!--            <figure class="cropped__instruction-wrapper&#45;&#45;image-box-figure">-->
            <!--              <img-->
            <!--                loading="lazy"-->
            <!--                src="assets/images/tachograf/prawidlowe2.png"-->
            <!--                alt="Portret kobiety ze spiętymi włosami" />-->
            <!--            </figure>-->
          </div>
        </section>
        <section class="cropped__instruction-wrapper--image">
          <h2 [innerHTML]="pageTexts.wrongPictureTitle"></h2>
        </section>
      </div>

      <div class="cropped__instruction-wrapper--image-carousel">
        <app-responsive-carousel
          [parentMargin]="'36px'"
          [items]="pageTexts.wrongImages"></app-responsive-carousel>
      </div>

      <div class="cropped__instruction-wrapper--padding">
        <ic-ghost-button
          class="close-button"
          [typeHTML]="'button'"
          [showArrow]="false"
          [width]="123"
          (click)="onClose()">
          <span class="forwardSpan">{{ pageTexts.button }}</span>
        </ic-ghost-button>
      </div>
    </main>
  </div>
</div>

<div class="container">
  <h3 class="col-12 browse">{{ 'MAIN_PAGE.SEARCH_SECTION.orBrowseSubtitle' | translate }}</h3>

  <div class="row">
    <div class="categories col-12 col-lg-8">
      <a routerLink="/{{ category.id }}" class="category" *ngFor="let category of categories">
        <img
          src="{{ category.categoryIcon.url }}"
          alt="{{ category.categoryIcon.alt }}"
          class="category-icon" />
        <h3 class="category-title">{{ category.categoryTitle }}</h3>
        <p class="category-description">{{ category.categoryDescription }}</p>
        <div class="round-button">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
            <defs>
              <style>
                .cls-1 {
                  fill: #2434b3;
                }

                .cls-2 {
                  fill: #fff;
                }
              </style>
            </defs>
            <g transform="translate(24411 8431)">
              <circle
                data-name="Ellipse 2"
                class="cls-1"
                cx="15"
                cy="15"
                r="15"
                transform="translate(-24411 -8431)" />
              <path
                data-name="Path 5782"
                class="cls-2"
                d="M-69.119,10l-1.234,1.235,3.516,3.516h-8.015v1.5h7.6l-3.1,3.1,1.234,1.234,5.291-5.291Z"
                transform="translate(-24327.148 -8431)" />
            </g>
          </svg>
        </div>
      </a>
    </div>

    <div class="col-12 col-lg-4 popular-services">
      <app-most-popular-services [borderlessStyle]="true" [hideAllServicesButton]="true">
      </app-most-popular-services>
    </div>
  </div>

  <div class="row">
    <div class="ic-btn">
      <a [routerLink]="[tachographLink]" class="link">
        <ic-ghost-button [ariaLabel]="'SERVICE_CATEGORY.seeAllAria' | translate" [showArrow]="true">
          <span>{{ 'MAIN_PAGE.SEARCH_SECTION.showAllButtons' | translate }}</span>
        </ic-ghost-button>
      </a>
    </div>
  </div>
</div>

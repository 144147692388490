import {LOCATION_INITIALIZED, registerLocaleData} from '@angular/common';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import localePL from '@angular/common/locales/pl';
import {APP_INITIALIZER, Injector, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NoPreloading, RouterModule} from '@angular/router';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {ToastrModule} from 'ngx-toastr';
import * as fromAuth from 'src/app/core/auth/auth.reducer';
import { environment } from 'src/environments/environment';
import {APP_ROUTING} from './app-routing.const';
import {AppComponent} from './app.component';
import {AuthEffects} from './core/auth/auth.effects';
import {CoreModule} from './core/core.module';
import {InfoBarModule} from './layout/info-bar/info-bar.module';
import {LayoutModule} from './layout/layout.module';
import {LoadersModule} from './layout/loaders/loaders.module';
import {SharedModule} from './shared/shared.module';
import {RouteEventsService} from './shared/services/route-events-service/route-events.service';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {TranslationModalComponent} from './shared/components/modal/translation-modal/translation-modal.component';
import {AppLanguage} from './app-language.enum';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    `assets/i18n/`,
    `.json?v=${Date.now().toString()}`
  );
}

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () =>
    new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
      locationInitialized.then(() => {
        const langToSet = AppLanguage.POLISH;
        translate.use(langToSet).subscribe(
          () => {
            console.log(`Successfully initialized '${langToSet}' language.'`);
          },
          () => {
            console.error(`Problem with '${langToSet}' language initialization.'`);
          },
          () => {
            resolve(null);
          }
        );
      });
    });
}

registerLocaleData(localePL);

@NgModule({
  declarations: [AppComponent, TranslationModalComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AngularSvgIconModule.forRoot(),
    ToastrModule.forRoot({
      preventDuplicates: true,
      timeOut: 3000,
      positionClass: 'toast-top-right',
      resetTimeoutOnDuplicate: true
    }),
    // NOTE: [RK] Preloading all modules disabled to check UX with on demand loading modules
    RouterModule.forRoot(APP_ROUTING, {
    onSameUrlNavigation: 'reload',
    scrollPositionRestoration: 'enabled',
    preloadingStrategy: NoPreloading,
    anchorScrolling: 'enabled',
    enableTracing: !environment.production && JSON.parse(localStorage.getItem('DEBUG_TRACING')) === true
    }),
    StoreModule.forRoot({ auth: fromAuth.authReducer }),
    EffectsModule.forRoot([AuthEffects]),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    CoreModule,
    InfoBarModule,
    LayoutModule,
    LoadersModule,
    SharedModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true
    },
    RouteEventsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

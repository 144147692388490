import { ShippingContactDetailsDto } from 'src/app/shared/models/employee/shipping-contact-details-dto.model';
import { FormOfRefundDto } from 'src/app/shared/models/applications/form-of-refund-dto.model';
import { CompanyDto } from './company-dto.model';
import { ApplicationDto } from 'src/app/shared/models/applications/application-dto.model';
import { StatusDto } from '../applications/status-dto.model';

export interface CompanyCardApplicationDto extends ApplicationDto {
  /**
   * Card shipping contact details
   */
  applicationKind: string;
  cardShippingContactDetails: ShippingContactDetailsDto;
  /**
   * Company details
   */
  companyDto: CompanyDto;
  companyStatementVehicleAllowedToPublicTraffic: boolean;
  /**
   * Checked when invoice will be electronic
   */
  electronicInvoice?: boolean;
  /**
   * Form of refund money
   */
  formOfRefund: FormOfRefundDto;
  /**
   * Application id
   */
  id?: string;
  paper?: boolean;
  /**
   * Reason of application
   */
  reasonType: CompanyCardApplicationDto.ReasonTypeEnum;
  /**
   * Application serial number
   */
  serialNumber?: number;
  /**
   * Status of application
   */
  status?: StatusDto;
  /**
   * Type of application
   */
  type: ApplicationDto.TypeEnum;
}
export namespace CompanyCardApplicationDto {
  export type ReasonTypeEnum =
    | 'FIRST_ISSUE'
    | 'CLOSE_EXPIRY_DATE'
    | 'CARD_LOSS'
    | 'CARD_THEFT'
    | 'DAMAGED_OR_DEFECTIVE_CARD'
    | 'INCORRECTLY_ENTERED_OR_LOST_PIN'
    | 'RESIDENCE_COUNTRY_CHANGE'
    | 'ADMINISTRATIVE_DATA_CHANGE'
    | 'PERSONAL_OR_ADMINISTRATIVE_DATA_CHANGE'
    | 'TIME_AND_NON_RENEWABLE_CARD'
    | 'RENEWAL_FOREIGN_CARD';
  export const ReasonTypeEnum = {
    FIRSTISSUE: 'FIRST_ISSUE' as ReasonTypeEnum,
    CLOSEEXPIRYDATE: 'CLOSE_EXPIRY_DATE' as ReasonTypeEnum,
    CARDLOSS: 'CARD_LOSS' as ReasonTypeEnum,
    CARDTHEFT: 'CARD_THEFT' as ReasonTypeEnum,
    DAMAGEDORDEFECTIVECARD: 'DAMAGED_OR_DEFECTIVE_CARD' as ReasonTypeEnum,
    INCORRECTLYENTEREDORLOSTPIN: 'INCORRECTLY_ENTERED_OR_LOST_PIN' as ReasonTypeEnum,
    RESIDENCECOUNTRYCHANGE: 'RESIDENCE_COUNTRY_CHANGE' as ReasonTypeEnum,
    ADMINISTRATIVEDATACHANGE: 'ADMINISTRATIVE_DATA_CHANGE' as ReasonTypeEnum,
    PERSONALORADMINISTRATIVEDATACHANGE: 'PERSONAL_OR_ADMINISTRATIVE_DATA_CHANGE' as ReasonTypeEnum,
    TIMEANDNONRENEWABLECARD: 'TIME_AND_NON_RENEWABLE_CARD' as ReasonTypeEnum,
    RENEWALFOREIGNCARD: 'RENEWAL_FOREIGN_CARD' as ReasonTypeEnum
  };
}

import { Component, OnInit, Input } from '@angular/core';
import { LegalInfoBoxDocumentModel } from './_models/legal-info-box-document.model';
import { EXTERNAL_DESTINATION_DICTIONARY } from '../../dictionary/external-route-dictionary.enum';

@Component({
  selector: 'app-legal-info-box',
  templateUrl: './legal-info-box.component.html',
  styleUrls: ['./legal-info-box.component.scss']
})
export class LegalInfoBoxComponent implements OnInit {
  externalLinks: Map<string, string>;
  @Input() title: string;
  @Input() legalDocuments: LegalInfoBoxDocumentModel[];

  constructor() {}

  ngOnInit() {
    this.externalLinks = EXTERNAL_DESTINATION_DICTIONARY;
  }
}

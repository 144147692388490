import { EventEmitter, Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { BaseFormBinderService } from 'src/app/shared/services/form/base-form-binder.service';
import { ValidationPatterns } from 'src/app/shared/validation/validation-patterns.const';
import { nameof } from './../../../extensions/extensions';
import { AddressDataFormValidation } from './address-data.model';
import { ValidationFunctions } from 'src/app/shared/validation/validation-functions.const';
import { ValidationService } from 'src/app/shared/validation/validation.service';

@Injectable()
export class AddressDataFormBinder extends BaseFormBinderService {
  private fieldValidation: AddressDataFormValidation;

  constructor(private validationService: ValidationService) {
    super();
  }
  protected createForm(fieldValidations: AddressDataFormValidation) {
    const fg = new UntypedFormGroup({});
    const formFields = Object.entries(fieldValidations.formFields);
    formFields.forEach(([name, validator]: [string, ValidatorFn]) => {
      fg.addControl(name, new UntypedFormControl(null, validator, ValidationFunctions.allowedCharsValidator(this.validationService)));
    });
    this.fieldValidation = fieldValidations;
    return fg;
  }

  public setMailingAddresOnChanges(emitter: EventEmitter<boolean>): void {
    this.form
      .get(nameof<AddressDataFormValidation['formFields']>('correspondenceAddressCheckbox'))
      .valueChanges.subscribe((newValue) => {
        emitter.emit(newValue);
      });
  }

  public setCountryCodeOnChanges(defaultCountryCode: string): void {
    this.form
      .get(nameof<AddressDataFormValidation['formFields']>('countryCode'))
      .valueChanges.subscribe((newValue) => {
        this.setPostCodeValidator(defaultCountryCode);
        this.getForm().get('postCode').updateValueAndValidity();
      });
  }

  private setPostCodeValidator(defaultCountryCode: string) {
    if (this.get('countryCode') === defaultCountryCode) {
      this.getForm()
        .get('postCode')
        .setValidators([
          this.fieldValidation.formFields.postCode,
          Validators.pattern(ValidationPatterns.polishPostCode)
        ]);
    } else {
      this.getForm().get('postCode').setValidators([this.fieldValidation.formFields.postCode]);
    }
  }
}

import { takeUntil } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import {
  CorrectionsErrors,
  FieldErrorEntry
} from 'src/app/shared/models/corrections/corrections.model';
import { PersonalDocument } from 'src/app/shared/models/employee/personal-document.model';
import { WorkshopCardApplicationDto } from 'src/app/shared/models/workshop-card-application/workshop-card-application.model';
import { PersonDataFormBinder } from './person-data.form-binder';
import {
  PersonDataDto,
  PersonDataFormValidation,
  PersonDataStaticContent
} from './person-data.model';
import { defaultPersonDataValidation } from './person-data.validation';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-person-data',
  templateUrl: './person-data.component.html',
  styleUrls: ['./person-data.component.scss'],
  providers: [PersonDataFormBinder]
})
export class PersonDataComponent implements OnInit, OnDestroy {
  @Input() staticContent: PersonDataStaticContent;
  @Input() formValidation: PersonDataFormValidation = defaultPersonDataValidation;
  @Input() aggregateFormGroup: UntypedFormGroup;
  @Input() personalDocumentTypes: PersonalDocument[];
  @Input() fetchedData: PersonDataDto;
  @Input() reason: WorkshopCardApplicationDto.ReasonTypeEnum;
  @Input() htmlElementId: string;
  @Input() disableBDateOnInit = false;
  @Input() isPeselRequired = true;
  @Input() set correctionsErrors(data: CorrectionsErrors) {
    this._correctionsErrors = data;
    this.getFieldsErrors(data);
  }
  private _correctionsErrors: CorrectionsErrors;

  @Output() saveDraft: EventEmitter<any> = new EventEmitter();
  @Output() birthDateChanged: EventEmitter<any> = new EventEmitter();

  ReasonTypeEnum: typeof WorkshopCardApplicationDto.ReasonTypeEnum =
    WorkshopCardApplicationDto.ReasonTypeEnum;
  formGroup: UntypedFormGroup;
  @Input() set formPrefix(prefix: string) {
    this._formPrefix = prefix;
    this.getFieldsErrors(this._correctionsErrors);
  }
  private _formPrefix = 'person';
  fieldsErrors: FieldErrorEntry[] = [];
  destroy$ = new Subject();

  public constructor(private binder: PersonDataFormBinder) {}

  public ngOnInit() {
    this.formGroup = this.binder.bindForm(this.formValidation);
    this.aggregateFormGroup.addControl(this.formValidation.formGroupName, this.formGroup);
    this.binder.fillFormWith(this.fetchedData);
    if (this.disableBDateOnInit) {
      this.formGroup.controls.birthDate.disable();
    }

    if (!this.isPeselRequired) {
      this.formGroup.controls.birthDate.enable();
      this.formGroup.controls.birthDate.setValidators(Validators.required);

      this.formGroup.controls.pesel.valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
        next: value => {
          if(value) {
            this.formGroup.controls.birthDate.disable();
          } else {
            this.formGroup.controls.birthDate.enable();
          }
        }
      })
    }
  }

  sectionErrorsExist(): boolean {
    return this._correctionsErrors?.sectionErrors.some((item) => item.entry === this._formPrefix);
  }

  private getFieldsErrors(correctionsErrors: CorrectionsErrors) {
    this.fieldsErrors =
      correctionsErrors?.fieldErrors
        .filter((item) => item.entry.startsWith(this._formPrefix))
        .map((entry) => {
          return {
            entry: entry.entry,
            previousValue: entry.previousValue
          };
        }) || [];
  }

  isFieldIncludedInCorrection(fieldName: string): boolean {
    return (
      this.fieldsErrors.some((err) => err.entry.endsWith(fieldName)) || this.sectionErrorsExist()
    );
  }

  findPreviousValue(fieldName: string): any {
    return this.fieldsErrors.find((err) => err.entry.endsWith(fieldName))?.previousValue;
  }

  public onBirthDateChanged(value: string): void {
    this.birthDateChanged.emit(value);
    this.saveDraft.emit();
  }

  public onPeselChanged(value: string): void {
    this.binder.updateDate(value);
    this.birthDateChanged.emit(value);
    this.saveDraft.emit();
  }

  public onBlur(): void {
    this.saveDraft.emit();
  }

  public hasNoPesel(): boolean {
    return this.binder.getControlValue('hasNoPesel');
  }

  public isControlRequired(controlName: string): boolean {
    return this.binder.isControlRequired(controlName);
  }

  ngOnDestroy(): void {
    if (this.aggregateFormGroup.contains(this.formValidation.formGroupName)) {
      this.aggregateFormGroup.removeControl(this.formValidation.formGroupName);
    }

    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }
}

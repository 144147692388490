import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment as env } from 'src/environments/environment';
import { DraftAlreadyExistsModal } from '../_models/draft-already-exists-modal.model';
import { isUndefined } from 'util';
import { STC_V1 } from 'src/app/shared/consts/api-version.config';

// /cardholders/{cardholderId}/applications/driver-card/check-if-exists

const CHECK_IF_EXISTS = '/check-if-exists';

@Injectable({
  providedIn: 'root'
})
export class DarftAlreadyExistsModalService {
  stcHeaders = new HttpHeaders().set('Content-Type', STC_V1);

  constructor(private _http: HttpClient) {}

  getInfoAboutExistingDraft$(
    userId: string,
    employeeId?: string,
  ): Observable<DraftAlreadyExistsModal> {
    if (employeeId === undefined) {
      return this._http.get<DraftAlreadyExistsModal>(
        `${env.BACKEND_API.BASE}${env.BACKEND_API.CARDHOLDERS}/` +
          `${userId}${env.BACKEND_API.APPLICATIONS}${env.BACKEND_API.DRIVER_CARD}${CHECK_IF_EXISTS}`,
        { headers: this.stcHeaders }
      );
    } else {
      return this._http.get<DraftAlreadyExistsModal>(
        `${env.BACKEND_API.BASE}${env.BACKEND_API.EMPLOYERS}/${userId}` +
          `${env.BACKEND_API.EMPLOYEES}/${employeeId}${env.BACKEND_API.APPLICATIONS}` +
          `${env.BACKEND_API.DRIVER_CARD}${CHECK_IF_EXISTS}`,
        { headers: this.stcHeaders }
      );
    }
  }
}

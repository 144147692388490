<div class="attachments-paper ic-page">
  <app-loaders [type]="'car'" [fullScreen]="true" [show]="loading"></app-loaders>
  <div *ngIf="!isAttachmentsRequired && !loading">
    <h2 class="attachments-title" *ngIf="displayTitle">
      {{ 'STC.PAPER_ATTACHMENTS.ATTACHMENTS' | translate }}
    </h2>
    <div class="attachments-teaser">
      <app-teaser-banner>{{
        'STC.NO_ATTACHMENTS_INFOBOX.INFOBOX_TITLE' | translate
      }}</app-teaser-banner>
    </div>
  </div>

  <div *ngIf="isAttachmentsRequired">
    <h2 class="attachments-title" *ngIf="displayTitle">
      {{ 'STC.PAPER_ATTACHMENTS.ATTACHMENTS' | translate }}
    </h2>
    <span class="required-info"><sup class="required">* </sup> {{'STC.PAPER_ATTACHMENTS.REQUIRED' | translate}}</span>

    <ul class="attachments">
      <li *ngIf="checkIfAttachmentIsRequired('PHOTO')">
        <span class="attachment-title">{{ 'STC.PAPER_ATTACHMENTS.PHOTO.LABEL' | translate }}<sup *ngIf="attachementHasRequiredAttr('PHOTO')" class="required">* </sup></span>
        {{ 'STC.PAPER_ATTACHMENTS.PHOTO.DESCRIPTION' | translate }}
      </li>
      <li *ngIf="checkIfAttachmentIsRequired('SIGNATURE_SCAN')">
        <span class="attachment-title">{{
          'STC.PAPER_ATTACHMENTS.SIGNATURE_SCAN.LABEL' | translate
        }}<sup *ngIf="attachementHasRequiredAttr('SIGNATURE_SCAN')" class="required">* </sup></span>
        {{ 'STC.PAPER_ATTACHMENTS.SIGNATURE_SCAN.DESCRIPTION' | translate }}
      </li>
      <li *ngIf="checkIfAttachmentIsRequired('COPY_OF_DRIVING_LICENSE')">
        <span class="attachment-title">{{
          'STC.PAPER_ATTACHMENTS.COPY_OF_DRIVING_LICENSE.LABEL' | translate
        }}<sup *ngIf="attachementHasRequiredAttr('COPY_OF_DRIVING_LICENSE')" class="required">* </sup></span>
      </li>
      <li *ngIf="checkIfAttachmentIsRequired('DRIVING_LICENSE_TRANSLATION')">
        <span class="attachment-title">{{
          'STC.PAPER_ATTACHMENTS.DRIVING_LICENSE_TRANSLATION.LABEL' | translate
        }}</span>
      </li><sup *ngIf="attachementHasRequiredAttr('DRIVING_LICENSE_TRANSLATION')" class="required">* </sup>
      <li *ngIf="checkIfAttachmentIsRequired('COPY_OF_THE_DRIVER_CARD')">
        <span class="attachment-title">{{
          'STC.PAPER_ATTACHMENTS.COPY_OF_THE_DRIVER_CARD.LABEL' | translate
        }}<sup *ngIf="attachementHasRequiredAttr('COPY_OF_THE_DRIVER_CARD')" class="required">* </sup></span>
      </li>
      <li *ngIf="checkIfAttachmentIsRequired('DRIVER_STATEMENT_ADDRESS') && isStatement">
        <span class="attachment-title">{{
          'STC.PAPER_ATTACHMENTS.DRIVER_STATEMENT_ADDRESS.LABEL' | translate
        }}<sup *ngIf="attachementHasRequiredAttr('DRIVER_STATEMENT_ADDRESS')" class="required">* </sup></span
        >{{ 'STC.PAPER_ATTACHMENTS.DRIVER_STATEMENT_ADDRESS.DESCRIPTION' | translate }}
      </li>
      <li *ngIf="checkIfAttachmentIsRequired('DRIVER_STATEMENT_LICENSE') && isStatement">
        <span class="attachment-title">{{
          'STC.PAPER_ATTACHMENTS.DRIVER_STATEMENT_LICENSE.LABEL' | translate
        }}<sup *ngIf="attachementHasRequiredAttr('DRIVER_STATEMENT_LICENSE')" class="required">* </sup></span
        >{{ 'STC.PAPER_ATTACHMENTS.DRIVER_STATEMENT_LICENSE.DESCRIPTION' | translate }}
      </li>
      <li *ngIf="checkIfAttachmentIsRequired('CONFIRMATION_OF_WORK') && isStatement">
        <span class="attachment-title">{{
          'STC.PAPER_ATTACHMENTS.CONFIRMATION_OF_WORK.LABEL' | translate
        }}<sup *ngIf="attachementHasRequiredAttr('CONFIRMATION_OF_WORK')" class="required">* </sup></span
        >{{ 'STC.PAPER_ATTACHMENTS.CONFIRMATION_OF_WORK.DESCRIPTION' | translate }}
      </li>
      <li *ngIf="checkIfAttachmentIsRequired('A_STATEMENT_ABOUT_LOSING_THE_CARD')">
        <span class="attachment-title">{{
          'STC.PAPER_ATTACHMENTS.A_STATEMENT_ABOUT_LOSING_THE_CARD.LABEL' | translate
        }}<sup *ngIf="attachementHasRequiredAttr('A_STATEMENT_ABOUT_LOSING_THE_CARD')" class="required">* </sup></span>
      </li>
      <li *ngIf="checkIfAttachmentIsRequired('A_STATEMENT_ABOUT_LOSING_THE_FOREIGN_CARD')">
        <span class="attachment-title">{{
          'STC.PAPER_ATTACHMENTS.A_STATEMENT_ABOUT_LOSING_THE_FOREIGN_CARD.LABEL' | translate
        }}<sup *ngIf="attachementHasRequiredAttr('A_STATEMENT_ABOUT_LOSING_THE_FOREIGN_CARD')" class="required">* </sup></span>
      </li>
      <li *ngIf="checkIfAttachmentIsRequired('A_STATEMENT_ABOUT_CARD_THEFT')">
        <span class="attachment-title">{{
          'STC.PAPER_ATTACHMENTS.A_STATEMENT_ABOUT_CARD_THEFT.LABEL' | translate
        }}<sup *ngIf="attachementHasRequiredAttr('A_STATEMENT_ABOUT_CARD_THEFT')" class="required">* </sup></span>
      </li>
      <li
        *ngIf="
          checkIfAttachmentIsRequired('A_COPY_OF_THE_DOCUMENT_ALLOWING_TRAFFIC') && isStatement
        ">
        <span class="attachment-title">{{
          'STC.PAPER_ATTACHMENTS.A_COPY_OF_THE_DOCUMENT_ALLOWING_TRAFFIC.LABEL' | translate
        }}<sup *ngIf="attachementHasRequiredAttr('A_COPY_OF_THE_DOCUMENT_ALLOWING_TRAFFIC')" class="required">* </sup></span>
        {{
          'STC.PAPER_ATTACHMENTS.A_COPY_OF_THE_DOCUMENT_ALLOWING_TRAFFIC.DESCRIPTION' | translate
        }}
      </li>
      <li
        *ngIf="
          checkIfAttachmentIsRequired('EMPLOYER_HAS_EMPLOYEE_AUTHORIZATION_TO_SUBMIT_APPLICATION')
        ">
        <span class="attachment-title">{{
          'STC.PAPER_ATTACHMENTS.EMPLOYER_HAS_EMPLOYEE_AUTHORIZATION_TO_SUBMIT_APPLICATION.LABEL'
            | translate
        }}<sup *ngIf="attachementHasRequiredAttr('EMPLOYER_HAS_EMPLOYEE_AUTHORIZATION_TO_SUBMIT_APPLICATION')" class="required">* </sup></span>
        {{
          'STC.PAPER_ATTACHMENTS.EMPLOYER_HAS_EMPLOYEE_AUTHORIZATION_TO_SUBMIT_APPLICATION.DESCRIPTION'
            | translate
        }}
      </li>
      <li
      *ngIf="
        checkIfAttachmentIsRequired('COPY_OF_INTERNATIONAL_DRIVING_LICENSE') && isStatement
      ">
      <span class="attachment-title">{{
        'STC.PAPER_ATTACHMENTS.COPY_OF_INTERNATIONAL_DRIVING_LICENSE.LABEL' | translate
      }}<sup *ngIf="attachementHasRequiredAttr('COPY_OF_INTERNATIONAL_DRIVING_LICENSE')" class="required">* </sup></span>
      {{
        'STC.PAPER_ATTACHMENTS.COPY_OF_INTERNATIONAL_DRIVING_LICENSE.DESCRIPTION' | translate
      }}
      </li>
      <li
        *ngIf="
          checkIfAttachmentIsRequired('COPY_OF_DRIVER_CERTIFICATE') && isStatement
        ">
          <span class="attachment-title">{{
            'STC.PAPER_ATTACHMENTS.COPY_OF_DRIVER_CERTIFICATE.LABEL' | translate
          }}<sup *ngIf="attachementHasRequiredAttr('COPY_OF_DRIVER_CERTIFICATE')" class="required">* </sup></span>
          {{
            'STC.PAPER_ATTACHMENTS.COPY_OF_DRIVER_CERTIFICATE.DESCRIPTION' | translate
          }}
      </li>
      <li
        *ngIf="
          checkIfAttachmentIsRequired('COPY_OF_DRIVER_CERTIFICATE_OR_DRIVER_QUALIFICATION_CARD') && isStatement
        ">
          <span class="attachment-title">{{
            'STC.PAPER_ATTACHMENTS.COPY_OF_DRIVER_CERTIFICATE_OR_DRIVER_QUALIFICATION_CARD.LABEL' | translate
          }}<sup *ngIf="attachementHasRequiredAttr('COPY_OF_DRIVER_CERTIFICATE_OR_DRIVER_QUALIFICATION_CARD')" class="required">* </sup></span>
          <span *ngIf="cardType === CardTypes.TEMPORARY_DRIVER_CARD">
            {{
              'STC.PAPER_ATTACHMENTS.COPY_OF_DRIVER_CERTIFICATE_OR_DRIVER_QUALIFICATION_CARD.DESCRIPTION' | translate
            }}
          </span>
          <app-info-box
              *ngIf="cardType === CardTypes.DRIVER_CARD"
              [bordered]="true" 
              [infoBoxText]="'STC.PAPER_ATTACHMENTS.COPY_OF_DRIVER_CERTIFICATE_OR_DRIVER_QUALIFICATION_CARD.INFO_BOX' | translate"
            ></app-info-box>
      </li>

      <li *ngIf="checkIfAttachmentIsRequired('COMPANY_AUTHORIZATION_FOR_NON_KRS_PERSON')">
        <ng-container *ngIf="cardType === CardTypes.CONTROL_CARD; else default;">
          <span class="attachment-title">{{
            'STC.PAPER_ATTACHMENTS.COMPANY_AUTHORIZATION_FOR_NON_KRS_PERSON_CONTROL.LABEL' | translate
          }}<sup *ngIf="attachementHasRequiredAttr('COMPANY_AUTHORIZATION_FOR_NON_KRS_PERSON')" class="required">* </sup></span>
          {{
            'STC.PAPER_ATTACHMENTS.COMPANY_AUTHORIZATION_FOR_NON_KRS_PERSON_CONTROL.DESCRIPTION' | translate
          }}
        </ng-container>
        <ng-template #default>
          <span class="attachment-title">{{
            'STC.PAPER_ATTACHMENTS.COMPANY_AUTHORIZATION_FOR_NON_KRS_PERSON.LABEL' | translate
          }}<sup *ngIf="attachementHasRequiredAttr('COMPANY_AUTHORIZATION_FOR_NON_KRS_PERSON')" class="required">* </sup></span>
          {{
            'STC.PAPER_ATTACHMENTS.COMPANY_AUTHORIZATION_FOR_NON_KRS_PERSON.DESCRIPTION' | translate
          }}
        </ng-template>
      </li>
      <li *ngIf="checkIfAttachmentIsRequired('PREVIOUS_CARD')">
        <span class="attachment-title">{{ cardName }}<sup *ngIf="attachementHasRequiredAttr('PREVIOUS_CARD')" class="required">* </sup> - </span>
        {{ 'STC.PAPER_ATTACHMENTS.PREVIOUS_CARD.DESCRIPTION' | translate }}
      </li>
      <li
      *ngIf="
        checkIfAttachmentIsRequired('OTHER')
      ">
      <span class="attachment-title">{{
        'STC.PAPER_ATTACHMENTS.OTHER.LABEL'
          | translate
      }}<sup *ngIf="attachementHasRequiredAttr('OTHER')" class="required">* </sup></span>
      {{
        reason === ReasonEnum.COMPLAINT ? ('STC.PAPER_ATTACHMENTS.OTHER.DESCRIPTION_OPTIONAL' | translate) : ('STC.PAPER_ATTACHMENTS.OTHER.DESCRIPTION' | translate)
      }}
    </li>
    <li
    *ngIf="
      showPaymentConfirmationAttachment
    ">
      <span class="attachment-title">{{
        'STC.PAPER_ATTACHMENTS.PAYMENT_CONFIRMATION_ATTACHMENT.LABEL'
          | translate
      }}<sup *ngIf="attachementHasRequiredAttr('PAYMENT_CONFIRMATION_ATTACHMENT')" class="required">* </sup></span>
      {{ 'STC.PAPER_ATTACHMENTS.PAYMENT_CONFIRMATION_ATTACHMENT.DESCRIPTION' | translate }}
    </li>
    </ul>

    <!-- INFO 1 -->
    <app-info-box
      *ngIf="shouldShowInfoBox()"
      [bordered]="true"
      [infoBoxText]="reason === 'CARD_LOSS' ? ('STC.PAPER_ATTACHMENTS.INFO_TEXT_CARD_LOSS' | translate) : ('STC.PAPER_ATTACHMENTS.INFO_TEXT_FOREIGN_CARD_LOSS' | translate)"
      [width]="infoBoxWidth">
    </app-info-box>
    <!-- /INFO 1 -->
    <!-- INFO 2 -->
      <!-- <app-info-box
      *ngIf="showInfoBox"
      [bordered]="true"
      [infoBoxText]="'STC.PAPER_ATTACHMENTS.INFO_TEXT_TRADITIONAL_PAYMENT' | translate"
      [width]="infoBoxWidth">
    </app-info-box> -->
    <!-- /INFO 2 -->
  </div>
</div>

<app-two-way-action-bar *ngIf="isActionBar" (submit)="onSubmit()" (navigateBack)="onBackClicked()">
</app-two-way-action-bar>

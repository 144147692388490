<div class="loader-wrapper" *ngIf="!content">
  <app-loaders [type]="'dots'"></app-loaders>
</div>
<app-loaders [show]="loading" [fullScreen]="true" [type]="'car'"></app-loaders>
<div class="sing-warning-modal" *ngIf="content">
  <section class="sing-warning-modal__header">
    <h2>{{ content.title }}</h2>
    <button (click)="onClose()" [attr.aria-label]="content.buttonCloseAria" class="close-btn">
      <svg-icon class="close-btn-icon" src="assets/images/close_mobile_.svg"></svg-icon>
    </button>
  </section>
  <section class="sing-warning-modal__body">
    <p *ngFor="let item of content.description" [innerHtml]="item | safeHtml"></p>
  </section>
  <section class="sing-warning-modal__buttons">
    <ic-ghost-button
      [showArrow]="true"
      [width]="200"
      appPreventDoubleClick
      (throttledClick)="onOkClick()"
      >{{ content.buttonConfirm }}</ic-ghost-button
    >
    <ic-ghost-button
      [showArrow]="true"
      [width]="150"
      [type]="GhostButtonType.BIG_SECOND_RANK_FLEX"
      (click)="onClose()"
      >{{ content.buttonAbort }}</ic-ghost-button
    >
  </section>
</div>

<div class="container header-container minimal-header-wrapper" *ngIf="logoOnly; else standard">
  <a
    (keydown.ENTER)="onClickScroll('app-breadcrumbs')"
    tabindex="1"
    role="button"
    [attr.aria-label]="'SERVICE_CATEGORY.skipToMainAria' | translate"
    class="skipToMainContent"
    >Skip to main content</a
  >
  <a routerLink="/" style="cursor: pointer" class="minimal-header">
    <img alt="Info Car" class="minimal-header__logo" src="assets/images/logo_infocar_desktop.svg" />
    <div class="minimal-header__label">
      {{ 'HEADER.logo_label' | translate }}
    </div>
  </a>
</div>

<ng-template #standard>
  <div class="header-container container">
    <div class="header-logo-wrapper">
      <a routerLink="/">
        <span class="logo" >
          <img alt="Info Car" src="assets/images/logo_infocar_desktop.svg" />
        </span>
        <span class="logo-label">
          <div>{{ 'HEADER.logo_label' | translate }}</div>
        </span>
      </a>
    </div>
    <a
      (keydown.ENTER)="onClickScroll('app-breadcrumbs')"
      tabindex="1"
      role="button"
      [attr.aria-label]="'SERVICE_CATEGORY.skipToMainAria' | translate"
      class="skipToMainContent"
      >Skip to main content</a
    >
    <!-- NOTE Hidden unprepered content
      <div class="langs">
          <span><a class="cyrillic" href="#">{{ 'HEADER.language_ukr' | translate}}</a></span>
          <span><a href="#">{{ 'HEADER.language_eng' | translate}}</a></span>
      </div> -->
    <div class="languageSwitcher desktop">
      <!-- <a
        *ngIf="currentLng !== appLanguage.UKRAINIAN"
        class="UA"
        (click)="onClick(appLanguage.UKRAINIAN)"
        >Українська</a
      > -->
      <a *ngIf="currentLng !== appLanguage.POLISH" class="PL" (click)="onClick(appLanguage.POLISH)"
        >Polski</a
      >
    </div>

    <div
      class="mobile-logo"
      role="button"
      (click)="closeMenuIfOpen()"
      routerLink="/"
      style="cursor: pointer">
      <img alt="Info car" class="mobile-logo-icon" src="assets/images/logo_infocar_desktop.svg" />
    </div>

    <div class="left-panel">
      <a class="categories-dropdown" ngbDropdown role="button" (openChange)="catOnClick()">
        <div
          [ngClass]="{
            'btn-group': true,
            categories: true,
            UA: currentLng === appLanguage.UKRAINIAN,
            btnActive: catOpen,
            btnInactive: !catOpen
          }"
          ngbDropdownToggle
          id="categoryDropdownId"
          tabindex="0"
          appTriggerClick
          [attr.aria-label]="'SERVICE_CATEGORY.dropdownAria' | translate"
          [attr.aria-expanded]="catOpen">
          <div id="button-categories">
            <img
              src="assets/images/uslugi_icon.svg"
              alt="{{ 'SERVICE_CATEGORY.handHoldingCar' | translate }}" />
            <span>{{ 'HEADER.service_categories_menu' | translate }}</span>
            <i class="material-icons">
              {{ catOpen ? 'expand_less' : 'expand_more' }}
            </i>
          </div>

          <div
            id="dropdown-categories"
            [attr.aria-hidden]="!catOpen"
            ngbDropdownMenu
            aria-labelledby="categoryDropdownId">
            <ul ngbDropdownItem>
              <ng-container *ngFor="let item of 'SERVICE_CATEGORY.SERVICES_INFO_BOX' | translate">
                <a routerLink="/{{ item.id }}">
                  <li role="menuitem">
                    <div
                      [ngClass]="{
                        'menu-item-content': true,
                        UA: currentLng === appLanguage.UKRAINIAN
                      }">
                      <img src="{{ item.categoryIcon.url }}" alt="{{ item.categoryIcon.alt }}" />
                      <span>{{ item.categoryTitle }}</span>
                    </div>
                  </li>
                </a>
              </ng-container>
            </ul>
          </div>
        </div>
      </a>
      <a id="about-link" [routerLink]="[aboutLink]">
        <div class="about">
          <img
            src="assets/images/oserwisie_icon.svg"
            alt="{{ 'SERVICE_CATEGORY.signpost' | translate }}" />
          <span>{{ 'HEADER.about_us' | translate }}</span>
        </div>
      </a>
    </div>
    <ng-container *ngIf="auth$ | async as auth">
      <div class="right-panel">
        <div class="mobile-my-account" [ngSwitch]="auth.status">
          <ng-container *ngSwitchCase="'initialized'">
            <a *ngIf="!!auth.user; else anonymousMobile" role="button" (click)="onMyAccountClick()">
              <span>{{ auth.user.firstname }}<br />{{ auth.user.lastname }}</span>
            </a>
            <ng-template #anonymousMobile>
              <ic-ghost-button
                [type]="GhostButtonType.SMALL_SECOND_RANK"
                [width]="110"
                [height]="32"
                class="mobile-my-acc-icon center"
                (click)="onMyAccountClick()">
                {{ 'HEADER.my_account_btn' | translate }}
              </ic-ghost-button>
            </ng-template>
          </ng-container>

          <ng-container *ngSwitchCase="'loading'">
            <app-loaders type="dots"></app-loaders>
          </ng-container>

          <ng-container *ngSwitchCase="'error'">
            <a role="button" (click)="onAuthRetryClick()">
              <span>{{ 'HEADER.login_error' | translate }}</span>
            </a>
          </ng-container>
        </div>
        <div
          class="my-account"
          role="button"
          [attr.aria-label]="auth?.user?.firstname ? (auth.user.firstname + ' ' + auth.user.lastname + ( 'HEADER.logout' | translate)) : 'HEADER.my_account' | translate"
          (keydown.ENTER)="onMyAccountClick()"
          tabindex="0"
          [ngSwitch]="auth.status">
          <ng-container *ngSwitchCase="'initialized'">
            <div class="my-account__content" *ngIf="!!auth.user; else anonymous">
              <img
                class="my-acc-icon"
                alt="{{ 'SERVICE_CATEGORY.accountIcon' | translate }}"
                src="assets/images/user_icon.svg"
                (click)="onMyAccountClick()" />
              <div class="auth-user">
                <a (click)="onMyAccountClick()" role="button"
                  >{{ auth.user.firstname }} {{ auth.user.lastname }}</a
                >
                <button (click)="onLogout()">
                  {{ 'HEADER.logout' | translate }}
                </button>
              </div>
            </div>

            <ng-template #anonymous>
              <div class="my-account__content">
                <img
                  class="my-acc-icon"
                  alt="{{ 'SERVICE_CATEGORY.accountIcon' | translate }}"
                  src="assets/images/user_icon.svg"
                  (click)="onMyAccountClick()" />
                <span style="cursor: pointer" role="button" (click)="onMyAccountClick()">{{
                  'HEADER.my_account' | translate
                }}</span>
              </div>
            </ng-template>
          </ng-container>

          <ng-container *ngSwitchCase="'loading'">
            <div class="my-account__content">
              <img
                class="my-acc-icon my-acc-icon--disabled"
                alt="{{ 'SERVICE_CATEGORY.accountIcon' | translate }}"
                src="assets/images/user_icon.svg" />
              <app-loaders type="dots"></app-loaders>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'error'">
            <div class="my-account__content">
              <img
                class="my-acc-icon"
                alt="{{ 'SERVICE_CATEGORY.accountIcon' | translate }}"
                src="assets/images/user_icon.svg"
                (click)="onAuthRetryClick()" />
              <div class="auth-user">
                <a (click)="onAuthRetryClick()" role="button"
                  >{{ 'HEADER.login_error' | translate }}
                </a>
              </div>
            </div>
          </ng-container>
        </div>
        <div
          class="search"
          [attr.aria-label]="'SERVICE_CATEGORY.searchAria' | translate"
          tabindex="0"
          role="button"
          (keydown.ENTER)="onSearchIconClick()"
          (click)="onSearchIconClick()">
          <img
            class="mobile-search-icon"
            src="assets/images/search_icon.svg"
            alt="{{ 'SERVICE_CATEGORY.searchIcon' | translate }}" />
          <img
            class="search-icon"
            alt="{{ 'SERVICE_CATEGORY.searchIcon' | translate }}"
            src="assets/images/search_mobile.svg" />
        </div>
      </div>
    </ng-container>
    <div
      class="menu"
      [attr.aria-label]="'SERVICE_CATEGORY.hamburgerAria' | translate"
      role="button"
      [ngClass]="{ 'menu-open': open }"
      (click)="menuOnClick()">
      <div class="burger-container">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <p *ngIf="!open; else closed">{{ 'HEADER.open' | translate }}</p>
      <ng-template #closed
        ><p>{{ 'HEADER.close' | translate }}</p>
      </ng-template>
    </div>
  </div>
  <app-header-categories
    (selectOption)="menuOnClick()"
    [currentLng]="currentLng"
    *ngIf="open"
    (changeLng)="onClick($event)"
    @showHideAnimation>
  </app-header-categories>
  <div class="black-shadow" *ngIf="open"></div>
</ng-template>

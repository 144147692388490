import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { GhostButtonType } from 'src/app/shared/components/ghost-button/ghost-button-type.enum';
import { BaseModalDirective } from 'src/app/shared/components/modal/base-modal';
import { Component, OnInit } from '@angular/core';
import { SignMethodWarningModalModel } from './models/sign-method-warning-modal.model';
import { SignApplicationMethodModalService } from '../sign-method-modal/services/sign-application-method-modal.service';

@Component({
  selector: 'app-sign-method-warning-modal',
  templateUrl: './sign-method-warning-modal.component.html',
  styleUrls: ['./sign-method-warning-modal.component.scss']
})
export class SignMethodWarningModalComponent extends BaseModalDirective implements OnInit {
  signModalData: SignMethodWarningModalModel;
  epuapLink: string;
  GhostButtonType = GhostButtonType;
  content: any;
  loading = false;
  constructor(
    private signMethodModalService: SignApplicationMethodModalService,
    private translate: TranslateService,
    private toast: ToastrService
  ) {
    super();
  }

  ngOnInit(): void {
    this.signModalData = this.getData();
    this.translate.get('SIGN_METHOD_WARNING_MODAL').subscribe({
      next: (result) => {
        this.content = result;
      }
    });
  }

  onOkClick() {
    this.loading = true;

    this.signMethodModalService
      .sendConfirmation(
        this.signModalData.selectedApplicationID,
        this.signModalData.type
      )
      .subscribe({
        next: () => this.getEpuapLink(),
        error: (error) => {
          this.loading = false;
          this.close();
          if (error.status !== 422) {
            this.toast.error(this.content.epuapErrorMessage);
          }
        }
      });
  }

  getEpuapLink() {
    this.signMethodModalService
      .getEpuapLink(this.signModalData.selectedApplicationID)
      .subscribe((result: any) => {
        this.epuapLink = result.epuapUrl;
        window.location.href = this.epuapLink;
      });
  }

  onClose() {
    this.close();
  }
}

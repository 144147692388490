import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.scss']
})
export class InfoBoxComponent implements OnInit {
  // to create multiple blocks of text inside a single info-box use <span> tags inside infoBoxText
  // to make a routerLink use <a> tag with 'link' attribute like <a href="/" link="/">Homepage</a>
  @Input() infoBoxText: string;
  @Input() infoBoxTitle: string;
  @Input() width: number;
  @Input() bordered = false;
  @Input() maxWidthLimited = true;
  @Input() type: 'info' | 'warning' | 'error' | 'success' = 'info';
  @Input() iconStyle: 'monochrome' | 'blue' = 'blue';
  content: any;
  constructor(private router: Router, private translate: TranslateService) {}

  ngOnInit() {
    this.translate.get('SHARED.INFO_BOX').subscribe({
      next: (content) => {
        this.content = content;

        if (!this.infoBoxTitle) {
          this.infoBoxTitle = this.content.waring;
        }
      }
    });
  }
}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import {
  CorrectionsErrors,
  FieldErrorEntry
} from 'src/app/shared/models/corrections/corrections.model';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { dateFromZeroYear, nameof } from 'src/app/shared/extensions/extensions';
import { Country } from '../../../models/countries/country.model';
import { DistrictOffice } from '../../../models/district-offices/district-office.model';
import { DrivingLicenceDescriptionModalComponent } from '../../modal/driving-licence-description-modal/driving-licence-description-modal.component';
import { DrivingLicenceDataFormBinder } from './driving-licence-data.form-binder';
import {
  DrivingLicenceDataDto,
  DrivingLicenceDataFormValidation,
  DrivingLicenceDataStaticContent
} from './driving-licence-data.model';
import { defaultDrivingLicenseDataValidation } from './driving-licence-data.validation';

@Component({
  selector: 'app-driving-licence-data',
  templateUrl: './driving-licence-data.component.html',
  styleUrls: ['./driving-licence-data.component.scss'],
  providers: [DrivingLicenceDataFormBinder]
})
export class DrivingLicenceDataComponent implements OnInit, OnDestroy {
  @Input() staticContent: DrivingLicenceDataStaticContent;
  @Input() formValidation: DrivingLicenceDataFormValidation = defaultDrivingLicenseDataValidation;
  @Input() aggregateFormGroup: UntypedFormGroup;

  @Input() isEdit: boolean;
  @Input() issuedAuthority = null;
  @Input() countries: Country[];
  @Input() defaultCountry: Country;

  @Input() offices: DistrictOffice[];

  @Input() drivingLicenseString = '';
  @Input() fetchedData: DrivingLicenceDataDto;

  @Output() saveDraft: EventEmitter<any> = new EventEmitter();
  @Output() countryCodeSelected: EventEmitter<string> = new EventEmitter();
  @Output() issuingAuthority: EventEmitter<string> = new EventEmitter();
  @Input() htmlElementId: string;
  @Input() set correctionsErrors(data: CorrectionsErrors) {
    this._correctionsErrors = data;
    this.getFieldsErrors(data);
  }
  private _correctionsErrors: CorrectionsErrors;
  showCovidDriverLicenseExpirationInfo: boolean;
  sub: Subscription;
  formGroup: UntypedFormGroup;
  formPrefix = 'drivingLicense';
  fieldsErrors: FieldErrorEntry[] = [];

  constructor(public binder: DrivingLicenceDataFormBinder, private modalService: ModalService) {}

  ngOnInit() {
    this.formGroup = this.binder.bindForm(this.formValidation);
    this.aggregateFormGroup.addControl(this.formValidation.formGroupName, this.formGroup);
    this.setCountries();
    this.setOffices();
    this.binder.fillFormWith(this.fetchedData);
    if (!this.fetchedData.authorityCountry) {
      // using nameof to ensure that control names exist on validation object
      const nameof = (name: keyof DrivingLicenceDataFormValidation['formFields']) => name;
      this.binder.setControl(nameof('authorityCountry'), this.defaultCountry.countryCode);
    }
    this.checkDriverLicenseExpirationDate();
  }

  sectionErrorsExist(): boolean {
    return this._correctionsErrors?.sectionErrors.some((item) => item.entry === this.formPrefix);
  }

  private getFieldsErrors(correctionsErrors: CorrectionsErrors) {
    this.fieldsErrors =
      correctionsErrors?.fieldErrors
        .filter((item) => item.entry.startsWith(this.formPrefix))
        .map((entry) => {
          return {
            entry: entry.entry,
            previousValue: entry.previousValue
          };
        }) || [];
  }

  isFieldIncludedInCorrection(fieldName: string): boolean {
    return (
      this.fieldsErrors.some((err) => err.entry.endsWith(fieldName)) || this.sectionErrorsExist()
    );
  }

  findPreviousValue(fieldName: string): any {
    return this.fieldsErrors.find((err) => err.entry.endsWith(fieldName))?.previousValue;
  }

  setCountries() {
    const defaultCountryIndex = this.countries.findIndex((country) => country.countryCode === 'PL');
    this.defaultCountry = this.countries[defaultCountryIndex];
    this.binder.setCountries(this.countries, this.defaultCountry);
    this.binder.getForm().get('authorityCountry').updateValueAndValidity();

    if (!this.isEdit || this.binder.getControlValue<string, string>('authorityCountry') === null) {
      this.binder.setControl('authorityCountry', this.defaultCountry.countryCode);
    }
  }

  setOffices() {
    this.binder.setDistrictOffices(this.offices);
    if (this.isEdit) {
      const selectedOfficeIndex = this.offices.findIndex(
        (office: DistrictOffice) => office.id === this.issuedAuthority
      );
      if (selectedOfficeIndex > -1) {
        // setTimeout(() => {
        //   this.issuingAuthoritySelect.setItemByIndex(selectedOfficeIndex);
        // }, 0);
      }
    }
    setTimeout(() => {
      this.binder.addCountryUpdatedValidator();
    }, 0);
  }

  public onBlur(): void {
    this.saveDraft.emit();
  }

  public onCountrySelected(): void {
    this.countryCodeSelected.emit(this.binder.getControlValue(nameof('authorityCountry')));
    this.saveDraft.emit();
  }

  public onIssuingAuthorityChange(): void {
    this.issuingAuthority.emit(this.binder.getControlValue(nameof('issuingAuthority')));
    this.saveDraft.emit();
  }

  public isControlRequired(controlName: string): boolean {
    return this.binder.isControlRequired(controlName);
  }

  public isDefaultCountrySelected(): boolean {
    if (!this.countries || !this.defaultCountry) {
      return false;
    }
    return this.binder.getControlValue('authorityCountry') === this.defaultCountry.countryCode;
  }

  checkDriverLicenseExpirationDate() {
    const expirationDateInput = this.formGroup.get('expirationDate');

    this.sub = expirationDateInput.valueChanges.subscribe((value) => {
      if (expirationDateInput.valid) {
        const date = String(value).split('.').reverse();
        const inputValueDate = dateFromZeroYear(date[0], date[1], date[2]);
        const freezeExpirationDate = new Date(2020, 2, 1);
        const dateNow = new Date(Date.now());

        this.showCovidDriverLicenseExpirationInfo =
          inputValueDate instanceof Date &&
          inputValueDate >= freezeExpirationDate &&
          inputValueDate <= dateNow;
      } else {
        this.showCovidDriverLicenseExpirationInfo = false;
      }
    });
  }
  onShowDrivingLicenceDescriptionModal = () => {
    this.modalService.open(DrivingLicenceDescriptionModalComponent);
  }

  ngOnDestroy(): void {
    if (this.aggregateFormGroup.contains(this.formValidation.formGroupName)) {
      this.aggregateFormGroup.removeControl(this.formValidation.formGroupName);
    }

    this.sub.unsubscribe();
  }
}

import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { ImageButtonType } from './image-edit-button.enum';

@Component({
  selector: 'app-image-edit-button',
  templateUrl: './image-edit-button.component.html',
  styleUrls: ['./image-edit-button.component.scss']
})
export class ImageEditButtonComponent implements OnInit {
  @Input() width?: number;
  @Input() height?: number;
  @Input() buttonType: ImageButtonType;
  @Input() label: string;
  ImageButtonType = ImageButtonType;

  constructor(private selfReference: ElementRef) {}

  ngOnInit() {}
}

import { AuthService } from './../../../core/auth/auth.service';
import { Component } from '@angular/core';
import { ToastPackage, Toast, ToastrService } from 'ngx-toastr';
import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';
import { AUTOSAVE_CONFIG } from './_consts/autosave.config';
import { take } from 'rxjs/operators';
import { Extensions } from '../../extensions/extensions';

@Component({
  selector: 'app-first-time-autosave-toastr',
  templateUrl: './first-time-autosave-toastr.component.html',
  styleUrls: ['./first-time-autosave-toastr.component.scss'],
  animations: [
    trigger('flyInOut', [
      state(
        'inactive',
        style({
          opacity: 0
        })
      ),
      transition(
        'inactive => active',
        animate(
          '400ms ease-out',
          keyframes([
            style({
              opacity: 0
            }),
            style({
              opacity: 1
            })
          ])
        )
      ),
      transition(
        'active => removed',
        animate(
          '400ms ease-out',
          keyframes([
            style({
              opacity: 1
            }),
            style({
              opacity: 0
            })
          ])
        )
      )
    ])
  ]
})
export class FirstTimeAutosaveToastrComponent extends Toast {
  isAnonymous: boolean;
  constructor(protected toastrService: ToastrService, public toastPackage: ToastPackage, private auth: AuthService) {
    super(toastrService, toastPackage);

    this.auth.user$
    .pipe(
      take(1)
    )
    .subscribe((result) => {
      this.isAnonymous = !result?.uuid && (!!Extensions.getCookie('anonymousToken') || !!sessionStorage.getItem('anonymousToken'));
    });
  }

  submitAutosave(event: Event) {
    event.stopPropagation();
    localStorage.setItem(
      AUTOSAVE_CONFIG.IS_TOASTR_ACCEPTED.key,
      AUTOSAVE_CONFIG.IS_TOASTR_ACCEPTED.value.true
    );
    this.toastPackage.triggerAction();
    this.remove();
  }
}

<div id="calendar-step-container" class="container container-calendar">
  <div *ngIf="!isCandidateMature && (licenseCategory !== WordExamCategory.BLOK_C && licenseCategory !== WordExamCategory.BLOK_D )" class="exam-type-warning">
    <p [innerHTML]="content.immatureCandidateWarning"></p>
  </div>
  <div *ngIf="practiceExamsOnly" class="exam-type-warning">
    <p [innerHTML]="content.practiceExamsOnly"></p>
  </div>
  <h2 class="label exam-type">{{ content.headerType }}</h2>
  <form class="exam-type-form" [formGroup]="examTypeFormGroup">
    <!--Filtry-->
    <div #filters class="row radio-datepick">
      <div
        *ngIf="!practiceExamsOnly"
        class="radio-container"
        [ngClass]="{
          active: examTypeFormGroup.value.examType === ExamType.Theoretical,
          disabled: examTypeFormGroup.get('examType').disabled
        }"
        id="theoretical-container"
        tabindex="0">
        <input
          appTriggerClick
          [attr.aria-label]="ExamType.Theoretical"
          type="radio"
          [value]="ExamType.Theoretical"
          formControlName="examType" />
        <span class="checkmark"></span>
        <div class="col-12 radio-label">
          {{ content.theoreticalLabel }}
          <div class="price">
            {{ theoreticalPrice || '-' }} {{ content.currency }}
          </div>
        </div>
      </div>
      <div
        class="radio-container"
        [ngClass]="{
          active: examTypeFormGroup.value.examType === ExamType.Practical,
          disabled: examTypeFormGroup.get('examType').disabled
        }"
        id="practical-container"
        tabindex="0">
        <input
          appTriggerClick
          [attr.aria-label]="ExamType.Practical"
          type="radio"
          [value]="ExamType.Practical"
          formControlName="examType" />
        <span class="checkmark"></span>
        <div class="col-12 radio-label">
          {{ content.practicalLabel }}
          <div class="price">
            {{ practicePrice || '-' }} {{ content.currency }}
          </div>
        </div>
      </div>
      <div
        *ngIf="!practiceExamsOnly"
        class="radio-container"
        [ngClass]="{
          active: examTypeFormGroup.value.examType === ExamType.Linked,
          disabled: examTypeFormGroup.get('examType').disabled
        }"
        id="combined-container"
        tabindex="0">
        <input
          appTriggerClick
          [attr.aria-label]="ExamType.Linked"
          type="radio"
          [value]="ExamType.Linked"
          formControlName="examType" />
        <span class="checkmark"></span>
        <div class="col-12 radio-label">
          <p>
            {{ content.combinedLabel }}
            <span>{{ content.combinedLabelSubtitle }}</span>
          </p>
          <div class="price">{{ linkedPrice || '-' }} {{ content.currency }}</div>
        </div>
      </div>
    </div>
    <!-- Wybór terminu -->
    <div class="row date-range-row">
      <h2 class="label exam-type date-range-type">{{ content.headerDate }}</h2>

      <div class="calendar-container">
        <input
          tabindex="0"
          appTriggerClick
          [hidden]="true"
          (select)="onDateSelection($event)"
          class="datepicker-input"
          type="text"
          [dayTemplate]="dayTemplate"
          [footerTemplate]="footerTemplate"
          [autoClose]="'outside'"
          [outsideDays]="'hidden'"
          [minDate]="startDate"
          [navigation]="'arrows'"
          [displayMonths]="2"
          [placement]="['bottom-left', 'top-right']"
          [attr.aria-label]="content.pickDate"
          [positionTarget]="calendarButton"
          (closed)="setIsDatePickerOpen(false)"
          ngbDatepicker
          #datePicker="ngbDatepicker" />
        <ng-template #dayTemplate let-date>
          <span
            (click)="onDateSelection(date)"
            class="custom-day"
            [ngClass]="{ first: date.equals(fromDate), last: date.equals(toDate) }"
            [class.range]="isRange(date)"
            role="button"
            [class.marked]="isScheduled(date)"
            (mouseenter)="hoveredDate = date"
            (mouseleave)="hoveredDate = null">
            {{ date.day }}
          </span>
        </ng-template>
        <ng-template #footerTemplate>
          <div class="ngb-dp-months modal-footer-wrapper">
            <span class="legend available-exams">
              {{ content.availableExamsLegend }}
            </span>
            <p class="taken-range" *ngIf="fromDate">
              {{ content.takenRange }}
              <strong>{{ content.from }} {{ fromDate.day }}.{{ fromDate.month }} </strong>
              <strong *ngIf="toDate">{{ content.to }} {{ toDate.day }}.{{ toDate.month }}</strong>
            </p>
            <ic-ghost-button
              [disabled]="!toDate"
              [typeHTML]="'button'"
              [showArrow]="true"
              [width]="140"
              (click)="onDateAccept()">
              <span class="forwardSpan start-btn__label" [innerHtml]="content.selectDate"></span>
            </ic-ghost-button>
          </div>
        </ng-template>
        <!-- Date picker buttons -->
        <div class="calendar-button" [ngClass]="{ active: isDatePickerOpen, '': isDatePickerOpen }">
          <p
            class="date-range-info"
            tabindex="0"
            (keydown.enter)="toggleIsDatePickerOpen(); datePicker.toggle()"
            role="button"
            (click)="toggleIsDatePickerOpen(); datePicker.toggle()"
            #calendarButton>
            <time class="selected-date-info">
              <ng-template [ngIf]="dateRange" [ngIfElse]="datePlaceholder">
                <span class="text-uppercase">{{
                  dateRange.fromDate | date: 'EEE dd, LLL':undefined:'pl'
                }}</span>
                <span>-</span>
                <span class="text-uppercase">{{
                  dateRange.toDate | date: 'EEE dd, LLL':undefined:'pl'
                }}</span>
              </ng-template>
            </time>

            <ng-template #datePlaceholder>
              <span class="text-capitalize">{{ content.from }}</span>
              <span>-</span>
              <span class="text-capitalize">{{ content.to }}</span></ng-template
            >
          </p>
          <button
            id="clear-date-button"
            class="btn btn-link"
            *ngIf="dateRange"
            type="button"
            (click)="clearDateRange()">
            Wyczyść termin
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<div *ngIf="isDatePickerOpen" class="dark-background"></div>

<button
  (click)="scrollToElement(filters)"
  class="mobile-filter-box"
  [class.sticky]="stickyFilterButton">
  {{ content.filterButtonLabel }}
</button>

<section class="container container-accordion">
  <exam-days-accordion
    [activeFilter]="activeFilter"
    [days]="filteredDays.slice(0, numberOfDaysToShow)"
    [content]="content.accordion"
    (examSelected)="onExamSelected($event)">
  </exam-days-accordion>
  <div class="ghost-wrapper">
    <ic-ghost-button
      *ngIf="numberOfDaysToShow < filteredDays.length"
      [type]="GhostButtonType.WORD_EXAM_SMALL_FLEX"
      [typeHTML]="'button'"
      (click)="showMoreDays()">
      {{ content.showMoreDaysButtonLabel }}
    </ic-ghost-button>
  </div>
</section>

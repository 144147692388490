import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MAIN_PAGE_ROUTE } from '../../main-page/_consts/main-page-routing.const';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class CompanyGuard  {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.auth.user$.pipe(
      map((result) => {
        const isBusiness = AuthService.isBusinessUser(result);
        if (!isBusiness) {
          this.router.navigate(['/', MAIN_PAGE_ROUTE]);
        }
        return isBusiness;
      })
    );
  }
}

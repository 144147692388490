import { AuthService } from 'src/app/core/auth';
import { Extensions } from 'src/app/shared/extensions/extensions';
import { Router } from '@angular/router';
import { Component } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-created-paper-application-toast',
  templateUrl: './created-paper-application-toast.component.html',
  styleUrls: ['./created-paper-application-toast.component.scss']
})
export class CreatedPaperApplicationToastComponent extends Toast {

  cardType: string;
  isComplaint: boolean = false;
  constructor(protected toastrService: ToastrService, public toastPackage: ToastPackage, private router: Router, private auth: AuthService) {
    super(toastrService, toastPackage);
    if(!!Extensions.getCookie('cardType')) {
      this.cardType = Extensions.getCookie('cardType');
    }

    if(!!Extensions.getCookie('complaintId')) {
      this.isComplaint = true;
    }
  }

  rerouteToApplication(event: Event) {
    event.stopPropagation();
    this.auth.loginAnonymousUser();
    this.router.navigateByUrl(Extensions.replacePlaceholders(this.toastPackage.config.payload, [Extensions.getCookie('applicationId')]));
    this.toastPackage.triggerAction();
    this.remove();
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { AuthService } from 'src/app/core/auth';
import { STC_V1 } from 'src/app/shared/consts/api-version.config';
import { Extensions, setCookieWithHours } from 'src/app/shared/extensions/extensions';
import { ApplicationDto } from 'src/app/shared/models/applications/application-dto.model';
import { DriverCardApplicationDto } from 'src/app/shared/models/driver-card-application/driver-card-application.model';
import { environment as env } from 'src/environments/environment';
import { Reason } from '../_models/reason.model';
import { ReasonsForCardList } from '../_models/reasons-for-card-list.model';

const BACKEND_REASONS_FOR_DRIVER_CARD = '?card=DRIVER_CARD';
const BACKEND_CARD_PARAMETER = '?card=';
const BACKEND_DRIVER_CARD = '/driver-card';

@Injectable()
export class CardReasonsService {
  private loggedUserUid: string;

  constructor(private _http: HttpClient, private auth: AuthService) {
    this.auth.user$
      .pipe(
        filter((result) => result != null),
        take(1)
      )
      .subscribe((result) => {
        this.loggedUserUid = result.uuid;
      });
  }

  getReasonsForCardList(): Observable<ReasonsForCardList> {
    return this._http.get<ReasonsForCardList>(
      `${env.BACKEND_API.BASE}${env.BACKEND_API.REASONS}${BACKEND_REASONS_FOR_DRIVER_CARD}`
    ).pipe(map((response) => {
      const reasons = response.reasons.filter(el => el.reasonType !== 
        'COMPLAINT');
      const modifiedResponse = {
        ...response,
        reasons
      }

      return modifiedResponse;
    }));
  }

  getReasonsForCardType$(cardType: ApplicationDto.TypeEnum): Observable<ReasonsForCardList> {
    return this._http.get<ReasonsForCardList>(
      `${env.BACKEND_API.BASE}${env.BACKEND_API.REASONS}${BACKEND_CARD_PARAMETER}${cardType}`
    );
  }

  submitReason(
    reason: Reason,
    isBusiness: boolean,
    isPaper?: boolean,
    employeeId?: string
  ): Observable<DriverCardApplicationDto> {
    const isUserAnonymous = !!Extensions.getCookie('anonymousToken');
    const headers = new HttpHeaders().set('accept', STC_V1).set('Access-Control-Expose-Headers', 'Anonymous-User-Id');

    if (isBusiness) {
      return this._http.post<DriverCardApplicationDto>(
        `${env.BACKEND_API.BASE}${env.BACKEND_API.EMPLOYERS}/${this.loggedUserUid}` +
          `${env.BACKEND_API.EMPLOYEES}/${employeeId}${env.BACKEND_API.APPLICATIONS}` +
          `${BACKEND_DRIVER_CARD}${env.BACKEND_API.REASONS}/${reason.reasonType}`,
        {},
        { headers: headers }
      );
    } else {
      if(isPaper) {
        if(!this.loggedUserUid && isUserAnonymous) {
          return this._http.post<DriverCardApplicationDto>(
            `${env.BACKEND_API.BASE}${env.BACKEND_API.ANONYMOUS}` +
            `${env.BACKEND_API.PAPER_FORM}${BACKEND_DRIVER_CARD}` +
            `${env.BACKEND_API.REASONS}/${reason.reasonType}`,
            {},
            { headers: headers, observe: 'response' }
          ).pipe(map(reponse => {
            setCookieWithHours(`ownerId=${reponse.headers.get('Anonymous-User-Id')};`, 4);
            setCookieWithHours(`applicationId=${reponse.body.id};`, 4);
            setCookieWithHours(`cardType=${reponse.body.type};`, 4);
            return reponse.body;
          }));;
        } else {
          return this._http.post<DriverCardApplicationDto>(
            `${env.BACKEND_API.BASE}${env.BACKEND_API.CARDHOLDERS}/${this.loggedUserUid}` +
              `${env.BACKEND_API.PAPER_FORM}${BACKEND_DRIVER_CARD}${env.BACKEND_API.REASONS}/` +
              `${reason.reasonType}`,
            {},
            { headers: headers }
          );
        }
      } else {
        return this._http.post<DriverCardApplicationDto>(
          `${env.BACKEND_API.BASE}${env.BACKEND_API.CARDHOLDERS}/${this.loggedUserUid}` +
            `${env.BACKEND_API.APPLICATIONS}${BACKEND_DRIVER_CARD}${env.BACKEND_API.REASONS}/` +
            `${reason.reasonType}`,
          {},
          { headers: headers }
        );
      }
    }
  }
}

<section class="application-verification">
    <ng-container>
      <header class="header">
        <div class="header-wrapper">
            <h1 class="header-title">{{('SHARED.APPLICATION_VERIFICATION_MODAL.title' | translate)}}</h1>
            <p>{{'SHARED.APPLICATION_VERIFICATION_MODAL.subtitle' | translate}}</p>
        </div>
        <button [attr.aria-label]="'SHARED.APPLICATION_VERIFICATION_MODAL.closeAria' | translate" (click)="onCancelClick()" class="close-btn">
          <svg-icon class="close-btn-icon" src="assets/images/close_mobile_.svg"></svg-icon>
        </button>
      </header>
      <main class="main">
        
        <form [formGroup]="formGroup">
            <app-form-input
            *ngIf="!shoudlAskForNip()"
            [inputId]="'birthDate'"
            formControlName="birthDate"
            type="text"
            [placeholder]="'SHARED.APPLICATION_VERIFICATION_MODAL.birthDate.placeholder' | translate"
            [label]="'SHARED.APPLICATION_VERIFICATION_MODAL.birthDate.label' | translate"
            [formGroup]="formGroup"
            mask="date"
            [isRequired]="true"
            [errorList]="[
              { label: ('SHARED.APPLICATION_VERIFICATION_MODAL.required' | translate), type: ['required'] },
              { label: ('SHARED.APPLICATION_VERIFICATION_MODAL.pattern' | translate), type: ['pattern'] }
            ]">
            </app-form-input>

            <app-form-input
            *ngIf="shoudlAskForNip()"
            [inputId]="'nip'"
            formControlName="nip"
            type="text"
            [placeholder]="'SHARED.APPLICATION_VERIFICATION_MODAL.nip.placeholder' | translate"
            [label]="'SHARED.APPLICATION_VERIFICATION_MODAL.nip.label' | translate"
            [formGroup]="formGroup"
            [isRequired]="true"
            [errorList]="[
              { label: ('SHARED.APPLICATION_VERIFICATION_MODAL.required' | translate), type: ['required'] },
              { label: ('SHARED.APPLICATION_VERIFICATION_MODAL.pattern' | translate), type: ['pattern'] }
            ]">
          </app-form-input>
        </form>

        <app-info-box 
        *ngIf="accessAttempt > 0"
        [infoBoxText]="'SHARED.APPLICATION_VERIFICATION_MODAL.wrongAccessData' | translate"
        [type]="'error'"
        [bordered]="true"
        ></app-info-box>

        <div class="nav-btns">
          <ic-ghost-button
          [typeHTML]="'button'"
          appPreventDoubleClick
          (throttledClick)="onConfirmClick()"
          [type]="GhostButtonType.BIG_FIRST_RANK_FLEX"
          [showArrow]="false"
          [justifyContent]="'center'"
          [disabled]="formGroup.invalid"
          [width]="140">
          <span
            class="forwardSpan start-btn__label"
            [innerHtml]="'SHARED.APPLICATION_VERIFICATION_MODAL.confirmButton' | translate"></span>
          </ic-ghost-button>

          <ic-ghost-button
          [typeHTML]="'button'"
          (click)="onCancelClick()"
          [type]="GhostButtonType.BIG_SECOND_RANK_FLEX"
          [showArrow]="false"
          [justifyContent]="'center'"
          [width]="140">
          <span class="forwardSpan start-btn__label" [innerHtml]="'SHARED.APPLICATION_VERIFICATION_MODAL.cancelButton' | translate"></span>
          </ic-ghost-button>
        </div>
      </main>
    </ng-container>
  </section>
  
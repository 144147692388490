import { TranslateService } from '@ngx-translate/core';
import { ErrorListIndicatorService } from './services/error-list-indicator.service';
import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { FormInputError } from './model/form-input.model';
import { KeyId } from './model/key-listener';
import { SelectableItemDereferencerDirective } from './selectable-item-dereferencer';
import { KeyEventListenerService } from './services/key-listener.service';
import { SelectableInputService } from './services/selectable-input.service';

export interface SelectableInputItem<T> {
  value: T;
  selected: boolean;
}

@Component({
  selector: 'app-selectable-input',
  templateUrl: './selectable-input.component.html',
  styleUrls: ['./selectable-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectableInputComponent),
      multi: true
    },
    KeyEventListenerService,
    SelectableInputService,
    ErrorListIndicatorService
  ]
})
export class SelectableInputComponent<
    ItemType,
    Key extends keyof ItemType,
    OutputKey extends keyof ItemType
  >
  extends SelectableItemDereferencerDirective<ItemType, Key, OutputKey>
  implements OnInit, OnDestroy, ControlValueAccessor {
  @Input('items') initialItems: ItemType[] = [];
  @Input() label: string;
  @Input() subLabel: string;
  @Input() subLabelSpaceing = false;
  @Input() placeholder: string;
  @Input() formGroup: UntypedFormGroup;
  @Input() formControlName: string;
  @Input() isRequired = false;
  @Input() errorList: FormInputError[] = [];
  @Input() disabledState = false;
  @Input() readOnly = false;
  @Input() defaultValue = null;
  @Input() htmlId: string;
  @Input() noMatchMessage: string;
  @Input() noActiveItemsMessage: string;
  @Input() isReportedForCorrection: boolean;
  @Input() warnValue: any;
  @Output() exit: EventEmitter<void> = new EventEmitter();

  inputValue: string;
  formControl: AbstractControl;
  isInvalidChanges: Observable<boolean>;
  errorListChanges: Observable<FormInputError[]>;
  private componentLifetimeSubscriptions: Subscription = new Subscription();

  constructor(
    private keyListener: KeyEventListenerService,
    private selectableInput: SelectableInputService,
    private errorListService: ErrorListIndicatorService,
    private translate: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    this.formControl = this.formGroup.get(this.formControlName);
    this.subscribeToFormChanges();
    this.setupErrorListService();
    this.setupOpenCloseBehavior();
    if (!this.noMatchMessage) {
      this.noMatchMessage = this.translate.instant('SHARED.SELECTABLE_INPUT_TEXTS.noMatchMessage');
    }

    if (this.defaultValue) {
      this.formControl.setValue(this.defaultValue);
      this.formGroup.controls[this.formControlName].value;
    }
  }

  private setupErrorListService() {
    this.errorListService.init(this.formControl, this.errorList);
    this.isInvalidChanges = this.errorListService.isInvalidChanges;
    this.errorListChanges = this.errorListService.errorListChanges;
  }

  private setupOpenCloseBehavior() {
    this.setupKeyEventCallbacks();
    const sub1 = this.selectableInput.opened$.subscribe(() => this.keyListener.subscribe());
    const sub2 = this.selectableInput.closed$
      .pipe(tap(() => this.exit.emit()))
      .subscribe(() => this.keyListener.unsubscribe());

    this.componentLifetimeSubscriptions.add(sub1);
    this.componentLifetimeSubscriptions.add(sub2);
  }

  private setupKeyEventCallbacks() {
    this.keyListener.callbacks = [
      {
        keys: [KeyId.ESCAPE, KeyId.TAB],
        callback: () => this.closeAndRestore()
      }
    ];
  }

  private subscribeToFormChanges() {
    const sub1 = this.formControl.valueChanges.subscribe((value) => this.writeValue(value));
    this.componentLifetimeSubscriptions.add(sub1);
  }

  ngOnDestroy() {
    this.componentLifetimeSubscriptions.unsubscribe();
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  onChange: any = (_: string) => {};

  onTouch: any = () => {};

  writeValue(obj: any): void {
    if (obj) {
      const foundItem = this.initialItems.find(
        (item: ItemType) => this.getOutputOf(item) === String(obj)
      );
      foundItem ? (this.inputValue = this.getKeyOf(foundItem)) : (this.inputValue = obj);
    } else {
      this.inputValue = obj;
      this.errorListService.updateValidationIndicators();
    }
  }

  onInputChange(value) {
    this.inputValue = value;
  }

  onClickOutside() {
    this.closeAndRestore();
  }

  onRiflebirdClose() {
    this.closeAndRestore();
  }

  onSelectionChange(item: ItemType) {
    this.saveToForm(item);
    this.close();
  }

  private close() {
    if (this.selectableInput.opened) {
      this.onTouch();
      this.errorListService.updateValidationIndicators();
      this.selectableInput.close();
    }
  }

  private saveToForm(item: ItemType) {
    const output = this.getOutputOf(item);
    this.onChange(output);
  }

  private closeAndRestore() {
    this.restoreToPreviousFormValue();
    this.close();
  }

  private restoreToPreviousFormValue() {
    const formValue = this.formGroup.controls[this.formControlName].value;
    const previouslySelectedItem =
      this.initialItems.find((item) => this.getOutputOf(item) === String(formValue)) || null;
    const previouslySelectedItemsKey = this.getKeyOf(previouslySelectedItem);
    if (formValue && previouslySelectedItemsKey == null) {
      this.inputValue = formValue;
    } else {
      this.inputValue = previouslySelectedItemsKey;
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabledState = isDisabled;
  }

  showWarning(): boolean {
    return !!this.warnValue && this.isTouched() && this.warnValue === this.formControl.value;
  }

  isTouched() {
    return this.formGroup?.controls[this.formControlName].touched;
  }

  isValid() {
    return this.formGroup?.controls[this.formControlName].valid;
  }
}

import { Translation as SearchServicesTranslation } from '../models/search/translation.model';
import * as shared from './shared.action';
import { createSelector, createFeatureSelector } from '@ngrx/store';
import { ServiceCategory } from '../models/service-category.model';
import { PopularService } from '../models/popular-service.model';

export interface SharedState {
  serviceCategoryData: ServiceCategory[];
  popularServices: PopularService[];
  searchServicesData: SearchServicesTranslation;
}

export const sharedInitialState: SharedState = {
  serviceCategoryData: new Array<ServiceCategory>(),
  popularServices: new Array<PopularService>(),
  searchServicesData: null
};

export function sharedReducer(
  state: SharedState = sharedInitialState,
  action: shared.Actions
): SharedState {
  switch (action.type) {
    case shared.ActionTypes.SET_SERVICE_CATEGORIES:
      return Object.assign({}, state, {
        serviceCategoryData: action.payload
      });
    case shared.ActionTypes.SET_POPULAR_SERVICES:
      return Object.assign({}, state, {
        popularServices: action.payload
      });

    case shared.ActionTypes.SET_SEARCH_SERVICES:
      return Object.assign({}, state, {
        searchServicesData: action.payload
      });
    default:
      return state;
  }
}

export const featureName = 'shared';

export const selectSharedState = createFeatureSelector<SharedState>(featureName);

export const selectServicesData = createSelector(
  selectSharedState,
  (state) => state.serviceCategoryData
);

export const selectPopularServicesData = createSelector(
  selectSharedState,
  (state) => state.popularServices
);

export const selectSearchServicesData = createSelector(
  selectSharedState,
  (state) => state.searchServicesData
);

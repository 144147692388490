<div class="ic-page" [formGroup]="formGroup" [attr.id]="htmlElementId ? htmlElementId : null">
  <div class="ic-page__form">
    <div
    *ngIf="staticContent?.contactInfoTitle"
      [ngClass]="{
        'ic-page__form-title': true,
        error: sectionErrorsExist(),
        'small-padding': staticContent?.contactInfoHint
      }">
      {{ staticContent?.contactInfoTitle }}
    </div>

    <div class="row" *ngIf="staticContent?.contactInfoHint">
      <div class="col-12 fit">
        <app-info-box
          [infoBoxText]="'STC.ADDRESS_DATA.CONTACT_INFO_HINT' | translate"
          [bordered]="true"
          [maxWidthLimited]="false"></app-info-box>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-6" *ngIf="formValidation.formFields.countryCode">
        <app-selectable-input
          #authorityCountry
          [id]="(htmlElementId ? htmlElementId + '-' : '') + 'countryCode'"
          *ngIf="countries"
          [htmlId]="'countryCode'"
          formControlName="countryCode"
          [isReportedForCorrection]="isFieldIncludedInCorrection(formSectionName + '.countryCode')"
          [warnValue]="findPreviousValue(formSectionName + '.countryCode')"
          [label]="staticContent.country.label"
          [isRequired]="isControlRequired('countryCode')"
          [formGroup]="formGroup"
          [items]="countries"
          key="countryName"
          outputKey="countryCode"
          [placeholder]="staticContent.country.placeholder"
          [errorList]="[{ label: staticContent.country.error, type: ['required'] }]"
          [readOnly]="countryLocked"
          (exit)="onCountrySelected()">
        </app-selectable-input>
      </div>
    </div>
    <div class="row">
      <div class="col-12 fit" *ngIf="formValidation.formFields.companyName">
        <app-form-input
          [inputId]="(htmlElementId ? htmlElementId + '-' : '') + 'companyName'"
          formControlName="companyName"
          [isReportedForCorrection]="isFieldIncludedInCorrection(formSectionName + '.companyName')"
          [warnValue]="findPreviousValue(formSectionName + '.companyName')"
          type="text"
          [placeholder]="staticContent.companyName.placeholder"
          [label]="staticContent.companyName.label"
          [formGroup]="formGroup"
          (blured)="onBlur()"
          [isRequired]="isControlRequired('companyName')"
          [errorList]="[
            { label: staticContent.tooLongValueError, type: ['maxlength'] },
            { label: staticContent?.companyName.error2, type: ['pattern'] }
          ]">
        </app-form-input>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6" *ngIf="formValidation.formFields.street">
        <app-form-input
          [inputId]="(htmlElementId ? htmlElementId + '-' : '') + 'street'"
          formControlName="street"
          [isReportedForCorrection]="isFieldIncludedInCorrection(formSectionName + '.street')"
          [warnValue]="findPreviousValue(formSectionName + '.street')"
          type="text"
          [placeholder]="staticContent?.street.placeholder"
          [label]="staticContent?.street.label"
          [formGroup]="formGroup"
          [isRequired]="isControlRequired('street')"
          (blured)="onBlur()"
          [errorList]="[
            { label: staticContent?.tooLongValueError, type: ['maxlength'] },
            { label: staticContent?.street.error2, type: ['pattern'] }
          ]">
        </app-form-input>
      </div>
      <div class="buildingNo col-12 col-md-3" *ngIf="formValidation.formFields.buildingNo">
        <app-form-input
          [inputId]="(htmlElementId ? htmlElementId + '-' : '') + 'buildingNo'"
          formControlName="buildingNo"
          [isReportedForCorrection]="isFieldIncludedInCorrection(formSectionName + '.buildingNo')"
          [warnValue]="findPreviousValue(formSectionName + '.buildingNo')"
          type="text"
          [placeholder]="staticContent?.building.placeholder"
          [label]="staticContent?.building.label"
          [formGroup]="formGroup"
          [isRequired]="isControlRequired('buildingNo')"
          (blured)="onBlur()"
          [errorList]="[
            { label: staticContent?.tooLongValueError, type: ['maxlength'] },
            { label: staticContent.building.error, type: ['required'] },
            { label: staticContent?.building.error2, type: ['pattern'] }
          ]">
        </app-form-input>
      </div>
      <div class="apartmentNo col-12 col-md-3" *ngIf="formValidation.formFields.apartmentNo">
        <app-form-input
          [inputId]="(htmlElementId ? htmlElementId + '-' : '') + 'apartmentNo'"
          formControlName="apartmentNo"
          [isReportedForCorrection]="isFieldIncludedInCorrection(formSectionName + '.apartmentNo')"
          [warnValue]="findPreviousValue(formSectionName + '.apartmentNo')"
          type="text"
          [placeholder]="staticContent?.apartment.placeholder"
          [label]="staticContent?.apartment.label"
          [formGroup]="formGroup"
          [isRequired]="isControlRequired('apartmentNo')"
          (blured)="onBlur()"
          [errorList]="[
            { label: staticContent?.tooLongValueError, type: ['maxlength'] },
            { label: staticContent?.apartment.error2, type: ['pattern'] }
          ]">
        </app-form-input>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6" *ngIf="formValidation.formFields.city">
        <app-form-input
          [inputId]="(htmlElementId ? htmlElementId + '-' : '') + 'city'"
          formControlName="city"
          [isReportedForCorrection]="isFieldIncludedInCorrection(formSectionName + '.city')"
          [warnValue]="findPreviousValue(formSectionName + '.city')"
          type="text"
          [placeholder]="staticContent?.city.placeholder"
          [label]="staticContent?.city.label"
          [formGroup]="formGroup"
          [isRequired]="isControlRequired('city')"
          (blured)="onBlur()"
          [errorList]="[
            { label: staticContent?.tooLongValueError, type: ['maxlength'] },
            { label: staticContent?.city.error, type: ['required'] },
            { label: staticContent?.city.error2, type: ['pattern'] }
          ]">
        </app-form-input>
      </div>
      <div class="col-12 col-md-6" *ngIf="formValidation.formFields.postCode">
        <app-form-input
          [inputId]="(htmlElementId ? htmlElementId + '-' : '') + 'postCode'"
          formControlName="postCode"
          [isReportedForCorrection]="isFieldIncludedInCorrection(formSectionName + '.postCode')"
          [warnValue]="findPreviousValue(formSectionName + '.postCode')"
          type="text"
          [placeholder]="staticContent?.zipcode.placeholder"
          [label]="staticContent?.zipcode.label"
          [formGroup]="formGroup"
          [isRequired]="isControlRequired('postCode')"
          (blured)="onBlur()"
          [errorList]="[
            { label: staticContent?.tooLongValueError, type: ['maxlength'] },
            { label: staticContent?.zipcode.error2, type: ['pattern'] },
            { label: staticContent?.zipcode.error, type: ['required'] }
          ]">
        </app-form-input>
      </div>
      <div class="col-12 col-md-6" *ngIf="formValidation.formFields.phoneNumber">
        <app-form-input
          [inputId]="(htmlElementId ? htmlElementId + '-' : '') + 'phoneNumber'"
          formControlName="phoneNumber"
          [isReportedForCorrection]="isFieldIncludedInCorrection('phoneNumber')"
          [warnValue]="findPreviousValue('phoneNumber')"
          type="text"
          [placeholder]="staticContent?.phone.placeholder"
          [label]="staticContent?.phone.label"
          [formGroup]="formGroup"
          [isRequired]="isControlRequired('phoneNumber')"
          (blured)="onBlur()"
          [errorList]="[
            { label: staticContent?.tooLongValueError, type: ['maxlength'] },
            { label: staticContent?.tooShortValueError, type: ['minlength'] },
            { label: staticContent?.phone.error2, type: ['pattern'] },
            { label: staticContent?.phone.error, type: ['required'] }
          ]">
        </app-form-input>
      </div>
      <div class="col-12 col-md-6" *ngIf="formValidation.formFields.email">
        <app-form-input
          [inputId]="(htmlElementId ? htmlElementId + '-' : '') + 'email'"
          formControlName="email"
          [isReportedForCorrection]="isFieldIncludedInCorrection('email')"
          [warnValue]="findPreviousValue('email')"
          type="text"
          [placeholder]="staticContent?.email.placeholder"
          [label]="staticContent?.email.label"
          [formGroup]="formGroup"
          [isRequired]="isControlRequired('email')"
          (blured)="onBlur()"
          [errorList]="[
            { label: staticContent?.tooLongValueError, type: ['maxlength'] },
            { label: staticContent?.email.error2, type: ['pattern'] },
            { label: staticContent?.email.error, type: ['required'] }
          ]">
        </app-form-input>
      </div>
    </div>
    <div
      class="row correspondence-checkbox-row"
      *ngIf="formValidation.formFields.correspondenceAddressCheckbox">
      <div class="col-12">
        <app-form-checkbox
          [inputId]="(htmlElementId ? htmlElementId + '-' : '') + 'correspondenceAddressCheckbox'"
          formControlName="correspondenceAddressCheckbox"
          [formGroup]="formGroup"
          type="checkbox"
          (change)="onBlur()"
          [label]="staticContent?.extraContactInfoLabel"
          [isRequired]="isControlRequired('correspondenceAddressCheckbox')">
        </app-form-checkbox>
      </div>
    </div>
  </div>
</div>

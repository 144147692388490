import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ValidatorFn, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { delay, map, take } from 'rxjs/operators';
import { AttachmentDto } from 'src/app/shared/models/attachments/attachment-dto.model';
import { AttachmentMetadata } from '../model';
import { AttachmentsFacadeService } from './attachments-facade.service';

@Injectable()
export class AttachmentValidatorFactory {
  constructor(private attachmentsService: AttachmentsFacadeService) {}

  public composeSyncValidators(config: AttachmentMetadata): ValidatorFn {
    return Validators.compose([
      this.maxFileSize(config.maxSize),
      this.acceptedFileExtensions(config.format)
    ]);
  }

  public composeAsyncValidators(config: AttachmentMetadata): AsyncValidatorFn {
    const validatorFunctions: AsyncValidatorFn[] = [
      this.uniqueFilename(this.attachmentsService.dtos$),
      this.maxFilesNumber(config.maxNumber, this.attachmentsService.selectDtosByType(config.type))
    ];
    if (config.required && config.attachable) {
      validatorFunctions.push(
        this.requiredAttachment(this.attachmentsService.selectDtosByType(config.type))
      );
    }
    return Validators.composeAsync(validatorFunctions);
  }

  private uniqueFilename(dtos: Observable<AttachmentDto[]>): AsyncValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value) {
        return of(null).pipe(delay(0));
      }
      return dtos.pipe(
        delay(0),
        take(1),
        map((attachments) => {
          const inputFiles = control.value.map((file: File) =>
            file.name.substring(0, file.name.lastIndexOf('.'))
          );
          const duplicatedFile = attachments.find((attachment) =>
            inputFiles.includes(
              attachment.fileName.substring(0, attachment.fileName.lastIndexOf('.'))
            )
          );
          const errorObj = duplicatedFile
            ? { duplicatedFile: { filename: duplicatedFile.fileName } }
            : null;
          return errorObj;
        })
      );
    };
  }

  private maxFilesNumber(
    maxFiles: number,
    dtosOfType: Observable<AttachmentDto[]>
  ): AsyncValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value) {
        return of(null).pipe(delay(0));
      }
      return dtosOfType.pipe(
        delay(0),
        take(1),
        map((dtos) => {
          const dtosLenght = dtos.length;
          const inputFilesLength = control.value.length;
          if (dtosLenght + inputFilesLength <= maxFiles) {
            return null;
          }
          return {
            maxFilesExceeded: { maxFiles: maxFiles }
          };
        })
      );
    };
  }

  private requiredAttachment(dtosOfType: Observable<AttachmentDto[]>): AsyncValidatorFn {
    return (control: AbstractControl) => {
      return dtosOfType.pipe(
        delay(0),
        take(1),
        map((dtos) => {
          if ((dtos == null || dtos.length === 0) && !control.value) {
            return {
              requiredAttachment: true
            };
          }
          return null;
        })
      );
    };
  }

  private maxFileSize(maxFileSizeInMB: number): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value) {
        return null;
      }
      const inputFiles = control.value;
      let errorObj = null;
      inputFiles.forEach((file) => {
        const fileSizeInMB = file.size / 1024 ** 2;
        if (fileSizeInMB > maxFileSizeInMB) {
          errorObj = {
            fileSizeExceeded: {
              filename: file.name,
              fileSize: fileSizeInMB,
              limit: maxFileSizeInMB
            }
          };
        }
      });
      return errorObj;
    };
  }

  private acceptedFileExtensions(acceptedExtensions: string[]): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value) {
        return null;
      }
      const inputFiles = control.value;
      let errorObj = null;
      inputFiles.forEach((file) => {
        const extension = file.name.split('.').pop();
        if (
          !acceptedExtensions
            .map((acceptedExtension) => acceptedExtension.toLowerCase())
            .includes(('.' + extension).toLowerCase())
        ) {
          errorObj = {
            invalidFileExtension: {
              filename: file.name,
              acceptedExtensions: acceptedExtensions
            }
          };
        }
      });
      return errorObj;
    };
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';

@Injectable()
export class TextEllipsisService {
  private clampedSubject: ReplaySubject<boolean> = new ReplaySubject();
  private enabledSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public isClamped$: Observable<boolean> = this.clampedSubject.asObservable();

  public isEnabled$: Observable<boolean> = this.enabledSubject.asObservable();

  public setClamped(isClamped: boolean) {
    this.clampedSubject.next(isClamped);
  }

  public toggleEnabled() {
    this.enabledSubject.next(!this.enabledSubject.getValue());
  }

  public setEnabled(isEnabled: boolean) {
    this.enabledSubject.next(isEnabled);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { FooterLinkModel } from '../_models/footer-link.model';
import { GhostButtonType } from './../../../shared/components/ghost-button/ghost-button-type.enum';
import { FooterBottom } from './../_models/footer.model';

@Component({
  selector: 'app-footer-products',
  templateUrl: './footer-products.component.html',
  styleUrls: ['./footer-products.component.scss']
})
export class FooterProductsComponent implements OnInit {
  @Input() content: FooterBottom;
  otherProducts: FooterLinkModel[];
  GhostButtonType: typeof GhostButtonType = GhostButtonType;
  constructor() {}

  ngOnInit() {
    this.otherProducts = [
      this.content.exciseBand,
      this.content.edoApp,
      this.content.sigillum,
      this.content.peopleAndDocs
    ];
  }
}

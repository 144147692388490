import { SignMethodModalService } from './sign-method-modal-service.interface';
import { Observable } from 'rxjs';
import { EpuapSignUrlDto } from '../models/epuap-sign-url.dto';
import { ApplicationSummaryConfirmationDto } from '../../../../../applications/stc/_models/application-summary-confirmation.dto';
import { EdoSignUrlDto } from '../models/edo-sign-url.dto';
import { QualifiedSignUrlDto } from '../models/qualified-sign-url.dto';
import { CorrectionSummaryConfirmationDto } from '../../../../../applications/stc/_models/correction-summary-confirmation.dto';
import { CardTypes } from '../../../../../applications/stc/_consts/card-types.enum';
import { environment as env } from '../../../../../../environments/environment';
import { inject, Injectable, InjectionToken, Type } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SignCorrectionMethodModalService } from './sign-correction-method-modal.service';

export const APPLICATION_SIGN_SERVICE_TOKEN = new InjectionToken<SignApplicationMethodModalService>(
  'SignApplicationMethodModalService',
  {
    providedIn: 'root',
    factory: () => new SignApplicationMethodModalService(inject(HttpClient))
  }
);
@Injectable({
  providedIn: 'root'
})
export class SignApplicationMethodModalService extends SignMethodModalService {
  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  static Factory: {
    providedIn?: 'root' | Type<any> | 'platform' | 'any';
    factory: () => SignApplicationMethodModalService;
  } = {
    providedIn: 'root',
    factory: () => new SignApplicationMethodModalService(inject(HttpClient))
  };

  cancelEpuapSign(applicationId: string): Observable<void> {
    return this.http.get<void>(
      `${env.BACKEND_API.BASE}${env.BACKEND_API.SIGN}${env.BACKEND_API.SIGN_CANCEL}${env.BACKEND_API.APPLICATIONS}/${applicationId}`,
      { responseType: 'text' as 'json' }
    );
  }

  getEdohubLink(applicationId: string): Observable<EdoSignUrlDto> {
    return this.http.get<EdoSignUrlDto>(
      `${env.BACKEND_API.BASE}${env.BACKEND_API.EDOHUB}${env.BACKEND_API.SIGN_LINK}${env.BACKEND_API.APPLICATIONS}/${applicationId}`
    );
  }

  getEpuapLink(applicationId: string): Observable<EpuapSignUrlDto> {
    return this.http.get<EpuapSignUrlDto>(
      `${env.BACKEND_API.BASE}${env.BACKEND_API.SIGN}${env.BACKEND_API.SIGN_LINK}${env.BACKEND_API.APPLICATIONS}/${applicationId}`
    );
  }

  getJnlpUrl(applicationId: string): Observable<QualifiedSignUrlDto> {
    return this.http.get<QualifiedSignUrlDto>(
      `${env.BACKEND_API.BASE}${env.BACKEND_API.SIGN_QUALIFIED}${env.BACKEND_API.JNLP_URL}${env.BACKEND_API.APPLICATIONS}/${applicationId}`
    );
  }

  sendConfirmation(
    applicationId: string,
    applicationType: CardTypes,
    isPaper: boolean = false
  ): Observable<void> {
    return this.http.put<void>(
      `${env.BACKEND_API.BASE}${isPaper ? env.BACKEND_API.PAPER_FORM : env.BACKEND_API.APPLICATIONS}${CardTypes.mapCardTypeToPathSegment(
        applicationType
      )}/${applicationId}${env.BACKEND_API.CONFIRM_SUMMARY}`,
      {},
      { headers: this.stcHeadersV2 }
    );
  }

  getStatus(documentSignedId: string): Observable<string> {
    return this.http.get<string>(
      `${env.BACKEND_API.BASE}${env.BACKEND_API.SIGN}${env.BACKEND_API.SIGN_STATUS}${env.BACKEND_API.APPLICATIONS}/${documentSignedId}`,
      { responseType: 'text' as 'json' }
    );
  }
}

<div
  #popup
  class="popup"
  [attr.aria-hidden]="!(openedChanges | async)"
  *ngIf="openedChanges | async">
  <ul class="results" *ngIf="resultsVisible">
    <li
      #resultElements
      *ngFor="let item of filteredResults"
      class="results__element"
      [id]="getKeyOf(item).toLowerCase().replace(' ', '-')"
      [class.results__element--selected]="isSelected(item)"
      (click)="onItemClick(item)"
      role="button">
      {{ getKeyOf(item) }}
    </li>
  </ul>

  <div *ngIf="!resultsVisible" class="results results--empty">
    {{ currentNoMatchMessage }}
  </div>
</div>

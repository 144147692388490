<div class="container-fluid news-wrapper" *ngIf="content">
  <section id="news-container" class="news-container container">
    <div class="row">
      <article
        id="news-slider-container"
        class="col-12 col-md-12 col-lg-12 col-xl-7 news-slider-container">
        <div id="news-navigation" class="news-navigation">
          <h3 class="col-xl-5 header">{{ content.title }}</h3>
          <div id="news-navigation-buttons" class="col-xl-7 news-navigation-buttons">
            <button
              aria-label="Poprzedni"
              (click)="prevSlides()"
              class="slide-button"
              [ngClass]="{ disabled: slidePage <= 0 }">
              <ng-template [ngTemplateOutlet]="roundButtonBack"></ng-template>
            </button>
            <button
              aria-label="Następny"
              (click)="nextSlides()"
              class="slide-button"
              [ngClass]="{ disabled: slidePage >= maxPages }">
              <ng-template [ngTemplateOutlet]="roundButtonNext"></ng-template>
            </button>
          </div>
        </div>
        <div id="articles-container" class="row news-slider">
          <!-- IS THIS EVEN VALID? -->
          <div
            id="sliders-container-data"
            #slidersContainerData
            (keydown.arrowright)="nextSlides()"
            (keydown.arrowleft)="prevSlides()"
            tabindex="0"
            [ngStyle]="{ transform: 'translateX(' + offset + 'px)' }"
            class="col-12 sliders-container">
            <a
              [routerLink]="['aktualnosci/' + slider.id]"
              #newsSlides
              tabindex="0"
              class="col-sm-12 col-md-4 col-xl-4 article news-slide"
              *ngFor="let slider of slidersContent; let i = index">
              <div class="news-slide-wrapper">
                <div
                  [ngStyle]="{'background-image':  'url(' + slider.imgSrc + ')'}"
                  alt=""
                  class="article-img"></div>
                <div class="article-date">{{ slider.date }}</div>
                <span  class="article-title">{{
                  slider.title | ellipsis: 80
                }}</span>
              </div>
            </a>
          </div>
        </div>
      </article>
      <aside id="popular-articles" class="col-12 col-md-12 col-lg-12 col-xl-5 popular-articles">
        <h3 id="popular-articles-title" class="header">
          {{ content.popularArticles }}
        </h3>
        <div id="popular-articles-container">
          <a
            class="recently-read-news"
            *ngFor="let news of recentlyReadNews"
            [routerLink]="['aktualnosci/' + news.id]"
            >{{ news.title }}</a
          >
        </div>
      </aside>
    </div>
  </section>
  <div class="row show-more-container">
    <div class="ic-btn">
      <ic-ghost-button
        [showArrow]="true"
        [ariaLabel]="'Zobacz wszystkie aktualności'"
        [routerLink]="['aktualnosci']"
        [queryParams]="{ page: 1 }">
        <span>{{ content.seeAllBtn }}</span>
      </ic-ghost-button>
    </div>
  </div>
</div>

<ng-template #roundButtonBack>
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
    <defs>
      <style>
        .cls-1 {
          fill: #2434b3;
        }

        .cls-2 {
          fill: #fff;
        }
      </style>
    </defs>
    <g transform="translate(24411 8431)">
      <circle
        data-name="Ellipse 2"
        class="cls-1"
        cx="15"
        cy="15"
        r="15"
        transform="translate(-24411 -8431)" />
      <path
        data-name="Path 5782"
        class="cls-2"
        d="M-69.119,10l-1.234,1.235,3.516,3.516h-8.015v1.5h7.6l-3.1,3.1,1.234,1.234,5.291-5.291Z"
        transform="translate(-24327.148 -8431) rotate(180 -69 15)" />
    </g>
  </svg>
</ng-template>
<ng-template #roundButtonNext>
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
    <defs>
      <style>
        .cls-1 {
          fill: #2434b3;
        }

        .cls-2 {
          fill: #fff;
        }
      </style>
    </defs>
    <g transform="translate(24411 8431)">
      <circle
        data-name="Ellipse 2"
        class="cls-1"
        cx="15"
        cy="15"
        r="15"
        transform="translate(-24411 -8431)" />
      <path
        data-name="Path 5782"
        class="cls-2"
        d="M-69.119,10l-1.234,1.235,3.516,3.516h-8.015v1.5h7.6l-3.1,3.1,1.234,1.234,5.291-5.291Z"
        transform="translate(-24327.148 -8431)" />
    </g>
  </svg>
</ng-template>

import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormInputError } from 'src/app/shared/components/forms/_model/form-input.model';

import { ControlConfig } from '../../../../my-account/settings/form.model';

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SimpleTextInputComponent,
      multi: true
    }
  ]
})
export class SimpleTextInputComponent implements OnInit, ControlValueAccessor {
  @Input() config: ControlConfig = null;
  @Input() form: UntypedFormGroup = null;
  @Input() mask: 'digit' | 'noWhitespace' | null = null;
  @Input() readOnly: boolean;
  @Input() inputWidth = 100;

  errors: FormInputError[] = [];

  invalid = false;

  value: string = null;

  ngOnInit(): void {
    this.errors = [];
    const control = this.form.get(this.config.formControlName);
    control.statusChanges.subscribe(() => {
      if (!control.invalid || !control.touched) {
        this.errors = [];
        this.invalid = false;
        return;
      }

      const freshErrors = [];

      this.config.formErrors.forEach((error) => {
        if (error.type.some((errorCode) => control.hasError(errorCode))) {
          freshErrors.push(error);
        }
      });
      this.errors = freshErrors;
      this.invalid = control.invalid && control.touched;
    });
  }

  onBlur() {
    this.onTouch();
    this.form.get(this.config.formControlName).updateValueAndValidity();
  }

  onValueChange($event) {
    $event.target.value = this.applyMask($event.target.value);
    this.onChange($event.target.value);
    this.value = $event.target.value;
  }

  private applyMask(val: string): string {
    switch (this.mask) {
      case 'digit':
        return val.replace(/\D/g, '');
      case 'noWhitespace':
        return val.replace(/\s/g, '');
      default:
        return val;
    }
  }

  writeValue(obj: string): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  onChange: any = (_: string) => {};

  onTouch: any = () => {};
}

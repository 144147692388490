import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FooterMiddle} from '../_models/footer.model';
import {FooterLinkModel} from '../_models/footer-link.model';
import {AppLanguage} from '../../../app-language.enum';

@Component({
  selector: 'app-footer-content',
  templateUrl: './footer-content.component.html',
  styleUrls: ['./footer-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterContentComponent implements OnInit {
  @Input() content: FooterMiddle;
  currentLng: string;
  appLanguage = AppLanguage;

  constructor(private router: Router) {
  }

  ngOnInit() {
    this.currentLng = localStorage.getItem('lang');
  }

  firstColumnAsArray(): FooterLinkModel[] {
    return [
      this.content.firstColumn.about,
      this.content.firstColumn.regulations,
      this.content.firstColumn.privacyPolicy,
      this.content.firstColumn.siteMap
    ];
  }

  secondColumnAsArray(): FooterLinkModel[] {
    return [
      this.content.secondColumn.driverLicense,
      this.content.secondColumn.vehicleRegistration,
      this.content.secondColumn.tachograph,
      this.content.secondColumn.adr
    ];
  }

  thirdColumnAsArray(): FooterLinkModel[] {
    return [
      this.content.thirdColumn.partners,
      this.content.thirdColumn.myAccount,
      this.content.thirdColumn.login,
      this.content.thirdColumn.register,
      this.content.thirdColumn.contact
    ];
  }

  tachographColumnAsArray(): FooterLinkModel[] {
    return [
      this.content.tachographColumn.tachograph,
      this.content.tachographColumn.tachograph_phone,
      this.content.tachographColumn.tachograph_email
    ];
  }

  otherColumnAsArray(): FooterLinkModel[] {
    return [
      this.content.otherColumn.other,
      this.content.otherColumn.other_phone,
      this.content.otherColumn.other_email
    ];
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { STC_V1 } from 'src/app/shared/consts/api-version.config';
import { environment as env } from 'src/environments/environment';

const GET_INFO_ABOUT_CHANGE = 'employee-data-changed';

@Injectable({
  providedIn: 'root'
})
export class EployeeDataChangeService {
  private header = new HttpHeaders().set('Content-Type', STC_V1);

  constructor(private http: HttpClient) {}

  updateEmployeeDataBasedOnApplication(applicationId: string): Observable<any> {
    return this.http.put<any>(
      `${env.BACKEND_API.BASE}${env.BACKEND_API.APPLICATIONS}${env.BACKEND_API.DRIVER_CARD}/${applicationId}/${GET_INFO_ABOUT_CHANGE}`,
      { headers: this.header }
    );
  }
}

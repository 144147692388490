<div
  *ngIf="date.holiday === undefined && date.other === undefined && content"
  class="term-container">
  <div class="row date-row">
    <div class="term-hour col-2 col-md-12">{{ date.hour }}</div>
    <div class="term-date col-10" *ngIf="day">
      {{ day.date | date: 'EEEE':undefined:'pl' }}
      {{ day.date | date: 'd':undefined:'pl' }}
      {{ (day.date | date: 'MMMM':undefined:'pl').slice(0, 3) }}
    </div>
  </div>
  <ng-container *ngFor="let availableExam of date.avalaibleExams">
    <div
      role="button"
      (click)="registerOnExam(availableExam)"
      class="exam-container"
      [ngClass]="{ 'nonactive-exam': availableExam.availablePlaces < 1 }">
      <div class="exam-type-title">{{ getExamName(availableExam.type) }}</div>
      <div class="exam-avaible-places" [ngClass]="{ nonactive: availableExam.availablePlaces < 1 }">
        <p class="left-text">{{ content.examsLeft }}</p>
        <div class="user-icon">
          <img
            *ngIf="availableExam.availablePlaces > 0; else userNonActive"
            alt="{{ 'WORD_REGISTRATION.CALENDAR_STEP.userIcon' | translate }}"
            src="assets/images/user_active.svg" />
          <ng-template #userNonActive>
            <img
              alt="{{ 'WORD_REGISTRATION.CALENDAR_STEP.userIcon' | translate }}"
              src="assets/images/user_nonactive.svg" />
          </ng-template>
        </div>
        <div class="places-count">{{ availableExam.availablePlaces }}</div>
      </div>
    </div>
  </ng-container>
</div>
<div class="holiday term-container" *ngIf="date.holiday || (date.other && content)">
  <div *ngIf="date.holiday" class="error-pill holiday-pill">
    {{ content.holiday }}
  </div>
  <div *ngIf="date.other" class="error-pill other-pill">
    {{ content.maintaince }}
  </div>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { FooterTop } from '../_models/footer.model';

@Component({
  selector: 'app-footer-top',
  templateUrl: './footer-top.component.html',
  styleUrls: ['./footer-top.component.scss']
})
export class FooterTopComponent implements OnInit {
  @Input() content: FooterTop;
  constructor() {}

  ngOnInit() {}
}

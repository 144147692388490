import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { OskLicenceType } from './../_models/posk-payment.model';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatPoskLicenceType' })
export class FormatPoskLicenceType implements PipeTransform {
  constructor(private translate: TranslateService) {}
  transform(value: OskLicenceType) {
    return this.translate.get('EXTERNAL_PAYMENTS.LICENCE_CONVERSION').pipe(
      map((conversionTexts) => {
        switch (value) {
          case OskLicenceType.EXAM:
            return conversionTexts.EXAM;
          case OskLicenceType.SMALL_OSK:
            return conversionTexts.SMALL_OSK;
          case OskLicenceType.MEDIUM_OSK:
            return conversionTexts.MEDIUM_OSK;
          case OskLicenceType.BIG_OSK:
            return conversionTexts.BIG_OSK;
          case OskLicenceType.OSK:
            return conversionTexts.OSK;
          default:
            return 'OSK';
        }
      })
    );
  }
}

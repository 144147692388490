<app-layout
  (keyup.TAB)="addFocusClass()"
  (keydown.SHIFT.TAB)="addFocusClass()"
  (click)="removeFocusClass()"
  (changeLng)="changeLng($event)"
  [ngClass]="focusOnTab ? 'focusElement' : ''"></app-layout>

<app-loaders
  fullScreen="true"
  type="splash"
  bdColor="#2434B3"
  animation="fadeOut"
  [show]="!routerLoaded"
  disableScroll="true"></app-loaders>

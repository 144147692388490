import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { MY_ACCOUNT_ROUTE } from 'src/app/my-account/_consts/my-account-route.const';
import { AuthService } from '../auth';

@Injectable()
export class AnonymousGuard  {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.auth.user$.pipe(
      first(),
      map((result) => {
        if (result) {
          this.router.navigate(['/', MY_ACCOUNT_ROUTE]);
          return false;
        }
        return true;
      })
    );
  }
}

<form class="acceptances-checkboxes" [formGroup]="formGroup" *ngIf="formGroup">
  <app-form-checkbox
    *ngIf="config.controls.length > 1"
    [inputId]="'ALL'"
    [isErrorContainer]="false"
    [checked]="allChecked"
    (valueChanged)="onAllChanged($event)"
    [label]="checkAllLabel"
    [bolded]="true"></app-form-checkbox>

  <ng-container *ngFor="let control of config.controls">
    <app-form-checkbox
      [inputId]="control.inputId"
      [formControlName]="control.formControlName"
      [isErrorContainer]="false"
      [formGroup]="formGroup"
      [isRequiredAfterButton]="control.required"
      [bolded]="control.bolded"
      [label]="control.label"
      [button]="control.button"
      (openModalClicked)="switchModalBaseOnCheckbox(control.formControlName)"
      (valueChanged)="onToggle($event, control.formControlName)">
    </app-form-checkbox>
  </ng-container>

  <span>{{ 'ACCEPTANCES.INFO' | translate }}</span>
</form>

import { TranslateService } from '@ngx-translate/core';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { CardTypes } from '../../../../applications/stc/_consts/card-types.enum';
import { ObtainCardType } from '../../../../application-landing-pages/obtain-tachograph-card/_consts/obtain-card.const';
import { AuthService } from '../../../../core/auth';
import { IndicatorService } from 'src/app/shared/services/indicator/indicator.service';
import { WizardStep } from 'src/app/shared/components/wizard-step/wizard-step';
import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Extensions } from 'src/app/shared/extensions/extensions';
import { ActivatedRoute, Router } from '@angular/router';
import {
  COMPLAINT_ROUTE,
  TACHOGRAPH_ROUTE
} from 'src/app/service-category/_consts/service-category-route.const';
import { ComplaintDataService } from './_services/complaint-data.service';
import { ComplaintReasons, ComplaintSingleReason } from './_consts/complaint.types';
import { filter, take } from 'rxjs/operators';
import { AcquireChannelType } from 'src/app/application-landing-pages/obtain-tachograph-card/_consts/obtain-card.const';
import { ChooseEmployeeModalComponent } from '../../modal/choose-employee-modal/choose-employee-modal.component';
import { EmployeeDto } from 'src/app/shared/models/employee/employee-dto.model';
import { DarftAlreadyExistsModalService } from 'src/app/applications/stc/driver-card-application/_services/application-already-exists.service';
import {
  DraftAlreadyExistsModalComponent,
  DraftAlreadyExistsModalOption
} from '../../modal/draft-already-exists-modal/draft-already-exists-modal.component';
import { MODAL_OVERLAY_CLICKED } from '../../modal/modal-ref';
import {
  ActionConfirmationModalComponent,
  ActionConfirmationModalType
} from '../../modal/action-confirmation-modal/action-confirmation-modal.component';
import { ApplicationDto } from 'src/app/shared/models/applications/application-dto.model';
import { DraftAlreadyExistsModal } from 'src/app/applications/stc/driver-card-application/_models/draft-already-exists-modal.model';
import { ToastrService } from 'ngx-toastr';
import { Subscriptions } from 'src/app/shared/rx-utils/subscriptions';
import { ReasonDto } from 'src/app/shared/models/applications/reason-dto.model';
import { ValidationExtension } from 'src/app/shared/validation/validation-extensions.const';
import { ComplaintCreatedDto } from './_dto/ComplaintCreatedDto';
import { ApplicationAlreadySubmittedModalComponent } from '../../modal/simple-nonblocking-information-modal/application-already-submitted-modal/application-already-submitted-modal.component';

@Component({
  selector: 'app-complaint-data',
  templateUrl: './complaint-data.component.html',
  styleUrls: ['./complaint-data.component.scss'],
  providers: [IndicatorService]
})
export class ComplaintDataComponent extends WizardStep implements OnInit, OnDestroy {
  @Input() nextStepRoute: string;
  @Input() isControlCard = false;
  @Input() cardType: CardTypes;

  PREVIOUS_STEP_ROUTE = `/${TACHOGRAPH_ROUTE}/${COMPLAINT_ROUTE}`;
  NEXT_STEP_ROUTE = '';
  ComplaintReasons = ComplaintReasons;
  reasonForCardForm: UntypedFormGroup;
  reason = {
    reasonType: null
  };
  reasonList: ComplaintSingleReason[] = [];
  isBusiness: boolean;
  cardholderId: string;
  params: any;
  isPersonal = null;
  isPaper: boolean;
  modalRef: any;
  subs = new Subscriptions();
  employee: EmployeeDto = null;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public router: Router,
    private indicator: IndicatorService,
    private elRef: ElementRef,
    private complaintService: ComplaintDataService,
    private auth: AuthService,
    public route: ActivatedRoute,
    private modalService: ModalService,
    private draftAlreadyExistsModalService: DarftAlreadyExistsModalService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {
    super(router);

    this.reasonForCardForm = this.formBuilder.group({
      reason: new UntypedFormControl(null, [Validators.required]),
      cardNumber: new UntypedFormControl(null, [Validators.required]),
      messageText: new UntypedFormControl(null)
    });

    this.subs.next = this.reasonForCardForm.valueChanges.subscribe({
      next: (newValue) => {
        this.reason.reasonType = newValue.reason;
      }
    });

    this.auth.user$
      .pipe(
        filter((result) => result != null),
        take(1)
      )
      .subscribe((result) => {
        this.isBusiness = AuthService.isBusinessUser(result);
        this.cardholderId = result.uuid;
      });
  }

  ngOnInit(): void {
    this.NEXT_STEP_ROUTE = this.nextStepRoute;
    this.getReasonList();
    this.params = this.route.snapshot.queryParams;
    this.isPaper = this.params.type === this.translate.instant(AcquireChannelType.PAPER);

    this.subs.next = this.reasonForCardForm.controls.reason.valueChanges.subscribe({
      next: (value) => {
        if (value === ComplaintReasons.OTHER) {
          this.reasonForCardForm.controls.messageText.setValidators(Validators.required);
          this.reasonForCardForm.controls.messageText.updateValueAndValidity({ emitEvent: false });
        } else {
          this.reasonForCardForm.controls.messageText.setValidators(null);
          this.reasonForCardForm.controls.messageText.updateValueAndValidity({ emitEvent: false });
        }
      }
    });

    this.openEmployeeModal();
  }

  openEmployeeModal() {
    if (
      this.isBusiness &&
      (this.cardType === CardTypes.DRIVER_CARD ||
      this.cardType === CardTypes.TEMPORARY_DRIVER_CARD ||
        this.cardType === CardTypes.WORKSHOP_CARD ||
        this.cardType === CardTypes.CONTROL_CARD)
    ) {
      this.modalRef = this.modalService.open(ChooseEmployeeModalComponent, {
        data: {
          reason: ReasonDto.ReasonTypeEnum.DAMAGEDORDEFECTIVECARD,
          cardType: this.cardType,
          freezeOverlay: true
        }
      });
      const sub = this.modalRef.afterClosed
        .pipe(filter((employee) => employee != null))
        .subscribe((employee: EmployeeDto) => {
          switch (this.cardType) {
            case CardTypes.TEMPORARY_DRIVER_CARD:
            case CardTypes.DRIVER_CARD:
              this.reasonForCardForm.controls.cardNumber.patchValue(
                employee?.driverDetailsDto.driverCardNumber
              );
              break;
            case CardTypes.WORKSHOP_CARD:
              this.reasonForCardForm.controls.cardNumber.patchValue(
                employee?.technicianDetailsDto.technicianCardNumber
              );
              break;
            case CardTypes.CONTROL_CARD:
              this.reasonForCardForm.controls.cardNumber.patchValue(
                employee?.controlOfficerDetailsDto?.controlCardNumber
              );
              break;
            default:
              this.reasonForCardForm.controls.cardNumber.reset();
          }
          sub.unsubscribe();
          this.employee = employee;
        });
    }
  }
  onBackClicked() {
    this.previousStep();
  }
  submitReason(): void {
    if (this.reasonForCardForm.valid) {
      this.tryToProceedWithApplication(this.isBusiness, this.cardholderId, this.employee);
    } else {
      this.controlCardNotSelected();
      Extensions.scrollToFirstInvalidInput();
    }
  }
  controlCardNotSelected(): void {
    if (!this.isBusiness && this.isPersonal == null && this.isControlCard) {
      this.elRef.nativeElement.querySelector('.label-reason')?.scrollIntoView({ block: 'center' });
      this.indicator.indicate('card-type-not-selected');
      return;
    }
  }

  isControlRequired(controlName: string) {
    return ValidationExtension.hasRequiredField(this.reasonForCardForm.get(controlName));
  }

  getReasonList(): void {
    this.complaintService.getReasonList().subscribe({
      next: (reasons) => {
        this.reasonList = reasons;
      }
    });
  }

  goToPersonalDataStep(isBusiness: boolean, employeeId?: string, isPersonal: boolean = false) {
    this.complaintService.submitReason(
        this.reasonForCardForm.controls.cardNumber.value,
        this.cardType,
        this.reason.reasonType,
        this.isPaper,
        isPersonal,
        this.reasonForCardForm.controls.messageText.value,
        isBusiness,
        employeeId
      ).subscribe((createdApplication: ComplaintCreatedDto) => {
        this.nextStep(createdApplication.applicationId);
      });
  }

  tryToProceedWithApplication(
    isBusiness: boolean,
    userId: string,
    employee?: EmployeeDto | boolean | any
  ): void {
    if ((this.cardType === CardTypes.DRIVER_CARD || this.cardType === CardTypes.TEMPORARY_DRIVER_CARD ) && this.cardholderId) {
      this.draftAlreadyExistsModalService
        .getInfoAboutExistingDraft$(userId, employee?.id).subscribe((res) => {
          if (res.draftExists) {
            this.showDraftAlreadyExistsModal(res, isBusiness, employee);
          } else {
            this.goToPersonalDataStep(isBusiness, employee ? employee.id : null);
          }
        });
    } else if (this.cardType === CardTypes.CONTROL_CARD) {
      const isPersonal = isBusiness ? employee !== false : this.isPersonal;
      this.goToPersonalDataStep(isBusiness, employee?.id, isPersonal);
    } else {
      this.goToPersonalDataStep(isBusiness, employee ? employee.id : null);
    }
  }

  showDraftAlreadyExistsModal(
    data: DraftAlreadyExistsModal,
    isBusiness: boolean,
    employee: EmployeeDto
  ) {
    if (!data.editAvailable) {
      this.modalRef = this.modalService.open(ApplicationAlreadySubmittedModalComponent, {
        data: { checkIfAlreadyExists: data, freezeOverlay: false }
      });
      this.modalRef.afterClosed.pipe(filter((result) => result != null)).subscribe(() => {});
    } else {
      this.modalRef = this.modalService.open(DraftAlreadyExistsModalComponent, {
        data: { checkIfAlreadyExists: data, freezeOverlay: false }
      });
      this.modalRef.afterClosed.pipe().subscribe((result) => {
        if (result == null || result === MODAL_OVERLAY_CLICKED) {
        } else if (result === DraftAlreadyExistsModalOption.EDIT) {
          this.nextStep(data.applicationId);
        } else if (result === DraftAlreadyExistsModalOption.DELETE) {
          this.modalRef = this.modalService.open(ActionConfirmationModalComponent, {
            data: ActionConfirmationModalType.DELETE_STC_DRAFT_APPLICATION
          });
          this.modalRef.afterClosed.subscribe((result) => {
            if (result == null || result === MODAL_OVERLAY_CLICKED) {
            } else {
              this.complaintService
                .deleteApplication(ApplicationDto.TypeEnum.DRIVERCARD, data.applicationId)
                .subscribe(
                  () => {
                    this.goToPersonalDataStep(isBusiness, employee.id);
                  },
                  () => {
                    this.toastr.error(
                      this.translate.instant('COMPLAINT.COMPLAINT_REASON.cantDeleteApp')
                    );
                  }
                );
            }
          });
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}

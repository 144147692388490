<button
  [attr.id]="htmlId"
  class="ghost-btn"
  [attr.aria-label]="ariaLabel"
  [ngClass]="{ inline: inline }"
  [type]="typeHTML"
  [disabled]="disabled"
  [style.width.px]="width"
  [style.height.px]="height"
  (click)="isDownload ? downloadFile() : null">
  <div class="ghost-btn__container" [ngClass]="loading ? 'ghost-btn__container--hidden' : ''">
    <svg
      *ngIf="showArrow && arrowLeft"
      class="next_arrow"
      xmlns="http://www.w3.org/2000/svg"
      width="7.33"
      height="11.009"
      viewBox="0 0 7.33 11.009"
      fill="white">
      <path
        d="M8.475,0,4.8,3.678,1.119,0,0,1.119l4.8,4.8,4.8-4.8Z"
        transform="translate(6 0) rotate(90)"
        stroke="rgba(0,0,0,0)"
        stroke-width="1"
        fill-rule="evenodd" />
    </svg>
    <ng-content></ng-content>
    <svg
      *ngIf="showArrow && !arrowLeft"
      class="next_arrow"
      xmlns="http://www.w3.org/2000/svg"
      width="7.33"
      height="11.009"
      viewBox="0 0 7.33 11.009"
      fill="white">
      <path
        d="M8.475,0,4.8,3.678,1.119,0,0,1.119l4.8,4.8,4.8-4.8Z"
        transform="translate(0.707 10.302) rotate(-90)"
        stroke="rgba(0,0,0,0)"
        stroke-width="1"
        fill-rule="evenodd" />
    </svg>
  </div>
  <div class="ghost-btn__spinner" *ngIf="loading">
    <svg
      class="spinner__content"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid">
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke="#FFF"
        stroke-width="10"
        r="35"
        stroke-dasharray="165 57">
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1s"
          values="0 50 50;360 50 50"
          keyTimes="0;1"></animateTransform>
      </circle>
    </svg>
  </div>
</button>

import { ValidationService } from './../../../validation/validation.service';
import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { BaseFormBinderService } from 'src/app/shared/services/form/base-form-binder.service';
import { PersonDataFormValidation } from './person-data.model';
import { ValidationFunctions } from 'src/app/shared/validation/validation-functions.const';

@Injectable()
export class PersonDataFormBinder extends BaseFormBinderService {
  private initialPeselValidator: ValidatorFn;

  constructor(private formBuilder: UntypedFormBuilder, private validationService: ValidationService) {
    super();
  }
  protected createForm(fieldValidations: PersonDataFormValidation) {
    const fg = new UntypedFormGroup({});
    const formFields = Object.entries(fieldValidations.formFields);
    formFields.forEach(([name, validator]: [string, ValidatorFn]) => {
      fg.addControl(name, new UntypedFormControl(null, validator, [ValidationFunctions.allowedCharsValidator(this.validationService)]));
    });
    if (fg.controls['pesel'].validator !== null) {
      fg.controls['hasNoPesel'].valueChanges.subscribe((noPesel) =>
        this.hasNoPeselValueChange(noPesel)
      );
    }
    this.initialPeselValidator = fieldValidations.formFields.pesel;
    return fg;
  }

  public updateDate(peselValue: string): void {
    if (!this.checkIfControlHasErrors('pesel', ['pattern', 'minlength', 'maxLength'])) {
      if (
        this.form.value.pesel !== null &&
        this.form.value.pesel !== undefined &&
        this.form.value.pesel !== ''
      ) {
        this.form
          .get('birthDate')
          .patchValue(
            peselValue.substr(4, 2) +
              '.' +
              (peselValue[2] < '2'
                ? peselValue.substr(2, 2) + '.19' + peselValue.substr(0, 2)
                : Number.parseInt(peselValue.slice(2, 3), 10) -
                  2 +
                  peselValue.substr(3, 1) +
                  '.20' +
                  peselValue.substr(0, 2))
          );
        this.form.get('birthDate').markAsTouched();
      } else {
        this.form.get('birthDate').setValue(' ');
      }
    }
  }

  hasNoPeselValueChange(noPesel: any) {
    if (noPesel) {
      this.enable('birthDate');
      if (this.getForm().get('birthDate').invalid) {
        this.setControl('birthDate', '');
      }
      this.setControl('pesel', ' ');
      setTimeout(() => {
        this.getForm().get('pesel').reset();
        this.disable('pesel');
      }, 0);
      this.getForm().get('birthDate').updateValueAndValidity();
      this.form.controls['pesel'].validator = this.initialPeselValidator;
      this.enable('personalDocumentIssuingAuthority');
      this.enable('personalDocumentNumber');
      this.enable('personalDocumentType');
    } else {
      this.enable('pesel');
      this.form.controls['pesel'].validator = Validators.compose([
        this.initialPeselValidator,
        Validators.required,
      ]);
      this.form.controls['pesel'].asyncValidator = Validators.composeAsync([
        ValidationFunctions.allowedCharsValidator(this.validationService)
      ]);
      if (this.getForm().get('pesel').invalid) {
        this.setControl('pesel', '');
      }
      if (
        this.getControlValue('birthDate') === '' ||
        this.getControlValue('birthDate') === null ||
        this.getControlValue('birthDate') === undefined
      ) {
        this.setControl('birthDate', ' ');
      }
      this.getForm().get('pesel').updateValueAndValidity();
      this.disable('birthDate');
      this.disable('personalDocumentIssuingAuthority');
      this.disable('personalDocumentNumber');
      this.disable('personalDocumentType');
    }
  }
}

import {IndicatorService} from 'src/app/shared/services/indicator/indicator.service';
import {Component, Inject, Injector, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {forkJoin, interval, of, Subscription} from 'rxjs';
import {catchError, finalize, tap} from 'rxjs/operators';
import {isNullOrUndefined} from 'src/app/shared/extensions/extensions';
import {GhostButtonType} from '../../ghost-button/ghost-button-type.enum';
import {BaseModalDirective} from '../base-modal';
import {Mode, SignatureMethod, SignStatus} from './consts/enums';
import {SignMethodsAvailability} from './models/sign-methods-availability.model';
import {SignMethodModalData} from './models/sign-method-modal-data.model';
import {SignMethodModalService} from './services/sign-method-modal-service.interface';

export const SIGN_MODAL_ABORT_CLICKED = 'abort';
export const SIGN_MODAL_READY = 'ready';

@Component({
  selector: 'app-sign-method-modal',
  templateUrl: './sign-method-modal.component.html',
  styleUrls: ['./sign-method-modal.component.scss'],
  providers: [IndicatorService]
})
export class SignMethodModalComponent extends BaseModalDirective implements OnInit, OnDestroy {
  stepsNumber = 3;
  method: SignatureMethod;
  SignatureMethod = SignatureMethod;
  mode: Mode;
  epuapLink: string;
  GhostButtonType: typeof GhostButtonType = GhostButtonType;
  selectedApplicationId: string;
  subscription: Subscription = new Subscription();
  response: { body: any; statusCode: string; statusCodeValue: number };
  headers: string[];
  instructionStep: number;
  timerButton;
  timerModal;
  showCancelButton: boolean;
  showNoResponseModal: boolean;
  disableSignButton: boolean;

  Mode: typeof Mode = Mode;
  edoUrl: string;
  signMethodsAvailability: SignMethodsAvailability;
  form: UntypedFormGroup;
  availabilityFetched: boolean;
  timeout: NodeJS.Timeout;
  private signMethodModalService: SignMethodModalService;

  constructor(
    @Inject(Injector) private injector: Injector,
    private router: Router,
    private indicator: IndicatorService
  ) {
    super();
  }

  getData(): SignMethodModalData {
    return super.getData() as SignMethodModalData;
  }

  ngOnInit() {
    this.instructionStep = 0;
    this.mode = this.getData().mode;
    this.selectedApplicationId = this.getData().applicationId;

    if (!this.getData().signMethodModalToken) {
      throw new Error('Cannot initiate SignMethodModalComponent without sign service');
    }

    this.signMethodModalService = this.injector.get<SignMethodModalService>(
      this.getData().signMethodModalToken
    );

    this.form = new UntypedFormGroup({
      signMethod: new UntypedFormControl(null)
    });

    this.subscription.add(
      this.form.controls['signMethod'].valueChanges.subscribe((newMethod: SignatureMethod) => {
        this.method = newMethod;
      })
    );

    if (!this.selectedApplicationId) {
      if (this.mode === Mode.PROFILE_WAIT) {
        throw new Error('Insufficient data for SignMethodModal');
      } else {
        this.getStatus();
      }
    }

    if (this.mode === Mode.PROFILE_WAIT) {
      this.waitForCompletion();
      this.prepareCancelButtonAndNoResponseModal();
    }

    if (this.mode === Mode.CERTIFICATE_WAIT) {
      this.getStatus();
    }

    this.fetchSignMethodsAvailability();
  }

  setInstructionStepOnDotClick(step: number) {
    this.instructionStep = step;
  }

  onOkClick() {
    if (isNullOrUndefined(this.method)) {
      this.indicator.indicate('sign-method-not-selected');
    } else if (this.method === SignatureMethod.PROFILE) {
      this.disableSignButton = true;
      this.signMethodModalService
        .sendConfirmation(
          this.selectedApplicationId,
          this.getData().applicationType
        )
        .subscribe(() => this.getEpuapLink());
    } else if (this.method === SignatureMethod.EDOHUB) {
      this.disableSignButton = true;
      this.signMethodModalService
        .sendConfirmation(
          this.selectedApplicationId,
          this.getData().applicationType
        )
        .subscribe(() => this.getEdohubLink());
    } else {
      this.mode = Mode.INSTRUCTION;
    }
  }

  onQualifiedSignClick() {
    if (this.method === SignatureMethod.CERTIFICATE) {
      this.signMethodModalService
        .sendConfirmation(
          this.selectedApplicationId,
          this.getData().applicationType
        )
        .subscribe(() => this.getJnlpUrl());
    }
  }

  onCancelClick() {
    this.close();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  getEpuapLink() {
    this.signMethodModalService
      .getEpuapLink(this.selectedApplicationId)
      .subscribe((result: any) => {
        this.disableSignButton = false;
        this.epuapLink = result.epuapUrl;
        window.location.href = this.epuapLink;
      });
  }

  onAbortClick() {
    this.signMethodModalService.cancelEpuapSign(this.selectedApplicationId).subscribe(() => {
      this.close(SIGN_MODAL_ABORT_CLICKED);
    });
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  getEdohubLink() {
    this.signMethodModalService
      .getEdohubLink(this.selectedApplicationId)
      .subscribe((result: any) => {
        this.disableSignButton = false;
        this.edoUrl = result.edoUrl;
        window.location.href = this.edoUrl;
      });
  }

  nextInstructionStep() {
    if (this.instructionStep < 2) {
      this.instructionStep += 1;
    }
  }

  private fetchSignMethodsAvailability(): void {
    this.signMethodsAvailability = {
      isEpuapAvailable: null,
      isEdoAvailable: null
    };

    const epuapFetch = this.signMethodModalService
      .isEpuapAvailable()
      .pipe(catchError(() => of({ isEpuapAvailable: false })));

    const edohubFetch = this.signMethodModalService
      .isEdoAvailable()
      .pipe(catchError(() => of({ isEdoAvailable: false })));

    forkJoin([epuapFetch, edohubFetch]).subscribe(([epuap, edohub]) => {
      this.signMethodsAvailability.isEpuapAvailable = epuap.isEpuapAvailable;
      this.signMethodsAvailability.isEdoAvailable = edohub.isEdoAvailable;

      this.availabilityFetched = true;
    });
  }

  private prepareCancelButtonAndNoResponseModal() {
    if (this.timerButton) {
      clearTimeout(this.timerButton);
    }
    if (this.timerModal) {
      clearTimeout(this.timerModal);
    }
    this.timerButton = setTimeout(() => (this.showCancelButton = true), 7000);
    this.timerModal = setTimeout(() => (this.showNoResponseModal = true), 50000);
  }

  private getJnlpUrl() {
    this.signMethodModalService
      .getJnlpUrl(this.selectedApplicationId)
      .pipe(
        tap(() => (this.mode = Mode.CERTIFICATE_WAIT)),
        finalize(() => this.setCertificateWaitMode())
      )
      .subscribe((result: any) => {
        window.location.href = result.jnlpUrl;
      });
  }

  private setCertificateWaitMode() {
    const source = interval(3000);
    this.subscription.add(source.subscribe(() => this.getStatus()));
  }

  private getStatus() {
    this.signMethodModalService.getStatus(this.selectedApplicationId).subscribe((resp) => {
      if (resp === SignStatus.SIGNED_OK) {
        this.close(SIGN_MODAL_READY);
      } else if (resp === SignStatus.SIGNED_NOT_OK) {
        this.mode = Mode.PROFILE_ERROR;
      } else if (resp === SignStatus.PENDING && this.method === SignatureMethod.PROFILE) {
        this.mode = Mode.PROFILE_WAIT;
      } else if (
        resp === SignStatus.NOT_CREATED &&
        this.method === SignatureMethod.PROFILE &&
        this.showNoResponseModal
      ) {
        this.mode = Mode.PROFILE_NO_RESPONSE;
      }
    });
  }

  private waitForCompletion() {
    const source = interval(1000);
    this.subscription.add(source.subscribe(() => this.getStatus()));
    this.timeout = setTimeout(() => {
      this.subscription.unsubscribe();
      this.mode = Mode.PROFILE_ERROR;
    }, 30000);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }
}

import { Action } from '@ngrx/store';
import { Header } from '../header/_models/header.model';
import { Footer } from '../footer/_models/footer.model';
import { InfoBarMessage } from '../info-bar/_models/info-bar-message.model';

export interface LayoutActions {
  SET_HEADER: string;
  SET_FOOTER: string;
  SET_INFO_BARS: string;
}

export const ActionTypes: LayoutActions = {
  SET_HEADER: '[Layout] SetHeader',
  SET_FOOTER: '[Layout] SetFooter',
  SET_INFO_BARS: '[Layout] SetInfoBars'
};

export class SetHeaderAction implements Action {
  type = ActionTypes.SET_HEADER;
  constructor(public payload: Header) {}
}
export class SetFooterAction implements Action {
  type = ActionTypes.SET_FOOTER;
  constructor(public payload: Footer) {}
}
export class SetInfoBars implements Action {
  type = ActionTypes.SET_INFO_BARS;
  constructor(public payload: InfoBarMessage[]) {}
}

export type Actions = SetHeaderAction | SetFooterAction | SetInfoBars;

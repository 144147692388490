import { isNullOrUndefined } from 'src/app/shared/extensions/extensions';
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormInputError } from '../_model/form-input.model';
import { TranslateService } from '@ngx-translate/core';
import { MaskConfig } from './mask/index';
import { MaskedInputDirective } from '@matheo/text-mask';

@Component({
  selector: 'app-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormInputComponent),
      multi: true
    }
  ]
})
export class FormInputComponent implements OnInit, AfterViewChecked, ControlValueAccessor {
  constructor(
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private elementRef: ElementRef
  ) {}
  preValue = '';
  el: HTMLInputElement;
  MaskConfig = MaskConfig;
  value: string;

  // value accesor
  onChange: (value) => void;
  onTouched: () => void;

  @ViewChild(MaskedInputDirective) textMask: MaskedInputDirective;
  @Output() blured = new EventEmitter<Event>();
  @Input() disabled: boolean;
  @Input() inputId: string;
  @Input() label: string;
  @Input() formControlName: string;
  @Input() formGroup: UntypedFormGroup;
  @Input() isRequired: Boolean;
  @Input() placeholder: string;
  @Input() errorList: FormInputError[];
  @Input() type = 'text';
  @Input() maxLength: number;
  @Input() min: number;
  @Input() max: number;
  @Input() step = 1;
  @Input() subLabel: string;
  @Input() subLabelSpaceing = false;
  @Input() mask;
  @Input() wrapSubLabel = false;
  @Input() info: string;
  @Input() isReportedForCorrection = false;
  @Input() warnValue: any;
  @Input() onClick: () => void = () => {};

  ngOnInit() {
    if (this.errorList) {
      this.errorList.forEach((error) => {
        error.label = error.key ? this.translate.instant(error.key) : error.label;
      });
    }
  }

  ngAfterViewChecked() {
    if (this.mask === 'date' || this.mask === 'accountNumber') {
      this.el = this.elementRef.nativeElement;
      this.el.autocomplete = 'new-password';
    }
  }

  onInputMask($event) {
    if (this.mask === 'digit') {
      $event.target.value = $event.target.value.replace(/\D/g, '');
    }
    this.preValue = $event.target.value;
    this.value = $event.target.value;
    this.onChange($event.target.value);
  }

  onPropagateBlur($event) {
    $event.target.value = ($event.target.value as string).trim();
    this.onChange($event.target.value);
    this.onTouched();
    this.blured.emit($event);
  }

  writeValue(value: string): void {
    this.preValue = value ? value : '';
    this.value = value ? value : '';
    setTimeout(() => this.updateTextMask(value), 0);
  }

  updateTextMask(value: string): void {
    if (this.mask === 'date' || this.mask === 'accountNumber') {
      this.textMask.writeValue(value);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onHasOneOfErrors(errors: string[]) {
    let invalid = false;
    errors.forEach((value) => {
      if (this.formGroup.controls[this.formControlName].hasError(value)) {
        invalid = true;
      }
    });
    return this.isTouched() && invalid;
  }

  incrementValue(): void {
    if (isNullOrUndefined(this.value) || this.value === '') {
      this.value = '0';
    }

    let value = parseFloat(this.value);
    if (isNullOrUndefined(this.max) || value < this.max) {
      value += this.step;
    }
    this.writeValue(value.toString());
    this.onChange(this.value);
  }

  decrementValue(): void {
    if (isNullOrUndefined(this.value) || this.value === '') {
      this.value = '0';
    }

    let value = parseFloat(this.value);
    if (isNullOrUndefined(this.min) || value > this.min) {
      value -= this.step;
    }
    this.writeValue(value.toString());
    this.onChange(this.value);
  }

  showWarning(): boolean {
    return !!this.warnValue && this.isTouched() && this.warnValue === this.value;
  }

  isTouched() {
    return this.formGroup?.controls[this.formControlName].touched;
  }

  isValid() {
    return this.formGroup?.controls[this.formControlName].disabled
      ? true
      : this.formGroup?.controls[this.formControlName].valid;
  }
}

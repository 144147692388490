import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/core/auth';
import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { ApplicationBasicDto } from 'src/app/shared/models/applications/application-basic-dto.model';
import { COMPANY_CARD_APPLICATION_PAYMENT_ROUTE } from '../../../../applications/stc/company-card-application/payment/_consts/payment.route.const';
import { COMPANY_CARD_APPLICATION_ROUTE } from '../../../../applications/stc/company-card-application/_consts/company-card-application.route.const';
import { CONTROL_CARD_APPLICATION_PAYMENT_ROUTE } from '../../../../applications/stc/control-card-application/payment/_consts/payment-route.const';
import { CONTROL_CARD_APPLICATION_ROUTE } from '../../../../applications/stc/control-card-application/_consts/control-card-application.route.const';
import { DRIVER_CARD_APPLICATION_PAYMENT_ROUTE } from '../../../../applications/stc/driver-card-application/payment/_consts/payment-route.const';
import { DRIVER_CARD_APPLICATION_ROUTE } from '../../../../applications/stc/driver-card-application/_consts/driver-card-application.route.const';
import { WORKSHOP_CARD_APPLICATION_PAYMENT_ROUTE } from '../../../../applications/stc/workshop-card-application/payment/_consts/payment-route.const';
import { WORKSHOP_CARD_APPLICATION_ROUTE } from '../../../../applications/stc/workshop-card-application/_consts/workshop-card-application.route.const';
import { STC_TACHOGRAPH_APPLICATIONS_ROUTE } from '../../../../applications/stc/_consts/stc-route.const';
import { CheckStatusResponseDto } from '../../../../applications/stc/company-card-application/_models/check-status-response-dto';
import { StcApplicationStatusDto } from '../../../../applications/stc/company-card-application/_models/stc-application-status-dto';
import { StatusDto } from 'src/app/shared/models/applications/status-dto.model';
import { GhostButtonType } from '../../ghost-button/ghost-button-type.enum';

@Component({
  selector: 'app-checking-stc-status-result',
  templateUrl: './checking-stc-status-result.component.html',
  styleUrls: ['./checking-stc-status-result.component.scss']
})
export class CheckingStcStatusResultComponent implements OnInit {
  errorIcon = 'assets/images/error';
  infoIconUrlFullPath = 'assets/images/i.svg';
  errorIconUrlFullPath = 'assets/images/error.svg';
  cssNormalStatusClassName = 'normal';
  cssErrorStatusClassName = 'error';
  cssSuccessStatusClassName = 'success';
  isLoggedIn: boolean;
  GhostButtonType = GhostButtonType;

  @Input() statusResults: CheckStatusResponseDto[];
  @Input() showHeader: boolean = true;

  constructor(
    private router: Router,
    private auth: AuthService,
    private translateService: TranslateService
  ) {
    this.auth.user$.subscribe({
      next: value => {
        this.isLoggedIn = !!value?.uuid;
      }
    })
   }

  ngOnInit(): void {
  }

  getNote(note: string): string {
    const splittedNote = note.split(';');

    if(splittedNote.length > 1) {
      return `<span class='notes-date'>${splittedNote[0]}</span><span class='notes-desc'>${splittedNote[1]}</span>`
    } else {
      return splittedNote[0];
    }
  }
  payForApplication(applicationId: string, applicationType: ApplicationBasicDto.TypeEnum): void {
    let appRoute: string, appPaymentRoute: string;
    switch (applicationType) {
      case ApplicationBasicDto.TypeEnum.DRIVER_CARD:
        appRoute = DRIVER_CARD_APPLICATION_ROUTE;
        appPaymentRoute = DRIVER_CARD_APPLICATION_PAYMENT_ROUTE;
        break;
      case ApplicationBasicDto.TypeEnum.WORKSHOP_CARD:
        appRoute = WORKSHOP_CARD_APPLICATION_ROUTE;
        appPaymentRoute = WORKSHOP_CARD_APPLICATION_PAYMENT_ROUTE;
        break;
      case ApplicationBasicDto.TypeEnum.COMPANY_CARD:
        appRoute = COMPANY_CARD_APPLICATION_ROUTE;
        appPaymentRoute = COMPANY_CARD_APPLICATION_PAYMENT_ROUTE;
        break;
      case ApplicationBasicDto.TypeEnum.CONTROL_CARD:
        appRoute = CONTROL_CARD_APPLICATION_ROUTE;
        appPaymentRoute = CONTROL_CARD_APPLICATION_PAYMENT_ROUTE;
        break;
    }
    this.router.navigate([
      `/${STC_TACHOGRAPH_APPLICATIONS_ROUTE}/${appRoute}/${applicationId}/${appPaymentRoute}`
    ]);
  }

  activeStatus(status: CheckStatusResponseDto): string {
    return status.statuses.find(el => el.active).status;
  }

  getTypeDescription(status: CheckStatusResponseDto): string {
    return status.statuses.find(el => el.active).description;
  }

  isTypeStatusError(status: CheckStatusResponseDto): boolean {
    return StatusDto.errorStatus.includes(this.activeStatus(status) as StatusDto.StatusEnum)
  }

  isTypeStatusSuccess(status: CheckStatusResponseDto): boolean {
    return StatusDto.successStatus.includes(this.activeStatus(status) as StatusDto.StatusEnum)
  }

  statusClass(status: CheckStatusResponseDto): string {
    if(StatusDto.errorStatus.includes(this.activeStatus(status) as StatusDto.StatusEnum)) {
      return this.cssErrorStatusClassName
    } else if (this.activeStatus(status) === StatusDto.StatusEnum.SENT) {
      return this.cssSuccessStatusClassName
    } else {
      return this.cssNormalStatusClassName
    }
  }

  statusInfoIcon(status: CheckStatusResponseDto): string {
    return StatusDto.errorStatus.includes(this.activeStatus(status) as StatusDto.StatusEnum) ? this.errorIconUrlFullPath : this.infoIconUrlFullPath; 
  }

  showPayButton(status: CheckStatusResponseDto): boolean {
    const paymentStatus = [
      StatusDto.StatusEnum.PAYMENTREJECTED,
      StatusDto.StatusEnum.PAYMENTTIMEOUT
    ];

    return paymentStatus.includes(this.activeStatus(status) as StatusDto.StatusEnum);
  }

  isErrorStatus(step: StcApplicationStatusDto): boolean {
    return StatusDto.errorStatus.includes(step.status);
  }

  stepDescription(step: StcApplicationStatusDto): string[] {
    const descriptionKey: string = 'STC.CHECKING_STC_APPLICATION_STATUSES_STEPS_INFO.DESCRIPTIONS.' + step.status;
    const descriptions: string[] = this.translateService.instant(descriptionKey);

    if(Array.isArray(descriptions)) {
      return descriptions;
    } else {
      return [];
    }
  }
}

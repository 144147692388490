<div class="mobile-services-container">
  <div class="languageSwitcher mobile">
    <!-- <a
      *ngIf="currentLng !== appLanguage.UKRAINIAN"
      class="UA"
      (click)="onClick(appLanguage.UKRAINIAN)"
      >Українська</a
    > -->
    <a *ngIf="currentLng !== appLanguage.POLISH" class="PL" (click)="onClick(appLanguage.POLISH)"
      >Polski</a
    >
  </div>

  <div class="mobile-services">
    <div class="mobile-menu-section">
      <span>
        {{ 'HEADER.service_categories_menu' | translate }}
      </span>
    </div>
    <div class="mobile-categories">
      <div *ngFor="let service of 'SERVICE_CATEGORY.SERVICES_INFO_BOX' | translate">
        <a
          routerLink="/{{ service.id }}"
          routerLinkActive="active-service-category"
          (click)="setActiveServiceCategory(service)">
          <div class="mobile-category-item">
            <div class="mobile-category-icon">
              <img src="{{ service.categoryIcon.url }}" alt="{{ service.categoryTitle }}" />
            </div>
            <div class="mobile-category-title">
              {{ service.categoryTitle }}
            </div>
          </div>
        </a>
      </div>
    </div>
    <a (click)="setActiveServiceCategory(null)" [routerLink]="[aboutLink]">
      <div class="mobile-menu-section">
        <span>
          {{ 'HEADER.about_us' | translate }}
        </span>
      </div>
    </a>

    <div class="mobile-menu-section" role="button" (click)="onLogout()" *ngIf="user">
      <span>
        {{ 'HEADER.logout' | translate | uppercase }}
      </span>
    </div>
    <!-- NOTE Hidden unprepered content

                    <div class="mobile-langs-section">
                        <div class="mobile-langs">
                            <span class="cyrillic">{{ 'HEADER.language_ukr' | translate}}</span>
                            <span>{{ 'HEADER.language_eng' | translate}}</span>
                        </div>
                    </div>
                -->
  </div>
</div>

export namespace UploadStatus {
  export abstract class Status {
    public readonly status: string;
    public readonly progress: number;

    protected constructor(status: string, progress?: number) {
      this.status = status;
      this.progress = progress || 0;
    }

    public equals(other: Status): boolean {
      if (other == null) return false;
      if (this.status !== other.status) return false;
      return true;
    }
  }

  export class Ready extends Status {
    public constructor() {
      super('READY');
    }
  }

  export class InProgress extends Status {
    public constructor(progress?: number) {
      super('IN_PROGRESS', progress);
    }
  }

  export class Uploaded extends Status {
    public constructor() {
      super('UPLOADED');
    }
  }

  export class Error extends Status {
    public constructor() {
      super('ERROR');
    }
  }

  export const READY = new Ready();
  export const IN_PROGRESS = new InProgress();
  export const UPLOADED = new Uploaded();
  export const ERROR = new Error();
}

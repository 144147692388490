import { BaseModalDirective } from 'src/app/shared/components/modal/base-modal';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-loss-information-modal',
  templateUrl: './card-loss-information-modal.component.html',
  styleUrls: ['./card-loss-information-modal.component.scss']
})
export class CardLossInformationModalComponent extends BaseModalDirective implements OnInit {

  constructor() { 
    super();
  }

  ngOnInit(): void {}

  onCancelClick(): void {
    this.close(null);
  }

  onNextClick(): void {
    this.close('NEXT')
  }
}

import { ValidationFunctions } from './../../../validation/validation-functions.const';
import { ValidationPatterns } from './../../../validation/validation-patterns.const';
import { DrivingLicenceDataFormValidation } from './driving-licence-data.model';
import { Validators } from '@angular/forms';
import { defaultPersonDataValidation } from '../person-data/person-data.validation';

/**
 * @Summary To disable field set its value to null
 */
export const defaultDrivingLicenseDataValidation: DrivingLicenceDataFormValidation = {
  formGroupName: 'drivingLicenceData',

  formFields: {
    authorityCountry: Validators.compose([Validators.nullValidator]),
    releaseDate: Validators.compose([
      Validators.minLength(8),
      Validators.maxLength(11),
      Validators.pattern(ValidationPatterns.date),
      ValidationFunctions.noFutureDateValidator(),
      ValidationFunctions.fireValidationParent('expirationDate'),
      ValidationFunctions.isDateAfterDateControlValidatorInGroup(
        'birthDate',
        defaultPersonDataValidation.formGroupName
      )
    ]),
    expirationDate: Validators.compose([
      Validators.minLength(8),
      Validators.maxLength(11),
      Validators.pattern(ValidationPatterns.date),
      ValidationFunctions.noPastDateValidator(),
      ValidationFunctions.isDateAfterDateControlValidatorParent('releaseDate')
    ]),
    serialNumber: Validators.compose([Validators.pattern(ValidationPatterns.driverLicenseNo), Validators.required]),
    issuingAuthority: Validators.compose([ValidationFunctions.maxLengthValidator(50)]),
    documentNumber: null,
    categories: null,
    validIndefinitely: Validators.compose([Validators.nullValidator])
  }
};

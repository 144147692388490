export interface WordExam {
  address: string;
  latitude: string;
  longitude: string;
  organizationUnitId: string;
  organizationUnitName: string;
  oskVehicleNumber: string;
  practice: WordExamDetails;
  practiceId: string;
  province: string;
  signLanguage: string;
  theory: WordExamDetails;
  theoryId: string;
  category: WordExamCategory;
}

export interface WordExamDetails {
  additionalInfo: string;
  date: string;
  examId: string;
}

export enum WordExamCategory {
  A = 'A',
  A_1 = 'A1',
  A_2 = 'A2',
  AM = 'AM',
  B = 'B',
  B_1 = 'B1',
  BE = 'BE',
  C = 'C',
  C_1 = 'C1',
  CE = 'CE',
  C_1_E = 'C1E',
  D = 'D',
  D_1 = 'D1',
  DE = 'DE',
  D_1_E = 'D1E',
  T = 'T',
  PT = 'PT',
  BLOK_C = 'BLOK_C',
  BLOK_D = 'BLOK_D'
}

export const oskVehicleAvailableCategories = [
  WordExamCategory.A,
  WordExamCategory.A_1,
  WordExamCategory.A_2,
  WordExamCategory.AM,
  WordExamCategory.B,
  WordExamCategory.BE
];

export const WordExamCategoryView = {
  [WordExamCategory.A]: 'A',
  [WordExamCategory.AM]: 'AM',
  [WordExamCategory.A_1]: 'A1',
  [WordExamCategory.A_2]: 'A2',
  [WordExamCategory.B]: 'B',
  [WordExamCategory.BE]: 'B+E',
  [WordExamCategory.B_1]: 'B1',
  [WordExamCategory.C]: 'C',
  [WordExamCategory.CE]: 'C+E',
  [WordExamCategory.C_1]: 'C1',
  [WordExamCategory.C_1_E]: 'C1+E',
  [WordExamCategory.D]: 'D',
  [WordExamCategory.DE]: 'D+E',
  [WordExamCategory.D_1]: 'D1',
  [WordExamCategory.D_1_E]: 'D1+E',
  [WordExamCategory.PT]: 'PT',
  [WordExamCategory.T]: 'T',
  [WordExamCategory.BLOK_C]: 'BLOK C',
  [WordExamCategory.BLOK_D]: 'BLOK D',
};

export interface CandidateDetails {
  firstname: string;
  lastname: string;
}

import { EmployeeDto } from './../../../models/employee/employee-dto.model';
import { Component, OnInit } from '@angular/core';
import { BaseModalDirective } from '../base-modal';
import { DeleteEmployeeModal } from './model/delete-employee-modal.model';

@Component({
  selector: 'app-delete-employee-modal',
  templateUrl: './delete-employee-modal.component.html',
  styleUrls: ['./delete-employee-modal.component.scss']
})
export class DeleteEmployeeModalComponent extends BaseModalDirective implements OnInit {
  employee: EmployeeDto;
  deleteEmployeeModalTexts: DeleteEmployeeModal;

  constructor() {
    super();
  }

  ngOnInit() {
    this.employee = this.getData();
  }

  onOkClick() {
    this.close(this.employee);
  }

  onCancelClick() {
    this.close();
  }
}

import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Extensions } from 'src/app/shared/extensions/extensions';
import { FOOTER_LINKS } from './_consts/footer-links.const';
import { Footer } from './_models/footer.model';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  content: Footer;

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.translateService.get('FOOTER').subscribe((footerTexts: Footer) => {
      this.content = Extensions.deepMerge(footerTexts, FOOTER_LINKS) as Footer;
    });
  }
}

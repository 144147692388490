import {
  STC_TACHOGRAPH_APPLICATIONS_ROUTE,
  STC_TACHOGRAPH_COMPLAINTS_ROUTE,
  STC_TACHOGRAPH_CORRECTIONS_ROUTE
} from '../../_consts/stc-route.const';
import { TEMPORARY_DRIVER_CARD_APPLICATION_REASON_ROUTE } from '../application-reason/_consts/application-reason.route.const';
import { TEMPORARY_DRIVER_CARD_APPLICATION_ATTACHMENTS_PARAMETRIZED_ROUTE } from '../attachments/_consts/attachments.route.const';
import { TEMPORARY_DRIVER_CARD_COMPLAINT_REASON_ROUTE } from '../complaint-reason/_consts/complaint-reason-route.const';
import { TEMPORARY_DRIVER_CARD_APPLICATION_PAYMENT_PARAMETRIZED_ROUTE } from '../payment/_consts/payment-route.const';
import { TEMPORARY_DRIVER_CARD_APPLICATION_PERSONAL_DATA_PARAMETRIZED_ROUTE } from '../personal-data/_consts/personal-data.route.const';
import { TEMPORARY_DRIVER_CARD_APPLICATION_SUMMARY_PARAMETRIZED_ROUTE } from '../summary/_consts/summary.route.const';

export const TEMPORARY_DRIVER_CARD_APPLICATION_ROUTE = 'czasowa-karta-kierowcy';

export const INDIVIDUAL_TEMPORARY_DRIVER_CARD_APPLICATION_STEPS_ROUTES: string[] = [
  `/${STC_TACHOGRAPH_APPLICATIONS_ROUTE}/${TEMPORARY_DRIVER_CARD_APPLICATION_ROUTE}/${TEMPORARY_DRIVER_CARD_APPLICATION_REASON_ROUTE}`,
  `/${STC_TACHOGRAPH_APPLICATIONS_ROUTE}/${TEMPORARY_DRIVER_CARD_APPLICATION_ROUTE}/${TEMPORARY_DRIVER_CARD_APPLICATION_PERSONAL_DATA_PARAMETRIZED_ROUTE}`,
  `/${STC_TACHOGRAPH_APPLICATIONS_ROUTE}/${TEMPORARY_DRIVER_CARD_APPLICATION_ROUTE}/${TEMPORARY_DRIVER_CARD_APPLICATION_ATTACHMENTS_PARAMETRIZED_ROUTE}`,
  `/${STC_TACHOGRAPH_APPLICATIONS_ROUTE}/${TEMPORARY_DRIVER_CARD_APPLICATION_ROUTE}/${TEMPORARY_DRIVER_CARD_APPLICATION_SUMMARY_PARAMETRIZED_ROUTE}`,
  `/${STC_TACHOGRAPH_APPLICATIONS_ROUTE}/${TEMPORARY_DRIVER_CARD_APPLICATION_ROUTE}/${TEMPORARY_DRIVER_CARD_APPLICATION_PAYMENT_PARAMETRIZED_ROUTE}`
];

export const INDIVIDUAL_TEMPORARY_DRIVER_CARD_COMPLAINT_STEPS_ROUTES: string[] = [
  `/${STC_TACHOGRAPH_COMPLAINTS_ROUTE}/${TEMPORARY_DRIVER_CARD_APPLICATION_ROUTE}/${TEMPORARY_DRIVER_CARD_COMPLAINT_REASON_ROUTE}`,
  `/${STC_TACHOGRAPH_COMPLAINTS_ROUTE}/${TEMPORARY_DRIVER_CARD_APPLICATION_ROUTE}/${TEMPORARY_DRIVER_CARD_APPLICATION_PERSONAL_DATA_PARAMETRIZED_ROUTE}`,
  `/${STC_TACHOGRAPH_COMPLAINTS_ROUTE}/${TEMPORARY_DRIVER_CARD_APPLICATION_ROUTE}/${TEMPORARY_DRIVER_CARD_APPLICATION_ATTACHMENTS_PARAMETRIZED_ROUTE}`,
  `/${STC_TACHOGRAPH_COMPLAINTS_ROUTE}/${TEMPORARY_DRIVER_CARD_APPLICATION_ROUTE}/${TEMPORARY_DRIVER_CARD_APPLICATION_SUMMARY_PARAMETRIZED_ROUTE}`,
  `/${STC_TACHOGRAPH_COMPLAINTS_ROUTE}/${TEMPORARY_DRIVER_CARD_APPLICATION_ROUTE}/${TEMPORARY_DRIVER_CARD_APPLICATION_PAYMENT_PARAMETRIZED_ROUTE}`
];

export const INDIVIDUAL_TEMPORARY_DRIVER_CARD_CORRECTION_STEPS_ROUTES: string[] = [
  `/${STC_TACHOGRAPH_CORRECTIONS_ROUTE}/${TEMPORARY_DRIVER_CARD_APPLICATION_ROUTE}/${TEMPORARY_DRIVER_CARD_APPLICATION_PERSONAL_DATA_PARAMETRIZED_ROUTE}`,
  `/${STC_TACHOGRAPH_CORRECTIONS_ROUTE}/${TEMPORARY_DRIVER_CARD_APPLICATION_ROUTE}/${TEMPORARY_DRIVER_CARD_APPLICATION_ATTACHMENTS_PARAMETRIZED_ROUTE}`,
  `/${STC_TACHOGRAPH_CORRECTIONS_ROUTE}/${TEMPORARY_DRIVER_CARD_APPLICATION_ROUTE}/${TEMPORARY_DRIVER_CARD_APPLICATION_SUMMARY_PARAMETRIZED_ROUTE}`
];

export const BUSINESS_TEMPORARY_DRIVER_CARD_APPLICATION_STEPS_ROUTES: string[] = [
  `/${STC_TACHOGRAPH_APPLICATIONS_ROUTE}/${TEMPORARY_DRIVER_CARD_APPLICATION_ROUTE}/${TEMPORARY_DRIVER_CARD_APPLICATION_REASON_ROUTE}`,
  `/${STC_TACHOGRAPH_APPLICATIONS_ROUTE}/${TEMPORARY_DRIVER_CARD_APPLICATION_ROUTE}/${TEMPORARY_DRIVER_CARD_APPLICATION_PERSONAL_DATA_PARAMETRIZED_ROUTE}`,
  `/${STC_TACHOGRAPH_APPLICATIONS_ROUTE}/${TEMPORARY_DRIVER_CARD_APPLICATION_ROUTE}/${TEMPORARY_DRIVER_CARD_APPLICATION_ATTACHMENTS_PARAMETRIZED_ROUTE}`,
  `/${STC_TACHOGRAPH_APPLICATIONS_ROUTE}/${TEMPORARY_DRIVER_CARD_APPLICATION_ROUTE}/${TEMPORARY_DRIVER_CARD_APPLICATION_SUMMARY_PARAMETRIZED_ROUTE}`,
  `/${STC_TACHOGRAPH_APPLICATIONS_ROUTE}/${TEMPORARY_DRIVER_CARD_APPLICATION_ROUTE}/${TEMPORARY_DRIVER_CARD_APPLICATION_PAYMENT_PARAMETRIZED_ROUTE}`
];

export const BUSINESS_TEMPORARY_DRIVER_CARD_COMPLAINT_STEPS_ROUTES: string[] = [
  `/${STC_TACHOGRAPH_COMPLAINTS_ROUTE}/${TEMPORARY_DRIVER_CARD_APPLICATION_ROUTE}/${TEMPORARY_DRIVER_CARD_COMPLAINT_REASON_ROUTE}`,
  `/${STC_TACHOGRAPH_COMPLAINTS_ROUTE}/${TEMPORARY_DRIVER_CARD_APPLICATION_ROUTE}/${TEMPORARY_DRIVER_CARD_APPLICATION_PERSONAL_DATA_PARAMETRIZED_ROUTE}`,
  `/${STC_TACHOGRAPH_COMPLAINTS_ROUTE}/${TEMPORARY_DRIVER_CARD_APPLICATION_ROUTE}/${TEMPORARY_DRIVER_CARD_APPLICATION_ATTACHMENTS_PARAMETRIZED_ROUTE}`,
  `/${STC_TACHOGRAPH_COMPLAINTS_ROUTE}/${TEMPORARY_DRIVER_CARD_APPLICATION_ROUTE}/${TEMPORARY_DRIVER_CARD_APPLICATION_SUMMARY_PARAMETRIZED_ROUTE}`,
  `/${STC_TACHOGRAPH_COMPLAINTS_ROUTE}/${TEMPORARY_DRIVER_CARD_APPLICATION_ROUTE}/${TEMPORARY_DRIVER_CARD_APPLICATION_PAYMENT_PARAMETRIZED_ROUTE}`
];

export const BUSINESS_TEMPORARY_DRIVER_CARD_CORRECTION_STEPS_ROUTES: string[] = [
  `/${STC_TACHOGRAPH_CORRECTIONS_ROUTE}/${TEMPORARY_DRIVER_CARD_APPLICATION_ROUTE}/${TEMPORARY_DRIVER_CARD_APPLICATION_PERSONAL_DATA_PARAMETRIZED_ROUTE}`,
  `/${STC_TACHOGRAPH_CORRECTIONS_ROUTE}/${TEMPORARY_DRIVER_CARD_APPLICATION_ROUTE}/${TEMPORARY_DRIVER_CARD_APPLICATION_ATTACHMENTS_PARAMETRIZED_ROUTE}`,
  `/${STC_TACHOGRAPH_CORRECTIONS_ROUTE}/${TEMPORARY_DRIVER_CARD_APPLICATION_ROUTE}/${TEMPORARY_DRIVER_CARD_APPLICATION_SUMMARY_PARAMETRIZED_ROUTE}`
];

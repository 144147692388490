import { MainPageService } from '../_service/main-page.service';
import { GhostButtonType } from '../../shared/components/ghost-button/ghost-button-type.enum';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Slide } from './models/slider.model';
import { Subscription } from 'rxjs';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { NgbSlideEvent } from '@ng-bootstrap/ng-bootstrap/carousel/carousel';
import { ActivatedRoute } from '@angular/router';
import { List } from 'immutable';

const sliderActionBoxMaxLength = 30;
const sliderActionBoxMaxLetters = 27;
const SLIDE_INTERVAL = 5000;
@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit, OnDestroy {
  @ViewChild('carousel', { static: true }) carousel: NgbCarousel;
  public sliderElements = new Array<Slide>();
  public isPaused = false;
  public imgSrc = 'assets/images/more_icon_normal.svg';
  public nextSlideHeader: string;
  public nextSliderElement: Slide;
  GhostButtonType: typeof GhostButtonType = GhostButtonType;
  private slideIdPrefix = 'ngb-slide-';
  private subscription = new Subscription();
  // todo remove when real cms show up
  private unusedSlidesIds = List<number>([211, 212, 213, 221, 223]);

  constructor(private route: ActivatedRoute, private mainPageService: MainPageService) {}

  ngOnInit() {
    this.getSliderElements();
  }

  getSliderElements() {
    this.sliderElements = this.mainPageService
      .getSlides()
      .filter((slide) => !this.unusedSlidesIds.contains(slide.id));
    this.sliderElements.sort((slide1, slide2) => slide1.ordinalNumber - slide2.ordinalNumber);
    if (this.sliderElements.length > 1) {
      this.nextSlideHeader = this.sliderElements[1].header;
      this.nextSliderElement = this.sliderElements[1];
    }
    setTimeout(() => {
      this.carousel.cycle();
    }, 0);
  }

  onSlideChange(event: NgbSlideEvent): void {
    let nextSlideId = Number.parseInt(event.current.replace(this.slideIdPrefix, ''), 10);
    if (nextSlideId === this.sliderElements.length - 1) {
      nextSlideId = 0;
    } else {
      nextSlideId++;
    }
    this.nextSlideHeader =
      this.sliderElements[nextSlideId].header.length >= sliderActionBoxMaxLength
        ? this.sliderElements[nextSlideId].header.slice(0, sliderActionBoxMaxLetters).concat('...')
        : this.sliderElements[nextSlideId].header;
    this.nextSliderElement = this.sliderElements[nextSlideId];
  }

  nextSlide() {
    this.carousel.next();
    if (this.isPaused) {
      this.carousel.pause();
    }
  }

  pauseOrStartCarousel() {
    if (this.isPaused) {
      this.carousel.interval = SLIDE_INTERVAL;
      this.carousel.cycle();
      this.isPaused = false;
    } else {
      this.carousel.interval = 0;
      this.carousel.pause();
      this.isPaused = true;
    }
  }

  prevSlide() {
    this.carousel.prev();
    if (this.isPaused) {
      this.carousel.pause();
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public buildSlideId(id: number): string {
    return this.slideIdPrefix + id;
  }
}

import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { CLoaders, PRIMARY_LOADER, LoadersInterface } from './loaders.enum';

@Injectable({
  providedIn: 'root'
})
export class LoadersService {
  private spinnerObservable = new ReplaySubject<CLoaders>(1);

  constructor() {}

  getLoaders(name: string): Observable<CLoaders> {
    return this.spinnerObservable.asObservable().pipe(filter((x) => x && x.name === name));
  }

  show(name: string = PRIMARY_LOADER, loaders?: LoadersInterface) {
    const showPromise = new Promise((resolve, _reject) => {
      if (loaders && Object.keys(loaders).length) {
        loaders['name'] = name;
        this.spinnerObservable.next(new CLoaders({ ...loaders, show: true }));
        resolve(true);
      } else {
        this.spinnerObservable.next(new CLoaders({ name, show: true }));
        resolve(true);
      }
    });
    return showPromise;
  }

  hide(name: string = PRIMARY_LOADER, debounce: number = 0) {
    const hidePromise = new Promise((resolve, _reject) => {
      setTimeout(() => {
        this.spinnerObservable.next(new CLoaders({ name, show: false }));
        resolve(true);
      }, debounce);
    });
    return hidePromise;
  }
}

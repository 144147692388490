<div class="ic-page" [attr.id]="htmlElementId ? htmlElementId : null">
  <div class="ic-page__form" [formGroup]="formGroup">
    <div
      [ngClass]="{
        'ic-page__form-title': true,
        error: sectionErrorsExist()
      }">
      {{ staticContent?.driverLicenceTitle }}
    </div>
    <div class="row">
      <div class="col-12 col-md-6" *ngIf="formValidation.formFields.validIndefinitely">
        <app-form-checkbox
          [isReportedForCorrection]="isFieldIncludedInCorrection('validIndefinitely')"
          [warnValue]="findPreviousValue('validIndefinitely')"
          inputId="validIndefinitely"
          formControlName="validIndefinitely"
          [formGroup]="formGroup"
          type="checkbox"
          [label]="staticContent?.validIndefinitely.label"
          (change)="onBlur()">
        </app-form-checkbox>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 stack--2" *ngIf="formValidation.formFields.authorityCountry">
        <app-selectable-input
          #authorityCountry
          id="authorityCountry"
          *ngIf="countries"
          [htmlId]="'authorityCountry'"
          [isReportedForCorrection]="isFieldIncludedInCorrection('authorityCountry')"
          [warnValue]="findPreviousValue('authorityCountry')"
          formControlName="authorityCountry"
          [label]="staticContent?.issuingCountry.label"
          [isRequired]="isControlRequired('authorityCountry')"
          [items]="countries"
          key="countryName"
          outputKey="countryCode"
          [formGroup]="formGroup"
          (exit)="onCountrySelected()"
          [placeholder]="staticContent?.issuingCountry.placeholder"
          [errorList]="[{ label: staticContent?.issuingCountry.error, type: ['required'] }]">
        </app-selectable-input>
      </div>
      <div class="col-12 col-md-6" *ngIf="formValidation.formFields.serialNumber">
        <app-form-input
          inputId="serialNumber"
          formControlName="serialNumber"
          type="text"
          [isReportedForCorrection]="isFieldIncludedInCorrection('serialNumber')"
          [warnValue]="findPreviousValue('serialNumber')"
          [placeholder]="staticContent.licenceNumber.placeholder"
          [isRequired]="isControlRequired('serialNumber')"
          [subLabel]="staticContent.licenceNumber.labelExtension"
          [label]="staticContent.licenceNumber.label"
          [formGroup]="formGroup"
          (blured)="onBlur()"
          [errorList]="[
            { label: staticContent?.licenceNumber.error3, type: ['maxlength'] },
            { label: staticContent?.licenceNumber.error2, type: ['pattern'] },
            { label: staticContent?.licenceNumber.error, type: ['required'] }
          ]">
        </app-form-input>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6" *ngIf="formValidation.formFields.releaseDate">
        <app-form-input
          inputId="releaseDate"
          formControlName="releaseDate"
          [isReportedForCorrection]="isFieldIncludedInCorrection('releaseDate')"
          [warnValue]="findPreviousValue('releaseDate')"
          type="text"
          [placeholder]="staticContent.issueDate.placeholder"
          [label]="staticContent.issueDate.label"
          mask="date"
          [isRequired]="isControlRequired('releaseDate')"
          [subLabel]="staticContent.issueDate.labelExtension"
          [wrapSubLabel]="true"
          [formGroup]="formGroup"
          (blured)="onBlur()"
          [errorList]="[
            { label: staticContent?.tooLongValueError, type: ['maxlength'] },
            {
              label: staticContent?.releaseDateBeforeBirthError,
              type: ['dateBeforeOtherDate']
            },
            { label: staticContent?.dateInFutureError, type: ['dateInFuture'] },
            { label: staticContent?.issueDate.error2, type: ['pattern'] },
            { label: staticContent?.issueDate.error, type: ['required'] }
          ]">
        </app-form-input>
      </div>
      <div class="col-12 col-md-6" *ngIf="formValidation.formFields.expirationDate">
        <app-form-input
          inputId="expirationDate"
          formControlName="expirationDate"
          [isReportedForCorrection]="isFieldIncludedInCorrection('expirationDate')"
          [warnValue]="findPreviousValue('expirationDate')"
          type="text"
          [placeholder]="staticContent.expiryDate.placeholder"
          [label]="staticContent.expiryDate.label"
          (blured)="onBlur()"
          [isRequired]="isControlRequired('expirationDate')"
          [subLabel]="staticContent.expiryDate.labelExtension"
          [wrapSubLabel]="true"
          [formGroup]="formGroup"
          mask="date"
          [errorList]="[
            { label: staticContent?.tooLongValueError, type: ['maxlength'] },
            {
              label: staticContent?.expirationDateBeforeReleaseError,
              type: ['dateBeforeOtherDate']
            },
            {
              label: staticContent?.dateInPastError,
              type: ['dateInPast']
            },
            { label: staticContent?.expiryDate.error2, type: ['pattern'] },
            { label: staticContent?.expiryDate.error, type: ['required'] }
          ]">
        </app-form-input>
      </div>
      <div
        *ngIf="showCovidDriverLicenseExpirationInfo"
        class="col-12 col-md-12 issuingAuthority covid-info">
        <app-info-box
          infoBoxText="{{ 'DRIVER_LICENSE.COVID_INFO' | translate }}"
          [bordered]="true"
          [maxWidthLimited]="false">
        </app-info-box>
      </div>
    </div>
    <div class="row">
      <div class="col-12 issuingAuthority" *ngIf="formValidation.formFields.issuingAuthority">
        <app-form-input
          inputId="issuingAuthority"
          *ngIf="!isDefaultCountrySelected()"
          formControlName="issuingAuthority"
          [isReportedForCorrection]="isFieldIncludedInCorrection('issuingAuthority')"
          [warnValue]="findPreviousValue('issuingAuthority')"
          [formGroup]="formGroup"
          [label]="staticContent?.issuer.label"
          (blured)="onBlur()"
          [isRequired]="isControlRequired('issuingAuthority')"
          [subLabel]="staticContent?.issuer.labelExtension"
          [placeholder]="staticContent?.issuer.placeholder"
          [errorList]="[
            { label: staticContent?.issuer.error, type: ['required'] },
            { label: staticContent?.tooLongValueError, type: ['maxlength'] }
          ]">
        </app-form-input>
        <app-selectable-input
          #issuingAuthority
          id="issuingAuthority"
          [htmlId]="'issuingAuthority'"
          formControlName="issuingAuthority"
          [isReportedForCorrection]="isFieldIncludedInCorrection('issuingAuthority')"
          [warnValue]="findPreviousValue('issuingAuthority')"
          *ngIf="isDefaultCountrySelected() && offices"
          [formGroup]="formGroup"
          [items]="offices"
          [key]="offices[0].displayName ? 'displayName' : 'name'"
          outputKey="name"
          [additionalFilterKeys]="['code']"
          [label]="staticContent?.issuer.label"
          (exit)="onIssuingAuthorityChange()"
          [isRequired]="isControlRequired('issuingAuthority')"
          [subLabel]="staticContent?.issuer.labelExtension"
          [placeholder]="staticContent?.issuer.placeholder"
          [errorList]="[
            { label: staticContent?.issuer.error, type: ['required'] },
            { label: staticContent?.issuer.error2, type: ['valueNotInArray'] },
            { label: staticContent?.tooLongValueError, type: ['maxlength'] }
          ]">
        </app-selectable-input>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6" *ngIf="formValidation.formFields.documentNumber">
        <app-form-input
          inputId="documentNumber"
          formControlName="documentNumber"
          [isReportedForCorrection]="isFieldIncludedInCorrection('documentNumber')"
          [warnValue]="findPreviousValue('documentNumber')"
          type="text"
          [placeholder]="staticContent.printNumber.placeholder"
          [label]="staticContent.printNumber.label"
          [isRequired]="isControlRequired('documentNumber')"
          [subLabel]="staticContent.printNumber.labelExtension"
          [formGroup]="formGroup"
          (blured)="onBlur()"
          [errorList]="[{ label: staticContent?.tooLongValueError, type: ['maxlength'] }]">
        </app-form-input>
      </div>
      <div class="col-12 col-md-6" *ngIf="formValidation.formFields.categories"></div>
    </div>
    <div class="drivingLicenceInfo">
      <span>
        {{ staticContent?.licenceHint }}
        <span
          class="showDrivingLicenceHint"
          (click)="onShowDrivingLicenceDescriptionModal()"
          role="button"
          >{{ staticContent?.licenceShowMoreLabel }}</span
        >
      </span>
    </div>
    <div class="foreignCountryInfo"
    *ngIf="binder.getControlValue('authorityCountry') !== 'PL' && staticContent?.foreignCountryInfo"
    >
      <app-info-box
      [infoBoxText]="staticContent?.foreignCountryInfo"
      [bordered]="true"
      ></app-info-box>
    </div>
  </div>
</div>

import { Router } from '@angular/router';
import { Extensions } from 'src/app/shared/extensions/extensions';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';
import { ACCOUNTS_V2 } from 'src/app/shared/consts/api-version.config';
import { environment as env } from 'src/environments/environment';
import {
  AcceptanceDecisionDto,
  AcceptanceDto,
  AcceptanceType,
  ClientAcceptancesDto,
  ClientAcceptancesResponseDto
} from '../model/acceptances-model';

const BUSINESS_CLIENT_SEGMENT = '/business-client/stc';
const INDIVIDUAL_CLIENT_SEGMENT = '/individual-client';
const REQUIRED_ACCEPTANCES = '/acceptances';
const UPDATE_ACCEPTANCES = '/update-acceptances';

@Injectable()
export class AcceptancesService {
  private availableAcceptances: AcceptanceDto[];
  constructor(private _http: HttpClient, private router: Router) {}

  getAcceptances(email: string): Observable<ClientAcceptancesDto> {
    const httpHeaders = new HttpHeaders().set('accept', ACCOUNTS_V2);
    return this._http
      .get<ClientAcceptancesDto>(
        `${env.ACCOUNT_MANAGEMENT.BASE}${env.ACCOUNT_MANAGEMENT.ACCOUNT_MANAGEMENT}` +
          `${env.ACCOUNT_MANAGEMENT.CLIENT}${REQUIRED_ACCEPTANCES}/${email}`,
        { headers: httpHeaders }
      )
      .pipe(tap((result) => (this.availableAcceptances = result.availableAcceptances)));
  }

  getAvailableAcceptances(isBusiness: boolean): Observable<AcceptanceDto[]> {
    return this._http
      .get<AcceptanceDto[]>(
        `${env.ACCOUNT_MANAGEMENT.BASE}${env.ACCOUNT_MANAGEMENT.ACCOUNT_MANAGEMENT}${
          isBusiness
            ? env.ACCOUNT_MANAGEMENT.BUSINESS_CLIENT + env.ACCOUNT_MANAGEMENT.STC
            : env.ACCOUNT_MANAGEMENT.INDIVIDUAL_CLIENT
        }${env.ACCOUNT_MANAGEMENT.AVAILABLE_ACCEPTANCES}`
      )
      .pipe(tap((result) => (this.availableAcceptances = result)));
  }

  updateAcceptances(
    acceptances: AcceptanceType[],
    userName: string
  ): Observable<ClientAcceptancesResponseDto> {
    const httpHeaders = new HttpHeaders().set('accept', ACCOUNTS_V2);
    if (!this.availableAcceptances) {
      throwError('Available acceptances need to be fetched first');
    }
    const payload: AcceptanceDecisionDto[] = this.availableAcceptances.map((acceptance) => ({
      name: acceptance.name,
      granted: !!acceptances.find((acceptanceName) => acceptance.name === acceptanceName)
    }));
    return this._http.post<ClientAcceptancesResponseDto>(
      `${env.BACKEND_API.BASE_ACCOUNT_MANAGMENT}${env.BACKEND_API.ACCOUNT_MANAGMENT}` +
        `${env.BACKEND_API.CLIENT}${UPDATE_ACCEPTANCES}/${userName}`,
      payload,
      { headers: httpHeaders }
    );
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GhostButtonType } from 'src/app/shared/components/ghost-button/ghost-button-type.enum';
import { ExamType } from '../_models/available-exam';
import { SelectedExam } from '../_models/selected-exam.model';
import { CalendarStepAccordionContent } from '../_models/word-exam-calendar-content.model';
import { ScheduledDays, WordExam } from '../_models/word-exam-schedule-list.model';

@Component({
  selector: 'exam-days-accordion',
  templateUrl: './exam-days.accordion.html',
  styleUrls: ['./exam-days.accordion.scss']
})
export class ExamDaysAccordionComponent implements OnInit {
  @Input() days: ScheduledDays[];
  @Input() activeFilter: ExamType = null;
  @Input() content: CalendarStepAccordionContent;
  @Output() examSelected: EventEmitter<SelectedExam> = new EventEmitter();
  GhostButtonType = GhostButtonType;
  ExamType = ExamType;
  opened = [];

  constructor() {}
  ngOnInit(): void {}

  selectExam(theory: WordExam, practice: WordExam): void {
    this.examSelected.next({ theory: theory, practice: practice });
  }
}

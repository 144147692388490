import { Extensions } from 'src/app/shared/extensions/extensions';
import { AuthService } from './../../../../core/auth/auth.service';
import { WizardStepBackModalService } from './services/wizard-step-back-modal.service';
import { GhostButtonType } from 'src/app/shared/components/ghost-button/ghost-button-type.enum';
import { BaseModalDirective } from 'src/app/shared/components/modal/base-modal';
import { Component, OnInit } from '@angular/core';
import { WizardStepBackModal } from './model/wizard-step-back-modal.model';

@Component({
  selector: 'app-wizard-step-back-modal',
  templateUrl: './wizard-step-back-modal.component.html',
  styleUrls: ['./wizard-step-back-modal.component.scss']
})
export class WizardStepBackModalComponent extends BaseModalDirective implements OnInit {
  GhostButtonType: typeof GhostButtonType = GhostButtonType;
  content: WizardStepBackModal;
  appDeleteWarning: boolean;
  isAnonymous: boolean;

  constructor(private contentService: WizardStepBackModalService, private auth: AuthService) {
    super();
    this.auth.state$.subscribe({
      next: value => {
        this.isAnonymous = !value.user?.uuid && (!!Extensions.getCookie('anonymousToken') || !!sessionStorage.getItem('anonymousToken'));
      }
    })
  }

  ngOnInit() {
    if (this.getData() && this.getData().delete) {
      this.appDeleteWarning = true;
    } else {
      this.appDeleteWarning = false;
    }
  }

  onOkClick() {
    this.close(true);
  }

  onCancelClick() {
    this.close();
  }
}

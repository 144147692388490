<div
  class="attachment-item"
  [ngClass]="{
    thumbnail: attachment.status.equals(uploadStatus.UPLOADED) && showThumbnail,
    'correction-warn': showWarning()
  }">
  <div class="file" *ngIf="!attachment.status.equals(uploadStatus.UPLOADED) || !showThumbnail">
    <div
      class="file__loader"
      *ngIf="attachment.status.equals(uploadStatus.IN_PROGRESS)"
      [style.width.%]="attachment.status.progress"></div>
    <span class="file__text">{{ attachment.dto.fileName }}</span>
  </div>
  <div class="pdf" *ngIf="attachment.status.equals(uploadStatus.UPLOADED) && showPdf">
    <pdf-viewer [src]="'data:application/pdf;base64,' + attachment.dto.content"
      [original-size]="false"
      [render-text]="false"
      [autoresize]="true"
      style="width: 300px; height: 350px;"
    ></pdf-viewer>
  </div>
  <div class="img-file" *ngIf="attachment.status.equals(uploadStatus.UPLOADED) && showThumbnail">
    <img
      alt=""
      [id]="htmlId ? htmlId : ''"
      class="thumbnail"
      [src]="'data:image/png;base64,' + attachment.dto.content" />
  </div>
  <p *ngIf="showWarning()" class="warn-descriptor">
    {{ 'STC.CORRECTIONS.ATTACHMENT_HINT' | translate }}
  </p>
  <div
    class="options"
    [ngClass]="{
      thumbnail: attachment.status.equals(uploadStatus.UPLOADED) && showThumbnail
    }">
    <button
      class="options__btn options__btn--edit"
      *ngIf="attachment.status.equals(uploadStatus.UPLOADED) && showThumbnail"
      (click)="onEdit(attachment)"
      type="button">
      {{ 'STC.ATTACHMENTS.ATTACHMENT.edit' | translate }}
    </button>
    <div class="option-remove" role="button" (click)="onDelete(attachment)">
      <button
        class="options__btn options__btn--remove"
        *ngIf="
          !attachment.status.equals(uploadStatus.IN_PROGRESS) &&
          !attachment.status.equals(uploadStatus.READY)
        ">
        {{ 'STC.ATTACHMENTS.ATTACHMENT.delete' | translate }}
      </button>
    </div>
    <a
      class="options__btn options__btn--send"
      *ngIf="attachment.status.equals(uploadStatus.ERROR)"
      role="button"
      (click)="onReupload(attachment)"
      >{{ 'STC.ATTACHMENTS.ATTACHMENT.send' | translate }}</a
    >
  </div>
</div>

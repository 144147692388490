import { MY_ACCOUNT_ROUTE } from '../../../my-account/_consts/my-account-route.const';
import {
  ADR_ROUTE,
  DRIVER_LICENSE_PAYMENT,
  DRIVER_LICENSE_ROUTE,
  TACHOGRAPH_ROUTE,
  VEHICLE_REGISTRATION_ROUTE
} from '../../../service-category/_consts/service-category-route.const';
import { EXAMS_ROUTE } from '../../../my-account/exams/_consts/exams.route.const';
import { NOTIFICATIONS_PANEL_ROUTE } from '../../../my-account/notifications-panel/_consts/notifications-panel.route.const';
import { NOTIFICATION_FORM_ROUTE } from '../../../my-account/notifications-panel/notification-form/_consts/notification-form.route.const';
import { WORD_EXAM_ROUTE } from '../../../applications/word-exam-registration/_consts/word-exam-route.const';
import { SEARCH_ROUTE } from '../../../search-results/_consts/search-route.const';
import { MESSAGES_ROUTE } from '../../../my-account/messages/_consts/messages.route.const';
import { STATEMENTS_ROUTE } from '../../../my-account/statements/_consts/statements.route.const';
import { LIST_EMPLOYEES_ROUTE } from '../../../my-account/company-employees/_consts/company-employees.route.const';
import { LIST_APPLICATIONS_ROUTE } from '../../../my-account/list-applications/_consts/list-applications.route.const';
import { PARTNERS_ROUTE } from '../../../service-category/services-info-pages/tachograph/partners/_consts/partners-route.const';
import {
  CONTACTPAGE_ROUTE,
  DEFECTIVE_CARD_INFO_ROUTE,
  SIGNATURES_INFO_PAGE,
  SITE_MAP_PAGE
} from '../../../info-pages/_consts/info-pages-route.const';
import {
  FORGOT_PASSWORD_ROUTE,
  LOGIN_ROUTE,
  RESET_PASSWORD_ROUTE
} from '../../../login/_consts/login-route.const';
import { CONTACT_ROUTE } from '../../../service-category/services-info-pages/tachograph/contact/_consts/contact-route.const';
import { REGISTRATION_ROUTE } from '../../../registration/_consts/registration-routes.const';
import { ADR_CLASSES_ROUTE } from '../../../service-category/services-info-pages/adr/adr-classes/_const/adr-classes-route';
import { SETTINGS_ROUTE } from '../../../my-account/settings/_consts/settings-route.const';

export class Breadcrumb {
  static readonly LABEL_PREFIX = 'BREADCRUMBS.';
  label: string;
  url: string;
}

export enum Page {
  Home,

  MyAccount,
  MyAccountTachograph,
  MyAccountDriverLicense,
  MyAccountVehicleRegistration,
  ExamsList,
  Settings,
  NotificationsPanel,
  NotificationsPanelAddForm,
  Statements,

  DriversLicence,
  ExamRegistration,

  SearchResults,
  Stc,
  Messages,
  Employees,
  ApplicationsSent,
  ApplicationsDrafts,
  Contact,

  SiteMapPage,

  Partners,
  SignaturesInfoPage,
  Login,
  ForgotPassword,
  ResetPassword,
  TachographContact,
  Registration,
  DriverLicence,
  DriverLicensePayment,
  VehicleRegistration,

  Adr,
  AdrClasses,
  DefectiveCards
}

export function mapPagesToBreadcrumbs() {
  return {
    [Page.Home]: { label: `${Breadcrumb.LABEL_PREFIX}HOME`, url: '/' },

    [Page.MyAccount]: { label: `${Breadcrumb.LABEL_PREFIX}MY_ACCOUNT`, url: MY_ACCOUNT_ROUTE },
    [Page.MyAccountDriverLicense]: {
      label: `${Breadcrumb.LABEL_PREFIX}MY_ACCOUNT_DRIVER_LICENSE`,
      url: MY_ACCOUNT_ROUTE + '/' + DRIVER_LICENSE_ROUTE
    },
    [Page.MyAccountVehicleRegistration]: {
      label: `${Breadcrumb.LABEL_PREFIX}MY_ACCOUNT_VEHICLE_REGISTRATION`,
      url: MY_ACCOUNT_ROUTE + '/' + VEHICLE_REGISTRATION_ROUTE
    },
    [Page.ExamsList]: {
      label: 'Egzamin na prawo jazdy',
      url: MY_ACCOUNT_ROUTE + '/' + EXAMS_ROUTE
    },
    [Page.Settings]: {
      label: `${Breadcrumb.LABEL_PREFIX}SETTINGS`,
      url: MY_ACCOUNT_ROUTE + '/' + SETTINGS_ROUTE
    },
    [Page.NotificationsPanel]: {
      label: 'Lista subskrypcji',
      url: MY_ACCOUNT_ROUTE + '/' + NOTIFICATIONS_PANEL_ROUTE
    },
    [Page.NotificationsPanelAddForm]: {
      label: 'Dodaj subksrybcję',
      url: MY_ACCOUNT_ROUTE + '/' + NOTIFICATION_FORM_ROUTE
    },
    [Page.DriversLicence]: {
      label: 'Prawo jazdy',
      url: DRIVER_LICENSE_ROUTE
    },
    [Page.ExamRegistration]: {
      label: 'Zapisz się na egzamin na prawo jazdy (online)',
      url: WORD_EXAM_ROUTE
    },
    [Page.SearchResults]: { label: `${Breadcrumb.LABEL_PREFIX}SEARCH_RESULTS`, url: SEARCH_ROUTE },

    [Page.Stc]: { label: `${Breadcrumb.LABEL_PREFIX}STC`, url: TACHOGRAPH_ROUTE },
    [Page.MyAccountTachograph]: {
      label: `${Breadcrumb.LABEL_PREFIX}STC`,
      url: `${MY_ACCOUNT_ROUTE}/${TACHOGRAPH_ROUTE}`
    },
    [Page.Messages]: { label: 'Wiadomości', url: MESSAGES_ROUTE },
    [Page.Statements]: { label: `${Breadcrumb.LABEL_PREFIX}STATEMENTS`, url: STATEMENTS_ROUTE },
    [Page.Employees]: {
      label: 'Pracownicy',
      url: MY_ACCOUNT_ROUTE + '/' + TACHOGRAPH_ROUTE + '/' + LIST_EMPLOYEES_ROUTE
    },
    [Page.ApplicationsSent]: {
      label: `${Breadcrumb.LABEL_PREFIX}APPLICATIONS_SENT`,
      url: LIST_APPLICATIONS_ROUTE
    },
    [Page.ApplicationsDrafts]: { label: `${Breadcrumb.LABEL_PREFIX}APPLICATIONS_DRAFTS`, url: '' },
    [Page.Partners]: {
      label: 'Uzyskaj kartę do tachografu w imieniu pracownika (usługa dla firm)',
      url: `${TACHOGRAPH_ROUTE}/${PARTNERS_ROUTE}`
    },
    [Page.Contact]: {
      label: `${Breadcrumb.LABEL_PREFIX}CONTACT`,
      url: CONTACTPAGE_ROUTE
    },
    [Page.SignaturesInfoPage]: {
      label: 'Jak podpisać wniosek',
      url: SIGNATURES_INFO_PAGE
    },
    [Page.SiteMapPage]: { label: `${Breadcrumb.LABEL_PREFIX}SITE_MAP`, url: SITE_MAP_PAGE },
    [Page.Login]: { label: `${Breadcrumb.LABEL_PREFIX}LOGIN`, url: LOGIN_ROUTE },
    [Page.ForgotPassword]: {
      label: `${Breadcrumb.LABEL_PREFIX}FORGOT_PASSWORD`,
      url: `${LOGIN_ROUTE}/${FORGOT_PASSWORD_ROUTE}`
    },
    [Page.ResetPassword]: {
      label: `${Breadcrumb.LABEL_PREFIX}RESET_PASSWORD`,
      url: `${LOGIN_ROUTE}/${RESET_PASSWORD_ROUTE}`
    },
    [Page.TachographContact]: {
      label: `${Breadcrumb.LABEL_PREFIX}CONTACT`,
      url: `${TACHOGRAPH_ROUTE}/${CONTACT_ROUTE}`
    },
    [Page.Registration]: {
      label: `${Breadcrumb.LABEL_PREFIX}REGISTRATION`,
      url: REGISTRATION_ROUTE
    },
    [Page.DriverLicence]: {
      label: 'Prawo jazdy',
      url: `${DRIVER_LICENSE_ROUTE}`
    },
    [Page.VehicleRegistration]: {
      label: 'Rejestracja pojazdu',
      url: `${VEHICLE_REGISTRATION_ROUTE}`
    },
    [Page.DriverLicensePayment]: {
      label: 'Zapłać za wydanie prawa jazdy',
      url: `${DRIVER_LICENSE_ROUTE}/${DRIVER_LICENSE_PAYMENT}`
    },

    [Page.Adr]: { label: `${Breadcrumb.LABEL_PREFIX}ADR`, url: `${ADR_ROUTE}` },
    [Page.AdrClasses]: {
      label: `${Breadcrumb.LABEL_PREFIX}ADR_CLASSES`,
      url: `${ADR_ROUTE}/${ADR_CLASSES_ROUTE}`
    },
    [Page.DefectiveCards]: {
      label: 'Powiadomienie dotyczące ważności karty',
      url: DEFECTIVE_CARD_INFO_ROUTE
    }
  };
}

<div class="ic-page" [formGroup]="formGroup" [attr.id]="htmlElementId ? htmlElementId : null">
  <div class="ic-page__form">
    <div class="ic-page__form-title" *ngIf="staticContent.companyTitle">
      {{ staticContent.companyTitle }}
    </div>
    <div class="row">
      <div class="col-12 fit" *ngIf="formValidation.formFields.name">
        <app-form-input
          [inputId]="(htmlElementId ? htmlElementId + '-' : '') + 'name'"
          formControlName="name"
          [isReportedForCorrection]="isFieldIncludedInCorrection('name')"
          [warnValue]="findPreviousValue('name')"
          type="text"
          [placeholder]="staticContent.name.placeholder"
          [label]="staticContent.name.label"
          [formGroup]="formGroup"
          (blured)="onBlur()"
          [isRequired]="isControlRequired('name')"
          [errorList]="[
            { label: staticContent?.name.error, type: ['required'] },
            { label: staticContent?.tooLongValueError, type: ['maxlength'] },
            { label: staticContent?.name.error2, type: ['pattern'] }
          ]">
        </app-form-input>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6" *ngIf="formValidation.formFields.nip">
        <app-form-input
          [inputId]="(htmlElementId ? htmlElementId + '-' : '') + 'nip'"
          formControlName="nip"
          type="text"
          [isReportedForCorrection]="isFieldIncludedInCorrection('nip')"
          [warnValue]="findPreviousValue('nip')"
          [placeholder]="staticContent.nip.placeholder"
          [label]="staticContent.nip.label"
          [formGroup]="formGroup"
          (blured)="onBlur()"
          [isRequired]="isControlRequired('nip')"
          [errorList]="[
            { label: staticContent?.nip.error, type: ['required'] },
            { label: staticContent?.tooLongValueError, type: ['maxlength'] },
            { label: staticContent?.nip.error2, type: ['pattern'] },
            { label: staticContent?.tooShortValueError, type: ['minlength'] }
          ]">
        </app-form-input>
      </div>
      <div class="col-12 col-md-6" *ngIf="formValidation.formFields.krs">
        <app-form-input
          [inputId]="(htmlElementId ? htmlElementId + '-' : '') + 'krs'"
          formControlName="krs"
          type="text"
          [isReportedForCorrection]="isFieldIncludedInCorrection('krs')"
          [warnValue]="findPreviousValue('krs')"
          [placeholder]="staticContent.krs.placeholder"
          [label]="staticContent.krs.label"
          [formGroup]="formGroup"
          (blured)="onBlur()"
          [isRequired]="isControlRequired('krs')"
          [errorList]="[
            { label: staticContent?.krs.error, type: ['required'] },
            { label: staticContent?.tooLongValueError, type: ['maxlength'] },
            { label: staticContent?.krs.error2, type: ['pattern'] }
          ]">
        </app-form-input>
      </div>
    </div>
    <div class="row">
      <div class="col-12 fit" *ngIf="formValidation.formFields.authorizationNumber">
        <app-form-input
          [inputId]="(htmlElementId ? htmlElementId + '-' : '') + 'authorizationNumber'"
          formControlName="authorizationNumber"
          type="text"
          [isReportedForCorrection]="isFieldIncludedInCorrection('authorizationNumber')"
          [warnValue]="findPreviousValue('authorizationNumber')"
          [placeholder]="staticContent.authorizationNumber.placeholder"
          [label]="staticContent.authorizationNumber.label"
          [formGroup]="formGroup"
          (blured)="onBlur()"
          [isRequired]="isControlRequired('authorizationNumber')"
          [errorList]="[
            { label: staticContent?.authorizationNumber.error, type: ['required'] },
            { label: staticContent?.tooLongValueError, type: ['maxlength'] },
            { label: staticContent?.authorizationNumber.error2, type: ['pattern'] }
          ]">
        </app-form-input>
      </div>
    </div>
    <div class="row">
      <div class="col-12 fit" *ngIf="formValidation.formFields.shortName">
        <app-form-input
          [inputId]="(htmlElementId ? htmlElementId + '-' : '') + 'shortName'"
          formControlName="shortName"
          type="text"
          [isReportedForCorrection]="isFieldIncludedInCorrection('shortName')"
          [warnValue]="findPreviousValue('shortName')"
          [placeholder]="staticContent.shortName.placeholder"
          [label]="staticContent.shortName.label"
          [formGroup]="formGroup"
          (blured)="onBlur()"
          [isRequired]="isControlRequired('shortName')"
          [errorList]="[
            { label: staticContent?.shortName.error, type: ['required'] },
            { label: staticContent?.tooLongValueError, type: ['maxlength'] },
            { label: staticContent?.shortName.error2, type: ['pattern'] }
          ]">
        </app-form-input>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6" *ngIf="formValidation.formFields.phoneNumber">
        <app-form-input
          [inputId]="(htmlElementId ? htmlElementId + '-' : '') + 'phoneNumber'"
          formControlName="phoneNumber"
          type="text"
          [isReportedForCorrection]="isFieldIncludedInCorrection('phoneNumber')"
          [warnValue]="findPreviousValue('phoneNumber')"
          [placeholder]="staticContent.phoneNumber.placeholder"
          [isRequired]="isControlRequired('phoneNumber')"
          [label]="staticContent.phoneNumber.label"
          [formGroup]="formGroup"
          (blured)="onBlur()"
          [errorList]="[
            { label: staticContent?.phoneNumber.error, type: ['required'] },
            { label: staticContent?.tooLongValueError, type: ['maxlength'] },
            { label: staticContent?.phoneNumber.error2, type: ['pattern'] },
            { label: staticContent?.tooShortValueError, type: ['minlength'] }
          ]">
        </app-form-input>
      </div>
      <div class="col-12 col-md-6" *ngIf="formValidation.formFields.email">
        <app-form-input
          [inputId]="(htmlElementId ? htmlElementId + '-' : '') + 'email'"
          formControlName="email"
          type="text"
          [isReportedForCorrection]="isFieldIncludedInCorrection('email')"
          [warnValue]="findPreviousValue('email')"
          [placeholder]="staticContent.email.placeholder"
          [isRequired]="isControlRequired('email')"
          [label]="staticContent.email.label"
          [formGroup]="formGroup"
          (blured)="onBlur()"
          [errorList]="[
            { label: staticContent?.email.error, type: ['required'] },
            { label: staticContent?.tooLongValueError, type: ['maxlength'] },
            { label: staticContent?.email.error2, type: ['pattern'] }
          ]">
        </app-form-input>
      </div>
    </div>
  </div>
</div>

<fieldset
  *ngIf="content"
  class="info-box"
  [ngClass]="{
    bordered: bordered,
    'max-width-limited': maxWidthLimited,
    'color-info': type === 'info',
    'color-warning': type === 'warning',
    'color-error': type === 'error',
    'color-success': type === 'success',
    monochrome: iconStyle === 'monochrome',
    blue: iconStyle === 'blue'
  }"
  [style.width.%]="width">
  <legend class="info-box__warning" *ngIf="bordered">
    {{ infoBoxTitle }}
  </legend>
  <p class="info-box__text" appInternalLink [innerHTML]="infoBoxText"></p>
</fieldset>

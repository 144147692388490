import { GhostButtonType } from '../../shared/components/ghost-button/ghost-button-type.enum';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ServiceCategory } from 'src/app/shared/models/service-category.model';
import { TranslateService } from '@ngx-translate/core';
import { TACHOGRAPH_ROUTE } from '../../service-category/_consts/service-category-route.const';

@Component({
  selector: 'app-categories-services',
  templateUrl: './categories-services.component.html',
  styleUrls: ['./categories-services.component.scss']
})
export class CategoriesServicesComponent implements OnInit, OnDestroy {
  categories: ServiceCategory[];
  GhostButtonType: typeof GhostButtonType = GhostButtonType;
  tachographLink = TACHOGRAPH_ROUTE;
  subscription = new Subscription();
  constructor(private route: ActivatedRoute, private translate: TranslateService) {}

  ngOnInit() {
    this.categories = this.translate.instant('SERVICE_CATEGORY.SERVICES_INFO_BOX');
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}

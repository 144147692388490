import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { clamp } from './clamp';
import { TextEllipsisService } from './text-ellipsis.service';
import { Subscriptions } from '../../rx-utils/subscriptions';
import { fromEvent, timer } from 'rxjs';
import { debounce, tap } from 'rxjs/operators';

@Directive({
  selector: '[appTextEllipsis]'
})
export class TextEllipsisDirective implements OnInit, OnDestroy {
  @Input() lines = 1;

  private subs: Subscriptions = new Subscriptions();

  constructor(private elementRef: ElementRef, private textEllipsisService: TextEllipsisService) {}

  ngOnInit(): void {
    this.subs.next = this.textEllipsisService.isEnabled$.subscribe({
      next: (isEnabled) => this.enableEllipsis(isEnabled)
    });

    this.subs.next = fromEvent(window, 'resize')
      .pipe(
        debounce(() => timer(100)),
        tap(() => this.textEllipsisService.setEnabled(true))
      )
      .subscribe({
        next: () => this.clamp()
      });
  }

  private enableEllipsis(isEnabled: boolean) {
    if (isEnabled) this.clamp();
    else this.elementRef.nativeElement.removeAttribute('style');
  }

  private clamp() {
    const { isClamped } = clamp(this.elementRef.nativeElement, {
      clamp: this.lines
    });
    setTimeout(() => this.textEllipsisService.setClamped(isClamped));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}

<div class="text-info-modal">
  <div class="header">
    <h2 class="header__title">
      {{ content ? content.title : termsContent.title }}
    </h2>
    <!-- TODO add text to json -->
    <img
      alt="Ikona - zamknij okno"
      role="button"
      class="header__close-btn"
      tabindex="0"
      (keydown.enter)="onCancelClick()"
      (click)="onCancelClick()"
      src="assets/images/Group 3293.svg" />
  </div>

  <div class="content">
    <div class="content__text terms">
      <ng-container *ngIf="termsContent">
        <header>
          <p class="terms-article__header-subtitle">
            {{ termsContent.subtitle }}
          </p>
        </header>

        <section class="terms-article__chapter" *ngFor="let chapter of termsContent.chapters">
          <h2 class="terms-article__chapter-title">
            {{ chapter.chapter_header }} <br /><span>{{ chapter.chapter_name }}</span>
          </h2>
          <div *ngFor="let paragraph of chapter.paragraphs">
            <h3 class="terms-article__par-title">{{ paragraph.name }}</h3>
            <p
              class="terms-article__par-text"
              *ngIf="paragraph.top_text"
              [innerHTML]="paragraph.top_text"></p>
            <ul class="terms-article__par-first-list" *ngIf="paragraph.list_items">
              <li *ngFor="let item of paragraph.list_items">
                <span [innerHTML]="item.text"></span>
                <ul class="terms-article__par-second-list" *ngIf="item.sub_items">
                  <li *ngFor="let subitem of item.sub_items">
                    <span [innerHTML]="subitem.text"></span>
                    <ul class="terms-article__par-third-list" *ngIf="subitem.sub_items">
                      <li *ngFor="let sub_subitem of subitem.sub_items">
                        <span [innerHTML]="sub_subitem"></span>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </section>
      </ng-container>

      <ng-container class="info" *ngIf="content">
        <ng-container *ngIf="content.content.subtitle">
          <h4 class="info__header">{{ content.content.subtitle }}</h4>
        </ng-container>

        <ng-container *ngFor="let info of content.content.info">
          <p class="info__title" *ngIf="info.title">{{ info.title }}</p>
          <ng-container *ngFor="let text of info?.text">
            <p class="info__text">{{ text }}</p>
          </ng-container>

          <ng-container *ngIf="info.unorderList">
            <ul class="info-unordered-list" *ngFor="let list of info.unorderList">
              <li class="info-unordered-list__item" *ngFor="let item of list.text">
                {{ item }}
              </li>
            </ul>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>

    <div class="info-button">
      <ic-ghost-button
        [type]="GhostButtonType.BIG_FIRST_RANK_FLEX"
        [justifyContent]="'center'"
        [showArrow]="false"
        [width]="136"
        (click)="onCancelClick()">
        <span class="btn-label">{{ pageText.closeBtn }}</span>
      </ic-ghost-button>
    </div>
  </div>
</div>

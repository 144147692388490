import { Action } from '@ngrx/store';
import { Slider } from '../slider/models/slider.model';
import { CategorySectionTitleModel } from '../categories-services/_model/category-section-titles.model';

export interface MainPageActions {
  SET_SLIDER: string;
  SET_CATEGORY_SECTION: string;
}

export const ActionTypes: MainPageActions = {
  SET_SLIDER: '[Slider] Set',
  SET_CATEGORY_SECTION: '[CategorySection] Set'
};

export class SetSliderAction implements Action {
  type = ActionTypes.SET_SLIDER;
  constructor(public payload: Slider) {}
}

export class SetCategorySectionAction implements Action {
  type = ActionTypes.SET_CATEGORY_SECTION;
  constructor(public payload: CategorySectionTitleModel) {}
}

export type Actions = SetSliderAction | SetCategorySectionAction;

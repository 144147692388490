<div
  [ngClass]="{
    overlay: true,
    'overlay--blue': blueOverlay,
    'overlay--white': whiteOverlay,
    'max-height': maxHeight
  }"
  (mousedown)="onOverlayClicked($event)">
  <div class="dialog" tabindex="0" #modal (mousedown)="onModalClicked($event)">
    <ng-template appInsertion> </ng-template>
  </div>
</div>

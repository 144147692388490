<div class="footer-products container" *ngIf="content">
  <div class="main-title">
    {{ 'FOOTER.bottom.otherProducts' | translate }}
  </div>
  <div class="footer-products-content">
    <a
      title="{{ 'LINK_TITLE.OPEN_IN_NEW_TAB' | translate }}"
      target="_blank"
      [href]="product.href"
      *ngFor="let product of otherProducts">
      <ic-ghost-button [type]="GhostButtonType.FOOTER_OTHER_PRODUCTS">
        {{ product.text }}
      </ic-ghost-button>
    </a>
  </div>
  <div class="footer-pwpw">
    <div class="pwpw-logo">
      <a
        title="{{ 'LINK_TITLE.OPEN_IN_NEW_TAB' | translate }}"
        target="_blank"
        href="https://www.pwpw.pl/"
        ><img alt="{{ 'SHARED.FOOTER.pwpwLogo' | translate }}" src="assets/images/logo/pwpw.svg"
      /></a>
    </div>
    <span>{{ 'FOOTER.bottom.pwpw' | translate }}</span>
  </div>
</div>

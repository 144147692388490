import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { CompanyCardApplicationDto } from 'src/app/shared/models/company-card-application/company-card-application.model';
import { CompanyCardNumberFormBinder } from './company-card-number.form-binder';
import {
  CompanyCardNumberDto,
  CompanyCardNumberFormValidation,
  CompanyCardNumberStaticContent
} from './company-card-number.model';
import { defaultCompanyCardNumberValidation } from './company-card-number.validation';
import { CorrectionsErrors, FieldErrorEntry } from '../../../models/corrections/corrections.model';

@Component({
  selector: 'app-company-card-number',
  templateUrl: './company-card-number.component.html',
  styleUrls: ['./company-card-number.component.scss'],
  providers: [CompanyCardNumberFormBinder]
})
export class CompanyCardNumberComponent implements OnInit, OnDestroy {
  @Input() staticContent: CompanyCardNumberStaticContent;
  @Input() formValidation: CompanyCardNumberFormValidation = defaultCompanyCardNumberValidation;
  @Input() aggregateFormGroup: UntypedFormGroup;
  @Input() fetchedData: CompanyCardNumberDto;
  @Input() reason: CompanyCardApplicationDto.ReasonTypeEnum;
  @Input() htmlElementId: string;
  @Output() saveDraft: EventEmitter<any> = new EventEmitter();

  @Input() set correctionsErrors(data: CorrectionsErrors) {
    this._correctionsErrors = data;
    this.getFieldsErrors(data);
  }
  private _correctionsErrors: CorrectionsErrors;
  formGroup: UntypedFormGroup;
  formPrefix = 'company';
  fieldsErrors: FieldErrorEntry[] = [];

  public constructor(private binder: CompanyCardNumberFormBinder) {}

  public ngOnInit() {
    if (this.reason && this.reason !== CompanyCardApplicationDto.ReasonTypeEnum.FIRSTISSUE) {
      this.formValidation = this.binder.addRequiredValidatorToCompanyCardNumber(
        this.formValidation
      );
    }
    this.formGroup = this.binder.bindForm(this.formValidation);
    this.aggregateFormGroup.addControl(this.formValidation.formGroupName, this.formGroup);
    this.binder.fillFormWith(this.fetchedData);
  }

  public onBlur(): void {
    this.saveDraft.emit();
  }

  public isControlRequired(controlName: string): boolean {
    return this.binder.isControlRequired(controlName);
  }

  sectionErrorsExist(): boolean {
    return this._correctionsErrors?.sectionErrors.some((item) => item.entry === this.formPrefix);
  }

  getFieldsErrors(correctionsErrors: CorrectionsErrors) {
    this.fieldsErrors =
      correctionsErrors?.fieldErrors
        .filter(
          (item) =>
            item.entry.startsWith(this.formPrefix) || item.entry.startsWith('currentCompanyCard')
        )
        .map((entry) => {
          return {
            entry: entry.entry,
            previousValue: entry.previousValue
          };
        }) || [];
  }

  isFieldIncludedInCorrection(fieldName: string): boolean {
    return (
      this.fieldsErrors.some((err) => err.entry.endsWith(fieldName)) || this.sectionErrorsExist()
    );
  }

  findPreviousValue(fieldName: string): any {
    return this.fieldsErrors.find((err) => err.entry.endsWith(fieldName))?.previousValue;
  }

  ngOnDestroy(): void {
    if (this.aggregateFormGroup.contains(this.formValidation.formGroupName)) {
      this.aggregateFormGroup.removeControl(this.formValidation.formGroupName);
    }
  }
}

import { NavigationEnd, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MetaService {
  link: HTMLLinkElement;

  constructor(@Inject(DOCUMENT) private dom, private router: Router) {}

  createCanonicalURL(url?: string) {
    if (this.link) {
      this.link.remove();
    }
    const canURL = url === undefined ? this.dom.URL.split('?')[0] : url;
    this.link = this.dom.createElement('link');
    this.link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(this.link);
    this.link.setAttribute('href', canURL);

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.link.remove();
    });
  }
}

import {Pipe, PipeTransform} from '@angular/core';
import {formatCurrency, getCurrencySymbol} from '@angular/common';
import {AppLanguage} from '../../../app-language.enum';

@Pipe({
  name: 'plnCurrency'
})
export class PlncurrencyPipe implements PipeTransform {

  private currencyCode = 'PLN';
  transform(value: number, locale:string = localStorage.getItem('lang')): string {
    return formatCurrency(
      value,
      PlncurrencyPipe.mapLangToLocale(locale),
      getCurrencySymbol(this.currencyCode, 'wide', PlncurrencyPipe.mapLangToLocale(locale)),
      this.currencyCode,
      undefined
    );
  }

  private static mapLangToLocale(locale: string) {

    switch (locale) {
      case AppLanguage.UKRAINIAN:
        // todo find why angular hate ukrainian locale return 'uk-UA';
      default:
        return 'pl-PL'
    }
  }
}

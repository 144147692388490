import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class LanguageHeaderInterceptor implements HttpInterceptor {
  constructor(private translate: TranslateService) {}

  private getLng(): string {
    switch (this.translate.currentLang) {
      case 'ua':
        return 'uk-UA';
      // NOTE add case to comunicate with backend in other lng
      default:
        return 'pl-PL';
    }
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        'Accept-Language': this.getLng()
      }
    });
    return next.handle(request);
  }
}

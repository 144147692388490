import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { filter, take } from 'rxjs/operators';
import { AuthService } from 'src/app/core/auth/auth.service';
import { User } from 'src/app/core/model/user.model';
import { ABOUT_US } from 'src/app/info-pages/_consts/info-pages-route.const';
import { ServiceCategory } from 'src/app/shared/models/service-category.model';
import { AppLanguage } from '../../../app-language.enum';

@Component({
  selector: 'app-header-categories',
  templateUrl: './header-categories.component.html',
  styleUrls: ['./header-categories.component.scss']
})
export class HeaderCategoriesComponent implements OnInit {
  public activeServiceCategory: ServiceCategory;
  @Input() currentLng: string;

  @Input()
  services: ServiceCategory[];

  @Output()
  selectOption = new EventEmitter<any>();
  appLanguage = AppLanguage;
  @Output() changeLng = new EventEmitter();
  aboutLink = ABOUT_US;
  user: User = null;
  constructor(private auth: AuthService, private router: Router) {}

  ngOnInit() {
    this.auth.user$
      .pipe(
        filter((result) => result != null),
        take(1)
      )
      .subscribe((userResult) => (this.user = userResult));
  }

  onLogout() {
    this.auth.logout();
    this.selectOption.emit();
    this.router.navigate(['/']);
  }

  setActiveServiceCategory(service) {
    this.activeServiceCategory = service;
    this.selectOption.emit();
  }

  onClick(lang: string) {
    this.changeLng.emit(lang);
  }
}

import { query } from '@angular/animations';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CountedApiResponseModel } from 'src/app/shared/models/search/counted-api-response.model';
import { Service } from 'src/app/shared/models/search/service.model';

import { SearchAction, SearchActionTypes } from './search.action';

export interface SearchCacheState {
  query: string;
  result: CountedApiResponseModel<Service>;
}

const initialState: SearchCacheState = {
  query: '',
  result: CountedApiResponseModel.empty<Service>()
};

export function searchCacheReducer(
  state: SearchCacheState = initialState,
  action: SearchAction
): SearchCacheState {
  switch (action.type) {
    case SearchActionTypes.SET_RESULTS:
      return Object.assign({}, state, {
        query: state.query,
        result: action.result
      });
    case SearchActionTypes.LOADING:
      return Object.assign({}, state, {
        query: action.query,
        result: CountedApiResponseModel.empty<Service>()
      });
    default:
      return state;
  }
}

export const featureName = 'searchServices';

export const selectSearchCacheState = createFeatureSelector<SearchCacheState>(featureName);

export const selectSearchQuery = createSelector(
  selectSearchCacheState,
  (state: SearchCacheState) => state.query
);

export const selectSearchResult = createSelector(
  selectSearchCacheState,
  (state: SearchCacheState) => state.result
);

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RouteStateService {
  private pathParamState = new BehaviorSubject<string>(null);
  pathParam: Observable<string>;

  constructor() {
    this.pathParam = this.pathParamState.asObservable();
  }

  updatePathParamState(newPathParam: string) {
    this.pathParamState.next(newPathParam);
  }
}

<div class="ic-page" [attr.id]="htmlElementId ? htmlElementId : null">
  <form class="ic-page__form" [formGroup]="formGroup">
    <section class="business-form-of-refund" *ngIf="businessAccount">
      <h3 [ngClass]="{
          'ic-page__form-title': true,
          error: sectionErrorsExist()
        }">
        {{ staticContent?.businessRefundSectionTitle }}
      </h3>
      <div class="row">
        <div class="col-12 col-md-6 businessBankAccountNumber">
          <app-form-input inputId="bankAccountNumber" formControlName="bankAccountNumber"
            [isReportedForCorrection]="isFieldIncludedInCorrection('bankAccountNumber')"
            [warnValue]="findPreviousValue('bankAccountNumber')" [label]="staticContent?.bankAccountNumber.label"
            [isRequired]="true" (blured)="onBlur()" [placeholder]="staticContent?.bankAccountNumber.placeholder"
            mask="accountNumber" [formGroup]="formGroup" [errorList]="[
              {
                label: staticContent?.bankAccountNumber.error,
                type: ['required']
              },
              {
                label: staticContent?.bankAccountNumber.error2,
                type: ['pattern']
              }
            ]">
          </app-form-input>
        </div>
      </div>
    </section>
    <section class="form-of-refund" *ngIf="!businessAccount">
      <h3 class="ic-page__form-title less-padding">
        {{ staticContent?.refundSectionTitle }}
      </h3>
      <div class="row">
        <div class="col-12">
          <label tabindex="0" appTriggerClick [ngClass]="{
              highlight: formGroup.controls['refundType'].value === 'BANK_TRANSFER',
              option: formGroup.controls['refundType'].value !== 'BANK_TRANSFER'
            }">
            <fieldset>
              <label for="refundType" class="ic-radio-btn">{{ staticContent?.bankAccount }}
                <input type="radio" name="refundType" value="BANK_TRANSFER" formControlName="refundType"
                  (change)="onBlur()" />
                <span class="checkmark"></span>
              </label>
              <div class="bankAccountNumber">
                <app-form-input inputId="bankAccountNumber" formControlName="bankAccountNumber"
                  [isReportedForCorrection]="isFieldIncludedInCorrection('bankAccountNumber')"
                  [warnValue]="findPreviousValue('bankAccountNumber')" [label]="staticContent?.bankAccountNumber.label"
                  [isRequired]="true" (blured)="onBlur()" [placeholder]="staticContent?.bankAccountNumber.placeholder"
                  mask="accountNumber" [formGroup]="formGroup" [errorList]="[
                  {
                    label: staticContent?.bankAccountNumber.error,
                    type: ['required']
                  },
                  {
                    label: staticContent?.bankAccountNumber.error2,
                    type: ['pattern']
                  }
                ]">
                </app-form-input>
              </div>
            </fieldset>
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label tabindex="0" appTriggerClick [ngClass]="{
              highlight: formGroup.controls['refundType'].value === 'MONEY_ORDER_RESIDENCE_ADDRESS',
              option: formGroup.controls['refundType'].value !== 'MONEY_ORDER_RESIDENCE_ADDRESS',
            }">
            <label for="refundType" class="ic-radio-btn">{{
              selectedCardType === cardTypes.WORKSHOP_CARD
              ? staticContent?.postToCompanyAddress
              : staticContent?.postToResidenceAddress
              }}
              <input id="money-order-residence-address" type="radio" name="refundType" formControlName="refundType"
                value="MONEY_ORDER_RESIDENCE_ADDRESS" (change)="onBlur()" />
              <span class="checkmark"></span>
            </label>
          </label>
        </div>
      </div>
      <div class="row" *ngIf="
          selectedCardType !== cardTypes.CONTROL_CARD &&
          selectedCardType !== cardTypes.WORKSHOP_CARD
        ">
        <div class="col-12">
          <label tabindex="0" appTriggerClick [ngClass]="{
              highlight: formGroup.controls['refundType'].value === 'MONEY_ORDER_MAILING_ADDRESS',
              option: formGroup.controls['refundType'].value !== 'MONEY_ORDER_MAILING_ADDRESS',
              disabled: !hasMailingAddress
            }">
            <label for="refundType" class="ic-radio-btn">{{ staticContent?.postToMailingAddress }}
              <input id="money-order-mailing-address" [disabled]="" type="radio" name="refundType" formControlName="refundType"
                value="MONEY_ORDER_MAILING_ADDRESS" (change)="onBlur()" />
              <span class="checkmark"></span>
            </label>
          </label>
        </div>
      </div>
    </section>
  </form>
</div>
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

const SLIDER_ENDPOINT = '/slider';

// note unused
@Injectable({
  providedIn: 'root'
})
export class SliderService {
  constructor(private _http: HttpClient) {}
}

import { DESTINATION_DICTIONARY } from '../dictionary/route-dictionary.enum';
import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'destination'
})
export class DestinationPipe implements PipeTransform {
  transform(value, args?: any): string {
    if (value.destination == null) {
      return '/';
    }
    if (!DESTINATION_DICTIONARY.has(value.destination.type)) {
      return '/';
    }
    return DESTINATION_DICTIONARY.get(value.destination.type);
  }
}

/**
 * INFOCAR STC API
 * API for processing STC applications
 *
 * OpenAPI spec version: v1
 * Contact: java@pulab.pl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { GuardStatusDto } from 'src/app/shared/models/status/guard-status.model';

/**
 * DTO describing application status fields
 */
export interface StatusDto extends GuardStatusDto {
  /**
   * Status date
   */
  date: string;
  /**
   * Status description
   */
  description: string;
  /**
   * Status id
   */
  id: string;
  /**
   * Status notes
   */
  notes: string;
  /**
   * Status value
   */
  status: StatusDto.StatusEnum;
  /**
   * Draft status
   */
  draft: boolean;
}
export namespace StatusDto {
  export type StatusEnum =
    | 'NOT_FOUND'
    | 'DRAFT_CREATED'
    | 'DRAFT_NON_CARD_SHIPPING_ADDRESS'
    | 'DRAFT_NON_ATTACHMENTS'
    | 'DRAFT_UNCONFIRMED'
    | 'DRAFT_UNSIGNED'
    | 'DRAFT_UNPAID'
    | 'PAYMENT_STARTED'
    | 'PAYMENT_ACCEPTED'
    | 'PAYMENT_REJECTED'
    | 'PAYMENT_TIMEOUT'
    | 'INCOMPLETE'
    | 'PRODUCTION'
    | 'SENT'
    | 'VERIFICATION'
    | 'REJECTED'
    | 'MANUAL_PAYMENT';
  export const StatusEnum = {
    NOTFOUND: 'NOT_FOUND' as StatusEnum,
    DRAFTCREATED: 'DRAFT_CREATED' as StatusEnum,
    DRAFTNONCARDSHIPPINGADDRESS: 'DRAFT_NON_CARD_SHIPPING_ADDRESS' as StatusEnum,
    DRAFTNONATTACHMENTS: 'DRAFT_NON_ATTACHMENTS' as StatusEnum,
    DRAFTUNCONFIRMED: 'DRAFT_UNCONFIRMED' as StatusEnum,
    DRAFTUNSIGNED: 'DRAFT_UNSIGNED' as StatusEnum,
    DRAFTUNPAID: 'DRAFT_UNPAID' as StatusEnum,
    PAYMENTSTARTED: 'PAYMENT_STARTED' as StatusEnum,
    PAYMENTACCEPTED: 'PAYMENT_ACCEPTED' as StatusEnum,
    PAYMENTREJECTED: 'PAYMENT_REJECTED' as StatusEnum,
    PAYMENTTIMEOUT: 'PAYMENT_TIMEOUT' as StatusEnum,
    INCOMPLETE: 'INCOMPLETE' as StatusEnum,
    PRODUCTION: 'PRODUCTION' as StatusEnum,
    SENT: 'SENT' as StatusEnum,
    VERIFICATION: 'VERIFICATION' as StatusEnum,
    REJECTED: 'REJECTED' as StatusEnum,
    MANUALPAYMENT: 'MANUAL_PAYMENT' as StatusEnum
  };
  export const errorStatus = [
    StatusEnum.NOTFOUND,
    StatusEnum.DRAFTUNPAID,
    StatusEnum.INCOMPLETE,
    StatusEnum.PAYMENTREJECTED,
    StatusEnum.PAYMENTTIMEOUT,
    StatusEnum.REJECTED
  ];

  export const successStatus = [StatusEnum.SENT];
}

import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class BackButtonService {
  private LOGIN_REFERRER_SEGMENT = 'test.info-car.pl/oauth2/login';
  constructor(private router: Router, private location: Location) {}

  public tryGoToPrevAppPage(formPrevPage: string, formOriginPage: string) {
    if (formPrevPage !== '') {
      this.router.navigateByUrl(formPrevPage);
    }
    if (document.referrer && document.referrer.includes(this.LOGIN_REFERRER_SEGMENT)) {
      this.router.navigateByUrl(formOriginPage);
    } else {
      this.location.back();
    }
  }
}

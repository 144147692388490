import { Input, Directive } from '@angular/core';

@Directive()
export abstract class SelectableItemDereferencerDirective<
  ItemType,
  Key extends keyof ItemType,
  OutputKey extends keyof ItemType
> {
  @Input('key') itemsKeyPropertyName: Key;
  @Input('outputKey') itemsOutputPropertyName: OutputKey;
  @Input('additionalFilterKeys') itemsKeyAdditionalFilterPropertyNames: Key[];

  constructor() {
    this.itemsOutputPropertyName = this.itemsKeyPropertyName as any as OutputKey;
  }

  protected getOutputOf(item: ItemType): string {
    if (item == null) {
      return null;
    }
    const output = item[this.itemsOutputPropertyName];
    if (output == null) {
      return this.getKeyOf(item);
    }
    return String(output);
  }

  protected getKeyOf(item: ItemType): string {
    if (item == null) {
      return null;
    }
    return String(item[this.itemsKeyPropertyName]);
  }

  protected getAdditionalKeysOf(item: ItemType): string[] {
    if (item == null || this.itemsKeyAdditionalFilterPropertyNames == null) {
      return null;
    }
    const result = [];
    this.itemsKeyAdditionalFilterPropertyNames.forEach((key) => {
      result.push(String(item[key]));
    });
    return result;
  }
}

import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { filter, skip } from 'rxjs/operators';

@Injectable()
export class SelectableInputService {
  private _openedStatus: BehaviorSubject<boolean> = new BehaviorSubject(false);

  get openedChanges(): Observable<boolean> {
    return this._openedStatus.asObservable();
  }

  get opened(): boolean {
    return this._openedStatus.value;
  }

  get opened$(): Observable<boolean> {
    return this._openedStatus.asObservable().pipe(filter((result) => result === true));
  }

  get closed$(): Observable<boolean> {
    return this._openedStatus.asObservable().pipe(
      filter((result) => result === false),
      skip(1)
    );
  }

  open() {
    this._openedStatus.next(true);
  }

  close() {
    this._openedStatus.next(false);
  }
}

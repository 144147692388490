export const ValidationPatterns = {
  requiredCapitalCase: /[A-Z]/,
  requiredOnlyDigit: /^[0-9]*$/,
  requiredLowerCase: /[a-z]/,
  requiredAlphanumeric: /[a-zA-Z0-9]/,
  requiredSpecialCharacter: /[\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
  requiredDigit: /[0-9]/,
  phoneNumber: /^(\(?\+?[0-9]{1,4}\)?)?( ?-?[0-9]{1,3})+$/,
  serialNumber: /^[0-9]{5}[\/][0-9]{2}[\/][0-9]{4,7}$/,
  // tslint:disable-next-line
  // NOTE: for future mail generations email: /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/,
  email: /^([A-Za-z0-9\+\-\_\.]+)@([A-Za-z0-9\+\-\_]+)\.+([A-Za-z0-9\+\-\_\.]{2,})$/,
  date: /^((0)[1-9]|[1-2][0-9]|(3)[0-1])(.)(((0)[1-9])|((1)[0-2]))(.)\d{4}$/,
  pesel: /^(\d{2})(0\d|2[1-9]|(1|3)[0-2])([0-2]\d|3[0-1])(\d{5})$/,
  characterSensitivePattern: /([^A-Za-z0-9 .\-,"'])/g,
  bankAccountNumber:
    /^(?:[0-9]{2}\s?[0-9]{4}\s?[0-9]{4}\s?[0-9]{4}\s?[0-9]{4}\s?[0-9]{4}\s?[0-9]{4})$/,
  polishPostCode: /^[0-9]{2}-[0-9]{3}$/,
  notPolishPostCode: /^[a-zA-Z0-9 /-]+$/,
  driverLicenseNo: /^\d{5}\/\d{2}\/\d{4}(\d{3})?$/,
  pkkValidation: /^(?:[0-9]{18}(1[1-4]|2[1-3]|3[1-4]|4[1-4]|51|61){1})$/,
  pkzValidation: /^(?:[0-9]{18}1(1[1-4]|2[1-4]){1})$/,
  notCyrillicPattern: /^[\u{00}-\u{FF}\u{0100}-\u{02FF}\u{0300}-\u{036F}\u{1E00}-\u{1EFF}\u{0100}-\u{02FF}\u{1E00}-\u{1EFF}\u{20A0}-\u{20CF}\u{0160}-\u{016F}\u{017D}-\u{017E}\u{011E}-\u{011F}\u{0130}-\u{0131}\u{015E}-\u{015F}\u{0160}-\u{0161}\u{017D}-\u{017E}]*$/u,
  cardNumber: /^(?=.*\d.*\d)[0-9A-Za-z-]{16}$/
};

import { Component, OnInit } from '@angular/core';
import { BaseModalDirective } from '../base-modal';
import { ReservationConfirmationService } from 'src/app/applications/word-exam-registration/registration-process/summary-step/_service/reservation-confirmation.service';

@Component({
  selector: 'app-word-summary-loader-modal',
  templateUrl: './word-summary-loader-modal.component.html',
  styleUrls: ['./word-summary-loader-modal.component.scss']
})
export class WordSummaryLoaderModalComponent extends BaseModalDirective implements OnInit {
  constructor(private reservationConfirmationService: ReservationConfirmationService) {
    super();
  }

  ngOnInit() {
    this.waitToClose();
  }

  private waitToClose() {
    this.reservationConfirmationService.waitingForConfirmation.subscribe((result) => {
      if (!result) {
        this.reservationConfirmationService.nextWaitingForConfirmation(true);
        this.close();
      }
    });
  }
}

import { News } from '../_models/news';
import * as news from './news-action';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PageOfNews } from '../_models/page-of-news';

export interface NewsState {
  news: PageOfNews[];
  promoNews: News[];
  total: number;
  tag?: string;
  loading: boolean;
  error: boolean;
}
const initialNewsState: NewsState = {
  news: [],
  promoNews: [],
  loading: false,
  total: 0,
  error: false
};
export function NewsReducer(state: NewsState = initialNewsState, action: news.Actions): NewsState {
  let pageOfNews: PageOfNews;
  switch (action.type) {
    case news.NewsActionTypes.GET_NEWS:
      return {
        ...state,
        loading: true
      };
    case news.NewsActionTypes.GET_NEWS_SUCCESS:
      pageOfNews = {
        news: action.payload['news'],
        pageNumber: action.payload['pageNumber']
      };
      return {
        ...state,
        news: [...state.news, pageOfNews],
        total: action.payload['total'],
        tag: null,
        loading: false
      };
    case news.NewsActionTypes.GET_NEWS_BY_TAG:
      return {
        ...state,
        loading: true
      };
    case news.NewsActionTypes.GET_NEWS_BY_TAG_SUCCESS:
      pageOfNews = {
        news: action.payload['news'],
        pageNumber: action.payload['pageNumber']
      };
      return {
        ...state,
        news: [...state.news, pageOfNews],
        total: action.payload['total'],
        tag: action.payload['tag'],
        loading: false
      };
    case news.NewsActionTypes.CLEAR_NEWS:
      return {
        ...state,
        news: []
      };
    case news.NewsActionTypes.GET_SLIDER_NEWS:
      return {
        ...state,
        loading: true
      };
    case news.NewsActionTypes.GET_SLIDER_NEWS_SUCCESS:
      return {
        ...state,
        promoNews: action.payload['news'],
        loading: false
      };
    default:
      return state;
  }
}
export const featureName = 'news';
export const selectNewsState = createFeatureSelector<NewsState>(featureName);

<div class="ic-page" [formGroup]="formGroup" [attr.id]="htmlElementId ? htmlElementId : null">
  <div class="ic-page__form">
    <h3 class="ic-page__form-title">
      {{ staticContent.controlCardTitle }}
    </h3>
    <div class="row">
      <div class="col-12 col-md-6">
        <app-form-input
          inputId="controlCardNumber"
          formControlName="controlCardNumber"
          [label]="staticContent?.controlCardNumber.label"
          (blured)="onBlur()"
          maxLength="16"
          [placeholder]="staticContent?.controlCardNumber.placeholder"
          [formGroup]="formGroup"
          [isRequired]="isControlRequired('controlCardNumber')"
          [isReportedForCorrection]="isFieldIncludedInCorrection('controlCardNumber')"
          [warnValue]="findPreviousValue('controlCardNumber')"
          [errorList]="[
            {
              label: staticContent?.controlCardNumber.error,
              type: ['minlength', 'maxlength']
            },
            {
              label: staticContent?.notAlphanumericCharactersError,
              type: ['pattern']
            },
            {
              label: staticContent?.controlCardNumber.error2,
              type: ['required']
            }
          ]">
        </app-form-input>
        <!-- <span class="ic-page__form__hint">Wpisz numer obecnej karty kontrolnej jeśli już
          ją posiadasz.</span> -->
      </div>
    </div>
  </div>
</div>

import { TranslateService } from '@ngx-translate/core';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { KeyEventListenerService } from '../services/key-listener.service';
import { SelectableInputService } from '../services/selectable-input.service';
import { KeyId } from './../model/key-listener';
import { SelectableItemDereferencerDirective } from '../selectable-item-dereferencer';

@Component({
  selector: 'si-internal-result-list',
  templateUrl: './result-list.component.html',
  styleUrls: ['./result-list.component.scss'],
  providers: [KeyEventListenerService]
})
export class ResultListComponent<
    ItemType,
    Key extends keyof ItemType,
    OutputKey extends keyof ItemType
  >
  extends SelectableItemDereferencerDirective<ItemType, Key, OutputKey>
  implements OnInit, OnDestroy {
  @ViewChild('popup', { static: true }) popupElement: ElementRef;
  @ViewChildren('resultElements') resultElements: QueryList<ElementRef>;
  @Input() items: ItemType[];
  @Input() noFilteredItemsMessage: string;
  @Input() noActiveItemsMessage: string;
  @Output() selectionChange: EventEmitter<ItemType> = new EventEmitter();

  filteredResults: ItemType[];
  openedChanges: Observable<boolean>;
  resultsVisible: boolean;
  currentlySelectedItem: ItemType;
  currentNoMatchMessage: string;
  private _inputValue: string;
  private componentLifetimeSubscriptions = new Subscription();

  constructor(
    private renderer: Renderer2,
    private keyListener: KeyEventListenerService,
    private selectableInput: SelectableInputService,
    private translate: TranslateService
  ) {
    super();
    this.openedChanges = selectableInput.openedChanges;
  }

  ngOnInit() {
    this.setupKeyEventCallbacks();
    this.setupOpenedBehavior();
    this.setupClosedBehavior();
    if (!this.noFilteredItemsMessage) {
      this.noFilteredItemsMessage = this.translate.instant(
        'SHARED.SELECTABLE_INPUT_TEXTS.noMatchMessage'
      );
    }
  }

  @Input()
  set inputValue(value: string) {
    this._inputValue = value;
    this.filterByQuery();
  }

  get inputValue() {
    return this._inputValue;
  }

  private filterByQuery() {
    this.clearSelection();
    if (this.inputValue == null) {
      this.filteredResults = this.items;
      if (this.noActiveItemsMessage) {
        this.currentNoMatchMessage = this.noActiveItemsMessage;
      }
    } else {
      this.filteredResults = this.items.filter((item) => {
        const prop = this.getKeyOf(item);
        const additionalProps = this.getAdditionalKeysOf(item);
        this.currentNoMatchMessage = this.noFilteredItemsMessage;
        if (prop == null && additionalProps == null) {
          return false;
        }
        return (
          ('' + prop).toLowerCase().includes(this.inputValue.toString().toLowerCase()) ||
          (additionalProps &&
            additionalProps.find((additionalProp) =>
              ('' + additionalProp).toLowerCase().includes(this.inputValue.toString().toLowerCase())
            ))
        );
      });
      this.selectFirst();
    }
    this.updateResultsVisibility();
  }

  private setupKeyEventCallbacks() {
    this.keyListener.callbacks = [
      { keys: [KeyId.ARROW_DOWN], callback: () => this.selectNext() },
      { keys: [KeyId.ARROW_UP], callback: () => this.selectPrev() },
      {
        keys: [KeyId.ENTER, KeyId.TAB],
        callback: () => this.publishSelection()
      }
    ];
  }

  private setupOpenedBehavior() {
    const sub = this.selectableInput.opened$
      .pipe(tap(() => this.filterByQuery()))
      .subscribe(() => this.keyListener.subscribe());

    this.componentLifetimeSubscriptions.add(sub);
  }

  private setupClosedBehavior() {
    const sub = this.selectableInput.closed$.subscribe(() => this.keyListener.unsubscribe());

    this.componentLifetimeSubscriptions.add(sub);
  }

  ngOnDestroy(): void {
    this.componentLifetimeSubscriptions.unsubscribe();
  }

  private clearSelection() {
    this.currentlySelectedItem = null;
  }

  private publishSelection() {
    this.selectionChange.emit(this.currentlySelectedItem);
  }

  private updateResultsVisibility() {
    this.resultsVisible = this.filteredResults != null && this.filteredResults.length > 0;
  }

  private selectFirst() {
    this.selectItem(0, 0);
  }

  isSelected(item: ItemType): boolean {
    return (
      item &&
      this.currentlySelectedItem &&
      this.getKeyOf(item) === this.getKeyOf(this.currentlySelectedItem)
    );
  }

  onItemClick(item: ItemType) {
    this.select(item);
    this.publishSelection();
  }

  private select(item: ItemType) {
    this.currentlySelectedItem = this.filteredResults.find(
      (element) => this.getKeyOf(element) === this.getKeyOf(item)
    );
  }

  private selectPrev() {
    const index = this.filteredResults.length - 1;
    this.selectItem(index, -1);
  }

  private selectNext() {
    const index = 0;
    this.selectItem(index, 1);
  }

  private selectItem(index: number, indexModifier: number) {
    if (this.filteredResults == null || this.filteredResults.length === 0) {
      return;
    }
    if (this.currentlySelectedItem != null) {
      index =
        this.filteredResults.findIndex(
          (item) => this.getKeyOf(item) === this.getKeyOf(this.currentlySelectedItem)
        ) + indexModifier;
    }
    if (index < 0) {
      index = this.filteredResults.length - 1;
    } else if (index >= this.filteredResults.length) {
      index = 0;
    }
    this.currentlySelectedItem = this.filteredResults[index];
    this.scrollToItem(index);
  }

  private scrollToItem(index: number) {
    let popup;
    try {
      popup = this.popupElement.nativeElement;
      const item = this.resultElements.toArray()[index].nativeElement;
      if (item.offsetTop < popup.scrollTop + item.clientHeight) {
        this.renderer.setProperty(
          this.popupElement.nativeElement,
          'scrollTop',
          item.offsetTop - item.clientHeight
        );
      } else if (item.offsetTop >= popup.scrollTop + popup.clientHeight - item.clientHeight) {
        this.renderer.setProperty(
          this.popupElement.nativeElement,
          'scrollTop',
          item.offsetTop - (popup.clientHeight - item.clientHeight * 2)
        );
      }
    } catch (e) {
      // NOOP
      return;
    }
  }
}

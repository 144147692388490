<ng-container *ngIf="formStatusChanges$ | async"></ng-container>
<section
  appDropZone
  (appDropZone)="onFileDrop($event)"
  class="attachments-group"
  [formGroup]="form">
  <h3 [ngClass]="{ 'attachment-type': true, 'correction-warn': showWaring }">
    <ng-content select="[title]"></ng-content><span *ngIf="config.required">*</span>
  </h3>

  <ng-content select="[info]"></ng-content>

  <div class="input-panel" *ngIf="!disableAddAttachment">
    <app-attachments-input
      (imageChanged)="onImageChanged()"
      [fileDropped]="file.asObservable()"
      class="input-panel__input-btn"
      [formControlName]="config.type"
      [config]="config"
      [class.invalid]="form.get(config.type).invalid"></app-attachments-input>

    <div class="error-list" *ngIf="form.get(config.type).touched && form.get(config.type).invalid">
      <p
        class="error-list__error-item"
        *ngIf="form.get(config.type).getError('duplicatedFile') as error">
        {{ 'STC.ATTACHMENTS.TYPE_GROUP.file_already_exists' | translate }}{{ error.filename }}
      </p>
      <p
        class="error-list__error-item"
        *ngIf="form.get(config.type).getError('requiredAttachment')">
        {{ 'STC.ATTACHMENTS.TYPE_GROUP.add_file_of_type' | translate
        }}{{ pageTexts[config.type]?.label }}
      </p>
      <p
        class="error-list__error-item"
        *ngIf="form.get(config.type).getError('maxFilesExceeded') as error">
        {{ 'STC.ATTACHMENTS.TYPE_GROUP.max_number_of_attachments' | translate }}{{ error.maxFiles }}
      </p>
      <p
        class="error-list__error-item"
        *ngIf="form.get(config.type).getError('fileSizeExceeded') as error">
        {{ 'STC.ATTACHMENTS.TYPE_GROUP.max_file_size' | translate }}{{ error.limit
        }}{{ 'STC.ATTACHMENTS.TYPE_GROUP.MB' | translate }}
      </p>
      <p
        class="error-list__error-item"
        *ngIf="form.get(config.type).getError('invalidFileExtension') as error">
        {{ 'STC.ATTACHMENTS.TYPE_GROUP.file_format_error' | translate
        }}{{ error.acceptedExtensions }}
      </p>
    </div>
    <div class="error-list" *ngIf="backendErrors">
      <p class="error-list__error-item" *ngFor="let error of backendErrors">
        {{ error.userMessage | slice: 2:-2 }}
      </p>
    </div>
  </div>

  <ul *ngIf="!disableAddAttachment" class="attachment-list">
    <app-attachment
      [htmlId]="htmlId"
      (showWarn)="showWarn($event)"
      [isReportedForCorrection]="correctionChecksums?.length > 0"
      [warnChecksums]="correctionChecksums"
      *ngFor="let attachment of selectByAttachmentType(config.type) | async; trackBy: trackById"
      [attachment]="attachment"
      (deleteAttachment)="onDeleteAttachment($event)"
      (reuploadAttachment)="onUploadAttachment($event)"
      (editAttachment)="onEditAttachment($event)"></app-attachment>
  </ul>
  <p *ngIf="config.type === 'PHOTO'">
    <a
    href="#"
    attr.aria-label="{{ 'STC.ATTACHMENTS.instruction_btn_aria' | translate }}"
    role="button"
    (click)="openPhotoInstruction($event)"
    >{{ 'STC.ATTACHMENTS.image_add_instruction' | translate }}</a>
    <span>{{ 'STC.ATTACHMENTS.proper_image_info' | translate }}</span>
  </p>
  <p class="description">
    <ng-content select="[description]"></ng-content>
    <span *ngIf="!disableAddAttachment">
      {{ 'STC.ATTACHMENTS.INFO_FORMAT' | translate }}
      {{ config.format.join(', ') }}.
      {{ 'STC.ATTACHMENTS.INFO_MAX_FILE_SIZE' | translate }}
      {{ config.maxSize }} MB.
    </span>
  </p>
</section>

<section 
[ngClass]="{
    'status__builder': true,
    'status__builder--deprecated': status.deprecated
}" 
*ngFor="let status of statusResults">
    <section class="status__type-wrapper row" *ngIf="status && showHeader">
        <div class="col-lg-12">
            <div class="status__type-box">
                <p class="status__type-title">{{ ('STC.CHECKING_STC_APPLICATION_STATUSES_STEPS_INFO.CARD_TYPES.' + status.applicationType) | translate }}</p>
                <span
                class="status__type-label"
                [ngClass]="{
                    'status__type-label--error': isTypeStatusError(status),
                    'status__type-label--success': isTypeStatusSuccess(status)
                }">
                {{ getTypeDescription(status) }}
              </span>
            </div>
            <div class="status__serial-box" *ngIf="status.serialNumber || status.crn">
                <p class="status__serial-title">{{ 'STC.CHECKING_STC_APPLICATION_STATUS.SERIAL_NUMBER_LABEL' | translate }}</p>
                <span class="status__serial-number">{{ status.serialNumber || status.crn }}</span>
            </div>
            <div class="status__deprecated" *ngIf="status.deprecated">
                <p class="status__deprecated-label">{{'STC.CHECKING_STC_APPLICATION_STATUSES_STEPS_INFO.DEPRECATED_INFO' | translate}}</p>
            </div>
        </div>
    </section>

    <section class="status__info-wrapper row">
        <div class="col-lg-8">
            <div [class]="'status__info-background status__info-background--' + statusClass(status)">
                <div class="status__info-details">
                    <div class="status__info-icon"><img src="{{ statusInfoIcon(status) }}" alt="" /></div>
                    <p class="status__info-title">{{('STC.CHECKING_STC_APPLICATION_STATUSES_STEPS_INFO.STATUS_TEXT.' + activeStatus(status)) | translate}}</p>
                </div>
    
                <ic-ghost-button
                *ngIf="showPayButton(status)"
                [type]="GhostButtonType.SLIDER"
                [width]="105"
                [justifyContent]="'center'"
                class="status__payment-button"
                (click)="payForApplication(status.applicationId, status.applicationType)"
                >
                <span>{{ 'STC.CHECKING_STC_APPLICATION_STATUSES_STEPS_INFO.PAY_BUTTON_TEXT' | translate }}</span>
                </ic-ghost-button>
            </div>
        </div>
    </section>

    <section class="status__steps-wrapper row" *ngIf="status">
        <div class="col-lg-8">
            <ul class="status__steps">
                <li 
                [ngClass]="{
                    'status__step': true,
                    'status__step--error': step.active && isErrorStatus(step),
                    'status__step--success': step.active && !isErrorStatus(step),
                    'status__step--prediction': step.prediction,
                    'status__step--active': step.active === true
                }"
                 *ngFor="let step of status.statuses">
                  <div class="status__step-header">
                    <div 
                    [ngClass]="{
                        'status__step-icon': true,
                        'status__step-icon--done': step.active !== true && !isErrorStatus(step),
                        'status__step-icon--clear': isErrorStatus(step) && step.active,
                        'status__step-icon--success': step.active === true && !isErrorStatus(step),
                        'status__step-icon--active': step.active === true,
                        'status__step-icon--prediction': step.prediction
                    }"
                    >
                        <i class="material-icons" *ngIf="step.active !== true"> done </i>
                        <i class="material-icons" *ngIf="step.active === true && !isErrorStatus(step)"> keyboard_arrow_right </i>
                        <i class="material-icons" *ngIf="isErrorStatus(step) && step.active"> clear </i>
                    </div>

                    <div class="status__step-title">
                        {{('STC.CHECKING_STC_APPLICATION_STATUSES_STEPS_INFO.STEPS_STATUS_TEXT.' + step.status) | translate}}
                        <p *ngIf="step.date" class="status__step-date">{{step.date}}</p>
                    </div>
                  </div>

                    <div class="status__step-text">
                        <div *ngIf="step.active && ((step?.hasNotes && !isLoggedIn) || !step?.hasNotes) && stepDescription(step)">
                            <p class="status__step-description">
                            <ng-container *ngFor="let desc of stepDescription(step); let i = index">
                                {{ i === 0 && step?.hasNotes && !isLoggedIn ? desc : '' }}
                                {{ i === 1 && !step?.hasNotes ? desc : '' }}
                            </ng-container>
                            </p>
                        </div>
    
                        <div *ngIf="isLoggedIn && step?.hasNotes">
                            <p class="status__step-description" *ngFor="let note of step?.notes">
                              <span class="status__step-notes" [innerHTML]="getNote(note)"></span>
                            </p>
                          </div>
                    </div>
                </li>
            </ul>
        </div>
    </section>
</section>
import { Pipe, PipeTransform } from '@angular/core';
import { Place } from './model/place.model';

export type AddressPipeDisplayMode = 'address' | 'name' | 'full';

@Pipe({
  name: 'addressPipe'
})
export class AddressPipe implements PipeTransform {
  transform(id: string | number, places: Place[], mode: AddressPipeDisplayMode = 'full'): any {
    const place = places.find((place) => place.id == id);
    if (!place) {
      return '-';
    }
    switch (mode) {
      case 'address':
        return place.address;
      case 'name':
        return place.name;
      case 'full':
        return `${place.address} (${place.name})`;
    }
  }
}

<div class="ic-page" [formGroup]="formGroup" [attr.id]="htmlElementId ? htmlElementId : null">
  <div class="ic-page__form" [ngClass]="{'ic-page__form': true, 'even': formValidation.formFields.combinedName}">
    <div
      *ngIf="staticContent?.personalInfoTitle"
      [ngClass]="{
        'ic-page__form-title': true,
        error: sectionErrorsExist()
      }">
      {{ staticContent?.personalInfoTitle }}
    </div>
    <div class="row">
      <div class="col-12 col-md-6" *ngIf="formValidation.formFields.hasNoPesel">
        <app-form-checkbox
          [inputId]="(htmlElementId ? htmlElementId + '-' : '') + 'hasNoPesel'"
          formControlName="hasNoPesel"
          [formGroup]="formGroup"
          type="checkbox"
          [label]="staticContent?.noNinLabel"
          (change)="onBlur()">
        </app-form-checkbox>
      </div>
    </div>
    <div class="row">
      <div class="col-12 combinedName" *ngIf="formValidation.formFields.combinedName">
        <app-form-input
          [inputId]="(htmlElementId ? htmlElementId + '-' : '') + 'combinedName'"
          formControlName="combinedName"
          [isReportedForCorrection]="isFieldIncludedInCorrection('combinedName')"
          [warnValue]="findPreviousValue('combinedName')"
          type="text"
          [placeholder]="staticContent?.combinedName.placeholder"
          [label]="staticContent?.combinedName.label"
          [formGroup]="formGroup"
          (blured)="onBlur()"
          [isRequired]="isControlRequired('combinedName')"
          [errorList]="[
            { label: staticContent?.combinedName.error, type: ['required'] },
            { label: staticContent?.tooLongValueError, type: ['maxlength'] },
            { label: staticContent?.combinedName.error2, type: ['pattern'] }
          ]">
        </app-form-input>
      </div>
      <div class="col-12 col-md-6" *ngIf="formValidation.formFields.firstName">
        <app-form-input
          [inputId]="(htmlElementId ? htmlElementId + '-' : '') + 'firstName'"
          formControlName="firstName"
          [isReportedForCorrection]="isFieldIncludedInCorrection('firstName')"
          [warnValue]="findPreviousValue('firstName')"
          type="text"
          [placeholder]="staticContent?.firstname.placeholder"
          [label]="staticContent?.firstname.label"
          [formGroup]="formGroup"
          (blured)="onBlur()"
          [isRequired]="isControlRequired('firstName')"
          [errorList]="[
            { label: staticContent?.firstname.error, type: ['required'] },
            { label: staticContent?.tooLongValueError, type: ['maxlength'] },
            { label: staticContent?.firstname.error2, type: ['pattern'] }
          ]">
        </app-form-input>
      </div>
      <div class="col-12 col-md-6" *ngIf="formValidation.formFields.middleName">
        <app-form-input
          [inputId]="(htmlElementId ? htmlElementId + '-' : '') + 'middleName'"
          formControlName="middleName"
          [isReportedForCorrection]="isFieldIncludedInCorrection('middleName')"
          [warnValue]="findPreviousValue('middleName')"
          type="text"
          [placeholder]="staticContent?.middlename.placeholder"
          [label]="staticContent?.middlename.label"
          (blured)="onBlur()"
          [formGroup]="formGroup"
          [isRequired]="isControlRequired('middleName')"
          [errorList]="[
            { label: staticContent?.tooLongValueError, type: ['maxlength'] },
            { label: staticContent?.middlename.error2, type: ['pattern'] }
          ]">
        </app-form-input>
      </div>
      <div class="col-12 col-md-6" *ngIf="formValidation.formFields.lastName">
        <app-form-input
          [inputId]="(htmlElementId ? htmlElementId + '-' : '') + 'lastName'"
          formControlName="lastName"
          [isReportedForCorrection]="isFieldIncludedInCorrection('lastName')"
          [warnValue]="findPreviousValue('lastName')"
          type="text"
          [placeholder]="staticContent?.lastname.placeholder"
          [label]="staticContent?.lastname.label"
          [formGroup]="formGroup"
          (blured)="onBlur()"
          [isRequired]="isControlRequired('lastName')"
          [errorList]="[
            { label: staticContent?.lastname.error, type: ['required'] },
            { label: staticContent?.tooLongValueError, type: ['maxlength'] },
            { label: staticContent?.lastname.error2, type: ['pattern'] }
          ]">
        </app-form-input>
      </div>
      <div class="col-12 col-md-6" *ngIf="formValidation.formFields.pesel">
        <app-form-input
          [inputId]="(htmlElementId ? htmlElementId + '-' : '') + 'pesel'"
          formControlName="pesel"
          [isReportedForCorrection]="isFieldIncludedInCorrection('pesel')"
          [warnValue]="findPreviousValue('pesel')"
          type="text"
          [placeholder]="staticContent?.nin.placeholder"
          [label]="staticContent?.nin.label"
          [formGroup]="formGroup"
          [maxLength]="11"
          (blured)="onPeselChanged($event.target.value)"
          mask="digit"
          [isRequired]="isControlRequired('pesel')"
          [errorList]="[
            { label: staticContent?.nin.error, type: ['required'] },
            { label: staticContent?.tooLongValueError, type: ['maxlength'] },
            { label: staticContent?.nin.error2, type: ['pattern'] }
          ]">
        </app-form-input>
      </div>
      <div class="col-12 col-md-6" *ngIf="formValidation.formFields.nip">
        <app-form-input
          [inputId]="(htmlElementId ? htmlElementId + '-' : '') + 'nip'"
          formControlName="nip"
          [isReportedForCorrection]="isFieldIncludedInCorrection('nip')"
          [warnValue]="findPreviousValue('nip')"
          type="text"
          [placeholder]="staticContent?.nip.placeholder"
          [label]="staticContent?.nip.label"
          [formGroup]="formGroup"
          (blured)="onBlur()"
          mask="digit"
          [isRequired]="isControlRequired('nip')"
          [errorList]="[
            { label: staticContent?.nip.error, type: ['required'] },
          ]">
        </app-form-input>
      </div>
      <div class="col-12 col-md-6" *ngIf="formValidation.formFields.certificateNumber">
        <app-form-input
          [inputId]="(htmlElementId ? htmlElementId + '-' : '') + 'certificateNumber'"
          formControlName="certificateNumber"
          [isReportedForCorrection]="
            isFieldIncludedInCorrection('certificateNumber') ||
            isFieldIncludedInCorrection('technicianCertificateNumber')
          "
          [warnValue]="
            findPreviousValue('certificateNumber') ||
            findPreviousValue('technicianCertificateNumber')
          "
          type="text"
          maxLength="25"
          [placeholder]="staticContent?.certificateNumber?.placeholder"
          [label]="staticContent?.certificateNumber?.label"
          [formGroup]="formGroup"
          (blured)="onBlur()"
          [isRequired]="isControlRequired('certificateNumber')"
          [errorList]="[
            {
              label: staticContent?.certificateNumber?.error,
              type: ['required']
            },
            { label: staticContent?.tooLongValueError, type: ['maxlength'] },
            { label: staticContent?.certificateNumber.error2, type: ['pattern'] }
          ]">
        </app-form-input>
      </div>
      <div class="col-12 col-md-6" *ngIf="formValidation.formFields.birthPlace">
        <app-form-input
          [inputId]="(htmlElementId ? htmlElementId + '-' : '') + 'birthPlace'"
          formControlName="birthPlace"
          [isReportedForCorrection]="isFieldIncludedInCorrection('birthPlace')"
          [warnValue]="findPreviousValue('birthPlace')"
          type="text"
          [placeholder]="staticContent?.placeOfBirth.placeholder"
          [label]="staticContent?.placeOfBirth.label"
          [formGroup]="formGroup"
          (blured)="onBlur()"
          [isRequired]="isControlRequired('birthPlace')"
          [errorList]="[
            { label: staticContent?.placeOfBirth.error, type: ['required'] },
            { label: staticContent?.tooLongValueError, type: ['maxlength'] },
            { label: staticContent?.placeOfBirth.error2, type: ['pattern'] }
          ]">
        </app-form-input>
      </div>
      <div class="col-12 col-md-6" *ngIf="formValidation.formFields.birthDate">
        <app-form-input
          [inputId]="(htmlElementId ? htmlElementId + '-' : '') + 'birthDate'"
          formControlName="birthDate"
          [isReportedForCorrection]="isFieldIncludedInCorrection('birthDate')"
          [warnValue]="findPreviousValue('birthDate')"
          type="text"
          [placeholder]="staticContent?.dateOfBirth.placeholder"
          [label]="staticContent?.dateOfBirth.label"
          [formGroup]="formGroup"
          mask="date"
          (blured)="onBirthDateChanged($event.target.value)"
          [isRequired]="isControlRequired('birthDate')"
          [errorList]="[
            { label: staticContent?.dateOfBirth.error, type: ['required'] },
            { label: staticContent?.dateOfBirth.error2, type: ['pattern'] },
            { label: staticContent?.dateInFutureError, type: ['dateInFuture'] }
          ]">
        </app-form-input>
      </div>
      <div class="col-12 col-md-6" *ngIf="formValidation.formFields.phoneNumber">
        <app-form-input
          [inputId]="(htmlElementId ? htmlElementId + '-' : '') + 'phoneNumber'"
          formControlName="phoneNumber"
          [isReportedForCorrection]="
            isFieldIncludedInCorrection('phoneNumber') ||
            isFieldIncludedInCorrection('technicianPhoneNumber')
          "
          [warnValue]="
            findPreviousValue('phoneNumber') || findPreviousValue('technicianPhoneNumber')
          "
          type="text"
          (blured)="onBlur()"
          [isRequired]="isControlRequired('phoneNumber')"
          [placeholder]="staticContent?.technicianPhone?.placeholder"
          [label]="staticContent?.technicianPhone?.label"
          [formGroup]="formGroup"
          [errorList]="[
            { label: staticContent?.tooLongValueError, type: ['maxlength'] },
            { label: staticContent?.tooShortValueError, type: ['minlength'] },
            {
              label: staticContent?.technicianPhone?.error2,
              type: ['pattern']
            },
            { label: staticContent?.technicianPhone?.error, type: ['required'] }
          ]">
        </app-form-input>
      </div>
      <div class="col-12 col-md-6" *ngIf="formValidation.formFields.email">
        <app-form-input
          [inputId]="(htmlElementId ? htmlElementId + '-' : '') + 'email'"
          formControlName="email"
          [isReportedForCorrection]="
            isFieldIncludedInCorrection('email') || isFieldIncludedInCorrection('technicianEmail')
          "
          [warnValue]="findPreviousValue('email') || findPreviousValue('technicianEmail')"
          [isRequired]="isControlRequired('email')"
          type="text"
          (blured)="onBlur()"
          [placeholder]="staticContent?.technicianEmail?.placeholder"
          [label]="staticContent?.technicianEmail?.label"
          [formGroup]="formGroup"
          [info]="staticContent?.technicianEmail?.info"
          [errorList]="[
            { label: staticContent?.tooLongValueError, type: ['maxlength'] },
            {
              label: staticContent?.technicianEmail?.error2,
              type: ['pattern']
            },
            { label: staticContent?.technicianPhone?.error, type: ['required'] }
          ]">
        </app-form-input>
      </div>
    </div>
    <div *ngIf="hasNoPesel()">
      <div class="row">
        <div class="col-12 col-md-6" *ngIf="formValidation.formFields.personalDocumentType">
          <app-selectable-input
            #personalDocumentType
            [id]="(htmlElementId ? htmlElementId + '-' : '') + 'personalDocumentType'"
            formControlName="personalDocumentType"
            [isReportedForCorrection]="isFieldIncludedInCorrection('personalDocumentType')"
            [warnValue]="findPreviousValue('personalDocumentType')"
            [formGroup]="formGroup"
            [items]="personalDocumentTypes"
            [htmlId]="'personalDocumentType'"
            (exit)="onBlur()"
            key="value"
            (blured)="onBlur()"
            outputKey="personalDocumentType"
            [label]="staticContent?.noPeselDocType.label"
            [placeholder]="staticContent?.noPeselDocType.placeholder"
            [isRequired]="isControlRequired('personalDocumentType')"
            [errorList]="[
              {
                label: staticContent?.noPeselDocType.error,
                type: ['required']
              },
              {
                label: staticContent?.noPeselDocType.error2,
                type: ['valueNotInArray']
              }
            ]">
          </app-selectable-input>
        </div>
        <div class="col-12 col-md-6" *ngIf="formValidation.formFields.personalDocumentNumber">
          <app-form-input
            [inputId]="(htmlElementId ? htmlElementId + '-' : '') + 'personalDocumentNumber'"
            formControlName="personalDocumentNumber"
            [isReportedForCorrection]="isFieldIncludedInCorrection('personalDocumentNumber')"
            [warnValue]="findPreviousValue('personalDocumentNumber')"
            type="text"
            [placeholder]="staticContent?.noPeselDocNr.placeholder"
            [label]="staticContent?.noPeselDocNr.label"
            [formGroup]="formGroup"
            (blured)="onBlur()"
            [isRequired]="isControlRequired('personalDocumentNumber')"
            [errorList]="[
              { label: staticContent?.noPeselDocNr.error, type: ['required'] },
              { label: staticContent?.tooLongValueError, type: ['maxlength'] },
              { label: staticContent?.noPeselDocNr.error2, type: ['pattern'] }
            ]">
          </app-form-input>
        </div>
      </div>
      <div class="row">
        <div
          class="col-12 issuingAuthority"
          *ngIf="formValidation.formFields.personalDocumentIssuingAuthority">
          <app-form-input
            [inputId]="
              (htmlElementId ? htmlElementId + '-' : '') + 'personalDocumentIssuingAuthority'
            "
            formControlName="personalDocumentIssuingAuthority"
            [isReportedForCorrection]="
              isFieldIncludedInCorrection('personalDocumentIssuingAuthority')
            "
            [warnValue]="findPreviousValue('personalDocumentIssuingAuthority')"
            type="text"
            [placeholder]="staticContent?.noPeselDocIssuer.placeholder"
            [label]="staticContent?.noPeselDocIssuer.label"
            [formGroup]="formGroup"
            (blured)="onBlur()"
            [isRequired]="isControlRequired('personalDocumentIssuingAuthority')"
            [errorList]="[
              {
                label: staticContent?.noPeselDocIssuer.error,
                type: ['required']
              },
              { label: staticContent?.tooLongValueError, type: ['maxlength'] },
              { label: staticContent?.noPeselDocIssuer.error2, type: ['pattern'] }
            ]">
          </app-form-input>
        </div>
      </div>
    </div>
  </div>
</div>

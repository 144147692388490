import { DomSanitizer } from '@angular/platform-browser';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitanizer: DomSanitizer) {}
  transform(html, addSvg?: boolean): any {
    if (addSvg) {
      html = `<div class="info-image"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="26" height="26" viewBox="0 0 26 26">
    <defs>
      <clipPath id="clip-path">
        <rect width="26" height="26" fill="none"/>
      </clipPath>
    </defs>
    <g id="info" clip-path="url(#clip-path)">
      <rect id="Rectangle_1924" data-name="Rectangle 1924" width="26" height="26" rx="13" fill="#2434b3"/>
      <path id="Path_5990" data-name="Path 5990" d="M4.216,13.7q-.034.772,1.492.772v.978H.39v-.978q1.475,0,1.475-.772V8.487Q1.865,7.7.39,7.7V6.72H4.216Zm.206-9.864A1.447,1.447,0,0,1,4,4.876a1.382,1.382,0,0,1-1.038.437,1.38,1.38,0,0,1-.575-.12,1.529,1.529,0,0,1-.781-.781,1.38,1.38,0,0,1-.12-.575A1.382,1.382,0,0,1,1.925,2.8a1.447,1.447,0,0,1,1.038-.42,1.4,1.4,0,0,1,1.029.429A1.4,1.4,0,0,1,4.421,3.838Z" transform="translate(9.609 3.62)" fill="#fff"/>
    </g>
  </svg></div><div class="additional-text">${html}</div>`;
    }
    return this.sanitanizer.bypassSecurityTrustHtml(html);
  }
}

import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment as env } from 'src/environments/environment';
import { INFO_BAR_CONFIG } from './_consts/info-bar.config';
import { InfoBarMessage, InfoBarResponse } from './_models/info-bar-message.model';

const INFOBAR_POLLING_INTERVAL = 15 * 60 * 1000;

@Injectable({
  providedIn: 'root'
})
export class InfoBarsFacadeService implements OnDestroy {
  private infoBars = new BehaviorSubject<InfoBarMessage[]>([]);
  private etag: string;
  private readonly intervalId: any;
  public infoBars$ = this.infoBars.asObservable();

  constructor(private http: HttpClient) {
    this.fetchBars();
    this.intervalId = setInterval(() => this.fetchBars(), INFOBAR_POLLING_INTERVAL);
  }

  private fetchBars() {
    let headers = new HttpHeaders().set('Access-Control-Expose-Headers', 'ETag');

    if (this.etag) {
      headers = headers.set('If-None-Match', this.etag);
    }

    this.http
      .get<InfoBarResponse>(`${env.INFOBARS_ENDPOINT}`, {
        headers: headers,
        observe: 'response'
      })
      .subscribe({
        next: (resp: HttpResponse<InfoBarResponse>) => {
          this.etag = resp.headers.get('ETag');

          if (resp.body) {
            this.infoBars.next(resp.body.infobars);
          }
        },
        error: () => { }
      });
  }

  public areCookiesAlreadyAccepted(): boolean {
    return (
      localStorage.getItem(INFO_BAR_CONFIG.COOKIES_ACCEPTANCE.key) ===
      INFO_BAR_CONFIG.COOKIES_ACCEPTANCE.value.true
    );
  }

  public acceptCookies(): void {
    localStorage.setItem(
      INFO_BAR_CONFIG.COOKIES_ACCEPTANCE.key,
      INFO_BAR_CONFIG.COOKIES_ACCEPTANCE.value.true
    );
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
  }
}

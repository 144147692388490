import { Extensions, setCookieWithHours } from 'src/app/shared/extensions/extensions';
import {CardTypes} from '../../../../../applications/stc/_consts/card-types.enum';
import {AuthService} from '../../../../../core/auth';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {filter, map, take, tap} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {ComplaintReasonsResponse, ComplaintSingleReason} from '../_consts/complaint.types';
import {STC_V1} from 'src/app/shared/consts/api-version.config';
import {ComplaintCreatedDto} from '../_dto/ComplaintCreatedDto';
import {CreateComplaintForEmployerDto} from '../_dto/CreateComplaintForEmployerDto';
import {CreateComplaintForCardholderDto} from '../_dto/CreateComplaintForCardholderDto';

@Injectable({
  providedIn: 'root'
})
export class ComplaintDataService {
  loggedUserUid: string;
  constructor(private http: HttpClient, private auth: AuthService) {
    this.auth.user$
      .pipe(
        filter((result) => result != null),
        take(1)
      )
      .subscribe((result) => {
        this.loggedUserUid = result.uuid;
      });
  }

  getReasonList(): Observable<ComplaintSingleReason[]> {
    return this.http
      .get<ComplaintReasonsResponse>(
        `${environment.BACKEND_API.BASE}${environment.BACKEND_API.COMPLAINT_REASONS}`
      )
      .pipe(map((response) => response.reasons));
  }

  public submitIndividual(dtoBody: CreateComplaintForCardholderDto, headers: HttpHeaders) {
    if(!this.loggedUserUid && !!Extensions.getCookie('anonymousToken')) {
      return this.http.post<ComplaintCreatedDto>(
        `${environment.BACKEND_API.BASE}${environment.BACKEND_API.ANONYMOUS}${environment.BACKEND_API.COMPLAINT}`,
        dtoBody,
        { headers: headers, observe: 'response' }
      ).pipe(map((response) => {
        setCookieWithHours(`ownerId=${response.headers.get('Anonymous-User-Id')};`, 4);
        setCookieWithHours(`applicationId=${response.body.applicationId};`, 4);
        setCookieWithHours(`complaintId=${response.body.complaintId};`, 4);
        setCookieWithHours(`cardType=${dtoBody.cardType};`, 4);

        return response.body;
      }));
    } else {
      return this.http.post<ComplaintCreatedDto>(
        `${environment.BACKEND_API.BASE}${environment.BACKEND_API.CARDHOLDERS}/${this.loggedUserUid}${environment.BACKEND_API.COMPLAINT}`,
        dtoBody,
        { headers: headers }
      );
    }
  }

  private submitEmployee(employeeId, dtoBody: CreateComplaintForEmployerDto, headers: HttpHeaders) {
    return this.http.post<ComplaintCreatedDto>(
      `${environment.BACKEND_API.BASE}${environment.BACKEND_API.EMPLOYERS}/${this.loggedUserUid}` +
        `${environment.BACKEND_API.EMPLOYEES}/${employeeId}${environment.BACKEND_API.COMPLAINT}`,
      dtoBody,
      { headers: headers }
    );
  }

  private submitEmployer(dtoBody: CreateComplaintForEmployerDto, headers: HttpHeaders) {
    return this.http.post<ComplaintCreatedDto>(
      `${environment.BACKEND_API.BASE}${environment.BACKEND_API.EMPLOYERS}/${this.loggedUserUid}${environment.BACKEND_API.COMPLAINT}`,
      dtoBody,
      { headers: headers }
    );
  }

  deleteApplication(cardType: string, applicationId: string): Observable<any> {
    const type = cardType.toLowerCase().replace(/_/g, '-');
    return this.http.delete(
      `${environment.BACKEND_API.BASE}${environment.BACKEND_API.APPLICATIONS}/${type}/${applicationId}`
    );
  }

  submitReason(
    cardNumber: string,
    cardType: CardTypes,
    reason: string,
    isPaper: boolean,
    isPersonal: boolean,
    additionalReason: string,
    isBusiness: boolean,
    employeeId?: string
  ): Observable<ComplaintCreatedDto> {
    const headers = new HttpHeaders().set('accept', STC_V1);

    const individualDto: CreateComplaintForCardholderDto = {
      cardNumber: cardNumber,
      cardType: cardType,
      complaintReason: reason,
      paper: isPaper,
      personal: isPersonal ? isPersonal : null,
      problemDescription: additionalReason
    };

    const businessDto: CreateComplaintForEmployerDto = {
      cardNumber: cardNumber,
      cardType: cardType,
      complaintReason: reason,
      problemDescription: additionalReason
    };

    switch (cardType) {
      case CardTypes.CONTROL_CARD:
        if (isBusiness) {
          if (isPersonal) {
            return this.submitEmployee(employeeId, businessDto, headers);
          } else {
            return this.submitEmployer(businessDto, headers);
          }
        } else {
          return this.submitIndividual(individualDto, headers);
        }
      case CardTypes.COMPANY_CARD:
        if (isBusiness) {
          return this.submitEmployer(businessDto, headers);
        } else {
          return this.submitIndividual(individualDto, headers);
        }
      case CardTypes.DRIVER_CARD:
      case CardTypes.TEMPORARY_DRIVER_CARD:
      case CardTypes.WORKSHOP_CARD:
        if (isBusiness) {
          return this.submitEmployee(employeeId, businessDto, headers);
        } else {
          return this.submitIndividual(individualDto, headers);
        }
    }
  }
}

import { ShippingContactDetailsDto } from '../employee/shipping-contact-details-dto.model';
import { ApplicationDto } from '../applications/application-dto.model';
import { FormOfRefundDto } from '../applications/form-of-refund-dto.model';
import { PersonDto } from '../employee/personDto';
import { StatusDto } from '../applications/status-dto.model';
import { ControlAuthorityDto } from './control-authority-dto.model';

export interface ControlCardApplicationDto extends ApplicationDto {
  cardShippingContactDetails: ShippingContactDetailsDto;
  electronicInvoice?: boolean;
  formOfRefund: FormOfRefundDto;
  id?: string;
  paper?: boolean;
  reasonType: ControlCardApplicationDto.ReasonTypeEnum;
  serialNumber?: number;
  status?: StatusDto;
  type: ApplicationDto.TypeEnum;
  controlAuthority: ControlAuthorityDto;
  person: PersonDto;
  personal?: boolean;
}
export namespace ControlCardApplicationDto {
  export type ReasonTypeEnum =
    | 'FIRST_ISSUE'
    | 'CLOSE_EXPIRY_DATE'
    | 'CARD_LOSS'
    | 'CARD_THEFT'
    | 'DAMAGED_OR_DEFECTIVE_CARD'
    | 'ADMINISTRATIVE_DATA_CHANGE';
  export const ReasonTypeEnum = {
    FIRSTISSUE: 'FIRST_ISSUE' as ReasonTypeEnum,
    CLOSEEXPIRYDATE: 'CLOSE_EXPIRY_DATE' as ReasonTypeEnum,
    CARDLOSS: 'CARD_LOSS' as ReasonTypeEnum,
    CARDTHEFT: 'CARD_THEFT' as ReasonTypeEnum,
    DAMAGEDORDEFECTIVECARD: 'DAMAGED_OR_DEFECTIVE_CARD' as ReasonTypeEnum,
    ADMINISTRATIVEDATACHANGE: 'ADMINISTRATIVE_DATA_CHANGE' as ReasonTypeEnum
  };
}

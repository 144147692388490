import { AuthAction, AuthActionType } from './auth.actions';
import { User } from '../model/user.model';

export const featureName = 'auth';

export interface AuthState {
  user: User;
  anonymous: boolean;
  status: AuthStatus;
}

export type AuthStatus = 'loading' | 'initialized' | 'error' | 'unlogged' | 'anonymous';

const initialState: AuthState = { user: null, status: 'loading', anonymous: false };

export function authReducer(state: AuthState = initialState, action: AuthAction): AuthState {
  switch (action.type) {
    case AuthActionType.AUTH_INIT:
      return {
        user: null,
        status: 'loading',
        anonymous: false
      };

    case AuthActionType.AUTH_READY:
      return {
        user: action.payload.user,
        status: 'initialized',
        anonymous: false
      };

    case AuthActionType.AUTH_ERROR:
      return {
        user: null,
        status: 'error',
        anonymous: false
      };

    case AuthActionType.LOGIN:
    case AuthActionType.AUTH_LOGGED:
      return {
        ...state,
        anonymous: false
      };

    case AuthActionType.SYNC_LOGOUT:
    case AuthActionType.LOGOUT:
    case AuthActionType.AUTH_LOGGED_OUT:
      return {
        ...state,
        user: null,
        anonymous: false
      };

    case AuthActionType.REFRESH_USER:
      return {
        ...state,
        status: 'loading',
        anonymous: false
      };

    case AuthActionType.SET_ACCEPTANCES:
      return {
        ...state,
        user: { ...state.user, hasAcc: action.payload.hasAcc },
        anonymous: false
      };

    case AuthActionType.CLEAR_AUTH:
      return {
        user: null,
        status: 'initialized',
        anonymous: false
      }; 

    case AuthActionType.DISABLE_ANONYMOUS:
      return {
        ...state,
        anonymous: false
      };


    case AuthActionType.ANONYMOUS_LOGIN:
      return {
        ...state,
        user: null,
        anonymous: true
      };

    default:
      return state;
  }
}

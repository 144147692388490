import { Extensions } from 'src/app/shared/extensions/extensions';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';

import { AuthService } from '../core/auth';
import { AcceptancesService } from '../core/services/acceptances-service';
import { ModalService } from '../shared/components/modal/modal.service';
import { ActivationService } from './_service/activation.service';
import { AcceptancesModalComponent } from './acceptances-modal/acceptances-modal.component';
import { InfoBarMessage } from './info-bar/_models/info-bar-message.model';
import { InfoBarsFacadeService } from './info-bar/info-bars-facade.service';
import { ToastrService } from 'ngx-toastr';
import { PaymentComponent } from '../external-payments/esp-payment/payment/payment.component';
import { PaymentComponent as PoskPaymentComponent } from '../external-payments/posk-payment/payment/payment.component';
import { PaymentConfirmationComponent } from '../external-payments/esp-payment/payment-confirmation/payment-confirmation.component';
import { PaymentConfirmationComponent as PoskPaymentConfirmationComponent } from '../external-payments/posk-payment/payment-confirmation/payment-confirmation.component';
import { TranslationModalComponent } from '../shared/components/modal/translation-modal/translation-modal.component';
import { TRANSLATED_PAGES } from './_const/layout.const';
import { LanguageService } from './_service/language.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  @Output() changeLng = new EventEmitter();
  infobars$: Observable<InfoBarMessage[]> = this.infoBarService.infoBars$;
  userMail: string;
  sub: Subscription = new Subscription();
  pageText: any;

  showElements = {
    showOnlyLogo: false,
    hideInfobars: false
  };

  onlyLogoComponents = [
    PaymentComponent,
    PaymentConfirmationComponent,
    PoskPaymentComponent,
    PoskPaymentConfirmationComponent
  ];

  noInfobarsComponents = [PoskPaymentComponent, PoskPaymentConfirmationComponent];
  lastRoute = null;

  constructor(
    private infoBarService: InfoBarsFacadeService,
    private modalService: ModalService,
    private auth: AuthService,
    private acc: AcceptancesService,
    private activationService: ActivationService,
    private toastrService: ToastrService,
    private translateService: TranslateService,
    private router: Router,
    private language: LanguageService
  ) {
    this.translateService.get('STC.ACTIVE_ACCOUNT').subscribe({
      next: (content) => {
        this.pageText = content;
      }
    });
    this.sub.add(
      this.activationService.accountActivated$.subscribe(() =>
        this.toastrService.info(this.pageText.title)
      )
    );

    this.sub.add(
      this.router.events
        .pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd))
        .subscribe((value) => {
          this.language.detectTranslatedPage(this.translateService.currentLang);
        })
    );
  }

  ngOnInit(): void {
    const sub = this.auth.state$
      .pipe(
        filter((state) => !!state.user),
        filter((state) => !state?.user?.hasAcc && !state.anonymous),
        switchMap((state) => this.acc.getAcceptances(state.user.email)),
        catchError((e) => {
          this.auth.logout();
          return throwError(() => e);
        })
      )
      .subscribe(
        (acceptances) => {
          let modalRef = this.modalService.open(AcceptancesModalComponent, {
            data: {
              acceptances: acceptances,
              user: this.userMail,
              freezeOverlay: true
            }
          });
          const sub = modalRef.afterClosed.subscribe((res) => {
            sub.unsubscribe();
            modalRef = null;
          });
          sub.unsubscribe();
        },
        () => {
          this.auth.logout();
        }
      );
  }

  changeLngMethod(lang: string) {
    this.changeLng.emit(lang);
  }
  checkIfShowElement(event, componentsList, flag: keyof LayoutComponent['showElements']) {
    if (componentsList.find((component) => event instanceof component)) {
      this.showElements[flag] = true;
    } else {
      this.showElements[flag] = false;
    }
  }

  onComponentActivated(event) {
    this.checkIfShowElement(event, this.onlyLogoComponents, 'showOnlyLogo');
    this.checkIfShowElement(event, this.noInfobarsComponents, 'hideInfobars');
  }
}

import { Translation as SearchServicesTranslation } from '../models/search/translation.model';
import { Action } from '@ngrx/store';
import { ServiceCategory } from '../models/service-category.model';
import { PopularService } from '../models/popular-service.model';

export interface SharedActions {
  SET_SERVICE_CATEGORIES: string;
  SET_POPULAR_SERVICES: string;
  SET_SEARCH_SERVICES: string;
}

export const ActionTypes: SharedActions = {
  SET_SERVICE_CATEGORIES: '[ServiceCategory] Set',
  SET_POPULAR_SERVICES: '[PopularServices] Set',
  SET_SEARCH_SERVICES: '[SearchServices] Set'
};

export class SetServiceCategoryAction implements Action {
  type = ActionTypes.SET_SERVICE_CATEGORIES;
  constructor(public payload: ServiceCategory[]) {}
}

export class SetPopularServicesAction implements Action {
  type = ActionTypes.SET_POPULAR_SERVICES;
  constructor(public payload: PopularService[]) {}
}

export class SetSearchServicesAction implements Action {
  type = ActionTypes.SET_SEARCH_SERVICES;
  constructor(public payload: SearchServicesTranslation) {}
}

export type Actions = SetServiceCategoryAction | SetPopularServicesAction | SetSearchServicesAction;

import { MODERNIZATION_WORK_ROUTE } from 'src/app/info-pages/_consts/info-pages-route.const';
import { environment } from './../../../../environments/environment';
import { ERROR_ROUTE } from './../_consts/error-route.const';
import { Router, NavigationStart } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, ErrorHandler, Injector, NgZone, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ErrorsHandler extends ErrorHandler {
  constructor(
    private translate: TranslateService,
    private ngZone: NgZone,
    @Inject(Injector) private injector: Injector
  ) {
    super();
  }

  private get toastrService(): ToastrService {
    return this.injector.get(ToastrService);
  }

  handleError(error: Error | HttpErrorResponse): void {
    if (!navigator.onLine) {
      this.safeNavigate(0, () => {
        this.translate.get('ERROR.CONNECTION_FAILURE').subscribe((result: string) => {
          this.toastrService.error(result, null, { onActivateTick: true });
        });
      });
      return;
    }

    if (error instanceof HttpErrorResponse) {
      const translateKey = 'ERROR.TITLES.DEFAULT';
      if (error.status >= 500) {
        this.safeNavigate(error.status);
      } else if (error.status >= 400) {
        this.translate.get(translateKey).subscribe((result: string) => {
          this.toastrService.error(`${result} kod: ${error.status}`, null, {
            onActivateTick: true
          });
        });
      }
    } else {
      const statusRegex = RegExp('"status": ?(\\d\\d?\\d?)');
      const status = error.message.match(statusRegex);
      if (status) {
        const statusCode = status[1]; // 1st capturing group
        this.safeNavigate(statusCode);
      }
    }

    if (!environment.production) {
      super.handleError(error);
    }
  }

  safeNavigate(statusCode: number | string, afterNavigateCallback?: Function) {
    const router = this.injector.get(Router);
    this.ngZone.run(() => {
      const sub = router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          setTimeout(() => {
            document.querySelector('body').click();
            sub.unsubscribe();
          }, 0);
        }
      });

      let redirectPath: string[];
      if (statusCode == 503) {
        redirectPath = [MODERNIZATION_WORK_ROUTE];
      } else {
        redirectPath = [`/${ERROR_ROUTE}`, `${statusCode}`];
      }
      router.navigate(redirectPath, { skipLocationChange: true, replaceUrl: true }).then(() => {
        if (afterNavigateCallback) {
          afterNavigateCallback();
        }
      });
    });
  }
}

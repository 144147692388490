import { ElementRef, Optional, Input, Directive, OnChanges } from '@angular/core';
import { RouterLink } from '@angular/router';

@Directive({
  selector: '[externalLink]'
})
export class ExternalLinkDirective implements OnChanges {
  @Input() externalLink: string;

  constructor(private el: ElementRef, @Optional() private link: RouterLink) {}

  ngOnChanges() {
    if (!this.link || !this.externalLink) {
      return;
    }
    this.el.nativeElement.href = this.link.href = this.externalLink;

    // Replace onClick
    this.link.onClick = (...args: any[]) => {
      return true;
    };
  }
}

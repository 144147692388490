import { Injectable, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import { filter, withLatestFrom } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';

interface AuthErrorCode {
  code: number;
}

interface AuthErrorCodeDictionary {
  [key: string]: AuthErrorEntry;
}

interface AuthErrorEntry extends AuthErrorCode {
  matchers?: AuthErrorReasonMatcher[];
}

interface AuthErrorReasonMatcher extends AuthErrorCode {
  sequence: string;
}

const authErrorDictionary: AuthErrorCodeDictionary = {
  discovery_document_load_error: { code: 1010 },
  token_validation_error: {
    code: 1020,
    matchers: [
      { sequence: 'Token has expired', code: 1021 },
      { sequence: 'Wrong issuer', code: 1022 }
    ]
  },
  jwks_load_error: { code: 1030 },
  invalid_nonce_in_state: { code: 1040 },
  discovery_document_validation_error: { code: 1050 },
  user_profile_load_error: { code: 1060 },
  token_error: { code: 1070 },
  code_error: { code: 1080 },
  token_refresh_error: { code: 1090 },
  silent_refresh_error: { code: 1100 },
  token_revoke_error: { code: 1110 },
  default_auth_error: { code: 1000 }
};

@Injectable()
export class AuthErrorLoggerService implements OnDestroy {
  private authErrorLog$: Subject<void> = new Subject<void>();
  private sub: Subscription;

  constructor(private toastr: ToastrService, private oauth: OAuthService) {
    this.sub = this.authErrorLog$
      .pipe(
        withLatestFrom(this.oauth.events),
        filter(([, error]) => error instanceof OAuthErrorEvent)
      )
      .subscribe({
        next: ([, error]) => this.handle(error as OAuthErrorEvent)
      });
  }

  logError(): void {
    this.authErrorLog$.next();
  }

  ngOnDestroy(): void {
    this.authErrorLog$.complete();
    this.sub.unsubscribe();
  }

  private handle(error: OAuthErrorEvent): void {
    const { code } = this.getErrorCodeFor(error);
    this.toastr.warning(
      `Logowanie nie powiodło się (kod błędu: ${code}). Spróbuj ponownie za chwilę...`,
      null,
      { disableTimeOut: true, closeButton: true }
    );
  }

  private getErrorCodeFor(error: OAuthErrorEvent): AuthErrorCode {
    const reason = typeof error.reason === 'string' ? error.reason : '';
    const defaultEntry = authErrorDictionary['default_auth_error'];
    const foundEntry = authErrorDictionary[`${error.type}`];
    if (!foundEntry) return defaultEntry;
    if (!foundEntry.matchers) return foundEntry;
    const reasonMatcher = foundEntry.matchers.find((matcher) => reason.includes(matcher.sequence));
    return reasonMatcher != null ? reasonMatcher : foundEntry;
  }
}

<div class="ic-input ic-input__stacking--2">
  <label
    [class.required-field]="isRequired"
    [class.sublabel-spaceing]="subLabelSpaceing"
    [class.warn]="showWarning()"
    [for]="htmlId"
    [class.error]="isReportedForCorrection && !isTouched()">
    {{ label }}
  </label>
  <span class="ic-input__subLabel" *ngIf="subLabel">
    {{ subLabel }}
  </span>

  <div
    [ngClass]="{
      'input-flex': true,
      'selectable-input': true,
      warn: showWarning(),
      error: isReportedForCorrection && !isTouched(),
      modified: isReportedForCorrection && isTouched() && isValid()
    }"
    (clickOutside)="onClickOutside()">
    <si-internal-text-input
      [key]="itemsKeyPropertyName"
      [outputKey]="itemsOutputPropertyName"
      [placeholder]="placeholder"
      [inputValue]="inputValue"
      (inputChange)="onInputChange($event)"
      (riflebirdClose)="onRiflebirdClose()"
      [siFormControl]="formControl"
      [disabledState]="disabledState"
      [readOnly]="readOnly"
      [htmlId]="htmlId"></si-internal-text-input>

    <div
      *ngIf="isInvalidChanges | async"
      class="error-icon selectable-input__error-icon"
      role="button"
      (click)="onClickOutside()"></div>

    <si-internal-result-list
      [key]="itemsKeyPropertyName"
      [outputKey]="itemsOutputPropertyName"
      [additionalFilterKeys]="itemsKeyAdditionalFilterPropertyNames"
      [items]="initialItems"
      [noFilteredItemsMessage]="noMatchMessage"
      [noActiveItemsMessage]="noActiveItemsMessage"
      [inputValue]="inputValue"
      (selectionChange)="onSelectionChange($event)"></si-internal-result-list>
  </div>
  <div class="error-description-container">
    <div *ngFor="let error of errorListChanges | async">
      <span *ngIf="error.show" class="error-description">{{ error.label }}</span>
    </div>
    <span *ngIf="showWarning()" class="warn-descriptor">{{
      'STC.CORRECTIONS.FIELD_HINT' | translate: { fieldName: label }
    }}</span>
  </div>
</div>

import { AcceptanceType } from './../../core/model/acceptances-model';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BaseModalDirective } from 'src/app/shared/components/modal/base-modal';
import { ModalRef } from 'src/app/shared/components/modal/modal-ref';
import { AcceptancesService } from 'src/app/core/services/acceptances-service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { GhostButtonType } from 'src/app/shared/components/ghost-button/ghost-button-type.enum';
import { AcceptancesFormValidation } from 'src/app/shared/components/acceptances-checboxes/acceptances-validation';
import { AuthService } from 'src/app/core/auth';
import { Subscription } from 'rxjs';
import { AcceptanceDto } from 'src/app/core/model/acceptances-model';

const ACCEPTANCES_FORMGROUP_NAME = 'acceptances';

@Component({
  selector: 'app-acceptances-modal',
  templateUrl: './acceptances-modal.component.html',
  styleUrls: ['./acceptances-modal.component.scss']
})
export class AcceptancesModalComponent extends BaseModalDirective implements OnInit, OnDestroy {
  GhostButtonType: typeof GhostButtonType = GhostButtonType;
  availableAcceptances: AcceptanceDto[];
  acceptances: AcceptanceType[];
  userMail: string;
  accModal: ModalRef;
  updatedAcceptances: AcceptanceType[] = [];
  formGroup: UntypedFormGroup;
  formValidation: AcceptancesFormValidation;
  sub: Subscription;
  content: any;
  loading: boolean;
  constructor(
    private accService: AcceptancesService,
    private auth: AuthService,
    private fb: UntypedFormBuilder,
    private tosr: ToastrService,
    private translateService: TranslateService
  ) {
    super();
    this.formGroup = this.fb.group({});
  }

  ngOnInit() {
    this.translateService.get('ACCEPTANCES_MODAL').subscribe({
      next: (content) => {
        this.content = content;
      }
    });
    this.availableAcceptances = this.getData().acceptances.availableAcceptances;
    this.acceptances = [...this.getData().acceptances.actualAcceptances];
    this.getAcceptances(this.acceptances);
    this.userMail = this.getData().acceptances.userName;
    this.createFormValidation();
  }

  createFormValidation() {
    this.formValidation = {
      formGroupName: ACCEPTANCES_FORMGROUP_NAME,
      formFields: {}
    };
    for (const acceptance of this.availableAcceptances) {
      this.formValidation.formFields[acceptance.name] = Validators.compose([
        acceptance.required ? Validators.requiredTrue : Validators.nullValidator
      ]);
    }
  }

  onSubmit() {
    if (this.formGroup.valid) {
      this.loading = true;
      this.accService.updateAcceptances(this.updatedAcceptances, this.userMail).subscribe(
        () => {
          this.loading = false;
          this.auth.setAcceptances(true);
          this.close();
        },
        () => {
          this.loading = false;
          this.tosr.error('Wystąpił nieznany błąd. Spróbuj ponownie później.');
          this.close();
        }
      );
    }
  }
  ngOnDestroy(): void {}

  getAcceptances(acceptances: AcceptanceType[]) {
    this.updatedAcceptances = [...acceptances];
  }
}

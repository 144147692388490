import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AutopayTermsService } from './services/autopay-terms.service';

@Component({
  selector: 'app-autopay-terms',
  templateUrl: './autopay-terms.component.html',
  styleUrl: './autopay-terms.component.scss'
})
export class AutopayTermsComponent implements OnInit {
  @Input() set paymentType(data: 'BLIK' | 'PAYMENT_CARD') {
    this._paymentType = data;

    this.autopayService.getAutopayTerms(this.paymentType).subscribe({
      next: value => {
        this.autopayTermsText = value.regulation;
      }
    })
  };

  get paymentType(): 'BLIK' | 'PAYMENT_CARD' {
    return this._paymentType;
  }

  @Output() termsAccepted: EventEmitter<boolean> = new EventEmitter<boolean>()
  termsChecked: boolean = false;
  autopayTermsText: string = '-';
  private _paymentType: 'BLIK' | 'PAYMENT_CARD' = 'BLIK';

  constructor(private autopayService: AutopayTermsService){
    this.autopayService.getAutopayTerms(this.paymentType).subscribe({
      next: value => {
        this.autopayTermsText = value.regulation;
      }
    })
  }

  ngOnInit(): void {
    this.termsAccepted.emit(false);
  }

  onTermsChanged(value: boolean): void {
    this.termsChecked = value;

    this.termsAccepted.emit(value);
  }
}

<section class="category-menu-list" *ngIf="content">
  <div class="inner-list">
    <h2 *ngIf="isTitleNeeded">{{ content.title }}</h2>
    <ul>
      <li *ngFor="let category of categoryMenu">
        <ic-service-link
          [internalUrl]="['/' + category.id.toString()]"
          [ngClass]="{ active: category === _currentCategory }"
          [type]="ServiceLinkType.CATEGORY_PAGE">
          {{
            category.categoryTitleTranslateKey
              ? (category.categoryTitleTranslateKey | translate)
              : category.categoryTitle
          }}</ic-service-link
        >
      </li>
    </ul>
  </div>
</section>

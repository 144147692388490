import { Subscription } from 'rxjs';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Step } from './step.model';
import { Extensions } from '../../extensions/extensions';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit, OnDestroy {
  @Input() public steps: Array<Step>;
  @Input() public initialStepIndex = 0;
  @Input() public dependsOnUrl = false;
  public currentStepIndex: number;
  subscription: Subscription;

  constructor(private router: Router) {}

  ngOnInit() {
    this.currentStepIndex = this.initialStepIndex;
    if (this.dependsOnUrl) {
      this.setStep(
        this.steps.findIndex((step: Step) =>
          step.route ? Extensions.regExpFromPathWithParams(step.route).test(this.router.url) : false
        )
      );
      this.subscription = this.router.events.subscribe((event: any) => {
        if (event instanceof NavigationEnd) {
          this.setStep(
            this.steps.findIndex((step: Step) =>
              step.route
                ? Extensions.regExpFromPathWithParams(step.route).test(this.router.url)
                : false
            )
          );
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (this.dependsOnUrl && this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  setStep(step: number) {
    this.currentStepIndex = Extensions.clamp(step, 0, this.steps.length - 1);
  }

  previousStep() {
    if (this.steps && this.currentStepIndex > 0) {
      this.currentStepIndex--;
    }
  }

  nextStep() {
    if (this.steps && this.currentStepIndex < this.steps.length - 1) {
      this.currentStepIndex++;
    }
  }
}

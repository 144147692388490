import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParamsBuilder } from 'src/app/shared/extensions/extensions';
import { environment as env } from 'src/environments/environment';
import {
  PikConfirmationType,
  PikPaymentConfirmationDTO
} from '../models/payment-confirmation.model';

const PAGE_PARAM = 'page';
const LIMIT_PARAM = 'limit';

@Injectable({
  providedIn: 'root'
})
export class PaymentConfirmationListService {
  constructor(private http: HttpClient) {}

  public getPaymentConfirmationList(
    type: PikConfirmationType.DRIVER | PikConfirmationType.VEHICLE,
    params: any
  ): Observable<PikPaymentConfirmationDTO> {
    const httpParams = new HttpParamsBuilder()
      .set('filter', type)
      .set(PAGE_PARAM, params.page ? params.page.toString() : null)
      .set(LIMIT_PARAM, params.limit ? params.limit.toString() : null)
      .build();

    return this.http.get<PikPaymentConfirmationDTO>(
      `${env.PIK_PAYMENT.BASE}${env.PIK_PAYMENT.DOCUMENT_PAYMENT.PURCHASE}${env.PIK_PAYMENT.DOCUMENT_PAYMENT.PAYED}`,
      { params: httpParams }
    );
  }

  public getSingleConfirmation(id: string): Observable<PikPaymentConfirmationDTO> {
    return this.http.get<PikPaymentConfirmationDTO>(
      `${env.PIK_PAYMENT.BASE}${env.PIK_PAYMENT.DOCUMENT_PAYMENT.PURCHASE}/${id}${env.PIK_PAYMENT.DOCUMENT_PAYMENT.PAYED}`
    );
  }

  public getPDFConfirmation(billId): Observable<any> {
    return this.http.get(
      `${env.PIK_PAYMENT.BASE}${env.PIK_PAYMENT.DOCUMENT_PAYMENT.PURCHASE}/${billId}${env.PIK_PAYMENT.DOCUMENT_PAYMENT.CONFIRMATION}`,
      { responseType: 'blob' as 'json' }
    );
  }
}

import { environment } from 'src/environments/environment';
import { TRAINING_CARD_ROUTE } from './applications/training-cards/_consts/training-cards-route.const';
import { ERROR_ROUTE } from './core/error/_consts/error-route.const';
import { Routes } from '@angular/router';
import { OBTAIN_TACHOGRAPH_CARD_ROUTE } from './application-landing-pages/obtain-tachograph-card/_consts/obtain-tachograph-route.const';
import { WORD_EXAM_ROUTE } from './applications/word-exam-registration/_consts/word-exam-route.const';
import { MAIN_PAGE_ROUTE } from './main-page/_consts/main-page-routing.const';
import { MY_ACCOUNT_ROUTE } from './my-account/_consts/my-account-route.const';
import { NEWS_ROUTE } from './news/_consts/new-route.const';
import { REGISTRATION_ROUTE } from './registration/_consts/registration-routes.const';
import { SEARCH_ROUTE } from './search-results/_consts/search-route.const';
import {
  ADR_ROUTE,
  SERVICE_CATEGORY_ROUTE,
  TACHOGRAPH_ROUTE
} from 'src/app/service-category/_consts/service-category-route.const';
import { LOGIN_ROUTE } from './login/_consts/login-route.const';
import { DRIVERS_LICENCE_ROUTE } from './applications/drivers-license/_consts/driver-license-routes';
import { VEHICLE_REGISTRATION_ROUTE } from './applications/vehicle-registration/_consts/vehicle-registration.routes';
import { REPORT_CARD_DAMAGE_OR_LOSS_ROUTE } from './applications/stc/report-card-damage-or-loss/_consts/report-card-damage-or-loss-routes.conts';
import { COMPLAINT_ROUTE } from './application-landing-pages/complaint/_consts/complaint-route.const';
import { STC_TACHOGRAPH_CORRECTIONS_ROUTE } from './applications/stc/_consts/stc-route.const';
import { OVERPAY_RETURN_ROUTE } from './applications/stc/overpay-return/consts/overpay-return-routes.const';

export const APP_ROUTING: Routes = [
  {
    path: SEARCH_ROUTE,
    loadChildren: () =>
      import('./search-results/search-results.module').then((m) => m.SearchResultsModule)
  },
  {
    path: MAIN_PAGE_ROUTE,
    loadChildren: () => import('./main-page/main-page.module').then((m) => m.MainPageModule)
  },
  {
    path: SERVICE_CATEGORY_ROUTE,
    loadChildren: () =>
      import('./service-category/service-category.module').then((m) => m.ServiceCategoryModule)
  },
  {
    path: LOGIN_ROUTE,
    loadChildren: () => import('./login/login.module').then((m) => m.LoginModule)
  },
  {
    path: MY_ACCOUNT_ROUTE,
    loadChildren: () => import('./my-account/my-account.module').then((m) => m.MyAccountModule)
  },
  {
    path: REGISTRATION_ROUTE,
    loadChildren: () =>
      import('./registration/registration.module').then((m) => m.RegistrationModule)
  },
  {
    path: WORD_EXAM_ROUTE,
    loadChildren: () =>
      import('./applications/word-exam-registration/word-exam-registration.module').then(
        (m) => m.WordExamRegistrationModule
      )
  },
  {
    path: TACHOGRAPH_ROUTE,
    loadChildren: () => import('./applications/stc/stc.module').then((m) => m.StcModule)
  },
  {
    path: OBTAIN_TACHOGRAPH_CARD_ROUTE,
    loadChildren: () =>
      import(
        './application-landing-pages/obtain-tachograph-card/obtain-tachograph-card.module'
      ).then((m) => m.ObtainTachographCardModule)
  },
  {
    path: COMPLAINT_ROUTE,
    loadChildren: () =>
      import('./application-landing-pages/complaint/complaint.module').then(
        (m) => m.ComplaintModule
      )
  },
  {
    path: `${TACHOGRAPH_ROUTE}/${REPORT_CARD_DAMAGE_OR_LOSS_ROUTE}`,
    loadChildren: () =>
      import(
        './applications/stc/report-card-damage-or-loss/report-card-damage-or-loss.module'
      ).then((m) => m.ReportCardDamageOrLossModule)
  },
  {
    path: '',
    loadChildren: () =>
      import('./applications/payment-confirmation/payment-confirmation.module').then(
        (m) => m.PaymentConfirmationModule
      )
  },
  {
    path: `${STC_TACHOGRAPH_CORRECTIONS_ROUTE}`,
    loadChildren: () =>
      import('./applications/stc/corrections/confirmation/confirmation.module').then(
        (m) => m.ConfirmationModule
      )
  },
  {
    path: NEWS_ROUTE,
    loadChildren: () => import('./news/news.module').then((m) => m.NewsModule)
  },
  {
    path: `${DRIVERS_LICENCE_ROUTE}`,
    loadChildren: () =>
      import('./applications/drivers-license/drivers-license.module').then(
        (m) => m.DriversLicenseModule
      )
  },
  {
    path: `${VEHICLE_REGISTRATION_ROUTE}`,
    loadChildren: () =>
      import('./applications/vehicle-registration/vehicle-registration.module').then(
        (m) => m.VehicleRegistrationModule
      )
  },
  {
    path: ADR_ROUTE,
    loadChildren: () => import('./applications/adr/adr.module').then((m) => m.AdrModule)
  },
  {
    path: TRAINING_CARD_ROUTE,
    loadChildren: () =>
      import('./applications/training-cards/training-cards.module').then(
        (m) => m.TrainingCardsModule
      )
  },
  {
    path: '',
    loadChildren: () =>
      import('./external-payments/external-payments.module').then((m) => m.ExternalPaymentsModule)
  },
  {
    path: '',
    loadChildren: () => import('./info-pages/info-pages.module').then((m) => m.InfoPagesModule)
  },
  {
    path: '**',
    redirectTo: `/${ERROR_ROUTE}/404`
  }
];

if(environment.FEATURE_TOGGLES.OVERPAY_RETURN) {
  APP_ROUTING.push(  {
    path: `${TACHOGRAPH_ROUTE}/${OVERPAY_RETURN_ROUTE}`,
    loadChildren: () =>
      import(
        './applications/stc/overpay-return/overpay-return.module'
      ).then((m) => m.OverpayReturnModule)
  },)
}
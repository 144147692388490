<div class="update-acc" *ngIf="content">
  <div class="update-acc__title">
    <h3>{{ content.title }}</h3>
  </div>
  <div class="update-acc__description">
    <p>{{ content.desc }}</p>
    <form [formGroup]="formGroup" (submit)="onSubmit()">
      <div class="update-acc__form">
        <app-acceptances-checkboxes
          *ngIf="availableAcceptances && acceptances"
          [initialAcceptances]="acceptances"
          [aggregateFormGroup]="formGroup"
          [formValidation]="formValidation"
          [missingAcceptances]="availableAcceptances"
          (emitAcceptances)="getAcceptances($event)">
        </app-acceptances-checkboxes>
      </div>

      <ic-ghost-button
        [disabled]="!formGroup.valid"
        [type]="GhostButtonType.BIG_FIRST_RANK"
        [typeHTML]="'submit'"
        [showArrow]="false"
        [width]="200"
        [justifyContent]="'center'"
        [disabled]="loading">
        <span class="forwardSpan start-btn__label">
          {{ content.save }}
        </span>
      </ic-ghost-button>
    </form>
  </div>
</div>

import { Extensions } from 'src/app/shared/extensions/extensions';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { empty, Observable, EMPTY } from 'rxjs';
import { IndividualClientDto } from 'src/app/shared/models/account-management/individual-client-dto.model';

import { ChangePasswordDto } from './account.model';
import { environment as env } from 'src/environments/environment';
import { EmployerDto } from './edit-company-data/edit-company-data.model';
import { tap, catchError } from 'rxjs/operators';
import { MODERNIZATION_WORK_ROUTE } from 'src/app/info-pages/_consts/info-pages-route.const';
import { ACCOUNTS_V1, STC_V1 } from 'src/app/shared/consts/api-version.config';

const baseUrl = env.BACKEND_API.BASE_ACCOUNT_MANAGMENT;

@Injectable({
  providedIn: 'root'
})
export class AccountApiService {
  private readonly headers = new HttpHeaders().set('Content-Type', ACCOUNTS_V1);

  private stcHeaders = new HttpHeaders().set('Content-Type', STC_V1);

  constructor(private http: HttpClient, private router: Router) {}

  public changePassword(passwordDto: ChangePasswordDto): Observable<any> {
    return this.http.put(`${baseUrl}/account-management/change-password`, passwordDto, {
      headers: this.headers
    });
  }

  public getIndividualClientBy(email: string): Observable<IndividualClientDto> {
    return this.http.get<IndividualClientDto>(
      `${baseUrl}/account-management/individual-client/${email}`
    );
  }

  public updateIndividualClientBy(uid: string, payload: IndividualClientDto): Observable<any> {
    return this.http.put<any>(`${baseUrl}/account-management/individual-client/${uid}`, payload, {
      headers: this.headers
    });
  }

  public deleteClientAccount(password: string): Observable<any> {
    return this.http.request('DELETE', `${baseUrl}/account-management/delete-client`, {
      body: { password: password },
      headers: this.headers
    });
  }

  public updateBusinessClientBy(uid: string, payload: any) {
    return this.http.put(`${baseUrl}/account-management/business-client/stc/${uid}`, payload, {
      headers: this.headers
    });
  }

  public getEmployerDetailsBy(uuid: string): Observable<EmployerDto> {
    return this.http.get<EmployerDto>(
      `${env.BACKEND_API.BASE}${env.BACKEND_API.EMPLOYERS}/${uuid}`
    );
  }
}

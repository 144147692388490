import { Observable, Subject } from 'rxjs';

export const MODAL_OVERLAY_CLICKED = 'overlay_clicked';

export class ModalRef {
  constructor(config: any = null) {
    this.data = config.data;
  }
  public data: any;

  private readonly _afterClosed = new Subject<any>();
  afterClosed: Observable<any> = this._afterClosed.asObservable();

  close(result?: any) {
    if (!this.shouldClose(result)) {
      return;
    } else {
      this._afterClosed.next(result);
      this._afterClosed.complete();
    }
  }

  shouldClose(closingData): boolean {
    // add "freezeOveraly: true" to modal config to prevent from closing on overlay click
    if (
      this.data &&
      this.data.hasOwnProperty('freezeOverlay') &&
      this.data.freezeOverlay &&
      closingData === MODAL_OVERLAY_CLICKED
    ) {
      return false;
    } else {
      return true;
    }
  }
}

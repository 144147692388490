import { OVERPAY_RETURN_ROUTE } from './../../applications/stc/overpay-return/consts/overpay-return-routes.const';
import {
  FIND_DISTRICT_OFFICE,
  FIND_OSK,
  FIND_WORD
} from './../../service-category/_consts/service-category-route.const';
import { CHECK_EXAM_AVAILABILITY_ROUTE } from './../../applications/drivers-license/_consts/driver-license-routes';
import { CHECKING_REGISTRATION_DOCUMENT_STATUS_ROUTE } from './../../applications/vehicle-registration/checking-registration-document-status/_consts/checking-registration-document-status.routes';
import { TRAINING_CARD_ROUTE } from './../../applications/training-cards/_consts/training-cards-route.const';
import { OBTAIN_TACHOGRAPH_CARD_ROUTE } from 'src/app/application-landing-pages/obtain-tachograph-card/_consts/obtain-tachograph-route.const';
import { CHECKING_STC_APPLICATION_STATUS_ROUTE } from 'src/app/applications/stc/checking-stc-application-status/_consts/checking-stc-application-status-route.const';
import { LOGIN_ROUTE } from 'src/app/login/_consts/login-route.const';
import { MY_ACCOUNT_ROUTE } from 'src/app/my-account/_consts/my-account-route.const';
import { NEWS_ROUTE } from 'src/app/news/_consts/new-route.const';
import { REGISTRATION_ROUTE } from 'src/app/registration/_consts/registration-routes.const';
import { ADR_CLASSES_ROUTE } from 'src/app/service-category/services-info-pages/adr/adr-classes/_const/adr-classes-route';
import { DRIVER_LICENSE_CATEGORIES } from 'src/app/service-category/services-info-pages/driver-license/driver-license-categories/_const/driver-license-categories-route.const';
import { FAQ_ROUTE } from 'src/app/service-category/services-info-pages/driver-license/faq/_consts/faq-route.const';
import { RULES_FOR_OBTAINING_PERMISSIONS_ROUTE } from 'src/app/service-category/services-info-pages/driver-license/rules-for-obtaining-permissions/_consts/rules-for-obtaining-permissions-route.const';
import { THEORETICAL_EXAM_INFORMATION_ROUTE } from 'src/app/service-category/services-info-pages/driver-license/theoretical-exam-information/_consts/theoretical-exam-information-route.const';
import { CONTACT_ROUTE } from 'src/app/service-category/services-info-pages/tachograph/contact/_consts/contact-route.const';
import { LEGAL_REGULATION_ROUTE } from 'src/app/service-category/services-info-pages/tachograph/legal-regulations/_consts/legal-regulations-route.const';
import { PARTNERS_ROUTE } from 'src/app/service-category/services-info-pages/tachograph/partners/_consts/partners-route.const';
import { VEHICLE_REGISTRATION_FAQ_ROUTE } from 'src/app/service-category/services-info-pages/vehicle-registration/vehicle-registration-faq/_consts/vehicle-registration-faq.route';
import { VEHICLE_REGISTRATION_PROCESS_DESCRIPTION_ROUTE } from 'src/app/service-category/services-info-pages/vehicle-registration/vehicle-registration-process-description/_consts/vehicle-registration-process-description.route';
import {
  ADR_EXAM_ROUTE,
  ADR_ROUTE,
  CERTIFICATE_RULES_ROUTE,
  COMPLAINT_ROUTE,
  DOCUMENT_INFORMATION_ROUTE,
  DRIVER_LICENSE_PAYMENT,
  DRIVER_LICENSE_ROUTE,
  TACHOGRAPH_ROUTE,
  VEHICLE_REGISTRATION_ROUTE,
  VEHICLE_TEMPORARY_WITHDRAWAL
} from 'src/app/service-category/_consts/service-category-route.const';
import {
  CHECK_PKK_STATUS_ROUTE,
  CHECK_DRIVERS_LICENSE_STATUS_ROUTE,
  DRIVERS_LICENCE_ROUTE
} from 'src/app/applications/drivers-license/_consts/driver-license-routes';
import { ADR_CHECKING_STATUS_ROUTE } from 'src/app/applications/adr/_consts/adr-routes.const';
import {
  TERMS_OF_SERVICE_ROUTE,
  ABOUT_US,
  DOWNLOAD_TACHO_APPLICATIONS,
  TACHOGRAPH_UA_FAQ,
  DOWNLOAD_TACHO_FORMS
} from 'src/app/info-pages/_consts/info-pages-route.const';
import {
  WORD_EXAM_PROCESS_ROUTE,
  WORD_EXAM_ROUTE
} from 'src/app/applications/word-exam-registration/_consts/word-exam-route.const';
import { TRAINING_INFO_ROUTE } from 'src/app/applications/training-cards/_consts/training-informaion-route.const';
import { DL_LEGAL_REGULATION_ROUTE } from 'src/app/service-category/services-info-pages/driver-license/dl-legal-regulations/_consts/dl-legal-regulations-route.const';
import { REPORT_CARD_DAMAGE_OR_LOSS_ROUTE } from 'src/app/applications/stc/report-card-damage-or-loss/_consts/report-card-damage-or-loss-routes.conts';
import { RULES_FOR_OBTAINING_PROFESSIONAL_PERMISSIONS_ROUTE } from 'src/app/service-category/services-info-pages/driver-license/rules-for-obtaining-professional-permissions/_consts/rules-for-obtaining-professional-permissions-route.const';

export const DESTINATION_DICTIONARY = new Map<string, string>([
  ['tachograph_card', `/${OBTAIN_TACHOGRAPH_CARD_ROUTE}`],
  ['tachograph_check_stc_status', `/${TACHOGRAPH_ROUTE}/${CHECKING_STC_APPLICATION_STATUS_ROUTE}`],
  ['tachograph_document_information', `/${TACHOGRAPH_ROUTE}/${DOCUMENT_INFORMATION_ROUTE}`],
  [
    'tachograph_report_card_damage_or_loss',
    `/${TACHOGRAPH_ROUTE}/${REPORT_CARD_DAMAGE_OR_LOSS_ROUTE}`
  ],
  ['tachograph_complaint', `/${TACHOGRAPH_ROUTE}/${COMPLAINT_ROUTE}`],
  ['tachograph_legal_regulations', `/${TACHOGRAPH_ROUTE}/${LEGAL_REGULATION_ROUTE}`],
  ['tachograph_contact', `/${TACHOGRAPH_ROUTE}/${CONTACT_ROUTE}`],
  ['forms', `/${TACHOGRAPH_ROUTE}/${DOWNLOAD_TACHO_FORMS}`],
  ['tachograph_partners', `/${TACHOGRAPH_ROUTE}/${PARTNERS_ROUTE}`],
  ['tachograph_test_cards', `/${TRAINING_CARD_ROUTE}/${TRAINING_INFO_ROUTE}`],
  ['tachograph_ua_faq', `/${TACHOGRAPH_ROUTE}/${TACHOGRAPH_UA_FAQ}`],
  [
    'vehicle_registration_document_information',
    `/${VEHICLE_REGISTRATION_ROUTE}/${DOCUMENT_INFORMATION_ROUTE}`
  ],
  ['vehicle_registration_document_payment', `/${VEHICLE_REGISTRATION_ROUTE}/powod`],
  ['vehicle_registration_faq', `/${VEHICLE_REGISTRATION_ROUTE}/${VEHICLE_REGISTRATION_FAQ_ROUTE}`],
  ['word_egzam', `/${WORD_EXAM_ROUTE}/${WORD_EXAM_PROCESS_ROUTE}`],
  [
    'vehicle_registration_temporary_withdrawal',
    `/${VEHICLE_REGISTRATION_ROUTE}/${VEHICLE_TEMPORARY_WITHDRAWAL}`
  ],
  [
    'vehicle_registration_process_description',
    `/${VEHICLE_REGISTRATION_ROUTE}/${VEHICLE_REGISTRATION_PROCESS_DESCRIPTION_ROUTE}`
  ],
  ['adr_document_information', `/${ADR_ROUTE}/${DOCUMENT_INFORMATION_ROUTE}`],
  ['adr_classes', `/${ADR_ROUTE}/${ADR_CLASSES_ROUTE}`],
  ['adr_exam', `/${ADR_ROUTE}/${ADR_EXAM_ROUTE}`],
  ['adr_certificate_rules', `/${ADR_ROUTE}/${CERTIFICATE_RULES_ROUTE}`],
  ['adr_status', `/${ADR_ROUTE}/${ADR_CHECKING_STATUS_ROUTE}`],
  ['driver_license_FAQ', `/${DRIVERS_LICENCE_ROUTE}/${FAQ_ROUTE}`],
  ['driver_license_legal_regulation', `/${DRIVERS_LICENCE_ROUTE}/${DL_LEGAL_REGULATION_ROUTE}`],
  ['driver_license_document_information', `/${DRIVER_LICENSE_ROUTE}/${DOCUMENT_INFORMATION_ROUTE}`],
  ['driver_license_categories', `/${DRIVERS_LICENCE_ROUTE}/${DRIVER_LICENSE_CATEGORIES}`],
  [
    'driver_license_theoretical_exam_information',
    `/${DRIVER_LICENSE_ROUTE}/${THEORETICAL_EXAM_INFORMATION_ROUTE}`
  ],
  [
    'driver_license_rules_for_obtaining_permissions',
    `/${DRIVER_LICENSE_ROUTE}/${RULES_FOR_OBTAINING_PERMISSIONS_ROUTE}`
  ],
  [
    'driver_license_rules_for_obtaining_professional_permissions',
    `/${DRIVER_LICENSE_ROUTE}/${RULES_FOR_OBTAINING_PROFESSIONAL_PERMISSIONS_ROUTE}`
  ],
  ['driver_license_word_exam', `/${DRIVER_LICENSE_ROUTE}/${WORD_EXAM_PROCESS_ROUTE}`],
  [
    'driver_license_word_exam_check_availability',
    `/${DRIVER_LICENSE_ROUTE}/${CHECK_EXAM_AVAILABILITY_ROUTE}`
  ],
  ['login', `/${LOGIN_ROUTE}`],
  ['register', `/${REGISTRATION_ROUTE}`],
  ['regulations', `/${TERMS_OF_SERVICE_ROUTE}`],
  ['account', `${MY_ACCOUNT_ROUTE}`],
  ['news', `${NEWS_ROUTE}`],
  ['terms_of_service', `${TERMS_OF_SERVICE_ROUTE}`],
  ['about_page', `${ABOUT_US}`],
  ['applications', `${DOWNLOAD_TACHO_APPLICATIONS}`],
  ['applications_article', `aktualnosci/2315`],
  ['word_price_article', `aktualnosci/2323`],
  ['tacho_card_info_article', `aktualnosci/2324`],
  ['g2v2_info_article', `aktualnosci/2325`],
  ['new_stc_applications_article', `aktualnosci/2326`],
  ['basket_disable_article', `aktualnosci/2327`],
  ['duedate_article', `aktualnosci/2317`],
  ['covid_article', `aktualnosci/2316`],
  ['word_informations', `/egzamin_prawa_jazdy`],
  ['check-pkk-status', `/${DRIVER_LICENSE_ROUTE}/${CHECK_PKK_STATUS_ROUTE}`],
  [
    'check-drivers-license-status',
    `/${DRIVER_LICENSE_ROUTE}/${CHECK_DRIVERS_LICENSE_STATUS_ROUTE}`
  ],
  [
    'check_registration_document_status',
    `/${VEHICLE_REGISTRATION_ROUTE}/${CHECKING_REGISTRATION_DOCUMENT_STATUS_ROUTE}`
  ],
  ['contact', `/${CONTACT_ROUTE}`],
  ['find_osk', `/${DRIVERS_LICENCE_ROUTE}/${FIND_OSK}`],
  ['find_word', `/${DRIVERS_LICENCE_ROUTE}/${FIND_WORD}`],
  ['find_district_office', `/${DRIVERS_LICENCE_ROUTE}/${FIND_DISTRICT_OFFICE}`],
  ['driver-license-payments', `/${DRIVERS_LICENCE_ROUTE}/${DRIVER_LICENSE_PAYMENT}`]
]);

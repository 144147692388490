import { Extensions } from 'src/app/shared/extensions/extensions';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, take, tap, filter } from 'rxjs/operators';

@Injectable()
export class AuthGuard  {

  constructor(private auth: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {

    return this.auth.state$.pipe(
      filter((authState) => authState.status !== 'loading'),
      take(1),
      tap((authState) => {
        const isAnonymous =
          route.data.passFlag &&
          (!!Extensions.getCookie('anonymousToken') || !!sessionStorage.getItem('anonymousToken')) &&
          authState?.anonymous;
        if (authState.status === 'initialized' && !authState.user && !isAnonymous) {
          this.auth.login(state.url);
        }
      }),
      map((authState) => !!authState.user || authState.anonymous)
    );
  }
}

import { ObtainCardType } from './../../application-landing-pages/obtain-tachograph-card/_consts/obtain-card.const';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { User } from '../model/user.model';
import { USER_ROLES } from '../_consts/user-roles.const';
import { AuthQueryService } from './auth-query.service';
import { AuthActions } from './auth.actions';
import * as fromAuth from './auth.reducer';
import { AuthState } from './auth.reducer';
import { ComplaintCardType } from 'src/app/application-landing-pages/complaint/_consts/complaint.const';

/**
 * @description
 *
 * User authentication and authorization service
 * @publicApi
 */

@Injectable()
export class AuthService {
  /**
   * @description
   *
   * Hot observable; Emits currently logged in user or null otherwise
   *
   * @usageNotes
   *
   * Example:
   *
   * ```
   * // continuous stream
   * authService.user$.subscribe( user => { ... } );
   *
   * // current user state
   * authService.user$.pipe(
   *  first()
   * ).subscribe( user => { ... } );
   *
   * ```
   */
  public user$: Observable<User> = this.authQuery.user$;

  public state$: Observable<AuthState> = this.authQuery.state$;

  constructor(private authStore: Store<fromAuth.AuthState>, private authQuery: AuthQueryService) {}

  static isIndividualUser(user: User): boolean {
    if (!user) {
      return false;
    }
    return user.roles.find((role) => role === USER_ROLES.ROLE_USER) && user.roles.length === 1;
  }

  static isBusinessUser(user: User): boolean {
    if (!user) {
      return false;
    }
    return user.roles.find((role) => role === USER_ROLES.ROLE_BUSINESS) != null;
  }

  /**
   * @param url optional path to redirect client after successful login
   *
   * @usageNotes
   *
   * Example:
   *
   * ```
   *
   * // login with redirect to home page
   * authService.login();
   *
   * // login with redirect to '/custom/path'
   * authService.login('/custom/path');
   *
   * ```
   */
  public login(url?: string) {
    this.authStore.dispatch(new AuthActions.Login({ url }));
  }

  public clearAuth() {
    this.authStore.dispatch(new AuthActions.ClearAuth())
  }

  public disableAnonymous() {
    this.authStore.dispatch(new AuthActions.DisableAnonymous())
  }

  public logout() {
    this.authStore.dispatch(new AuthActions.Logout());
  }

  public retry() {
    this.authStore.dispatch(new AuthActions.AuthInit());
  }

  public refreshUser() {
    this.authStore.dispatch(new AuthActions.RefreshUser());
  }

  public loginAnonymousUser() {
    this.authStore.dispatch(new AuthActions.AnonymousLogin());
  }

  public setAcceptances(hasAcc: boolean) {
    this.authStore.dispatch(new AuthActions.SetAcceptances({ hasAcc }));
  }
}

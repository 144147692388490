export enum GhostButtonType {
  BIG_FIRST_RANK = 'BIG_FIRST_RANK',
  BIG_FIRST_RANK_FLEX = 'BIG_FIRST_RANK_FLEX',
  BIG_SECOND_RANK = 'BIG_SECOND_RANK',
  BIG_SECOND_RANK_FLEX = 'BIG_SECOND_RANK_FLEX',
  SMALL_FIRST_RANK = 'SMALL_FIRST_RANK',
  SMALL_FIRST_RANK_FLEX = 'SMALL_FIRST_RANK_FLEX',
  SMALL_SECOND_RANK = 'SMALL_SECOND_RANK',
  SMALL_SECOND_RANK_FLEX = 'SMALL_SECOND_RANK_FLEX',
  SLIDER = 'SLIDER',
  SLIDER_CONTROL = 'SLIDER_CONTROL',
  FOOTER_OTHER_PRODUCTS = 'FOOTER_OTHER_PRODUCTS',
  WORD_EXAM_SMALL_FLEX = 'WORD_EXAM_SMALL_FLEX',
  SIMPLE_LINK = 'SIMPLE_LINK'
}

import {TranslateService} from '@ngx-translate/core';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GhostButtonType} from '../ghost-button/ghost-button-type.enum';
import {isNullOrUndefined} from '../../extensions/extensions';

@Component({
  selector: 'app-two-way-action-bar',
  templateUrl: './two-way-action-bar.component.html',
  styleUrls: ['./two-way-action-bar.component.scss']
})
export class TwoWayActionBarComponent implements OnInit {
  GhostButtonType: typeof GhostButtonType = GhostButtonType;

  @Output() submit = new EventEmitter<any>();
  @Output() navigateBack = new EventEmitter<any>();
  @Input() reasonStep: false;
  @Input() backButtonRoute: string;
  @Input() backwardButtonDisabled: boolean;
  @Input() forwardButtonDisabled: boolean;
  @Input() backwardButtonLabel;
  @Input() desktopForwardButtonLabel;
  @Input() mobileForwardButtonLabel;
  @Input() isMobileLabelsDifferentOnDesktop = false;
  @Input() marginTop: number;
  @Input() marginBottom: number;
  @Input() mobileMarginTop: number;
  @Input() mobileMarginBottom: number;
  @Input() forwardDesktopWidth = 202;
  @Input() forwardMobileWidth = 135;
  @Input() icPageContainer = true;

  constructor(private translateService: TranslateService) {}

  ngOnInit() {
    this.translateService.get('SHARED.TWO_WAY_BAR').subscribe({
      next: (response) => {
        if (isNullOrUndefined(this.backwardButtonLabel)) {
          this.backwardButtonLabel = response.backwardButtonLabel;
        }
        if (isNullOrUndefined(this.desktopForwardButtonLabel)) {
          this.desktopForwardButtonLabel = response.desktopForwardButtonLabel;
        }
        if (isNullOrUndefined(this.mobileForwardButtonLabel)) {
          this.mobileForwardButtonLabel = response.mobileForwardButtonLabel;
        }
      }
    });
  }

  onSubmit() {
    this.submit.emit();
  }

  onNavigateBack() {
    this.navigateBack.emit();
  }

  checkIsMobile(): boolean {
    if (window.innerWidth <= 576) {
      return true;
    } else {
      return false;
    }
  }
  setWidthBaseOnWidth(): number {
    if (this.isMobileLabelsDifferentOnDesktop) {
      if (window.innerWidth <= 576) {
        return this.forwardMobileWidth;
      } else {
        return this.forwardDesktopWidth;
      }
    } else {
      return this.forwardMobileWidth;
    }
  }
  setLabelBaseOnWidth(): string {
    if (this.checkIsMobile()) {
      return this.mobileForwardButtonLabel;
    } else {
      return this.desktopForwardButtonLabel;
    }
  }
}

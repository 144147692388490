import {
  Component,
  Type,
  ComponentFactoryResolver,
  ViewChild,
  OnDestroy,
  ComponentRef,
  AfterViewInit,
  ChangeDetectorRef,
  ElementRef,
  OnInit
} from '@angular/core';
import { InsertionDirective } from './insertion.directive';
import { Subject } from 'rxjs';
import { ModalRef, MODAL_OVERLAY_CLICKED } from './modal-ref';
import { BaseModalDirective } from './base-modal';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements AfterViewInit, OnDestroy, OnInit {
  componentRef: ComponentRef<BaseModalDirective>;

  @ViewChild(InsertionDirective) insertionPoint: InsertionDirective;
  @ViewChild('modal') modal: ElementRef;

  private readonly _onClose = new Subject<any>();
  public onClose = this._onClose.asObservable();

  childComponentType: Type<any>;

  blueOverlay = false;
  whiteOverlay = false;
  maxHeight = false;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private cd: ChangeDetectorRef,
    private modalRef: ModalRef
  ) {
    if (this.modalRef.data && this.modalRef.data.hasOwnProperty('blueOverlay')) {
      this.blueOverlay = this.modalRef.data.blueOverlay;
    }
    if (this.modalRef.data && this.modalRef.data.hasOwnProperty('whiteOverlay')) {
      this.whiteOverlay = this.modalRef.data.whiteOverlay;
    }
    if (this.modalRef.data && this.modalRef.data.hasOwnProperty('maxHeight')) {
      this.maxHeight = this.modalRef.data.maxHeight;
    }
  }

  ngOnInit() {
    setTimeout(() => {
      this.modal.nativeElement.focus();
    });
  }
  ngAfterViewInit() {
    this.loadChildComponent(this.childComponentType);
    this.cd.detectChanges();
  }

  onOverlayClicked(evt: MouseEvent) {
    this.modalRef.close(MODAL_OVERLAY_CLICKED);
  }

  onModalClicked(evt: MouseEvent) {
    evt.stopPropagation();
  }

  loadChildComponent(componentType: Type<BaseModalDirective>) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);

    const viewContainerRef = this.insertionPoint.viewContainerRef;
    viewContainerRef.clear();

    this.componentRef = viewContainerRef.createComponent(componentFactory);
    this.componentRef.instance.ref = this.modalRef;
  }

  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  close() {
    this._onClose.next(null);
  }
}

<div class="training-card-info">
  <section class="training-card-info__header">
    <h2>{{ 'TRAINING_CARDS.MODAL.title' | translate }}</h2>
    <button
      (click)="onClose()"
      [attr.aria-label]="'TRAINING_CARDS.MODAL.buttonCloseAria' | translate"
      class="close-btn">
      <svg-icon class="close-btn-icon" src="assets/images/close_mobile_.svg"></svg-icon>
    </button>
  </section>

  <section class="training-card-info__body">
    <p>{{ 'TRAINING_CARDS.MODAL.description' | translate }}</p>
  </section>

  <section class="training-card-info__buttons">
    <ic-ghost-button
      (click)="onClose()"
      [width]="135"
      [type]="GhostButtonType.BIG_SECOND_RANK"
      [justifyContent]="'center'"
      >{{ 'TRAINING_CARDS.MODAL.buttonCancel' | translate }}</ic-ghost-button
    >
    <ic-ghost-button [justifyContent]="'center'" [width]="135" (click)="moveForward()">{{
      'TRAINING_CARDS.MODAL.buttonAccept' | translate
    }}</ic-ghost-button>
  </section>
</div>

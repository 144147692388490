import { SharedModule } from 'src/app/shared/shared.module';
import { ERROR_ROUTING } from './_consts/error-routing.const';
import { NgModule, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorComponent } from './error.component';
import { ErrorsHandler } from './errors-handler/errors-handler';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [ErrorComponent],
  imports: [CommonModule, SharedModule, RouterModule.forChild([ERROR_ROUTING])],
  providers: [
    {
      provide: ErrorHandler,
      useClass: ErrorsHandler
    }
  ],
  exports: [RouterModule]
})
export class ErrorModule {}

import { Injectable } from '@angular/core';
import {
  PRIMARY_OUTLET,
  Router,
  RoutesRecognized,
  UrlSegment,
  UrlSegmentGroup,
  UrlTree
} from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter, pairwise } from 'rxjs/operators';
import { Location } from '@angular/common';
import { STC_TACHOGRAPH_APPLICATIONS_ROUTE } from 'src/app/applications/stc/_consts/stc-route.const';

@Injectable()
export class RouteEventsService {
  public previousRoutePath = new BehaviorSubject<string>('');
  public formOriginPath = new BehaviorSubject<string>('');

  constructor(private router: Router, private location: Location) {
    this.previousRoutePath.next(this.location.path());
    this.router.events
      .pipe(
        filter((e) => e instanceof RoutesRecognized),
        pairwise()
      )
      .subscribe((event: any[]) => {
        this.previousRoutePath.next(event[0].urlAfterRedirects);

        this.setFormOriginPath(router);
      });
  }

  private setFormOriginPath(router: Router) {
    const tree: UrlTree = router.parseUrl(this.previousRoutePath.value);
    const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
    if (g) {
      const s: UrlSegment[] = g.segments;
      if (s[0] && s[1]) {
        const applicationUrlFragment = s[0].path + '/' + s[1].path;
        if (!STC_TACHOGRAPH_APPLICATIONS_ROUTE.includes(applicationUrlFragment)) {
          this.formOriginPath.next(this.previousRoutePath.value);
        }
      } else {
        this.formOriginPath.next(this.previousRoutePath.value);
      }
    } else {
      this.formOriginPath.next(this.previousRoutePath.value);
    }
  }
}

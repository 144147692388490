import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { BaseModalDirective } from 'src/app/shared/components/modal/base-modal';

import { Translation } from '../../../models/search/translation.model';
import * as fromShared from '../../../store/shared.reducer';

@Component({
  selector: 'app-search-modal',
  templateUrl: './search-modal.component.html',
  styleUrls: ['./search-modal.component.scss']
})
export class SearchModalComponent extends BaseModalDirective implements OnInit, OnDestroy {
  translation: Translation;
  subscription: Subscription;

  constructor(private servicesStore: Store<fromShared.SharedState>) {
    super();
    this.subscription = this.servicesStore
      .select(fromShared.selectSearchServicesData)
      .subscribe((result) => (this.translation = result));
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  close(): void {
    this.ref.close();
  }
}

export interface ComplaintReasonsResponse {
  count: number;
  reasons: ComplaintSingleReason[];
}

export interface ComplaintSingleReason {
  message: string;
  reasonType: string;
}

export enum ComplaintReasons {
  INCORRECT_DATA = 'INCORRECT_DATA',
  NOT_WORKING = 'NOT_WORKING',
  OTHER = 'OTHER'
}

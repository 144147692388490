import { GhostButtonType } from './../ghost-button/ghost-button-type.enum';
import { Component, HostListener, Input, OnInit } from '@angular/core';
import { PromotionalBoxContentModel } from './models/promotional-box.model';
import { isNullOrUndefined } from '../../extensions/extensions';

@Component({
  selector: 'app-promotional-box',
  templateUrl: './promotional-box.component.html',
  styleUrls: ['./promotional-box.component.scss']
})
export class PromotionalBoxComponent implements OnInit {
  @Input() content: PromotionalBoxContentModel;
  isMobile: boolean;

  constructor() {}

  ngOnInit(): void {
    isNullOrUndefined(this.content.style) ? (this.content.style = 'default') : null;
    isNullOrUndefined(this.content.direction) ? (this.content.direction = 'vertical') : null;
    this.checkIsMobile();
  }

  @HostListener('window:resize', ['$event'])
  checkIsMobile() {
    if (window.innerWidth <= 576) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }
}

import { TACHOGRAPH_ROUTE } from 'src/app/service-category/_consts/service-category-route.const';
import { STC_COMPLAINT_APPLICATION_ROUTE } from 'src/app/application-landing-pages/complaint/_consts/complaint-route.const';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import fileType from 'file-type';
import { ToastrService } from 'ngx-toastr';
import { COMPANY_CARD_APPLICATION_PERSONAL_DATA_PARAMETRIZED_ROUTE } from 'src/app/applications/stc/company-card-application/personal-data/_consts/personal-data.route.const';
import { COMPANY_CARD_APPLICATION_ROUTE } from 'src/app/applications/stc/company-card-application/_consts/company-card-application.route.const';
import { CONTROL_CARD_APPLICATION_PERSONAL_DATA_PARAMETRIZED_ROUTE } from 'src/app/applications/stc/control-card-application/personal-data/_consts/personal-data.route.const';
import { CONTROL_CARD_APPLICATION_ROUTE } from 'src/app/applications/stc/control-card-application/_consts/control-card-application.route.const';
import { DRIVER_CARD_APPLICATION_PERSONAL_DATA_PARAMETRIZED_ROUTE } from 'src/app/applications/stc/driver-card-application/personal-data/_consts/personal-data.route.const';
import { DRIVER_CARD_APPLICATION_ROUTE } from 'src/app/applications/stc/driver-card-application/_consts/driver-card-application.route.const';
import { TEMPORARY_DRIVER_CARD_APPLICATION_PERSONAL_DATA_PARAMETRIZED_ROUTE } from 'src/app/applications/stc/temporary-driver-card-application/personal-data/_consts/personal-data.route.const';
import { TEMPORARY_DRIVER_CARD_APPLICATION_ROUTE } from 'src/app/applications/stc/temporary-driver-card-application/_consts/temporary-driver-card-application.route.const';
import { WORKSHOP_CARD_APPLICATION_PERSONAL_DATA_PARAMETRIZED_ROUTE } from 'src/app/applications/stc/workshop-card-application/personal-data/_consts/personal-data.route.const';
import { WORKSHOP_CARD_APPLICATION_ROUTE } from 'src/app/applications/stc/workshop-card-application/_consts/workshop-card-application.route.const';
import { STC_TACHOGRAPH_APPLICATIONS_ROUTE } from 'src/app/applications/stc/_consts/stc-route.const';
import { CreatedPaperApplicationToastComponent } from '../components/created-paper-application-toast/created-paper-application-toast.component';
import { FirstTimeAutosaveToastrComponent } from '../components/first-time-autosave-toastr/first-time-autosave-toastr.component';
import { ApplicationDto } from '../models/applications/application-dto.model';

export const SECOND = 1000;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;

export abstract class Extensions {
  public static clamp(number: number, lower: number, upper: number): number {
    if (isNaN(number) || isNullOrUndefined(number)) {
      throw new Error('The number argument must be a proper number');
    }
    if (isNaN(lower) || isNaN(upper) || isNullOrUndefined(lower) || isNullOrUndefined(upper)) {
      throw new Error('Bounds must be proper numbers');
    }
    return number <= upper ? (number >= lower ? number : lower) : upper;
  }

  public static zip(arrays: Array<Array<any>>, labels: Array<string>): Array<any> {
    if (
      isNullOrUndefined(arrays) ||
      isNullOrUndefined(labels) ||
      arrays.length === 0 ||
      labels.length === 0
    ) {
      return null;
    }

    if (arrays.length !== labels.length) {
      throw new Error('Number of arrays must be equal to number of labels');
    }

    const length = arrays[0].length;
    arrays.forEach((array: Array<any>) => {
      if (isNullOrUndefined(array)) {
        throw new Error('Arrays to zip must all be defined');
      }
      if (array.length !== length) {
        throw new Error('Arrays must be of equal length');
      }
    });

    const result = [];
    for (let elementIndex = 0; elementIndex < length; elementIndex++) {
      const item = {};
      for (let labelIndex = 0; labelIndex < labels.length; labelIndex++) {
        item[labels[labelIndex]] = arrays[labelIndex][elementIndex];
      }
      result.push(item);
    }
    return result;
  }

  public static regExpFromPathWithParams(path: string): RegExp {
    if (isNullOrUndefined(path)) {
      throw new Error('Path must be defined');
    }
    return new RegExp(path.replace(/:\w+/g, '[^/]+'));
  }

  public static replacePlaceholders(path: string, args: string[]): string {
    if (isNullOrUndefined(path)) {
      throw new Error('Path must be defined');
    }

    if (isNullOrUndefined(args)) {
      throw new Error('Arguments must be defined');
    }

    args.forEach((arg: string) => {
      path = path.replace(/:\w+/, arg);
    });
    return path;
  }

  public static simpleObjectsEqual(object: any, other: any): boolean {
    if ((object === null && other === null) || (object === undefined && other === undefined)) {
      return true;
    }

    if (object === null || object === undefined || other === null || other === undefined) {
      return false;
    }

    if (!(object instanceof Object) && !(other instanceof Object)) {
      return object === other;
    }

    if (!(object instanceof Object) || !(other instanceof Object)) {
      return false;
    }

    const entries1 = Object.entries(object);
    const entries2 = Object.entries(other);

    if (entries1.length !== entries2.length) {
      return false;
    }

    for (let i = 0; i < entries1.length; i++) {
      const [key1, value1] = entries1[i];
      const [key2, value2] = entries2[i];
      if (key1 !== key2 || value1 !== value2) {
        return false;
      }
    }

    return true;
  }

  public static scrollToFirstInvalidInput() {
    setTimeout(() => {
      const firstElementWithError = document.querySelector(
        'div.custom-error-container, app-form-checkbox.invalid, app-selectable-input.invalid, textarea.invalid, input.invalid, select.invalid, app-form-input.invalid, app-attachments-input.invalid, label.invalid'
      );
      if (firstElementWithError) {
        firstElementWithError.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      }
    }, 0);
  }

  public static deepMerge(...args) {
    // Variables
    const target = {};

    // Merge the object into the target object
    const merger = (obj) => {
      for (const prop in obj) {
        if (obj.hasOwnProperty(prop)) {
          if (Object.prototype.toString.call(obj[prop]) === '[object Object]') {
            // If we're doing a deep merge and the property is an object
            target[prop] = Extensions.deepMerge(target[prop], obj[prop]);
          } else {
            // Otherwise, do a regular merge
            target[prop] = obj[prop];
          }
        }
      }
    };

    // Loop through each object and conduct a merge
    for (let i = 0; i < arguments.length; i++) {
      merger(arguments[i]);
    }

    return target;
  }

  public static depolishify(text: string): string {
    const translate_regex = /[ąćęłńóśźżĄĆĘŁŃÓŚŹŻ]/g;
    const translate = {
      ą: 'a',
      ć: 'c',
      ę: 'e',
      ł: 'l',
      ń: 'n',
      ó: 'o',
      ś: 's',
      ź: 'z',
      ż: 'z',
      Ą: 'A',
      Ć: 'C',
      Ę: 'E',
      Ł: 'L',
      Ń: 'N',
      Ó: 'O',
      Ś: 'S',
      Ź: 'Z',
      Ż: 'Z'
    };
    return text.replace(translate_regex, (match) => translate[match]);
  }

  public static trimForm(formGroup: UntypedFormGroup): void {
    Object.keys(formGroup.controls).forEach((key) => {
      if (formGroup.get(key)) {
        if (formGroup.get(key)['controls']) {
          Extensions.trimForm(formGroup.get(key) as UntypedFormGroup);
        } else {
          const formValue = formGroup.get(key).value;
          if (formValue && typeof formValue === 'string') {
            formGroup.get(key).setValue(formValue.trim());
          }
        }
      }
    });
  }

  public static setCookie(name: string, val: string, isExpiring?: boolean) {
    const value = val;
    let expires = '';

    if (isExpiring) {
      const date = new Date();
      date.setTime(date.getTime() + 1 * DAY);
      expires = `expires=+${date.toUTCString()};`;
    }

    // Set cookie
    document.cookie = name + '=' + value + '; ' + expires + ' path=/';
  }

  public static getCookie(name: string) {
    const value = '; ' + document.cookie;
    const parts = value.split('; ' + name + '=');

    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    } else {
      return null;
    }
  }

  public static async checkIfMimeTypeAllowedToOpenCropperModal(
    file: File
  ): Promise<{ isAllowed: boolean; mimeType: string }> {
    const notAllowedMimes = ['application/pdf', 'image/tiff'];
    let isAllowed;
    let fileMime;
    await new Promise((resolve) => {
      const blob = file.slice(0, fileType.minimumBytes);

      const reader = new FileReader();
      reader.onloadend = function (e) {
        // @ts-ignore
        const bytes = new Uint8Array(e.target.result);
        fileMime = fileType(bytes).mime;

        if (notAllowedMimes.includes(fileMime)) {
          isAllowed = false;
        } else {
          isAllowed = true;
        }

        resolve(isAllowed);
      };
      reader.readAsArrayBuffer(blob);
    });
    return { isAllowed: isAllowed, mimeType: fileMime };
  }

  public static base64toBlob(b64Data) {
    const byteString = atob(b64Data);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab]);
  }

  public static base64toArrayBuffer(b64Data) {
    const byteString = atob(b64Data);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return ab;
  }

  public static getWordExamCandidateMaturityDate(birthDate: Date): Date {
    return new Date(
      Date.UTC(
        birthDate.getUTCFullYear() + 18,
        birthDate.getUTCMonth(),
        birthDate.getUTCDate() - 30,
        0,
        0,
        0
      )
    );
  }

  public static checkIfWordExamCandidateIsMature(birthDate: Date): boolean {
    const maturityDate = Extensions.getWordExamCandidateMaturityDate(birthDate);
    let currentDate = new Date();
    currentDate = new Date(
      Date.UTC(
        currentDate.getUTCFullYear(),
        currentDate.getUTCMonth(),
        currentDate.getUTCDate(),
        0,
        0,
        0
      )
    );
    return currentDate >= maturityDate;
  }
}

export function dateFromZeroYear(year, month, day) {
  month = month !== 0 ? month - 1 : month; /* js Date month counting from zero. */
  const zDate = new Date(year, month, day);
  zDate.setFullYear(year);
  return zDate;
}

export const nameof = <T>(name: keyof T) => name;

export function openCustomToastr(_toastr: ToastrService) {
  const options = { ..._toastr.toastrConfig };
  options.toastComponent = FirstTimeAutosaveToastrComponent;
  options.disableTimeOut = true;
  options.tapToDismiss = false;
  const toastr = _toastr.show('', '', options);
  return toastr;
}

export function openAnonymousPaperApplicationToastr(loggedUser: string, toast: ToastrService) {
  if(!loggedUser && !!Extensions.getCookie('anonymousToken') && !!Extensions.getCookie('ownerId')) {
    let applicationTypeRoute, applicationParametrizedRoute;

    switch(Extensions.getCookie('cardType')) {
      case ApplicationDto.TypeEnum.COMPANYCARD: 
        applicationTypeRoute = COMPANY_CARD_APPLICATION_ROUTE;
        applicationParametrizedRoute = COMPANY_CARD_APPLICATION_PERSONAL_DATA_PARAMETRIZED_ROUTE;
        break;
      case ApplicationDto.TypeEnum.CONTROLCARD:
        applicationTypeRoute = CONTROL_CARD_APPLICATION_ROUTE;
        applicationParametrizedRoute = CONTROL_CARD_APPLICATION_PERSONAL_DATA_PARAMETRIZED_ROUTE;
        break;
      case ApplicationDto.TypeEnum.DRIVERCARD:
        applicationTypeRoute = DRIVER_CARD_APPLICATION_ROUTE;
        applicationParametrizedRoute = DRIVER_CARD_APPLICATION_PERSONAL_DATA_PARAMETRIZED_ROUTE;
        break;
      case ApplicationDto.TypeEnum.TEMPORARYDRIVERCARD:
        applicationTypeRoute = TEMPORARY_DRIVER_CARD_APPLICATION_ROUTE;
        applicationParametrizedRoute = TEMPORARY_DRIVER_CARD_APPLICATION_PERSONAL_DATA_PARAMETRIZED_ROUTE;
        break;
      case ApplicationDto.TypeEnum.WORKSHOPCARD:
        applicationTypeRoute = WORKSHOP_CARD_APPLICATION_ROUTE;
        applicationParametrizedRoute = WORKSHOP_CARD_APPLICATION_PERSONAL_DATA_PARAMETRIZED_ROUTE;
        break;
    }

    const isComplaint = !!Extensions.getCookie('complaintId');

    const options = { ...toast.toastrConfig };
    const route = `${isComplaint ? (TACHOGRAPH_ROUTE + '/' + STC_COMPLAINT_APPLICATION_ROUTE) : STC_TACHOGRAPH_APPLICATIONS_ROUTE}/${applicationTypeRoute}/${applicationParametrizedRoute}`;
    options.payload = route;
    options.toastComponent = CreatedPaperApplicationToastComponent;
    options.disableTimeOut = true;
    options.timeOut = 0;
    const toastr  = toast.show('Masz niedokończony wniosek o kartę przedsiębiorstwa.', 'Przejdź do kontynuowania wypełniania wniosku', options);

    return toastr;
  }
}

export function isNullOrUndefined(value: any) {
  return value === null || value === undefined;
}

export class HttpParamsBuilder {
  private _params: HttpParams;
  constructor() {
    this._params = new HttpParams();
  }

  public set(param: string, value: string): HttpParamsBuilder {
    if (!isNullOrUndefined(param) && !isNullOrUndefined(value)) {
      this._params = this._params.set(param, value);
    }
    return this;
  }

  public build(): HttpParams {
    return this._params;
  }
}

export function customErrorHandler(
  response: HttpErrorResponse,
  toastrService: ToastrService,
  translateService: TranslateService
) {
  const genericUserInfo = `${translateService.instant('TOASTR.ERROR.GENERIC_INFO')} ${
    response.status
  }`;

  if (response.error && response.error.errors && response.error.errors.length) {
    response.error.errors.forEach((error, index, arr) => {
      error.userMessage
        ? toastrService.error(error.userMessage)
        : toastrService.error(genericUserInfo);
    });
  } else {
    toastrService.error(genericUserInfo);
  }
}

export function sanitizePhoneNumber(phoneNumber: string): string {
  if (phoneNumber === null || phoneNumber === undefined || phoneNumber === '') {
    return phoneNumber;
  }
  return phoneNumber.replace(/[^\+^0-9]/g, '');
}

function rand(min: number, max: number): number {
  return min + Math.floor((max - min) * Math.random());
}

function dotProduct(array1: number[], array2: number[]) {
  if (array1.length !== array2.length) {
    throw new Error('Arrays have to have equal length to compute their dot product');
  }
  return array1.reduce((acc, value, index) => acc + value * array2[index], 0);
}

export function generatePesel(): string {
  const getMonthOffsetForPesel = (century: number): number => {
    switch (century) {
      case 18:
        return 80;
      case 19:
        return 0;
      case 20:
        return 20;
      case 21:
        return 40;
      case 22:
        return 60;
    }
  };

  const daysInMonth = (year: number, month: number): number => {
    // JS - month are zero based, but days are 1 based
    // so 1 is first day of month.
    // If we pass 0 day of next month - we get LAST day of month.
    return new Date(year, month + 1, 0).getDate();
  };

  const last2Digits = (number: number): string => {
    const n = Math.floor(number % 100);
    return ('00' + n.toString(10)).slice(-2);
  };

  const computePeselControlDigit = (rawPesel: string): number => {
    const peselDigits = rawPesel.split('').map((d) => +d);
    const weigths = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
    let sum = dotProduct(peselDigits, weigths);
    sum = 10 - (sum % 10);
    return sum === 10 ? 0 : sum;
  };

  const birthYear = rand(1900, 2009);

  const century = Math.floor(birthYear / 100);
  const monthOffset = getMonthOffsetForPesel(century);

  const birthMonth = rand(1, 13);
  const birthDay = rand(1, daysInMonth(birthYear, birthMonth) + 1);
  const peselMonth = birthMonth + monthOffset;

  const fourRandomDigits = ('0000' + rand(0, 10000).toString(10)).slice(-4);

  const rawPesel =
    last2Digits(birthYear) + last2Digits(peselMonth) + last2Digits(birthDay) + fourRandomDigits;

  const controlDigit = computePeselControlDigit(rawPesel);
  return rawPesel + controlDigit;
}

export function dateFromPesel(pesel: string): Date {
  const centuryForMonthOffset = (month: number): number => {
    if (month >= 80) {
      return 1800;
    } else if (month >= 60) {
      return 2200;
    } else if (month >= 40) {
      return 2100;
    } else if (month >= 20) {
      return 2000;
    } else {
      return 1900;
    }
  };

  let year = parseInt(pesel.substring(0, 2), 10);
  let month = parseInt(pesel.substring(2, 4), 10) - 1;
  const day = parseInt(pesel.substring(4, 6), 10);

  year += centuryForMonthOffset(month);
  month = month % 20;

  return new Date(Date.UTC(year, month, day, 0, 0, 0));
}

export function fillFormWithData(form: UntypedFormGroup, data: any) {
  if (!form || !data) {
    return;
  }
  Object.entries(data).forEach(([name, value]: [string, any]) => {
    if (form.controls[name]) {
      form.controls[name].patchValue(value);
    }
  });
}

export function generateNip() {
  const taxOfficeId =
    rand(1, 10).toString(10) + rand(1, 10).toString(10) + rand(1, 10).toString(10);
  const rest = ('000000' + rand(0, 1000000).toString(10)).slice(-6);
  const rawNip = taxOfficeId + rest;

  const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7];

  let sum = dotProduct(
    rawNip.split('').map((d) => +d),
    weights
  );
  sum = sum % 11;

  if (sum === 10) {
    // valid NIP cannot have control sum equal 10
    return generateNip();
  } else {
    return rawNip + sum.toString(10);
  }
}

export function setCookieWithHours(cookie: string, expHours: number) {
  let date = new Date();
  date.setTime(date.getTime() + (expHours * 60 * 60 * 1000));
  const expires = "expires=" + date.toUTCString();
  document.cookie = cookie + expires + "; path=/";
}

export function deleteCookie(cName: string) {
  document.cookie = `${cName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
}

export function deletePaperApplicationCookies() {
  deleteCookie('anonymousToken');
  deleteCookie('ownerId');
  deleteCookie('applicationId');
  deleteCookie('editableApplication');
  deleteCookie('cardType');
  deleteCookie('complaintId');
}

export function deletePaperApplicationSessionStorage() {
  sessionStorage.removeItem('anonymousToken');
  sessionStorage.removeItem('ownerId');
  sessionStorage.removeItem('applicationId');
  sessionStorage.removeItem('editableApplication');
  sessionStorage.removeItem('cardType');
  sessionStorage.removeItem('complaintId');
}
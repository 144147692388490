import { AttachmentType } from 'src/app/shared/models/attachments/attachment-dto.model';

export interface AttachmentMetadata {
  type: AttachmentType;
  format: string[];
  maxNumber: number;
  maxSize: number;
  required: boolean;
  attachable: boolean;
}

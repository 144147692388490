import { map, switchMap, catchError } from 'rxjs/operators';
import { Observable, of, timer } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppVersionService {
  private etag: string;
  private version: string;

  constructor(private http: HttpClient) { }

  checkAppVersion(time: number): Observable<string> {
    let headers = new HttpHeaders().set('Access-Control-Expose-Headers', 'ETag');
    
    return timer(0, time).pipe(
      switchMap(_ => {
        if (this.etag) {
          headers = headers.set('If-None-Match', this.etag);
        }

        return this.http.get<{version: string}>('assets/version.json', {
          headers: headers,
          observe: 'response',
          params: {
            't': Date.now().toString() // Dodajemy parametr cacheBust
          },
        }).pipe(
          catchError((err) => {
            if (err.status === 304) {
              return of(undefined); // Return undefined if response is 304
            } else {
              throw err; // Throw error for other cases
            }
          })
        )
      }),
      map((resp: HttpResponse<{version: string}>) =>{
        if (!resp) {
          return this.version;
        } else {
          this.etag = resp.headers.get('ETag');
          this.version = resp.body.version
          return this.version;
        }
      })
    )
  }
}

import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class IndicatorService implements OnDestroy {
  private indicateEvent$: Subject<string> = new Subject();

  constructor() {}

  getIndicateEvent$(): Observable<string> {
    return this.indicateEvent$.asObservable();
  }

  indicate(eventName: string) {
    this.indicateEvent$.next(eventName);
  }

  ngOnDestroy(): void {
    this.indicateEvent$.complete();
  }
}

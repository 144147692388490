export const TRANSLATED_PAGES = [
  '/tachograf/uzyskaj-karte-do-tachografu',
  '/tachograf/sprawdz-czy-twoja-karta-do-tachografu-jest-gotowa',
  '/tachograf/informacje-o-dokumencie',
  '/tachograf/zglos-zgubienie-uszkodzenie-lub-kradziez-karty-do-tachografu',
  '/tachograf/zloz-reklamacje-karty-do-tachografu-i-uzyskaj-karte-zastepcza',
  '/tachograf/wnioski/',
  '/tachograf/reklamacje/',
  '/tachograf/regulacje-prawne',
  '/tachograf/kontakt',
  '/tachograf',
  '/tachograf/ua-faq',
  '/kontakt',
  '/konto/ustawienia',
  '/konto/tachograf',
  '/rejestracja',
  '/blad',
  '/payment',
  '/prace-serwisowe',
  '/adr',
  '/rejestracja-pojazdu',
  '/prawo-jazdy',
  '/?state='
];

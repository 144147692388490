import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Input } from '@angular/core';
import { DashboardServices } from 'src/app/my-account/dashboard/dashboard-services/dashboard-services.model';
import { DRIVER_LICENSE_ROUTE } from 'src/app/service-category/_consts/service-category-route.const';
import { DESTINATION_DICTIONARY } from '../../dictionary/route-dictionary.enum';

@Component({
  selector: 'app-most-popular-services',
  templateUrl: './most-popular-services.component.html',
  styleUrls: ['./most-popular-services.component.scss']
})
export class MostPopularServicesComponent implements OnInit {
  @Input() borderlessStyle: boolean;
  @Input() hideAllServicesButton: boolean;
  DRIVNG_LICENSE_ROUTE = `/${DRIVER_LICENSE_ROUTE}`;
  mostPopularServices: Array<DashboardServices> = [];
  content: any;

  constructor(private translate: TranslateService) {}

  ngOnInit() {
    this.translate.get('SHARED.MOST_POPULAR_SERVICES').subscribe({
      next: (content) => {
        this.content = content;
        this.getMostPopularServices();
      }
    });
  }

  getMostPopularServices() {
    this.mostPopularServices = [
      {
        title: this.content.getTachoCard,
        redirectRoute: DESTINATION_DICTIONARY.get('tachograph_card')
      },
      {
        title: this.content.checkYourDL,
        redirectRoute: DESTINATION_DICTIONARY.get('check-drivers-license-status')
      },
      {
        title: this.content.checkExamAvailability,
        redirectRoute: DESTINATION_DICTIONARY.get('driver_license_word_exam_check_availability')
      },
      {
        title: this.content.dlReservation,
        redirectRoute: DESTINATION_DICTIONARY.get('driver_license_word_exam')
      },
      {
        title: this.content.checkPoofOfRegistration,
        redirectRoute: DESTINATION_DICTIONARY.get('check_registration_document_status')
      },
      {
        title: this.content.checkTachoCard,
        redirectRoute: DESTINATION_DICTIONARY.get('tachograph_check_stc_status')
      },
      {
        title: this.content.checkAdr,
        redirectRoute: DESTINATION_DICTIONARY.get('adr_status')
      },
      {
        title: this.content.checkPkkStatus,
        redirectRoute: DESTINATION_DICTIONARY.get('check-pkk-status')
      }
    ];
  }
}

import { AuthConfig } from 'angular-oauth2-oidc';
import { environment as env } from 'src/environments/environment';

export const authConfig: AuthConfig = {
  issuer: env.AUTH_ISSUER,
  redirectUri: env.REDIRECT_URI,
  silentRefreshRedirectUri: env.SILENT_REFRESH_REDIRECT_URI,
  userinfoEndpoint: env.STC.ME,
  clientId: 'client',
  dummyClientSecret: 'secret',
  scope: 'openid profile email resource.read',
  requireHttps: true,
  disableAtHashCheck: true,
  useHttpBasicAuth: true,
  clearHashAfterLogin: false,
  oidc: true,
  postLogoutRedirectUri: env.REDIRECT_URI,
  logoutUrl: env.AUTH_LOGOUT_URL
};

export interface KeyIdentifier {
  key: string;
  keyCode: number;
}

export interface KeysListenerCallback {
  keys: KeyIdentifier[];
  callback: VoidFunction;
}

export class KeyId implements KeyIdentifier {
  static readonly ENTER = new KeyId('Enter', 13);
  static readonly TAB = new KeyId('Tab', 9);
  static readonly ARROW_UP = new KeyId('ArrowUp', 38);
  static readonly ARROW_DOWN = new KeyId('ArrowDown', 40);
  static readonly ESCAPE = new KeyId('Escape', 27);

  private constructor(public readonly key, public readonly keyCode) {}
}

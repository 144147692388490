import {
  Component,
  OnDestroy,
  Input,
  OnInit,
  OnChanges,
  SimpleChange,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Inject,
  Renderer2
} from '@angular/core';
import { LoadersService } from './loaders.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DEFAULTS, CLoaders, PRIMARY_LOADER, LoaderType } from './loaders.enum';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-loaders',
  templateUrl: 'loaders.component.html',
  styleUrls: ['loaders.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('loaderAnimation', [
      state('fadeIn', style({ opacity: 1 })),
      state('fadeOut', style({ opacity: 1 })),
      state('fadeInOut', style({ opacity: 1 })),

      transition('fadeOut => void, fadeInOut => void', [animate(400, style({ opacity: 0 }))]),
      transition('void => fadeIn, void => fadeInOut', [
        style({ opacity: 0 }),
        animate(400, style({ opacity: 1 }))
      ])
    ])
  ]
})
export class LoadersComponent implements OnDestroy, OnInit, OnChanges {
  @Input() bdColor: string;

  @Input() type: LoaderType;

  @Input() fullScreen: boolean;

  @Input() name: string;

  @Input() zIndex: number;

  @Input() show: boolean;

  @Input() animation: string;

  @Input() disableScroll: boolean;

  @Input() positionRelative: boolean;
  @Input() reverse: boolean;
  @Input() loadingText: string;

  loaders: CLoaders = new CLoaders();

  ngUnsubscribe: Subject<void> = new Subject();

  constructor(
    private loadersService: LoadersService,
    private changeDetector: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2
  ) {
    this.bdColor = DEFAULTS.BD_COLOR;
    this.zIndex = DEFAULTS.Z_INDEX;
    this.type = DEFAULTS.LOADER_TYPE;
    this.fullScreen = false;
    this.name = PRIMARY_LOADER;
    this.show = true;
    this.animation = DEFAULTS.ANIMATION;
    this.disableScroll = false;
    this.positionRelative = false;
    this.reverse = false;
  }

  ngOnInit() {
    this.setDefaultOptions();
    this.loadersService
      .getLoaders(this.name)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((loaders: CLoaders) => {
        this.setDefaultOptions();
        Object.assign(this.loaders, loaders);
        this.changeDetector.markForCheck();
      });
  }

  setDefaultOptions = () => {
    this.loaders = new CLoaders({
      name: this.name,
      bdColor: this.bdColor,
      type: this.type,
      fullScreen: this.fullScreen,
      show: this.show,
      zIndex: this.zIndex,
      animation: this.animation,
      positionRelative: this.positionRelative,
      reverse: this.reverse,
      loadingText: this.loadingText
    });
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    for (const propName in changes) {
      if (propName) {
        const changedProp = changes[propName];
        if (changedProp.isFirstChange()) {
          return;
        } else if (
          typeof changedProp.currentValue !== 'undefined' &&
          changedProp.currentValue !== changedProp.previousValue
        ) {
          if (changedProp.currentValue !== '') {
            this.loaders[propName] = changedProp.currentValue;
          }
        }
      }
    }
  }

  onAnimationStart(event) {
    if (this.show && this.disableScroll)
      this.renderer.addClass(this.document.documentElement, 'disable-scroll');
  }

  onAnimationEnd(event) {
    this.renderer.removeClass(this.document.documentElement, 'disable-scroll');
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}

<div *ngIf="attachmentsConfig$ | async as config; else titlePlaceholder" class="attachments-header">
  <ng-container *ngIf="config.length === 0; else attachmentTitle">
    <h2 class="attachments-title">{{ 'STC.ATTACHMENTS.title' | translate }}</h2>
    <app-teaser-banner class="teaser-banner--gap-top">{{
      'STC.NO_ATTACHMENTS_INFOBOX.INFOBOX_TITLE' | translate
    }}</app-teaser-banner>
  </ng-container>
</div>

<ng-template #attachmentTitle>
  <h2 class="attachments-title">
    {{ 'STC.ATTACHMENTS.add_files_header' | translate }}
  </h2>
  <span class="attachments-required">{{ 'STC.ATTACHMENTS.mandatory_input' | translate }}</span>
  <div class="attachments-info">
    <h2 class="attachments-title attachments-title--gap">
      {{ 'STC.ATTACHMENTS.title' | translate }}
    </h2>
  </div>
</ng-template>

<ng-template #titlePlaceholder>
  <h2 class="attachments-title">{{ 'STC.ATTACHMENTS.title' | translate }}</h2>
  <div class="title-placeholder"></div>
</ng-template>

<form (ngSubmit)="onSubmit()" [formGroup]="form" class="attachments-form">
  <main class="attachments-main">
    <ng-container *ngFor="let config of attachmentsConfig$ | async">
      <ng-container [ngSwitch]="config.type">
        <!-- PHOTO -->
        <app-attachments-type-group
          [correctionChecksums]="correctionErrors.get('PHOTO')"
          [applicationType]="applicationType"
          [htmlId]="'image1'"
          *ngSwitchCase="'PHOTO'"
          [config]="config">
          <span title>{{ 'STC.ATTACHMENTS.PHOTO.label' | translate }}</span>
          <span description [innerHTML]="'STC.ATTACHMENTS.PHOTO.description' | translate"> </span>
        </app-attachments-type-group>
        <!-- SIGNATURE_SCAN -->
        <app-attachments-type-group
          [correctionChecksums]="correctionErrors.get('SIGNATURE_SCAN')"
          [applicationType]="applicationType"
          *ngSwitchCase="'SIGNATURE_SCAN'"
          [config]="config">
          <span title>{{ 'STC.ATTACHMENTS.SIGNATURE_SCAN.label' | translate }}</span>
          <span description [innerHTML]="'STC.ATTACHMENTS.SIGNATURE_SCAN.description' | translate">
          </span>
        </app-attachments-type-group>
        <!-- COPY_OF_DRIVING_LICENSE -->
        <app-attachments-type-group
          [correctionChecksums]="correctionErrors.get('COPY_OF_DRIVING_LICENSE')"
          [applicationType]="applicationType"
          *ngSwitchCase="'COPY_OF_DRIVING_LICENSE'"
          [config]="config">
          <span title>{{ 'STC.ATTACHMENTS.COPY_OF_DRIVING_LICENSE.label' | translate }}</span>
          <span
            description
            [innerHTML]="'STC.ATTACHMENTS.COPY_OF_DRIVING_LICENSE.description' | translate">
          </span>
        </app-attachments-type-group>
        <!-- DRIVING_LICENSE_TRANSLATION -->
        <app-attachments-type-group
          [correctionChecksums]="correctionErrors.get('DRIVING_LICENSE_TRANSLATION')"
          [applicationType]="applicationType"
          *ngSwitchCase="'DRIVING_LICENSE_TRANSLATION'"
          [config]="config">
          <span title>{{ 'STC.ATTACHMENTS.DRIVING_LICENSE_TRANSLATION.label' | translate }}</span>
          <span
            description
            [innerHTML]="'STC.ATTACHMENTS.DRIVING_LICENSE_TRANSLATION.description' | translate">
          </span>
        </app-attachments-type-group>
        <!-- COPY_OF_THE_DRIVER_CARD -->
        <app-attachments-type-group
          [correctionChecksums]="correctionErrors.get('COPY_OF_THE_DRIVER_CARD')"
          [applicationType]="applicationType"
          *ngSwitchCase="'COPY_OF_THE_DRIVER_CARD'"
          [config]="config">
          <span title>{{ 'STC.ATTACHMENTS.COPY_OF_THE_DRIVER_CARD.label' | translate }}</span>
          <span
            description
            [innerHTML]="'STC.ATTACHMENTS.COPY_OF_THE_DRIVER_CARD.description' | translate">
          </span>
        </app-attachments-type-group>
        <!-- DRIVER_STATEMENT_LICENSE -->
        <app-attachments-type-group
          [correctionChecksums]="correctionErrors.get('DRIVER_STATEMENT_LICENSE')"
          [applicationType]="applicationType"
          *ngSwitchCase="'DRIVER_STATEMENT_LICENSE'"
          [config]="config">
          <span title>{{ 'STC.ATTACHMENTS.DRIVER_STATEMENT_LICENSE.label' | translate }}</span>
          <span
            description
            [innerHTML]="'STC.ATTACHMENTS.DRIVER_STATEMENT_LICENSE.description' | translate">
          </span>
        </app-attachments-type-group>
        <!-- DRIVER_STATEMENT_ADDRESS -->
        <app-attachments-type-group
          [correctionChecksums]="correctionErrors.get('DRIVER_STATEMENT_ADDRESS')"
          [applicationType]="applicationType"
          *ngSwitchCase="'DRIVER_STATEMENT_ADDRESS'"
          [config]="config">
          <span title>{{ 'STC.ATTACHMENTS.DRIVER_STATEMENT_ADDRESS.label' | translate }}</span>
          <span
            description
            [innerHTML]="'STC.ATTACHMENTS.DRIVER_STATEMENT_ADDRESS.description' | translate">
          </span>
        </app-attachments-type-group>
        <!-- CONFIRMATION_OF_WORK -->
        <app-attachments-type-group
          [correctionChecksums]="correctionErrors.get('CONFIRMATION_OF_WORK')"
          [applicationType]="applicationType"
          *ngSwitchCase="'CONFIRMATION_OF_WORK'"
          [config]="config">
          <span title>{{ 'STC.ATTACHMENTS.CONFIRMATION_OF_WORK.label' | translate }}</span>
          <span
            description
            [innerHTML]="'STC.ATTACHMENTS.CONFIRMATION_OF_WORK.description' | translate">
          </span>
        </app-attachments-type-group>
        <!-- A STATMENT_ABOUT_LOSING_FOREIGN_CARD -->
        <app-attachments-type-group
        [correctionChecksums]="correctionErrors.get('A_STATEMENT_ABOUT_LOSING_THE_FOREIGN_CARD')"
        [applicationType]="applicationType"
        *ngSwitchCase="'A_STATEMENT_ABOUT_LOSING_THE_FOREIGN_CARD'"
        [config]="config">
        <span title>{{
          'STC.ATTACHMENTS.A_STATEMENT_ABOUT_LOSING_THE_FOREIGN_CARD.label' | translate
        }}</span>
        <span
          description
          [innerHTML]="
            'STC.ATTACHMENTS.A_STATEMENT_ABOUT_LOSING_THE_FOREIGN_CARD.description' | translate
          ">
        </span>
      </app-attachments-type-group>
        <!-- A_STATEMENT_ABOUT_LOSING_THE_CARD -->
        <app-attachments-type-group
          [correctionChecksums]="correctionErrors.get('A_STATEMENT_ABOUT_LOSING_THE_CARD')"
          [applicationType]="applicationType"
          *ngSwitchCase="'A_STATEMENT_ABOUT_LOSING_THE_CARD'"
          [config]="config">
          <span title>{{
            'STC.ATTACHMENTS.A_STATEMENT_ABOUT_LOSING_THE_CARD.label' | translate
          }}</span>
          <span
            description
            [innerHTML]="
              'STC.ATTACHMENTS.A_STATEMENT_ABOUT_LOSING_THE_CARD.description' | translate
            ">
          </span>
        </app-attachments-type-group>
        <!-- A_STATEMENT_ABOUT_CARD_THEFT -->
        <app-attachments-type-group
          [correctionChecksums]="correctionErrors.get('A_STATEMENT_ABOUT_CARD_THEFT')"
          [applicationType]="applicationType"
          *ngSwitchCase="'A_STATEMENT_ABOUT_CARD_THEFT'"
          [config]="config">
          <span title>{{ 'STC.ATTACHMENTS.A_STATEMENT_ABOUT_CARD_THEFT.label' | translate }}</span>
          <span
            description
            [innerHTML]="'STC.ATTACHMENTS.A_STATEMENT_ABOUT_CARD_THEFT.description' | translate">
          </span>
        </app-attachments-type-group>
        <!-- A_COPY_OF_THE_DOCUMENT_ALLOWING_TRAFFIC -->
        <app-attachments-type-group
          [correctionChecksums]="correctionErrors.get('A_COPY_OF_THE_DOCUMENT_ALLOWING_TRAFFIC')"
          [applicationType]="applicationType"
          *ngSwitchCase="'A_COPY_OF_THE_DOCUMENT_ALLOWING_TRAFFIC'"
          [config]="config">
          <span title>{{
            'STC.ATTACHMENTS.A_COPY_OF_THE_DOCUMENT_ALLOWING_TRAFFIC.label' | translate
          }}</span>
          <span
            description
            [innerHTML]="
              'STC.ATTACHMENTS.A_COPY_OF_THE_DOCUMENT_ALLOWING_TRAFFIC.description' | translate
            ">
          </span>
        </app-attachments-type-group>
        <!-- EMPLOYER_HAS_EMPLOYEE_AUTHORIZATION_TO_SUBMIT_APPLICATION -->
        <app-attachments-type-group
          [correctionChecksums]="
            correctionErrors.get('EMPLOYER_HAS_EMPLOYEE_AUTHORIZATION_TO_SUBMIT_APPLICATION')
          "
          [applicationType]="applicationType"
          *ngSwitchCase="'EMPLOYER_HAS_EMPLOYEE_AUTHORIZATION_TO_SUBMIT_APPLICATION'"
          [config]="config">
          <span title>{{
            'STC.ATTACHMENTS.EMPLOYER_HAS_EMPLOYEE_AUTHORIZATION_TO_SUBMIT_APPLICATION.label'
              | translate
          }}</span>
          <app-info-box
            info
            *ngIf="displayMissingRequiredAttachmentNotification"
            [bordered]="true"
            [maxWidthLimited]="false"
            [infoBoxText]="
              'STC.ATTACHMENTS.EMPLOYER_HAS_EMPLOYEE_AUTHORIZATION_TO_SUBMIT_APPLICATION.info'
                | translate
            "></app-info-box>
          <span
            description
            [innerHTML]="
              'STC.ATTACHMENTS.EMPLOYER_HAS_EMPLOYEE_AUTHORIZATION_TO_SUBMIT_APPLICATION.description'
                | translate
            ">
          </span>
        </app-attachments-type-group>
        <!-- COMPANY_AUTHORIZATION_FOR_NON_KRS_PERSON -->
        <app-attachments-type-group
          [correctionChecksums]="correctionErrors.get('COMPANY_AUTHORIZATION_FOR_NON_KRS_PERSON')"
          [applicationType]="applicationType"
          *ngSwitchCase="'COMPANY_AUTHORIZATION_FOR_NON_KRS_PERSON'"
          [config]="config">
          <ng-container title>
            <ng-container *ngIf="cardType === CardTypes.CONTROLCARD; else defaultCompanyAuthForNonTitle;">
              <span>{{
                'STC.ATTACHMENTS.COMPANY_AUTHORIZATION_FOR_NON_KRS_PERSON_CONTROL.label' | translate
              }}</span>
            </ng-container>
            <ng-template #defaultCompanyAuthForNonTitle>
              <span title>{{
                'STC.ATTACHMENTS.COMPANY_AUTHORIZATION_FOR_NON_KRS_PERSON.label' | translate
              }}</span>
            </ng-template>
          </ng-container>
           
          <ng-container description>
            <ng-container *ngIf="cardType === CardTypes.CONTROLCARD; else defaultCompanyAuthForNonDesc;">
              <span
              [innerHTML]="
                'STC.ATTACHMENTS.COMPANY_AUTHORIZATION_FOR_NON_KRS_PERSON_CONTROL.description' | translate
              ">
            </span>
            </ng-container>
            <ng-template #defaultCompanyAuthForNonDesc>
              <span
              [innerHTML]="
                'STC.ATTACHMENTS.COMPANY_AUTHORIZATION_FOR_NON_KRS_PERSON.description' | translate
              ">
            </span>
            </ng-template>
          </ng-container>
          
        </app-attachments-type-group>
        <!-- COPY_OF_INTERNATIONAL_DRIVING_LICENSE -->
        <app-attachments-type-group
          [correctionChecksums]="correctionErrors.get('COPY_OF_INTERNATIONAL_DRIVING_LICENSE')"
          [applicationType]="applicationType"
          *ngSwitchCase="'COPY_OF_INTERNATIONAL_DRIVING_LICENSE'"
          [config]="config">
          <span title>{{
            'STC.ATTACHMENTS.COPY_OF_INTERNATIONAL_DRIVING_LICENSE.label' | translate
          }}</span>
          <span
            description
            [innerHTML]="
              'STC.ATTACHMENTS.COPY_OF_INTERNATIONAL_DRIVING_LICENSE.description' | translate
            ">
          </span>
        </app-attachments-type-group>
        <!-- COPY_OF_DRIVER_CERTIFICATE -->
        <app-attachments-type-group
          [correctionChecksums]="correctionErrors.get('COPY_OF_DRIVER_CERTIFICATE')"
          [applicationType]="applicationType"
          *ngSwitchCase="'COPY_OF_DRIVER_CERTIFICATE'"
          [config]="config">
            <span title>{{
              'STC.ATTACHMENTS.COPY_OF_DRIVER_CERTIFICATE.label' | translate
            }}</span>
            <span
              description
              [innerHTML]="
                'STC.ATTACHMENTS.COPY_OF_DRIVER_CERTIFICATE.description' | translate
              ">
            </span>
        </app-attachments-type-group>
        <!-- COPY_OF_DRIVER_CERTIFICATE_OR_DRIVER_QUALIFICATION_CARD -->
        <app-attachments-type-group
          [correctionChecksums]="correctionErrors.get('COPY_OF_DRIVER_CERTIFICATE_OR_DRIVER_QUALIFICATION_CARD')"
          [applicationType]="applicationType"
          *ngSwitchCase="'COPY_OF_DRIVER_CERTIFICATE_OR_DRIVER_QUALIFICATION_CARD'"
          [config]="config">
            <span title>{{
              'STC.ATTACHMENTS.COPY_OF_DRIVER_CERTIFICATE_OR_DRIVER_QUALIFICATION_CARD.label' | translate
            }}</span>
            <span
              *ngIf="cardType === CardTypes.TEMPORARYDRIVERCARD"
              description
              [innerHTML]="
                'STC.ATTACHMENTS.COPY_OF_DRIVER_CERTIFICATE_OR_DRIVER_QUALIFICATION_CARD.description' | translate
              ">
            </span>
            <app-info-box
              description
              *ngIf="cardType === CardTypes.DRIVERCARD"
              [bordered]="true" 
              [infoBoxText]="'STC.ATTACHMENTS.COPY_OF_DRIVER_CERTIFICATE_OR_DRIVER_QUALIFICATION_CARD.infoBox' | translate"
              ></app-info-box>
        </app-attachments-type-group>
        <!-- OTHER-->
        <app-attachments-type-group
        *ngSwitchCase="'OTHER'"
        [applicationType]="applicationType"
        [config]="config">
        <span title>{{
        ('STC.ATTACHMENTS.OTHER.label' | translate)
        }}</span>
        <span
          description
          [innerHTML]="reason === ReasonEnum.COMPLAINT ? ('STC.ATTACHMENTS.OTHER.descriptionOptional' | translate) : ('STC.ATTACHMENTS.OTHER.description' | translate)
          ">
        </span>
      </app-attachments-type-group>
      <!-- PREVIOUS_CARD -->
      <app-attachments-type-group
      *ngSwitchCase="'PREVIOUS_CARD'"
      [applicationType]="applicationType"
      [disableAddAttachment]="true"
      [config]="config">
      <span title>{{generateCardName()}}</span>
      <span
        description
        [innerHTML]="('STC.ATTACHMENTS.PREVIOUS_CARD.description' | translate)">
      </span>
      </app-attachments-type-group>
      </ng-container>
    </ng-container>

    <app-attachments-group-placeholder *ngFor="let placeholder of groupPlaceholders">
    </app-attachments-group-placeholder>
  </main>
</form>
<app-two-way-action-bar
  (submit)="onSubmit()"
  (navigateBack)="onBackClicked()"
  [forwardButtonDisabled]="submitDisabled">
</app-two-way-action-bar>

<section
  class="promotional-box"
  *ngIf="content"
  [ngClass]="{
    'promotional-box--vertical': content.direction === 'vertical',
    'promotional-box--horizontal': content.direction === 'horizontal',
    'promotional-box--service-promo': content.style === 'servicePromo'
  }">
  <div
    class="promotional-box__wrapper"
    [ngClass]="{ 'ic-page': content.direction === 'horizontal' }">
    <div
      class="promotional-box__wrapper--img"
      style="background-image: url({{ content.imgSrc }})"
      [attr.aria-label]="content.imgAlt"></div>

    <div class="promotional-box__content">
      <span *ngIf="content.promoLabel" class="promotional-box__content--label">{{
        content.promoLabel
      }}</span>
      <h3 *ngIf="content.title">{{ content.title }}</h3>
      <p *ngIf="!content.mobileSubtitle || !isMobile">{{ content.subtitle }}</p>
      <p *ngIf="content.mobileSubtitle && isMobile">
        {{ content.mobileSubtitle }}
      </p>
      <ic-ghost-button
        *ngIf="content.internalUrl"
        [showArrow]="true"
        [type]="content.buttonType"
        [width]="content.buttonWidth"
        routerLink="{{ content.internalUrl }}"
        >{{ content.buttonText }}</ic-ghost-button
      >
    </div>
  </div>
</section>

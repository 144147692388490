<section class="delete-employee-modal">
  <div class="loader" *ngIf="!employee">
    <app-loaders type="dots"></app-loaders>
  </div>

  <div class="delete-employee" *ngIf="employee">
    <header class="header">
      <h1 class="header-title">
        {{ 'DASHBOARD.EMPLOYEES.DELETE_EMPLOYEE_MODAL.QUESTION_TEXT' | translate }}
        {{ employee?.firstName }} {{ employee?.lastName }}?
      </h1>
      <button
        [attr.aria-label]="'DASHBOARD.EMPLOYEES.DELETE_EMPLOYEE_MODAL.CLOSE_BTN_ARIA' | translate"
        (click)="onCancelClick()"
        class="close-btn">
        <svg-icon class="close-btn-icon" src="assets/images/close_mobile_.svg"></svg-icon>
      </button>
    </header>
    <main class="main">
      <p
        class="content"
        [innerHtml]="'DASHBOARD.EMPLOYEES.DELETE_EMPLOYEE_MODAL.WARNING_TEXT' | translate"></p>
      <div class="nav-btns">
        <button (click)="onOkClick()" class="cool-btn primary-btn">
          {{ 'DASHBOARD.EMPLOYEES.DELETE_EMPLOYEE_MODAL.DELETE_BTN' | translate }}
        </button>
        <button (click)="onCancelClick()" class="cool-btn outline-btn">
          {{ 'DASHBOARD.EMPLOYEES.DELETE_EMPLOYEE_MODAL.CANCEL_BTN' | translate }}
        </button>
      </div>
    </main>
  </div>
</section>

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MissingRequiredAttachmentNotificationService {
  private notify = false;
  constructor() {}

  setNotification(): void {
    this.notify = true;
  }

  shouldNotify(): boolean {
    const beforeClear = this.notify;
    this.notify = false;
    return beforeClear;
  }
}

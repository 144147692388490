<section class="action-confirmation-modal">
  <div class="loader" *ngIf="!content">
    <app-loaders type="dots"></app-loaders>
  </div>

  <ng-container *ngIf="content">
    <header class="header">
      <h1 class="header-title">{{ content.title }}</h1>
      <button [attr.aria-label]="content.btnCloseAria" (click)="onCancelClick()" class="close-btn">
        <svg-icon class="close-btn-icon" src="assets/images/close_mobile_.svg"></svg-icon>
      </button>
    </header>
    <main class="main">
      <p class="content" [innerHtml]="content.description"></p>
      <div class="nav-btns">
        <button (click)="onConfirmClick()" class="cool-btn primary-btn">
          {{ content.confirmButton }}
        </button>
        <button (click)="onCancelClick()" class="cool-btn outline-btn">
          {{ content.cancelButton }}
        </button>
      </div>
    </main>
  </ng-container>
</section>

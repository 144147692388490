import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType, OnInitEffects} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {from, fromEvent, of} from 'rxjs';
import {catchError, filter, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';

import {User} from '../model/user.model';
import {AuthHelperService} from './auth-helper.service';
import {AuthQueryService} from './auth-query.service';
import {AuthActions, AuthActionType} from './auth.actions';
import {localStorageLoginSync, localStorageLogoutSync} from './auth.const';

@Injectable()
export class AuthEffects implements OnInitEffects {
  constructor(
    private actions$: Actions,
    private authHelper: AuthHelperService,
    private authQuery: AuthQueryService
  ) {}


  autologinInit = createEffect(() => this.actions$.pipe(
    ofType(AuthActionType.AUTH_INIT),
    tap(() => this.authHelper.configure()),
    switchMap(() =>
      from(this.authHelper.autoLogin()).pipe(
        map((user: User) => new AuthActions.AuthReady({ user })),
        catchError(() => of(new AuthActions.AuthError()))
      )
    )
  ));


  tryLogin = createEffect(() => this.actions$.pipe(
    ofType(AuthActionType.LOGIN),
    tap((action: AuthActions.Login) => {
      this.authHelper.tryLogin(action.payload.url)
    })
  ), { dispatch: false });

  detectUserType = createEffect(() => this.actions$.pipe(
    ofType(AuthActionType.AUTH_READY),
    map((action: AuthActions.AuthReady) => {
      const isLogged = !!action?.payload?.user?.uuid;

      if (isLogged) {
        return new AuthActions.AuthenticatedLogged();
      }

      return new AuthActions.AuthenticatedLoggedOut();
    })
  ));
  
  logout = createEffect(() => this.actions$.pipe(
    ofType(AuthActionType.LOGOUT),
    tap(() => this.authHelper.logout())
  ), { dispatch: false });


  refreshUser = createEffect(() => this.actions$.pipe(
    ofType(AuthActionType.REFRESH_USER),
    switchMap(() =>
      from(this.authHelper.refreshUser()).pipe(
        map((user: User) => new AuthActions.AuthReady({ user })),
        catchError(() => of(new AuthActions.AuthError()))
      )
    )
  ));


  storageSyncLogin = createEffect(() => fromEvent<StorageEvent>(window, 'storage').pipe(
    withLatestFrom(this.authQuery.user$),
    filter(([evt, user]) => {
      const { uuid = '' } = user || {};
      return evt.key === localStorageLoginSync.key && !!evt.newValue && evt.newValue !== uuid;
    }),
    map(() => new AuthActions.SyncLogin())
  ));


  syncLogin = createEffect(() => this.actions$.pipe(
    ofType(AuthActionType.SYNC_LOGIN),
    tap(() => this.authHelper.syncLogin())
  ), { dispatch: false });


  storageSyncLogout = createEffect(() => fromEvent<StorageEvent>(window, 'storage').pipe(
    filter(
      (evt) =>
        evt.key === localStorageLogoutSync.key && evt.newValue === localStorageLogoutSync.value
    ),
    map(() => new AuthActions.SyncLogout())
  ));

  public anonymousLogin = createEffect(() => this.actions$.pipe(
    ofType(AuthActionType.ANONYMOUS_LOGIN),
    tap((action: AuthActions.AnonymousLogin) => this.authHelper.setAnonymousToken())
  ), { dispatch: false });

  syncLogout = createEffect(() => this.actions$.pipe(
    ofType(AuthActionType.SYNC_LOGOUT),
    tap(() => this.authHelper.syncLogout())
  ), { dispatch: false });


  silentLogout = createEffect(() => this.authQuery.silentLogout$.pipe(map(() => new AuthActions.SyncLogout())));


  ngrxOnInitEffects(): Action {
    return new AuthActions.AuthInit();
  }
}

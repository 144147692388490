export class CountedApiResponseModel<T> {
  public items: T[];
  public totalCount: number;

  constructor(items: any[], totalCount: number) {
    this.items = items;
    this.totalCount = totalCount;
  }

  static empty<T>(): CountedApiResponseModel<T> {
    return new CountedApiResponseModel<T>([], 0);
  }
}

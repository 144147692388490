<div class="ic-page" *ngIf="!breadcrumbsHidden">
  <div class="row">
    <div class="col" id="breadcrumbs-col">
      <div class="breadcrumbs-placeholder" *ngIf="!hideBreadcrumbsPlaceholder"></div>
      <ul id="breadcrumbs-list" *ngIf="breadcrumbs && breadcrumbs.length > 0">
        <li *ngFor="let breadcrumb of breadcrumbs; let i = index">
          <div class="breadcrumb-wrapper">
            <a *ngIf="i !== breadcrumbs.length - 1" [routerLink]="breadcrumb.url">{{
              breadcrumb.label
            }}</a>
            <ng-container *ngIf="i === breadcrumbs.length - 1">{{ breadcrumb.label }}</ng-container>
          </div>
        </li>
      </ul>
      <div *ngIf="displayEllipsis" class="breadcrumbs-end">&#x2026;</div>
    </div>
  </div>
</div>

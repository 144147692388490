<div
  class="riflebird riflebird--arrow"
  [class.disabled]="disabledState"
  [class.read-only]="readOnly"
  role="button"
  [attr.aria-label]="'SHARED.INPUT_FORM.expandList' | translate"
  (click)="onRiflebirdClick()">
  <svg
    *ngIf="!readOnly"
    fill="black"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M7 10l5 5 5-5z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
</div>
<input
  #input
  type="text"
  [id]="htmlId ? htmlId : ''"
  class="text-input"
  autocomplete="off"
  [placeholder]="placeholder"
  [(ngModel)]="inputValue"
  [attr.aria-label]="placeholder"
  (ngModelChange)="onModelChange()"
  (click)="onFocus($event)"
  (focus)="onFocus($event)"
  [attr.aria-expanded]="openedChanges | async"
  [class.invalid]="isInvalidChanges | async"
  [disabled]="disabledState || readOnly"
  [attr.title]="placeholder" />

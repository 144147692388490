<section class="pagination-limit-row">
  <button
    *ngFor="let item of items"
    (keydown.enter)="onItemSelected(item)"
    (click)="onItemSelected(item)"
    class="pagination-limit-box"
    [ngClass]="{ active: item === currentItem }">
    {{ item }}
  </button>
</section>

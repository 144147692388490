<div class="search-modal d-flex flex-column align-items-center">
  <header>
    <h1>{{ 'SEARCH.title' | translate }}</h1>
    <button [attr.aria-label]="'SEARCH_MODAL.btnCloseAria' | translate" (click)="close()">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12.879"
        height="12.879"
        viewBox="0 0 12.879 12.879">
        <g
          id="Group_2402"
          data-name="Group 2402"
          transform="translate(-215.743 142.204) rotate(-45)">
          <line
            id="Line_163"
            data-name="Line 163"
            y2="16.214"
            transform="translate(253.107 53)"
            fill="none"
            stroke="#2c25d0"
            stroke-miterlimit="10"
            stroke-width="2" />
          <line
            id="Line_164"
            data-name="Line 164"
            x2="16.214"
            transform="translate(245 61.107)"
            fill="none"
            stroke="#2c25d0"
            stroke-miterlimit="10"
            stroke-width="2" />
        </g>
      </svg>
    </button>
  </header>
  <app-search-bar
    [resultsEnabled]="true"
    class="w-100"
    (closeEvent)="close()"
    [requestFocus]="true"></app-search-bar>
</div>

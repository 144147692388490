import { TranslateService } from '@ngx-translate/core';
import { OsMapService } from './services/os-map.service';
import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  Output,
  ElementRef,
  Renderer2,
  ViewChild,
  EventEmitter
} from '@angular/core';
import * as mapboxgl from 'maplibre-gl';
import { isNullOrUndefined } from '../../extensions/extensions';
import { PinsListModel, SelectedPinModel } from './models/os-map.model';

@Component({
  selector: 'app-os-maps',
  templateUrl: './os-maps.component.html',
  styleUrls: ['./os-maps.component.scss']
})
export class OsMapsComponent implements OnInit, OnChanges {
  @Input() pinsList: SelectedPinModel[];
  @Input() selectedSinglePin: SelectedPinModel;
  @Input() selectedPinsGroup: PinsListModel;
  @Input() defaultMapZoom = 5;
  @Input() pinChangeSizeZoom = 5;
  @Input() centerMapLat = 52.269548;
  @Input() centerMapLng = 19.368265;
  @Input() mapBounds: number[] = [13.150390625, 48.53786794532644, 24.994140625, 55.52714186454645];
  @Input() popupMaxWidth = '240px';
  @Input() popupBtnText: string = this.translateService.instant('MAPS.choose');
  @Input() popupBtnTextActive: string = this.translateService.instant('MAPS.choosen');
  @Input() markerIconUrl = 'assets/images/pointer-map.svg';
  @Input() markerHeight = 32;
  @Input() markerWidth = 20;
  @Input() mapAnimationSpeed = 1.7;
  @Input() markerAnchor = 'bottom';
  @Input() openPopupOnZoomEnd = true;
  @Input() popupInfos: (keyof SelectedPinModel)[] = ['address'];
  @Output() markerSelected: EventEmitter<any> = new EventEmitter();
  style: any;
  map: mapboxgl.Map;
  popups: Array<any> = [];
  lastOpenedPopup: any;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private osMapService: OsMapService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.osMapService.getStyle('osm-liberty').subscribe((response) => {
      this.style = response;
      this.buildMap(this.style);
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.selectedSinglePin &&
      !isNullOrUndefined(changes.selectedSinglePin.currentValue) &&
      changes.selectedSinglePin.currentValue !== changes.selectedSinglePin.previousValue
    ) {
      this.setMap(
        parseFloat(this.selectedSinglePin.latitude),
        parseFloat(this.selectedSinglePin.longitude),
        17
      );
      const activePopup = this.popups.find((popup) => {
        const coords = popup.getLngLat();
        return (
          coords.lat === parseFloat(this.selectedSinglePin.latitude) &&
          coords.lng === parseFloat(this.selectedSinglePin.longitude)
        );
      });

      if (activePopup) {
        if (!isNullOrUndefined(this.lastOpenedPopup)) {
          this.lastOpenedPopup.options.className = '';
          this.lastOpenedPopup._content.children.namedItem('choose-btn').innerText =
            this.popupBtnText;
          this.lastOpenedPopup.remove();
        }

        this.popups.forEach((popup) => popup.remove());

        activePopup._content.children.namedItem('choose-btn').innerText = this.popupBtnTextActive;
        // active class is added in two variants. First for existing popups, second for not existing yet popups
        activePopup.addClassName('active');
        activePopup.options.className = 'active';
        this.lastOpenedPopup = activePopup;

        if (this.openPopupOnZoomEnd) {
          this.map.once('zoomend', () => {
            activePopup.addTo(this.map);
          });
        }
      }
    } else if (
      changes.selectedPinsGroup &&
      changes.selectedPinsGroup.currentValue !== changes.selectedPinsGroup.previousValue
    ) {
      this.setMap(
        parseFloat(this.selectedPinsGroup.latitude),
        parseFloat(this.selectedPinsGroup.longitude),
        this.selectedPinsGroup.zoom
      );
    }
  }

  changePinsSizeBasedOnZoom(element, popupBottomOffset, popupElement) {
    if (this.map.getZoom() < this.pinChangeSizeZoom) {
      element.style.width = `${this.markerWidth - Math.round(this.map.getZoom())}px`;
      element.style.height = `${this.markerHeight - Math.round(this.map.getZoom())}px`;
      popupBottomOffset = this.markerHeight - Math.round(this.map.getZoom());
      popupElement.setOffset({
        bottom: [0, -popupBottomOffset],
        'bottom-right': [0, -popupBottomOffset],
        'bottom-left': [0, -popupBottomOffset]
      });
    } else {
      element.style.width = `${this.markerWidth + Math.round(this.map.getZoom())}px`;
      element.style.height = `${this.markerHeight + Math.round(this.map.getZoom())}px`;
      popupBottomOffset = this.markerHeight + Math.round(this.map.getZoom());
      popupElement.setOffset({
        bottom: [0, -popupBottomOffset],
        'bottom-right': [0, -popupBottomOffset],
        'bottom-left': [0, -popupBottomOffset]
      });
    }
  }

  preparePopupHTML(marker) {
    return (
      `<h2>${marker.name}</h2>` +
      this.popupInfos
        .map((info) => (marker[info] ? `<p>${marker[info]}​​​​​​​​</p>` : null))
        .join('') +
      `<button id="choose-btn" class="choose-btn" value=${marker.id},${marker.provinceId}>${this.popupBtnText}</button>`
    );
  }

  buildMap(style) {
    if (!mapboxgl.supported()) {
      console.warn('Mapa nie supportuje webGL');
    } else {
      this.map = new mapboxgl.Map({
        container: 'map',
        style: style,
        zoom: this.defaultMapZoom,
        center: [this.centerMapLng, this.centerMapLat],
        maxBounds: this.mapBounds as mapboxgl.LngLatBoundsLike
      });

      this.map.addControl(new mapboxgl.NavigationControl());

      if (this.pinsList) {
        this.pinsList.forEach((marker) => {
          const el = document.createElement('div');
          el.className = 'marker';
          el.style.backgroundImage = `url(${this.markerIconUrl})`; // path to marker icon (dark color - used on icon hover)
          el.style.width = `${this.markerWidth}px`;
          el.style.height = `${this.markerHeight}px`;
          const popupBottomOffset = this.markerHeight;
          const markerCountyName = marker.countyName ? marker.countyName.replace(' ', '_') : '';

          const popup = new mapboxgl.Popup({
            offset: {
              bottom: [0, -popupBottomOffset],
              'bottom-right': [0, -popupBottomOffset],
              'bottom-left': [0, -popupBottomOffset]
            }
          })
            .setLngLat([Number(marker.longitude), Number(marker.latitude)])
            .setHTML(
              // NOTE add data attribute to button
              `<h2>${marker.name}</h2><p>${marker.address}</p><button id="choose-btn" class="choose-btn" value=${marker.id},${marker.provinceId},${markerCountyName}>${this.popupBtnText}</button>`
            )
            .setMaxWidth(this.popupMaxWidth);

          this.popups.push(popup);
          this.changePinsSizeBasedOnZoom(el, popupBottomOffset, popup);
          this.map.on('zoom', () => {
            this.changePinsSizeBasedOnZoom(el, popupBottomOffset, popup);
          });

          new mapboxgl.Marker({
            element: el,
            anchor: this.markerAnchor as mapboxgl.Anchor
          })
            .setLngLat([Number(marker.longitude), Number(marker.latitude)])
            .setPopup(popup)
            .addTo(this.map);
        });
      }

      this.renderer.listen(this.elementRef.nativeElement, 'click', (event) => {
        if (event.target.value) {
          const [markerId, provinceId, countyName] = event.target.value.split(',');
          const values = {
            provinceId: provinceId,
            markerId: markerId,
            countyName: countyName.replace('_', ' ')
          };

          this.markerSelected.emit(values);
        }
      });

      if (this.selectedSinglePin) {
        this.setMap(
          parseFloat(this.selectedSinglePin.latitude),
          parseFloat(this.selectedSinglePin.longitude),
          17
        );
      } else if (this.selectedPinsGroup) {
        this.setMap(
          parseFloat(this.selectedPinsGroup.latitude),
          parseFloat(this.selectedPinsGroup.longitude),
          this.selectedPinsGroup.zoom
        );
      }
    }
  }

  setMap(latitude: number, longitude: number, zoom: number) {
    if (this.map) {
      this.map.flyTo({
        center: [longitude, latitude],
        zoom,
        speed: this.mapAnimationSpeed
      });
    }
  }
}

import { ModalRef } from './modal-ref';
import { Directive } from '@angular/core';

// TODO: [RK] add generic BaseModal Type for generic modal reference data.
@Directive()
export abstract class BaseModalDirective {
  public ref: ModalRef;
  constructor() {}

  getData(): any {
    if (!this.ref) {
      return null;
    }
    return this.ref.data;
  }

  close(data?: any) {
    if (this.ref) {
      this.ref.close(data);
    }
  }
}

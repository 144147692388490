<div class="custom-toastr">
  <div class="toast-info">
    <div class="toast-message">
      {{ 'TOASTR.STC.AUTOSAVE_INFO.AUTOSAVE' | translate }}
    </div>
  </div>
  <div class="autosave-info">
    <h2 class="autosave-info__title">
      {{ 'TOASTR.STC.AUTOSAVE_INFO.TITLE' | translate }}
    </h2>
    <p *ngIf="!isAnonymous" class="autosave-info__info">
      {{ 'TOASTR.STC.AUTOSAVE_INFO.CONTENT' | translate }}
      <b> {{ 'TOASTR.STC.AUTOSAVE_INFO.DRAFTS' | translate }}</b>
    </p>
    <p *ngIf="isAnonymous">
      {{'TOASTR.STC.AUTOSAVE_INFO.CONTENT_ANONYMOUS' | translate}}
    </p>
    <span class="autosave-info__submit" role="button" (click)="submitAutosave($event)">{{
      'TOASTR.STC.AUTOSAVE_INFO.SUBMIT_LABEL' | translate
    }}</span>
  </div>
</div>

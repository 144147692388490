<section class="choose-employee-modal" *ngIf="content">
  <div class="loader" *ngIf="!filteredEmployes && !chooseEmployeeModalTexts">
    <app-loaders type="dots"></app-loaders>

    <ic-ghost-button
      [type]="GhostButtonType.BIG_FIRST_RANK_FLEX"
      [justifyContent]="'center'"
      [showArrow]="false"
      [width]="140"
      (click)="onCancelClick()">
      <span class="forwardSpan start-btn__label">{{ content.closeBtn }}</span>
    </ic-ghost-button>
  </div>

  <div class="container" *ngIf="filteredEmployes">
    <section *ngIf="impersonalCardAllowed()">
      <div class="header">
        <span class="title">{{ content.title }}</span>
        <img
          role="button"
          alt="Ikona - zamknij okno"
          class="close-btn"
          tabindex="0"
          (keydown.enter)="onCancelClick()"
          (click)="onCancelClick()"
          src="assets/images/close_mobile_.svg" />
      </div>

      <div class="tile-list">
        <div class="tile" role="button" (click)="onImpersonalCardClick()">
          <span class="name">{{ content.controlCard }}</span>
        </div>
      </div>
      <div class="divider">{{ content.or }}</div>
    </section>

    <section>
      <div class="header">
        <span class="title">{{ content.chooseEmployee }}</span>
      </div>
      <div class="ic-hint-light" *ngIf="filteredEmployes.length === 0">
        <img src="assets/images/info_ic_blue.svg" alt="{{ content.infoIconAria }}" />
        <span>
          {{ content.noEmployeeOnList }}
          <a role="button" (click)="onAddEmployeeLinkClick()">{{ content.addEmployee }}</a>
        </span>
      </div>

      <ng-template [ngTemplateOutlet]="inputRef"></ng-template>

      <div class="tile-list" *ngIf="filteredEmployes.length > 0">
        <div *ngFor="let employee of filteredEmployes">
          <div
            class="tile"
            tabindex="0"
            role="button"
            appTriggerClick
            (click)="selectEmployee(employee)">
            <span class="name" (onClick)="selectEmployee(employee)">
              {{ employee.person.firstName }}
              {{ employee.person.lastName }}
            </span>
          </div>
        </div>
      </div>
    </section>

    <ic-ghost-button
      [type]="GhostButtonType.BIG_FIRST_RANK_FLEX"
      [justifyContent]="'center'"
      [showArrow]="false"
      [width]="140"
      (click)="onCancelClick()">
      <span class="forwardSpan start-btn__label">{{ content.closeBtn }}</span>
    </ic-ghost-button>
  </div>
</section>

<ng-template #inputRef>
  <div class="filter-input-wrapper" [formGroup]="filterForm">
    <input
      #searchInput
      formControlName="filterQuery"
      [placeholder]="'STC.EMPLOYEES_LIST.filterEmployeesPlaceholder' | translate"
      [attr.title]="'STC.EMPLOYEES_LIST.filterEmployeesPlaceholder' | translate"
      [attr.aria-label]="'STC.EMPLOYEES_LIST.filterEmployeesPlaceholder' | translate" />
    <img
      src="assets/images/search_icon_dark.svg"
      [attr.aria-label]="'MAIN_PAGE.SEARCH_SECTION.aria-label' | translate"
      alt="search"
      (click)="setFocusOnSearchInput()"
      role="button" />
  </div>
</ng-template>

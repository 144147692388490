<div class="ic-input">
  <label
    class="ic-input__label"
    [for]="inputId"
    [ngClass]="{
      warn: showWarning(),
      error: isReportedForCorrection && !isTouched(),
      'required-field': isRequired,
      'sublabel-spaceing': subLabelSpaceing
    }"
    >{{ label }}</label
  >
  <span [ngClass]="{ 'ic-input__subLabel': true, 'wrap-sub-label': wrapSubLabel }" *ngIf="subLabel">
    {{ subLabel }}
  </span>
  <div
    class="input-flex"
    [ngClass]="{
      'input-flex': true,
      warn: showWarning(),
      error: isReportedForCorrection && !isTouched(),
      modified: isReportedForCorrection && isTouched() && isValid()
    }">
    <ng-template [ngIf]="mask && mask !== 'digit'" [ngIfElse]="genericInput">
      <input
        [textMask]="MaskConfig[mask]"
        [id]="inputId"
        [value]="value"
        [disabled]="disabled"
        (input)="onChange($event.target.value)"
        (blur)="onPropagateBlur($event)"
        (click)="onClick()"
        (load)="onChange($event.target.value)"
        [ngClass]="{
          invalid:
            formGroup.controls[formControlName].invalid &&
            formGroup.controls[formControlName].touched
        }"
        [type]="type"
        [attr.title]="placeholder"
        [attr.aria-label]="placeholder"
        [placeholder]="placeholder"
        [attr.maxlength]="maxLength" />
    </ng-template>
    <ng-template #genericInput>
      <input
        [id]="inputId"
        [value]="value"
        [disabled]="disabled"
        (input)="onInputMask($event)"
        (blur)="onPropagateBlur($event)"
        (click)="onClick()"
        [ngClass]="{
          invalid:
            formGroup.controls[formControlName].invalid &&
            formGroup.controls[formControlName].touched
        }"
        [type]="type"
        [placeholder]="placeholder"
        [attr.maxlength]="maxLength"
        [attr.min]="min"
        [attr.title]="placeholder"
        [attr.aria-label]="placeholder"
        [attr.max]="max"
        [attr.step]="step" />
      <div class="spinners-container" *ngIf="type === 'number'">
        <button
          class="number-input-spinner spinner-up"
          type="button"
          [attr.aria-label]="'SHARED.INPUT_FORM.add' | translate"
          (click)="incrementValue()">
          +
        </button>
        <button
          class="number-input-spinner spinner-down"
          [attr.aria-label]="'SHARED.INPUT_FORM.subtract' | translate"
          type="button"
          (click)="decrementValue()">
          -
        </button>
      </div>
    </ng-template>
    <div
      *ngIf="
        formGroup.controls[formControlName].invalid && formGroup.controls[formControlName].touched
      "
      class="error-icon"></div>
  </div>
  <div class="error-description-container">
    <div *ngFor="let error of errorList">
      <span *ngIf="onHasOneOfErrors(error.type)" class="error-description">{{ error.label }}</span>
    </div>
    <span *ngIf="showWarning()" class="warn-descriptor">{{
      'STC.CORRECTIONS.FIELD_HINT' | translate: { fieldName: label }
    }}</span>
  </div>
  <div *ngIf="info" class="info-description-container">
    <p [innerHtml]="info"></p>
  </div>
</div>

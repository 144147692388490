export const SERVICE_CATEGORY_ROUTE = '';
export const CATEGORY_NAME_ROUTE = ':name';
export const DRIVER_LICENSE_ROUTE = 'prawo-jazdy';
export const VEHICLE_REGISTRATION_ROUTE = 'rejestracja-pojazdu';
export const TACHOGRAPH_ROUTE = 'tachograf';
export const ADR_ROUTE = 'adr';
export const DOCUMENT_INFORMATION_ROUTE = 'informacje-o-dokumencie';
export const COMPLAINT_ROUTE = 'zloz-reklamacje-karty-do-tachografu-i-uzyskaj-karte-zastepcza';
export const CERTIFICATE_RULES_ROUTE = 'zasady-zdobywania-zaswiadczenia';
export const ADR_EXAM_ROUTE = 'egzamin';
export const VEHICLE_TEMPORARY_WITHDRAWAL = 'czasowe-wycofanie-pojazdu-z-ruchu';
export const FIND_OSK = 'znajdz-szkole-jazdy';
export const FIND_DISTRICT_OFFICE = 'zobacz-gdzie-mozesz-zlozyc-wniosek-o-pkk';
export const FIND_WORD = 'zobacz-gdzie-mozesz-zdawac-egzamin';
export const DRIVER_LICENSE_PAYMENT = 'zaplac-za-wydanie-prawa-jazdy';
export const VEHICLE_REGISTRATION_PAYMENT = 'zaplac-za-wydanie-dowodu-rejestracyjnego';

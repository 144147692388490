import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {DomSanitizer} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import fileType from 'file-type';
import {Observable, Subject, Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {Extensions} from 'src/app/shared/extensions/extensions';
import {AttachmentType} from 'src/app/shared/models/attachments/attachment-dto.model';
import {ErrorDto} from 'src/app/shared/models/errors/error-dto.model';
import {
  CroppedImageInfoModalComponent
} from '../../../modal/cropped-image-info-modal/cropped-image-info-modal.component';
import {CroppedImageModalComponent} from '../../../modal/cropped-image-modal/cropped-image-modal.component';
import {MODAL_OVERLAY_CLICKED} from '../../../modal/modal-ref';
import {ApplicationAttachmentType, AttachmentEntity, AttachmentMetadata} from '../model';
import {AttachmentsFacadeService, AttachmentsFormBinder} from '../services';
import {ModalService} from '../../../modal/modal.service';

@Component({
  selector: 'app-attachments-type-group',
  templateUrl: './attachments-type-group.component.html',
  styleUrls: ['./attachments-type-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttachmentsTypeGroupComponent implements OnInit, OnDestroy {
  @Input() config: AttachmentMetadata;
  @Input() htmlId: string;
  @Input() applicationType: ApplicationAttachmentType;
  @Input() correctionChecksums: string[];
  @Input() disableAddAttachment: boolean = false;
  form: UntypedFormGroup;
  formStatusChanges$: Observable<string>;
  sub: Subscription;
  pageTexts: any;
  backendErrors: ErrorDto[];
  file: Subject<Array<File>> = new Subject();
  valuesArray = [];
  showWaring: boolean;

  constructor(
    private attachmentsService: AttachmentsFacadeService,
    private translateService: TranslateService,
    private modalService: ModalService,
    private formBinder: AttachmentsFormBinder,
    private _DomSanitizationService: DomSanitizer
  ) {
    this.form = this.formBinder.getForm();
    this.formStatusChanges$ = this.form.statusChanges.pipe(
      filter((status) => status === 'VALID' || status === 'INVALID')
    );
  }

  ngOnInit() {
    this.sub = this.translateService.get('STC.ATTACHMENTS').subscribe((res: any) => {
      this.pageTexts = res;
    });
  }

  onDeleteAttachment(attachment: AttachmentEntity) {
    attachment.errorMessages
      ? (this.backendErrors = attachment.errorMessages)
      : (this.backendErrors = null);
    this.attachmentsService.deleteAttachment(attachment, this.applicationType);
  }

  onUploadAttachment(attachment: AttachmentEntity) {
    this.attachmentsService.uploadAttachment(attachment, this.applicationType);
  }

  onImageChanged() {
    this.backendErrors = null;
  }

  onFileDrop(event) {
    if (event) {
      this.file.next(event);
    }
  }

  onEditAttachment(attachment: AttachmentEntity) {
    const fileContent = Extensions.base64toArrayBuffer(attachment.dto.content);
    const fileTypeConst = fileType(fileContent).mime;
    const fileName = attachment.dto.fileName;

    const uncroppedImage = this._DomSanitizationService.bypassSecurityTrustUrl(
      URL.createObjectURL(new Blob([fileContent]))
    );
    const ref = this.modalService.open(CroppedImageModalComponent, {
      data: {
        freezeOverlay: true,
        cropperImage: uncroppedImage,
        fileType: fileTypeConst,
        attachmentType: this.config.type,
        imageEdited: true
      }
    });
    ref.afterClosed.subscribe((result) => {
      if (result === null || result === MODAL_OVERLAY_CLICKED) {
        return;
      } else if (result === 'DELETED') {
        this.onDeleteAttachment(attachment);
      }

      const reader = new FileReader();
      reader.readAsBinaryString(result);
      reader.onload = () => {
        const fileList = Array.of(new File([result], fileName, { type: fileTypeConst }));
        this.attachmentsService.deleteAttachment(attachment, this.applicationType).add(() => {
          this.attachmentsService.createReadyToUploadAttachmentsFromFiles(
            this.config.type,
            fileList,
            this.applicationType
          );
        });
      };
    });
  }

  openPhotoInstruction(event) {
    event.preventDefault();
    this.modalService.open(CroppedImageInfoModalComponent, {
      data: { maxHeight: true }
    });
  }

  selectByAttachmentType(type: AttachmentType): Observable<AttachmentEntity[]> {
    return this.attachmentsService.selectEntitiesByType(type);
  }

  trackById(index: number, entity: AttachmentEntity) {
    return entity.id || null;
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  showWarn(value: boolean): void {
    this.valuesArray.push(value);
    this.showWaring = this.valuesArray.some((value) => value);
    this.valuesArray = [];
  }
}

import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderStripeService {
  private isOpened = new Subject<boolean>();

  constructor() {
    this.isOpened.next(false);
  }

  open() {
    this.isOpened.next(true);
  }

  close() {
    this.isOpened.next(false);
  }

  notifyIsOpened(): Observable<boolean> {
    return this.isOpened.asObservable();
  }
}

import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { GhostButtonType } from 'src/app/shared/components/ghost-button/ghost-button-type.enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit, OnDestroy {
  public errorStatus: number;
  private sub: Subscription;
  public errorTitle: string;
  public isMobile = false;

  GhostButtonType: typeof GhostButtonType = GhostButtonType;

  constructor(private route: ActivatedRoute, private translate: TranslateService) {}

  ngOnInit() {
    this.checkIsMobile();
    this.sub = this.route.params.subscribe((params: { errorStatus: number }) => {
      this.errorStatus = params.errorStatus / 2 ? Number(params.errorStatus) : 0;
      this.setErrorTitle(this.errorStatus);
    });
  }

  @HostListener('window:resize', ['$event'])
  checkIsMobile() {
    if (window.innerWidth <= 576) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }
  private setErrorTitle(errorStatus: number): void {
    this.translate.get('ERROR.TITLES').subscribe((titles: string) => {
      this.errorTitle = titles[errorStatus] ? titles[errorStatus] : titles['DEFAULT'];
    });
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}

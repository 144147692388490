import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as mainPage from './main-page.action';
import { Slider } from '../slider/models/slider.model';
import { CategorySectionTitleModel } from '../categories-services/_model/category-section-titles.model';

export interface MainPageState {
  sliderData: Slider;
  categorySection: CategorySectionTitleModel;
}

export const MainPageInitialState: MainPageState = {
  sliderData: null,
  categorySection: null
};

export function MainPageReducer(
  state: MainPageState = MainPageInitialState,
  action: mainPage.Actions
): MainPageState {
  switch (action.type) {
    case mainPage.ActionTypes.SET_SLIDER:
      return Object.assign({}, state, {
        sliderData: action.payload
      });
    case mainPage.ActionTypes.SET_CATEGORY_SECTION:
      return Object.assign({}, state, {
        categorySection: action.payload
      });
    default:
      return state;
  }
}

export const featureName = 'main-page';

export const selectMainPageState = createFeatureSelector<MainPageState>(featureName);

export const selectSliderData = createSelector(
  selectMainPageState,
  (state: MainPageState) => state.sliderData
);

export const selectCategorySectionData = createSelector(
  selectMainPageState,
  (state: MainPageState) => state.categorySection
);

<ng-container *ngIf="styleType === 'bordered'">
  <!--  todo dodać obsługę warna dla stylu bordered jeżeli będzie potrzebna-->
  <label
    [for]="inputId"
    class="ic-input bordered"
    [ngClass]="{
      'checkbox-invalid':
        formGroup?.controls[formControlName].invalid &&
        formGroup?.controls[formControlName].touched,
      active: checked,
      'required-field': isRequired
    }"
    [for]="inputId">
    <input
      tabindex="0"
      appTriggerClick
      [id]="inputId"
      [checked]="checked"
      (change)="forwardChanged($event.target.checked)"
      [ngClass]="{
        invalid: formGroup?.controls[formControlName].invalid || forceInvalid,
        switch: styleType === 'toggle'
      }"
      type="checkbox"
      [disabled]="disabled" />
    <p>
      <span appInternalLink [innerHtml]="label | safeHtml"></span>
      <span *ngIf="sublabel" class="sublabel">{{ sublabel }}</span>
    </p>
  </label>

  <div *ngIf="isErrorContainer" class="error-description-container">
    <div *ngFor="let error of errorList">
      <span *ngIf="hasOneOfErrors(error.type)" class="error-description">{{ error.label }}</span>
    </div>
    <span *ngIf="showWarning()" class="warn-descriptor">{{
      'STC.CORRECTIONS.FIELD_HINT' | translate: { fieldName: label }
    }}</span>
  </div>
</ng-container>

<div class="ic-input" *ngIf="styleType !== 'bordered'">
  <div
    class="input-flex"
    [ngClass]="{
      error: isReportedForCorrection && !isTouched(),
      warn: showWarning(),
      modified: isReportedForCorrection && isTouched() && isValid(),
      'checkbox-invalid':
        formGroup?.controls[formControlName].invalid && formGroup?.controls[formControlName].touched
    }">
    <input
      tabindex="0"
      appTriggerClick
      [id]="inputId"
      [checked]="checked"
      (change)="forwardChanged($event.target.checked)"
      [ngClass]="{
        invalid: formGroup?.controls[formControlName].invalid || forceInvalid,
        switch: styleType === 'toggle'
      }"
      type="checkbox"
      [disabled]="disabled" />
    <label
      class="checkbox-label"
      [for]="inputId"
      [ngClass]="{ 'required-field': isRequired, bolded: bolded }">
      <div *ngIf="styleType === 'toggle'" class="switch">
        <div class="dot"></div>
      </div>
      <span appInternalLink [innerHtml]="label | safeHtml"></span>
      <span
        *ngIf="button"
        [ngClass]="{ 'required-field': isRequiredAfterButton }"
        class="checkbox-button"
        [attr.aria-label]="'SHARED.INPUT_FORM.openModal'"
        role="button"
        (click)="openModal($event)"
        >{{ button }}
      </span>
    </label>
  </div>
  <div *ngIf="isErrorContainer" class="error-description-container">
    <div *ngFor="let error of errorList">
      <span *ngIf="hasOneOfErrors(error.type)" class="error-description">{{ error.label }}</span>
    </div>
    <span *ngIf="showWarning()" class="warn-descriptor">{{
      'STC.CORRECTIONS.FIELD_HINT' | translate: { fieldName: label }
    }}</span>
  </div>
</div>

import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { Subject } from 'rxjs';
import { GhostButtonType } from 'src/app/shared/components/ghost-button/ghost-button-type.enum';
import { DAY, isNullOrUndefined } from 'src/app/shared/extensions/extensions';
import { ExamType } from '../_models/available-exam';
import { Day } from '../_models/day';
import { ExamDate } from '../_models/exam-date';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit, AfterViewInit {
  @Input() currentDateSubject: Subject<any>;
  @Input() activeFilter: ExamType;
  @Output() changeDateEvent: EventEmitter<any> = new EventEmitter();
  @Output() dateConfirmed: EventEmitter<void> = new EventEmitter();
  GhostButtonType = GhostButtonType;
  filterType: ExamType;
  currentDate: ExamDate;
  showDaysCount: number;
  maxDaysCount: number;
  currentDayIndex: number;
  buttonWidth = '49';
  buttonHeight = '49';
  filteredDays: Day[];
  datesLength: number;
  fromDate: Date;
  endIndex: number;
  toDate: Date;
  days: Day[];
  window = window;
  mobileDays: Day[];
  mobileDaysOfWeek;
  loading: boolean;

  constructor() {}

  ngOnInit() {
    this.window.scroll(0, 0);
    this.endIndex = 3;
    this.loading = true;
    if (this.currentDateSubject) {
      this.currentDateSubject.subscribe((date) => {
        this.fromDate = date.fromDate;
        this.toDate = date.toDate;
        this.currentDate = date;
        this.datesLength = this.calculateDatesLength();
      });
    }
  }
  calculateDatesLength(): any {
    const oneDay = DAY;
    return Math.round(Math.abs((this.fromDate.getTime() - this.toDate.getTime()) / oneDay));
  }
  getFilteredDays(): any {
    if (this.calculateDatesLength() < this.showDaysCount) {
      this.showDaysCount = this.calculateDatesLength() + 1;
    } else {
      this.showDaysCount = this.maxDaysCount;
    }
    if (this.days !== undefined && !isNullOrUndefined(this.filterType)) {
      let filteredDays: Day[];
      filteredDays = JSON.parse(JSON.stringify(this.days));
      this.filteredDays = filteredDays.filter((day) =>
        day.dates.map(
          (date) =>
            (date.avalaibleExams = date.avalaibleExams.filter(
              (exam) => exam.type === this.filterType
            ))
        )
      );
    } else if (isNullOrUndefined(this.filterType)) this.filteredDays = this.days;
    this.setMobilePaginatedDates(this.endIndex);
  }
  @HostListener('window:resize', ['$event'])
  onresize(event?) {
    this.changeShowDaysCount(window.innerWidth);
  }
  changeShowDaysCount(viewPortWidth: number): any {
    if (viewPortWidth < 768) {
      this.showDaysCount = 1;
      this.setMobilePaginatedDates(3);
    } else {
      this.showDaysCount = 4;
    }
    this.maxDaysCount = this.showDaysCount;
  }
  setMobilePaginatedDates(endIndex: number) {
    if (this.filteredDays) {
      this.mobileDays = this.filteredDays.slice(0, endIndex);
      this.mobileDaysOfWeek = this.filteredDays.slice(0, endIndex);
    }
  }
  ngAfterViewInit(): void {
    this.changeShowDaysCount(window.innerWidth);
  }

  showNextDay() {
    if (this.currentDayIndex < this.filteredDays.length - this.showDaysCount)
      this.currentDayIndex += this.showDaysCount;
  }
  showPreviousDay() {
    if (Number(this.currentDayIndex) !== 0) {
      this.currentDayIndex -= this.showDaysCount;
    }
  }
  onScrollDown() {
    this.endIndex += 3;
    this.setMobilePaginatedDates(this.endIndex);
  }
  changeDate() {
    this.changeDateEvent.emit();
  }

  onDateConfirmed() {
    this.dateConfirmed.emit();
  }
}

import { GhostButtonType } from 'src/app/shared/components/ghost-button/ghost-button-type.enum';
import { Extensions } from 'src/app/shared/extensions/extensions';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { BaseModalDirective } from 'src/app/shared/components/modal/base-modal';
import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { ImageButtonType } from '../../image-edit-button/image-edit-button.enum';
import { CropperComponent } from 'angular-cropperjs';
import { TranslateService } from '@ngx-translate/core';
import { CropperImageModel } from './model/cropped-image-model.model';
import { AttachmentType } from 'src/app/shared/models/attachments/attachment-dto.model';
import * as changedpi from 'changedpi';

const MIN_DIMENSIONS = {
  [AttachmentType.PHOTO]: {
    width: 492,
    height: 633
  },
  [AttachmentType.SIGNATURE_SCAN]: {
    width: 591,
    height: 118
  }
};

const MAX_DIMENSIONS = {
  long: 5000,
  short: 3000
};

const ASPECT_RATIOS = {
  [AttachmentType.PHOTO]:
    MIN_DIMENSIONS[AttachmentType.PHOTO].width / MIN_DIMENSIONS[AttachmentType.PHOTO].height,
  [AttachmentType.SIGNATURE_SCAN]:
    MIN_DIMENSIONS[AttachmentType.SIGNATURE_SCAN].width /
    MIN_DIMENSIONS[AttachmentType.SIGNATURE_SCAN].height
};

@Component({
  selector: 'app-cropped-image-modal',
  templateUrl: './cropped-image-modal.component.html',
  styleUrls: ['./cropped-image-modal.component.scss']
})
export class CroppedImageModalComponent extends BaseModalDirective implements OnInit {
  ImageButtonType: typeof ImageButtonType = ImageButtonType;

  @ViewChild('cropperElement') cropperElement: CropperComponent;

  cropperImage;
  fileType: string;
  attachmentType: AttachmentType;
  mimeType: string;
  allowedToChangeDPI = ['image/jpeg', 'image/png'];
  isLoading: boolean;
  GhostButtonType = GhostButtonType;

  cropperConfig = {
    aspectRatio: null,
    cropBoxResizable: true,
    fillColor: '#fff',
    zoomOnWheel: true,
    zoomable: true,
    scalable: true,
    movable: true,
    responsive: true,
    dragMode: 'move',
    modal: false,
    guides: true,
    background: true,
    highlight: true,
    autoCropArea: 1,
    viewMode: 1,
    minContainerWidth: 0,
    minContainerHeight: 0,
    center: true
  };
  pageTexts: CropperImageModel;
  sub: Subscription;
  isMobile: boolean;
  minWidth: number;
  minHeight: number;
  cropperReady: boolean;
  imageEdited: boolean;
  blob: Blob;
  showSaveAttemptModal: boolean = false;
  thumbnail: string;
  AttachmentType = AttachmentType;

  constructor(private translateService: TranslateService, private toastrService: ToastrService) {
    super();
  }

  ngOnInit() {
    const data = this.getData();
    this.cropperImage = data.cropperImage;
    this.fileType = data.fileType;
    this.mimeType = data.mimeType;
    this.imageEdited = data.imageEdited;
    this.attachmentType = data.attachmentType;
    [this.minWidth, this.minHeight] = this.minDimensions(this.attachmentType);

    this.sub = this.translateService.get('STC.ATTACHMENTS.MODAL').subscribe((res: any) => {
      this.pageTexts = res;
    });
    this.chooseAspectRatio();
    this.onResize();
    this.setGuidesInPhotoType();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    if (window.outerWidth <= 576) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  readyCrop($event) {
    this.cropperReady = true;
    let imgSizeError: boolean;
    const imgData = this.cropperElement.cropper.getImageData();

    const insufficientLongerSide =
      Math.max(imgData.naturalHeight, imgData.naturalWidth) <
      Math.max(this.minHeight, this.minWidth);
    const insufficientShorterSide =
      Math.min(imgData.naturalHeight, imgData.naturalWidth) <
      Math.min(this.minHeight, this.minWidth);

    if (insufficientLongerSide || insufficientShorterSide) {
      imgSizeError = true;
    }

    if (imgSizeError) {
      this.toastrService.error(
        this.pageTexts.size_error + ' ' + this.minWidth + 'x' + this.minHeight
      );
      this.close(null);
    }

    const [cropBoxMinWidth, cropBoxMinHeight] = this.calcCropBoxMinDimensions(imgData);

    this.setCropBoxMinDimensions(cropBoxMinWidth, cropBoxMinHeight);

    const cropBoxData = this.cropperElement.cropper.getCropBoxData();
    if (cropBoxData.width < cropBoxMinWidth || cropBoxData.height < cropBoxMinHeight) {
      this.cropperElement.cropper.setCropBoxData({
        width: cropBoxMinWidth,
        height: cropBoxMinHeight
      });
    }
  }

  b64toBlob(dataURI) {
    const byteString = atob(dataURI.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: this.fileType });
  }

  resizeTooBigImage(base64Str, MAXLONG, MAXSHORT, horizontal) {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = base64Str;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        let width = img.width;
        let height = img.height;

        if (horizontal) {
          if (width > MAXLONG) {
            height *= MAXLONG / width;
            width = MAXLONG;
          }
          if (height > MAXSHORT) {
            width *= MAXSHORT / height;
            height = MAXSHORT;
          }
        } else {
          if (height > MAXLONG) {
            width *= MAXLONG / height;
            height = MAXLONG;
          }
          if (width > MAXSHORT) {
            height *= MAXSHORT / width;
            width = MAXSHORT;
          }
        }
        canvas.width = Math.round(width);
        canvas.height = Math.round(height);
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);
        resolve(canvas.toDataURL(this.fileType, 0.9));
      };
    });
  }

  checkIfImgNeedsResizing(
    canvasData: string,
    longerSide: number,
    shorterSide: number,
    horizontal: boolean
  ) {
    if (longerSide > MAX_DIMENSIONS.long || shorterSide > MAX_DIMENSIONS.short) {
      this.resizeTooBigImage(
        canvasData,
        MAX_DIMENSIONS.long,
        MAX_DIMENSIONS.short,
        horizontal
      ).then((result: string) => {
        this.changeDpiAndCreateBlobToUpload(result);
      });
    } else {
      this.changeDpiAndCreateBlobToUpload(canvasData);
    }
  }

  changeDpiAndCreateBlobToUpload(canvasData: string) {
    this.allowedToChangeDPI.includes(this.mimeType)
      ? (canvasData = changedpi.changeDpiDataUrl(canvasData, 300))
      : null;
    this.thumbnail = canvasData;
    this.blob = this.b64toBlob(canvasData);
    if (!this.showSaveAttemptModal) {
      this.close(this.blob)
    }
      
  }

  onImageSave() {
    this.isLoading = true;

    this.getCroppedImage();
  }

  getCroppedImage(): void {
    const canvas = this.cropperElement.cropper.getCroppedCanvas();
    const canvasData = canvas.toDataURL(this.fileType, 0.9);

    // Resize image if it's too big
    if (canvas.width > canvas.height) {
      // if horizontal img
      this.checkIfImgNeedsResizing(canvasData, canvas.width, canvas.height, true);
    } else {
      // if vertical
      this.checkIfImgNeedsResizing(canvasData, canvas.height, canvas.width, false);
    }
  }

  onSaveAttempt() {
    this.showSaveAttemptModal = true;
    this.getCroppedImage();
  }

  onSaveAttemptClose() {
    this.showSaveAttemptModal = false;
  }

  onImageCropUpload() {
    this.onSaveAttemptClose();
    this.close(this.blob)
  }

  onClose() {
    this.close(null);
  }

  onDelete() {
    this.close('DELETED');
  }

  zoom(zoom: number) {
    this.cropperElement.cropper.zoom(zoom);
    const [cropBoxMinWidth, cropBoxMinHeight] = this.calcCropBoxMinDimensions(
      this.cropperElement.cropper.getImageData()
    );

    const containerData = this.cropperElement.cropper.getContainerData();
    if (containerData.width < cropBoxMinWidth || containerData.height < cropBoxMinHeight) {
      this.cropperElement.cropper.zoom(-zoom);
      return;
    }

    this.setCropBoxMinDimensions(cropBoxMinWidth, cropBoxMinHeight);

    const cropBoxData = this.cropperElement.cropper.getCropBoxData();
    if (cropBoxData.width < cropBoxMinWidth || cropBoxData.height < cropBoxMinHeight) {
      this.cropperElement.cropper.setCropBoxData({
        width: cropBoxMinWidth,
        height: cropBoxMinHeight
      });
    }
  }

  chooseAspectRatio() {
    if (ASPECT_RATIOS.hasOwnProperty(this.attachmentType)) {
      this.cropperConfig.aspectRatio = ASPECT_RATIOS[this.attachmentType];
    } else {
      this.cropperConfig.aspectRatio = null;
    }
  }

  setGuidesInPhotoType() {
    this.attachmentType === AttachmentType.PHOTO ? (this.cropperConfig.guides = false) : null;
  }

  resetCropper() {
    this.cropperElement.cropper.reset();

    const [cropBoxWidth, cropBoxHeight] = this.calcCropBoxMinDimensions(
      this.cropperElement.cropper.getImageData()
    );

    this.setCropBoxMinDimensions(cropBoxWidth, cropBoxHeight);

    this.cropperElement.cropper.reset();
  }

  private minDimensions(attachmentType: AttachmentType): [number, number] {
    if (MIN_DIMENSIONS.hasOwnProperty(attachmentType)) {
      return [MIN_DIMENSIONS[attachmentType].width, MIN_DIMENSIONS[attachmentType].height];
    } else {
      return [0, 0];
    }
  }

  private calcCropBoxMinDimensions(imgData: Cropper.ImageData): [number, number] {
    const cropBoxWidth = (this.minWidth * imgData.width) / imgData.naturalWidth;
    const cropBoxHeight = (this.minHeight * imgData.height) / imgData.naturalHeight;
    return [cropBoxWidth, cropBoxHeight];
  }

  private setCropBoxMinDimensions(width: number, height: number): void {
    // @ts-ignore
    this.cropperElement.cropper.options.minCropBoxWidth = width;
    // @ts-ignore
    this.cropperElement.cropper.options.minCropBoxHeight = height;
    // @ts-ignore
    this.cropperElement.cropper.limitCropBox(true, false);
  }
}

<app-loaders [fullScreen]="true" type="spinner" [show]="loading"></app-loaders>

<ng-container *ngIf="!loading && filteredDays && filteredDays.length > 0">
  <ng-container *ngIf="window.innerWidth >= 768">
    <div class="calendar-date-pick">
      <div class="picker-container container">
        <div
          id="left-button"
          class="picker-button"
          [ngClass]="{ 'no-active': currentDayIndex === 0 }">
          <ic-ghost-button
            (click)="showPreviousDay()"
            [showArrow]="true"
            [arrowLeft]="true"
            [width]="buttonWidth"
            [height]="buttonHeight">
          </ic-ghost-button>
        </div>
        <div class="days-picker row">
          <div
            *ngFor="
              let day of filteredDays | slice: currentDayIndex:currentDayIndex + showDaysCount
            "
            class="day col-12 col-sm-12 col-md-3 col-lg-3">
            <div class="day-name col-sm-12 col-md-12">
              {{ day.date | date: 'EEEE':undefined:'pl' }}
            </div>
            <div class="day-date col-sm-12 col-md-12">
              {{ day.date | date: 'd':undefined:'pl' }}
              {{ (day.date | date: 'MMMM':undefined:'pl').slice(0, 3) }}
            </div>
          </div>
        </div>
        <div
          *ngIf="filteredDays && filteredDays.length > 0"
          id="right-button"
          class="picker-button"
          [ngClass]="{
            'no-active': !(currentDayIndex < filteredDays.length - showDaysCount)
          }">
          <ic-ghost-button
            (click)="showNextDay()"
            [showArrow]="true"
            [width]="buttonWidth"
            [height]="buttonHeight">
          </ic-ghost-button>
        </div>
      </div>
    </div>
    <!--Calendar start-->
    <div class="container">
      <div class="row terms-row">
        <div
          *ngFor="let day of filteredDays | slice: currentDayIndex:currentDayIndex + showDaysCount"
          class="col-12 col-sm-12 col-md-3 col-lg-3 term">
          <ng-container *ngFor="let date of day.dates">
            <app-date [date]="date" (dateConfirmed)="onDateConfirmed()"></app-date>
          </ng-container>
        </div>
      </div>
    </div>
    <!--Calendar stop-->
  </ng-container>
  <ng-container *ngIf="window.innerWidth < 768">
    <div
      infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollUpDistance]="1.5"
      [infiniteScrollThrottle]="50"
      (scrolled)="onScrollDown()"
      [fromRoot]="true"
      *ngIf="window.innerWidth < 768"
      class="container">
      <div class="day-box" *ngFor="let day of mobileDays; let i = index">
        <div class="mobile-day-box">
          <div class="day-name">
            {{ day.date | date: 'EEEE':undefined:'pl' }}
          </div>
          <div class="day-date">
            {{ day.date | date: 'd':undefined:'pl' }}
            {{ (day.date | date: 'MMMM':undefined:'pl').slice(0, 3) }}
          </div>
        </div>
        <div *ngIf="mobileDays && mobileDays[i]" class="date">
          <ng-container *ngFor="let date of mobileDays[i].dates">
            <app-date [date]="date" (dateConfirmed)="onDateConfirmed()"></app-date>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

import { GhostButtonType } from './../../ghost-button/ghost-button-type.enum';
import { Component, OnInit } from '@angular/core';
import { BaseModalDirective } from '../base-modal';

@Component({
  selector: 'app-word-reservation-timed-out-modal',
  templateUrl: './word-reservation-timed-out-modal.component.html',
  styleUrls: ['./word-reservation-timed-out-modal.component.scss']
})
export class WordReservationTimedOutModalComponent extends BaseModalDirective implements OnInit {
  GhostButtonType = GhostButtonType;

  constructor() {
    super();
  }

  ngOnInit() {}

  onOkClick() {
    this.close(true);
  }

  onCancelClick() {
    this.close();
  }
}

import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2
} from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT, ViewportScroller } from '@angular/common';
import { animate, style, transition, trigger } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { AuthState } from 'src/app/core/auth/auth.reducer';
import { AuthService } from 'src/app/core/auth/auth.service';
import { ABOUT_US } from 'src/app/info-pages/_consts/info-pages-route.const';
import { SearchModalComponent } from 'src/app/shared/components/modal/search-modal/search-modal.component';
import { MY_ACCOUNT_ROUTE } from '../../my-account/_consts/my-account-route.const';
import { ModalService } from '../../shared/components/modal/modal.service';
import { Header } from './_models/header.model';
import { GhostButtonType } from 'src/app/shared/components/ghost-button/ghost-button-type.enum';
import { AppLanguage } from '../../app-language.enum';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('showHideAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate(200, style({ transform: 'translateX(0)' }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)' }),
        animate(200, style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ]
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Output() changeLng = new EventEmitter();
  @Input() logoOnly: boolean;
  @Input() currentLng: string;
  appLanguage = AppLanguage;
  headerData$: Observable<Header>;
  open: boolean;
  catOpen: boolean;
  auth$: Observable<AuthState> = this.auth.state$;
  routeSubscribcion: Subscription;
  minimal: boolean;
  aboutLink = ABOUT_US;
  GhostButtonType: typeof GhostButtonType = GhostButtonType;
  constructor(
    private modalService: ModalService,
    private router: Router,
    private auth: AuthService,
    private translate: TranslateService,
    private viewportScroller: ViewportScroller,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    this.open = false;
    this.catOpen = false;
    this.currentLng = localStorage.getItem('lang');
  }

  onLogout() {
    this.auth.logout();
  }

  onClick(lang: string) {
    this.changeLng.emit(lang);
  }

  onClickScroll(element) {
    const scrollElement = document.querySelector(element).nextSibling.nextElementSibling;
    scrollElement.setAttribute('tabindex', '-1');
    scrollElement.scrollIntoView();
    scrollElement.focus();
  }
  menuOnClick() {
    this.open = !this.open;
    if (this.open) {
      this.renderer.addClass(this.document.documentElement, 'disable-scroll');
    } else {
      this.renderer.removeClass(this.document.documentElement, 'disable-scroll');
    }
  }
  catOnClick() {
    this.catOpen = !this.catOpen;
  }

  onSearchIconClick() {
    this.modalService.open(SearchModalComponent, {
      data: { blueOverlay: true }
    });
  }

  onMyAccountClick() {
    this.router.navigate(['/', MY_ACCOUNT_ROUTE]);
    this.closeMenuIfOpen();
  }

  onAuthRetryClick() {
    this.auth.retry();
  }

  ngOnDestroy(): void {
    this.routeSubscribcion.unsubscribe();
  }

  closeMenuIfOpen(): void {
    if (this.open) {
      this.menuOnClick();
    }
  }
}

import { Component, HostListener, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ExternalPaymentsCartListContent } from '../../../external-payments/esp-payment/_models/esp-payment-content.model';
import { EspPaymentDto } from '../../../external-payments/esp-payment/_models/esp-payment.model';

@Component({
  selector: 'app-external-cart-list',
  templateUrl: './external-cart-list.component.html',
  styleUrls: ['./external-cart-list.component.scss']
})
export class ExternalCartListComponent implements OnInit {
  @Input() payment: EspPaymentDto;
  @Input() titleAsH1 = false;
  content: ExternalPaymentsCartListContent;
  isMobile: boolean;

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    this.translate.get('EXTERNAL_PAYMENTS.CART_LIST').subscribe({
      next: (content: ExternalPaymentsCartListContent) => (this.content = content)
    });

    this.checkIsMobile();
  }

  @HostListener('window:resize', ['$event'])
  checkIsMobile() {
    if (window.innerWidth <= 576) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }
}

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { WordExamCalendarContent } from '../_models/word-exam-calendar-content.model';

export enum WordExamCalendarDataState {
  OLD = 'OLD',
  UNCHANGED = 'UNCHANGED',
  CHANGED = 'CHANGED'
}

@Injectable()
export class WordExamCalendarService {
  constructor(private translate: TranslateService) {}

  public getWordExamCalendarContent(): Observable<WordExamCalendarContent> {
    return this.translate.get('WORD_REGISTRATION.WORD_EXAM_CALENDAR');
  }
}

export interface AttachmentDto {
  applicationId?: string;
  correctionId?: string;
  fileName: string;
  format: string[];
  content?: string;
  checksum: string;
  maxNumber: number;
  isRequired?: boolean;
  maxSize: number;
  type: AttachmentType;
  id?: string;
  required?: boolean;
  attachable?: boolean;
}

export enum AttachmentType {
  PHOTO = 'PHOTO',
  SIGNATURE_SCAN = 'SIGNATURE_SCAN',
  COPY_OF_DRIVING_LICENSE = 'COPY_OF_DRIVING_LICENSE',
  DRIVING_LICENSE_TRANSLATION = 'DRIVING_LICENSE_TRANSLATION',
  COPY_OF_THE_DRIVER_CARD = 'COPY_OF_THE_DRIVER_CARD',
  CONFIRMATION_OF_WORK = 'CONFIRMATION_OF_WORK',
  A_STATEMENT_ABOUT_LOSING_THE_CARD = 'A_STATEMENT_ABOUT_LOSING_THE_CARD',
  A_STATEMENT_ABOUT_LOSING_THE_FOREIGN_CARD = 'A_STATEMENT_ABOUT_LOSING_THE_FOREIGN_CARD',
  A_STATEMENT_ABOUT_CARD_THEFT = 'A_STATEMENT_ABOUT_',
  A_COPY_OF_THE_DOCUMENT_ALLOWING_TRAFFIC = 'A_COPY_OF_THE_DOCUMENT_ALLOWING_TRAFFIC',
  DRIVER_STATEMENT_LICENSE = 'DRIVER_STATEMENT_LICENSE',
  DRIVER_STATEMENT_ADDRESS = 'DRIVER_STATEMENT_ADDRESS',
  EMPLOYER_HAS_EMPLOYEE_AUTHORIZATION_TO_SUBMIT_APPLICATION = 'EMPLOYER_HAS_EMPLOYEE_AUTHORIZATION_TO_SUBMIT_APPLICATION',
  COMPANY_AUTHORIZATION_FOR_NON_KRS_PERSON = 'COMPANY_AUTHORIZATION_FOR_NON_KRS_PERSON',
  PREVIOUS_CARD = 'PREVIOUS_CARD',
  OTHER = "OTHER",
  COPY_OF_DRIVER_CERTIFICATE_OR_DRIVER_QUALIFICATION_CARD = "COPY_OF_DRIVER_CERTIFICATE_OR_DRIVER_QUALIFICATION_CARD",
  COPY_OF_DRIVER_CERTIFICATE = "COPY_OF_DRIVER_CERTIFICATE",
  COPY_OF_INTERNATIONAL_DRIVING_LICENSE = "COPY_OF_INTERNATIONAL_DRIVING_LICENSE"
}

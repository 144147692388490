<div class="ic-page" [formGroup]="formGroup" [attr.id]="htmlElementId ? htmlElementId : null">
  <div class="ic-page__form">
    <h2
      [ngClass]="{
        'ic-page__form-title': true,
        error: sectionErrorsExist()
      }">
      {{ staticContent.driverCardTitle }}
    </h2>
    <div class="row">
      <div class="col-12 col-md-6">
        <app-form-input
          inputId="driverCardNumber"
          formControlName="driverCardNumber"
          [isReportedForCorrection]="isFieldIncludedInCorrection('driverCardNumber')"
          [warnValue]="findPreviousValue('driverCardNumber')"
          [label]="staticContent?.driverCardNumber.label"
          (blured)="onBlur()"
          [placeholder]="staticContent?.driverCardNumber.placeholder"
          maxLength="16"
          [formGroup]="formGroup"
          [isRequired]="isControlRequired('driverCardNumber')"
          [errorList]="[
            {
              label: staticContent?.driverCardNumber.error,
              type: ['minlength', 'maxlength']
            },
            {
              label: staticContent?.notAlphanumericCharactersError,
              type: ['pattern']
            },
            {
              label: staticContent?.driverCardNumber.error2,
              type: ['required']
            }
          ]">
        </app-form-input>
      </div>
    </div>
  </div>
</div>

<div class="backwardBtn">
  <ic-ghost-button
    typeHTML="button"
    appPreventDoubleClick
    [type]="GhostButtonType.BIG_SECOND_RANK"
    [showArrow]="true"
    [arrowLeft]="true"
    [width]="135"
    (throttledClick)="navigateBack()"
    [disabled]="disabled">
    <span class="backwardSpan">{{ label }}</span>
  </ic-ghost-button>
</div>

import { ErrorListIndicatorService } from './../services/error-list-indicator.service';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  HostBinding
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { tap } from 'rxjs/operators';

import { SelectableItemDereferencerDirective } from '../selectable-item-dereferencer';
import { SelectableInputService } from '../services/selectable-input.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'si-internal-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss']
})
export class TextInputComponent<
    ItemType,
    Key extends keyof ItemType,
    OutputKey extends keyof ItemType
  >
  extends SelectableItemDereferencerDirective<ItemType, Key, OutputKey>
  implements OnInit {
  @Input() placeholder: string;
  @Input() inputValue: string;
  @Input('siFormControl') formControl: AbstractControl;
  @Input() disabledState: boolean;
  @Input() readOnly: boolean;
  @Input() htmlId: string;

  @Output() inputChange = new EventEmitter<string>();
  @Output() riflebirdClose = new EventEmitter<void>();

  @ViewChild('input', { static: true }) inputElement: ElementRef;

  isInvalidChanges: Observable<boolean>;
  openedChanges: Observable<boolean>;

  constructor(
    private selectableInput: SelectableInputService,
    private errorListService: ErrorListIndicatorService
  ) {
    super();
    this.openedChanges = selectableInput.openedChanges;
  }

  ngOnInit() {
    this.selectableInput.opened$
      .pipe(tap(() => (this.inputValue = null)))
      .subscribe(() => this.inputChange.emit(this.inputValue));

    this.selectableInput.closed$
      .pipe(tap(() => this.inputElement.nativeElement.blur()))
      .subscribe();

    this.isInvalidChanges = this.errorListService.isInvalidChanges;
  }

  onModelChange() {
    this.inputChange.emit(this.inputValue);
  }

  onFocus(event?) {
    this.selectableInput.open();
  }

  onRiflebirdClick() {
    if (this.disabledState === true) {
      return;
    }
    if (this.selectableInput.opened) {
      this.riflebirdClose.emit();
    } else {
      this.inputElement.nativeElement.focus();
    }
  }
}

import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/auth';
import { MetaService } from 'src/app/shared/services/meta-service/meta.service';
import { BreadcrumbsService } from 'src/app/shared/components/breadcrumbs/breadcrumbs.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { openAnonymousPaperApplicationToastr } from '../shared/extensions/extensions';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss']
})
export class MainPageComponent implements OnInit, OnDestroy {
  loggedUser: string;
  constructor(private breadcrumbsService: BreadcrumbsService, private metaService: MetaService, private auth: AuthService, private toast: ToastrService) {}

  ngOnInit() {
    this.breadcrumbsService.breadcrumbsHide.emit(true);
    this.metaService.createCanonicalURL();

    this.auth.state$.pipe(filter(state => state.status === 'initialized')).subscribe({
      next: value => {
        this.loggedUser = value.user?.uuid;
        openAnonymousPaperApplicationToastr(this.loggedUser, this.toast);
      }
    })
  }
  
  ngOnDestroy() {
    this.breadcrumbsService.breadcrumbsHide.emit(false);
  }
}

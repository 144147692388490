<section
  class="most-popular-services"
  *ngIf="content"
  [ngClass]="{ 'most-popular-borderless': borderlessStyle }">
  <h2 class="most-popular-services__title">{{ content.title }}</h2>
  <ul class="most-popular-services__services">
    <a
      *ngFor="let service of mostPopularServices"
      routerLink="{{ service.redirectRoute }}"
      externalLink="{{ service.href }}"
      [attr.title]="service.href ? ('LINK_TITLE.OPEN_IN_NEW_TAB' | translate) : ''"
      [attr.target]="service.href ? '_blank' : '_self'">
      <li>{{ service.title }}</li>
    </a>
  </ul>
  <a *ngIf="!hideAllServicesButton" routerLink="/{{ DRIVNG_LICENSE_ROUTE }}"
    ><span class="most-popular-services__show-all">{{ content.seeAll }}</span></a
  >
</section>

import {TranslateService} from '@ngx-translate/core';
import {DraftAlreadyExistsModal} from './model/draft-already-exists-modal.model';
import {
  CardReasonsService
} from '../../../../applications/stc/driver-card-application/application-reason/_services/card-reasons.service';
import {Component, HostListener, OnInit} from '@angular/core';
import {BaseModalDirective} from '../base-modal';
import {GhostButtonType} from '../../ghost-button/ghost-button-type.enum';
import {ReasonDto} from 'src/app/shared/models/applications/reason-dto.model';
import {StcApplicationsService} from 'src/app/applications/stc/_services/stc-applications.service';

export enum DraftAlreadyExistsModalOption {
  EDIT = 'EDIT',
  DELETE = 'DELETE'
}

@Component({
  selector: 'app-draft-already-exists-modal',
  templateUrl: './draft-already-exists-modal.component.html',
  styleUrls: ['./draft-already-exists-modal.component.scss'],
  providers: [CardReasonsService, StcApplicationsService]
})
export class DraftAlreadyExistsModalComponent extends BaseModalDirective implements OnInit {
  GhostButtonType: typeof GhostButtonType = GhostButtonType;
  maxMobileViewportWidth = 480; // max width for 'Edytuj' instead of 'Edytuj wniosek'
  isViewportMobile: boolean;

  reason: ReasonDto;
  date: string;
  content: DraftAlreadyExistsModal;

  constructor(private cardReasonService: CardReasonsService, private translate: TranslateService) {
    super();
  }

  ngOnInit() {
    this.setIsViewportMobile(window.innerWidth);
    this.getDate();
    this.getReason();
    this.translate
      .get('DRAFT_ALREADY_EXISTS_MODAL')
      .subscribe((result: DraftAlreadyExistsModal) => {
        this.content = result;
      });
  }

  onEditClick() {
    this.close(DraftAlreadyExistsModalOption.EDIT);
  }

  onDeleteClick() {
    this.close(DraftAlreadyExistsModalOption.DELETE);
  }

  onCancelClick() {
    this.close();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setIsViewportMobile(window.innerWidth);
  }

  setIsViewportMobile(viewportWidth: number) {
    this.isViewportMobile = viewportWidth < this.maxMobileViewportWidth;
  }

  getReason() {
    this.cardReasonService.getReasonsForCardType$('DRIVER_CARD').subscribe((resp) => {
      resp.reasons.forEach((reason) => {
        if (reason.reasonType === this.getData().checkIfAlreadyExists.reasonType) {
          this.reason = reason;
        }
      });
    });
  }

  getDate() {
    this.date = this.getData().checkIfAlreadyExists.updatedAt
      ? this.formatDate(this.getData().checkIfAlreadyExists.updatedAt)
      : this.formatDate(this.getData().checkIfAlreadyExists.createdAt);
  }

  formatDate(date: string): string {
    return `${date.slice(0, 10)} ${date.slice(11, 16)}`;
  }
}

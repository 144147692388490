import { environment as env } from './../../../environments/environment';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

const ACTIVATE_URL = '/activate';

@Injectable({
  providedIn: 'root'
})
export class ActivationService {
  private _accountActivated: Subject<any> = new Subject();

  public accountActivated$: Observable<any> = this._accountActivated.asObservable();

  constructor(private http: HttpClient) {}

  public activateAccount(token: string) {
    this.postActivationToken(token).subscribe({
      complete: () => this._accountActivated.next(null)
    });
  }

  private postActivationToken(token: string): Observable<any> {
    return this.http.post<any>(
      `${env.BACKEND_API.BASE_ACCOUNT_MANAGMENT}${env.BACKEND_API.ACCOUNT_MANAGMENT}` +
        `${env.BACKEND_API.CLIENT}${ACTIVATE_URL}/${token}`,
      token
    );
  }
}

<ng-template [ngIf]="!days || days.length < 1" [ngIfElse]="accordion">
  <p id="no-exams-label">
    <img src="assets/images/circled-info.svg" />
    <span>{{ content.noExams }}</span>
  </p>
</ng-template>
<ng-template #accordion>
  <div ngbAccordion [closeOthers]="true">
    <div ngbAccordionItem (show)="opened[i] = true" (hide)="opened[i] = false" [collapsed]="i !== 0" [destroyOnHide]="true" *ngFor="let scheduleDay of days, let i = index">
      <div ngbAccordionHeader>
        <button ngbAccordionButton>
          <h5 class="m-0">{{ scheduleDay.day | date: 'EEEE dd.MM':undefined:'pl' }}</h5>
          <span>{{ opened[i] ? content.collapse : content.expand }}</span>
        </button>
      </div>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <div class="day-content">
            <div class="table-header">
              <h6>{{ content.examTypeHeader }}</h6>
              <h6>{{ content.hourHeader }}</h6>
              <h6>{{ content.placesLeftHeader }}</h6>
              <h6 class="table-header__additional">{{ content.additionalInfoHeader }}</h6>
            </div>
            <div class="table-row">
              <ng-container *ngFor="let scheduledHour of scheduleDay.scheduledHours; let i = index">
                <!-- łączone -->
                <ng-template [ngIf]="activeFilter === null || activeFilter === ExamType.Linked">
                  <ng-container *ngFor="let linked of scheduledHour?.linkedExamsDto">
                    <div class="linked-row" *ngFor="let practice of linked.practiceExams">
                      <p class="exam-type">{{ content.combinedExamName }}</p>
                      <p class="exam-time">
                        <span>
                          {{ linked.theoryExam.date | date: 'H:mm':undefined:'pl' }} {{
                          content.theoryExamName | lowercase }}
                        </span>
                        <span>
                          {{ practice.date | date: 'H:mm':undefined:'pl' }} {{
                          content.practiveExamName | lowercase }}
                        </span>
                      </p>
                      <p class="exam-places">{{practice.places}}</p>
                      <p class="additional-info">
                        <span class="additional-info__header-mobile"
                          >{{ content.additionalInfoHeader }}</span
                        >
                        <span *ngIf="linked.theoryExam.additionalInfo">
                          {{ linked.theoryExam.additionalInfo }}{{ practice.additionalInfo ? ',' : ''
                          }}
                        </span>
                        <span *ngIf="practice.additionalInfo"> {{ practice.additionalInfo }} </span>
                        <span *ngIf="!linked.theoryExam.additionalInfo && !practice.additionalInfo">
                          {{ null | dashpipe }}
                        </span>
                      </p>
                      <div class="ghost-wrapper">
                        <ic-ghost-button
                          [type]="GhostButtonType.WORD_EXAM_SMALL_FLEX"
                          [htmlId]="'combinedExamButton'+i"
                          [typeHTML]="'button'"
                          (click)="selectExam(linked.theoryExam, practice)">
                          {{ content.selectExamButtonLabel }}
                        </ic-ghost-button>
                        <div
                          class="mobile-select"
                          [attr.aria-label]="content.dropdownList"
                          role="button"
                          (click)="selectExam(linked.theoryExam, practice)"></div>
                      </div>
                    </div>
                  </ng-container>
                </ng-template>
                <!-- teoria -->
                <ng-template [ngIf]="activeFilter === null || activeFilter === ExamType.Theoretical">
                  <div class="theory-row" *ngFor="let theory of scheduledHour?.theoryExams">
                    <p class="exam-type exam-type-theory">{{ content.theoryExamName }}</p>
                    <p class="exam-time">
                      <span>{{ theory.date | date: 'H:mm':undefined:'pl' }}</span>
                    </p>
                    <p class="exam-places">{{theory.places}}</p>
                    <p class="additional-info">
                      <span class="additional-info__header-mobile"
                        >{{ content.additionalInfoHeader }}</span
                      >
                      {{theory.additionalInfo | dashpipe}}
                    </p>
                    <div class="ghost-wrapper">
                      <ic-ghost-button
                        [type]="GhostButtonType.WORD_EXAM_SMALL_FLEX"
                        [htmlId]="'theoryButton'+i"
                        [typeHTML]="'button'"
                        (click)="selectExam(theory, null)">
                        {{ content.selectExamButtonLabel }}
                      </ic-ghost-button>
                      <div
                        class="mobile-select"
                        [attr.aria-label]="content.dropdownList"
                        role="button"
                        (click)="selectExam(theory, null)"></div>
                    </div>
                  </div>
                </ng-template>
                <!-- praktyka -->
                <ng-template [ngIf]="activeFilter === null || activeFilter === ExamType.Practical">
                  <div class="theory-row" *ngFor="let practice of scheduledHour?.practiceExams">
                    <p class="exam-type exam-type-practise">{{ content.practiveExamName }}</p>
                    <p class="exam-time">
                      <span>{{ practice.date | date: 'H:mm':undefined:'pl' }}</span>
                    </p>
                    <p class="exam-places">{{practice.places}}</p>
                    <p class="additional-info">
                      <span class="additional-info__header-mobile"
                        >{{ content.additionalInfoHeader }}</span
                      >
                      {{practice.additionalInfo| dashpipe}}
                    </p>
                    <div class="ghost-wrapper">
                      <ic-ghost-button
                        [type]="GhostButtonType.WORD_EXAM_SMALL_FLEX"
                        [htmlId]="'practiceExamsButton'+i"
                        [typeHTML]="'button'"
                        (click)="selectExam(null, practice)">
                        {{ content.selectExamButtonLabel }}
                      </ic-ghost-button>
                      <div
                        class="mobile-select"
                        [attr.aria-label]="content.dropdownList"
                        role="button"
                        (click)="selectExam(null, practice)"></div>
                    </div>
                  </div>
                </ng-template>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
import {TranslateService} from '@ngx-translate/core';
import {ChooseEmployeeModal} from './models/choose-emplyee-modal.model';
import {EmployeeDto} from '../../../models/employee/employee-dto.model';
import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BaseModalDirective} from '../base-modal';
import {EmployeeDtoList} from 'src/app/shared/models/employee/employee-dto-list.model';
import {ReasonDto} from 'src/app/shared/models/applications/reason-dto.model';
import {EmployeesService} from 'src/app/my-account/company-employees/list-employees/_services/employees.service';
import {ActivatedRoute, Router} from '@angular/router';
import {GhostButtonType} from '../../ghost-button/ghost-button-type.enum';
import {CardTypes} from 'src/app/applications/stc/_consts/card-types.enum';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-choose-employee-modal',
  templateUrl: './choose-employee-modal.component.html',
  styleUrls: ['./choose-employee-modal.component.scss']
})
export class ChooseEmployeeModalComponent extends BaseModalDirective implements OnInit, OnDestroy {
  @ViewChild('searchInput') searchInput: ElementRef;

  cardType: CardTypes;
  GhostButtonType = GhostButtonType;
  selectedEmployee: EmployeeDto;
  chooseEmployeeModalTexts: ChooseEmployeeModal;
  content: any;
  filterForm: UntypedFormGroup = new UntypedFormGroup({
    filterQuery: new UntypedFormControl(null)
  });
  filteredEmployes: EmployeeDto[];

  constructor(
    private employeesService: EmployeesService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    this.translate.get('SHARED.CHOOSE_EMPLOYEE_MODAL').subscribe({
      next: (content) => {
        this.content = content;
      }
    });
    const data = this.getData();
    const reason = data.reason;
    this.cardType = data.cardType;
    this.getEmployees(reason);
    document.querySelector('html').classList.add('hideScrollbar');
  }

  onOkClick() {}

  onCancelClick() {
    this.close();
  }

  onAddEmployeeLinkClick() {
    this.router.navigateByUrl('/konto/dodaj-pracownika');
    this.close();
  }

  onImpersonalCardClick() {
    this.close(false);
  }

  getEmployees(reason: ReasonDto.ReasonTypeEnum) {
    this.employeesService.getEmployeesByReason(reason).subscribe((result: EmployeeDtoList) => {
      this.filteredEmployes = result.employees;
      this.filterEmployeesList(result);
    });
  }

  filterEmployeesList(employeesList: EmployeeDtoList) {
    this.filterForm.controls['filterQuery'].valueChanges.subscribe({
      next: (result) => {
        this.filteredEmployes = employeesList.employees.filter(
          (el) =>
            el.person.firstName.toLowerCase().startsWith(result.toLowerCase()) ||
            el.person.lastName.toLowerCase().startsWith(result.toLowerCase())
        );
      }
    });
  }
  selectEmployee(employee) {
    this.selectedEmployee = employee;
    this.close(this.selectedEmployee);
  }

  // NOTE: impersonal card allowed only for control card
  impersonalCardAllowed(): boolean {
    return this.cardType === CardTypes.CONTROL_CARD;
  }

  setFocusOnSearchInput() {
    setTimeout(() => this.searchInput.nativeElement.focus());
  }

  ngOnDestroy(): void {
    document.querySelector('html').classList.remove('hideScrollbar');
  }
}

import { TranslateService } from '@ngx-translate/core';
import { ServiceLinkType } from '../service-link/service-link-type.enum';
import { Component, OnInit, Input } from '@angular/core';
import { ServiceCategory } from 'src/app/shared/models/service-category.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-category-menu',
  templateUrl: './category-menu.component.html',
  styleUrls: ['./category-menu.component.scss']
})
export class CategoryMenuComponent implements OnInit {
  private _currentCategory: ServiceCategory;
  content: any;
  @Input() categoryMenu: ServiceCategory[];
  @Input() isTitleNeeded = true;
  ServiceLinkType: typeof ServiceLinkType = ServiceLinkType;

  @Input()
  set currentCategory(currentCategory: ServiceCategory) {
    this._currentCategory = currentCategory;
  }

  constructor(private translate: TranslateService) {}

  ngOnInit() {
    this.translate.get('SERVICE_CATEGORY.CATEGORY_MENU').subscribe({
      next: (content) => {
        this.content = content;
      }
    });
  }
}

import { ActivationService } from './_service/activation.service';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { SearchModalComponent } from '../shared/components/modal/search-modal/search-modal.component';
import { SharedModule } from '../shared/shared.module';
import { FooterModule } from './footer/footer.module';
import { HeaderModule } from './header/header.module';
import { InfoBarModule } from './info-bar/info-bar.module';
import { LayoutComponent } from './layout.component';
import { LoaderStripeModule } from './loader-stripe/loader-stripe.module';
import { LayoutService } from './_service/layout.service';
import * as fromLayout from './_store/layout.reducer';
import { AcceptancesModalComponent } from './acceptances-modal/acceptances-modal.component';
import { LoadersModule } from './loaders/loaders.module';

@NgModule({
  declarations: [LayoutComponent, AcceptancesModalComponent],
  exports: [LayoutComponent, AcceptancesModalComponent],
  imports: [
    CommonModule,
    InfoBarModule,
    HeaderModule,
    FooterModule,
    StoreModule.forFeature(fromLayout.featureName, fromLayout.layoutReducer),
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    LoaderStripeModule,
    LoadersModule,
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule
  ],
  providers: [LayoutService]
})
export class LayoutModule {}

import { EventEmitter, Injectable } from '@angular/core';
import { Breadcrumb, Page } from './breadcrumb.model';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsService {
  breadcrumbsChange: EventEmitter<Array<Page | Breadcrumb>> = new EventEmitter();
  breadcrumbsClear: EventEmitter<any> = new EventEmitter();
  breadcrumbsHide: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

  setBreadcrumbs(breadcrumbs: Array<Page | Breadcrumb>) {
    this.breadcrumbsChange.emit(breadcrumbs);
  }

  hideBreadcrumbs(value: boolean) {
    this.breadcrumbsHide.emit(value);
  }

  clear() {
    this.breadcrumbsClear.emit();
  }
}

import { Extensions } from 'src/app/shared/extensions/extensions';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../auth';

@Injectable()
export class StcPaperInterceptor implements HttpInterceptor {
  isUserLogged: string;

  constructor(private auth: AuthService) {
    this.auth.state$.subscribe({
      next: value => {
        this.isUserLogged = value?.user?.firstname
      }
    })
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if(request.url.includes('api')) {
      if((!!Extensions.getCookie('anonymousToken') || !!sessionStorage.getItem('anonymousToken')) && !this.isUserLogged) {
        request = request.clone({
          setHeaders: {
            'Anonymous-Token': Extensions.getCookie('anonymousToken') || sessionStorage.getItem('anonymousToken')
          }
        });
      }
  
      if((!!Extensions.getCookie('ownerId') || !!sessionStorage.getItem('ownerId')) && !this.isUserLogged) {
        request = request.clone({
          setHeaders: {
            'Anonymous-User-Id': Extensions.getCookie('ownerId') || sessionStorage.getItem('ownerId')
          }
        });
      }
    }

    return next.handle(request);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';
import { FooterTopComponent } from './footer-top/footer-top.component';
import { FooterContentComponent } from './footer-content/footer-content.component';
import { FooterProductsComponent } from './footer-products/footer-products.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  declarations: [
    FooterComponent,
    FooterTopComponent,
    FooterContentComponent,
    FooterProductsComponent
  ],
  imports: [CommonModule, FormsModule, RouterModule, ReactiveFormsModule, SharedModule],
  providers: [],
  exports: [FooterComponent]
})
export class FooterModule {}

import {
  CALENDAR_STEP_PARAMETRIZED_ROUTE,
  CALENDAR_STEP_ROUTE
} from 'src/app/applications/word-exam-registration/registration-process/calendar-step/_const/calendar-step-route.const';
import {
  EXAMINATION_CENTER_STEP_ROUTE
} from 'src/app/applications/word-exam-registration/registration-process/examination-center-step/_const/examination-center-step.route.const';
import {
  PAYMENT_STEP_PARAMETRIZED_ROUTE
} from 'src/app/applications/word-exam-registration/registration-process/payment-step/_const/personal-data-step-route.const';
import {
  PERSONAL_DATA_STEP_ROUTE
} from 'src/app/applications/word-exam-registration/registration-process/personal-data-step/_const/personal-data-step-route.const';
import {
  SUMMARY_STEP_PARAMETRIZED_ROUTE,
  SUMMARY_STEP_ROUTE
} from 'src/app/applications/word-exam-registration/registration-process/summary-step/_const/summary-step-route.const';
import {DETAILS_STEP_ROUTE} from '../registration-process/details-step/_const/details-step-route.const';

export const WORD_EXAM_ROUTE = 'prawo-jazdy';
export const WORD_EXAM_PROCESS_ROUTE = 'zapisz-sie-na-egzamin-na-prawo-jazdy';
export const WORD_RESCHEDULE_RESERVATION = 'zmien-termin-egzaminu-na-prawo-jazdy';
export const WORD_RESCHEDULE_RESERVATION_PARAMETRIZED = 'zmien-termin-egzaminu-na-prawo-jazdy/:id';

export const WORD_EXAM_REGISTRATION_STEPS_ROUTES: string[] = [
  `/${WORD_EXAM_ROUTE}/${WORD_EXAM_PROCESS_ROUTE}/${PERSONAL_DATA_STEP_ROUTE}`,
  `/${WORD_EXAM_ROUTE}/${WORD_EXAM_PROCESS_ROUTE}/${EXAMINATION_CENTER_STEP_ROUTE}`,
  `/${WORD_EXAM_ROUTE}/${WORD_EXAM_PROCESS_ROUTE}/${CALENDAR_STEP_ROUTE}`,
  `/${WORD_EXAM_ROUTE}/${WORD_EXAM_PROCESS_ROUTE}/${DETAILS_STEP_ROUTE}`,
  `/${WORD_EXAM_ROUTE}/${WORD_EXAM_PROCESS_ROUTE}/${SUMMARY_STEP_ROUTE}`,
  `/${WORD_EXAM_ROUTE}/${WORD_EXAM_PROCESS_ROUTE}/${PAYMENT_STEP_PARAMETRIZED_ROUTE}`
];

export const WORD_RESCHEDULE_RESERVATION_STEPS_ROUTES: string[] = [
  `/${WORD_EXAM_ROUTE}/${WORD_RESCHEDULE_RESERVATION}/${CALENDAR_STEP_PARAMETRIZED_ROUTE}`,
  `/${WORD_EXAM_ROUTE}/${WORD_RESCHEDULE_RESERVATION}/${SUMMARY_STEP_PARAMETRIZED_ROUTE}`
];

import { Action } from '@ngrx/store';
import { News } from '../_models/news';

export interface NewsActions {
  GET_NEWS: string;
  GET_NEWS_ERORR: string;
  GET_NEWS_SUCCESS: string;
  GET_NEWS_BY_TAG: string;
  GET_NEWS_BY_TAG_SUCCESS: string;
  GET_NEWS_BY_TAG_ERROR: string;
  CLEAR_NEWS: string;
  GET_SLIDER_NEWS: string;
  GET_SLIDER_NEWS_SUCCESS: string;
  GET_SLIDER_NEWS_ERROR: string;
}

export const NewsActionTypes: NewsActions = {
  GET_NEWS: 'Get_News',
  GET_NEWS_ERORR: 'Get_News_Erorr',
  GET_NEWS_SUCCESS: 'Get_News_Success',
  GET_NEWS_BY_TAG: 'Get_News_By_Tag',
  GET_NEWS_BY_TAG_SUCCESS: 'Get_News_By_Tag_Success',
  GET_NEWS_BY_TAG_ERROR: 'Get_News_By_Tag_Error',
  CLEAR_NEWS: 'Clear_News',
  GET_SLIDER_NEWS: 'Get_Slider_News',
  GET_SLIDER_NEWS_SUCCESS: 'Get_Slider_News_Success',
  GET_SLIDER_NEWS_ERROR: 'Get_Slider_News_Error'
};

export class GetNews implements Action {
  type = NewsActionTypes.GET_NEWS;
  constructor(public payload: { page: number; rows: number; promo?: boolean }) {}
}

export class GetNewsSuccess implements Action {
  type = NewsActionTypes.GET_NEWS_SUCCESS;
  constructor(public payload: { news: News[]; total: number; pageNumber?: number }) {}
}

export class GetNewsError implements Action {
  type = NewsActionTypes.GET_NEWS_ERORR;
  constructor(public payload: { error: string }) {}
}

export class GetNewsByTag implements Action {
  type = NewsActionTypes.GET_NEWS_BY_TAG;
  constructor(public payload: { page: number; rows: number; tag: number }) {}
}

export class GetNewsByTagError implements Action {
  type = NewsActionTypes.GET_NEWS_BY_TAG_ERROR;
  constructor(public payload: { error: string }) {}
}

export class GetNewsByTagSuccess implements Action {
  type = NewsActionTypes.GET_NEWS_BY_TAG_SUCCESS;
  constructor(public payload: { news: News[]; total: number; tag: string }) {}
}

export class ClearNews implements Action {
  type = NewsActionTypes.CLEAR_NEWS;
  constructor(public payload: boolean) {}
}
export class GetSliderNews implements Action {
  type = NewsActionTypes.GET_SLIDER_NEWS;
  constructor(public payload: { page: number; rows: number }) {}
}
export class GetSliderNewsSuccess implements Action {
  type = NewsActionTypes.GET_SLIDER_NEWS_SUCCESS;
  constructor(public payload: { news: News[] }) {}
}
export class GetSliderNewsError implements Action {
  type = NewsActionTypes.GET_SLIDER_NEWS_ERROR;
  constructor(public payload: { error: string }) {}
}

export type Actions =
  | GetNews
  | GetNewsSuccess
  | GetNewsError
  | GetNewsByTag
  | GetNewsByTagError
  | GetNewsByTagSuccess
  | ClearNews
  | GetSliderNews
  | GetSliderNewsSuccess
  | GetSliderNewsError;

import { User } from 'src/app/core/model/user.model';
import { Injectable } from '@angular/core';
import { createFeatureSelector, select, Store } from '@ngrx/store';
import { OAuthService } from 'angular-oauth2-oidc';
import * as Immutable from 'immutable';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, withLatestFrom } from 'rxjs/operators';

import * as fromAuth from './auth.reducer';

@Injectable()
export class AuthQueryService {
  constructor(private authStore: Store<fromAuth.AuthState>, private oauth: OAuthService) {}

  private authSelector = createFeatureSelector<fromAuth.AuthState>(fromAuth.featureName);

  public status$: Observable<fromAuth.AuthStatus> = this.authStore.pipe(
    select(this.authSelector),
    map((state) => state.status),
    distinctUntilChanged()
  );

  private _user$: Observable<User> = this.authStore.pipe(
    select(this.authSelector),
    map((state) => state.user)
  );

  public user$: Observable<User> = combineLatest([this.status$, this._user$]).pipe(
    filter(([status, user]) => status === 'initialized' || status === 'error' || status === 'anonymous'),
    withLatestFrom(this._user$),
    map(([state, user]) => user),
    distinctUntilChanged(stateComparator)
  );

  public state$: Observable<fromAuth.AuthState> = this.authStore.pipe(
    select(this.authSelector),
    distinctUntilChanged(stateComparator)
  );

  public silentLogout$: Observable<boolean> = this.oauth.events.pipe(
    filter(
      (event) => event.type === 'silent_refresh_error' || event.type === 'silent_refresh_timeout'
    ),
    withLatestFrom(this.status$),
    filter(([event, status]) => status === 'initialized'),
    withLatestFrom(this._user$),
    filter(([combined, user]) => !!user),
    map(() => true)
  );
}

function stateComparator(prev: any, curr: any) {
  return Immutable.is(Immutable.fromJS(prev), Immutable.fromJS(curr));
}

<section *ngIf="content" class="info-for-bussines-modal">
  <div class="header">
    <h2 class="header__title">{{ content.MODAL_TITLE | translate }}</h2>
    <img
      alt="{{ content.BTN_CLOSE_ARIA }}"
      role="button"
      class="header__close-btn"
      tabindex="0"
      (keydown.enter)="onCloseModalClick()"
      (click)="onCloseModalClick()"
      src="assets/images/Group 3293.svg" />
  </div>
  <div class="info-for-bussines-modal__content">
    <span class="info-text">{{ infoTextTranslateKey | translate }}</span>
    <span class="info-text">
      {{ ctaTextTranslateKey.PART_1 ? ctaTextTranslateKey.PART_1 : '' }}
      <a (click)="onCtaClick()" routerLink="/{{ ctaLink }}">{{
        ctaTextTranslateKey.LINK | translate
      }}</a>
      {{ ctaTextTranslateKey.PART_2 | translate }}
    </span>
    <div class="info-button">
      <ic-ghost-button
        [type]="GhostButtonType.BIG_FIRST_RANK_FLEX"
        [justifyContent]="'center'"
        [showArrow]="false"
        [width]="136"
        (click)="onCloseModalClick()">
        <span class="btn-label">{{ content.BUTTON_LABEL | translate }}</span>
      </ic-ghost-button>
    </div>
  </div>
</section>

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { CompanyDataFormBinder } from './company-data.form-binder';
import {
  CompanyDataDto,
  CompanyDataFormValidation,
  CompanyDataStaticContent
} from './company-data.model';
import { defaultCompanyDataFormValidation } from './company-data.validation';
import { CorrectionsErrors, FieldErrorEntry } from '../../../models/corrections/corrections.model';
import { CompanyDataCorrectionPrefixEnum } from './company-data-correction-prefix.enum';

@Component({
  selector: 'app-company-data',
  templateUrl: './company-data.component.html',
  styleUrls: ['./company-data.component.scss'],
  providers: [CompanyDataFormBinder]
})
export class CompanyDataComponent implements OnInit, OnDestroy {
  @Input() staticContent: CompanyDataStaticContent;
  @Input() formValidation: CompanyDataFormValidation = defaultCompanyDataFormValidation;
  @Input() aggregateFormGroup: UntypedFormGroup;
  @Input() fetchedData: CompanyDataDto;
  @Input() htmlElementId: string;

  @Output() saveDraft: EventEmitter<any> = new EventEmitter();

  @Input() set correctionsErrors(data: CorrectionsErrors) {
    this._correctionsErrors = data;
    this.getFieldsErrors(data);
  }
  private _correctionsErrors: CorrectionsErrors;
  formGroup: UntypedFormGroup;
  @Input() set formPrefix(prefixType: CompanyDataCorrectionPrefixEnum) {
    switch (prefixType) {
      case CompanyDataCorrectionPrefixEnum.WORKSHOP:
        this._formPrefix = 'workshop';
        break;
      case CompanyDataCorrectionPrefixEnum.CONTROL_AUTHORITY:
        this._formPrefix = 'controlAuthority';
        break;
      case CompanyDataCorrectionPrefixEnum.COMPANY:
        this._formPrefix = 'company';
        break;
    }
    this.getFieldsErrors(this._correctionsErrors);
  }
  private _formPrefix = 'company';
  fieldsErrors: FieldErrorEntry[] = [];

  public constructor(private binder: CompanyDataFormBinder) {}

  public ngOnInit() {
    this.formGroup = this.binder.bindForm(this.formValidation);
    this.aggregateFormGroup.addControl(this.formValidation.formGroupName, this.formGroup);
    this.binder.fillFormWith(this.fetchedData);
  }

  public onBlur(): void {
    this.saveDraft.emit();
  }

  public isControlRequired(controlName: string): boolean {
    return this.binder.isControlRequired(controlName);
  }

  sectionErrorsExist(): boolean {
    return this._correctionsErrors?.sectionErrors.some((item) => item.entry === this._formPrefix);
  }

  getFieldsErrors(correctionsErrors: CorrectionsErrors) {
    this.fieldsErrors =
      correctionsErrors?.fieldErrors
        .filter((item) => item.entry.startsWith(this._formPrefix))
        .map((entry) => {
          return {
            entry: entry.entry,
            previousValue: entry.previousValue
          };
        }) || [];
  }

  isFieldIncludedInCorrection(fieldName: string): boolean {
    return (
      this.fieldsErrors.some((err) => err.entry.endsWith(fieldName)) || this.sectionErrorsExist()
    );
  }

  findPreviousValue(fieldName: string): any {
    return this.fieldsErrors.find((err) => err.entry.endsWith(fieldName))?.previousValue;
  }

  ngOnDestroy(): void {
    if (this.aggregateFormGroup.contains(this.formValidation.formGroupName)) {
      this.aggregateFormGroup.removeControl(this.formValidation.formGroupName);
    }
  }
}

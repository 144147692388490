export enum SignatureMethod {
  PROFILE,
  CERTIFICATE,
  EDOHUB
}

export enum Mode {
  CHOOSE_METHOD,
  INSTRUCTION,
  PROFILE_WAIT,
  PROFILE_ERROR,
  PROFILE_NO_RESPONSE,
  CERTIFICATE_WAIT
}

export enum SignStatus {
  NOT_CREATED = 'NOT_CREATED',
  PENDING = 'PENDING',
  SIGNED_OK = 'SIGNED_OK',
  SIGNED_NOT_OK = 'SIGNED_NOT_OK',
  SIGN_HAS_BEEN_CANCELED = ' SIGN_HAS_BEEN_CANCELED'
}

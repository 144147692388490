export enum ObtainCardType {
  DRIVER_CARD = 'STC.CARD_TYPES.DRIVER_CARD',
  TEMPORARY_DRIVER_CARD = 'STC.CARD_TYPES.TEMPORARY_DRIVER_CARD',
  CONTROL_CARD = 'STC.CARD_TYPES.CONTROL_CARD',
  COMPANY_CARD = 'STC.CARD_TYPES.COMPANY_CARD',
  WORKSHOP_CARD = 'STC.CARD_TYPES.WORKSHOP_CARD'
}

export enum CardPossessionType {
  POLISH_CARD = 'STC.CARD_POSSESSION_TYPE.POLISH_CARD',
  FOREIGN_CARD = 'STC.CARD_POSSESSION_TYPE.FOREIGN_CARD',
  NO_CARD = 'STC.CARD_POSSESSION_TYPE.NO_CARD',
  TEMPORARY_CARD = 'STC.CARD_POSSESSION_TYPE.TEMPORARY_CARD'
}

export enum DriverCardType {
  NORMAL_CARD = 'STC.DRIVER_CARD_TYPE.NORMAL_CARD',
  TEMPORARY_CARD = 'STC.DRIVER_CARD_TYPE.TEMPORARY_CARD'
}

export enum TemporaryCardPossessionType {
  OBTAIN = 'STC.TEMPORARY_CARD_POSSESSION.OBTAIN',
  NOT_OBTAINED = 'STC.TEMPORARY_CARD_POSSESSION.NOT_OBTAINED'
}

export enum AcquireChannelType {
  INTERNET = 'STC.ACQUIRE_CHANNEL_TYPE.INTERNET',
  PAPER = 'STC.ACQUIRE_CHANNEL_TYPE.PAPER'
}

export enum ApplicationKind {
  HYBRID = 'HYBRID',
  PAPER_V2 = 'PAPER_V2'
}
export enum TachographLandingPageStep {
  START,
  CARD_TYPE,
  CARD_POSSESSION,
  CHANNEL,
  SUMMARY,
  DRIVER_CARD_TYPE,
  TEMPORARY_CARD_POSSESSION,
  VALID_DATE,
  PAPER_LOGIN
}

<section class="image__section">
  <ng-template [ngIf]="!isMobile" [ngIfElse]="dragScroll">
    <div class="image__section-no-carousel">
      <div class="image__section-no-carousel--wrapper" *ngFor="let item of items">
        <img loading="lazy" [alt]="item.alt" [src]="item.imageUrl" />
        <figcaption>
          <span>{{ item.caption }}</span>
        </figcaption>
      </div>
    </div>
  </ng-template>

  <ng-template #dragScroll>
    <drag-scroll #ds [snap-disabled]="true" [drag-scroll-y-disabled]="true" (indexChanged)="indexChanged($event)">
      <div #viewItem drag-scroll-item class="image__section--wrapper image__section--carousel"
        *ngFor="let item of items; let i = index">
        <img loading="lazy" [alt]="item.alt" [src]="item.imageUrl" (load)="imageLoaded()" />
        <figcaption>
          <span>{{ item.caption }}</span>
        </figcaption>
      </div>
    </drag-scroll>
    <div class="dots" #dots>
      <div class="dot" (click)="onDotClick(i)" role="button" aria-label="Slider button"
        [ngClass]="{ active: i === activeDotIndex }" *ngFor="let dot of Array(numberOfDots); let i = index"></div>
    </div>
  </ng-template>
</section>
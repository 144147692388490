<div class="bok-info">
  <h2 class="bok-info__header">
    {{ 'PAYMENT.PAYMENT_CONFIRMATION.CUSTOMER_SERVICE_HEADER' | translate }}
  </h2>
  <div class="bok-info-table">
    <div class="bok-info-table__col bok-info-table__col--bordered text-line text-line--standard-10">
      <p>
        {{ 'PAYMENT.PAYMENT_CONFIRMATION.CUSTOMER_SERVICE_HELPLINE_HEADER' | translate }}
      </p>
      <span class="text-line text-line--blue-12-bold text-line--padding-v-10">
        {{ 'PAYMENT.PAYMENT_CONFIRMATION.CUSTOMER_SERVICE_PHONE' | translate }}
      </span>
    </div>
    <div class="bok-info-table__col bok-info-table__col--bordered text-line text-line--standard-10">
      <p>
        {{ 'PAYMENT.PAYMENT_CONFIRMATION.CUSTOMER_SERVICE_EMAIL_HEADER' | translate }}
      </p>
      <span class="text-line text-line--blue-12-bold text-line--padding-v-10">
        {{ 'PAYMENT.PAYMENT_CONFIRMATION.CUSTOMER_SERVICE_EMAIL' | translate }}
      </span>
    </div>
  </div>
  <div class="bok-info__footer text-line text-line--standard-8" [innerHTML]="'PAYMENT.PAYMENT_CONFIRMATION.CUSTOMER_SERVICE_TEXT' | translate"></div>
</div>

export type LoaderType = 'splash' | 'spinner' | 'dots' | 'blue-dots' | 'bordered-dots' | 'car';

export interface LoaderDefaults {
  BD_COLOR: string;
  TYPE_COLOR: string;
  LOADER_TYPE: LoaderType;
  Z_INDEX: number;
  SHOW: boolean;
  ANIMATION: string;
  ON: boolean;
}

export const DEFAULTS: LoaderDefaults = {
  BD_COLOR: 'transparent',
  TYPE_COLOR: 'transparent',
  LOADER_TYPE: 'spinner',
  Z_INDEX: 999,
  SHOW: true,
  ANIMATION: 'fadeIn',
  ON: true
};

export const PRIMARY_LOADER = 'primary';

export interface LoadersInterface {
  bdColor?: string;
  color?: string;
  type?: string;
  fullScreen?: boolean;
  zIndex?: number;
}

export class CLoaders {
  name: string;
  bdColor: string;
  type: string;
  class: string;
  fullScreen: boolean;
  show: boolean;
  zIndex: number;
  animation: string;
  positionRelative: boolean;
  reverse: boolean;
  loadingText: string;

  constructor(init?: Partial<CLoaders>) {
    Object.assign(this, init);
  }
}

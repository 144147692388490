<section class="services-menu-list" *ngIf="content">
  <div class="inner-list">
    <h2>{{ content.title }}</h2>
    <ul>
      <li *ngFor="let service of servicesMenu">
        <ic-service-link
          [internalUrl]="['/' + service.redirectRoute]"
          [externalUrl]="service.href"
          [type]="ServiceLinkType.CATEGORY_PAGE">
          {{ service.text | translate }}</ic-service-link
        >
      </li>
    </ul>
  </div>
</section>

import { RefundFormDataFormValidation } from './refund-form-data.model';
import { Validators } from '@angular/forms';
import { ValidationPatterns } from 'src/app/shared/validation/validation-patterns.const';

export const defaultRefundFormDataValidation: RefundFormDataFormValidation = {
  formGroupName: 'refundFormData',

  formFields: {
    bankAccountNumber: Validators.compose([
      Validators.required,
      Validators.pattern(ValidationPatterns.bankAccountNumber)
    ]),
    refundType: Validators.compose([])
  }
};

import { OAuthStorage } from 'angular-oauth2-oidc';
import { Injectable } from '@angular/core';

@Injectable()
export class InMemoryAuthStorage implements OAuthStorage {
  private readonly memory: Map<string, string> = new Map();
  private readonly storage = sessionStorage;
  private readonly storageKeys: string[] = ['PKCI_verifier', 'nonce', 'url', 'state'];

  getItem(key: string): string {
    return this.storageKeys.includes(key) ? this.storage.getItem(key) : this.memory.get(key);
  }

  removeItem(key: string): void {
    this.storageKeys.includes(key) ? this.storage.removeItem(key) : this.memory.delete(key);
  }

  setItem(key: string, data: string): void {
    this.storageKeys.includes(key) ? this.storage.setItem(key, data) : this.memory.set(key, data);
  }
}

/**
 * INFOCAR STC API
 * API for processing STC applications
 *
 * OpenAPI spec version: v1
 * Contact: java@pulab.pl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * DTO describing enum value and translated reason
 */
export interface ReasonDto {
  /**
   * Translated reason
   */
  message: string;
  /**
   * Reason type
   */
  reasonType: ReasonDto.ReasonTypeEnum;
}
export namespace ReasonDto {
  export type ReasonTypeEnum =
    | 'FIRST_ISSUE'
    | 'CLOSE_EXPIRY_DATE'
    | 'CARD_LOSS'
    | 'CARD_THEFT'
    | 'DAMAGED_OR_DEFECTIVE_CARD'
    | 'INCORRECTLY_ENTERED_OR_LOST_PIN'
    | 'RESIDENCE_COUNTRY_CHANGE'
    | 'ADMINISTRATIVE_DATA_CHANGE'
    | 'PERSONAL_OR_ADMINISTRATIVE_DATA_CHANGE'
    | 'TIME_AND_NON_RENEWABLE_CARD'
    | 'FIRST_ISSUE_LOST_FOREIGN_CARD'
    | 'COMPLAINT'
  export const ReasonTypeEnum = {
    FIRSTISSUE: 'FIRST_ISSUE' as ReasonTypeEnum,
    CLOSEEXPIRYDATE: 'CLOSE_EXPIRY_DATE' as ReasonTypeEnum,
    CARDLOSS: 'CARD_LOSS' as ReasonTypeEnum,
    CARDTHEFT: 'CARD_THEFT' as ReasonTypeEnum,
    DAMAGEDORDEFECTIVECARD: 'DAMAGED_OR_DEFECTIVE_CARD' as ReasonTypeEnum,
    INCORRECTLYENTEREDORLOSTPIN: 'INCORRECTLY_ENTERED_OR_LOST_PIN' as ReasonTypeEnum,
    RESIDENCECOUNTRYCHANGE: 'RESIDENCE_COUNTRY_CHANGE' as ReasonTypeEnum,
    ADMINISTRATIVEDATACHANGE: 'ADMINISTRATIVE_DATA_CHANGE' as ReasonTypeEnum,
    PERSONALORADMINISTRATIVEDATACHANGE: 'PERSONAL_OR_ADMINISTRATIVE_DATA_CHANGE' as ReasonTypeEnum,
    TIMEANDNONRENEWABLECARD: 'TIME_AND_NON_RENEWABLE_CARD' as ReasonTypeEnum,
    RENEWALFOREIGNCARD: 'RENEWAL_FOREIGN_CARD' as ReasonTypeEnum,
    FIRSTISSUELOSTFOREIGNCARD: 'FIRST_ISSUE_LOST_FOREIGN_CARD' as ReasonTypeEnum,
    COMPLAINT: 'COMPLAINT' as ReasonTypeEnum
  };
}

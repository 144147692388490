<section class="accept-term-modal">
  <header class="header">
    <h1 class="header-title">{{ content.headerTitle }}</h1>
    <!-- TODO add text to json -->
    <button [attr.aria-label]="'Zamknij'" (click)="onCancelClick()" class="close-btn">
      <svg-icon class="close-btn-icon" src="assets/images/close_mobile_.svg"></svg-icon>
    </button>
  </header>
  <main>
    {{ content.message }}
  </main>
  <div class="nav-btns">
    <div class="modal-btn">
      <ic-ghost-button
        class="confirm-modal-btn"
        (click)="onOkClick()"
        [width]="buttonOk.width"
        [height]="buttonOk.height"
        >{{ content.buttonOkLabel || 'Potwierdź' }}
      </ic-ghost-button>
    </div>
    <div class="modal-btn">
      <ic-ghost-button
        class="confirm-modal-btn"
        (click)="onCancelClick()"
        [width]="buttonCancel.width"
        [type]="GhostButtonType.SMALL_SECOND_RANK_FLEX"
        [height]="buttonOk.height"
        >{{ content.buttonCancelLabel || 'Anuluj' }}
      </ic-ghost-button>
    </div>
  </div>
</section>

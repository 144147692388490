import { AcceptDateModalComponent } from 'src/app/shared/components/modal/accept-date-modal/accept-date-modal.component';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { ModalService } from '../../modal/modal.service';
import { AvailableExam, ExamType } from '../_models/available-exam';
import { Day } from '../_models/day';
import { ExamDate } from '../_models/exam-date';

const EXAM_TYPES = ['Teoria', 'Praktyka', 'Łączony'];
const EXAM_FULLNAMES = [
  'Egzamin teoretyczny kat.B1',
  'Egzamin praktyczny kat.B1',
  'Egzamin teoretyczny + praktyczny kat.B1'
];

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss']
})
export class DateComponent implements OnInit {
  @Input() date: ExamDate;
  @Output() dateConfirmed: EventEmitter<void> = new EventEmitter();

  day: Day;
  content: any;

  constructor(private modalService: ModalService, private translate: TranslateService) {}

  ngOnInit() {
    this.translate.get('WORD_REGISTRATION.WORD_EXAM_CALENDAR.DATE').subscribe({
      next: (content) => {
        this.content = content;
      }
    });
  }

  setExamAdditionalInformations(exam: AvailableExam): AvailableExam {
    exam.examFullName = EXAM_FULLNAMES[exam.type];
    exam.examHour = this.date.hour;
    return exam;
  }

  getExamName(type: ExamType): string {
    return EXAM_TYPES[type];
  }
  registerOnExam(availableExam: AvailableExam) {
    if (availableExam.availablePlaces > 0) {
      const ref = this.modalService.open(AcceptDateModalComponent, {
        data: availableExam
      });
      const sub = ref.afterClosed.pipe(filter((result) => result != null)).subscribe((res) => {
        sub.unsubscribe();
        this.dateConfirmed.emit();
      });
    }
  }
}

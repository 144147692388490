import {NgbDatepickerI18n} from '@ng-bootstrap/ng-bootstrap';
import {Injectable} from '@angular/core';
import {TranslationWidth} from '@angular/common';

const DAYS = ['Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota', 'Niedziela'];
const MONTHS = [
  'Styczeń',
  'Luty',
  'Marzec',
  'Kwiecień',
  'Maj',
  'Czerwiec',
  'Lipiec',
  'Sierpień',
  'Wrzesień',
  'Październik',
  'Listopad',
  'Grudzień'
];

@Injectable()
export class IcDatepickerI18n extends NgbDatepickerI18n {
  getWeekdayShortName(weekday: number): string {
    return DAYS[weekday - 1].substring(0, 1);
  }
  getMonthShortName(month: number, year?: number): string {
    return MONTHS[month - 1].substring(0, 3).toUpperCase();
  }
  getMonthFullName(month: number, year?: number): string {
    return MONTHS[month - 1];
  }
  getDayAriaLabel(date: import('@ng-bootstrap/ng-bootstrap').NgbDateStruct): string {
    return date.day.toString();
  }

  getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
    return DAYS[weekday - 1].substring(0, width);;
  }
}

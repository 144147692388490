<div class="cropper-box" *ngIf="cropperImage && pageTexts">
  <div class="background-overlay" *ngIf="isLoading">
    <app-loaders
      [type]="'car'"
      [loadingText]="'LOADERS.PLEASE_WAIT' | translate"
      [show]="isLoading"
      [fullScreen]="false"></app-loaders>
  </div>
  <header>
    <h1 class="header-title">{{ pageTexts.title }}</h1>
    <button [attr.aria-label]="pageTexts.btnCloseAria" (click)="onClose()" class="close-btn">
      <svg-icon class="close-btn-icon" src="assets/images/close_mobile_.svg"></svg-icon>
    </button>
  </header>

  <main class="cropper__box__content">
    <angular-cropper
      #cropperElement
      [cropperOptions]="cropperConfig"
      (ready)="readyCrop($event)"
      [imageUrl]="cropperImage"
      class="mb-3 mt-3 d-block"></angular-cropper>

    <div class="cropper__option__box__wrapper">
      <div class="cropper-options-box">
        <div class="cropper-options-box__group">
          <app-image-edit-button
            label="{{ pageTexts.rotate_left }}"
            [width]="41"
            [height]="41"
            [buttonType]="ImageButtonType.ROTATE_LEFT"
            (click)="cropperElement.cropper.rotate('-90')"></app-image-edit-button>

          <app-image-edit-button
            label="{{ pageTexts.rotate_right }}"
            [width]="41"
            [height]="41"
            [buttonType]="ImageButtonType.ROTATE_RIGHT"
            (click)="cropperElement.cropper.rotate('90')"></app-image-edit-button>
        </div>

        <div class="cropper-options-box__group" *ngIf="!isMobile">
          <app-image-edit-button
            label="{{ pageTexts.slight_rotate_left }}"
            [width]="53"
            [height]="41"
            [buttonType]="ImageButtonType.ROTATE_LEFT"
            (click)="cropperElement.cropper.rotate('-10')"></app-image-edit-button>

          <app-image-edit-button
            label="{{ pageTexts.slight_rotate_right }}"
            [width]="53"
            [height]="41"
            [buttonType]="ImageButtonType.ROTATE_RIGHT"
            (click)="cropperElement.cropper.rotate('10')"></app-image-edit-button>
        </div>

        <div class="cropper-options-box__group">
          <app-image-edit-button
            label="{{ pageTexts.zoom_in }}"
            [width]="41"
            [height]="41"
            [buttonType]="ImageButtonType.PLUS"
            (click)="zoom(0.1)"></app-image-edit-button>

          <app-image-edit-button
            label="{{ pageTexts.zoom_out }}"
            [width]="41"
            [height]="41"
            [buttonType]="ImageButtonType.MINUS"
            (click)="zoom(-0.1)"></app-image-edit-button>
        </div>
      </div>
      <div class="cropper-options-box cropper-options-box--save">
        <button class="cropper__options__box__reset" (click)="resetCropper()">
          {{ pageTexts.reset }}
        </button>
        <ic-ghost-button
          appPreventDoubleClick
          [ngClass]="{ disabled: !cropperReady, 'add-button': true }"
          [typeHTML]="'button'"
          [showArrow]="false"
          [disabled]="!cropperReady"
          [width]="103"
          (throttledClick)="attachmentType === AttachmentType.PHOTO ? onSaveAttempt() : onImageSave()"
          [justifyContent]="'center'">
          <span class="forwardSpan">{{ 'STC.ATTACHMENTS.INPUT.save' | translate }}</span>
        </ic-ghost-button>

        <ic-ghost-button
          *ngIf="imageEdited"
          appPreventDoubleClick
          [ngClass]="{ disabled: !cropperReady, 'add-button': true }"
          [typeHTML]="'button'"
          [showArrow]="false"
          [type]="GhostButtonType.BIG_SECOND_RANK_FLEX"
          [disabled]="!cropperReady"
          [width]="103"
          (throttledClick)="onDelete()"
          [justifyContent]="'center'">
          <span class="forwardSpan">{{ 'STC.ATTACHMENTS.INPUT.delete' | translate }}</span>
        </ic-ghost-button>

        <ic-ghost-button
          *ngIf="!imageEdited"
          appPreventDoubleClick
          [ngClass]="{ disabled: !cropperReady, 'add-button': true }"
          [typeHTML]="'button'"
          [showArrow]="false"
          [type]="GhostButtonType.BIG_SECOND_RANK_FLEX"
          [disabled]="!cropperReady"
          [width]="103"
          (throttledClick)="onClose()"
          [justifyContent]="'center'">
          <span class="forwardSpan">{{ 'STC.ATTACHMENTS.INPUT.cancel' | translate }}</span>
        </ic-ghost-button>
      </div>
    </div>
  </main>
</div>

<div class="cropper-box cropper-box__confirmation" *ngIf="showSaveAttemptModal">
  <header>
    <h1 class="header-title">Czy na pewno chcesz zapisać zdjęcie?</h1>
    <button [attr.aria-label]="pageTexts.btnCloseAria" (click)="onSaveAttemptClose()" class="close-btn">
      <svg-icon class="close-btn-icon" src="assets/images/close_mobile_.svg"></svg-icon>
    </button>
  </header>

  <main>
    <section class="cropper-box__preview">
      <app-loaders *ngIf="!thumbnail" [type]="'dots'"></app-loaders>
      <img
        *ngIf="thumbnail"
        alt=""
        [id]="'thumbnail'"
        class="thumbnail"
        [src]="thumbnail" />
    </section>
  
    <section class="cropper-box__info">
      <p>Zdjęcie powinno przedstawiać całą głowę (od jej czubka) oraz górną część barków, twarz powinna zajmować 70-80% zdjęcia. Pamiętaj, że jeśli zdjęcie nie będzie spełniać wymagań, twój wniosek o kartę może zostać odrzucony.</p>
    </section>
  
    <section class="cropper-box__buttons">
      <ic-ghost-button
            appPreventDoubleClick
            [ngClass]="{ disabled: !cropperReady, 'add-button': true }"
            [typeHTML]="'button'"
            [showArrow]="false"
            [disabled]="!cropperReady"
            [width]="103"
            (throttledClick)="onImageCropUpload()"
            [justifyContent]="'center'">
            <span class="forwardSpan">{{ 'STC.ATTACHMENTS.INPUT.save' | translate }}</span>
          </ic-ghost-button>
  
          <ic-ghost-button
            appPreventDoubleClick
            [ngClass]="{ disabled: !cropperReady, 'add-button': true }"
            [typeHTML]="'button'"
            [showArrow]="false"
            [type]="GhostButtonType.BIG_SECOND_RANK_FLEX"
            [disabled]="!cropperReady"
            [width]="103"
            (throttledClick)="onSaveAttemptClose()"
            [justifyContent]="'center'">
            <span class="forwardSpan">{{ 'STC.ATTACHMENTS.INPUT.cancel' | translate }}</span>
          </ic-ghost-button>
    </section>
  </main>

</div>
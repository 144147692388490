import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { BaseModalDirective } from '../base-modal';
import { GhostButtonType } from '../../ghost-button/ghost-button-type.enum';
import { TermsOfServicePageModel } from 'src/app/info-pages/terms-of-service-page/_model/terms-of-service-page.model';
import { ModalContent } from '../../acceptances-checboxes/_models/acceptances-modal-content.model';

@Component({
  selector: 'app-text-information-modal',
  templateUrl: './text-information-modal.component.html',
  styleUrls: ['./text-information-modal.component.scss']
})
export class TextInformationModalComponent extends BaseModalDirective implements OnInit {
  GhostButtonType: typeof GhostButtonType = GhostButtonType;
  termsContent: TermsOfServicePageModel;
  content: ModalContent;
  pageText: any;
  constructor(private translate: TranslateService) {
    super();
  }

  ngOnInit() {
    this.translate.get('SHARED.TEXT_INFO_MODAL').subscribe({
      next: (content) => {
        this.pageText = content;
      }
    });
    this.content = this.getData().content;
    this.termsContent = this.getData().termsContent;
  }

  onCancelClick() {
    this.close();
  }
}

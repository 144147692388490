export const SIGNATURES_INFO_PAGE = 'jak-podpisac-wniosek';
export const SITE_MAP_PAGE = 'mapa-strony';
export const CONTACTPAGE_ROUTE = 'kontakt';
export const TERMS_OF_SERVICE_ROUTE = 'regulamin';
export const ABOUT_US = 'o-serwisie';
export const DOWNLOAD_TACHO_APPLICATIONS = 'wnioski';
export const DOWNLOAD_TACHO_FORMS = 'formularze';
export const DEFECTIVE_CARD_INFO_ROUTE = 'powiadomienie-dotyczace-waznosci-karty';
export const MODERNIZATION_WORK_ROUTE = 'prace-serwisowe';
export const TACHOGRAPH_UA_FAQ = 'ua-faq';
export const INFORMATION_CLAUSE = 'klauzula-informacyjna'

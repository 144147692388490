<app-loader-stripe id="flamboyant-loader"></app-loader-stripe>
<ng-container *ngIf="!showElements.hideInfobars">
  <app-info-bar
    *ngFor="let bar of infobars$ | async; let i = index; let count = count"
    [message]="bar"
    [zIndex]="count - i">
  </app-info-bar>
</ng-container>
<app-header
  (changeLng)="changeLngMethod($event)"
  id="main-header-menu"
  [logoOnly]="showElements.showOnlyLogo"></app-header>
<app-breadcrumbs *ngIf="!showElements.showOnlyLogo"></app-breadcrumbs>
<router-outlet (activate)="onComponentActivated($event)"></router-outlet>
<app-footer></app-footer>

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customer-service-office-info-box',
  templateUrl: './customer-service-office-info-box.component.html',
  styleUrls: ['./customer-service-office-info-box.component.scss']
})
export class CustomerServiceOfficeInfoBoxComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

<button class="image__edit__button">
  <svg-icon
    class="image__edit__icon"
    *ngIf="buttonType == ImageButtonType.ROTATE_LEFT"
    src="assets/images/rotate-left.svg"
    [svgStyle]="{ 'width.px': width, 'height.px': height }"></svg-icon>
  <svg-icon
    class="image__edit__icon"
    *ngIf="buttonType == ImageButtonType.ROTATE_RIGHT"
    src="assets/images/rotate-right.svg"
    [svgStyle]="{ 'width.px': width, 'height.px': height }"></svg-icon>
  <svg-icon
    class="image__edit__icon"
    *ngIf="buttonType == ImageButtonType.PLUS"
    src="assets/images/plus-zoom.svg"
    [svgStyle]="{ 'width.px': width, 'height.px': height }"></svg-icon>
  <svg-icon
    class="image__edit__icon"
    *ngIf="buttonType == ImageButtonType.MINUS"
    src="assets/images/minus.svg"
    [svgStyle]="{ 'width.px': width, 'height.px': height }"></svg-icon>
  <span>{{ label }}</span>
</button>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pagination-limit-chooser',
  templateUrl: './pagination-limit-chooser.component.html',
  styleUrls: ['./pagination-limit-chooser.component.scss']
})
export class PaginationLimitChooserComponent implements OnInit {
  @Input() items: Array<number>;
  @Input() currentItem: number;
  @Output() selectionChanged: EventEmitter<number> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onItemSelected(selectedItem: number): void {
    if (this.currentItem !== selectedItem) {
      this.selectionChanged.emit(selectedItem);
    }
    this.currentItem = selectedItem;
  }
}

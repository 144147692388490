import { Validators, ValidatorFn } from '@angular/forms';
import { FormValidation } from '../../services/form/base-form-binder.service';

export interface AcceptancesFormValidation extends FormValidation {
  formGroupName: string;
  formFields: {
    RULES?: ValidatorFn;
    INFOS?: ValidatorFn;
    MARKETING_EMAIL?: ValidatorFn;
    MARKETING_PHONE?: ValidatorFn;
  };
}

export const defaultAcceptancesValidation: AcceptancesFormValidation = {
  formGroupName: 'acceptances',
  formFields: {
    RULES: Validators.compose([Validators.requiredTrue]),
    INFOS: Validators.compose([Validators.requiredTrue]),
    MARKETING_EMAIL: Validators.nullValidator,
    MARKETING_PHONE: Validators.nullValidator
  }
};

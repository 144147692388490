import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AutopayTermsService {

  constructor(private http: HttpClient) { }

  getAutopayTerms(paymentType: 'BLIK' | 'PAYMENT_CARD'): Observable<{regulation: string}> {
    let uri = `${environment.PAYMENT_PROCESSOR.BASE}${environment.AUTOPAY.COMMITMENTS}${environment.AUTOPAY.REGULATIONS}`;

    if(paymentType === 'PAYMENT_CARD') {
      uri += `${environment.AUTOPAY.SUBSCRIPTION}`;
    }

    return this.http.get<{regulation: string}>(uri);
  }
}

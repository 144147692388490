import {
  Injectable,
  ComponentRef,
  ComponentFactoryResolver,
  ApplicationRef,
  Injector,
  Type,
  EmbeddedViewRef,
  Renderer2,
  RendererFactory2
} from '@angular/core';
import { ModalComponent } from './modal.component';
import { ModalConfig } from './modal-config';
import { ModalRef } from './modal-ref';
import { ModalInjector } from './modal-injector';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  modalComponentRefs: ComponentRef<ModalComponent>[] = Array<ComponentRef<ModalComponent>>();

  private renderer: Renderer2;
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public open(componentType: Type<any>, config: ModalConfig = null) {
    if (!config) {
      config = new ModalConfig();
    }

    if (!this.renderer.parentNode('.dialog')) {
      const modalRef = this.appendModalComponentToBody(config);

      this.modalComponentRefs[this.modalComponentRefs.length - 1].instance.childComponentType =
        componentType;
      return modalRef;
    }
  }

  private appendModalComponentToBody(config: ModalConfig) {
    const map = new WeakMap();
    map.set(ModalConfig, config);

    const modalRef = new ModalRef(config);
    map.set(ModalRef, modalRef);

    const sub = modalRef.afterClosed.subscribe(() => {
      this.removeModalComponentFromBody();
      sub.unsubscribe();
    });

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ModalComponent);
    const componentRef = componentFactory.create(new ModalInjector(this.injector, map));

    this.appRef.attachView(componentRef.hostView);

    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    document.querySelector('app-layout').appendChild(domElem);

    this.modalComponentRefs.push(componentRef);

    this.modalComponentRefs[this.modalComponentRefs.length - 1].instance.onClose.subscribe(() => {
      this.removeModalComponentFromBody();
    });

    document.body.style.overflow = 'hidden';

    return modalRef;
  }

  private removeModalComponentFromBody() {
    document.body.style.overflow = 'auto';
    const modalComponentRef = this.modalComponentRefs.pop();
    this.appRef.detachView(modalComponentRef.hostView);
    modalComponentRef.destroy();
  }
}

<div class="employee__wrapper" *ngIf="content">
  <header class="employee__wrapper-header">
    <div class="employee__wrapper-header--title">
      <h2>{{ content.title }}</h2>
      <!-- TODO add text to json -->
      <button aria-label="Zamknij" (click)="onClose()" class="close-btn">
        <svg-icon class="close-btn-icon" src="assets/images/close_mobile_.svg"></svg-icon>
      </button>
    </div>
    <p class="employee__wrapper-header--desc">{{ content.diffData }}</p>
  </header>
  <main class="employee__wrapper-main">
    <p class="employee__wrapper-main--desc">
      <strong>{{ content.updateData }}</strong>
    </p>
    <div class="employee__wrapper-main--buttons">
      <ic-ghost-button
        [showArrow]="false"
        [justifyContent]="'center'"
        class="button"
        [typeHTML]="'button'"
        [width]="136"
        (click)="submitForm()">
        <span>{{ content.yesBtn }}</span>
      </ic-ghost-button>

      <ic-ghost-button
        [showArrow]="false"
        [type]="GhostButtonType.BIG_SECOND_RANK"
        [justifyContent]="'center'"
        class="button"
        [typeHTML]="'button'"
        [width]="134"
        (click)="onClose()">
        <span>{{ content.noBtn }}</span>
      </ic-ghost-button>
    </div>
    <section class="employee__wrapper-info">
      <p [innerHTML]="content.yesBtnInfo"></p>
      <p [innerHTML]="content.noBtnInfo"></p>
    </section>
  </main>
</div>

import { AccountActivationResolver } from './../_resolver/account-activation.resolver';
import { AnonymousGuard } from 'src/app/core/guard/anonymous-guard';
import { MainPageComponent } from '../main-page.component';
import { Route } from '@angular/router';
import { MainPageResolver } from '../_resolver/main-page.resolver';

export const MAIN_PAGE_ROUTE = '';
export const ACTIVATION_ROUTE = 'aktywacja';
export const ACTIVATION_ROUTE_PARAMETRIZED = 'aktywacja/:token';

export const ACTIVATION_ROUTING: Route = {
  path: ACTIVATION_ROUTE_PARAMETRIZED,
  component: MainPageComponent,
  resolve: {
    mainPage: MainPageResolver,
    accountActivation: AccountActivationResolver
  },
  canActivate: [AnonymousGuard],
  runGuardsAndResolvers: 'always'
};
export const MAIN_PAGE_ROUTING: Route = {
  path: '',
  component: MainPageComponent,
  pathMatch: 'full',
  resolve: {
    mainPage: MainPageResolver
  },
  // runGuardsAndResolvers: 'always',
  children: [ACTIVATION_ROUTING]
};

import {Component, OnInit} from '@angular/core';
import {BaseModalDirective} from 'src/app/shared/components/modal/base-modal';
import {GhostButtonType} from '../../ghost-button/ghost-button-type.enum';

@Component({
  selector: 'app-training-card-info',
  templateUrl: './training-card-info.component.html',
  styleUrls: ['./training-card-info.component.scss']
})
export class TrainingCardInfoComponent extends BaseModalDirective implements OnInit {
  GhostButtonType = GhostButtonType;

  constructor() {
    super();
  }

  ngOnInit(): void {}

  moveForward() {
    this.close(true);
  }

  onClose() {
    this.close();
  }
}

import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment as env } from 'src/environments/environment';
import { ApplicationSummaryConfirmationDto } from 'src/app/applications/stc/_models/application-summary-confirmation.dto';
import { IsEdoAvailable, IsEpuapAvailable } from '../models/sign-methods-availability.model';
import { STC_V1, STC_V2 } from 'src/app/shared/consts/api-version.config';
import { CardTypes } from '../../../../../applications/stc/_consts/card-types.enum';
import { CorrectionSummaryConfirmationDto } from '../../../../../applications/stc/_models/correction-summary-confirmation.dto';
import { EpuapSignUrlDto } from '../models/epuap-sign-url.dto';
import { EdoSignUrlDto } from '../models/edo-sign-url.dto';
import { QualifiedSignUrlDto } from '../models/qualified-sign-url.dto';

export abstract class SignMethodModalService {
  protected stcHeadersV1 = new HttpHeaders().set('Content-Type', STC_V1);
  protected stcHeadersV2 = new HttpHeaders().set('Content-Type', STC_V2);

  constructor(protected http: HttpClient) {}

  abstract getEpuapLink(applicationId: string): Observable<EpuapSignUrlDto>;

  abstract getEdohubLink(applicationId: string): Observable<EdoSignUrlDto>;

  abstract cancelEpuapSign(applicationId: string): Observable<void>;

  abstract getStatus(documentSignedId: string): Observable<string>;

  abstract getJnlpUrl(applicationId: string): Observable<QualifiedSignUrlDto>;

  abstract sendConfirmation(
    applicationId: string,
    applicationType: CardTypes,
    isPaper?: boolean
  ): Observable<void>;

  isEpuapAvailable(): Observable<IsEpuapAvailable> {
    return this.http.get<IsEpuapAvailable>(
      `${env.BACKEND_API.BASE}${env.BACKEND_API.SIGN}${env.BACKEND_API.IS_EPUAP_AVAILABLE}`
    );
  }

  isEdoAvailable(): Observable<IsEdoAvailable> {
    return this.http.get<IsEdoAvailable>(
      `${env.BACKEND_API.BASE}${env.BACKEND_API.EDOHUB}${env.BACKEND_API.IS_EDO_AVAILABLE}`
    );
  }
}

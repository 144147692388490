import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
  CorrectionsErrors,
  FieldErrorEntry
} from 'src/app/shared/models/corrections/corrections.model';
import { DriverCardApplicationDto } from 'src/app/shared/models/driver-card-application/driver-card-application.model';
import { DriverCardNumberFormBinder } from './driver-card-number.form-binder';
import {
  DriverCardNumberDto,
  DriverCardNumberFormValidation,
  DriverCardNumberStaticContent
} from './driver-card-number.model';
import { defaultDriverCardNumberValidation } from './driver-card-number.validation';

@Component({
  selector: 'app-driver-card-number',
  templateUrl: './driver-card-number.component.html',
  styleUrls: ['./driver-card-number.component.scss'],
  providers: [DriverCardNumberFormBinder]
})
export class DriverCardNumberComponent implements OnInit, OnDestroy {
  @Input() staticContent: DriverCardNumberStaticContent;
  @Input() formValidation: DriverCardNumberFormValidation = defaultDriverCardNumberValidation;
  @Input() aggregateFormGroup: UntypedFormGroup;
  @Input() fetchedData: DriverCardNumberDto;
  @Input() reason: DriverCardApplicationDto.ReasonTypeEnum;
  @Input() htmlElementId: string;

  @Output() saveDraft: EventEmitter<any> = new EventEmitter();

  @Input() set correctionsErrors(data: CorrectionsErrors) {
    this._correctionsErrors = data;
    this.getFieldsErrors(data);
  }
  private _correctionsErrors: CorrectionsErrors;
  formGroup: UntypedFormGroup;
  formPrefix = 'driverCardNumber';
  fieldsErrors: FieldErrorEntry[] = [];

  public constructor(private binder: DriverCardNumberFormBinder) {}

  public ngOnInit() {
    if (this.reason && this.reason !== DriverCardApplicationDto.ReasonTypeEnum.FIRSTISSUE) {
      this.formValidation = this.binder.addRequiredValidatorToDriverCardNumber(this.formValidation);
    }
    this.formGroup = this.binder.bindForm(this.formValidation);
    this.aggregateFormGroup.addControl(this.formValidation.formGroupName, this.formGroup);
    this.binder.fillFormWith(this.fetchedData);
  }

  sectionErrorsExist(): boolean {
    return this._correctionsErrors?.sectionErrors.some((item) => item.entry === this.formPrefix);
  }

  getFieldsErrors(correctionsErrors: CorrectionsErrors) {
    this.fieldsErrors =
      correctionsErrors?.fieldErrors
        .filter(
          (item) =>
            item.entry.startsWith(this.formPrefix) || item.entry.startsWith('currentDriverCard')
        )
        .map((entry) => {
          return {
            entry: entry.entry,
            previousValue: entry.previousValue
          };
        }) || [];
  }

  isFieldIncludedInCorrection(fieldName: string): boolean {
    return (
      this.fieldsErrors.some((err) => err.entry.endsWith(fieldName)) || this.sectionErrorsExist()
    );
  }

  findPreviousValue(fieldName: string): any {
    return this.fieldsErrors.find((err) => err.entry.endsWith(fieldName))?.previousValue;
  }

  public onBlur() {
    this.saveDraft.emit();
  }

  public isControlRequired(controlName: string): boolean {
    return this.binder.isControlRequired(controlName);
  }

  ngOnDestroy(): void {
    if (this.aggregateFormGroup.contains(this.formValidation.formGroupName)) {
      this.aggregateFormGroup.removeControl(this.formValidation.formGroupName);
    }
  }
}

<div class="slider-component">
  <ngb-carousel
    [animation]="false"
    #carousel
    [showNavigationArrows]="false"
    [pauseOnHover]="false"
    (keydown.arrowleft)="prevSlide()"
    (keydown.arrowright)="nextSlide()"
    (keydown.SPACE)="pauseOrStartCarousel()"
    (slide)="onSlideChange($event)">
    <ng-template
      ngbSlide
      *ngFor="let slide of sliderElements; let i = index"
      [id]="buildSlideId(i)">
      <div class="slide-img-container">
        <img class="slide-img" [src]="slide.img" [alt]="slide.alt" />
      </div>
      <div class="carousel-caption">
        <div class="container">
          <h1
            [innerHTML]="slide?.header"
            *ngIf="carousel.activeId === buildSlideId(i); else bannerTitle"></h1>
          <ng-template #bannerTitle>
            <div [innerHTML]="slide?.header"></div>
          </ng-template>
          <h2
            [innerHTML]="slide?.subheader"
            *ngIf="carousel.activeId === buildSlideId(i); else bannerSubTitle"></h2>
          <ng-template #bannerSubTitle>
            <p [innerHTML]="slide?.subheader"></p>
          </ng-template>
          <a *ngIf="slide.destination" [routerLink]="slide | destination">
            <ic-ghost-button [type]="GhostButtonType.SLIDER" [showArrow]="true">
              {{ slide?.buttonLabel }}</ic-ghost-button
            >
          </a>
          <a
            *ngIf="!slide.destination"
            href="{{ slide.href }}"
            title="{{ 'LINK_TITLE.OPEN_IN_NEW_TAB' | translate }}"
            target="_blank">
            <ic-ghost-button [type]="GhostButtonType.SLIDER" [showArrow]="true">
              {{ slide?.buttonLabel }}</ic-ghost-button
            >
          </a>
        </div>
      </div>
    </ng-template>
  </ngb-carousel>
  <div class="slider-action-box">
    <div>
      <div class="action-div">
        <ic-ghost-button
          [ariaLabel]="'MAIN_SLIDER.prevBtnAria' | translate"
          [justifyContent]="'center'"
          [type]="GhostButtonType.SLIDER_CONTROL"
          (click)="prevSlide()">
          <img
            class="action-box-icon"
            alt="{{ 'MAIN_SLIDER.prevBtnAria' | translate }}"
            src="assets/images/prev_icon_normal.svg" />
        </ic-ghost-button>
      </div>
      <div class="action-div">
        <ic-ghost-button
          [ariaLabel]="'MAIN_SLIDER.pauseBtnAria' | translate"
          [justifyContent]="'center'"
          [type]="GhostButtonType.SLIDER_CONTROL"
          (click)="pauseOrStartCarousel()"
          ><img
            class="action-box-icon"
            alt="{{ 'MAIN_SLIDER.pauseBtnAria' | translate }}"
            [src]="
              isPaused
                ? 'assets/images/play_icon_normal.svg'
                : 'assets/images/pause_icon_normal.svg'
            " />
        </ic-ghost-button>
      </div>
      <div class="action-div">
        <ic-ghost-button
          [ariaLabel]="'MAIN_SLIDER.nextBtnAria' | translate"
          [justifyContent]="'center'"
          [type]="GhostButtonType.SLIDER_CONTROL"
          (click)="nextSlide()">
          <img
            class="action-box-icon"
            alt="{{ 'MAIN_SLIDER.nextBtnAria' | translate }}"
            src="assets/images/next_icon_normal.svg" />
        </ic-ghost-button>
      </div>
    </div>
  </div>
</div>

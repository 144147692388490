import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appTriggerClick]'
})
export class TriggerClickDirective {
  constructor() {}

  @HostListener('keydown.enter', ['$event'])
  onElementClick(event) {
    event.target.click();
  }
}

<div class="translation">
  <div class="translation__wrapper">
    <header class="translation__header">
      <h2 class="translation__title">{{ 'TRANSLATION_MODAL.title' | translate }}</h2>
      <button
        (click)="close()"
        [attr.aria-label]="'TRAINING_CARDS.MODAL.buttonCloseAria' | translate"
        class="close-btn">
        <svg-icon class="close-btn-icon" src="assets/images/close_mobile_.svg"></svg-icon>
      </button>
    </header>
    <section class="translation__content">
      <p>{{ 'TRANSLATION_MODAL.desc' | translate }}</p>
      <p appInternalLink [innerHtml]="'TRANSLATION_MODAL.faqs' | translate | safeHtml"></p>
    </section>
    <section class="translation__button">
      <ic-ghost-button [type]="GhostButtonType.BIG_FIRST_RANK_FLEX" (click)="close()">{{
        'TRANSLATION_MODAL.nextBtn' | translate
      }}</ic-ghost-button>
      <ic-ghost-button [type]="GhostButtonType.BIG_SECOND_RANK_FLEX" (click)="goBack()">{{
        lastUrl
          ? ('TRANSLATION_MODAL.backBtn' | translate)
          : ('TRANSLATION_MODAL.backBtnDefault' | translate)
      }}</ic-ghost-button>
    </section>
  </div>
</div>

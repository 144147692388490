import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Input } from '@angular/core';
import { ServiceLink } from '../../../application-landing-pages/obtain-tachograph-card/_models/service-link.model';
import { ServiceLinkType } from 'src/app/shared/components/service-link/service-link-type.enum';
import { Router } from '@angular/router';

@Component({
  selector: 'app-related-services',
  templateUrl: './related-services.component.html',
  styleUrls: ['./related-services.component.scss']
})
export class RelatedServicesComponent implements OnInit {
  content: any;
  @Input() servicesMenu: ServiceLink[];
  ServiceLinkType: typeof ServiceLinkType = ServiceLinkType;

  constructor(private router: Router, private translate: TranslateService) {}

  ngOnInit() {
    const route = this.router.url;
    this.servicesMenu = this.servicesMenu.filter((service) => service.redirectRoute !== route);
    this.translate.get('STC.RELATED_SERVICES').subscribe({
      next: (content) => {
        this.content = content;
      }
    });
  }
}

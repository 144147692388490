import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Extensions } from 'src/app/shared/extensions/extensions';
import { InfoBarsFacadeService } from '../info-bars-facade.service';
import { InfoBarMessage } from '../_models/info-bar-message.model';
import { GhostButtonType } from '../../../shared/components/ghost-button/ghost-button-type.enum';
import { Observable } from 'rxjs';
import { TextEllipsisService } from '../../../shared/directives/text-ellipsis/text-ellipsis.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-info-bar',
  templateUrl: './info-bar.component.html',
  styleUrls: ['./info-bar.component.scss'],
  providers: [TextEllipsisService]
})
export class InfoBarComponent implements OnInit {
  @Input() message: InfoBarMessage;
  @Input() zIndex: number;
  visible: boolean;
  isExpended$: Observable<boolean> = this.textEllipsisService.isEnabled$.pipe(map((it) => !it));
  isClamped$: Observable<boolean> = this.textEllipsisService.isClamped$;

  GhostButtonType: typeof GhostButtonType = GhostButtonType;

  constructor(
    private location: Location,
    private infoBarService: InfoBarsFacadeService,
    private router: Router,
    private translate: TranslateService,
    private textEllipsisService: TextEllipsisService
  ) {}

  ngOnInit() {
    this.visible = this.hasNoCookie();
    this.setupVisibilityTimeout();
    this.textEllipsisService.setEnabled(true);
  }

  setupVisibilityTimeout() {
    const { ttl } = this.message;
    if (typeof ttl === 'number' && ttl > 0) {
      setTimeout(() => (this.visible = false), this.message.ttl * 1000);
    }
  }

  onToggleExpandText(): void {
    this.textEllipsisService.toggleEnabled();
  }

  onClose() {
    Extensions.setCookie(`infobar-${this.message.id}`, 'true', true);
    this.visible = false;
  }

  private hasNoCookie(): boolean {
    return !Extensions.getCookie(`infobar-${this.message.id}`);
  }
}

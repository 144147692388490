<div class="custom-toastr">
    <div class="toast-info-wrapper">
      <div class="toast-message">
        {{ (isComplaint ? ('TOASTR.STC.CREATED_PAPER_COMPLAINT.TITLE' | translate) : ('TOASTR.STC.CREATED_PAPER_APPLICATION.TITLE' | translate)) +  (isComplaint ? (('TOASTR.STC.CREATED_PAPER_COMPLAINT.' + cardType) | translate) : (('TOASTR.STC.CREATED_PAPER_APPLICATION.' + cardType) | translate))}}
      </div>

      <span class="toast-submit" role="button" (click)="rerouteToApplication($event)">{{
        isComplaint ? ('TOASTR.STC.CREATED_PAPER_COMPLAINT.SUBMIT_LABEL' | translate) : ('TOASTR.STC.CREATED_PAPER_APPLICATION.SUBMIT_LABEL' | translate)
      }}</span>
    </div>
  </div>
  
<div class="legal-info">
  <h3 class="legal-info__title">{{ title }}</h3>
  <p class="legal-info__text" *ngFor="let document of legalDocuments">
    <a
      class="legal-info__text-link"
      title="{{ 'LINK_TITLE.OPEN_IN_NEW_TAB' | translate }}"
      target="_blank"
      href="{{ this.externalLinks.get(document.destination) }}"
      >{{ document.text }}</a
    >
  </p>
</div>

<!-- <ng-template [ngIf]="config.type !== AttachmentType.PHOTO"> -->
<ng-template [ngIf]="false">
  <ic-ghost-button
    class="add-button"
    [typeHTML]="'button'"
    [showArrow]="true"
    [width]="117"
    (click)="inputElement.click()"
    appTriggerClick>
    <span class="forwardSpan">{{ 'STC.ATTACHMENTS.INPUT.add' | translate }}</span>
  </ic-ghost-button>
  <input
    [attr.aria-label]="'STC.ATTACHMENTS.INPUT.add_file' | translate"
    hidden
    #inputElement
    type="file"
    [accept]="accept"
    [multiple]="multiple"
    (change)="onUploadFiles($event.target.files)" />
</ng-template>

<ic-ghost-button
  class="add-button add-button--cropper"
  appTriggerClick
  [typeHTML]="'button'"
  [showArrow]="true"
  [width]="config.type === AttachmentType.PHOTO ? 117 : 117"
  (click)="inputCropperElement.click()"
  *ngIf="!cropperImage">
  <span class="forwardSpan">{{
    (config.type === AttachmentType.PHOTO
      ? 'STC.ATTACHMENTS.INPUT.add'
      : 'STC.ATTACHMENTS.INPUT.add'
    ) | translate
  }}</span>
</ic-ghost-button>

<input
  hidden
  [attr.aria-label]="'STC.ATTACHMENTS.INPUT.add_file' | translate"
  class="sr-only"
  type="file"
  (change)="onImageChange($event)"
  #inputCropperElement
  name="file"
  [accept]="accept" />

import * as newsActions from 'src/app/news/_store/news-action';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { MainPage } from '../_model/main-page.model';
import { SliderService } from '../slider/service/slider.service';
import { MainPageService } from '../_service/main-page.service';
import { Store } from '@ngrx/store';
import {
  MainPageState,
  selectCategorySectionData,
  selectSliderData
} from '../_store/main-page.reducer';
import {
  selectPopularServicesData,
  selectServicesData,
  SharedState
} from 'src/app/shared/store/shared.reducer';
import { filter, map, take } from 'rxjs/operators';
import { NewsState, selectNewsState } from 'src/app/news/_store/news-reducer';

@Injectable()
export class MainPageResolver  {
  constructor(
    private sliderService: SliderService,
    private mainPageService: MainPageService,
    private mainPageStore: Store<MainPageState>,
    private sharedStore: Store<SharedState>,
    private newsStore: Store<NewsState>
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<MainPage> {
    this.initMainPageData();
    // unused
    const servicesData$ = this.sharedStore.select(selectServicesData);
    // unused
    const popularServicesData$ = this.sharedStore.select(selectPopularServicesData);
    // unused
    const sliderData$ = this.mainPageStore.select(selectSliderData);
    // unused
    const categorySectionData$ = this.mainPageStore.select(selectCategorySectionData);
    const newsState$ = this.newsStore.select(selectNewsState);
    return combineLatest(
      servicesData$,
      popularServicesData$,
      sliderData$,
      categorySectionData$,
      newsState$,
      (servicesData, popularServicesData, sliderData, categorySectionData, newsState) => {
        return {
          categories: servicesData,
          services: popularServicesData,
          slider: sliderData,
          categoriesSection: categorySectionData,
          news: newsState
        } as MainPage;
      }
    ).pipe(
      filter((x) => {
        return x.news !== undefined && !!x.news && x.news.promoNews.length > 0;
      }),
      map((mainPage) => {
        return mainPage;
      }),
      take(1)
    );
  }

  initMainPageData() {
    this.newsStore.dispatch(new newsActions.GetSliderNews({ page: 1, rows: 6 }));
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReservationConfirmationService {
  constructor() {}

  private waitingForConfirmationSource = new BehaviorSubject(true);
  waitingForConfirmation = this.waitingForConfirmationSource.asObservable();

  nextWaitingForConfirmation(isWaiting: boolean) {
    this.waitingForConfirmationSource.next(isWaiting);
  }
}

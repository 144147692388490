import { Component, OnInit } from '@angular/core';
import { BaseModalDirective } from '../base-modal';
import { GhostButtonType } from '../../ghost-button/ghost-button-type.enum';
import { DefaultConfirmationModalContentModel } from './default-confirmation-modal-content.model';

@Component({
  selector: 'app-delete-account-modal',
  templateUrl: './default-confirmation-modal.component.html',
  styleUrls: ['./default-confirmation-modal.component.scss']
})
export class DefaultConfirmationModalComponent extends BaseModalDirective implements OnInit {
  content: DefaultConfirmationModalContentModel;
  buttonOk = {
    width: 136,
    height: 50
  };
  buttonCancel = {
    width: 136,
    height: 50
  };
  GhostButtonType = GhostButtonType;
  ngOnInit() {
    this.content = this.getData();
  }
  onOkClick() {
    if (this.content.buttonOkFunction) {
      this.content.buttonOkFunction();
    }
    this.close('clickedOk');
  }
  onCancelClick() {
    if (this.content.buttonCancelFunction) {
      this.content.buttonCancelFunction;
    }
    this.close();
  }
}

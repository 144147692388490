<form [formGroup]="config.form" class="accept-term-modal">
  <header class="header">
    <h1 class="header-title" [innerHTML]="content.header"></h1>
    <button
      [attr.aria-label]="content.btnCloseAria"
      (click)="onCancelClick()"
      type="button"
      [disabled]="loading"
      class="close-btn">
      <svg-icon class="close-btn-icon" src="assets/images/close_mobile_.svg"></svg-icon>
    </button>
  </header>
  <main>
    <div class="main__text" [innerHTML]="content.mainText"></div>

    <app-text-input
      formControlName="password"
      [config]="config.controls['password']"
      [form]="config.form"></app-text-input>
  </main>
  <div class="nav-btns">
    <div class="modal-btn">
      <ic-ghost-button
        class="confirm-modal-btn"
        typeHTML="button"
        [disabled]="loading"
        (click)="onOkClick()"
        [width]="buttonOk.width"
        [height]="buttonOk.height"
        >{{ content.deleteButtonLabel }}
      </ic-ghost-button>
    </div>
    <div class="modal-btn">
      <ic-ghost-button
        class="confirm-modal-btn"
        [disabled]="loading"
        typeHTML="button"
        (click)="onCancelClick()"
        [width]="buttonCancel.width"
        [type]="GhostButtonType.SMALL_SECOND_RANK_FLEX"
        [height]="buttonOk.height"
        >{{ content.cancelButtonLabel }}
      </ic-ghost-button>
    </div>
  </div>
</form>

import { BaseModalDirective } from 'src/app/shared/components/modal/base-modal';
import { Component, OnInit } from '@angular/core';
import { GhostButtonType } from '../../ghost-button/ghost-button-type.enum';

@Component({
  selector: 'app-should-keep-anonymous-application-modal',
  templateUrl: './should-keep-anonymous-application-modal.component.html',
  styleUrls: ['./should-keep-anonymous-application-modal.component.scss']
})
export class ShouldKeepAnonymousApplicationModalComponent extends BaseModalDirective implements OnInit {

  GhostButtonType = GhostButtonType;

  constructor() { 
    super();
  }

  ngOnInit(): void {
  }

  onCancelClick(): void {
    this.close(null);
  }

  onDeleteClick(): void {
    this.close('DELETE');
  }

  onContinueClick(): void {
    this.close('CONTINUE')
  }
}

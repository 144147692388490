<main class="ic-page confirmation-list">
  <section class="confirmation-list__header">
    <h1>{{ title }}</h1>
    <button *ngIf="showSingleExam" class="confirmation-list__clear-btn" (click)="clearIdFilter()">
      {{ 'PIK_PAYMENTS.showAllConfirmations' | translate }}
    </button>
  </section>
  <section class="confirmation-list__body">
    <div class="loader-wrapper" *ngIf="!confirmations">
      <app-loaders [type]="'spinner'"></app-loaders>
    </div>
    <ng-container *ngIf="confirmations && confirmations.length">
      <div
        class="confirmation-list__tile confirmation-tile"
        *ngFor="let confirmation of confirmations; let buttonIndex = index">
        <div class="confirmation-tile__header">
          <h2 class="confirmation-tile__title">{{ boxTitle }}</h2>
          <h3 class="confirmation-tile__subtitle">
            {{ confirmation.selectedPositions.categoryName }}
          </h3>
        </div>
        <div class="confirmation-tile__body">
          <p class="confirmation-tile__sum">
            <strong
              >{{ 'PIK_PAYMENTS.paymentSum' | translate }}
              {{ formatPrice(confirmation.totalPrice) }}
              {{ 'PIK_PAYMENTS.unit' | translate }}</strong
            >
          </p>
          <!-- NOTE -->
          <!-- + confirmation.provision -->
          <p class="confirmation-tile__date" *ngIf="confirmation.payedAt != null">
            <strong>{{ 'PIK_PAYMENTS.paymentDate' | translate }} </strong
            >{{ confirmation.payedAt | date: 'dd.MM.yyyy':undefined:'pl' }}
          </p>
        </div>
        <div class="confirmation-tile__button">
          <ic-ghost-button
            #button
            (click)="
              convertPDFConfirmation(
                buttonIndex,
                confirmation.id,
                confirmation.payedAt,
                confirmation.selectedPositions.categoryName
              )
            "
            [type]="GhostButtonType.SMALL_FIRST_RANK"
            [showArrow]="false"
            [justifyContent]="'center'"
            >{{ 'PIK_PAYMENTS.download' | translate }}</ic-ghost-button
          >
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="confirmations && confirmations.length === 0">
      <div class="confirmation-list__no-confirmation-wrapper">
        <h2 class="confirmation-list__no-confirmation">
          {{ 'PIK_PAYMENTS.noConfirmation' | translate }}
        </h2>
      </div>
      <ic-ghost-button
        [type]="GhostButtonType.BIG_SECOND_RANK"
        [showArrow]="true"
        [arrowLeft]="true"
        [routerLink]="[buttonLink]"
        >{{ 'PIK_PAYMENTS.backBtn' | translate }}</ic-ghost-button
      >
    </ng-container>
  </section>
  <ng-container *ngIf="confirmations && confirmations.length > 0">
    <section class="confirmation-list__pagination confirmation-pagination" *ngIf="!showSingleExam">
      <nav class="confirmation-pagination__controls">
        <app-pagination-limit-chooser
          [items]="itemsPerPageAvailableOptions"
          [currentItem]="itemsPerPage"
          (selectionChanged)="onLimitChange($event)"></app-pagination-limit-chooser>
        <app-pagination
          [currentPage]="currentPage"
          [pageCount]="pageCount"
          (pageChanged)="onPageChange($event)"
          [mobileInfiniteScroll]="false"></app-pagination>
      </nav>
    </section>
  </ng-container>
</main>

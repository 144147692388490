import { BaseModalDirective } from 'src/app/shared/components/modal/base-modal';
import { Component } from '@angular/core';
import { GhostButtonType } from '../../ghost-button/ghost-button-type.enum';

@Component({
  selector: 'app-app-version-changed-modal',
  templateUrl: './app-version-changed-modal.component.html',
  styleUrl: './app-version-changed-modal.component.scss'
})
export class AppVersionChangedModalComponent extends BaseModalDirective {
  GhostButtonType = GhostButtonType;
  onCancelClick(): void {
    this.close(null);
  }

  onConfirmClick(): void {
    window.location.reload();
  }
}

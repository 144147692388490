<section
  [ngClass]="{
    'sign-method-modal': true,
    'sign-method-modal--choose': mode === Mode.CHOOSE_METHOD,
    'sign-method-modal--instruction': mode === Mode.INSTRUCTION,
    'sign-method-modal--wait': mode === Mode.CERTIFICATE_WAIT
  }">
  <div
    class="sign-method-modal--wrapper d-flex flex-wrap align-items-center"
    *ngIf="availabilityFetched && mode === Mode.CHOOSE_METHOD">
    <div class="loader" *ngIf="!availabilityFetched">
      <app-loaders type="dots"></app-loaders>
    </div>

    <div class="top d-flex justify-content-space-between">
      <span class="title align-self-end">
        {{ 'SIGN_METHOD_MODAL.TITLE' | translate }}
      </span>
      <img
        role="button"
        alt="{{ 'SIGN_METHOD_MODAL.CLOSE_BUTTON_ARIA' | translate }}"
        class="close-btn"
        tabindex="0"
        (keydown.enter)="onCancelClick()"
        (click)="onCancelClick()"
        src=" assets/images/close_mobile_.svg " />
    </div>

    <div class="options d-flex flex-wrap w-100" [formGroup]="form">
      <label
        [ngClass]="{
          tile: true,
          'col-md-4': true,
          disabled: !signMethodsAvailability.isEdoAvailable
        }"
        tabindex="0"
        appTriggerClick
        [appIndicateFor]="'sign-method-not-selected'"
        [animClass]="'indicate'"
        [firstFrameClass]="'indicate-ff'">
        <div
          class="tile__select-wrapper"
          [class.tile__select-wrapper--selected]="method === SignatureMethod.EDOHUB">
          <div class="tile__icon-box">
            <img class="tile__icon" src="assets/images/edo_app_icon.svg" alt="sign method icon" />
          </div>
          <div class="tile__input-box">
            <input
              class="tile__input"
              tabindex="-1"
              [attr.disabled]="!signMethodsAvailability.isEdoAvailable || null"
              type="radio"
              id="edohub-option-input"
              formControlName="signMethod"
              [value]="SignatureMethod.EDOHUB" />
            <div class="tile__checkmark"></div>
            <span
              class="tile__input-label"
              [innerHTML]="'SIGN_METHOD_MODAL.SIGN_OPTIONS.EDOHUB_OPTION' | translate">
            </span>
          </div>
        </div>
      </label>
      <label
        [ngClass]="{
          tile: true,
          'col-md-4': true,
          disabled: !signMethodsAvailability.isEpuapAvailable
        }"
        tabindex="0"
        appTriggerClick
        [appIndicateFor]="'sign-method-not-selected'"
        [animClass]="'indicate'"
        [firstFrameClass]="'indicate-ff'">
        <div
          class="tile__select-wrapper"
          [class.tile__select-wrapper--selected]="method === SignatureMethod.PROFILE">
          <div class="tile__icon-box">
            <img
              class="tile__icon"
              src="assets/images/profile_sign_icon@2x.png"
              alt="sign method icon" />
          </div>
          <div class="tile__input-box">
            <input
              class="tile__input"
              tabindex="-1"
              [attr.disabled]="!signMethodsAvailability.isEpuapAvailable || null"
              type="radio"
              id="profile-option-input"
              formControlName="signMethod"
              [value]="SignatureMethod.PROFILE" />
            <div class="tile__checkmark"></div>
            <span
              class="tile__input-label"
              [innerHTML]="'SIGN_METHOD_MODAL.SIGN_OPTIONS.PROFILE_OPTION' | translate">
            </span>
          </div>
        </div>
      </label>
      <label
        [ngClass]="{
          tile: true,
          'col-md-4': true
        }"
        tabindex="0"
        appTriggerClick
        id="certificate-tile"
        [appIndicateFor]="'sign-method-not-selected'"
        [animClass]="'indicate'"
        [firstFrameClass]="'indicate-ff'">
        <div
          class="tile__select-wrapper"
          [class.tile__select-wrapper--selected]="method === SignatureMethod.CERTIFICATE">
          <div class="tile__icon-box">
            <img class="tile__icon" src="assets/images/cert_sign_icon.svg" alt="sign method icon" />
          </div>
          <div class="tile__input-box">
            <input
              class="tile__input"
              tabindex="-1"
              type="radio"
              id="certificate-option-input"
              formControlName="signMethod"
              [value]="SignatureMethod.CERTIFICATE" />
            <div class="tile__checkmark"></div>
            <span
              class="tile__input-label"
              [innerHTML]="'SIGN_METHOD_MODAL.SIGN_OPTIONS.CERTIFICATE_OPTION' | translate">
            </span>
          </div>
        </div>
      </label>
    </div>
    <div class="info-box">
      <app-info-box
        [infoBoxText]="'SIGN_METHOD_MODAL.INFO_BOX' | translate"
        [bordered]="true"
        [maxWidthLimited]="false">
      </app-info-box>
    </div>
    <div class="ic-btn forwardBtn" role="button" (click)="onOkClick()">
      <ic-ghost-button
        [disabled]="disableSignButton || form.invalid"
        type="submit"
        [showArrow]="true"
        [width]="202"
        [inline]="true">
        <span class="forwardSpan">
          {{ 'SIGN_METHOD_MODAL.SIGN_BUTTON' | translate }}
        </span>
      </ic-ghost-button>
    </div>
  </div>

  <div
    *ngIf="mode === Mode.INSTRUCTION"
    class="sign-method-modal--wrapper d-flex flex-column align-items-start">
    <div class="instruction-title">
      {{ 'SIGN_METHOD_MODAL.CERTIFICATE_TITLE' | translate }}
    </div>
    <div class="instruction__subtitle">
      {{ 'SIGN_METHOD_MODAL.SUBTITLE_FOR_INSTRUCTION' | translate }}
    </div>
    <ng-container [ngSwitch]="instructionStep">
      <div class="row" *ngSwitchCase="0">
        <div class="instruction__step col-12 col-lg-6">
          <div class="step-nr__circle">
            <span class="step-nr__nr">1</span>
          </div>
          <div class="instruction__text">
            <h6 class="text__header">
              {{ 'SIGN_METHOD_MODAL.STEPS.STEP1.header' | translate }}
            </h6>
            <div class="text__content">
              <div>
                {{ 'SIGN_METHOD_MODAL.STEPS.STEP1.content' | translate
                }}<strong>{{
                  'SIGN_METHOD_MODAL.STEPS.STEP1.important_content' | translate
                }}</strong>
              </div>
            </div>
          </div>
        </div>
        <img
          class="instruction__image"
          src="assets/images/ck_1.png"
          alt="{{ 'SIGN_METHOD_MODAL.imageAria' | translate }}" />
      </div>
      <div class="row" *ngSwitchCase="1">
        <div class="instruction__step col-12 col-lg-6">
          <div class="step-nr__circle">
            <span class="step-nr__nr">2</span>
          </div>
          <div class="instruction__text">
            <h6 class="text__header">
              {{ 'SIGN_METHOD_MODAL.STEPS.STEP2.header' | translate }}
            </h6>
            <div class="text__content">
              <div>
                {{ 'SIGN_METHOD_MODAL.STEPS.STEP2.content' | translate
                }}<strong>{{
                  'SIGN_METHOD_MODAL.STEPS.STEP2.important_content' | translate
                }}</strong>
              </div>
            </div>
          </div>
        </div>
        <img
          class="instruction__image"
          src="assets/images/ck_2.png"
          alt="{{ 'SIGN_METHOD_MODAL.uploadImageAria' | translate }}" />
      </div>
      <div class="row" *ngSwitchCase="2">
        <div class="instruction__step col-12 col-lg-6">
          <div class="step-nr__circle">
            <span class="step-nr__nr">3</span>
          </div>
          <div class="instruction__text">
            <h6 class="text__header">
              {{ 'SIGN_METHOD_MODAL.STEPS.STEP3.header' | translate }}
            </h6>
            <div class="text__content">
              <div>
                {{ 'SIGN_METHOD_MODAL.STEPS.STEP3.content' | translate }}
              </div>
            </div>
          </div>
        </div>
        <img
          class="instruction__image"
          src="assets/images/ck_3.png"
          alt="{{ 'SIGN_METHOD_MODAL.penImageAria' | translate }}" />
      </div>
    </ng-container>
    <div class="instruction__counter d-flex justify-content-center w-100">
      <div *ngFor="let number of [0, 1, 2]">
        <div
          [ngClass]="{
            counter__dot: true,
            'counter__dot--active': number == instructionStep
          }"
          role="button"
          (click)="setInstructionStepOnDotClick(number)"></div>
      </div>
    </div>
    <div class="navigation-btns d-flex justify-content-center flex-wrap w-100">
      <div class="forward-btn">
        <ic-ghost-button
          (click)="instructionStep < 2 ? nextInstructionStep() : onQualifiedSignClick()"
          [type]="GhostButtonType.BIG_FIRST_RANK_FLEX"
          [width]="155"
          [showArrow]="true">
          <span class="nav-btns__text">{{ 'SIGN_METHOD_MODAL.NEXT_STEP_BUTTON' | translate }}</span>
        </ic-ghost-button>
      </div>
      <div class="sign-btn">
        <ic-ghost-button
          [type]="GhostButtonType.BIG_SECOND_RANK"
          [width]="331"
          (click)="onQualifiedSignClick()">
          <span class="nav-btns__text">{{
            'SIGN_METHOD_MODAL.CLOSE_INSTRUCTION_AND_SIGN_BUTTON' | translate
          }}</span>
        </ic-ghost-button>
      </div>
    </div>
  </div>

  <div
    class="wait-for-completion-cert sign-method-modal--wrapper d-flex flex-column"
    *ngIf="mode === Mode.CERTIFICATE_WAIT">
    <div class="instruction-title">
      {{ 'SIGN_METHOD_MODAL.CERTIFICATE_TITLE' | translate }}
    </div>
    <div class="wait-for-completion-cert__content">
      <div class="wait-for-completion-cert__middle">
        <app-loaders type="blue-dots"></app-loaders>
        <div class="wait__middle__info">
          {{ 'SIGN_METHOD_MODAL.CERTIFICATE_WAITING_INFO' | translate }}
        </div>
      </div>
      <ic-ghost-button
        [width]="115"
        (click)="onAbortClick()"
        [type]="GhostButtonType.BIG_SECOND_RANK_FLEX"
        [showArrow]="false"
        [justifyContent]="'center'"
        ><span>{{ 'SIGN_METHOD_MODAL.CANCEL_BUTTON' | translate }}</span></ic-ghost-button
      >
    </div>
  </div>

  <div
    class="wait-for-completion sign-method-modal--wrapper d-flex flex-wrap"
    *ngIf="mode === Mode.PROFILE_WAIT">
    <div
      class="wait-for-completion__content d-flex justify-content-center align-items-center flex-column">
      <div class="wait-for-completion__middle">
        <div class="wait__middle__info">
          {{ 'SIGN_METHOD_MODAL.PROFILE_WAITING_INFO' | translate }}
        </div>
        <app-loaders type="blue-dots"></app-loaders>
      </div>
      <ic-ghost-button
        *ngIf="showCancelButton"
        [width]="115"
        (click)="onAbortClick()"
        [type]="GhostButtonType.BIG_SECOND_RANK_FLEX"
        [showArrow]="false"
        [justifyContent]="'center'"
        ><span>{{ 'SIGN_METHOD_MODAL.CANCEL_BUTTON' | translate }}</span></ic-ghost-button
      >
    </div>
  </div>

  <div
    class="profile-error sign-method-modal--wrapper d-flex justify-content-center flex-wrap align-items-center"
    *ngIf="mode === Mode.PROFILE_ERROR">
    <div class="profile-error__content">
      <i class="material-icons content__icon"> error_outline </i>
      <div class="profile-error__content-desc">
        <span class="content__info">
          {{ 'SIGN_METHOD_MODAL.SIGN_WITH_PROFILE_ERROR' | translate }}
        </span>
      </div>
      <div class="content__buttons">
        <ic-ghost-button
          [type]="GhostButtonType.BIG_SECOND_RANK"
          [typeHTML]="'button'"
          [showArrow]="false"
          [justifyContent]="'center'"
          [width]="131"
          (click)="onAbortClick()">
          <span class="buttons__close-span">{{
            'SIGN_METHOD_MODAL.CLOSE_BUTTON' | translate
          }}</span>
        </ic-ghost-button>
        <ic-ghost-button
          type="submit"
          [justifyContent]="'center'"
          [showArrow]="false"
          [width]="202"
          (click)="getEpuapLink()">
          <span class="buttons__try-again-span">{{
            'SIGN_METHOD_MODAL.TRY_AGAIN_BUTTON' | translate
          }}</span>
        </ic-ghost-button>
      </div>
    </div>
  </div>

  <div
    class="profile-error sign-method-modal--wrapper d-flex flex-wrap justify-content-center align-items-center"
    *ngIf="mode === Mode.PROFILE_NO_RESPONSE">
    <div class="profile-error__content">
      <i class="material-icons content__icon"> error_outline </i>
      <div class="profile-error__content-desc">
        <span class="content__info"> {{ 'SIGN_METHOD_MODAL.NO_RESPONSE_ERROR' | translate }} </span
        ><span class="content__info-standard">{{
          'SIGN_METHOD_MODAL.NO_RESPONSE_DESCRIPTION' | translate
        }}</span>
      </div>
      <div class="content__buttons">
        <ic-ghost-button
          [type]="GhostButtonType.BIG_SECOND_RANK"
          [typeHTML]="'button'"
          [showArrow]="false"
          [justifyContent]="'center'"
          [width]="131"
          (click)="onAbortClick()">
          <span class="buttons__close-span">{{
            'SIGN_METHOD_MODAL.CLOSE_BUTTON' | translate
          }}</span>
        </ic-ghost-button>
        <ic-ghost-button
          type="submit"
          [justifyContent]="'center'"
          [showArrow]="false"
          [width]="202"
          (click)="getEpuapLink()">
          <span class="buttons__try-again-span">{{
            'SIGN_METHOD_MODAL.TRY_AGAIN_BUTTON' | translate
          }}</span>
        </ic-ghost-button>
      </div>
    </div>
  </div>
</section>

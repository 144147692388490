<section class="word-reservation-timed-out-modal">
  <header class="header">
    <h1 class="header-title">
      {{ 'WORD_REGISTRATION.PAYMENT_STEP.PAYMENT_TIMED_OUT_MODAL.TITLE' | translate }}
    </h1>
    <button
      [attr.aria-label]="
        'WORD_REGISTRATION.PAYMENT_STEP.PAYMENT_TIMED_OUT_MODAL.BTN_CLOSE_ARIA' | translate
      "
      (click)="onCancelClick()"
      class="close-btn">
      <svg-icon class="close-btn-icon" src="assets/images/close_mobile_.svg"></svg-icon>
    </button>
  </header>
  <main class="main">
    <p
      class="content"
      [innerHtml]="
        'WORD_REGISTRATION.PAYMENT_STEP.PAYMENT_TIMED_OUT_MODAL.DESCRIPTION' | translate
      "></p>
    <div class="nav-btns">
      <div class="word-exam-button-wrapper">
        <ic-ghost-button
          [typeHTML]="'button'"
          [type]="GhostButtonType.BIG_FIRST_RANK"
          [width]="155"
          (click)="onOkClick()"
          >{{
            'WORD_REGISTRATION.PAYMENT_STEP.PAYMENT_TIMED_OUT_MODAL.REGISTER_AGAIN' | translate
          }}</ic-ghost-button
        >
      </div>
      <div class="word-exam-button-wrapper">
        <ic-ghost-button
          [typeHTML]="'button'"
          [type]="GhostButtonType.BIG_SECOND_RANK"
          [width]="155"
          (click)="onCancelClick()"
          >{{
            'WORD_REGISTRATION.PAYMENT_STEP.PAYMENT_TIMED_OUT_MODAL.CANCEL' | translate
          }}</ic-ghost-button
        >
      </div>
    </div>
  </main>
</section>

import { AttachmentDto } from 'src/app/shared/models/attachments/attachment-dto.model';
import { ErrorDto } from 'src/app/shared/models/errors/error-dto.model';
import { UploadStatus } from './upload-status';

export interface AttachmentEntity {
  readonly id: number;
  dto: AttachmentDto;
  status: UploadStatus.Status;
  errorMessages?: ErrorDto[];
}

export type ApplicationAttachmentType = 'APPLICATION' | 'CORRECTION';

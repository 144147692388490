import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { TranslationModalComponent } from 'src/app/shared/components/modal/translation-modal/translation-modal.component';
import { TRANSLATED_PAGES } from '../_const/layout.const';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  lastRoute: string;

  constructor(private router: Router, private modalService: ModalService) {}

  checkIfStartWithRoute() {
    const splitedRoute = this.router.url.split('/');
    const firstPart = splitedRoute[1] ? '/' + splitedRoute[1] : '';
    const secondPart = splitedRoute[2] ? '/' + splitedRoute[2] : '';
    return TRANSLATED_PAGES.some((el) => el.startsWith(firstPart + secondPart));
  }
  detectTranslatedPage(lng: string) {
    if (lng !== 'pl') {
      if (this.checkIfStartWithRoute()) {
        this.lastRoute = this.router.url;
        return;
      } else {
        this.modalService.open(TranslationModalComponent, {
          data: { lastRoute: this.lastRoute, freezeOverlay: true }
        });
      }
    }
  }
}

import { WordExamCategory } from './../../../models/word-exam-reservation/word-exam.model';
import { Component, OnInit } from '@angular/core';
import { BaseModalDirective } from 'src/app/shared/components/modal/base-modal';
import { WordExam } from '../../word-exam-calendar/_models/word-exam-schedule-list.model';

@Component({
  selector: 'app-accept-date-modal',
  templateUrl: './accept-date-modal.component.html',
  styleUrls: ['./accept-date-modal.component.scss']
})
export class AcceptDateModalComponent extends BaseModalDirective implements OnInit {
  buttonOk = {
    width: 155,
    height: 50
  };

  theory: WordExam;
  practice: WordExam;
  category: string;
  isReschedule: boolean = false;
  organizationName: string;
  organizationAddress: string;
  type: 'PKK' | 'PKZ' = 'PKK';

  constructor() {
    super();
  }

  ngOnInit() {
    const data = this.getData();
    this.theory = data.theory;
    this.practice = data.practice;
    this.category = data.category;
    this.isReschedule = data.isReschedule;
    this.organizationAddress = data.organizationAddress,
    this.organizationName = data.organizationName
    this.type = data.type;
  }

  onOkClick() {
    this.close(true);
  }

  onCancelClick() {
    this.close();
  }

  translateCategory(): string {
    switch(this.category) {
      case WordExamCategory.BLOK_C:
        return 'BLOK C';
      case WordExamCategory.BLOK_D:
        return 'BLOK D';
      default: 
        return this.category;
    }
  }
}

import { Router } from '@angular/router';
import {
  UnavailableServiceForBusinessModalContent,
  WizardStepBackCtabutton
} from './_model/unavailable-service-for-business-modal.model';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { BaseModalDirective } from '../base-modal';
import { GhostButtonType } from '../../ghost-button/ghost-button-type.enum';
import { MY_ACCOUNT_ROUTE } from 'src/app/my-account/_consts/my-account-route.const';
import {
  REGISTRATION_ROUTES,
  REGISTRATION_ROUTE
} from 'src/app/registration/_consts/registration-routes.const';

export enum UnavailableServiceForBusinessReason {
  WRONG_CARD_TYPE = 'wrong_card_type',
  WRONG_CHANNEL = 'wrong_channel',
  WORD_EXAM = 'word_exam',
  DL_PAYMENT = 'dl_payment'
}

@Component({
  selector: 'app-unavailable-service-for-business-modal',
  templateUrl: './unavailable-service-for-business-modal.component.html',
  styleUrls: ['./unavailable-service-for-business-modal.component.scss']
})
export class UnavailableServiceForBusinessModalComponent
  extends BaseModalDirective
  implements OnInit {
  GhostButtonType: typeof GhostButtonType = GhostButtonType;
  content: UnavailableServiceForBusinessModalContent;
  infoTextTranslateKey: string;
  ctaTextTranslateKey: WizardStepBackCtabutton;
  ctaLink: string;
  reason: UnavailableServiceForBusinessReason;

  constructor(private translate: TranslateService, private router: Router) {
    super();
  }

  ngOnInit() {
    this.translate
      .get('UNAVAILABLE_SERVICE_FOR_BUSINESS_MODAL')
      .subscribe((result: UnavailableServiceForBusinessModalContent) => {
        this.content = result;
      });

    if (this.getData() && this.getData().reason) {
      this.reason = this.getData().reason;
      switch (this.getData().reason) {
        case UnavailableServiceForBusinessReason.WRONG_CARD_TYPE:
          this.infoTextTranslateKey = this.content.WRONG_CARD_TYPE_INFO_TEXT;
          this.ctaTextTranslateKey = this.content.CTA_TEXT.STANDARD;
          this.ctaLink = MY_ACCOUNT_ROUTE;
          break;
        case UnavailableServiceForBusinessReason.WRONG_CHANNEL:
          this.infoTextTranslateKey = this.content.FORBIDDEN_CHANNEL_FOR_BUSSINESS_INFO_TEXT;
          this.ctaTextTranslateKey = this.content.CTA_TEXT.STANDARD;
          this.ctaLink = MY_ACCOUNT_ROUTE;
          break;
        case UnavailableServiceForBusinessReason.WORD_EXAM:
          this.infoTextTranslateKey = this.content.WORD_FORBIDDEN_CHANNEL_FOR_BUSSINESS_INFO_TEXT;
          this.ctaTextTranslateKey = this.content.CTA_TEXT.WORD;
          this.ctaLink = `${REGISTRATION_ROUTE}/${REGISTRATION_ROUTES.FORM}`;
          break;
        case UnavailableServiceForBusinessReason.DL_PAYMENT:
          this.infoTextTranslateKey = this.content.WORD_FORBIDDEN_CHANNEL_FOR_BUSSINESS_INFO_TEXT;
          this.ctaTextTranslateKey = this.content.CTA_TEXT.DL_PAYMENT;
          this.ctaLink = `${REGISTRATION_ROUTE}/${REGISTRATION_ROUTES.FORM}`;
          break;
        default:
          this.infoTextTranslateKey = this.content.WRONG_CARD_TYPE_INFO_TEXT;
          this.ctaTextTranslateKey = this.content.CTA_TEXT.STANDARD;
          this.ctaLink = MY_ACCOUNT_ROUTE;
      }
    }
  }

  onCtaClick() {
    this.close();
  }

  onCloseModalClick() {
    this.close();
    this.reason === UnavailableServiceForBusinessReason.WORD_EXAM
      ? this.router.navigateByUrl('/')
      : null;
  }
}

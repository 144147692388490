import { Router } from '@angular/router';
import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appInternalLink]'
})
export class InternalLinkDirective {
  constructor(private router: Router) {}

  @HostListener('click', ['$event'])
  onSectionClick(event) {
    if (event.target.tagName.toLowerCase() === 'a') {
      if (event.target.getAttribute('link')) {
        event.preventDefault();
        const url = event.target.getAttribute('link');
        this.router.navigateByUrl(url);
      }
    }
  }
}

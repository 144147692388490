import { RouterModule } from '@angular/router';
import { SharedModule } from './../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoBarComponent } from './info-bar/info-bar.component';
import { AngularSvgIconModule } from 'angular-svg-icon';

@NgModule({
  declarations: [InfoBarComponent],
  imports: [CommonModule, SharedModule, RouterModule, AngularSvgIconModule],
  exports: [InfoBarComponent]
})
export class InfoBarModule {}

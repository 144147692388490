import { TranslateService } from '@ngx-translate/core';
import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { News } from 'src/app/news/_models/news';
import { NewsService } from 'src/app/news/news.service';
import { ActivatedRoute } from '@angular/router';
import { MainPage } from '../_model/main-page.model';
import { Subscription } from 'rxjs';

// MOCKED DATA
import { newsMock } from 'src/app/news/_mock/news';

declare const $: any;

@Component({
  selector: 'app-news-page-section',
  templateUrl: './news-page-section.component.html',
  styleUrls: ['./news-page-section.component.scss']
})
export class NewsPageSectionComponent implements OnInit, OnDestroy {
  @ViewChild('slidersContainerData') slidersContainerData: ElementRef;
  slidesPerPage = 3;
  mobileSlidesPerPage = 1;
  slidersContent: News[] = [];
  content: any;
  private slideWidth: number;
  private slidePage = 0;
  private maxPages: number;
  private subscription = new Subscription();
  isMobile: boolean;
  offset: number;
  constructor(
    private newsService: NewsService,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) {}
  recentlyReadNews = [];
  ngOnInit() {
    this.getNews();
    this.newsService.getRecentlyReadNews().subscribe((news: any[]) => {
      this.recentlyReadNews = news;
    });
    this.translate.get('MAIN_PAGE.NEWS_SECTION').subscribe({
      next: (content) => {
        this.content = content;
      }
    });

    this.checkIsMobile();
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getNews() {
    this.subscription = this.route.data.subscribe((data: { mainPage: MainPage }) => {
      this.slidersContent.push(...data.mainPage.news.promoNews, ...newsMock);
      this.slidersContent = this.slidersContent.sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
      );
      this.formatNewsDate();
      this.slidePage = 0;
    });
  }

  slide(): void {
    this.slideWidth = this.slidersContainerData?.nativeElement.clientWidth || 0;
    this.offset = this.slidePage * this.slideWidth * -1 || 0;
  }

  newsDateFormater(date: string) {
    const d = new Date(date.replace(/-/g, '/'));
    return `${d.toLocaleString('pl-PL', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    })}`;
  }

  formatNewsDate() {
    this.slidersContent = this.slidersContent.map((slide) => {
      return {
        ...slide,
        date: this.newsDateFormater(slide.date)
      };
    });
  }

  prevSlides() {
    if (this.slidePage > 0) {
      this.slidePage -= 1;
    }

    this.slide();
  }

  nextSlides() {
    if (this.slidePage < this.maxPages) {
      this.slidePage += 1;
    }
    this.slide();
  }

  checkMaxPages() {
    this.maxPages = this.isMobile
      ? Math.ceil(this.slidersContent.length / this.mobileSlidesPerPage) - 1
      : Math.ceil(this.slidersContent.length / this.slidesPerPage) - 1;
  }

  @HostListener('window:resize', ['$event'])
  checkIsMobile() {
    this.isMobile = window.outerWidth <= 767;

    this.slidePage = 0;
    this.slide();

    this.checkMaxPages();
  }
}

<div class="footer-top" *ngIf="content">
  <div class="container">
    <div class="row justify-content-between">
      <div class="info-car-icon col">
        <a routerLink="/">
          <img
            class="icon"
            [alt]="'FOOTER.top.infocar.logoAlt' | translate"
            src="assets/images/logo_ic_stopka_.svg"
            width="88px"
            height="39px" />
        </a>
      </div>
      <div class="social-media col">
        <a
          title="{{ 'FOOTER.top.facebook.text' | translate }}. {{
            'LINK_TITLE.OPEN_IN_NEW_TAB' | translate
          }}"
          target="_blank"
          [href]="content.facebook.href">
          <img
            class="icon"
            src="assets/images/logo/facebook.svg"
            width="26px"
            height="26px"
            [alt]="'FOOTER.top.facebook.text' | translate" />
        </a>
        <a
          title="{{ 'FOOTER.top.twitter.text' | translate }}. {{
            'LINK_TITLE.OPEN_IN_NEW_TAB' | translate
          }}"
          target="_blank"
          [href]="content.twitter.href">
          <img
            class="icon"
            src="assets/images/logo/twitter.svg"
            width="26px"
            height="26px"
            [alt]="'FOOTER.top.twitter.text' | translate" />
        </a>
      </div>
    </div>
  </div>
</div>

import { CompanyDataFormValidation } from './company-data.model';
import { ValidationFunctions } from '../../../validation/validation-functions.const';
import { Validators } from '@angular/forms';
import { ValidationPatterns } from 'src/app/shared/validation/validation-patterns.const';

export const defaultCompanyDataFormValidation: CompanyDataFormValidation = {
  formGroupName: 'companyData',
  formFields: {
    name: Validators.compose([
      Validators.maxLength(200),
      Validators.required,
    ]),
    nip: Validators.compose([
      Validators.minLength(10),
      Validators.maxLength(10),
      Validators.pattern(ValidationPatterns.requiredDigit),
      Validators.required,
      ValidationFunctions.nipControlSum()
    ]),
    krs: Validators.compose([
      Validators.maxLength(10),
      Validators.minLength(10),
    ]),
    authorizationNumber: Validators.compose([
      Validators.maxLength(20),
      Validators.required,
    ]),
    shortName: Validators.compose([
      Validators.maxLength(35),
      Validators.required,
    ]),
    phoneNumber: Validators.compose([
      Validators.pattern(ValidationPatterns.phoneNumber),
      ValidationFunctions.sanitizedPhoneNumberLength(7, 16),
      Validators.required
    ]),
    email: Validators.compose([
      Validators.pattern(ValidationPatterns.email),
      ValidationFunctions.maxLengthValidator(80),
      Validators.required
    ])
  }
};

import {Injectable, OnDestroy} from '@angular/core';
import {Location} from '@angular/common';
import {Extensions} from 'src/app/shared/extensions/extensions';
import {ActivatedRoute, Router} from '@angular/router';
import {StcCanDeactivateRouteService} from 'src/app/applications/stc/_services/stc-can-deactivate-route.service';
import {SubscriptionLike} from 'rxjs';
import {RouteStateService} from '../../services/route-state/route-state.service';
import {ApplicationType} from './application-type.model';
import {
  DRIVER_CARD_APPLICATION_PERSONAL_DATA_ROUTE
} from 'src/app/applications/stc/driver-card-application/personal-data/_consts/personal-data.route.const';
import {
  DRIVER_CARD_APPLICATION_ATTACHMENTS_ROUTE
} from 'src/app/applications/stc/driver-card-application/attachments/_consts/attachments.route.const';
import {
  DRIVER_CARD_APPLICATION_SUMMARY_ROUTE
} from 'src/app/applications/stc/driver-card-application/summary/_consts/summary.route.const';
import {
  DRIVER_CARD_APPLICATION_PAYMENT_ROUTE
} from 'src/app/applications/stc/driver-card-application/payment/_consts/payment-route.const';
import {
  EXAMINATION_CENTER_STEP_ROUTE
} from 'src/app/applications/word-exam-registration/registration-process/examination-center-step/_const/examination-center-step.route.const';
import {
  PERSONAL_DATA_STEP_ROUTE
} from 'src/app/applications/word-exam-registration/registration-process/personal-data-step/_const/personal-data-step-route.const';
import {
  CALENDAR_STEP_ROUTE
} from 'src/app/applications/word-exam-registration/registration-process/calendar-step/_const/calendar-step-route.const';
import {
  DETAILS_STEP_ROUTE
} from 'src/app/applications/word-exam-registration/registration-process/details-step/_const/details-step-route.const';
import {
  SUMMARY_STEP_ROUTE
} from 'src/app/applications/word-exam-registration/registration-process/summary-step/_const/summary-step-route.const';
import {
  PAYMENT_STEP_ROUTE
} from 'src/app/applications/word-exam-registration/registration-process/payment-step/_const/personal-data-step-route.const';
import {
  WORD_EXAM_PROCESS_ROUTE,
  WORD_EXAM_ROUTE
} from 'src/app/applications/word-exam-registration/_consts/word-exam-route.const';

const WORD_EXAM_ROUTES = [
  `${WORD_EXAM_ROUTE}/${WORD_EXAM_PROCESS_ROUTE}/${PERSONAL_DATA_STEP_ROUTE}`,
  `${WORD_EXAM_ROUTE}/${WORD_EXAM_PROCESS_ROUTE}/${EXAMINATION_CENTER_STEP_ROUTE}`,
  `${WORD_EXAM_ROUTE}/${WORD_EXAM_PROCESS_ROUTE}/${CALENDAR_STEP_ROUTE}`,
  `${WORD_EXAM_ROUTE}/${WORD_EXAM_PROCESS_ROUTE}/${DETAILS_STEP_ROUTE}`,
  `${WORD_EXAM_ROUTE}/${WORD_EXAM_PROCESS_ROUTE}/${SUMMARY_STEP_ROUTE}`,
  `${WORD_EXAM_ROUTE}/${WORD_EXAM_PROCESS_ROUTE}/${PAYMENT_STEP_ROUTE}`
];

const STC_APPLICATION_ROUTES = [
  DRIVER_CARD_APPLICATION_PERSONAL_DATA_ROUTE,
  DRIVER_CARD_APPLICATION_ATTACHMENTS_ROUTE,
  DRIVER_CARD_APPLICATION_SUMMARY_ROUTE,
  DRIVER_CARD_APPLICATION_PAYMENT_ROUTE
];

// TODO: Add Angular decorator.
@Injectable()
export abstract class WizardStep implements OnDestroy {
  abstract PREVIOUS_STEP_ROUTE: string;
  abstract NEXT_STEP_ROUTE: string;
  properLeaving = false;

  isProperLeaving(): boolean {
    return this.properLeaving;
  }

  protected windowBackEventSubscription$: SubscriptionLike;

  constructor(
    protected router: Router,
    protected routeService?: RouteStateService,
    protected route?: ActivatedRoute
  ) {
    if (route && routeService) {
      routeService.updatePathParamState(route.snapshot.paramMap.get('id'));
    }
  }

  public nextStep(...args: string[]): void {
    this.router.navigateByUrl(Extensions.replacePlaceholders(this.NEXT_STEP_ROUTE, args));
  }

  public previousStep(...args: string[]): void {
    this.router.navigateByUrl(Extensions.replacePlaceholders(this.PREVIOUS_STEP_ROUTE, args));
  }

  public nextStepWithQueryParams(params: any, ...args: string[]): void {
    this.router.navigate([Extensions.replacePlaceholders(this.NEXT_STEP_ROUTE, args)], {
      queryParams: params
    });
  }

  public previousStepWithQueryParams(params: any, ...args: string[]): void {
    this.router.navigate([Extensions.replacePlaceholders(this.PREVIOUS_STEP_ROUTE, args)], {
      queryParams: params
    });
  }

  protected setupBrowserBackReplacement(
    locationStrategy: Location,
    currentUrl: string,
    applicationType: ApplicationType,
    deactivateService?: StcCanDeactivateRouteService
  ): void {
    const appTypeRoutes =
      applicationType === ApplicationType.WORD_EXAM ? WORD_EXAM_ROUTES : STC_APPLICATION_ROUTES;

    this.windowBackEventSubscription$ = locationStrategy.subscribe((location) => {
      const currentUrlIndex = appTypeRoutes.findIndex((value) => currentUrl.includes(value));
      const nextUrlIndex = appTypeRoutes.findIndex((value) => location.url.includes(value));

      switch (applicationType) {
        case ApplicationType.WORD_EXAM: {
          if (nextUrlIndex > currentUrlIndex) {
            history.back();
          } else if (nextUrlIndex < currentUrlIndex) {
            this.onBackClicked();
          }
          break;
        }
        case ApplicationType.STC_APPLICATION: {
          this.properLeaving = true;
          deactivateService ? deactivateService.blockDeactivationOnce() : null;
          this.onBackClicked();
          break;
        }
      }
    });
  }

  abstract onBackClicked(): void;

  ngOnDestroy(): void {
    if (this.windowBackEventSubscription$) {
      this.windowBackEventSubscription$.unsubscribe();
    }
    if (this.routeService) {
      this.routeService.updatePathParamState(null);
    }
  }
}

import { ApplicationDto } from './../applications/application-dto.model';
/**
 * INFOCAR STC API
 * API for processing STC applications
 *
 * OpenAPI spec version: v1
 * Contact: java@pulab.pl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PersonDto } from '../employee/personDto';
import { FormOfRefundDto } from './../applications/form-of-refund-dto.model';
import { ShippingContactDetailsDto } from '../employee/shipping-contact-details-dto.model';
import { ContactDetailsDto } from './../employee/contactDetailsDto';
import { WorkshopDto } from './wokrshop-dto.model';
import { StatusDto } from '../applications/status-dto.model';

/**
 * DTO describing workshop card application fields
 */
export interface WorkshopCardApplicationDto extends ApplicationDto {
  /**
   * Card shipping contact details
   */
  cardShippingContactDetails: ShippingContactDetailsDto;
  /**
   * Number of technician certificate
   */
  certificateNumber: string;
  /**
   * Contact details
   */
  contactDetails: ContactDetailsDto;
  /**
   * Checked when invoice will be electronic
   */
  electronicInvoice?: boolean;
  /**
   * Form of refund money
   */
  formOfRefund: FormOfRefundDto;
  /**
   * Application id
   */
  id?: string;
  paper?: boolean;
  /**
   * Person details
   */
  person: PersonDto;
  /**
   * PIN shipping contact details
   */
  pinShippingContactDetails: ShippingContactDetailsDto;
  /**
   * Reason of application
   */
  reasonType: WorkshopCardApplicationDto.ReasonTypeEnum;
  /**
   * Application serial number
   */
  serialNumber?: number;
  /**
   * Statement confirmation
   */
  statement?: boolean;
  /**
   * Status of application
   */
  status?: StatusDto;
  /**
   * Serial number of technician card
   */
  technicianCardNumber?: string;
  /**
   * Type of application
   */
  type: ApplicationDto.TypeEnum;
  /**
   * Workshop details
   */
  workshop: WorkshopDto;
}
export namespace WorkshopCardApplicationDto {
  export type ReasonTypeEnum =
    | 'FIRST_ISSUE'
    | 'CLOSE_EXPIRY_DATE'
    | 'CARD_LOSS'
    | 'CARD_THEFT'
    | 'DAMAGED_OR_DEFECTIVE_CARD'
    | 'INCORRECTLY_ENTERED_OR_LOST_PIN'
    | 'RESIDENCE_COUNTRY_CHANGE'
    | 'ADMINISTRATIVE_DATA_CHANGE'
    | 'PERSONAL_OR_ADMINISTRATIVE_DATA_CHANGE'
    | 'TIME_AND_NON_RENEWABLE_CARD'
    | 'RENEWAL_FOREIGN_CARD';
  export const ReasonTypeEnum = {
    FIRSTISSUE: 'FIRST_ISSUE' as ReasonTypeEnum,
    CLOSEEXPIRYDATE: 'CLOSE_EXPIRY_DATE' as ReasonTypeEnum,
    CARDLOSS: 'CARD_LOSS' as ReasonTypeEnum,
    CARDTHEFT: 'CARD_THEFT' as ReasonTypeEnum,
    DAMAGEDORDEFECTIVECARD: 'DAMAGED_OR_DEFECTIVE_CARD' as ReasonTypeEnum,
    INCORRECTLYENTEREDORLOSTPIN: 'INCORRECTLY_ENTERED_OR_LOST_PIN' as ReasonTypeEnum,
    RESIDENCECOUNTRYCHANGE: 'RESIDENCE_COUNTRY_CHANGE' as ReasonTypeEnum,
    ADMINISTRATIVEDATACHANGE: 'ADMINISTRATIVE_DATA_CHANGE' as ReasonTypeEnum,
    PERSONALORADMINISTRATIVEDATACHANGE: 'PERSONAL_OR_ADMINISTRATIVE_DATA_CHANGE' as ReasonTypeEnum,
    TIMEANDNONRENEWABLECARD: 'TIME_AND_NON_RENEWABLE_CARD' as ReasonTypeEnum,
    RENEWALFOREIGNCARD: 'RENEWAL_FOREIGN_CARD' as ReasonTypeEnum
  };
}

import { TACHOGRAPH_ROUTE } from 'src/app/service-category/_consts/service-category-route.const';

export const STC_APPLICATIONS_ROUTE = 'wnioski';
export const STC_COMPLAINTS_ROUTE = 'reklamacje';
export const STC_CORRECTIONS_ROUTE = 'korekty';

export const STC_TACHOGRAPH_APPLICATIONS_ROUTE = TACHOGRAPH_ROUTE + '/' + STC_APPLICATIONS_ROUTE;
export const STC_TACHOGRAPH_COMPLAINTS_ROUTE = TACHOGRAPH_ROUTE + '/' + STC_COMPLAINTS_ROUTE;
export const STC_TACHOGRAPH_CORRECTIONS_ROUTE = TACHOGRAPH_ROUTE + '/' + STC_CORRECTIONS_ROUTE;
export const STC_MAIL_PAYMENT = 'zaplac-za-wniosek/:id/:ownerId'

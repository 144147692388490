export const EXTERNAL_DESTINATION_DICTIONARY = new Map<string, string>([
  [
    'ue_ordinance_165_2014',
    'https://eur-lex.europa.eu/legal-content/PL/TXT/PDF/?uri=CELEX:32014R0165&from=PL'
  ],
  [
    'ue_ordinance_799_2016',
    'https://eur-lex.europa.eu/legal-content/PL/TXT/PDF/?uri=CELEX:02016R0799-20160526&from=PL'
  ],
  [
    'tacho_law_5_2018',
    'https://isap.sejm.gov.pl/isap.nsf/download.xsp/WDU20180001480/T/D20181480L.pdf'
  ],
  [
    'pl_ordinance_17_2023',
    'https://isap.sejm.gov.pl/isap.nsf/download.xsp/WDU20230000986/O/D20230986.pdf'
  ],
  ['pl_ordinance_973_2012', 'https://isap.sejm.gov.pl/isap.nsf/DocDetails.xsp?id=WDU20120000973'],
  ['pl_ordinance_602_1997', 'https://isap.sejm.gov.pl/isap.nsf/DocDetails.xsp?id=WDU19970980602'],
  ['pl_ordinance_151_2011', 'https://isap.sejm.gov.pl/isap.nsf/DocDetails.xsp?id=WDU20110300151'],
  ['transport_ordinance_0609_2001', 'https://isap.sejm.gov.pl/isap.nsf/DocDetails.xsp?id=wdu20011251371'],
  ['road_transport_09_2001', 'https://isap.sejm.gov.pl/isap.nsf/DocDetails.xsp?id=WDU20011251371'],
  ['hazardous_materials', 'https://isap.sejm.gov.pl/isap.nsf/DocDetails.xsp?id=WDU20112271367'],
  [
    'ustawa_prawo_o_ruchu_drogowym',
    'https://isap.sejm.gov.pl/isap.nsf/DocDetails.xsp?id=WDU19970980602'
  ],
  [
    'ustawa_o_kierujacych_pojazdami',
    'https://isap.sejm.gov.pl/isap.nsf/DocDetails.xsp?id=WDU20110300151'
  ]
]);

import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-password-requirement-hint',
  templateUrl: './password-requirement-hint.component.html',
  styleUrls: ['./password-requirement-hint.component.scss']
})
export class PasswordRequirementHintComponent implements OnInit {
  content: string;
  constructor(private translateService: TranslateService) {}

  ngOnInit() {
    this.translateService
      .get('REGISTRATION.INDIVIDUAL.FORM.PASSWORD_REQUIRMENT_INFO')
      .subscribe((content) => {
        this.content = content;
      });
  }
}

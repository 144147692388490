<article class="error">
  <aside *ngIf="errorStatus !== 0" class="error-image">
    <img class="error-image__img" src="assets/images/pacholek.png" alt="pachołek" />
  </aside>
  <section class="error-data">
    <h1 class="error-data__title">
      {{ errorTitle }}
    </h1>
    <span class="error-data__status">
      {{ 'ERROR.ERROR_STATUS' | translate }}{{ errorStatus }}
    </span>
    <ic-ghost-button
      *ngIf="errorStatus < 500"
      class="error-data__button"
      [type]="GhostButtonType.BIG_FIRST_RANK"
      [typeHTML]="'button'"
      [showArrow]="true"
      [routerLink]="['/']"
      [width]="isMobile ? 160 : undefined"
      >{{ 'ERROR.HOME' | translate }}</ic-ghost-button
    >
  </section>
</article>

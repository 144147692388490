<section class="agreements-modal">
    <ng-container>
      <header class="header">
        <div class="header-wrapper">
            <h1 class="header-title">{{'SHARED.AGREEMNETS_MODAL.title' | translate}}</h1>
            <p>{{'SHARED.AGREEMNETS_MODAL.description' | translate}}</p>
        </div>
        <button [attr.aria-label]="'SHARED.AGREEMNETS_MODAL.closeAria' | translate" (click)="onCancelClick()" class="close-btn">
          <svg-icon class="close-btn-icon" src="assets/images/close_mobile_.svg"></svg-icon>
        </button>
      </header>
      <main class="main">
        
        <form [formGroup]="formGroup">
            <app-form-checkbox
            [formGroup]="formGroup"
            [formControlName]="'regulations'"
            [inputId]="'regulations'"
            [label]="'SHARED.AGREEMNETS_MODAL.regulationsLabel' | translate"
            [isRequired]="true"
            [errorList]="[
            {
                label: 'SHARED.AGREEMNETS_MODAL.required' | translate,
                type: ['required']
            }
            ]">
        </app-form-checkbox>

        <app-form-checkbox
            [formGroup]="formGroup"
            [formControlName]="'infoRead'"
            [inputId]="'infoRead'"
            [label]="'SHARED.AGREEMNETS_MODAL.infoRead' | translate"
            [isRequired]="true"
            [errorList]="[
            {
                label: 'SHARED.AGREEMNETS_MODAL.required' | translate,
                type: ['required']
            }
            ]">
        </app-form-checkbox>
        </form>

        <div class="nav-btns">
          <ic-ghost-button
          [typeHTML]="'button'"
          appPreventDoubleClick
          (throttledClick)="onConfirmClick()"
          [type]="GhostButtonType.BIG_FIRST_RANK_FLEX"
          [showArrow]="false"
          [justifyContent]="'center'"
          [disabled]="formGroup.invalid"
          [width]="140">
          <span
            class="forwardSpan start-btn__label"
            [innerHtml]="'SHARED.AGREEMNETS_MODAL.confirmButton' | translate"></span>
          </ic-ghost-button>

          <ic-ghost-button
          [typeHTML]="'button'"
          (click)="onCancelClick()"
          [type]="GhostButtonType.BIG_SECOND_RANK_FLEX"
          [showArrow]="false"
          [justifyContent]="'center'"
          [width]="140">
          <span class="forwardSpan start-btn__label" [innerHtml]="'SHARED.AGREEMNETS_MODAL.cancelButton' | translate"></span>
          </ic-ghost-button>
        </div>
      </main>
    </ng-container>
  </section>
  
import { GhostButtonType } from './../../../ghost-button/ghost-button-type.enum';
import { MODAL_OVERLAY_CLICKED } from './../../../modal/modal-ref';
import { ImageButtonType } from './../../../image-edit-button/image-edit-button.enum';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  OnDestroy,
  Output,
  EventEmitter
} from '@angular/core';
import { AttachmentMetadata, AttachmentEntity } from '../model';
import { CropperComponent } from 'angular-cropperjs';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SearchModalComponent } from '../../../modal/search-modal/search-modal.component';
import { ModalService } from '../../../modal/modal.service';
import { CroppedImageModalComponent } from '../../../modal/cropped-image-modal/cropped-image-modal.component';
import { Observable, Subscription } from 'rxjs';
import { truncate } from 'fs';
import { CroppedImageInfoModalComponent } from '../../../modal/cropped-image-info-modal/cropped-image-info-modal.component';
import { AttachmentType } from 'src/app/shared/models/attachments/attachment-dto.model';
import { Extensions } from 'src/app/shared/extensions/extensions';

@Component({
  selector: 'app-attachments-input',
  templateUrl: './attachments-input.component.html',
  styleUrls: ['./attachments-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: AttachmentsInputComponent,
      multi: true
    }
  ]
})
export class AttachmentsInputComponent implements OnInit, ControlValueAccessor, OnDestroy {
  ImageButtonType: typeof ImageButtonType = ImageButtonType;

  @ViewChild('inputCropperElement') inputCropperElement: ElementRef;

  cropperImage;

  @ViewChild('inputElement') inputElement: ElementRef;

  accept: string;

  multiple: boolean;

  @Input() config: AttachmentMetadata;
  @Input() fileDropped: Observable<Array<File>>;
  @Output() imageChanged = new EventEmitter<void>();

  AttachmentType = AttachmentType;
  GhostButtonType = GhostButtonType;
  sub: Subscription;

  constructor(
    private _DomSanitizationService: DomSanitizer,
    private router: Router,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.accept = this.config.format.reduce((acc, next) => acc + ',' + next);
    this.multiple = this.config.maxNumber > 1;
    this.onImageDropped();
  }

  onImageChange(event) {
    this.imageChanged.emit();
    let files = this.inputCropperElement.nativeElement.files;
    let file = files[0];

    if (event instanceof Array) {
      files = event;
      file = files[0];
    }
    Extensions.checkIfMimeTypeAllowedToOpenCropperModal(file).then((response) => {
      if (response.isAllowed) {
        const uncroppedImage = this._DomSanitizationService.bypassSecurityTrustUrl(
          URL.createObjectURL(file)
        );
        const fileType = file.type;
        const ref = this.modalService.open(CroppedImageModalComponent, {
          data: {
            mimeType: response.mimeType,
            freezeOverlay: true,
            cropperImage: uncroppedImage,
            fileType: fileType,
            attachmentType: this.config.type
          }
        });
        ref.afterClosed.subscribe((result) => {
          if (result === null || result === MODAL_OVERLAY_CLICKED) {
            this.clearInputElements();
            return;
          }

          const reader = new FileReader();
          reader.readAsBinaryString(result);
          reader.onload = () => {
            const fileList = Array.of(
              new File([result], file.name, {
                type: file.type,
                lastModified: file.lastModified
              })
            );
            this.onUploadFiles(fileList);
          };
        });
      } else {
        this.onUploadFiles(files);
      }
    });
  }
  onUploadFiles(fileList: FileList | File[]) {
    const files: File[] = fileList ? (fileList.length !== 0 ? Array.from(fileList) : null) : null;
    this.onChange(files);
    this.onTouch();
    this.clearInputElements();
  }

  onImageDropped() {
    this.sub = this.fileDropped.subscribe({
      next: (data) => {
        this.onImageChange(data);
      }
    });
  }
  private clearInputElements() {
    setTimeout(() => {
      if (this.inputElement) {
        this.inputElement.nativeElement.value = '';
      }
      this.inputCropperElement.nativeElement.value = '';
    }, 0);
  }

  writeValue(obj: any): void {}

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  onChange: any = (_: string) => {};

  onTouch: any = () => {};

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}

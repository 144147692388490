import { environment } from '../../../../environments/environment';

export enum CardTypes {
  DRIVER_CARD = 'DRIVER_CARD',
  WORKSHOP_CARD = 'WORKSHOP_CARD',
  CONTROL_CARD = 'CONTROL_CARD',
  COMPANY_CARD = 'COMPANY_CARD',
  TEMPORARY_DRIVER_CARD = 'TEMPORARY_DRIVER_CARD'
}
export namespace CardTypes {
  export function mapCardTypeToPathSegment(applicationType: CardTypes): string {
    switch (applicationType) {
      case CardTypes.COMPANY_CARD:
        return environment.BACKEND_API.COMPANY_CARD;
      case CardTypes.CONTROL_CARD:
        return environment.BACKEND_API.CONTROL_CARD;
      case CardTypes.DRIVER_CARD:
        return environment.BACKEND_API.DRIVER_CARD;
      case CardTypes.WORKSHOP_CARD:
        return environment.BACKEND_API.WORKSHOP_CARD;
      case CardTypes.TEMPORARY_DRIVER_CARD:
        return environment.BACKEND_API.TEMPORARY_DRIVER_CARD
    }
  }
}

import { CompanyCardNumberFormValidation } from './company-card-number.model';
import { Validators } from '@angular/forms';
import { ValidationPatterns } from 'src/app/shared/validation/validation-patterns.const';
export const defaultCompanyCardNumberValidation: CompanyCardNumberFormValidation = {
  formGroupName: 'companyCardNumber',

  formFields: {
    companyCardNumber: Validators.compose([
      Validators.minLength(16),
      Validators.maxLength(16),
      Validators.pattern(ValidationPatterns.cardNumber)
    ])
  }
};

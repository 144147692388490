import { FormOfRefundDto } from 'src/app/shared/models/applications/form-of-refund-dto.model';
import { DrivingLicenseDto } from './../employee/drivingLicenseDto';
import { ContactDetailsDto } from 'src/app/shared/models/employee/contactDetailsDto';
import { ShippingContactDetailsDto } from 'src/app/shared/models/employee/shipping-contact-details-dto.model';
import { ApplicationDto } from './../applications/application-dto.model';
import { PersonDto } from '../employee/personDto';
import { StatusDto } from '../applications/status-dto.model';
/**
 * INFOCAR STC API
 * API for processing STC applications
 *
 * OpenAPI spec version: v1
 * Contact: java@pulab.pl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * DTO describing driver card application fields
 */
export interface DriverCardApplicationDto extends ApplicationDto {
  /**
   * Birth date of person
   */
  birthDate: string;
  /**
   * Birth place of person
   */
  birthPlace: string;
  /**
   * Card shipping contact details
   */
  cardShippingContactDetails: ShippingContactDetailsDto;
  /**
   * Contact details of person
   */
  contactDetails: ContactDetailsDto;
  /**
   * Serial number of driver card
   */
  driverCardNumber?: string;
  /**
   * Driving license of person
  */
 drivingLicense: DrivingLicenseDto;
 driverStatementDrivingLicense: boolean;
 driverStatementNormalResidenceAddressInPoland: boolean;
 /**
   * Checked when invoice will be electronic
   */
  electronicInvoice?: boolean;
  /**
   * Form of refund money
   */
  formOfRefund: FormOfRefundDto;
  /**
   * Application id
   */
  id?: string;
  paper?: boolean;
  /**
   * Person details
   */
  person: PersonDto;
  /**
   * Reason of application
   */
  reasonType: DriverCardApplicationDto.ReasonTypeEnum;
  
  /**
   * Application serial number
   */
  serialNumber?: number;
  /**
   * Statement confirmation
   */
  statement?: boolean;
  /**
   * Status of application
   */
  status?: StatusDto;
  /**
   * Type of application
   */
  type: ApplicationDto.TypeEnum;
  transportType: string;
}
export namespace DriverCardApplicationDto {
  export type ReasonTypeEnum =
    | 'FIRST_ISSUE'
    | 'CLOSE_EXPIRY_DATE'
    | 'CARD_LOSS'
    | 'CARD_THEFT'
    | 'DAMAGED_OR_DEFECTIVE_CARD'
    | 'INCORRECTLY_ENTERED_OR_LOST_PIN'
    | 'RESIDENCE_COUNTRY_CHANGE'
    | 'ADMINISTRATIVE_DATA_CHANGE'
    | 'PERSONAL_OR_ADMINISTRATIVE_DATA_CHANGE'
    | 'TIME_AND_NON_RENEWABLE_CARD'
    | 'RENEWAL_FOREIGN_CARD'
    | 'FIRST_ISSUE_LOST_FOREIGN_CARD';
  export const ReasonTypeEnum = {
    FIRSTISSUE: 'FIRST_ISSUE' as ReasonTypeEnum,
    CLOSEEXPIRYDATE: 'CLOSE_EXPIRY_DATE' as ReasonTypeEnum,
    CARDLOSS: 'CARD_LOSS' as ReasonTypeEnum,
    CARDTHEFT: 'CARD_THEFT' as ReasonTypeEnum,
    DAMAGEDORDEFECTIVECARD: 'DAMAGED_OR_DEFECTIVE_CARD' as ReasonTypeEnum,
    INCORRECTLYENTEREDORLOSTPIN: 'INCORRECTLY_ENTERED_OR_LOST_PIN' as ReasonTypeEnum,
    RESIDENCECOUNTRYCHANGE: 'RESIDENCE_COUNTRY_CHANGE' as ReasonTypeEnum,
    ADMINISTRATIVEDATACHANGE: 'ADMINISTRATIVE_DATA_CHANGE' as ReasonTypeEnum,
    PERSONALORADMINISTRATIVEDATACHANGE: 'PERSONAL_OR_ADMINISTRATIVE_DATA_CHANGE' as ReasonTypeEnum,
    TIMEANDNONRENEWABLECARD: 'TIME_AND_NON_RENEWABLE_CARD' as ReasonTypeEnum,
    RENEWALFOREIGNCARD: 'RENEWAL_FOREIGN_CARD' as ReasonTypeEnum,
    FIRSTISSUELOSTFOREIGNCARD: 'FIRST_ISSUE_LOST_FOREIGN_CARD' as ReasonTypeEnum
  };
}

<section class="driving-licence-description-modal">
  <div class="modal-card" *ngIf="drivingLicenceDescriptionModal">
    <div class="header">
      <h1 class="modal-title">{{ drivingLicenceDescriptionModal?.title }}</h1>
      <button
        class="close-btn"
        [attr.aria-label]="drivingLicenceDescriptionModal?.btnCloseAria"
        (click)="onCloseClick()">
        <svg-icon class="close-btn-icon" src="assets/images/close_mobile_.svg"></svg-icon>
      </button>
    </div>
    <div class="obverse-section">
      <img alt="" src="{{ drivingLicenceDescriptionModal?.obverseImg }}" />
      <ul class="obverse-description-list">
        <li>
          <h2>
            {{ drivingLicenceDescriptionModal?.obverseDescription.drivingLicenseNumber }}
          </h2>
          <p>
            {{ drivingLicenceDescriptionModal?.obverseDescription.drivingLicenseField }}
          </p>
        </li>
        <li>
          <h2>
            {{ drivingLicenceDescriptionModal?.obverseDescription.releaseDate }}
          </h2>
          <p>
            {{ drivingLicenceDescriptionModal?.obverseDescription.releaseDateField }}
          </p>
        </li>
        <li>
          <h2>
            {{ drivingLicenceDescriptionModal?.obverseDescription.expirationDate }}
          </h2>
          <p>
            {{ drivingLicenceDescriptionModal?.obverseDescription.expirationDateField }}
          </p>
        </li>
        <li>
          <h2>
            {{ drivingLicenceDescriptionModal?.obverseDescription.authority }}
          </h2>
          <p>
            {{ drivingLicenceDescriptionModal?.obverseDescription.authorityField }}
          </p>
        </li>
      </ul>
    </div>
    <div class="reverse-section">
      <img alt="" src="{{ drivingLicenceDescriptionModal?.reverseImg }}" />
      <ul class="reverse-description-list">
        <li>
          <h2>
            {{ drivingLicenceDescriptionModal?.reverseDescription.printNumber }}
          </h2>
          <p>
            {{ drivingLicenceDescriptionModal?.reverseDescription.printUnderQR }}
          </p>
        </li>
        <li>
          <h2>
            {{ drivingLicenceDescriptionModal?.reverseDescription.driverLicenseCategory }}
          </h2>
          <p>
            {{ drivingLicenceDescriptionModal?.reverseDescription.driverLicenseCategoryField }}
          </p>
        </li>
      </ul>
    </div>
  </div>
</section>

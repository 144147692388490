import { SUMMARY_STEP_ROUTE } from 'src/app/applications/word-exam-registration/registration-process/summary-step/_const/summary-step-route.const';
import { TranslateService } from '@ngx-translate/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { RouteEventsService } from 'src/app/shared/services/route-events-service/route-events.service';
import { CONTROL_CARD_APPLICATION_REASON_ROUTE } from 'src/app/applications/stc/control-card-application/application-reason/_consts/application-reason.route.const';
import { CONTROL_CARD_APPLICATION_ATTACHMENTS_ROUTE } from 'src/app/applications/stc/control-card-application/attachments/_consts/attachments.route.const';
import {
  WORD_EXAM_ROUTE,
  WORD_EXAM_PROCESS_ROUTE
} from 'src/app/applications/word-exam-registration/_consts/word-exam-route.const';
import { LoaderStripeService } from 'src/app/layout/_service/loader-stripe.service';

const appNotFoundErrorCode = 'application.not-found';
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private toastrService: ToastrService,
    private translate: TranslateService,
    private router: Router,
    private routeEventsService: RouteEventsService,
    private loaderService: LoaderStripeService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loaderService.open();
    return next.handle(req).pipe(
      finalize(() => this.loaderService.close()),
      catchError((error: HttpErrorResponse) => {
        switch (error.status) {
          case 422:
            let errorCode = null;
            if (error.error && error.error.errors && error.error.errors.length > 0) {
              errorCode = error.error.errors[0].code;
            }
            if (
              this.router.url.includes(CONTROL_CARD_APPLICATION_REASON_ROUTE) &&
              errorCode === appNotFoundErrorCode
            ) {
              break;
            } else if (
              this.router.url.includes(
                `${WORD_EXAM_ROUTE}/${WORD_EXAM_PROCESS_ROUTE}/${SUMMARY_STEP_ROUTE}`
              )
            ) {
              // don't show validation errors on WORD summary page via toastrs
              break;
            } else {
              if (error.error && error.error['errors']) {
                error.error['errors'].forEach((backendError) => {
                  this.displayErrorsAsToasts(backendError['userMessage']);
                });
              } else if (error['errors']) {
                error['errors'].forEach((backendError) => {
                  this.displayErrorsAsToasts(backendError['userMessage']);
                });
              } else {
                this.translate.get('ERROR.INTERCEPTOR_DEFAULT').subscribe((result: string) => {
                  this.toastrService.error(`${result}`);
                });
              }
              break;
            }
          case 404:
            break;
          default:
            break;
        }
        return throwError(error);
      })
    );
  }

  private isErrorMessageAnArray(jsonString: string) {
    try {
      const parsedResponse = JSON.parse(jsonString);
      if (parsedResponse && parsedResponse instanceof Array) {
        return true;
      }
    } catch (e) {}
    return false;
  }

  private displayErrorsAsToasts(errorMessage: any) {
    if (this.isErrorMessageAnArray(errorMessage)) {
      const errorsArray = JSON.parse(errorMessage);
      errorsArray.forEach((e) => {
        // toastrs are always visible on attachment pages
        if (this.router.url.includes(CONTROL_CARD_APPLICATION_ATTACHMENTS_ROUTE)) {
          this.toastrService.error(`${e}`, null, { timeOut: 0 });
        } else {
          this.toastrService.error(`${e}`, null, {timeOut: 10000});
        }
      });
    } else {
      this.toastrService.error(`${errorMessage}`, null, {timeOut: 10000});
    }
  }
}

<div class="search-form-box w-100">
  <form [formGroup]="searchForm">
    <input
      #searchInput
      formControlName="search"
      [attr.aria-expanded]="showResults"
      [placeholder]="'SEARCH.placeholder' | translate"
      (focus)="onFocus()"
      (blur)="onBlur()"
      [attr.title]="'SEARCH.placeholder' | translate"
      [attr.aria-label]="'SEARCH.placeholder' | translate" />
    <img
      src="assets/images/search_icon_dark.svg"
      [attr.aria-label]="'MAIN_PAGE.SEARCH_SECTION.aria-label' | translate"
      alt="search"
      (click)="setFocusOnSearchInput()"
      role="button" />
  </form>
  <div
    [attr.aria-hidden]="!showResults"
    *ngIf="
      !allwaysHideResults &&
      showResults &&
      (inputHasFocus || resultsAllwaysShown || mouseClickOnResults)
    "
    class="search-results-box"
    (clickOutside)="onClickOutside()">
    <div class="results-content">
      <!-- results list -->
      <div
        class="results-found-content"
        *ngIf="searchResponse.items.length !== 0 && searchLoading === false">
        <ul class="results">
          <li
            *ngFor="
              let service of searchResponse.items
                | slice: 0:(isLimited ? resultsToShow : allResults)
            "
            class="results__item">
            <a
              [routerLink]="service | destination"
              externalLink="{{ service.external }}"
              (click)="onItemClick()"
              [attr.title]="service.external ? ('LINK_TITLE.OPEN_IN_NEW_TAB' | translate) : ''"
              [attr.target]="service.external ? '_blank' : '_self'">
              <span>{{ service.title }}</span>
            </a>
          </li>
        </ul>
        <div *ngIf="isLimited" class="all-button-box">
          <button (click)="onShowAll($event)" class="ic-btn col-12 col-sm-6 col-md-6 col-lg-5">
            <div>{{ 'SEARCH.allButtonLabel' | translate }} ({{ searchResponse.totalCount }})</div>
            <img src="assets/images/next_icon_hoover.png" alt="Show all services icon" />
          </button>
        </div>
      </div>
      <!-- loading box -->
      <div class="loading-box" *ngIf="searchLoading === true">
        <app-loaders type="dots"></app-loaders>
      </div>
      <!-- no results box -->
      <div class="no-results" *ngIf="searchResponse.items.length === 0 && searchLoading === false">
        <h2>{{ 'SEARCH.noResults' | translate }}</h2>
      </div>
    </div>
  </div>
</div>

import { UntypedFormGroup } from '@angular/forms';
import { ValidationExtension } from '../../validation/validation-extensions.const';
import { ModifierFunction } from './../../models/modifier-function.model';
import { Injectable } from '@angular/core';
import { debounceTime, distinctUntilChanged, filter, first, firstValueFrom, map, tap } from 'rxjs';

export function nullReplacer(key, value) {
  if (value === null) {
    return undefined;
  }
  return value;
}

export interface SubmitOptions {
  updateValueAndValidity?: boolean;
}

export interface FormValidation {
  formGroupName: string;
  formFields: any;
}

const defaultSubmitOptions: SubmitOptions = { updateValueAndValidity: true };

@Injectable()
export abstract class BaseFormBinderService {
  protected form: UntypedFormGroup;
  protected submitted = false;

  constructor() {}

  protected abstract createForm(fieldValidations?: FormValidation, formGroupNames?: any): UntypedFormGroup;

  public build(staticContent?: any) {}

  public bindForm(fieldValidations?: FormValidation, formGroupNames?: any) {
    this.form = this.createForm(fieldValidations, formGroupNames);
    return this.form;
  }

  getForm() {
    return this.form;
  }

  submit(opt?: SubmitOptions) {
    const options = { ...defaultSubmitOptions, ...opt };
    this.submitted = true;
    this.submitFormGroup(this.form, options);
  }

  private submitFormGroup(formGroup: UntypedFormGroup, options: SubmitOptions) {
    for (const i in formGroup.controls) {
      if (formGroup.controls[i] instanceof UntypedFormGroup) {
        this.submitFormGroup(formGroup.controls[i] as UntypedFormGroup, options);
      } else {
        formGroup.controls[i].markAsTouched();
        if (options.updateValueAndValidity) {
          formGroup.controls[i].updateValueAndValidity();
        }
      }
    }
  }

  unsubmit() {
    this.submitted = false;
  }

  isSubmitted() {
    return this.submitted;
  }  
  
  async isValid(): Promise<boolean> {  
    return new Promise((resolve) => {
      this.form.statusChanges
        .pipe(
          debounceTime(300),
          distinctUntilChanged(),
          filter((status) => status !== 'PENDING'),
          first(),
          map((status) => status === 'VALID'),
          tap((isValid) => {
            resolve(isValid);
          })
        )
        .subscribe();
      
      this.form.updateValueAndValidity();
    });
  }
  
  checkIfFormHasErrors(errors: string[]): boolean {
    let invalid = false;
    errors.forEach((value) => {
      if (this.form.hasError(value)) {
        invalid = true;
      }
    });
    return invalid;
  }

  checkIfControlIsInvalid(controlName: string): boolean {
    return (
      (this.form.controls[controlName].touched || this.submitted) &&
      this.form.controls[controlName].invalid
    );
  }

  checkIfControlHasError(controlName: string, error: string) {
    return (
      (this.form.controls[controlName].touched || this.submitted) &&
      this.form.controls[controlName].hasError(error)
    );
  }

  checkIfControlHasErrors(controlName: string, errors: string[]) {
    let invalid = false;
    errors.forEach((value) => {
      if (this.form.controls[controlName].hasError(value)) {
        invalid = true;
      }
    });

    return (this.form.controls[controlName].touched || this.submitted) && invalid;
  }

  checkIfControlHasErrorsOtherThan(controlName: string, errors: string[]) {
    let hasExcludedError = false;
    errors.forEach((value) => {
      if (this.form.controls[controlName].hasError(value)) {
        hasExcludedError = true;
      }
    });

    return (
      (this.form.controls[controlName].touched || this.submitted) &&
      this.form.controls[controlName].invalid &&
      !hasExcludedError
    );
  }

  setControl<P, R = P>(controlName: string, value: P, modifierFunction?: ModifierFunction<P, R>) {
    this.set<P, R>(controlName, value, modifierFunction);
  }

  protected set<P, R = P>(
    name: string,
    value: P,
    modifierFunction?: ModifierFunction<P, R>,
    options?: Object
  ) {
    let modifiedValue = value as any as R;
    modifiedValue = this.tryToExecuteModifier<P, R>(value, modifierFunction);
    this.form.controls[name].patchValue(modifiedValue, options);
  }

  protected tryToExecuteModifier<P, R>(value: P, modifierFunction: ModifierFunction<P, R>): R {
    let modifiedValue = value as any as R;
    if (modifierFunction && value) {
      try {
        modifiedValue = modifierFunction(value);
      } catch (e) {
        modifiedValue = null;
        console.error(
          `Modifier function exception. Cannot execute modifier on provided value. Intercepted exception: ${e}`
        );
      } finally {
        return modifiedValue;
      }
    }
    return modifiedValue;
  }

  getControlValue<P, R = P>(controlName: string, modifierFunction?: ModifierFunction<P, R>): R {
    return this.get(controlName, modifierFunction);
  }

  protected getEither<P, R = P>(
    firstControlName: string,
    secondControlName: string,
    modifierFunction?: ModifierFunction<P, R>
  ): R {
    const firstValue = this.get<P, R>(firstControlName, modifierFunction);
    return firstValue ? firstValue : this.get<P, R>(secondControlName, modifierFunction);
  }

  protected get<P, R = P>(controlName: string, modifierFunction?: ModifierFunction<P, R>): R {
    let value = this.form.controls[controlName].value;
    value = this.tryToExecuteModifier<P, R>(value, modifierFunction);
    if (value === '') {
      value = null;
    }
    return value;
  }

  disable(controlName: string) {
    this.form.controls[controlName].disable();
  }

  enable(controlName: string) {
    this.form.controls[controlName].enable();
  }

  public setDisabledStateForControlInNestedFormGroup(
    controlName: string,
    formGroupName: string,
    disabledState: boolean
  ): void {
    const control = this.form.get([formGroupName, controlName]);
    if (control) {
      disabledState ? control.disable() : control.enable();
    }
  }

  public getControlValueInNestedFormGroup(controlName: string, formGroupName: string): any {
    const control = this.form.get([formGroupName, controlName]);
    if (control) {
      return control.value;
    }
    return null;
  }

  public isControlRequired(controlName: string): boolean {
    return (
      controlName &&
      this.form.get(controlName) &&
      ValidationExtension.hasRequiredField(this.form.get(controlName))
    );
  }

  protected fillObjectWithFormValues(object: any, formGroupName?: string, maxLength?: number) {
    const formGroup = formGroupName ? this.form.get(formGroupName) : this.form;
    if (!formGroup) {
      return;
    }
    Object.keys(object).forEach((name: string) => {
      object[name] =
        formGroup.get(name) && formGroup.get(name).value
          ? String(formGroup.get(name).value).slice(0, maxLength)
          : null;
    });
  }

  protected fillObjectWithMatchingTypeFormValues(
    object: any,
    formGroupName?: string,
    maxLength?: number
  ) {
    const formGroup = formGroupName ? this.form.get(formGroupName) : this.form;
    if (!formGroup) {
      return;
    }
    Object.keys(object).forEach((name: string) => {
      object[name] =
        formGroup.get(name) &&
        (formGroup.get(name).value || typeof formGroup.get(name).value == 'boolean')
          ? formGroup.get(name).value
          : null;
    });
  }

  public fillFormWith(object: any, options?: Object) {
    if (!object) {
      return;
    }

    Object.entries(object).forEach(([name, value]: [string, any]) => {
      if (this.form.controls[name]) {
        this.set(name, value, null, options);
      }
    });
  }
}

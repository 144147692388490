import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FormConfig } from 'src/app/my-account/settings/form.model';
import {
  BaseFormBinderService,
  FormValidation
} from 'src/app/shared/services/form/base-form-binder.service';
import { DeleteAccountFormContent } from './_model/delete-account-form-content.model';

@Injectable()
export class DeleteAccountFormBuilderService extends BaseFormBinderService {
  content: DeleteAccountFormContent;
  constructor(private formBuilder: UntypedFormBuilder, private translate: TranslateService) {
    super();
    this.translate.get('DASHBOARD.SETTINGS.DELETE_ACCOUNT_MODAL.FORM_CONTENT').subscribe({
      next: (result: DeleteAccountFormContent) => (this.content = result)
    });
  }

  public build(): FormConfig {
    return this.createConfig();
  }
  createConfig(): FormConfig {
    return {
      form: this.bindForm(),
      controls: {
        password: {
          label: this.content.label,
          formControlName: 'password',
          type: 'password',
          placeholder: this.content.placeholder,
          formErrors: [
            {
              type: ['required'],
              label: this.content.requiredLabel
            },
            {
              type: ['maxlength'],
              label: this.content.maxlengthLabel
            }
          ]
        }
      }
    };
  }

  protected createForm(fieldValidations?: FormValidation, formGroupNames?: any): UntypedFormGroup {
    return this.formBuilder.group({
      password: this.formBuilder.control(null, [Validators.required, Validators.maxLength(256)])
    });
  }
}

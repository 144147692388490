import { Component } from '@angular/core';

@Component({
  selector: 'app-teaser-banner',
  template: `
    <div class="teaser-banner">
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./teaser-banner.component.scss']
})
export class TeaserBannerComponent {}

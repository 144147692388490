import { Directive, OnInit } from '@angular/core';
import { BaseModalDirective } from '../base-modal';

@Directive()
export abstract class SimpleNonblockingInformationModalComponent
  extends BaseModalDirective
  implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() {}

  onOkClick() {
    this.close();
  }

  onCancelClick() {
    this.close();
  }
}

import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { ControlCardApplicationDto } from './../../../../models/control-card-application/control-card-application.model';
import { EployeeDataChangeService } from './../_service/eployee-data-change.service';
import { GhostButtonType } from 'src/app/shared/components/ghost-button/ghost-button-type.enum';
import { BaseModalDirective } from 'src/app/shared/components/modal/base-modal';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-employee-data-change-modal',
  templateUrl: './employee-data-change-modal.component.html',
  styleUrls: ['./employee-data-change-modal.component.scss']
})
export class EmployeeDataChangeModalComponent extends BaseModalDirective implements OnInit {
  GhostButtonType: typeof GhostButtonType = GhostButtonType;
  content: any;
  constructor(
    private employeeService: EployeeDataChangeService,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    this.translate.get('SHARED.EMPLOYEE_DATA_CHANGE_MODAL').subscribe({
      next: (content) => {
        this.content = content;
      }
    });
  }

  submitForm() {
    this.close(true);
  }

  onClose() {
    this.close(null);
  }
}

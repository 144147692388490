<div class="ic-input">
  <label class="ic-input__label">{{ config?.label }}<span *ngIf="config.required"> *</span></label>
  <div class="input-flex">
    <div class="read-only" *ngIf="readOnly"></div>
    <input
      autocomplete="new-password"
      [value]="value"
      [style.width.%]="inputWidth"
      (input)="onValueChange($event)"
      [disabled]="config.disabled || readOnly"
      (blur)="onBlur()"
      [ngClass]="{
        invalid: invalid
      }"
      [type]="config.type"
      [placeholder]="config.placeholder"
      [attr.title]="config.placeholder"
      [attr.aria-label]="config.placeholder" />
    <div *ngIf="invalid" class="error-icon"></div>
  </div>
  <div class="error-description-container">
    <div *ngFor="let error of errors">
      <span class="error-description">{{ error?.label }}</span>
    </div>
  </div>
</div>

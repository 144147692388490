import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { News } from './_models/news';
import { newsMock } from './_mock/news';

@Injectable({
  providedIn: 'root'
})
export class NewsService {
  constructor(private httpClient: HttpClient) {}

  // todo move to real cms
  getNewsById(id: number): Observable<News> {
    const mock = newsMock.find((art) => art.id === id);
    if (mock) {
      return new BehaviorSubject<News>(mock);
    } else return this.httpClient.get<News>(environment.WP_API_INFOCAR_URL + '/news/' + id);
  }

  // todo move to real cms
  getNewsPromoPaginated(page: number, newsPerPage: number) {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('rows', newsPerPage.toString())
      .set('promo', 'true');
    return this.httpClient.get(environment.WP_API_INFOCAR_URL + '/newsPaginated/', {
      params: params
    });
  }
  // todo move to real cms
  getNewsPaginated(
    page: number,
    rows: number,
    tagId?: number
  ): Observable<{ news: News[]; total: number; tag?: string }> {
    if (!tagId) {
      const params = new HttpParams().set('page', page.toString()).set('rows', rows.toString());
      return this.httpClient.get<{ news: News[]; total: number }>(
        environment.WP_API_INFOCAR_URL + '/newsPaginated/',
        { params: params }
      );
    }
    return this.getNewsPaginatedByTag(page, rows, tagId);
  }
  // todo move to real cms
  getRecentlyReadNews() {
    return this.httpClient.get(environment.WP_API_INFOCAR_URL + '/recentlyReadNews');
  }

  // todo move to real cms
  private getNewsPaginatedByTag(
    page: number,
    rows: number,
    tagId: number
  ): Observable<{ news: News[]; total: number; tag: string }> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('rows', rows.toString())
      .set('tagId', tagId.toString());
    return this.httpClient.get<{ news: News[]; total: number; tag: string }>(
      environment.WP_API_INFOCAR_URL + '/newsPaginatedByTag/',
      { params: params }
    );
  }
}

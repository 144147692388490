import { Component } from '@angular/core';

@Component({
  selector: 'app-text-input-placeholder',
  template: `
    <article class="placeholder">
      <div class="placeholder__element placeholder__element--title"></div>
      <div class="placeholder__element placeholder__element--input"></div>
    </article>
  `,
  styleUrls: ['./text-input-placeholder.component.scss']
})
export class TextInputPlaceholderComponent {}

import { trigger } from '@angular/animations';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { interval, Observable, Subscription } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { ChoosenPaymentType } from 'src/app/shared/components/choose-payment-method/choosen-payment-type.model';
import { FADEOUT_ANIMATION } from 'src/app/shared/extensions/animations';
import { PaymentMethodType } from 'src/app/shared/models/payment/payment-dto.model';
import { ExternalPaymentsContentModel } from '../../esp-payment/_models/esp-payment-content.model';
import { EspPaymentStatus } from '../../esp-payment/_models/esp-payment.model';
import { POSK_PAYMENT_CONFIRMATION, POSK_ROUTE } from '../_consts/posk-payment-route.const';
import { PoskLicencePaymentDto } from '../_models/posk-licence-payment.model';
import {
  OskPaymentType,
  PoskPaymentQueryParams,
  PoskPaymentStatus,
  PoskTypeSubscription
} from '../_models/posk-payment.model';
import { PoskWordPaymentDto } from '../_models/posk-word-payment.model';
import { PoskPaymentService } from '../_services/posk-payment.service';
import { GhostButtonType } from 'src/app/shared/components/ghost-button/ghost-button-type.enum';
import { environment as env } from 'src/environments/environment';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  animations: [trigger('fadeOut', FADEOUT_ANIMATION)]
})
export class PaymentComponent implements OnInit, OnDestroy {
  redirectUrl = env.POSK.REDIRECT_URL;
  automaticRedirect: ReturnType<typeof setInterval>;
  GhostButtonType: typeof GhostButtonType = GhostButtonType;
  payment: PoskLicencePaymentDto | PoskWordPaymentDto;
  isPeriodic: boolean;
  content: ExternalPaymentsContentModel;
  paymentId: string;
  paymentType: OskPaymentType;
  splashEnabled = false;
  loaderEnabled = false;
  initialLoaderEnabled = true;
  OskPaymentType: typeof OskPaymentType = OskPaymentType;
  PoskTypeSubscription: typeof PoskTypeSubscription = PoskTypeSubscription;
  PENDING_STATUSES = [EspPaymentStatus.AUTHPENDING];
  timeInterval: Subscription;
  timeToRedirect = 30;
  errors = {
    invalidStatus: false,
    invalidType: false,
    noResponse: false
  };
  constructor(
    private translate: TranslateService,
    private poskService: PoskPaymentService,
    private route: ActivatedRoute,
    protected router: Router,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.selectPaymentSourceBasedOnParams();
    this.getStaticContent();
  }

  setPaymentError(flag: keyof PaymentComponent['errors']) {
    this.initialLoaderEnabled = false;
    this.goToLinkAutomatically();
    this.errors[flag] = true;
  }

  selectPaymentSourceBasedOnParams() {
    this.route.queryParams.pipe(take(1)).subscribe((params: PoskPaymentQueryParams) => {
      if (!params.id) {
        this.setPaymentError('invalidType');
        return;
      }

      this.paymentId = params.id;
      this.paymentType = params.type;

      if (this.paymentType !== OskPaymentType.LICENCE && this.paymentType !== OskPaymentType.WORD) {
        this.setPaymentError('invalidType');
        return;
      }

      switch (this.paymentType) {
        case OskPaymentType.LICENCE:
          this.getPayment(this.poskService.getPoskLicencePayment, this.paymentId);
          break;
        case OskPaymentType.WORD:
          this.getPayment(this.poskService.getPoskWordPayment, this.paymentId);
          break;
        default:
          this.initialLoaderEnabled = false;
          break;
      }
    });
  }

  getPayment(
    callback: (id: string) => Observable<PoskLicencePaymentDto | PoskWordPaymentDto>,
    id: string
  ) {
    callback(id).subscribe({
      next: (payment) => {
        if (payment.redirectUrl) {
          this.redirectUrl = payment.redirectUrl;
        }
        if (this.checkIfPaymentIsAvailable(payment)) {
          this.payment = payment;
          this.isPeriodic = payment.periodic;
        } else {
          this.setPaymentError('invalidStatus');
        }
        this.initialLoaderEnabled = false;
      },
      error: (error) => {
        this.setPaymentError('noResponse');
        this.handlePaymentError(null, error);
      }
    });
  }

  checkIfPaymentIsAvailable(payment: PoskLicencePaymentDto | PoskWordPaymentDto) {
    return payment.status === PoskPaymentStatus.REGISTERED;
  }

  getStaticContent() {
    this.translate.get('EXTERNAL_PAYMENTS').subscribe({
      next: (content: ExternalPaymentsContentModel) => (this.content = content)
    });
  }

  setHTMLPaymentTitle() {
    if (this.paymentType === OskPaymentType.WORD || !this.payment.typeSubscription) {
      return this.content.CART_LIST.chooseMethod;
    }
    switch (this.payment.typeSubscription) {
      case PoskTypeSubscription.ZMIANA_KARTY_ZWROT_SRODKOW:
        return this.content.CART_LIST.authorizeCard;
      case PoskTypeSubscription.ZMIANA_KARTY_PLATNOSC_ZA_LICENCJE:
        return this.content.CART_LIST.changeCardAndPay;
      case PoskTypeSubscription.REJESTRACJA_PLATNOSCI:
        return this.content.CART_LIST.onlyCardPayment;
      default:
        return this.content.CART_LIST.chooseMethod;
    }
  }

  setHTMLpayButtonText() {
    if (
      this.payment.typeSubscription &&
      this.payment.typeSubscription === PoskTypeSubscription.ZMIANA_KARTY_ZWROT_SRODKOW
    ) {
      return this.content.CHOOSE_METHOD.add;
    }
  }

  handlePaymentError(toastrMessage: string, error?: HttpErrorResponse) {
    [this.initialLoaderEnabled, this.splashEnabled, this.loaderEnabled] = [false, false, false];
    toastrMessage ? this.toastrService.error(toastrMessage) : null;
    const genericMessage = `${this.translate.instant('EXTERNAL_PAYMENTS.ERRORS.GENERIC')} ${
      error.status ? error.status : '0'
    }`;

    if (error.error && error.error.errors) {
      for (const e of error.error.errors) {
        e.userMessage
          ? this.toastrService.error(e.userMessage)
          : this.toastrService.error(genericMessage);
      }
    } else {
      this.toastrService.error(genericMessage);
    }
  }

  onSubmit(event: ChoosenPaymentType) {
    switch (event.paymentType) {
      case PaymentMethodType.BLIK:
        env.FEATURE_TOGGLES.BLIK_LVL_ZERO ? this.proceedWithBlik(event.blikCode) : this.proceedWithECard();
        break;
      case PaymentMethodType.E_CARD:
        this.proceedWithECard();
        break;
      case PaymentMethodType.PAYMENT_CARD:
        this.proceedWithPaymentCard();
        break;
      default:
        console.error('Nieobsługiwany typ płatności');
        break;
    }
  }

  private proceedWithBlik(blikCode: string) {
    this.splashEnabled = true;

    this.poskService.createBlikPayment$(this.paymentId, this.createBlikObject(blikCode)).subscribe({
      next: (result) => {
        if (this.PENDING_STATUSES.includes(result.paymentStatus)) {
          this.poolBlikPaymentStatus(result.billId);
        } else if (result.paymentStatus === EspPaymentStatus.EXCEEDED) {
          this.handlePaymentError(this.content.CONFIRMATION.EXCEEDED.paymentInfo);
        } else {
          this.handleBlikPaymentStatus(result);
        }
      },
      error: (error) => {
        this.handlePaymentError(null, error);
      }
    });
  }

  private createBlikObject(code: string) {
    return {
      blikCode: code
    };
  }

  private poolBlikPaymentStatus(billId: string): void {
    this.timeInterval = interval(3000)
      .pipe(switchMap(() => this.poskService.getPaymentStatus(billId)))
      .subscribe({
        next: (bill) => this.handleBlikPaymentStatus(bill),
        error: (error) => {
          this.handlePaymentError(null, error);
        }
      });
  }

  private handleBlikPaymentStatus(bill): boolean {
    if (bill.paymentStatus) {
      this.splashEnabled = false;
      this.timeInterval ? this.timeInterval.unsubscribe() : null;
      this.router.navigate(['/', POSK_ROUTE, POSK_PAYMENT_CONFIRMATION, bill.billId]);
    }
    return false;
  }

  private proceedWithECard() {
    this.loaderEnabled = true;
    this.poskService.createPayment$(this.paymentId).subscribe({
      next: (result) => {
        if (result.paymentStatus === EspPaymentStatus.EXCEEDED) {
          this.handlePaymentError(this.content.CONFIRMATION.EXCEEDED.paymentInfo);
        } else {
          window.location.href = result.redirectUrl;
        }
      },
      error: (error) => {
        this.handlePaymentError(null, error);
      }
    });
  }

  goToLinkAutomatically() {
    this.automaticRedirect = setInterval(() => {
      if (this.timeToRedirect > 0) {
        this.timeToRedirect--;
      } else {
        clearInterval(this.automaticRedirect);
        window.location.href = this.redirectUrl;
      }
    }, 1000);
  }
  proceedWithPaymentCard() {
    this.proceedWithECard();
  }

  ngOnDestroy(): void {
    clearTimeout(this.automaticRedirect);
  }
}

import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalRef } from '../modal/modal-ref';
import { ModalService } from '../modal/modal.service';
import { TextInformationModalComponent } from '../modal/text-information-modal/text-information-modal.component';
import { AcceptancesFormConfig, AcceptancesControlConfig } from './_models/acceptances-form.model';
import { AcceptancesFormBuilderService } from './_services/acceptances-form-builder.service';
import { UntypedFormGroup } from '@angular/forms';
import { AcceptancesFormValidation } from './acceptances-validation';
import { defaultAcceptancesValidation } from '../acceptances-checboxes/acceptances-validation';
import { TermsOfServicePageModel } from 'src/app/info-pages/terms-of-service-page/_model/terms-of-service-page.model';
import { ModalContent } from './_models/acceptances-modal-content.model';
import { AcceptanceDto, AcceptanceType } from 'src/app/core/model/acceptances-model';
import { AcceptancesStaticContent } from './_models/acceptances-static-content.model';

@Component({
  selector: 'app-acceptances-checkboxes',
  templateUrl: './acceptances-checboxes.component.html',
  styleUrls: ['./acceptances-checboxes.component.scss'],
  providers: [AcceptancesFormBuilderService]
})
export class AcceptancesChecboxesComponent implements OnInit, OnDestroy {
  @Output() emitAcceptances: EventEmitter<any> = new EventEmitter<any>();
  @Input() formValidation: AcceptancesFormValidation = defaultAcceptancesValidation;
  @Input() aggregateFormGroup: UntypedFormGroup;
  @Input() missingAcceptances: AcceptanceDto[];
  @Input() initialAcceptances: AcceptanceType[];
  @Input() blockRequired: boolean;

  formGroup: UntypedFormGroup;
  config: AcceptancesFormConfig;
  modalRef: ModalRef;

  // modals content
  rulesContent: TermsOfServicePageModel;
  rodoContent: ModalContent;
  infosContent: ModalContent;
  acceptances: string[] = [];
  allChecked = false;
  checkAllLabel: string;

  constructor(
    private formConfigBinder: AcceptancesFormBuilderService,
    private modalService: ModalService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.translate.get('ACCEPTANCES').subscribe({
      next: (content: AcceptancesStaticContent) => {
        this.checkAllLabel = content.CHECK_ALL;
        this.config = this.formConfigBinder.build(
          content,
          this.formValidation,
          this.missingAcceptances,
          this.blockRequired
        );
        this.formGroup = this.config.form;
        if (this.initialAcceptances) {
          this.acceptances = [...this.initialAcceptances];
          for (const acc of this.initialAcceptances) {
            const control = this.formGroup.get(acc);
            if (control) {
              control.patchValue(true);
            }
          }
        }
        this.aggregateFormGroup.addControl(this.formValidation.formGroupName, this.formGroup);
        this.updateAllCheckedState();
      }
    });
    this.fetchModalsContents();
  }

  private fetchModalsContents() {
    this.translate.get('TERMS_OF_SERVICE_PAGE').subscribe((resp: TermsOfServicePageModel) => {
      this.rulesContent = resp;
    });
    this.translate.get(`ACCEPTANCES_MODALS.INFOS_MODAL_CONTENT`).subscribe((resp: ModalContent) => {
      this.infosContent = resp;
    });
  }

  switchModalBaseOnCheckbox(formControlName: AcceptanceType) {
    switch (formControlName) {
      case AcceptanceType.RULES:
        this.openRulesModal(this.rulesContent);
        break;
      case AcceptanceType.INFOS:
        this.openContentModal(this.infosContent);
        break;
    }
  }

  onToggle(value: boolean, formControlName: string) {
    if (!value && this.acceptances.includes(formControlName)) {
      this.acceptances.splice(
        this.acceptances.findIndex((acceptance) => acceptance === formControlName),
        1
      );
    } else if (value && !this.acceptances.includes(formControlName)) {
      this.acceptances = [formControlName, ...this.acceptances];
    }
    this.config.form.controls[formControlName].patchValue(value, {
      emitEvent: false
    });
    this.updateAllCheckedState();
    this.emitAcceptances.emit(this.acceptances);
  }

  onAllChanged(value: boolean) {
    this.allChecked = !this.allChecked;
    this.formConfigBinder.setControlsValueTo(this.allChecked);
    this.emitAcceptances.emit(this.acceptances);
  }

  private openContentModal(content: any) {
    if (!this.modalRef) {
      this.modalRef = this.modalService.open(TextInformationModalComponent, {
        data: { content: content }
      });
      const sub = this.modalRef.afterClosed.subscribe(() => {
        sub.unsubscribe();
        this.modalRef = null;
      });
    }
  }
  private openRulesModal(content: any) {
    if (!this.modalRef) {
      this.modalRef = this.modalService.open(TextInformationModalComponent, {
        data: { termsContent: content }
      });
      const sub = this.modalRef.afterClosed.subscribe(() => {
        sub.unsubscribe();
        this.modalRef = null;
      });
    }
  }

  private updateAllCheckedState(): void {
    this.allChecked = this.acceptances.length === this.config.controls.length;
  }

  ngOnDestroy(): void {
    if (this.aggregateFormGroup.contains(this.formValidation.formGroupName)) {
      this.aggregateFormGroup.removeControl(this.formValidation.formGroupName);
    }
  }
}

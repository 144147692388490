import { ApplicationKind } from 'src/app/application-landing-pages/obtain-tachograph-card/_consts/obtain-card.const';
import { ApplicationDto } from '../applications/application-dto.model';
import { ApplicationBasicDto } from '../applications/application-basic-dto.model';

// export interface PaymentDto {
//   id: string;
//   type: ApplicationDto.TypeEnum;
// }

export interface PaymentDto {
  applicationOwnerIdType: string;
  applicationId: string;
  paymentStatus: PaymentStatus;
  type: string;
  billId: {
    id: string;
  };
}

export interface PaymentDtoV2 {
  applicationId: string;
  applicationKind: ApplicationKind;
  applicationType: ApplicationDto.TypeEnum;
  complaintId: string;
  emailAddress: string;
  paymentStatus: PaymentStatus;
  reasonType: ApplicationDto.ReasonTypeEnum;
  serialNumber: string;
}

export interface PaymentProviderResponse {
  useBillUrl: string;
}

export enum PaymentMethodType {
  BLIK,
  E_CARD,
  PAYMENT_CARD,
  TRADITIONAL_PAYMENT
}

export enum PaymentStatus {
  AUTHREJECTED = 'AUTHREJECTED',
  AUTHPENDING = 'AUTHPENDING',
  AUTHACCEPTED = 'AUTHACCEPTED',
  REVERSALREJECTED = 'REVERSALREJECTED',
  REVERSALPENDING = 'REVERSALPENDING',
  REVERSALACCEPTED = 'REVERSALACCEPTED',
  EXCEEDED = 'EXCEEDED'
}

import { ObtainCardType } from './../../application-landing-pages/obtain-tachograph-card/_consts/obtain-card.const';
import { Action } from '@ngrx/store';
import { User } from '../model/user.model';
import { ComplaintCardType } from 'src/app/application-landing-pages/complaint/_consts/complaint.const';

export interface AuthPayload {
  cardType?: ObtainCardType | ComplaintCardType;
  user?: User;
  url?: string;
  hasAcc?: boolean;
}
export interface AuthAction extends Action {
  payload?: AuthPayload;
}

export enum AuthActionType {
  AUTH_INIT = '[Auth] init',
  AUTH_READY = '[Auth] ready',
  AUTH_ERROR = '[Auth] error',
  LOGIN = '[Auth] login',
  LOGOUT = '[Auth] logout',
  AUTH_LOGGED = '[Auth] logged',
  AUTH_LOGGED_OUT = '[Auth] logged out',
  SYNC_LOGIN = '[Auth] sync login',
  SYNC_LOGOUT = '[Auth] sync logout',
  SET_USER = '[Auth] set user',
  REFRESH_USER = '[Auth] refresh user',
  SET_ACCEPTANCES = '[Auth] set acceptances',
  ANONYMOUS_LOGIN = '[Auth] anonymous login',
  CLEAR_AUTH = '[Auth] clear auth',
  DISABLE_ANONYMOUS = '[Auth] disable anonymous'
}

export namespace AuthActions {
  export class AuthInit implements AuthAction {
    type = AuthActionType.AUTH_INIT;
    constructor() {}
  }

  export class AuthReady implements AuthAction {
    type = AuthActionType.AUTH_READY;
    constructor(public payload: { user: User }) {}
  }

  export class AuthError implements AuthAction {
    type = AuthActionType.AUTH_ERROR;
    constructor() {}
  }

  export class Login implements AuthAction {
    type = AuthActionType.LOGIN;
    constructor(public payload: { url?: string }) {}
  }

  export class Logout implements AuthAction {
    type = AuthActionType.LOGOUT;
  }

  export class SyncLogin implements AuthAction {
    type = AuthActionType.SYNC_LOGIN;
  }

  export class SyncLogout implements AuthAction {
    type = AuthActionType.SYNC_LOGOUT;
  }

  export class RefreshUser implements AuthAction {
    type = AuthActionType.REFRESH_USER;
    constructor() {}
  }

  export class ClearAuth implements AuthAction {
    type = AuthActionType.CLEAR_AUTH;
    constructor() {}
  }

  export class AnonymousLogin implements AuthAction {
    type = AuthActionType.ANONYMOUS_LOGIN;
    constructor() {}
  }

  export class AuthenticatedLogged implements AuthAction {
    type = AuthActionType.AUTH_LOGGED
  }

  export class AuthenticatedLoggedOut implements AuthAction {
    type = AuthActionType.AUTH_LOGGED_OUT
  }

  export class DisableAnonymous implements AuthAction {
    type = AuthActionType.DISABLE_ANONYMOUS;
    constructor() {}
  }

  export class SetAcceptances implements AuthAction {
    type = AuthActionType.SET_ACCEPTANCES;

    constructor(public payload: { hasAcc: boolean }) {}
  }
}

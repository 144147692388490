import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../../shared/shared.module';
import { LoaderStripeComponent } from './loader-stripe.component';

@NgModule({
  declarations: [LoaderStripeComponent],
  imports: [CommonModule, SharedModule],
  exports: [LoaderStripeComponent],
  providers: []
})
export class LoaderStripeModule {}

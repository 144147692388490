import { Subscription } from 'rxjs';

export class Subscriptions {
  private subs: Subscription;

  constructor() {
    this.subs = new Subscription();
  }

  set next(sub: Subscription) {
    this.subs.add(sub);
  }

  public unsubscribe() {
    this.subs.unsubscribe();
  }
}

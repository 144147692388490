import { DriverCardNumberFormValidation } from './driver-card-number.model';
import { Validators } from '@angular/forms';
import { ValidationPatterns } from 'src/app/shared/validation/validation-patterns.const';

export const defaultDriverCardNumberValidation: DriverCardNumberFormValidation = {
  formGroupName: 'driverCardNumber',

  formFields: {
    driverCardNumber: Validators.compose([
      Validators.minLength(16),
      Validators.maxLength(16),
      Validators.pattern(ValidationPatterns.cardNumber)
    ])
  }
};

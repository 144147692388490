import { RouterModule } from '@angular/router';
import { InMemoryAuthStorage } from './in-memory-auth-storage';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './auth.effects';
import { AuthService } from './auth.service';
import { AuthHelperService } from './auth-helper.service';
import { AuthQueryService } from './auth-query.service';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { AuthErrorLoggerService } from './auth-error-logger.service';
import { environment } from '../../../environments/environment';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: environment.auth.OAUTH_ALLOWED_URLS,
        sendAccessToken: true
      }
    })
  ],
  exports: [StoreModule, EffectsModule],
  providers: [
    AuthEffects,
    AuthService,
    AuthHelperService,
    AuthQueryService,
    AuthErrorLoggerService,
    { provide: OAuthStorage, useClass: InMemoryAuthStorage }
  ]
})
export class AuthModule {
  constructor(
    private auth: AuthService,
    private authHelper: AuthHelperService,
    private authQuery: AuthQueryService,
    private authEffects: AuthEffects,
    private authErrorLogger: AuthErrorLoggerService
  ) {}
}

import { Country } from 'src/app/shared/models/countries/country.model';
import { AddressDataDto } from 'src/app/shared/components/stc/address-data/address-data.model';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AddressDataFormBinder } from './address-data.form-binder';
import { AddressDataFormValidation, AddressDataStaticContent } from './address-data.model';
import { defaultAddressDataValidation } from './address-data.validation';
import {
  CorrectionsErrors,
  FieldErrorEntry
} from 'src/app/shared/models/corrections/corrections.model';
import { AddressDataCorrectionPrefixEnum } from './address-data-correction-prefix.enum';

const nameof = (name: keyof AddressDataFormValidation['formFields']) => name;

@Component({
  selector: 'app-address-data',
  templateUrl: './address-data.component.html',
  styleUrls: ['./address-data.component.scss'],
  providers: [AddressDataFormBinder]
})
export class AddressDataComponent implements OnInit, OnDestroy {
  @Input() staticContent: AddressDataStaticContent;
  @Input() formValidation: AddressDataFormValidation = defaultAddressDataValidation;
  @Input() aggregateFormGroup: UntypedFormGroup;
  @Input() fetchedData: AddressDataDto;
  @Input() countries: Country[];
  @Input() defaultCountry: Country;
  @Input() countryLocked: boolean;
  @Output() saveDraft: EventEmitter<any> = new EventEmitter();
  @Output() countryCodeSelected: EventEmitter<string> = new EventEmitter();
  @Output() mailingAddressSelectionChanged: EventEmitter<boolean> = new EventEmitter();
  @Input() htmlElementId: string;
  @Input() formSectionName: string;
  @Input() set correctionsErrors(data: CorrectionsErrors) {
    this._correctionsErrors = data;
    this.getFieldsErrors(data);
  }
  private _correctionsErrors: CorrectionsErrors;
  formGroup: UntypedFormGroup;
  defaultCountryCode = 'PL';
  @Input() set formPrefix(prefixType: AddressDataCorrectionPrefixEnum) {
    switch (prefixType) {
      case AddressDataCorrectionPrefixEnum.DEFAULT:
        this._formPrefix = 'contactDetails';
        break;
      case AddressDataCorrectionPrefixEnum.TECHNICIAN:
        this._formPrefix = 'technicianContactDetails';
        break;
      case AddressDataCorrectionPrefixEnum.WORKSHOP:
        this._formPrefix = 'workshopContactDetails';
        break;
      case AddressDataCorrectionPrefixEnum.CONTROL_AUTHORITY:
        this._formPrefix = 'controlAuthorityContactDetails';
        break;
      case AddressDataCorrectionPrefixEnum.COMPANY:
        this._formPrefix = 'companyContactDetails';
        break;
    }
    this.getFieldsErrors(this._correctionsErrors);
  }
  private _formPrefix = 'contactDetails';
  fieldsErrors: FieldErrorEntry[] = [];
  constructor(private binder: AddressDataFormBinder) {}

  ngOnInit() {
    this.formGroup = this.binder.bindForm(this.formValidation);
    this.aggregateFormGroup.addControl(this.formValidation.formGroupName, this.formGroup);
    if (this.formValidation.formFields.countryCode) {
      this.setDefaultCountry();
      this.binder.setCountryCodeOnChanges(this.defaultCountry.countryCode);
    }
    this.binder.fillFormWith(this.fetchedData);
    if (this.formValidation.formFields.countryCode) {
      if (!this.fetchedData.countryCode) {
        // using nameof to ensure that control names exist on validation object
        this.binder.setControl(nameof('countryCode'), this.defaultCountry.countryCode);
      } else if (this.fetchedData.countryCode !== this.defaultCountryCode) {
        this.countryCodeSelected.emit(this.binder.getControlValue(nameof('countryCode')));
      }
    }
    if (this.formValidation.formFields.correspondenceAddressCheckbox) {
      this.binder.setMailingAddresOnChanges(this.mailingAddressSelectionChanged);
    }
  }

  sectionErrorsExist(): boolean {
    return this._correctionsErrors?.sectionErrors.some(
      (item) =>
        item.entry === this._formPrefix ||
        item.entry === this._formPrefix + '.' + this.formSectionName
    );
  }

  getFieldsErrors(correctionsErrors: CorrectionsErrors) {
    this.fieldsErrors =
      correctionsErrors?.fieldErrors
        .filter((item) => item.entry.startsWith(this._formPrefix))
        .map((entry) => {
          return {
            entry: entry.entry,
            previousValue: entry.previousValue
          };
        }) || [];
  }

  isFieldIncludedInCorrection(fieldName: string): boolean {
    return (
      this.fieldsErrors.some((err) => err.entry.endsWith(fieldName)) || this.sectionErrorsExist()
    );
  }

  findPreviousValue(fieldName: string): any {
    return this.fieldsErrors.find((err) => err.entry.endsWith(fieldName))?.previousValue;
  }

  setDefaultCountry(): void {
    const defaultCountryIndex = this.countries.findIndex(
      (country) => country.countryCode === this.defaultCountryCode
    );
    this.defaultCountry = this.countries[defaultCountryIndex];
    this.binder.setControl(nameof('countryCode'), this.defaultCountry.countryCode);
  }

  public onBlur(): void {
    this.saveDraft.emit();
  }

  public onCountrySelected(): void {
    this.countryCodeSelected.emit(this.binder.getControlValue(nameof('countryCode')));
    this.saveDraft.emit();
  }

  public isControlRequired(controlName: string): boolean {
    return this.binder.isControlRequired(controlName);
  }

  ngOnDestroy(): void {
    if (this.aggregateFormGroup.contains(this.formValidation.formGroupName)) {
      this.aggregateFormGroup.removeControl(this.formValidation.formGroupName);
    }
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { Translation } from '../../shared/models/search/translation.model';
import * as fromShared from '../../shared/store/shared.reducer';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-search-page-section',
  templateUrl: './search-page-section.component.html',
  styleUrls: ['./search-page-section.component.scss']
})
export class SearchPageSectionComponent implements OnInit, OnDestroy {
  translation: Translation;
  subscription: Subscription;

  constructor(private servicesStore: Store<fromShared.SharedState>) {
    this.subscription = this.servicesStore
      .select(fromShared.selectSearchServicesData)
      .subscribe((result) => (this.translation = result));
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

<section class="wizard-step-back-modal">
  <div *ngIf="appDeleteWarning; else Normal">
    <header class="header">
      <h1 class="header__title">
        {{ 'SHARED.WIZARD_BACK_MODAL.TITLE_QUIT' | translate }}
      </h1>
      <img
        alt="{{ 'SHARED.WIZARD_BACK_MODAL.BTN_CLOSE_ARIA' | translate }}"
        role="button"
        class="close-btn"
        tabindex="0"
        (keydown.enter)="onCancelClick()"
        (click)="onCancelClick()"
        src="assets/images/Group 3293.svg" />
    </header>
    <main class="main">
      <p class="main__content">
        {{ 'SHARED.WIZARD_BACK_MODAL.CONTENT_APP_DELETE' | translate }}
      </p>
      <div class="main__nav-btns">
        <ic-ghost-button
          (click)="onOkClick()"
          [type]="GhostButtonType.BIG_FIRST_RANK_FLEX"
          [width]="150"
          [height]="50"
          [showArrow]="false">
          <span class="nav-btns__text">{{
            'SHARED.WIZARD_BACK_MODAL.QUIT_BUTTON_LABEL' | translate
          }}</span>
        </ic-ghost-button>
        <ic-ghost-button
          (click)="onCancelClick()"
          [type]="GhostButtonType.BIG_SECOND_RANK_FLEX"
          [width]="208"
          [height]="50">
          <span class="nav-btns__text">{{
            'SHARED.WIZARD_BACK_MODAL.CANCEL_QUIT_BUTTON_LABEL' | translate
          }}</span>
        </ic-ghost-button>
      </div>
    </main>
  </div>
  <ng-template #Normal>
    <header class="header">
      <h1 class="header__title">
        {{ 'SHARED.WIZARD_BACK_MODAL.TITLE_BACK' | translate }}
      </h1>
      <img
        alt="{{ 'SHARED.WIZARD_BACK_MODAL.BTN_CLOSE_ARIA' | translate }}"
        role="button"
        class="close-btn"
        tabindex="0"
        (keydown.enter)="onCancelClick()"
        (click)="onCancelClick()"
        src="assets/images/Group 3293.svg" />
    </header>
    <main class="main">
      <p class="main__content">
        {{ isAnonymous ? ('SHARED.WIZARD_BACK_MODAL.CONTENT_BACK_ANONYMOUS' | translate) : ('SHARED.WIZARD_BACK_MODAL.CONTENT_BACK' | translate) }}
      </p>
      <div class="main__nav-btns">
        <ic-ghost-button
          (click)="onOkClick()"
          [type]="GhostButtonType.BIG_FIRST_RANK_FLEX"
          [width]="136"
          [showArrow]="false">
          <span class="nav-btns__text">{{
            'SHARED.WIZARD_BACK_MODAL.BACK_BUTTON_LABEL' | translate
          }}</span>
        </ic-ghost-button>
        <ic-ghost-button
          (click)="onCancelClick()"
          [type]="GhostButtonType.BIG_SECOND_RANK_FLEX"
          [width]="136">
          <span class="nav-btns__text">{{
            'SHARED.WIZARD_BACK_MODAL.CANCEL_BUTTON_LABEL' | translate
          }}</span>
        </ic-ghost-button>
      </div>
    </main>
  </ng-template>
</section>

import { Component, OnInit, Input } from '@angular/core';
import { GhostButtonType } from '../ghost-button/ghost-button-type.enum';

@Component({
  selector: 'app-statement',
  templateUrl: './statement.component.html',
  styleUrls: ['./statement.component.scss']
})
export class StatementComponent implements OnInit {
  GhostButtonType: typeof GhostButtonType = GhostButtonType;

  @Input() statementTitle: string;
  @Input() statementSubtitle: string;
  @Input() fileName: string;
  @Input() linkHref: string;
  @Input() downloadLabel: string;

  constructor() {}

  ngOnInit() {}
}

import { Extensions } from 'src/app/shared/extensions/extensions';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';
import { AttachmentEntity, UploadStatus } from '../model';

@Component({
  selector: 'app-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttachmentComponent implements OnInit, OnChanges {
  @Input() attachment: AttachmentEntity;
  @Input() htmlId: string;
  @Input() isReportedForCorrection = false;
  @Input() warnChecksums: string[];

  @Output() deleteAttachment: EventEmitter<AttachmentEntity> = new EventEmitter();
  @Output() reuploadAttachment: EventEmitter<AttachmentEntity> = new EventEmitter();
  @Output() editAttachment: EventEmitter<AttachmentEntity> = new EventEmitter();
  @Output() showWarn = new EventEmitter();

  showThumbnail = false;

  uploadStatus = UploadStatus;

  isTouched = false;
  showPdf: boolean;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (this.attachment) {
      Extensions.checkIfMimeTypeAllowedToOpenCropperModal(
        new File(
          [Extensions.base64toBlob(this.attachment.dto.content)],
          this.attachment.dto.fileName
        )
      ).then((show) => {
        this.showThumbnail = show.isAllowed;
        this.showPdf = show.mimeType === 'application/pdf';
        this.changeDetectorRef.detectChanges();
      });
    }
  }

  ngOnChanges(changes): void {
    if (changes.attachment.currentValue.status === UploadStatus.UPLOADED) {
      const attachmentContent = changes.attachment.currentValue.dto.content;
      const attachmentFileName = changes.attachment.currentValue.dto.fileName;
      const bytes = Extensions.base64toBlob(attachmentContent);

      Extensions.checkIfMimeTypeAllowedToOpenCropperModal(
        new File([bytes], attachmentFileName)
      ).then((show) => {
        this.showThumbnail = show.isAllowed;
        this.changeDetectorRef.detectChanges();
      });
    } else if (changes.attachment.currentValue.status === UploadStatus.ERROR) {
      this.deleteAttachment.emit(this.attachment);
    }
  }

  onDelete(attachment: AttachmentEntity) {
    this.deleteAttachment.emit(attachment);
    this.showWarn.emit(null);
  }

  onReupload(attachment: AttachmentEntity) {
    this.reuploadAttachment.emit(attachment);
    this.showWarn.emit(null);
  }

  onEdit(attachment: AttachmentEntity) {
    this.editAttachment.emit(attachment);
    this.showWarn.emit(null);
  }

  showWarning(): boolean {
    const shouldShowWarn =
      !!this.warnChecksums &&
      this.warnChecksums.some((warnChecksum) => warnChecksum === this.attachment.dto?.checksum);
    this.showWarn.emit(shouldShowWarn);
    return shouldShowWarn;
  }
}

import { Extensions } from 'src/app/shared/extensions/extensions';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { debounceTime, filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import * as fromSearchCache from '../../../core/_store/search.reducer';
import { SEARCH_CONFIG } from '../../consts/search-config';
import { CountedApiResponseModel } from '../../models/search/counted-api-response.model';
import { Service } from '../../models/search/service.model';
import { Translation } from '../../models/search/translation.model';
import * as fromShared from '../../store/shared.reducer';
import { SearchActions } from './../../../core/_store/search.action';
import { Router } from '@angular/router';
import { SEARCH_ROUTE } from 'src/app/search-results/_consts/search-route.const';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('searchInput') searchInput: ElementRef;
  @Input() requestFocus = false;
  // tslint:disable-next-line:no-input-rename
  @Input('hideResults') allwaysHideResults = false;
  @Output() closeEvent = new EventEmitter<boolean>();
  allServices: Service[] = [];
  resultsAllwaysShown: boolean;
  showResults: boolean;
  inputHasFocus: boolean;
  searchLoading: boolean;
  mouseClickOnResults: boolean;
  searchField: UntypedFormControl;
  searchForm: UntypedFormGroup;
  translation: Translation;
  searchQuery = '';
  searchResponse: CountedApiResponseModel<Service>;
  isLimited = true;
  resultsToShow = SEARCH_CONFIG.RESULTS_TO_SHOW;
  allResults = SEARCH_CONFIG.ALL_RESULTS;
  subscription: Subscription;

  constructor(
    private servicesStore: Store<fromShared.SharedState>,
    private translate: TranslateService,
    private searchCacheStore: Store<fromSearchCache.SearchCacheState>,
    private router: Router,
    fb: UntypedFormBuilder
  ) {
    this.searchField = new UntypedFormControl();
    this.searchForm = fb.group({ search: this.searchField });
    this.searchResponse = new CountedApiResponseModel<Service>([], 0);
    this.showResults = false;
    this.inputHasFocus = false;
    this.mouseClickOnResults = false;
    this.searchLoading = false;
    this.subscription = this.servicesStore
      .select(fromShared.selectSearchServicesData)
      .subscribe((result) => (this.translation = result));
  }

  @Input()
  set resultsEnabled(resultsAllwaysShown: boolean) {
    this.resultsAllwaysShown = resultsAllwaysShown;
  }

  ngOnInit() {
    this.setupSearchBarListener();
    this.fetchAllServices();
  }

  private fetchAllServices() {
    this.allServices = [];
    forkJoin([
      this.translate.get('SERVICE_CATEGORY.DRIVER_LICENSE.ALL_SUBSERVICES'),
      this.translate.get('SERVICE_CATEGORY.TACHOGRAPH.ALL_SUBSERVICES'),
      this.translate.get('SERVICE_CATEGORY.ADR.ALL_SUBSERVICES'),
      this.translate.get('SERVICE_CATEGORY.VEHICLE_REGISTRATION.ALL_SUBSERVICES')
    ])
      .pipe(
        map((category) => {
          category.forEach((element) => {
            element.forEach((service) => {
              this.allServices.push(...service['services']);
            });
          });
        })
      )
      .subscribe(() => {});
  }

  ngAfterViewInit(): void {
    if (this.requestFocus) {
      this.setFocusOnSearchInput();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private setupSearchBarListener() {
    this.searchField.valueChanges
      .pipe(
        shareReplay(),
        filter((query) => query.length > 2),
        tap(() => {
          this.searchLoading = true;
        }),
        debounceTime(400),
        map((query) => query.toLowerCase()),
        tap((query) =>
          this.searchCacheStore.dispatch(new SearchActions.LoadingResultsSearchAction(query))
        ),
        switchMap((query) => this.makeSearch(query))
      )
      .subscribe((result) => {
        this.searchField.value.length > 2
          ? this.setAnalyticsData(this.searchField.value, result.totalCount)
          : null;
        this.isLimited = true;
        this.searchLoading = false;
        this.searchResponse = result;
        this.searchCacheStore.dispatch(new SearchActions.SetResultsSearchAction(result));
      });

    this.searchField.valueChanges.pipe(map((query) => query.length)).subscribe((length) => {
      this.showResults = length > 2;
    });
  }

  onFocus() {
    this.inputHasFocus = true;
    this.mouseClickOnResults = true;
  }

  onBlur() {
    this.inputHasFocus = false;
  }

  onClickOutside() {
    if (!this.inputHasFocus) {
      this.mouseClickOnResults = false;
    }
  }

  setAnalyticsData(searchField: string, result: number) {
    window['gtmAnalytics'] = window['gtmAnalytics'] || [];
    window['gtmAnalytics'].push({
      command: 'sendEvent',
      action: 'Search query',
      label: searchField,
      value: result,
      interactive: true
    });
  }

  setFocusOnSearchInput() {
    setTimeout(() => this.searchInput.nativeElement.focus());
  }

  onShowAll(event) {
    event.stopPropagation();
    this.router.navigate(['/', SEARCH_ROUTE]);
    this.closeEvent.emit(true);
  }

  onItemClick() {
    this.closeEvent.emit(true);
  }
  makeSearch(query: string): Observable<CountedApiResponseModel<Service>> {
    const finder = (x: Service) =>
      x.title.toLowerCase().includes(query) ||
      Extensions.depolishify(x.title).toLowerCase().includes(query) ||
      x.description.toLowerCase().includes(query) ||
      Extensions.depolishify(x.description).toLowerCase().includes(query);

    const sorter = (a: Service, b: Service) => {
      const maxLength = Math.max(
        a.title.length,
        a.description ? a.description.length : 0,
        b.title.length,
        b.description ? b.description.length : 0
      );
      const penalize = (x: number) => (x < 0 ? x * -maxLength : x);

      // FIXME algorytm sortowania jest do poprawy
      // powinien bazowac na sumie punktacji wyznaczanej z liczby i wagi
      // hitow query w tytule, opisie i tagach (i moze jakis arbitralny offset) a niekoniecznie pozycji w stringu
      let w: number, x: number, y: number, z: number;

      w =
        penalize(a.title.toLowerCase().indexOf(query)) -
        penalize(b.title.toLowerCase().indexOf(query));
      x =
        penalize(Extensions.depolishify(a.title).toLowerCase().indexOf(query)) -
        penalize(Extensions.depolishify(b.title).toLowerCase().indexOf(query));
      y =
        penalize(a.description.toLowerCase().indexOf(query)) -
        penalize(b.description.toLowerCase().indexOf(query));
      z =
        penalize(Extensions.depolishify(a.description).toLowerCase().indexOf(query)) -
        penalize(Extensions.depolishify(b.description).toLowerCase().indexOf(query));

      return w !== 0 ? w : x !== 0 ? x : y !== 0 ? y : z !== 0 ? z : 0;
    };

    const foundServices: Service[] = this.allServices.filter(finder).sort(sorter);
    const foundServicesResponse: CountedApiResponseModel<Service> =
      new CountedApiResponseModel<Service>(foundServices, foundServices.length);

    return of(foundServicesResponse);
  }
}

import { ActivationService } from './../../layout/_service/activation.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AccountActivationResolver  {
  constructor(private accountActivation: ActivationService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const token = route.params['token'];
    if (token) this.accountActivation.activateAccount(token);

    return true;
  }
}

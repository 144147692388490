import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/core/auth/auth.service';
import { STC_V1 } from 'src/app/shared/consts/api-version.config';
import { HttpParamsBuilder } from 'src/app/shared/extensions/extensions';
import { ReasonDto } from 'src/app/shared/models/applications/reason-dto.model';
import { environment as env } from 'src/environments/environment';
import {
  EmployeeDtoList,
  EmployeeMinimalDtoList
} from '../../../../shared/models/employee/employee-dto-list.model';

const PAGE_PARAM = 'page';
const LIMIT_PARAM = 'limit';
const FILTER_PARAM = 'filter';
const ONLY_COUNT_FILTER = 'onlyCount';

@Injectable({
  providedIn: 'root'
})
// FIXME This service should be in shared or stc shared module
export class EmployeesService {
  private loggedUserUid: string;

  constructor(private _http: HttpClient, private auth: AuthService) {
    this.auth.user$.subscribe((me) => {
      me ? (this.loggedUserUid = me.uuid) : (this.loggedUserUid = '');
    });
  }

  getEmployeesByReason(reason: ReasonDto.ReasonTypeEnum): Observable<EmployeeDtoList> {
    const headers = new HttpHeaders().set('Content-Type', STC_V1);
    return this._http.get<EmployeeDtoList>(
      `${env.BACKEND_API.BASE}${env.BACKEND_API.EMPLOYERS}` +
        `/${this.loggedUserUid}${env.BACKEND_API.EMPLOYEES_BY_REASON}/${reason}`,
      { headers: headers }
    );
  }

  getEmployees(params: GetEmployeesParams): Observable<EmployeeMinimalDtoList> {
    const headers = new HttpHeaders().set('Content-Type', STC_V1);
    const httpParams = new HttpParamsBuilder()
      .set(PAGE_PARAM, params.page.toString())
      .set(LIMIT_PARAM, params.limit.toString())
      .set(FILTER_PARAM, params.filterQuery)
      .build();
    return this._http.get<EmployeeMinimalDtoList>(
      `${env.BACKEND_API.BASE}${env.BACKEND_API.EMPLOYERS}` +
        `/${this.loggedUserUid}${env.BACKEND_API.EMPLOYEES_SEARCH}`,
      { headers: headers, params: httpParams }
    );
  }

  getEmployeeCount(): Observable<EmployeeDtoList> {
    const headers = new HttpHeaders().set('Content-Type', STC_V1);
    const httpParams = new HttpParams().set(FILTER_PARAM, ONLY_COUNT_FILTER);
    return this._http.get<EmployeeDtoList>(
      `${env.BACKEND_API.BASE}${env.BACKEND_API.EMPLOYERS}` +
        `/${this.loggedUserUid}${env.BACKEND_API.EMPLOYEES}/`,
      { headers: headers, params: httpParams }
    );
  }

  deleteEmployee(employeeId: string): Observable<{}> {
    const headers = new HttpHeaders().set('Content-Type', STC_V1);
    return this._http.delete(
      `${env.BACKEND_API.BASE}${env.BACKEND_API.EMPLOYERS}` +
        `/${this.loggedUserUid}${env.BACKEND_API.EMPLOYEES}/${employeeId}`,
      { headers: headers }
    );
  }
}

export interface GetEmployeesParams {
  page?: number;
  limit?: number;
  sort?: string;
  direction?: SortDirection;
  filterQuery?: string;
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

<div [ngClass]="{ 'ic-page': icPageContainer }">
  <div
    class="ic-page__action-bar"
    [style.marginTop.px]="checkIsMobile() ? mobileMarginTop : marginTop"
    [style.marginBottom.px]="checkIsMobile() ? mobileMarginBottom : marginBottom">
    <div class="row">
      <div class="backwardBtn">
        <app-back-button
          *ngIf="reasonStep"
          label="{{ backwardButtonLabel }}"
          [formOriginPage]="backButtonRoute"
          [disabled]="backwardButtonDisabled">
        </app-back-button>
        <ic-ghost-button
          *ngIf="!reasonStep"
          appPreventDoubleClick
          [htmlId]="'prev-btn'"
          [type]="GhostButtonType.BIG_SECOND_RANK"
          [typeHTML]="'button'"
          [showArrow]="true"
          [arrowLeft]="true"
          (throttledClick)="onNavigateBack()"
          [disabled]="backwardButtonDisabled">
          <span class="backwardSpan">{{ backwardButtonLabel }}</span>
        </ic-ghost-button>
      </div>
      <div class="forwardBtn">
        <ic-ghost-button
          appPreventDoubleClick
          [htmlId]="'next-btn'"
          (throttledClick)="onSubmit()"
          [typeHTML]="'button'"
          [showArrow]="true"
          [width]="setWidthBaseOnWidth()"
          [disabled]="forwardButtonDisabled">
          <span class="forwardSpan">{{ setLabelBaseOnWidth() }}</span>
        </ic-ghost-button>
      </div>
    </div>
  </div>
</div>

import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

const STYLE = '/style.json';

@Injectable({
  providedIn: 'root'
})
export class OsMapService {
  constructor(private httpClient: HttpClient) {}

  getStyle(templateName: string): Observable<any> {
    return this.httpClient.get(`${environment.MAP_TEMPLATE_STYLES}${templateName}${STYLE}`);
  }
}

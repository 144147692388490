import { environment as env } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { PoskLicencePaymentDto } from '../_models/posk-licence-payment.model';
import { PoskWordPaymentDto } from '../_models/posk-word-payment.model';

@Injectable({
  providedIn: 'root'
})
export class PoskPaymentService {
  constructor(private _http: HttpClient) {}

  getPoskLicencePayment = (id: string): Observable<PoskLicencePaymentDto> => {
    return this._http.get<PoskLicencePaymentDto>(`${env.POSK.BASE}${env.POSK.LICENCE}/${id}`);
    // return of({
    //     paymentId: 'df980016-839f-4ad2-997c-3f628e32eb4e',
    //     amount: 13000,
    //     provision: 200,
    //     status: null,
    //     licenceType: OskLicenceType.BIG_OSK,
    //     period: OskLicenseProductPeriod.DAYS_90
    // });
  }

  getPoskWordPayment = (id: string): Observable<PoskWordPaymentDto> => {
    return this._http.get<PoskWordPaymentDto>(`${env.POSK.BASE}${env.POSK.WORD}/${id}`);
    // return of({
    //   paymentId: '21313123',
    //   category: WordExamCategory.A,
    //   amount: 12000,
    //   provision: 200,
    //   reservationId: '11321231231',
    //   wordName: 'WORD Kraków',
    //   status: null
    // });
  }

  createPayment$(id: string): Observable<any> {
    return this._http.post<any>(
      `${env.POSK.BASE}${env.POSK.PAYMENTS}/${id}/${env.POSK.REDIRECT}`,
      {}
    );
  }

  createBlikPayment$(id: any, payload): Observable<any> {
    return this._http.post<any>(
      `${env.POSK.BASE}${env.POSK.PAYMENTS}/${id}/${env.POSK.BLIK}`,
      payload
    );
  }

  getPaymentStatus(billId): Observable<any> {
    return this._http.get<any>(`${env.POSK.BASE}${env.POSK.BILLS}/${billId}`);
  }
}

import { NavigationEnd, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { GhostButtonType } from '../../ghost-button/ghost-button-type.enum';
import { BaseModalDirective } from '../base-modal';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-translation-modal',
  templateUrl: './translation-modal.component.html',
  styleUrls: ['./translation-modal.component.scss']
})
export class TranslationModalComponent extends BaseModalDirective implements OnInit, OnDestroy {
  GhostButtonType = GhostButtonType;
  lastUrl = null;
  private destroy$ = new Subject();
  constructor(private router: Router) {
    super();
  }

  ngOnInit(): void {
    this.lastUrl = this.getData().lastRoute;
    this.router.events
      .pipe(
        filter((routerEvent) => routerEvent instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.close());
  }

  goBack() {
    if (this.lastUrl) {
      this.router.navigateByUrl(this.lastUrl);
    } else {
      this.router.navigateByUrl('/');
    }
    this.close();
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }
}

<section class="accept-term-modal" id="accept-term-modal">
  <header class="header row">
    <h1 class="header-title">
      {{ 'WORD_REGISTRATION.ACCEPT_DATE_MODAL.HEADER' | translate }}
    </h1>
    <button
      [attr.aria-label]="'WORD_REGISTRATION.ACCEPT_DATE_MODAL.CLOSE_BTN_ARIA' | translate"
      (click)="onCancelClick()"
      class="close-btn">
      <svg-icon class="close-btn-icon" src="assets/images/close_mobile_.svg"></svg-icon>
    </button>
  </header>

  <div class="row">
    <div *ngIf="isReschedule" class="col-12 reschedule-info">
      <p>Zmiana terminu egzaminu jest możliwa do 48h przed rozpoczęciem.</p>
      <p>Twoja rezerwacja będzie oczekiwała na potwierdzenie przez Word.</p>
      <p>Poprzedni termin zostanie automatycznie anulowany.</p>
    </div>
    <ng-template [ngIf]="theory && practice" [ngIfElse]="singleExam">
      <div class="col-12">
        <h3>
          {{ 'WORD_REGISTRATION.ACCEPT_DATE_MODAL.EXAM' | translate }}
          {{ 'WORD_REGISTRATION.ACCEPT_DATE_MODAL.LINKED' | translate }}
          {{ 'WORD_REGISTRATION.ACCEPT_DATE_MODAL.CATEGORY' | translate }}
          {{ translateCategory() }}
        </h3>
        <p *ngIf="organizationName || organizationAddress">{{organizationName}} {{organizationAddress}}</p>
      </div>

      <div class="col-12 exam-details-container">
        <p class="exam-details">
          <span class="bold">{{ 'WORD_REGISTRATION.ACCEPT_DATE_MODAL.THEORY' | translate }} </span>
          <span>{{ 'WORD_REGISTRATION.ACCEPT_DATE_MODAL.DATE' | translate }} </span>
          <span class="bold">{{ theory.date | date: 'dd.MM.yyyy, H:mm' }}</span>
        </p>
        <p class="exam-details">
          <span>{{ 'WORD_REGISTRATION.ACCEPT_DATE_MODAL.ADDITIONAL_INFO' | translate }} </span>
          <span class="bold">{{ theory.additionalInfo | dashpipe }}</span>
        </p>
      </div>

      <div class="col-12 exam-details-container">
        <p class="exam-details">
          <span class="bold"
            >{{ 'WORD_REGISTRATION.ACCEPT_DATE_MODAL.PRACTICE' | translate }}
          </span>
          <span>{{ 'WORD_REGISTRATION.ACCEPT_DATE_MODAL.DATE' | translate }} </span>
          <span class="bold">{{ practice.date | date: 'dd.MM.yyyy, H:mm' }}</span>
        </p>
        <p class="exam-details">
          <span>{{ 'WORD_REGISTRATION.ACCEPT_DATE_MODAL.ADDITIONAL_INFO' | translate }} </span>
          <span class="bold">{{ practice.additionalInfo | dashpipe }}</span>
        </p>
      </div>
    </ng-template>

    <ng-template #singleExam>
      <div class="col-12">
        <h3>
          <ng-container *ngIf="type === 'PKK'">
            {{ 'WORD_REGISTRATION.ACCEPT_DATE_MODAL.EXAM' | translate }}
          {{
            (theory
              ? 'WORD_REGISTRATION.ACCEPT_DATE_MODAL.THEORETICAL'
              : 'WORD_REGISTRATION.ACCEPT_DATE_MODAL.PRACTICAL'
            ) | translate

          }}
          {{ 'WORD_REGISTRATION.ACCEPT_DATE_MODAL.CATEGORY' | translate }}
          </ng-container>
          <ng-container *ngIf="type === 'PKZ'">
            {{ 'WORD_REGISTRATION.ACCEPT_DATE_MODAL.QUALIFICATION_TEST' | translate }}
          </ng-container>
          {{ translateCategory() }}
        </h3>
        <p *ngIf="organizationName || organizationAddress">{{organizationName}} {{organizationAddress}}</p>
      </div>

      <div class="col-12 exam-details-container">
        <p class="exam-details">
          <span>{{ 'WORD_REGISTRATION.ACCEPT_DATE_MODAL.DATE' | translate }} </span>
          <span class="bold">{{
            (theory ? theory.date : practice?.date) | date: 'dd.MM.yyyy, H:mm'
          }}</span>
        </p>
        <p class="exam-details">
          <span>{{ 'WORD_REGISTRATION.ACCEPT_DATE_MODAL.ADDITIONAL_INFO' | translate }} </span>
          <span class="bold">{{
            (theory ? theory.additionalInfo : practice?.additionalInfo) | dashpipe
          }}</span>
        </p>
      </div>
    </ng-template>
  </div>

  <div class="row nav-btns">
    <ic-ghost-button
      appPreventDoubleClick
      [throttleTime]="30000"
      (throttledClick)="onOkClick()"
      class="confirm-modal-btn"
      [htmlId]="'confirm-modal-btn'"
      (click)="onOkClick()"
      [showArrow]="true"
      [width]="buttonOk.width"
      [height]="buttonOk.height">
      {{ 'WORD_REGISTRATION.ACCEPT_DATE_MODAL.CONFIRM_BUTTON' | translate }}
    </ic-ghost-button>
  </div>
</section>

import { ObtainCardType } from './../../../../application-landing-pages/obtain-tachograph-card/_consts/obtain-card.const';
import { FormOfRefundDto } from './../../../models/applications/form-of-refund-dto.model';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { RefundFormDataFormBinder } from './refund-form-data.form-binder';
import {
  RefundFormDataDto,
  RefundFormDataFormValidation,
  RefundFormDataStaticContent
} from './refund-form-data.model';
import { defaultRefundFormDataValidation } from './refund-form-data.validation';
import { nameof } from 'src/app/shared/extensions/extensions';
import {
  CorrectionsErrors,
  FieldErrorEntry
} from 'src/app/shared/models/corrections/corrections.model';

@Component({
  selector: 'app-refund-form-data',
  templateUrl: './refund-form-data.component.html',
  styleUrls: ['./refund-form-data.component.scss'],
  providers: [RefundFormDataFormBinder]
})
export class RefundFormDataComponent implements OnInit, OnDestroy {
  @Input() staticContent: RefundFormDataStaticContent;
  @Input() formValidation: RefundFormDataFormValidation = defaultRefundFormDataValidation;
  @Input() aggregateFormGroup: UntypedFormGroup;
  @Input() set hasMailingAddress(data: boolean) {
    this._hasMailingAddress = data;

    if(!data) {
      this.formGroup?.reset();
    }
  }

  get hasMailingAddress(): boolean {
    return this._hasMailingAddress;
  }
  @Input() businessAccount = false;
  @Input() fetchedData: RefundFormDataDto;
  @Input() htmlElementId: string;
  @Output() saveDraft: EventEmitter<any> = new EventEmitter();
  @Output() refundTypeSelected: EventEmitter<FormOfRefundDto.RefundTypeEnum> = new EventEmitter();
  @Input() selectedCardType: ObtainCardType;
  @Input() set correctionsErrors(data: CorrectionsErrors) {
    this._correctionsErrors = data;
    this.getFieldsErrors(data);
  }
  private _correctionsErrors: CorrectionsErrors;
  private _hasMailingAddress: boolean;

  formGroup: UntypedFormGroup;
  cardTypes = ObtainCardType;
  formPrefix = 'formOfRefund';
  fieldsErrors: FieldErrorEntry[] = [];

  public constructor(private binder: RefundFormDataFormBinder) {}

  public ngOnInit() {
    this.formGroup = this.binder.bindForm(this.formValidation);
    this.aggregateFormGroup.addControl(this.formValidation.formGroupName, this.formGroup);
    this.binder.fillFormWith(this.fetchedData);
    if (this.binder.isRefundTypeNull()) {
      this.binder.setRefundTypeTo(FormOfRefundDto.RefundTypeEnum.BANKTRANSFER);
    }
    this.refundTypeSelected.emit(
      this.binder.getControlValue(nameof<RefundFormDataFormValidation['formFields']>('refundType'))
    );
  }

  sectionErrorsExist(): boolean {
    return this._correctionsErrors?.sectionErrors.some((item) => item.entry === this.formPrefix);
  }

  getFieldsErrors(correctionsErrors: CorrectionsErrors) {
    this.fieldsErrors =
      correctionsErrors?.fieldErrors
        .filter((item) => item.entry.startsWith(this.formPrefix))
        .map((entry) => {
          return {
            entry: entry.entry,
            previousValue: entry.previousValue
          };
        }) || [];
  }

  isFieldIncludedInCorrection(fieldName: string): boolean {
    return (
      this.fieldsErrors.some((err) => err.entry.endsWith(fieldName)) || this.sectionErrorsExist()
    );
  }

  findPreviousValue(fieldName: string): any {
    return this.fieldsErrors.find((err) => err.entry.endsWith(fieldName))?.previousValue;
  }

  onBlur(): void {
    this.saveDraft.emit();
    this.refundTypeSelected.emit(
      this.binder.getControlValue(nameof<RefundFormDataFormValidation['formFields']>('refundType'))
    );
  }
  ngOnDestroy(): void {
    if (this.aggregateFormGroup.contains(this.formValidation.formGroupName)) {
      this.aggregateFormGroup.removeControl(this.formValidation.formGroupName);
    }
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { GhostButtonType } from 'src/app/shared/components/ghost-button/ghost-button-type.enum';
import { customErrorHandler } from 'src/app/shared/extensions/extensions';
import { PoskPaymentStatus } from '../_models/posk-payment.model';
import { PoskPaymentService } from '../_services/posk-payment.service';
import { environment as env } from 'src/environments/environment';

@Component({
  selector: 'app-payment-confirmation',
  templateUrl: './payment-confirmation.component.html',
  styleUrls: ['./payment-confirmation.component.scss']
})
export class PaymentConfirmationComponent implements OnInit, OnDestroy {
  GhostButtonType: typeof GhostButtonType = GhostButtonType;
  status: PoskPaymentStatus;
  content: any;
  redirectUrl = env.POSK.REDIRECT_URL;
  automaticRedirect: ReturnType<typeof setInterval>;
  timeToRedirect = 15;
  paymentCreationError: boolean;

  constructor(
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private poskService: PoskPaymentService,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.getPaymentStatusAndTranslationContent();
  }
  getPaymentStatusAndTranslationContent() {
    const paramStatus = this.route.snapshot.queryParamMap.get('status');
    if (paramStatus && paramStatus === 'error') {
      this.paymentCreationError = true;
    }

    this.poskService.getPaymentStatus(this.route.snapshot.paramMap.get('id')).subscribe({
      next: (bill) => {
        this.status = this.paymentCreationError ? PoskPaymentStatus.ERROR : bill.status;
        this.redirectUrl = bill.redirectUrl;
        this.goToLinkAutomatically();
        this.getStatusTexts(this.status);
      },
      error: (error) => {
        customErrorHandler(error, this.toastrService, this.translate);
        this.goToLinkAutomatically();
        this.getStatusTexts('NORESPONSE');
      }
    });
  }

  getStatusTexts(status: PoskPaymentStatus | 'NORESPONSE') {
    this.translate.get(`EXTERNAL_PAYMENTS.CONFIRMATION.${this.getMappedStatus(status)}`).subscribe({
      next: (content) => (this.content = content)
    });
  }

  getMappedStatus(billStatus: PoskPaymentStatus | 'NORESPONSE') {
    switch (billStatus) {
      case PoskPaymentStatus.SEND ||
        PoskPaymentStatus.REGISTERED ||
        PoskPaymentStatus.INITIALIZING_PAYMENT_PROCESS:
        return 'AUTHPENDING';
      case PoskPaymentStatus.REJECTED:
        return 'AUTHREJECTED';
      case PoskPaymentStatus.ACCEPTED:
        return 'AUTHACCEPTED';
      case PoskPaymentStatus.ERROR:
        return 'EXCEEDED';
      case 'NORESPONSE':
        return 'NORESPONSE';
      default:
        return 'AUTHPENDING';
    }
  }

  goToLinkAutomatically() {
    this.automaticRedirect = setInterval(() => {
      if (this.timeToRedirect > 0) {
        this.timeToRedirect--;
      } else {
        clearInterval(this.automaticRedirect);
        this.goToLink();
      }
    }, 1000);
  }

  goToLink() {
    const link = this.redirectUrl.slice(0, 1);

    if (link === '/') {
      this.router.navigateByUrl(this.redirectUrl);
    } else {
      window.location.href = this.redirectUrl;
    }
  }

  ngOnDestroy() {
    clearTimeout(this.automaticRedirect);
  }
}

import { Injectable, OnDestroy } from '@angular/core';
import * as Immutable from 'immutable';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map, filter } from 'rxjs/operators';
import {
  AttachmentDto,
  AttachmentType
} from 'src/app/shared/models/attachments/attachment-dto.model';
import { ErrorDto } from 'src/app/shared/models/errors/error-dto.model';
import { AttachmentEntity, UploadStatus } from '../model';

@Injectable()
export class AttachmentsStore implements OnDestroy {
  private readonly dispatcher: BehaviorSubject<Immutable.List<AttachmentEntity>> =
    new BehaviorSubject(null);
  private id = 0;
  public entities$: Observable<AttachmentEntity[]> = this.dispatcher.asObservable().pipe(
    filter((list) => !!list),
    distinctUntilChanged(entityComparator),
    map((list) => list.toArray())
  );

  public init(dtos?: AttachmentDto[]) {
    const initialState = dtos.map((attachmentDto) => {
      const createdEntity: AttachmentEntity = {
        id: this.nextId(),
        dto: attachmentDto,
        status: UploadStatus.UPLOADED
      };
      return createdEntity;
    });
    this.dispatcher.next(Immutable.List(initialState));
  }

  public createUploaded(dto: AttachmentDto): AttachmentEntity {
    return this.create(dto, UploadStatus.UPLOADED);
  }

  public createReadyToUpload(dto: AttachmentDto): AttachmentEntity {
    return this.create(dto, UploadStatus.READY);
  }

  private create(dto: AttachmentDto, status: UploadStatus.Status): AttachmentEntity {
    const createdEntity: AttachmentEntity = {
      id: this.nextId(),
      dto: dto,
      status: status
    };
    this.dispatcher.next(this.dispatcher.value.push(createdEntity));
    return createdEntity;
  }

  public updateProgress(entity: AttachmentEntity, progress: number) {
    this.updateStatus(entity, new UploadStatus.InProgress(progress));
  }

  public updateStatus(entity: AttachmentEntity, status: UploadStatus.Status, error?: ErrorDto[]) {
    const index = this.dispatcher.value.findIndex((item) => item.id === entity.id);
    this.dispatcher.next(
      this.dispatcher.value.update(index, (value) => ({
        ...value,
        status: status,
        errorMessages: error
      }))
    );
  }

  public updateUploaded(entity: AttachmentEntity, dto: AttachmentDto) {
    const index = this.dispatcher.value.findIndex((item) => item.id === entity.id);
    this.dispatcher.next(
      this.dispatcher.value.update(index, (value) => ({
        ...value,
        dto: dto,
        status: UploadStatus.UPLOADED,
        errorMessages: null
      }))
    );
  }

  public selectEntitiesByType(type: AttachmentType): Observable<Immutable.List<AttachmentEntity>> {
    return this.dispatcher.asObservable().pipe(
      map((state) => state.filter((item) => item.dto.type === type)),
      distinctUntilChanged(entityComparator)
    );
  }

  public deleteEntity(entity: AttachmentEntity) {
    const index = this.dispatcher.value.findIndex((item) => item.id === entity.id);
    this.dispatcher.next(this.dispatcher.value.delete(index));
  }

  private nextId(): number {
    return this.id++;
  }

  ngOnDestroy(): void {
    this.dispatcher.complete();
  }
}

function entityComparator(
  prev: Immutable.List<AttachmentEntity>,
  curr: Immutable.List<AttachmentEntity>
): boolean {
  return prev.equals(curr);
}

import { ResultListComponent } from './result-list/result-list.component';
import { TextInputComponent } from './text-input/text-input.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectableInputComponent } from './selectable-input.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [SelectableInputComponent, TextInputComponent, ResultListComponent],
  imports: [CommonModule, ClickOutsideModule, TranslateModule, FormsModule],
  exports: [SelectableInputComponent]
})
export class SelectableInputModule {}

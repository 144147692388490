<article *ngIf="message && visible" [style.z-index]="zIndex" class="ic-info-bar">
  <div class="container ic-info-bar-content">
    <figure>
      <img
        class="ic-info-bar-content-icon"
        src="assets/images/info-icon.svg"
        alt="IC-info-bar icon" />
    </figure>
    <div class="ic-info-bar-content-close">
      <button [attr.aria-label]="'INFO_BAR.closeBtnAria' | translate" (click)="onClose()">
        <svg-icon
          class="ic-info-bar-content-close-btn"
          src="assets/images/close-icon.svg"></svg-icon>
      </button>
    </div>
    <main class="ic-info-bar-content-text">
      <h6 *ngIf="message?.header">{{ message?.header }}</h6>
      <p *ngIf="message?.text">
        <span
          appTextEllipsis
          lines="2"
          [innerHTML]="message?.text | safeHtml"
          appInternalLink></span>
      </p>

      <ic-ghost-button
        *ngIf="isClamped$ | async"
        (click)="onToggleExpandText()"
        [showArrow]="false"
        [justifyContent]="'center'"
        [type]="GhostButtonType.SIMPLE_LINK"
        class="ic-info-bar-content-text-expand-btn">
        <span *ngIf="isExpended$ | async as expanded; else collapsed">{{
          'INFO_BAR.expandedLabel' | translate
        }}</span>
        <ng-template #collapsed
          ><span>{{ 'INFO_BAR.collapsedLabel' | translate }}</span></ng-template
        >
      </ic-ghost-button>
    </main>
  </div>
</article>
